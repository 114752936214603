import { Button, Result } from 'antd';

export default function NotFound(props) {
  const menu = localStorage.getItem('menu');
  const tempMenu = JSON.parse(menu);
  const isAdmin = tempMenu.find((menu) => menu.id === 2)?.isAdmin;
  const isEmployee = tempMenu.find((menu) => menu.id === 2)?.isEmployee;
  const isManager = tempMenu.find((menu) => menu.id === 2)?.isManager;
  if (isAdmin && props?.location?.pathname === '/app/dashboard') {
    props.history.push('/app/admin-dashboard');
  }
  if (!isAdmin && isManager && props?.location?.pathname === '/app/dashboard') {
    props.history.push('/app/manager-dashboard');
  }
  if (!isAdmin && !isManager && isEmployee && props?.location?.pathname === '/app/dashboard') {
    props.history.push('/app/employee-dashboard');
  }

  return (
    <Result
      status="404"
      title="Page Not Found"
      subTitle="We can't find the page you are looking for."
      extra={[
        <Button type="primary" key="console" onClick={() => props.history.push('/')}>
          Back Home
        </Button>,
      ]}
    />
  );
}
