import * as XLSX from 'xlsx';

export function excelFileToArrays(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      const readerResult = e.target.result;
      const workBinary = XLSX.read(readerResult, { type: 'binary', cellDates: true });
      const sheetNames = workBinary.SheetNames[0];
      const workSheet = workBinary.Sheets[sheetNames];
      const arrayResult = XLSX.utils.sheet_to_json(workSheet, {
        header: 1,
        raw: false,
        blankrows: false,
        dateNF: 'dd/mm/yyyy',
      });

      resolve({
        status: 'success',
        content: {
          file_name: file.name,
          excel_arrays: arrayResult,
          error_message: '',
        },
      });
    };

    reader.onerror = function (e) {
      reject({
        status: 'error',
        content: {
          file_name: file.name,
          excel_arrays: [],
          error_message: 'Pengolahan data gagal',
        },
      });
    };

    reader.readAsBinaryString(file);
  });
}
