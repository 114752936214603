import { useEffect, useState } from 'react';
import { Col, Row, Skeleton } from 'antd';
import EmployeeDashboardUsecase from './EmployeeDashboard.usecase';
import Shortcut from '../templates/admin-dashboard-template/components/Shortcut';
import EmployeeSection from '../components/EmployeeSection';
import News from '../components/News';
import Submission from '../components/Submission';
import Carousels from '../components/Carousel';
import { aesGcmDecrypt } from '../../../services/Encrypter';
import { ToolbarDetailActionButton } from '../../hr/component/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface';
import ToolbarButtonDetailTemplate from '../../hr/employee/components/toolbar-button-detail-template/ToolbarButtonDetailTemplate';

const useCaseImpl = new EmployeeDashboardUsecase();
let is_admin = false;
let is_manager = false;
let is_employee = false;
export default function EmployeeDashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [employeeDetail, setEmployeeDetail] = useState({});
  const [banner, setBanner] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [submissionData, setSubmissionData] = useState<any>([]);

  const ShortcutProps = [
    {
      key: 'leave',
      icon: 'fa-cubes',
      title: 'Cuti',
      href: '/v2/app/hr-leave/my-submission',
    },
    {
      key: 'loan',
      icon: 'fa fa-credit-card',
      title: 'Pinjaman',
      href: '/v2/app/hr-loan/submission-loan',
    },
    {
      key: 'overtime',
      icon: 'fa fa-hourglass-end',
      title: 'Lembur',
      href: '/v2/app/hr-overtime/my-submission-overtime',
    },
    {
      key: 'claim',
      icon: 'fa fa-cart-arrow-down',
      title: 'Klaim',
      href: '/v2/app/hr-claim/my-submission-claim',
    },
  ];

  const currentMenu = localStorage.getItem('menu');
    if (currentMenu) {
      const menu = JSON.parse(currentMenu);
      is_admin = menu.find(item => item.id === 2)?.isAdmin ? true : false
      is_manager = menu.find(item => item.id === 2)?.isManager ? true : false
      is_employee = menu.find(item => item.id === 2)?.isEmployee ? true : false
    }

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {
    setIsLoading(true);
    let tempFoto = '';
    const [news, emp, app, ban] = await Promise.all([
      useCaseImpl.getNews(),
      useCaseImpl.getEmployeeSection(),
      useCaseImpl.getProcessApp(),
      useCaseImpl.getBanner()
    ]);
    if (emp?.data?.content) {
      if (emp.data.content.file_foto) {
        tempFoto = await aesGcmDecrypt(emp.data.content.file_foto);
      }
    }
    setEmployeeDetail({ ...emp.data.content, file_foto: tempFoto });
    setBanner(ban.data.content);
    setNewsList(news.data.content);
    setSubmissionData(app.data.content);
    setIsLoading(false);
  }

  const adminButton: ToolbarDetailActionButton = {
    active: is_admin,
    disabled: false,
    label: 'Admin',
    onClick: () => {
      window.location.href = '/v2/app/admin-dashboard';
    },
  };

  const managerButton: ToolbarDetailActionButton = {
    active: is_manager,
    disabled: false,
    label: 'Daftar Pengajuan',
    onClick: () => {
      window.location.href = '/v2/app/manager-dashboard';
    },
  };

  const employeeButton: ToolbarDetailActionButton = {
    active: is_employee,
    disabled: true,
    label: 'Personal Detail',
  };

  // if (isLoading) {
  //   return <PageLoader />;
  // }

  return (
    <div id="employee-dashboard-page">
      <ToolbarButtonDetailTemplate
        submitButton={{ active: false }}
        firstActionButton={adminButton}
        secondActionButton={employeeButton}
        thirdActionButton={managerButton}
        fourthActionButton={{ active: false }}
        dropdownActionButton={{ visible: false }}
      />
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {isLoading ? (
            <Skeleton loading={isLoading} active />
          ) : (
            <EmployeeSection data={employeeDetail} />
          )}
        </Col>

        <Col span={6}>
          <Shortcut ShortcutProps={ShortcutProps} />
        </Col>
        <Col span={6}>
          {isLoading ? <Skeleton loading={isLoading} active /> : <News data={newsList} />}
        </Col>
        <Col span={12} style={{ height: 'max-content' }}>
          {isLoading ? <Skeleton loading={isLoading} active /> : <Carousels banner={banner} />}
        </Col>
        <Col span={24}>
          <h2>Proses Pengajuan Anda</h2>
        </Col>
        <Col span={6}>
          {isLoading ? (
            <Skeleton loading={isLoading} active />
          ) : (
            <Submission
              title={'Cuti'}
              data={submissionData?.leave}
              href={'/v2/app/hr-leave/my-submission'}
            />
          )}
        </Col>
        <Col span={6}>
          {isLoading ? (
            <Skeleton loading={isLoading} active />
          ) : (
            <Submission
              title={'Klaim'}
              data={submissionData?.claim}
              href={'/v2/app/hr-claim/my-submission-claim'}
            />
          )}
        </Col>
        <Col span={6}>
          {isLoading ? (
            <Skeleton loading={isLoading} active />
          ) : (
            <Submission
              title={'Pinjaman'}
              data={submissionData?.loan}
              href={'/v2/app/hr-loan/submission-loan'}
            />
          )}
        </Col>
        <Col span={6}>
          {isLoading ? (
            <Skeleton loading={isLoading} active />
          ) : (
            <Submission
              title={'Lembur'}
              data={submissionData?.ovt}
              href={'/v2/app/hr-overtime/my-submission-overtime'}
            />
          )}
        </Col>
      </Row>
    </div>
  );
}
