import React, { useEffect, useState } from 'react';
import { Form, Button } from 'antd';
import FormBuilder from 'antd-form-builder';
import { useHistory } from 'react-router-dom';
import PageLoader from '../../../../../components/loader/PageLoader';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import HrAddEmployeeUsecase from './AddUser.usecase';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
const useCaseImpl = new HrAddEmployeeUsecase()
const localNotificationService = new LocalNotificationService();

export default function HrAddEmployee() {
  const history = useHistory();
  const [form] = Form.useForm();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [, updateState] = React.useState<any>();
  const [employeeList, setEmployeeList] = useState([]);
  const [AccessList, setAccessList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Users',
        href: "/v2/app/hr-employee/list-user",
      },
      {
        clickable: false,
        displayPathName: 'Baru',
      }
    ],
  };

  const meta: any = {
    columns: 1,
    fields: [
      {
        key: 'user_type',
        label: 'Tipe Pengguna',
        required: true,
        message: 'Tipe Pengguna harus diisi',
        widget: 'select',
        options: [
          { value: 'ess', label: 'ESS' },
          { value: 'system', label: 'System Admin' },
        ],
      },
      {
        key: 'employee_id',
        label: 'Karyawan',
        required: true,
        message: 'Karyawan harus diisi',
        widget: 'select',
        widgetProps: {
          showSearch: true
        },
        options: employeeList,
      },
      // {
      //   key: 'password_type',
      //   label: 'Password',
      //   required: true,
      //   widget: 'radio-group',
      //   forwardRef: true,
      //   options: [
      //     { value: 'default', label: 'Default' },
      //     { value: 'custom', label: 'Custom' },
      //   ],
      // },
      {
        key: 'timezone',
        label: 'Zona Waktu',
        required: true,
        message: 'Zona Waktu harus diisi',
        widget: 'select',
        options: [
          { value: 'wib', label: 'WIB' },
          { value: 'wita', label: 'WITA' },
          { value: 'wit', label: 'WIT' },
        ],
      },
      { key: 'is_lock', label: 'Dikunci', widget: 'checkbox' },
      {
        key: 'separator',
        colSpan: 4,
        render() {
          return (
            <hr />
          );
        },
      },
      {
        key: 'hak_akses',
        label: 'Hak Akses',
        widget: 'checkbox-group',
        forwardRef: true,
        options: AccessList,
      },
    ],
  };

  useEffect(() => {
    setIsLoading(true);
    initialPage();
  }, []);

  async function initialPage() {
    // const temSvp = await useCaseImpl.getResGroupSvc();
    const employeeList = await useCaseImpl.getEmployeeList();
    const getAccessList = await useCaseImpl.getAccessList();

    setEmployeeList(employeeList);
    setAccessList(getAccessList)
    setIsLoading(false);
  }

  async function onFinish(e) {
    setIsLoading(true);
    const list_access = e.hak_akses && e.hak_akses.map(item => JSON.parse(item)).map(function (row) {
      return {
        id: row.id,
        level: row.level,
        name: row.name,
      }
    })
    let tempParam: any = {}
    e.employee_id && (tempParam['employee_id'] = JSON.parse(e.employee_id)?.id)
    e.login && (tempParam['login'] = e.login.trim())
    e.hak_akses && (tempParam['list_group_id'] = list_access)
    
    const params = {
        user_type: e.user_type,
        password_type: e.password_type,
        password: e.password,
        timezone: e.timezone,
        ...tempParam
    }

    useCaseImpl.create(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          history.push('/app/hr-employee/list-user');
          localNotificationService.openSuccess('User Berhasil Ditambah', '', 3);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    );

  }

  let newMeta = meta;
  if (form.getFieldValue('user_type') === 'ess') {
    newMeta.fields.splice(2, 0, {
      key: 'password_type',
      label: 'Tipe Password',
      required: true,
      widget: 'radio-group',
      forwardRef: true,
      options: [
        { value: 'default', label: 'Default' },
        { value: 'custom', label: 'Custom' },
      ],
    });
    if (form.getFieldValue('password_type') === 'custom') {
      newMeta.fields.splice(3, 0, {
        key: 'password',
        label: 'Password',
        required: true,
        widget: 'password'
      });
      
    }
  }
  if (form.getFieldValue('user_type') === 'system') {
    const objIndex = newMeta.fields.findIndex((obj => obj.key === 'employee_id'));
    newMeta.fields[objIndex] = {
      key: 'login',
      label: 'Nama Pengguna',
      rules: [{ required: true, whitespace: true, message: "Nama Pengguna harus diisi", }],
    }
    newMeta.fields.splice(2, 0, {
      key: 'password',
      label: 'Password',
      required: true,
      widget: 'password',
      message: 'Password harus diisi',
    });
  }

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div
      style={{ position: 'relative', height: 'calc(100vh - 100px)' }}
    >
      <Form form={form} layout="horizontal" onFinish={onFinish} onValuesChange={forceUpdate}>
        <div>
          <div className="form-wrapper">
            <div>
              <Breadcrumb breadcrumbConf={breadcrumbConf} />
              <Form.Item
                className="form-footer"
                style={{ width: '100%', marginTop: 20 }}
              >
                <Button
                  type="primary"
                  onClick={() => form.submit()}
                  disabled={isLoading}
                >
                  Simpan
                </Button>
                &nbsp; &nbsp;
                <Button
                  onClick={() => history.push('/app/hr-employee/list-user')}
                >
                  Batal
                </Button>
              </Form.Item>
              <div style={{ border: '1px solid #000', padding: 10, overflowY: 'scroll', height: '70vh' }}>
                <FormBuilder form={form} meta={newMeta} />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
