import { Button, Col, DatePicker, Dropdown, Input, Menu, Pagination, Row, Select, Space, Table } from "antd";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import ComponentCardView from "./components/ComponentCardView";
import { useEffect, useState } from "react";
import { CardViewListTemplateButton, CardViewListTemplateDropdownButton, CardViewListTemplatePagination, CardViewListTemplatePropsType, SearchColumnKeyType } from "./CardViewListTemplate.interface";
import { AppstoreOutlined, UnorderedListOutlined, DownOutlined } from '@ant-design/icons'
import './CardViewListTemplate.less'
import BottomLoading from "../../components/bottom-loading/BottomLoading";

let output = {
  pagination: null,
  sort: null,
  searchColumn: null,
  selection: null,
};

export default function CardViewListTemplate(props: CardViewListTemplatePropsType) {
  let [addButton, setAddButton] = useState<CardViewListTemplateButton>();
  let [exportButton, setExportButton] = useState<CardViewListTemplateButton>();
  let [deleteButton, setDeleteButton] = useState<CardViewListTemplateButton>();
  let [dropdownButton, setDropdownButton] = useState<CardViewListTemplateDropdownButton>();
  let [isCardMode, setIsCardMode] = useState(true)
  let [dataSource, setDataSource] = useState([]);
  let [columns, setColumns] = useState<any>();
  let [tableRowSelectionActive, setTableRowSelectionActive] = useState();
  let [selectedRowKeys, setSelectedRowKeys] = useState<any>();
  let [loading, setLoading] = useState(true);
  let [pagination, setPagination] = useState<CardViewListTemplatePagination>({
    current: 1,
    pageSize: 5,
    total: 50,
    size: 'small',
  });

  useEffect(() => {
    fillActionButtonState('addButton');
  }, [props.addButton]);
  useEffect(() => {
    fillActionButtonState('exportButton');
  }, [props.exportButton]);
  useEffect(() => {
    fillActionButtonState('deleteButton');
  }, [props.deleteButton]);
  useEffect(() => {
    if (props.dropdownButton) {
      processDropdownButton();
    }
  }, [props.dropdownButton]);
  useEffect(() => {
    processTableData();
  }, [props.tableData]);
  useEffect(() => {
    processTableHeader();
  }, [props.tableHeader]);
  useEffect(() => {
    processPagination();
  }, [props.tablePagination]);
  useEffect(() => {
    processLoading();
  }, [props.isLoading]);


  const processDropdownButton = () => {
    const result: any = { visible: true, disabled: false, options: [] };

    result.visible = props.dropdownButton.visible === undefined ? true : props.dropdownButton.visible;
    result.disabled = props.dropdownButton.disabled === undefined ? false : props.dropdownButton.disabled;
    result.options = props.dropdownButton.options
      ? props.dropdownButton.options
      : [{ label: 'Option 1', value: 'option_1' }];

    setDropdownButton(result)
  }

  const fillActionButtonState = (procedure) => {
    const result: any = { visible: true, disabled: false };

    if (props[procedure]) {
      result.visible = props[procedure].visible === undefined ? true : props[procedure].visible;
      result.disabled = props[procedure].disabled === undefined ? false : props[procedure].disabled;
    }

    switch (procedure) {
      case 'addButton':
        setAddButton(result);
        break;
      case 'exportButton':
        setExportButton(result);
        break;
      case 'deleteButton':
        setDeleteButton(result);
        break;
      default:
        break;
    }
  };

  const processTableData = () => {
    if (props.tableData && props.tableData.dataSource && props.tableData.dataSource.length) {
      const searchColumnKey: SearchColumnKeyType[] = props.tableData.searchColumnKey
        ? props.tableData.searchColumnKey
        : [];
      const searchColumnResult = JSON.parse(JSON.stringify(props.tableData.dataSource[0]));
      const dataSource = props.tableData.dataSource ? props.tableData.dataSource : [];
      let result = [];

      if (searchColumnKey.length) {
        for (let key in searchColumnResult) {
          searchColumnResult[key] = '';
          searchColumnKey.map(sck => {
            if (sck.key === key) {
              searchColumnResult[key] = sck.componentType
            }
          })
        }

        result.push(searchColumnResult);
      }
      result = result.concat(dataSource);

      setDataSource([...result]);
      processTableRowSelection();
    }
  };

  const processTableHeader = () => {
    const column: any = props.tableHeader ? props.tableHeader : [];
    const result: any = [];

    if (column.length) {
      column.forEach((header) => {
        const temp = header;
        temp.render = (text, record) => {
          switch (text) {
            case 'text':
              return <Input.Search
                allowClear
                onPressEnter={(e: any) => onEnterSearchColumn(e.target.value, header.key)}
                onSearch={(val) => onEnterSearchColumn(val, header.key)}
              />
            case 'date':
              return <DatePicker
                format="YYYY/MM/DD"
                onChange={(_, date) => onEnterSearchColumn(date, header.key)}
              />
            case 'select':
              const options = props.tableData.searchColumnKey.find(sck => {
                return sck.key === header.key
              }).options
              return <Select
                style={{ width: '100%' }}
                options={options}
                onChange={(value) => onEnterSearchColumn(value, header.key)}
              />
            default:
              break;
          }
          if (header.isCTA !== undefined && header.isCTA) {
            return <a href={record.href}>{text}</a>;
          } else {
            return <span>{text}</span>;
          }
        };

        result.push(header);
      });
      setColumns([...result]);
    } else {
      // empty conf for header table
    }
  };

  const onEnterSearchColumn = (value, columnName) => {
    if (!output.searchColumn) {
      output.searchColumn = {};
    }
    output.searchColumn[columnName] = value;

    sendOutputTable();
  };

  const processPagination = () => {
    const resultPagination: CardViewListTemplatePagination = pagination;

    if (props.tablePagination) {
      const tablePagination = props.tablePagination;
      resultPagination.current = tablePagination.current ? tablePagination.current : 1;
      resultPagination.pageSize = tablePagination.pageSize ? tablePagination.pageSize : 5;
      resultPagination.total = tablePagination.total ? tablePagination.total : 5;
      resultPagination.size = tablePagination.size ? tablePagination.size : 'small';
    }

    // setPagination(resultPagination);
  };

  const processLoading = () => {
    const result = props.isLoading !== undefined ? props.isLoading : true;
    setLoading(result);
  };

  const processTableRowSelection = () => {
    const result: any = props.tableData.tableRowSelectionActive
      ? props.tableData.tableRowSelectionActive
      : false;
    setTableRowSelectionActive(result);
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys([...selectedRowKeys]);

    output.selection = selectedRowKeys;
    sendOutputTable();
  };

  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
    getCheckboxProps: (record) => {
      return {
        disabled: record.key === 'search-input' || record.key === 'empty-data' || record.key === 'text'
      }
      
    },
  };

  const onClickActionButton = (procedure) => {
    if (props[procedure] && props[procedure].onClick) {
      props[procedure].onClick();
    }
  };

  const sendOutputTable = () => {
    if (props.tableOnChange) {
      props.tableOnChange(output);
    }
  };

  const tableOnChange = (pagination, filters, sort, extra) => {
    output.sort = {
      columnKey: sort.columnKey,
      field: sort.field,
      order: sort.order,
    };
    sendOutputTable();
  };

  const paginationOnChange = (page, pageSize) => {
    output.pagination = { page, pageSize };
    sendOutputTable();
  };

  const onRow = (record) => {
    if (props.onRow) {
      props.onRow(record)
    }
  }

  const AddButton = () => {
    return addButton && addButton.visible ? (
      <Button
        type="primary"
        disabled={addButton.disabled}
        onClick={() => onClickActionButton('addButton')}
      >
        Tambah
      </Button>
    ) : <div></div>;
  };

  const ChangeModeButton = () => {
    return (
      <Space>
        <Button
          type="primary"
          disabled={!isCardMode}
          onClick={() => {
            props.changeMode(false)
            setIsCardMode(false)
          }}
        >
          <UnorderedListOutlined />
        </Button>
        <Button
          type="primary"
          disabled={isCardMode}
          onClick={() => {
            props.changeMode(true)
            setIsCardMode(true)
          }}
        >
          <AppstoreOutlined />
        </Button>
      </Space>
    )
  }

  const DropdownButton = () => {
    const menu = dropdownButton && dropdownButton.visible ? (
      <Menu>
        {dropdownButton.options.map((opt) => opt && (
          <Menu.Item
            disabled={opt?.disabled}
            onClick={() => opt.onClick()}
            key={opt.key}
          >
            {opt.label}
          </Menu.Item>
        ))}
      </Menu>
    ) : null

    return dropdownButton && dropdownButton.visible ? (
      <Dropdown
        disabled={dropdownButton.disabled}
        overlay={menu}
      >
        <Button
          type='primary'
        >
          Tindakan <DownOutlined />
        </Button>
      </Dropdown>
    ) : null
  };

  return (
    <div className="card-view-list-template">
      <Row justify="space-between">
        <Breadcrumb breadcrumbConf={props.breadcrumbConf} /> 
        {!props.isOnlyTable ? <ChangeModeButton /> : null}
      </Row>

      <div className="toolbar">
        <AddButton />
        <DropdownButton />
        <Col className='left-section'>
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            size={pagination.size}
            showSizeChanger={false}
            onChange={paginationOnChange}
          />
        </Col>
      </div>
      <Row style={{ display: isCardMode && !props.isOnlyTable ? '' : 'none' }} gutter={[10, 10]}>
        {dataSource?.length ? dataSource.map((element, i) => {
            if (!element.key || element.key === "search-input" || element.key === "empty-data") {
              return null
            }
            
            return (
              <Col key={i} xs={24} md={12} lg={8} xl={6}>
                <ComponentCardView 
                  id={element.key}
                  title={element.title} 
                  description={element.description} 
                  EditButtonCard={props.EditButtonCard}
                  DeleteButtonCard={props.DeleteButtonCard}
                  firstRow={element.first_row}
                  secondRow={element.second_row}
                  thirdRow={element.third_row}
                />
              </Col>
            )
          }) : null}
      </Row>
      <Table
        style={{ display: isCardMode && !props.isOnlyTable ? 'none' : '' }}
        className="no-expand-icon-table"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        rowSelection={tableRowSelectionActive ? rowSelection : null}
        onChange={tableOnChange}
        scroll={props.tableScroll ? props.tableScroll : false}
        onRow={(record) => ({
          onClick: () => onRow(record),
        })}
      />

      <BottomLoading active={loading} />
    </div>
  )
}