export function excelFileToFormDataFormat(excelFile: File) {
  return new Promise((resolve, rejects) => {
    const formData = new FormData()
    formData.append('file', excelFile)

    resolve({
      status: 'success',
        content: {
          file_name: excelFile.name,
          excel_file: formData,
          error_message: '',
        },
    })
  })
}