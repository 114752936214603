export const EmployeeWorkingScheduleConstant = {
  TABLE_HEADER: [
    { 
      title: 'Karyawan', 
      key: 'employee_name', 
      dataIndex: 'employee_name', 
      width: 240, 
      editable: false, 
    },
    { 
      title: 'Tanggal Efektif', 
      key: 'date_start_schedule_work', 
      dataIndex: 'date_start_schedule_work', 
      editable: true,
      type: 'date',
      width: 160,
      sorter: true,
    },  
    { 
      title: 'Tanggal Berakhir', 
      key: 'date_end_schedule_work', 
      dataIndex: 'date_end_schedule_work', 
      editable: true,
      type: 'date',
      width: 160,
      sorter: true, 
    }, 
    { 
      title: 'Jadwal Kerja', 
      key: 'nama_jadwal_kerja', 
      dataIndex: 'nama_jadwal_kerja', 
      width: 200, 
      editable: true,
      type: 'select', 
      options: [],
      sorter: true,
    },
    { title: 'Dibuat Oleh', key: 'create_name', dataIndex: 'create_name', editable: false, width: 200 },
    { title: 'Dibuat Pada', key: 'create_date_string', dataIndex: 'create_date_string', editable: false, width: 200, sorter: true },
    { title: 'Diubah Oleh', key: 'write_name', dataIndex: 'write_name', editable: false, width: 200 },
    { title: 'Diubah Pada', key: 'write_date_string', dataIndex: 'write_date_string', editable: false, width: 200, sorter: true },
  ], 
  SEARCH_COLUMN_KEY: [
    { key: 'key', componentType: "search-input" },
    { key: 'employee_name', componentType: "text" },
    { key: 'nama_jadwal_kerja', componentType: "text" },
  ],
  DEFAULT_EMPTY_DATA_SOURCE: [
    {
      key: null,
      employee_name: '',
      date_start_schedule_work: '',
      date_end_schedule_work: '',
      nama_jadwal_kerja: '',
      create_name: '',
      create_date: '',
      write_name: '',
      write_date: '',
    },
  ],
}