import { Card, Row, Col, Image } from 'antd';
import defaultAvatar from '../../../components/asset/avatar.jpeg';

export default function EmployeeSection(props) {
  const { data } = props;
  return (
    <Card style={{ minWidth: '100%', minHeight: '100%', background: '#F3F4FB' }}>
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <div>
            <Image
              width={70}
              height={70}
              preview={false}
              src={data.file_foto || defaultAvatar}
              style={{ borderRadius: 5 }}
            />
            <p style={{ fontSize: 14, color: '#80838B', marginTop: 20 }}>{data.date_now}</p>
            <p style={{ color: '#EA2F2A', fontSize: 25 }}>{data.employee_name}</p>
            <p style={{ fontSize: 16 }}>
              Role - <strong>{data.job_name}</strong>
            </p>
            <p style={{ fontSize: 16 }}>
              Location - <strong>{data.location}</strong>
            </p>
          </div>
        </Col>
        <Col span={16}>
          <Row gutter={[12, 12]}>
            <Col span={8}>
              <Card title="Jam Kerja Hari ini" style={{ minWidth: '100%', minHeight: '100%' }}>
                <p style={{ fontSize: 20, fontWeight: 'bolder', lineHeight: '1em' }}>{data.schedule_work}</p>
              </Card>
            </Col>
            <Col span={8}>
              <Card title="Absen Masuk" style={{ minWidth: '100%', minHeight: '100%' }}>
                <Row justify={'space-between'}>
                  <p>Check in: </p>
                  <p style={{ fontSize: 14, fontWeight: 'bolder' }}>{data.check_in}</p>
                </Row>
                <Row justify={'space-between'}>
                  <p>Check out: </p>
                  <p style={{ fontSize: 14, fontWeight: 'bolder' }}>{data.check_out}</p>
                </Row>
              </Card>
            </Col>
            <Col span={8}>
              <Card title="Sisa Cuti" style={{ minWidth: '100%', minHeight: '100%' }}>
                <p style={{ fontSize: 20, fontWeight: 'bolder' }}>{data.leave_plafon}</p>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}
