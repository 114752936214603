export const TemplateHeader = [
  'Nama Jadwal Kerja',
  'Toleransi Kehadiran (Menit)',
  'Sanksi Kehadiran (Menit)',
  'Hari',
  'Jam Mulai',
  'Jam Selesai',
  'Durasi Istirahat',
];

export const MasterWorkingScheduleTableColumns = [
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: null,
  },
  {
    title: 'Nama Jadwal Kerja',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Toleransi Kehadiran',
    dataIndex: 'tolerance_absent',
    key: 'tolerance_absent',
  },
  {
    title: 'Hari',
    dataIndex: 'day',
    key: 'day',
  },
  {
    title: 'Jam Mulai',
    dataIndex: 'start_time',
    key: 'start_time',
  },
  {
    title: 'Jam Selesai',
    dataIndex: 'end_time',
    key: 'end_time',
  },
  {
    title: 'Durasi Istirahat',
    dataIndex: 'break_time',
    key: 'break_time',
  },
  {
    title: 'Pesan Error',
    dataIndex: 'error_message',
    key: 'error_message',
    render: null,
  },
];

export const mockData = [
  {
    status: true,
    name: 'Jadwal 1',
    tolerance_absent: 1,
    sanctions_absent: 1,
    day: 'Senin',
    start_time: 12,
    end_time: 12,
    break_time: 11,
    error_message: 'tada',
  },
  {
    status: false,
    name: 'Jadwal 2',
    tolerance_absent: 1,
    sanctions_absent: 1,
    day: 'Selasa',
    start_time: 12,
    end_time: 12,
    break_time: 11,
    error_message: '...',
  },
];
