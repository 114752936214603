import getEmployeeMe from "../../../../../@core/interaction/employee/GetEmployeeMe.interaction";
import Constant from "../../../../../config/Constants";
import API from "../../../../../services/API.service";

export default class AddMySubmissionOvertimeUsecase {
  getEmployeeMe() {
    return getEmployeeMe().then((res: any) => {
      return res.data.content
    })
  }

  save(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/overtime/request"
    return API.post(URL, body)
  }
}