import { Form, Tabs } from "antd"
import Breadcrumb from "../../../../../../components/breadcrumb/Breadcrumb"
import BottomLoading from "../../../../../../components/bottom-loading/BottomLoading"
import { AddMasterHolidayTemplatePropsType } from "./AddMasterHolidayTemplate.interface";
import ToolbarButtonDetailTemplate from "../../../../component/toolbar-button-detail-template/ToolbarButtonDetailTemplate";
import { MemoizeHistoryTabs } from "../../../../component/history-payroll-period-tabs/HistoryPayrollPeriodTabs";
import FormBuilder from "antd-form-builder";
import moment from "moment";
import React from "react";

export default function AddMasterHolidayTemplate(props: AddMasterHolidayTemplatePropsType) {
  const [form] = Form.useForm()
  const [, updateState] = React.useState<any>(); const forceUpdate = React.useCallback(() => updateState({}), []);

  const onFinish = (values) => {
    if (props.onFinish) {
      props.onFinish(form.getFieldsValue());
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (props.onFinishFailed) {
      props.onFinishFailed(form.getFieldsValue());
    }
  };

  const onFieldsChange = (changedFields) => {
    if (changedFields.length === 1) {
      const fieldName = changedFields[0].name[0];
      
      const startDate = form.getFieldsValue().start_date;
      const endDate = form.getFieldsValue().end_date;

      switch (fieldName) {
        default:
          break;
        case 'start_date':
          processDate(startDate, endDate);
          break;
        case 'end_date':
          processDate(startDate, endDate);
          break;
      }
    }
  };

  const disabledStartDate = (current) => {
    const end_date = form.getFieldsValue().end_date
    let date_before = new Date()
    date_before.setDate(date_before.getDate() - 1);

    let date_after

    if (end_date) {
      date_after = moment(end_date).toDate(); 
    }
    
    return current && current.valueOf() > date_after;
  };

  const disabledEndDate = (current) => {
    const start_date = form.getFieldsValue().start_date
    let date

    if (start_date) {
      date = moment(start_date).toDate(); 
    } else {
      date = new Date();
      date.setDate(date.getDate() - 1);
    }
    
    return current && current.valueOf() < date;
  };

  const meta = {
    formItemLayout: { wrapperCol: { span: 8 } },
    fields: [
      {
        key: 'name',
        label: 'Nama Hari Libur',
        placeholder: 'Ketik disini...',
        viewMode: props.viewMode,
        initialValue: props.detailDataSource?.holidayName,
        rules: [{ required: true, whitespace: true, message: 'Nama Hari Libur harus diisi', }]
      },
      {
        key: 'start_date',
        widget: 'date-picker',
        label: 'Dari',
        placeholder: 'Pilih tanggal...',
        viewMode: props.viewMode,
        initialValue: formatDate(props.detailDataSource?.startDate),
        rules: [{ required: true, message: 'Mohon Lengkapi kolom ini', }],
        widgetProps: { disabledDate: disabledStartDate }
      },
      {
        key: 'end_date',
        widget: 'date-picker',
        label: 'Sampai',
        placeholder: 'Pilih tanggal...',
        viewMode: props.viewMode,
        initialValue: formatDate(props.detailDataSource?.endDate),
        rules: [{ required: true, message: 'Mohon Lengkapi kolom ini', }],
        widgetProps: { disabledDate: disabledEndDate }
      },
      {
        key: 'holiday_length',
        label: 'Lama Libur',
        placeholder: 'Ketik disini...',
        viewMode: true,
        initialValue: props.detailDataSource?.holidayLength || "",
      },
    ]
  }

  function formatDate(date) {
    return date ? props.viewMode ? moment(date)?.format("YYYY-MM-DD") : moment(date) : null
  }

  function processDate(startDate, endDate) {
    if (startDate && endDate) {
      const start_date = new Date(startDate).setHours(0, 0, 0, 0);
      const end_date = new Date(endDate).setHours(0, 0, 0, 0);
      const result = moment(end_date).diff(moment(start_date), 'days');
      
      form.setFieldValue('holiday_length', result + 1 + " Hari")
      forceUpdate()
    }
  }

  return (
    <div id="add-salary-component" style={{ height: 'calc(100vh - 100px)' }}>
      <Breadcrumb breadcrumbConf={props.breadcrumbConf} />

      <Form
        name="basic"
        labelCol={{ span: 4 }}
        layout="horizontal"
        labelAlign="left"
        form={form}
        colon={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={onFieldsChange}
      >
        <ToolbarButtonDetailTemplate
          submitButton={props.submitButton}
          firstActionButton={props.firstActionButton}
          secondActionButton={props.secondActionButton}
          thirdActionButton={props.thirdActionButton}
          fourthActionButton={props.fourthActionButton}
          dropdownActionButton={props.dropdownActionButton}
        />

        <div className="scrollable-content">
          <div className="form-wrapper">
            <FormBuilder form={form} meta={meta} />
            {props.historyId ? (
              <div className="tabs-wrapper">
                <Tabs defaultActiveKey="1" type="card">
                  <Tabs.TabPane tab="History" key="1">
                    <MemoizeHistoryTabs history_id={props.historyId} />
                  </Tabs.TabPane>
                </Tabs>
              </div>
            ) : null}
          </div>
        </div>
      </Form>

      <BottomLoading active={props.isLoading} />
    </div>
  )
}