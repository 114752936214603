import { Button, Form } from "antd"
import Breadcrumb from "../../../../../components/breadcrumb/Breadcrumb";
import BottomLoading from "../../../../../components/bottom-loading/BottomLoading";
import { LeaveUsageReportTemplatePropsType } from "./LeaveUsageReportTemplate.interface";
import "./LeaveUsageReportTemplate.less"
import FormBuilder from "antd-form-builder";
import React from "react";

export default function LeaveUsageReportTemplate(props: LeaveUsageReportTemplatePropsType) {
  const [form] = Form.useForm()
  const [, updateState] = React.useState<any>(); const forceUpdate = React.useCallback(() => updateState({}), []);

  const onFinish = (values) => {
    if (props.onFinish) {
      props.onFinish(form.getFieldsValue());
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (props.onFinishFailed) {
      props.onFinishFailed(form.getFieldsValue());
    }
  };

  const onFieldsChange = (changedFields) => {
    if (props.onFieldChange) {
      props.onFieldChange(changedFields, form);
    }
  };

  const SubmitButton = () => {
    return (
      <Form.Item style={{ marginBottom: '0px' }}>
        <Button
          type="primary"
          htmlType="submit"
          disabled={props.isLoading}
        >
          TAMPILKAN
        </Button>
      </Form.Item>
    )
  };

  const meta = {
    columns: 1,
    fields: [
      {
        key: 'job',
        label: 'Jabatan',
        widget: 'radio-group',
        forwardRef: true,
        disabled: props.isLoading,
        options: [
          { value: 'all', label: 'Tampilkan Semua Jabatan' },
          { value: 'select', label: 'Tampilkan Jabatan Tertentu' },
        ],
        widgetProps: {
          direction: 'vertical',
          mode: '',
          filterOption: null
        },
        initialValue: 'all',
        formItemLayout: { labelCol: { span: 3 }, wrapperCol: { span: 8 } },
      },
      {
        key: 'employee',
        label: 'Karyawan',
        widget: 'radio-group',
        forwardRef: true,
        disabled: props.isLoading,
        options: [
          { value: 'all', label: 'Tampilkan Semua Karyawan' },
          { value: 'select', label: 'Tampilkan Karyawan Tertentu' },
        ],
        widgetProps: {
          direction: 'vertical',
        },
        initialValue: 'all',
        formItemLayout: { labelCol: { span: 3 }, wrapperCol: { span: 8 } },
      },
      {
        key: 'leave_config',
        label: 'Konfigurasi Cuti',
        widget: 'radio-group',
        forwardRef: true,
        disabled: props.isLoading,
        options: [
          { value: 'all', label: 'Tampilkan Semua Konfigurasi Cuti' },
          { value: 'select', label: 'Tampilkan Konfigurasi Cuti Tertentu' },
        ],
        initialValue: 'all',
        formItemLayout: { labelCol: { span: 3 }, wrapperCol: { span: 8 } },
      },
      {
        key: 'leave_data',
        label: 'Data Cuti',
        widget: 'radio-group',
        forwardRef: true,
        disabled: props.isLoading,
        options: [
          { value: 'aktif', label: 'Aktif' },
          { value: 'non-aktif', label: 'Kadaluarsa' },
          { value: 'all', label: 'Semua' },
        ],
        initialValue: 'aktif',
        formItemLayout: { labelCol: { span: 3 }, wrapperCol: { span: 1 } },
      },
    ]
  }

  let newMeta = meta
  if (form.getFieldValue('job') === 'select') {
    const objIndex = newMeta.fields.findIndex((obj => obj.key === 'job'));
    newMeta.fields.splice(objIndex + 1, 0, {
      key: 'list_job',
      label: ' ',
      widget: 'select',
      forwardRef: true,
      disabled: props.isLoading,
      options: props.jobList,
      initialValue: undefined,
      widgetProps: {
        direction: '',
        mode: 'multiple',
        filterOption: (input:any, option:any) => 
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      },
      formItemLayout: { labelCol: { span: 3 }, wrapperCol: { span: 10 } },
    });
  }
  if (form.getFieldValue('employee') === 'select') {
    const objIndex = newMeta.fields.findIndex((obj => obj.key === 'employee'));
    newMeta.fields.splice(objIndex + 1, 0, {
      key: 'list_employee',
      label: ' ',
      widget: 'select',
      forwardRef: true,
      disabled: props.isLoading,
      options: props.employeeList,
      initialValue: undefined,
      widgetProps: {
        direction: '',
        mode: 'multiple',
        filterOption: (input:any, option:any) => 
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      },
      formItemLayout: { labelCol: { span: 3 }, wrapperCol: { span: 10 } },
    });
  }
  if (form.getFieldValue('leave_config') === 'select') {
    const objIndex = newMeta.fields.findIndex((obj => obj.key === 'leave_config'));
    newMeta.fields.splice(objIndex + 1, 0, {
      key: 'list_config',
      label: ' ',
      widget: 'select',
      forwardRef: true,
      disabled: props.isLoading,
      options: props.leaveConfigList,
      initialValue: undefined,
      widgetProps: {
        direction: '',
        mode: 'multiple',
        filterOption: (input:any, option:any) => 
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      },
      formItemLayout: { labelCol: { span: 3 }, wrapperCol: { span: 10 } },
    });
  }

  return (
    <div id="add-salary-component" style={{ height: 'calc(100vh - 100px)' }}>
      <Breadcrumb breadcrumbConf={props.breadcrumbConf} />

      <Form
        name="basic"
        layout="horizontal"
        form={form}
        labelAlign="left"
        colon={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={onFieldsChange}
        onValuesChange={forceUpdate}
      >
        <div className="scrollable-content">
          <div className="form-wrapper">
            <FormBuilder form={form} meta={meta} />
          </div>
          
          <SubmitButton />
        </div>
      </Form>

      <BottomLoading active={props.isLoading} />
    </div>
  )
}