import Constant from "../../../../config/Constants";
import API from "../../../../services/API.service";

export default class ImportLeaveCorrectionUseCase {
  validateToServer(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + '/v3/leave/corrections/validate';
    return API.post(URL, body);
  }

  saveSchedule(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + '/v3/leave/corrections/upload';
    return API.post(URL, body);
  }

  downloadTemplate() {
    const URL = Constant.URL_BASE_ATTENDANCE + '/v3/leave/corrections/template';
    return API.get(URL);
  }
}