import { Card, Space } from 'antd';
import Col from 'antd/es/grid/col';
import { DeleteOutlined, EditOutlined, ApartmentOutlined } from '@ant-design/icons';
import { CardComponentPropsType } from './ComponentCardView.interface';
import './ComponentCardView.less';

export default function ComponentCardView(props: CardComponentPropsType) {
  const Header = () => {
    return (
      <Col>
        <span style={{ fontWeight: 'bold', fontSize: 14, color: 'grey' }}>
          {props.title}
        </span>
        <br />
        <span style={{ fontWeight: 'bold', fontSize: 14, color: 'red' }}>
          {props.description}
        </span>
      </Col>
    );
  };

  const EditButton = () => {
    const onClick = () => {
      if (props.EditButtonCard.onClick) {
        props.EditButtonCard.onClick(props.id);
      }
    };

    return props.EditButtonCard.visible ? (
      <>
        <EditOutlined disabled={props.EditButtonCard.disabled} onClick={() => onClick()} />
      </>
    ) : null;
  };

  const DeleteButton = () => {
    const onClick = () => {
      if (props.DeleteButtonCard.onClick) {
        props.DeleteButtonCard.onClick(props.id);
      }
    };

    return props.DeleteButtonCard.visible ? (
      <>
        <DeleteOutlined disabled={props.DeleteButtonCard.disabled} onClick={() => onClick()} />
      </>
    ) : null;
  };

  return (
    <Card
      title={<Header />}
      style={{ border: '1px solid black', borderRadius: '4px' }}
      extra={
        <Space>
          <EditButton />
          <DeleteButton />
        </Space>
      }
    >
      <div style={{ fontWeight: 'bold', margin: '-15px 0px -20px 10px' }}>
        <p>
          <ApartmentOutlined /> {props.firstRow}
        </p>
        <p>Jumlah Karyawan {props.secondRow !== '(0)' ? props.secondRow : '(-)'}</p>
        <i>Terakhir Diubah {props.thirdRow}</i>
      </div>
    </Card>
  );
}