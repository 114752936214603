import { ToolbarDetailActionButton } from "../../components/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface";
import { useEffect, useState } from "react";
import ToastService from "../../../../../services/Toast.service";
import LocalNotificationService from "../../../../../services/LocalNotification.service";
import AddMasterLocationUsecase from "./AddMasterLocation.usecase";
import AddMasterLocationTemplate from "../template/AddMasterLocationTemplate";

const usecase = new AddMasterLocationUsecase()
const toastService = new ToastService()
const localNotificationService = new LocalNotificationService();

export default function AddMasterLocation() {
  const [regionList, setRegionList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    init()
  }, [])

  async function init() {
    const listRegion = await usecase.getRegionList()
    setRegionList([...listRegion])
  }

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Master Lokasi',
        href: "/v2/app/hr-employee/master-location",
      },
      {
        clickable: false,
        displayPathName: 'Baru',
        href: "/v2/app/hr-employee/master-location/new/",
      }
    ],
  };

  const submitButton = { active: true, disabled: false };
  const firstActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: false,
    onClick: () => {
      const back = window.confirm("Anda Ingin Kembali")
      if (back) {
        window.location.href = "/v2/app/hr-employee/master-location"
      }
    },
  };

  const onFinish = (e) => {
    setIsLoading(true)
    const body = {
      name: e.location?.trim(),
      parent_id: e.region,
      as_attendance: !!e.checkbox_attendance_location,
      ...e.select_attendance_location, 
      radius: Number(e.radius),
      active: true,
    }

    usecase.create(body).then(
      (res) => {
        const data = res.data
        if (data.code === 200) {
          const message = data.message
          localNotificationService.openSuccess(message, '', 1)
        }
        setTimeout(() => {
          window.location.href = "/v2/app/hr-employee/master-location"
        }, 1500)
      }
    ).catch((err) => {
      toastService.error(err)
      setIsLoading(false)
    })
  }
  
  return (
    <div className="add-employee-location">
      <AddMasterLocationTemplate
        breadcrumbConf={breadcrumbConf}
        dropdownActionButton={{ visible: false }}
        firstActionButton={firstActionButton}
        secondActionButton={{ active: false }}
        thirdActionButton={{ active: false }}
        fourthActionButton={{ active: false }}
        listRegion={regionList}
        onFinish={onFinish}
        submitButton={submitButton}
        isLoading={isLoading}
        historyId={null}
      />
    </div>
  )
}