import moment from "moment";

export function getNewsType(type) {
  const collection = {
    employee: 'Karyawan Tertentu',
    all_employee: 'Semua Karyawan',
    job: 'Jabatan Tertentu',
  };

  return collection[type] || '-';
}

export function newsListMapper(response) {
    const content = response.content ? response.content : null;
    let contentMap = null;
  
    if (content) {
      contentMap = content.map((data) => {
        const item = {
          id: data.id,
          key: data.id,
          name: data.name,
          send_date: moment(data.start_date).format('DD-MM-YYYY'),
          expired_date: moment(data.expired_date).format('DD-MM-YYYY'),
          receiver: getNewsType(data.news_type),
          status: data.status_news,
          create_name: data.create_name,
          create_date: moment(data.view_create_date).format('DD-MM-YYYY HH:mm'),
          write_name: data.write_name,
          write_date: moment(data.view_write_date).format('DD-MM-YYYY HH:mm'),
        };
        return item;
      });
    }
  
    return {
      content: contentMap,
      metaData: response.metadata,
    };
  }
  