// import AttendanceUseCase from '../../../../../../@core/use-case/attendance/Attendance.usecase';
import { recapAttendanceListMapper } from '../../../../../../@core/mapper/attendance/RecapAttendanceRequestList.mapper';
import { getRecapAttendance } from '../../../../../../@core/interaction/attendance/GetRecapAttendance.interaction';
import exportRecapAttendance from '../../../../../../@core/interaction/attendance/ExportRecapAttendance.interaction'

export default class MyRecapAttendance {

  getRecapAttendance(params, hrefPath) {
    return getRecapAttendance(params).then((res: any) =>
      recapAttendanceListMapper(res.data, hrefPath),
    );
  }

  exportRecapAttendance(params) {
    return exportRecapAttendance(params);
  }

}
