import { useEffect, useState } from 'react';
import CardSubmissionListComponent from './components/CardSubmissionListComponent';
import { DashboardManagerConstant } from './DashboardManager.constant';
import {
  ApprovalState,
  DataSourceSubmisionType,
  KeySubmission,
} from './components/CardSubmissionListComponent.interface';
import { DashboardManagerUsecase } from './DashboardManager.usecase';
import { Modal, Skeleton } from 'antd';
import ToastService from '../../../services/Toast.service';
import LocalNotificationService from '../../../services/LocalNotification.service';
import { GetLeavesDTO } from '../../../@core/interface/leave/GetLeaves.interface';
import moment from 'moment';
import { ToolbarDetailActionButton } from '../../hr/component/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface';
import { UpdateLeaveStateDTO } from '../../../@core/interface/leave/UpdateLeaveState.interface';
import ToolbarButtonDetailTemplate from '../../hr/employee/components/toolbar-button-detail-template/ToolbarButtonDetailTemplate';
import BottomLoading from '../../../components/bottom-loading/BottomLoading';

const constant = DashboardManagerConstant;
const usecase = new DashboardManagerUsecase();
const toastService = new ToastService();
const localNotificationService = new LocalNotificationService();

let leave_total = 0;
let claim_total = 0;
let loan_total = 0;
let overtime_total = 0;
let is_admin = false;
let is_manager = false;
let is_employee = false;

export default function DashboardManager() {
  const [leaveData, setLeaveData] = useState<DataSourceSubmisionType[]>();
  const [claimData, setClaimData] = useState<DataSourceSubmisionType[]>();
  const [loanData, setLoanData] = useState<DataSourceSubmisionType[]>();
  const [overtimeData, setOvertimeData] = useState<DataSourceSubmisionType[]>();
  const [visibleWarningModal, setVisibleWarningModal] = useState(false);
  const [disabledModalButton, setDisabledModalButton] = useState(false)
  const [approveProps, setApproveProps] = useState({
    state: null,
    id: null,
    key: null,
    submissionState: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const approval_params: GetLeavesDTO = {
    page: 1,
    limit: 3,
    with_meta: true,
  };

  const currentMenu = localStorage.getItem('menu');
    if (currentMenu) {
      const menu = JSON.parse(currentMenu);
      is_admin = menu.find(item => item.id === 2)?.isAdmin ? true : false
      is_manager = menu.find(item => item.id === 2)?.isManager ? true : false
      is_employee = menu.find(item => item.id === 2)?.isEmployee ? true : false
    }

  async function init() {
    setIsLoading(true);

    await Promise.all([
      getLeaveList(approval_params),
      getClaimList(approval_params),
      getLoanList(approval_params),
      getOvertimeList(approval_params),
    ]);
    setIsLoading(false);
  }

  async function getLeaveList(params) {
    const approval_leave: any = await usecase.getApprovalLeave(params);
    if (approval_leave?.data) {
      const content = approval_leave.data?.content || [];
      const metadata = approval_leave.data?.metadata || 0;

      setLeaveData(
        content.map((leave): DataSourceSubmisionType => {
          const submission_date = `${formatSubmissionDate(
            leave.date_start,
          )} - ${formatSubmissionDate(leave.date_end)}`;

          return {
            key: leave.id,
            title: leave.employee_name,
            description: leave.nik,
            submission_date,
            submission_number: leave.name,
            submission_type: leave.leave_type,
            additional: `${leave.duration} hari`,
            url: leave.file_foto,
            state: leave.lbl_state,
          };
        }),
      );
      leave_total = metadata?.total_records;
    }
  }

  async function getClaimList(params) {
    const approval_claim = await usecase.getApprovalClaim(params);
    if (approval_claim?.data) {
      const content = approval_claim.data?.content || [];
      const metadata = approval_claim.data?.metadata || 0;

      setClaimData(
        content.map((claim): DataSourceSubmisionType => {
          return {
            key: claim.id,
            title: claim.employee_name,
            description: claim.nik,
            submission_date: formatSubmissionDate(claim.date_claim),
            submission_number: claim.name,
            submission_type: claim.claim_type || claim.claim_config,
            additional: claim.total_value?.toLocaleString(),
            url: claim.file_foto,
          };
        }),
      );
      claim_total = metadata?.total_records;
    }
  }

  async function getLoanList(params) {
    const approval_loan = await usecase.getApprovalLoan(params);
    if (approval_loan?.data) {
      const content = approval_loan.data?.content || [];
      const metadata = approval_loan.data?.metadata || 0;

      setLoanData(
        content.map((loan): DataSourceSubmisionType => {
          return {
            key: loan.id,
            title: loan.employee_name,
            description: loan.nik,
            submission_date: formatSubmissionDate(loan.date_request),
            submission_number: loan.name,
            submission_type: loan.loan_type,
            additional: loan.loan_value_requested?.toLocaleString(),
            url: loan.file_foto,
          };
        }),
      );
      loan_total = metadata?.total_records;
    }
  }

  async function getOvertimeList(params) {
    const approval_overtime = await usecase.getApprovalOvertime(params);
    if (approval_overtime?.data) {
      const content = approval_overtime.data?.content || [];
      const metadata = approval_overtime.data?.metadata || 0;

      setOvertimeData(
        content.map((ovt): DataSourceSubmisionType => {
          return {
            key: ovt.id,
            title: ovt.emp_name,
            description: ovt.nik,
            submission_date: formatSubmissionDate(ovt.date_request),
            submission_number: formatDateOvertime(ovt.date_start),
            submission_type: formatDateOvertime(ovt.date_end),
            additional: ovt.time_total,
            url: ovt.file_foto,
          };
        }),
      );
      overtime_total = metadata?.total_records;
    }
  }

  function formatDateOvertime(dateString: string) {
    if (dateString) {
      return moment(dateString).format('HH:mm:ss');
    }

    return '';
  }

  function formatSubmissionDate(dateString: string) {
    if (dateString) {
      return moment(dateString).format('DD MMM YYYY');
    }

    return '';
  }

  const adminButton: ToolbarDetailActionButton = {
    active: is_admin,
    disabled: false,
    label: 'Admin',
    onClick: () => {
      window.location.href = '/v2/app/admin-dashboard';
    },
  };

  const managerButton: ToolbarDetailActionButton = {
    active: is_manager,
    disabled: true,
    label: 'Daftar Pengajuan',
  };

  const employeeButton: ToolbarDetailActionButton = {
    active: is_employee,
    disabled: false,
    label: 'Personal Detail',
    onClick: () => {
      window.location.href = '/v2/app/employee-dashboard';
    },
  };

  const approveData = async () => {
    switch (approveProps.key) {
      case 'leave':
        onApproveLeave(approveProps.id, approveProps.submissionState, approveProps.state);
        break;
      case 'claim':
        onApproveClaim(approveProps.state, approveProps.id);
        break;
      case 'loan':
        onApproveLoan(approveProps.state, approveProps.id);
        break;
      case 'overtime':
        onApproveOvertime(approveProps.state, approveProps.id);
        break;
    }
  };

  function onApproveLeave(leaveId, stepState, st) {
    if (st) {
      approveToEndPoint(leaveId, stepState);
    } else {
      rejectToEndPoint(leaveId);
    }
  }

  function approveToEndPoint(leaveId, stepState) {
    setDisabledModalButton(true)
    let state: any = '';

    switch (stepState) {
      default:
        state = '';
        console.error(':: step state not available');
        break;
      case 'Pengajuan':
        state = 'approve1';
        break;
      case 'Persetujuan Ke-2':
        state = 'approve2';
        break;
    }

    if (state) {
      const params: UpdateLeaveStateDTO = {
        leaveId: [leaveId],
        leaveState: state,
      };
      usecase.approveLeave(params).then(
        async (res) => {
          localNotificationService.openSuccess('Pengajuan Cuti telah disetujui', '', 3);
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        },
        (err) => {
          setDisabledModalButton(false);
        },
      );
    }
  }

  function rejectToEndPoint(leaveId) {
    setDisabledModalButton(true)
    usecase.rejectLeave([leaveId]).then(
      (res) => {
        localNotificationService.openSuccess('Pengajuan Cuti telah ditolak', '', 1);
          setTimeout(() => {
            window.location.reload()
          }, 1000)
      },
      (err) => {
        setDisabledModalButton(false);
      },
    );
  }

  const onApproveClaim = (state, id) => {
    setDisabledModalButton(true)
    const body = { state };

    usecase
      .approveClaim(body, id)
      .then(async (res) => {
        localNotificationService.openSuccess(`Pengajuan Klaim telah ${state === 'reject' ? 'ditolak' : 'disetujui'}`, '', 1);
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      })
      .catch((err) => {
        setDisabledModalButton(false);
        toastService.error(err);
      });
  };

  const onApproveLoan = (state, id) => {
    setDisabledModalButton(true)
    const body = { ids: [id], state };

    usecase
      .approveLoan(body)
      .then(async (res) => {
        localNotificationService.openSuccess(`Pengajuan Pinjaman telah ${state === 'reject' ? 'ditolak' : 'disetujui'}`, '', 1);
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      })
      .catch((err) => {
        setDisabledModalButton(false);
        toastService.error(err);
      });
  };

  const onApproveOvertime = (state, id) => {
    setDisabledModalButton(true)
    const body = { id, state };

    usecase
      .approveOvertime(body)
      .then(async (res) => {
        localNotificationService.openSuccess(`Pengajuan Lembur telah ${state === 'reject' ? 'ditolak' : 'disetujui'}`, '', 1);
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      })
      .catch((err) => {
        setDisabledModalButton(false);
        toastService.error(err);
      });
  };

  const onClickApprove = (
    state: ApprovalState,
    id: string,
    key: KeySubmission,
    submissionState,
  ) => {
    setApproveProps({ state, id, key, submissionState });
    setVisibleWarningModal(true);
  };

  return (
    <div id="manager-dashboard-page">
      <Modal
        title={(approveProps.state === 'approve' ? 'Menyetujui' : 'Menolak') + ' Pengajuan'}
        cancelText="Tidak"
        okText="Ya"
        centered={true}
        visible={visibleWarningModal}
        cancelButtonProps={{ type: 'ghost', disabled: disabledModalButton }}
        onOk={approveData}
        okButtonProps={{ disabled: disabledModalButton }}
        onCancel={() => setVisibleWarningModal(false)}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p>
            Apakah anda yakin untuk
            {approveProps.state === 'approve' ? ' menyetujui ' : ' menolak '}
            pengajuan ini
          </p>
        </div>
      </Modal>
      <ToolbarButtonDetailTemplate
        submitButton={{ active: false }}
        firstActionButton={adminButton}
        secondActionButton={employeeButton}
        thirdActionButton={managerButton}
        fourthActionButton={{ active: false }}
        dropdownActionButton={{ visible: false }}
      />

      <div className="scrollable-content">
        {isLoading ? (
          <Skeleton loading={isLoading} active />
        ) : (
          <CardSubmissionListComponent
            disabledButton={disabledModalButton}
            submission_key="leave"
            onClick={onClickApprove}
            title="Pengajuan Cuti Karyawan"
            submission_total={leave_total}
            header={constant.TABLE_HEADER_LEAVE}
            dataSource={leaveData}
            href="/v2/app/hr-leave/employee-approval"
          />
        )}
        {isLoading ? (
          <Skeleton loading={isLoading} active />
        ) : (
          <CardSubmissionListComponent
            disabledButton={disabledModalButton}
            submission_key="claim"
            onClick={onClickApprove}
            title="Pengajuan Klaim"
            submission_total={claim_total}
            header={constant.TABLE_HEADER_CLAIM}
            dataSource={claimData}
            href="/v2/app/hr-claim/employee-approval-claim"
          />
        )}
        {isLoading ? (
          <Skeleton loading={isLoading} active />
        ) : (
          <CardSubmissionListComponent
            disabledButton={disabledModalButton}
            submission_key="loan"
            onClick={onClickApprove}
            title="Pengajuan Pinjaman"
            submission_total={loan_total}
            header={constant.TABLE_HEADER_LOAN}
            dataSource={loanData}
            href="/v2/app/hr-loan/approval-loan"
          />
        )}
        {isLoading ? (
          <Skeleton loading={isLoading} active />
        ) : (
          <CardSubmissionListComponent
            disabledButton={disabledModalButton}
            submission_key="overtime"
            onClick={onClickApprove}
            title="Pengajuan Lembur"
            submission_total={overtime_total}
            header={constant.TABLE_HEADER_OVERTIME}
            dataSource={overtimeData}
            href="/v2/app/hr-overtime/employee-approval-overtime"
          />
        )}
      </div>

      <BottomLoading active={isLoading} />
    </div>
  );
}
