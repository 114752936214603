export default class SharedService {
  createMockResponse(mockData, delay = 1500) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(mockData);
      }, delay);
    });
  }

  createPromise(procedure, data) {
    return new Promise((resolve, reject) => {
      if (procedure === 'resolve') {
        resolve(data);
      } else {
        reject(data);
      }
    });
  }

  backToOdooLink(url) {
    const hyperlinkElement = document.createElement('a');
    hyperlinkElement.href = url;

    document.body.appendChild(hyperlinkElement);
    hyperlinkElement.click();
    hyperlinkElement.parentNode.removeChild(hyperlinkElement);
  }
}
