import { useEffect, useState } from "react";
import { ToolbarDetailActionButton, ToolbarDetailDropdownButton } from "../../../component/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface";
import AddSubmissionOverTimeTemplate from "../../templates/submission-overtime-template/AddSubmissionOvertimeTemplate";
import DetailMySubmissionOvertimeUsecase from "./DetailMySubmissionOvertime.usecase";
import { DetailSubmissionOvertimeMapper } from "../../../../../@core/mapper/overtime/ListSubmissionOvertimeMapper";
import ToastService from "../../../../../services/Toast.service";
import LocalNotificationService from "../../../../../services/LocalNotification.service";

const usecase = new DetailMySubmissionOvertimeUsecase()
const toastService = new ToastService()
const localNotificationService = new LocalNotificationService()
const steps = [
  "Pengajuan", "Disetujui",
  "Persetujuan Ke-2", "Ditolak",
  "Batal", "Selesai"
]

export default function DetailMySubmissionOvertime({ match }) {
  const overtimeId = match.params.submissionId

  // state view
  const [isLoading, setIsLoading] = useState(false)
  const [detailDataSource, setDetailData] = useState<any>()

  useEffect(() => {
    init()
  }, [])

  function init() {
    setIsLoading(true)
    
    usecase.getDetail(overtimeId).then(
      (res) => {
        const content = res.data.content
        const result = DetailSubmissionOvertimeMapper(content)
        
        setDetailData({...result})
        setIsLoading(false)
      }
    ).catch(err => {
      setIsLoading(false)
    })
  }

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Pengajuan Lembur Saya',
        href: "/v2/app/hr-overtime/my-submission-overtime",
      },
      {
        clickable: false,
        displayPathName: detailDataSource?.submissionNumber,
        href: "/v2/app/hr-overtime/my-submission-overtime/new/",
      }
    ],
  };

  const submitButton = { active: false, disabled: isLoading };
  const firstActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: isLoading,
    onClick: () => {
      window.location.href = "/v2/app/hr-overtime/my-submission-overtime"
    },
  };

  const dropdownActionButton: ToolbarDetailDropdownButton = {
    disabled: false,
    visible: !!overtimeId && detailDataSource?.activeSteps === "Pengajuan",
    options: [
      {
        key: 'non-aktif', disabled: !detailDataSource?.status, label: 'Non-Aktifkan', onClick(procedure) {
          setIsLoading(true)
          const params = {
            ids: [overtimeId],
            menu: "saya",
            status: false
          }
          usecase.active(params).then(
            (res) => {
              const content = res.data.content
            if (content.code === 200) {
              const message = content.message.split("- ")[1]
              localNotificationService.openSuccess(message, '', 1)
            }
            setTimeout(() => {
              window.location.reload()
            }, 1500)
            }).catch(err => {
              setIsLoading(false)
              toastService.error(err)
            })
        },
      }
    ]
  }

  const onFieldChange = (e) => {
    if (e.length === 1) {
      const fieldName = e[0].name[0];

      switch (fieldName) {
        default:
          break;
      }
    }
  }

  const onFinish = (value) => {
    
  }

  return (
    <div className="add-my-submission-overtime">
      <AddSubmissionOverTimeTemplate
        breadcrumbConf={breadcrumbConf}
        isDetail={!!overtimeId}
        historyId={detailDataSource?.historyId}
        viewMode={true}
        steps={steps}
        dataSource={detailDataSource?.dataSource}
        activeSteps={detailDataSource?.activeSteps}
        approval1={detailDataSource?.approval1}
        approval2={detailDataSource?.approval2}
        employeeList={[]}
        isMySubmission={true}
        isLoading={isLoading}
        submitButton={submitButton}
        firstActionButton={firstActionButton}
        secondActionButton={{ active: false }}
        thirdActionButton={{ active: false }}
        fourthActionButton={{ active: false }}
        dropdownActionButton={dropdownActionButton}
        onFieldChange={onFieldChange}
        onFinish={onFinish}
      />
    </div>
  )
}