import Constant from "../../../../../config/Constants"
import API from "../../../../../services/API.service"

export default class AddMasterDepartmentUsecase {
  create(body) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/master-department/create"
    return API.post(URL, body)
  }

  getDept() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/master-department/card-view"
    return API.get(URL, { params: { is_active: true } }).then(res => {
      return res.data.content.map((opt) => {
        return { key: opt.id, label: opt.name, value: opt.id }
      })
    })
  }
}