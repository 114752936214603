import { useState, useEffect } from 'react';
import EmployeeLeaveDetailTemplate from '../../../templates/employee-leave-detail-template/EmployeeLeaveDetailTemplate';
import { LeaveDetailConstant } from '../../../../../../config/LeaveDetail.constant';
import { Modal } from 'antd';
import LocalNotificationService from '../../../../../../services/LocalNotification.service';
import { ToolbarLeaveDetailActionButton } from '../../../templates/employee-leave-detail-template/components/toolbar-leave-detail-template/ToolbarLeaveDetailTemplate.interface';
import HrEmployeeApprovalLeaveDetailUseCaseImpl from './HrEmployeeApprovalLeaveDetail.usecase.impl';
import { GetJobListDTO } from '../../../../../../@core/interface/job/GetJobList.interface';
import { GetLeaveDetailDTO } from '../../../../../../@core/interface/leave/GetLeaveDetail.interface';
import { UpdateLeaveStateDTO } from '../../../../../../@core/interface/leave/UpdateLeaveState.interface';
import { EmployeeLeaveDetailGeneralForm, EmployeeLeaveDetailUnfinishLeave } from '../../../templates/employee-leave-detail-template/components/employee-leave-detail-form/EmployeeLeaveDetailForm.interface';
import { GetLeavePlafonDTO } from '../../../../../../@core/interface/leave/GetLeavePlafon.interface';
import ModalApprovalLeave from './modal-approval-list/ModalApprovalLeave';

const useCaseImpl = new HrEmployeeApprovalLeaveDetailUseCaseImpl();
const localNotificationService = new LocalNotificationService();

let jobList = {};
let leaveId = 0;
let params
let employee_name
let employee_nik
let leave_config_emp_id: string

export default function HrEmployeeApprovalLeaveDetail({ match }) {
  leaveId = match.params.leaveId;

  const [visibleListModal, setVisibleListModal] = useState(false)
  let [employeeDetailForm, setEmployeeDetailForm] = useState(
    LeaveDetailConstant.INIT_EMPLOYEE_DETAIL_FORM,
  );
  let [stepState, setStepState] = useState();
  let [remainsDayOff, setRemainsDayOff] = useState({ active: true, value: '', editMode: true });
  let [unfinishLeave, setUnfinishLeave] = useState<EmployeeLeaveDetailUnfinishLeave>({
    active: true,
    value: '',
    editMode: false,
    showList: <a onClick={() => setVisibleListModal(true)} >Tampilkan</a>,
    rules: [{}],
  });
  let [availPlafon, setAvailPlafon] = useState<EmployeeLeaveDetailGeneralForm>({
    active: true,
    value: '',
    editMode: false,
    rules: [{}],
  })
  let [approvalData, setApprovalData] = useState(LeaveDetailConstant.INIT_APPROVAL_DATA);
  let [archiveImg, setArchiveImg] = useState(LeaveDetailConstant.INIT_ARCHIVE_IMAGE);
  let [isLoading, setIsLoading] = useState(true);
  let [visibleWarningModal, setVisibleWarningModal] = useState(false);
  let [breadcrumbConf, setBreadcrumbConf] = useState({
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Persetujuan Cuti Karyawan',
        href: '/v2/app/hr-leave/employee-approval',
      },
      { clickable: false, displayPathName: '', href: '/v2/app/hr-leave/employee-approval/new' },
    ],
  });
  const approveButton: ToolbarLeaveDetailActionButton = {
    label: 'SETUJUI',
    type: 'primary',
    active: true,
    onClick: onClickApprove,
    disabled: isLoading,
  };
  const rejectButton: ToolbarLeaveDetailActionButton = {
    label: 'TOLAK',
    type: 'ghost',
    active: true,
    onClick: onClickReject,
    disabled: isLoading,
  };

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {
    setIsLoading(true);
    const getJobListParams: GetJobListDTO = {
      limit: 300,
      sort: 'id|asc',
    };
    jobList = await useCaseImpl.getJobList(getJobListParams);

    const getLeaveDetailParams: GetLeaveDetailDTO = {
      leaveId,
      jobList,
    };
    const employeeDetail: any = await useCaseImpl.getLeaveDetail(getLeaveDetailParams);
    
    const { leave_type_id, employee_id, nik, employee } = employeeDetail
    let leavePlafon
    let leaveRem
    let leaveQueue
    if (!!leave_type_id) {
      const { 
        leave_rem_plafon, 
        leave_queue,
        leave_rem 
      } = await setPlafon(leave_type_id, employee_id)

      leavePlafon = Number(leave_rem_plafon) >= 0 ?
      `${leave_rem_plafon} Hari` : leave_rem_plafon
      
      leaveRem = Number(leave_rem) >= 0 ?
      `${leave_rem} Hari` : leave_rem
    
      leaveQueue = `${leave_queue} Hari`
    } else {
      leavePlafon = "N/A"
      leaveRem = "N/A"
      leaveQueue = "N/A"
    }
    params = { leave_config_id: leave_type_id, employee_id }

    setStepState(employeeDetail.stepState);

    const copyDetailForm = JSON.parse(JSON.stringify(employeeDetail.leaveDetailForm))
    copyDetailForm.remainsDayOff = { value: leavePlafon }
    setEmployeeDetailForm(JSON.parse(JSON.stringify(copyDetailForm)));

    remainsDayOff.value = leavePlafon;
    setRemainsDayOff(JSON.parse(JSON.stringify(remainsDayOff)));

    setUnfinishLeave(() => {
      return { ...unfinishLeave, value: leaveQueue }
    })

    availPlafon.value = leaveRem
    setAvailPlafon(JSON.parse(JSON.stringify(availPlafon)))

    setArchiveImg(JSON.parse(JSON.stringify(employeeDetail.archiveImg)));
    leave_config_emp_id = employeeDetail.leave_config_emp_id
    employee_name = employee
    employee_nik = nik

    const tempBreadcrumbConf = JSON.parse(JSON.stringify(breadcrumbConf));
    tempBreadcrumbConf.manualData[1].displayPathName = employeeDetail.name;
    setBreadcrumbConf(tempBreadcrumbConf);

    const approval = await useCaseImpl.getApprovalLeave(leaveId, jobList);
    setApprovalData(JSON.parse(JSON.stringify(approval)));

    setIsLoading(false);
  }

  async function setPlafon(leave_config_id, employee_id) {
    setIsLoading(true)
    let result
    const params: GetLeavePlafonDTO = {
      leave_config_id,
      employee_id,
      leave_config_emp_id
    }

    await useCaseImpl.getLeavePlafon(params).then(
      (res) => {
        result = res.data.content
        setIsLoading(false)
      }, (err) => {
        setIsLoading(false);
      }
    )

    return result
  }

  function onClickApprove() {
    setIsLoading(true);

    let state: any = '';

    switch (stepState) {
      default:
        state = '';
        console.error(':: step state not available');
        break;
      case 'Pengajuan':
        state = 'approve1';
        break;
      case 'Persetujuan Ke-2':
        state = 'approve2';
        break;
    }

    if (state) {
      const params: UpdateLeaveStateDTO = {
        leaveId: [leaveId],
        leaveState: state,
      };
      useCaseImpl.approveLeave(params).then(
        (res) => {
          localNotificationService.openSuccess('Pengajuan Cuti telah disetujui', '', 3);
          setTimeout(() => {
            window.location.href = "/v2/app/hr-leave/employee-approval"
          }, 1000)
        },
        (err) => {
          setIsLoading(false);
        },
      );
    }
  }

  function onClickReject() {
    setVisibleWarningModal(true);
  }

  function rejectToEndPoint() {
    setIsLoading(true);

    useCaseImpl.rejectLeave([leaveId]).then(
      (res) => {
        setVisibleWarningModal(false);
        localNotificationService.openSuccess('Pengajuan Cuti telah ditolak', '', 3);
        setTimeout(() => {
          window.location.href = "/v2/app/hr-leave/employee-approval"
        }, 1000)
      },
      (err) => {
        setIsLoading(false);
        setVisibleWarningModal(false);
      },
    );
  }

  function actionHideModal() {
    setVisibleListModal(false);
  }

  return (
    <div
      className="employee-approval-leave-detail"
      style={{ position: 'relative', height: 'calc(100vh - 100px)' }}
    >
      <Modal
        title="Tolak Pengajuan"
        cancelText="BATALKAN"
        visible={visibleWarningModal}
        cancelButtonProps={{ type: 'ghost' }}
        onOk={rejectToEndPoint}
        onCancel={() => setVisibleWarningModal(false)}
      >
        <p>Apakah Anda yakin ingin untuk menolak pengajuan cuti ini?</p>
      </Modal>
      <ModalApprovalLeave
        actionHideModal={actionHideModal}
        visibleModal={visibleListModal}
        dataSource={{ 
          nik: employee_nik, 
          name: employee_name, 
          params,
        }} 
      />
      <EmployeeLeaveDetailTemplate
        breadcrumbConf={breadcrumbConf}
        submitButton={{ active: false }}
        firstActionButton={{ active: false }}
        secondActionButton={{ active: false }}
        thirdActionButton={approveButton}
        fourthActionButton={rejectButton}
        stepState={stepState}
        submissionDate={employeeDetailForm.submissionDate}
        employee={employeeDetailForm.employee}
        position={employeeDetailForm.position}
        department={employeeDetailForm.department}
        description={employeeDetailForm.description}
        leaveType={employeeDetailForm.leaveType}
        remainsDayOff={remainsDayOff}
        unfinishLeave={unfinishLeave}
        availPlafon={availPlafon}
        leaveDate={employeeDetailForm.leaveDate}
        leaveDuration={employeeDetailForm.leaveDuration}
        firstApprovalData={approvalData.firstApprovalData}
        secondApprovalData={approvalData.secondApprovalData}
        firstArchiveImg={archiveImg.firstArchiveImg}
        secondArchiveImg={archiveImg.secondArchiveImg}
        thirdArchiveImg={archiveImg.thirdArchiveImg}
        fourthArchiveImg={archiveImg.fourthArchiveImg}
        isLoading={isLoading}
      />
    </div>
  );
}
