import { Form, Tabs } from 'antd';
import { AddEmployeeTerminateTemplatePropsType } from './AddEmployeeTerminateTemplate.interface';
import FormBuilder from 'antd-form-builder';
import { useForm } from 'antd/lib/form/Form';
import { MemoizeHistoryTabs } from '../../components/history-payroll-period-tabs/HistoryPayrollPeriodTabs';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import InsertFileTable from './components/InsertFileTable';
import React, { useEffect, useState } from 'react';
import { EmployeeTerminateTemplateUsecase } from './AddEmployeeTerminateTemplate.usecase';
import BottomLoading from '../../../../../components/bottom-loading/BottomLoading';
import DatePickerFutureDate from './components/DatePickerFutureDate';
import ToolbarButtonDetailTemplate from '../../../component/toolbar-button-detail-template/ToolbarButtonDetailTemplate';

const usecase = new EmployeeTerminateTemplateUsecase()

let job_name
let department_name
let join_date
let leave_queue

export default function AddEmployeeTerminateTemplate(props: AddEmployeeTerminateTemplatePropsType) {
  const [form] = useForm();
  const [reasonList, setReasonList] = React.useState([])
  const [disabledForm, setDisabledForm] = useState(props.isLoading)
  const [, updateState] = React.useState<any>(); const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    setDisabledForm(props.isLoading)
  }, [props.isLoading])

  const meta = {
    columns: 4,
    colon: false,
    fields: [
      {
        key: 'employee',
        label: 'Karyawan',
        colSpan: 4,
        viewMode: props.viewMode,
        widget: 'select',
        options: props.employeeList,
        required: true,
        disabled: disabledForm,
        message: "Karyawan harus diisi.",
        initialValue: props.detailDataSource?.employee || [],
        widgetProps: {
          showSearch: true, 
          mode: '',
          filterOption: (input:any, option:any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'department',
        label: 'Departemen',
        colSpan: 4,
        viewMode: true,
        initialValue: department_name || props.detailDataSource?.department || '',
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'job',
        label: 'Jabatan',
        colSpan: 4,
        viewMode: true,
        initialValue: job_name || props.detailDataSource?.job || '',
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'join_date',
        label: 'Tanggal Bergabung',
        colSpan: 4,
        viewMode: true,
        disabled: disabledForm,
        initialValue: join_date || props.detailDataSource?.join_date || '',
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'effective_date',
        label: 'Tanggal Terminasi Efektif',
        colSpan: 4,
        viewMode: props.viewMode,
        widget: DatePickerFutureDate,
        required: true,
        message: "Tanggal Terminasi Efektif harus diisi.",
        initialValue: props.detailDataSource?.effective_date || '',
        widgetProps: {
          disabledForm
        },
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'terminate_category',
        label: 'Kategori Terminasi',
        colSpan: 4,
        viewMode: props.viewMode,
        widget: 'select',
        options: props.categoryList,
        required: true,
        disabled: disabledForm,
        message: "Kategori Terminasi harus diisi.",
        initialValue: props.detailDataSource?.terminate_category || '',
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'information',
        label: 'Keterangan',
        colSpan: 4,
        viewMode: props.viewMode,
        widget: 'textarea',
        required: true,
        disabled: disabledForm,
        message: "Keterangan harus diisi.",
        initialValue: props.detailDataSource?.information || '',
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'leave_remaining',
        label: 'Sisa Cuti',
        colSpan: 4,
        viewMode: true,
        initialValue: leave_queue || props.detailDataSource?.leave_remaining || '',
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'must_be_returned',
        label: 'Inventaris yang wajib dikembalikan',
        colSpan: 4,
        disabled: disabledForm,
        viewMode: props.viewMode,
        initialValue: props.detailDataSource?.must_be_returned || '',
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'insert_file_table',
        label: 'File Tambahan',
        colSpan: 4,
        disabled: disabledForm,
        initialValue: {
          dataSource: [{ key: 1, name: 'button', file: null, error_message: '' }],
        },
        widget: InsertFileTable,
        widgetProps: {
          disabled: props.viewMode || disabledForm
        },
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
    ],
  };

  const onFinish = (values) => {
    if (props.onFinish) {
      const queue = Number(leave_queue?.split(" ")[0])
      props.onFinish(form.getFieldsValue(), queue);
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (props.onFinishFailed) {
      props.onFinishFailed(form.getFieldsValue());
    }
  };

  const onFieldsChange = (e) => {
    if (e.length === 1) {
      const fieldName = e[0].name[0];
      const value = e[0].value;

      switch (fieldName) {
        default:
          break;
        case 'employee':
          processEmployee(value, form);
          break;
        case 'terminate_category':
          getListReasonResign(value)
          break;
      }
    }

    if (props.onFieldChange) {
      props.onFieldChange(e, form);
    }
  };

  
  async function processEmployee(value, form) {
    setDisabledForm(true)
    const selectedEmployee = props.employeeList.filter((data) => data.value === value)[0];
    const employeeJobDetail = props.jobList[selectedEmployee.job_id];

    const params = {
      employee_id: selectedEmployee.value
    }

    const response = await usecase.getEmployeePlafon(params)
    const content = response.data.content
    
    job_name = employeeJobDetail?.job_name
    department_name = employeeJobDetail?.dept_name
    join_date = selectedEmployee?.join_date
    leave_queue = `${content?.leave_queue} Hari`
    forceUpdate()
    setDisabledForm(false)
  }

  function getListReasonResign(id) {
    const reasonResign = props.categoryList.find(e => {
      return e.key === id
    }).options

    setReasonList(reasonResign || [])
    forceUpdate()
  }

  if (reasonList.length) {
    meta.fields.splice(6, 0, {
      key: 'terminate_reason',
      label: 'Alasan Terminasi',
      colSpan: 4,
      viewMode: props.viewMode,
      options: reasonList,
      widget: 'select',
      required: true,
      disabled: disabledForm,
      message: 'Alasan Terminasi harus diisi.',
      initialValue: props.detailDataSource?.terminate_reason || [],
      widgetProps: {
        showSearch: true,
        mode: '',
        filterOption: (input:any, option:any) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      },
      formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 8 },
      },
    },)
  }

  return (
    <div className="add-employee-terminate-template">
      <Breadcrumb breadcrumbConf={props.breadcrumbConf} />
      
      <Form
        name="basic"
        style={{ 
          width: '100%', 
          height: 'calc(100vh - 130px)', 
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
        layout="horizontal"
        labelAlign="left"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={onFieldsChange}
      >
        <ToolbarButtonDetailTemplate
          submitButton={{
            ...props.submitButton,
            disabled: disabledForm,
          }}
          firstActionButton={{ ...props.firstActionButton, disabled: disabledForm }}
          secondActionButton={props.secondActionButton}
          thirdActionButton={props.thirdActionButton}
          fourthActionButton={props.fourthActionButton}
          dropdownActionButton={props.dropdownActionButton}
        />

        <FormBuilder form={form} meta={meta} />
        {props.historyId ? (
          <div className="tabs-wrapper">
            <Tabs defaultActiveKey="1" type="card">
              <Tabs.TabPane tab="History" key="1">
                <MemoizeHistoryTabs key="history-master" history_id={props.historyId} />
              </Tabs.TabPane>
            </Tabs>
          </div>
        ) : null}
      </Form>

      <BottomLoading key="isLoading" active={disabledForm} />
    </div>
  );
}
