import Constant from "../../../../../config/Constants";
import API from "../../../../../services/API.service";

export default class AccessListUsecase {

  getAccessList(params) {
    const URL = Constant.URL_BASE_USER + "/v3/hak-akses"
    return API.get(URL, { params })
  }

  export(params) {
    const URL = Constant.URL_BASE_USER + "/v3/hak-akses"
    return API.get(URL, { params })
  }

}