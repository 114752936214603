import LeaveConfigurationUseCase from '../../../../../../@core/use-case/leave/LeaveConfiguration.usecase';
import { leaveConfigurationMapper } from '../../../../../../@core/mapper/leave/LeaveConfiguration.mapper';
import getLeaveConfiguration from '../../../../../../@core/interaction/leave/GetLeaveConfiguration.interaction';
import generateLeaveConfiguration from '../../../../../../@core/interaction/leave/GenerateLeaveConfiguration.interaction';
import exportLeaveConfiguration from '../../../../../../@core/interaction/leave/ExportLeaveConfiguration.interaction'
import deactiveOvtConfiguration from '../../../../../../@core/interaction/leave/DeactiveLeaveConfiguration.interaction'


export default class LeaveConfigurationUseCaseImpl extends LeaveConfigurationUseCase {
  getLeaveConfigurationList(params, hrefPath) {
    return getLeaveConfiguration(params).then((res: any) =>
      leaveConfigurationMapper(res.data, hrefPath),
    );
  }
  generateLeaveConfiguration(params): Promise<any> {
    return generateLeaveConfiguration(params);
  }
  exportLeaveConfiguration(params) {
    return exportLeaveConfiguration(params);
  }
  deactiveLeaveConfiguration(params) {
    return deactiveOvtConfiguration(params);
  }
}
