/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory } from 'react-router-dom';
import LandingListTemplate from '../../../../templates/landing-list-template/LandingListTemplate';
import { useEffect, useState } from 'react';
import { LandingListTemplatePagination } from '../../../../templates/landing-list-template/LandingListTemplate.interface';
import { ListBannerConstant } from './ListBanner.constant';
import { Modal, Row, Space } from 'antd';
import Breadcrumb from '../../../../components/breadcrumb/Breadcrumb';
import { DeleteOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import ToastService from '../../../../services/Toast.service';
import LocalNotificationService from '../../../../services/LocalNotification.service';
import ListBannerUseCase from './ListBanner.usecase';
import moment from 'moment';
import { BodyUploadBannerType } from '../upload-banner/UploadBanner.interface';

let page = 1;
let limit = 15;
let sort = 'write_date|desc'

const usecase = new ListBannerUseCase();
const toastService = new ToastService();
const localNotificationService = new LocalNotificationService();
const constant = ListBannerConstant;

export default function ListBanner() {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [activeProps, setActiveProps] = useState<BodyUploadBannerType>();
  const [tableScroll] = useState({ y: 600, x: 'max-content' });
  const [visibleWarningModal, setVisibleWarningModal] = useState(false);
  const [pagination] = useState<LandingListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });

  let tableHeader: any = constant.TABLE_HEADER;
  let searchColumnKey = constant.SEARCH_COLUMN_KEY;
  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: true };

  useEffect(() => {
    tableHeader[9].render = (e, records) => {
      const id = records.key;

      return (
        e && (
          <Space>
            {records.status === "Aktif" ? (
              <DeleteOutlined onClick={() => onClickActive(records)} />
            ) : (
              <EyeOutlined onClick={() => onClickActive(records)} />
            )}
            <a href={records.href}>
              <EditOutlined href={records.href} />
            </a>
          </Space>
        )
      );
    };

    loadList();
  }, []);

  function loadList() {
    setIsLoading(true);
    const params = {
      page,
      limit,
      sort,
      with_meta: true,
    };

    usecase.getAll(params).then(
      (res: any) => {
        setIsLoading(false);
        const content = res.data.content;
        // const metaData = res.data.metadata;

        const resultDataSource = content?.length
          ? mapperDatasource(content)
          : constant.DEFAULT_EMPTY_DATA_SOURCE;

        setDataSource([...resultDataSource]);
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }

  function mapperDatasource(dataSource) {
    return dataSource.map((ds) => {
      return {
        key: ds.id,
        promotion_name: ds.name,
        start_date: ds.start_date,
        end_date: ds.end_date,
        position: ds.position,
        status: ds.is_active ? "Aktif" : "Non-Aktif",
        image_url: ds.image_url,
        redirection: ds.redirection,
        content: ds.content,
        create_name: ds.create_name,
        create_date: moment(ds.create_date).format("DD-MM-YYYY"),
        write_name: ds.write_name,
        write_date: moment(ds.write_date).format("DD-MM-YYYY"),
        action: true,
        href: "/v2/app/admin/banner/" + ds.id
      };
    });
  }

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: false,
        displayPathName: 'Banner Promosi',
        href: '/v2/app/admin/banner',
      },
    ],
  };

  const addButton = {
    onClick: () => {
      history.push('/app/admin/banner/new/');
    },
    disabled: isLoading,
  };

  function onClickActive(e) {
    setActiveProps({
      id: e.key,
      name: e.promotion_name,
      image_url: e.image_url,
      position: e.position,
      action_type: 2,
      redirection: e.redirection,
      platform_type: 1,
      content: e.content,
      start_date: e.start_date,
      end_date: e.end_date,
      is_active: e.status !== "Aktif",
      is_repeated: false,
    });
    setVisibleWarningModal(true);
  }

  function setActive(status, ids) {
    setIsLoading(true);

    usecase.active(activeProps).then(
      (res: any) => {
        const data = res.data;
        if (data.code === 200) {
          const message = data.message;
          localNotificationService.openSuccess(message, '', 1);
        }
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      (err) => {
        setIsLoading(false);
        toastService.error(err);
      },
    );
  }

  return (
    <div className="list-banner">
      <Modal
        title="Konfirmasi"
        cancelText="Cancel"
        okText={!activeProps?.is_active ? 'Non-aktifkan' : 'Aktifkan'}
        open={visibleWarningModal}
        cancelButtonProps={{ type: 'ghost' }}
        onOk={() => setActive(activeProps?.is_active, activeProps?.id)}
        onCancel={() => setVisibleWarningModal(false)}
      >
        <p>Apakah yakin ingin men{!activeProps?.is_active ? 'on-' : 'g'}aktifkan data ini?</p>
      </Modal>

      <Row justify="space-between">
        <Breadcrumb breadcrumbConf={breadcrumbConf} />
      </Row>

      <LandingListTemplate
        addButton={addButton}
        deleteButton={{ visible: false }}
        exportButton={{ visible: false }}
        tableScroll={tableScroll}
        tablePagination={pagination}
        tableData={tableData}
        tableHeader={tableHeader}
        isLoading={isLoading} 
      />
    </div>
  );
}
