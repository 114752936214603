import { Card, Col, Row, Form } from 'antd';
import { EmployeeOvertimeType } from './DashboardCard.interface';
import { useForm } from 'antd/lib/form/Form';
import MonthPickerField from './MonthPickerField';

export default function EmployeeOvertime({
  hour,
  employee,
  title,
  onChange,
}: EmployeeOvertimeType) {
  const [form] = useForm();

  const ExtraComponent = () => {
    return (
      <Form form={form} onValuesChange={(e) => onChange(title, form.getFieldValue(title))}>
        <MonthPickerField key={title} name={title} />
      </Form>
    );
  };

  return (
    <Card title={title} extra={<ExtraComponent />} style={{ minWidth: '100%', minHeight: '100%' }}>
      <Row align="middle" style={{ marginTop: '25px' }}>
        <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '-15px' }}>
          <h1 style={{ fontSize: '48px', fontWeight: 700, lineHeight: 0.1 }}>{hour}</h1>
          <p>jam</p>
        </Col>
        <p style={{ fontWeight: 700, fontSize: '16px' }}>&nbsp; oleh &nbsp;</p>
        <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '-15px' }}>
          <h1 style={{ fontSize: '48px', fontWeight: 700, lineHeight: 0.1 }}>{employee}</h1>
          <p>karyawan</p>
        </Col>
      </Row>
    </Card>
  );
}
