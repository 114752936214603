import { Form } from "antd"
import Breadcrumb from "../../../../../components/breadcrumb/Breadcrumb"
import BottomLoading from "../../../../../components/bottom-loading/BottomLoading"
import FormBuilder from "antd-form-builder";
import { AddMasterLocationTemplatePropsType } from "./AddMasterLocationTemplate.interface";
import ToolbarButtonDetailTemplate from "../../../component/toolbar-button-detail-template/ToolbarButtonDetailTemplate";
import SelectAttendanceLocation from "./components/SelectAttendanceLocation";
import React, { useEffect } from "react";

export default function AddMasterLocationTemplate(props: AddMasterLocationTemplatePropsType) {
  const [form] = Form.useForm()
  const [, updateState] = React.useState<any>(); const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    form.setFieldsValue(props.detailDataSource)
    forceUpdate()
  }, [props.detailDataSource])

  const meta: { columns: number, colon: boolean, fields: any[] } = {
    columns: 4,
    colon: false,
    fields: [
      {
        key: 'location',
        label: 'Lokasi',
        viewMode: props.viewMode,
        colSpan: 4,
        rules: [{ required: true, whitespace: true, message: "Lokasi harus diisi", }],
        initialValue: props.detailDataSource?.name || "",
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 }
        },
      },
      {
        key: 'region',
        label: 'Region',
        viewMode: props.viewMode,
        colSpan: 4,
        required: true,
        message: "Region harus diisi",
        align: 'left',
        widget: 'select',
        initialValue: initialValueSelect() || "",
        options: props.listRegion,
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 }
        },
      },
      {
        key: 'checkbox_attendance_location',
        label: 'Gunakan sebagai lokasi absensi',
        widget: 'checkbox',
        colSpan: 4,
        disabled: props.viewMode,
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 }
        },
      },
      {
        key: 'select_attendance_location',
        label: ' ',
        widget: SelectAttendanceLocation, 
        colSpan: 4,
        initialValue: { lat: 0, lng: 0 },
        widgetProps: {
          disabled: props.viewMode || props.isLoading,
        },
        formItemLayout: {
          labelCol: { span: 0 },
          wrapperCol: { span: 8 }
        },
      },
    ]
  }

  function initialValueSelect() {
    return props.viewMode ? props.detailDataSource?.region_name
      : props.detailDataSource?.region_id
  }

  const onFinish = (values) => {
    if (props.onFinish) {
      props.onFinish(form.getFieldsValue());
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (props.onFinishFailed) {
      props.onFinishFailed(form.getFieldsValue());
    }
  };

  const onFieldsChange = (changedFields) => {
    if (props.onFieldChange) {
      props.onFieldChange(changedFields, form);
    }
  };

  if (!form.getFieldValue('checkbox_attendance_location')) {
    meta.fields.splice(3, 1)
  }

  return (
    <div id="add-employee-department-template" style={{ height: 'calc(100vh - 100px)', width: '100%' }}>
      <Breadcrumb breadcrumbConf={props.breadcrumbConf} />

      <Form
        name="basic"
        style={{ width: '100%' }}
        layout="horizontal"
        labelAlign="left"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={forceUpdate}
        onFieldsChange={onFieldsChange}
      >
        <ToolbarButtonDetailTemplate
          submitButton={{
            ...props.submitButton,
            disabled: props.isLoading
          }}
          firstActionButton={{ ...props.firstActionButton, disabled: props.isLoading }}
          secondActionButton={props.secondActionButton}
          thirdActionButton={props.thirdActionButton}
          fourthActionButton={props.fourthActionButton}
          dropdownActionButton={props.dropdownActionButton}
        />

        <div className="scrollable-content">
          <div className="form-wrapper-schedule">
            <FormBuilder form={form} meta={meta} />
          </div>
        </div>
      </Form>

      <BottomLoading active={props.isLoading} />
    </div>
  )
}