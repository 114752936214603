import { DataSourceMasterWorkingType } from "../template/AddMasterWorkingScheduleTemplate.interface";

export const DefaultDataSourceMasterWorking = {
  name: null,
  attendance_tolerance: null,
  break_total: null,
  work_time_total: null,
  table_days: {
    Senin: {
      start: null,
      end: null,
      break: null,
    },
    Selasa: {
      start: null,
      end: null,
      break: null,
    },
    Rabu: {
      start: null,
      end: null,
      break: null,
    },
    Kamis: {
      start: null,
      end: null,
      break: null,
    },
    Jumat: {
      start: null,
      end: null,
      break: null,
    },
    Sabtu: {
      start: null,
      end: null,
      break: null,
    },
    Minggu: {
      start: null,
      end: null,
      break: null,
    },
  }
}