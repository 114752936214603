const Constant = {
  URL_BASE: process.env.REACT_APP_HIRA,
  URL_BASE_PATH: process.env.REACT_APP_HIRA_GO_API,
  URL_BASE_ATTENDANCE: process.env.REACT_APP_HIRA_ATTENDANCE,
  URL_BASE_COMPANY: process.env.REACT_APP_HIRA_COMPANY,
  URL_BASE_CLAIM: process.env.REACT_APP_HIRA_CLAIM,
  URL_BASE_LOAN: process.env.REACT_APP_HIRA_LOAN,
  URL_BASE_USER: process.env.REACT_APP_HIRA_USER,
  URL_BASE_MISC: process.env.REACT_APP_HIRA_MISC,
  URL_BASE_REGISTER: process.env.REACT_APP_HIRA_REGISTER,

  // AUTHENTICATION URL
  URL_LOGIN: '/auth',

  // ACL MENU URL
  URL_ACL_MENU: '/res/menu',

  // LEAVES
  URL_EMPLOYEE_CONFIG_LINE: '/hr/leave/employee-config',
  URL_LEAVE_CORRECTION: '/v3/leave/corrections',
  URL_LEAVE_REQUEST: '/v3/leave/request',
  URL_LEAVE_TYPE: '/hr/leave/types',
  URL_EMPLOYEE: '/v3/lov/emp',

  // STORAGE VAR
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  EXPIRES_IN: 'expires_in',

  // API RESPONSE CODE
  RESP_OK: 200,
  RESP_CREATED: 201,
  RESP_NO_CONTENT: 204,
  RESP_UNAUTHORIZED: 401,
  RESP_FORBIDDEN: 403,
  RESP_NOT_FOUND: 404,

  // VALIDATE RESPONSE CODE
  LOCAL_VALIDATION_ERROR: 'LOCAL_VALIDATION_ERROR',

  //MODAL NOTIFICATION COMPONENT
  MODAL_SUCCESS: 'MODAL_SUCCESS',
  MODAL_ERROR: 'MODAL_ERROR',
  MODAL_WARNING: 'MODAL_WARNING',
  MODAL_DISMISS: 5000,

  // CONFIG MEDIA FILE
  FILE_MP3: 'MP3',
  FILE_PNG: 'PNG',
  FILE_JPG: 'JPG',
  FILE_JPEG: 'JPEG',
  FILE_PDF: 'PDF',
  FILE_EXCEL:
    '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',

  //CONFIG PROPERTIES
  DATE_FORMAT: 'DD-MMMM-YYYY',
  LOCALE_FORMATER: 'id',
  REGEX_NUMBER_ONLY: /^[0-9+]*$/,

  // PAGINATION
  DEFAULT_PAGE_OPTIONS: ['10', '25', '50', '100'],
  DEFAULT_PAGE_SIZE: 25,
  DEFAULT_FIRST_PAGE: 1,
  DEFAULT_SORT: 'write_date|desc',
};

export default Constant;
