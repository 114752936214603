import { Button, Modal, Table } from 'antd';
import { useState } from 'react';

export default function ErrorDetailButton({ data }) {
  const style = {
    backgroundColor: '#ffddca',
    color: '#d7451d',
    border: '2px solid rgb(249, 193, 162)',
    fontSize: '12px',
    cursor: 'pointer',
  };
  const columns = [
    {
      title: 'Nama Kolom',
      dataIndex: 'column_name',
      key: 'column_name',
    },
    {
      title: 'Pesan Error',
      dataIndex: 'error_message',
      key: 'error_message',
      render: (error_message) => {
        return error_message.length ? (
          <ul>
            {error_message.map((error, index) => {
              return <li style={{marginLeft: "-24px"}} key={index}>{error}</li>;
            })}
          </ul>
        ) : null;
      },
    },
  ];
  let [visibleModal, setVisibleModal] = useState(false);
  let [dataSource, setDataSource] = useState([]);

  const onClickErrorDetail = () => {
    const result = [];
    const error_list = data.error_list;
    for (const key in error_list) {
      if (error_list[key].status === false) {
        result.push({
          column_name: error_list[key].field_name,
          error_message: error_list[key].message,
        });
      }
    }

    setDataSource([...result]);
    setVisibleModal(true);
  };

  return (
    <>
      <button style={style} onClick={onClickErrorDetail}>
        Lihat Detail
      </button>

      <Modal
        visible={visibleModal}
        title="Detail Pesan Error"
        closable={false}
        footer={[
          <Button key="ok" type="primary" onClick={() => setVisibleModal(false)}>
            OK
          </Button>,
        ]}
      >
        <Table columns={columns} dataSource={dataSource} pagination={false} scroll={{ y: 400 }} />
      </Modal>
    </>
  );
}
