import { Table } from 'antd';

export const LoanPaymentConstant = {
    TABLE_HEADER: [
      { title: 'Nomor Pinjaman', key: 'loan_number', dataIndex: 'loan_number', width: 160 },
      {
        title: 'NIK',
        key: 'nik',
        dataIndex: 'nik',
        width: 120,
      },
      { title: 'Karyawan', key: 'employee_name', dataIndex: 'employee_name', width: 150 },
      { title: 'Angsuran bulan ke-', key: 'installment_num', dataIndex: 'installment_num', width: 180 },
      {
        title: 'Tipe Pinjaman',
        key: 'loan_type',
        dataIndex: 'loan_type',
        width: 150
      },
      {
        title: 'Keterangan',
        key: 'description',
        dataIndex: 'description',
        width: 150
      },
      {
        title: 'Pokok',
        key: 'amount',
        dataIndex: 'amount',
        width: 120
      },
      {
        title: 'Bunga',
        key: 'interest',
        dataIndex: 'interest',
        width: 120
      },
      { title: 'Total Angsuran', key: 'installment_total', dataIndex: 'installment_total', width: 150 },
      {
        title: 'Status Pembayaran',
        key: 'state_payment',
        dataIndex: 'state_payment',
        editable: true,
        type: 'select',
        width: 180,
      },
      {
        title: 'Tanggal Pembayaran',
        key: 'payment_date',
        dataIndex: 'payment_date',
        editable: true,
        type: 'payment-date',
        width: 180
      },
      {
        title: 'Lihat Detail',
        type: 'button',
        key: 'action',
        dataIndex: 'action',
        width: 120,
      },
      
    ],
    SEARCH_COLUMN_KEY: [],
    SEARCH_COLUMN_COMPONENT: [],
    DEFAULT_EMPTY_DATA_SOURCE: [],
  };
