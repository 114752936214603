import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Button, Space } from 'antd';
import { UnorderedListOutlined, AppstoreOutlined } from '@ant-design/icons';
import LandingListTemplate from '../../../../../templates/landing-list-template/LandingListTemplate';
import CardListTemplate from '../../../../../templates/card-list-template/CardListTemplate';
import { MasterJobListConstant } from './ListMasterJob.constant';
import { getListMasterJobMapper } from '../../../../../@core/mapper/employee/GetMasterJob.mapper';
import MasterJobListUsecase from './ListMasterJob.usecase';
import ToastService from '../../../../../services/Toast.service';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';

const useCaseImpl = new MasterJobListUsecase();
const constant = MasterJobListConstant;
const toastService = new ToastService();
const localNotificationService = new LocalNotificationService();

let page = 1;
let limit = 20;
let job_name = '';
let department_name = '';
let loadPagination = true;
let selectionJobId = [];

export default function ListMasterJob() {
  const history = useHistory();
  const tableHeader: any = constant.TABLE_HEADER;
  const searchColumnKey = constant.SEARCH_COLUMN_KEY;
  const searchColumnComponent = constant.SEARCH_COLUMN_COMPONENT;

  let [pagination, setPagination] = useState<any>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });
  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([]);
  let [tableScroll, setTableScroll] = useState({ y: 200 });
  const [viewState, setViewState] = useState('card');

  const tableData = {
    searchColumnKey,
    dataSource,
    tableRowSelectionActive: true,
    searchColumnComponent,
  };

  useEffect(() => {
    initialPage();
  }, []);

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: false,
        displayPathName: 'Master Jabatan',
      },
    ],
  };

  async function initialPage() {
    loadList();
    const clientHeight = document.getElementById('list-master-job-page').clientHeight;

    setTableScroll({ y: clientHeight - 200 });
  }

  function loadList() {
    setIsLoading(true);
    const params = {
      page,
      limit,
      job_name,
      department_name,
      with_meta: true,
    };

    useCaseImpl.getJobList(params).then((res: any) => {
      const content = res.data.content;
      const metaData = res.data?.metadata;
      const resultDataSource =
        content && content?.length
          ? getListMasterJobMapper(content)
          : constant.DEFAULT_EMPTY_DATA_SOURCE;
      loadPagination = true;
      setDataSource([...resultDataSource]);
      updatePaginationState(metaData?.total_records);
      setIsLoading(false);
    });
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    const outputSort = output.sort;
    const outputSearchColumn = output.searchColumn;

    processSearchColumn(outputSearchColumn);
    processSelection(output.selection);
    processPagination(outputPagination);
  };

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  function processSearchColumn(outputSearchColumn) {
    let isLoadList = false;
    if (outputSearchColumn) {
      if (outputSearchColumn.job_name !== undefined && outputSearchColumn.job_name !== job_name) {
        job_name = outputSearchColumn.job_name;
        isLoadList = true;
      }
      if (
        outputSearchColumn.department_name !== undefined &&
        outputSearchColumn.department_name !== department_name
      ) {
        department_name = outputSearchColumn.department_name;
        isLoadList = true;
      }
      if (isLoadList) {
        page = 1;
        loadPagination = false;
        loadList();
      }
    }
  }

  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionJobId) {
      selectionJobId = outputSelection;
    }
  }

  const addButton = {
    onClick: () => {
      history.push('/app/hr-employee/master-job/new/');
    },
    disabled: isLoading,
  };

  async function handleExport() {
    setIsLoading(true);
    const params = {
      page,
      limit,
      job_name,
      department_name,
      with_meta: true,
    };
    useCaseImpl.export(params).then(
      (resp: any) => {
        const content = resp.data.content;
        if (resp.status === 200) {
          const data = content?.data;
          let sampleArr = base64ToArrayBuffer(data);
          saveByteArray(content?.filename, sampleArr);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }
  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  }

  async function handleChangeStatus(status) {
    if (selectionJobId.length < 1) {
      localNotificationService.openError('Pilih jabatan terlebih dahulu', '', 1);
      return;
    }
    setIsLoading(true);
    const params = {
      id: selectionJobId,
      status,
    };
    useCaseImpl.changeStatus(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          loadList();
          const message = data.message;
          localNotificationService.openSuccess(message, '', 1);
        } else {
          localNotificationService.openToast(
            'topRight',
            data?.error?.message,
            '',
            { color: '#fff', background: 'red' },
            3,
          );
        }
        setIsLoading(false);
      },
      (err) => {
        localNotificationService.openToast(
          'topRight',
          err,
          '',
          { color: '#fff', background: 'red' },
          3,
        );
        setIsLoading(false);
      },
    );
  }

  async function deactivateJob(id) {
    setIsLoading(true);
    const params = {
      id: [id],
      status: 'tidak_aktif',
    };
    useCaseImpl.changeStatus(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          loadList();
          const message = data.message;
          localNotificationService.openSuccess(message, '', 1);
        } else {
          localNotificationService.openToast(
            'topRight',
            data?.error?.message,
            '',
            { color: '#fff', background: 'red' },
            3,
          );
        }
        setIsLoading(false);
      },
      (err) => {
        localNotificationService.openToast(
          'topRight',
          err,
          '',
          { color: '#fff', background: 'red' },
          3,
        );
        setIsLoading(false);
      },
    );
  }

  const dropdownButton = {
    visible: true,
    disabled: false,
    options: [
      { key: 'export', label: 'Ekspor', onClick: () => handleExport() },
      { key: 'active', label: 'Aktifkan', onClick: () => handleChangeStatus('aktif') },
      { key: 'inactive', label: 'Non-Aktifkan', onClick: () => handleChangeStatus('non-aktif') },
    ],
  };

  const onRow = (e) => {
    if (e.key && e.key !== 'text' && e.key !== 'empty-data') {
      history.push('/app/hr-employee/detail-master-job/' + e.key);
    }
  };

  return (
    <div id="list-master-job-page" style={{ height: 'calc(100vh - 100px)' }}>
      <Row justify="space-between">
        <Breadcrumb breadcrumbConf={breadcrumbConf} />
        <Space>
          <Button
            type="primary"
            disabled={viewState === 'card'}
            onClick={() => setViewState('card')}
          >
            <AppstoreOutlined />
          </Button>
          <Button
            type="primary"
            disabled={viewState === 'list'}
            onClick={() => setViewState('list')}
          >
            <UnorderedListOutlined />
          </Button>
        </Space>
      </Row>
      <div style={{ display: viewState === 'list' ? '' : 'none' }}>
        <LandingListTemplate
          addButton={addButton}
          deleteButton={{ visible: false }}
          exportButton={{ visible: false }}
          dropdownButton={dropdownButton}
          tableHeader={tableHeader}
          tableData={tableData}
          tablePagination={pagination}
          tableOnChange={tableOnChange}
          isLoading={isLoading}
          tableScroll={tableScroll}
          onRow={onRow}
        />
      </div>
      <div style={{ display: viewState === 'card' ? '' : 'none' }}>
        <CardListTemplate
          addButton={addButton}
          deleteButton={{ visible: false }}
          exportButton={{ visible: false }}
          tableData={tableData}
          tablePagination={pagination}
          tableOnChange={tableOnChange}
          isLoading={isLoading}
          tableScroll={tableScroll}
          type={'master-job'}
          onDeactivate={deactivateJob}
        />
      </div>
    </div>
  );
}
