import Constant from '../../../../../../config/Constants';
import API from '../../../../../../services/API.service';
import { TemplateHeader } from './ImportMasterWorkingSchedule.constant';
import { validateExcelData } from './ImportMasterWorkingSchedule.service';

export default class ImportMasterWorkingScheduleUseCase {
  doValidate(selectedFile) {
    return new Promise(async (resolve, reject) => {
      const excelArrays = selectedFile.content.excel_arrays;
      const extension = selectedFile.content.file_name.split('.').pop();
      const requiredExtension = ['xlsx', 'xls'];
      const result = {
        status: 'error',
        content: {
          dataSource: [],
          errorMessage: '',
        },
      };
      const errorTemp = [];

      if (requiredExtension.indexOf(extension) === -1) {
        errorTemp.push('File yang di pilih tidak sesuai dengan template yang telah di sediakan');
      }

      if (JSON.stringify(excelArrays[0]) !== JSON.stringify(TemplateHeader)) {
        errorTemp.push('File yang di pilih tidak sesuai dengan template yang telah di sediakan');
      }

      if (excelArrays.length === 0 || excelArrays.length === 1) {
        errorTemp.push('File yang di pilih tidak memiliki data');
      }

      if ((excelArrays.length - 1) % 7 !== 0) {
        errorTemp.push('File yang dipilih harus memiliki baris berkelipatan 7');
      }

      if (excelArrays.length > 140) {
        errorTemp.push('File yang di impor melebihi 140 baris');
      }

      if (errorTemp.length) {
        result.content.errorMessage = errorTemp[0];
        reject(result);
      } else {
        const validateLocalResult: any = await validateExcelData(excelArrays);
        result.status = validateLocalResult.status;
        result.content.dataSource = validateLocalResult.dataSource;
        resolve(result);
      }
    });
  }

  validateToServer(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + '/v3/configuration/master-jadwal-kerja/validate';
    return API.post(URL, body);
  }

  saveSchedule(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + '/v3/configuration/master-jadwal-kerja';
    return API.post(URL, body);
  }

  downloadTemplate() {
    const URL = Constant.URL_BASE_ATTENDANCE + '/v3/configuration/master-jadwal-kerja/template';
    return API.get(URL);
  }
}
