import Constant from "../../../../../config/Constants";
import API from "../../../../../services/API.service";

export default class MasterLocationListUseCase {
  convertOutputSortToEndPointFormat(tableOutputSort) {
    let result = '';
    const columnKey = tableOutputSort.columnKey;
    const order = tableOutputSort.order;

    if (order) {
      switch (columnKey) {
        default:
          break;
        case 'location_name':
          result += 'location_name|';
          break;
        case 'create_date':
          result += 'create_date|';
          break;
        case 'write_date':
          result += 'write_date|';
          break;
      }

      switch (order) {
        default:
          break;
        case 'ascend':
          result += 'asc';
          break;
        case 'descend':
          result += 'desc';
          break;
      }
    }

    return result;
  }

  getAll(params) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/master-location/list"
    return API.get(URL, { params })
  }

  export(params) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/master-location/export"
    return API.get(URL, { params })
  }

  update(body) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/master-location/update"
    return API.post(URL, body)
  }

  active(body) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/master-location/update-state"
    return API.put(URL, body)
  }
}