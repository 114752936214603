import Constant from '../../config/Constants';
import API from '../../services/API.service';

class MenuService {
  getMenu() {
    const URL = Constant.URL_BASE_USER+'/v3/menu';

    return new Promise((resolve, reject) => {
      API.get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error?.response) {
            console.log(error.response.data);
          }
          reject(error);
        });
    });
  }
}

export default MenuService;
