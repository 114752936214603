export const ListBannerConstant = {
  TABLE_HEADER: [
    {
      title: 'Nama Promosi',
      key: 'promotion_name',
      dataIndex: 'promotion_name',
      width: 220,
    },
    {
      title: 'Tanggal Mulai',
      key: 'start_date',
      dataIndex: 'start_date',
      width: 180,
    },
    {
      title: 'Tanggal Akhir',
      key: 'end_date',
      dataIndex: 'end_date',
      width: 180,
    },
    {
      title: 'Posisi',
      key: 'position',
      dataIndex: 'position',
      width: 180,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 140,
    },
    {
      title: 'Dibuat Oleh',
      key: 'create_name',
      dataIndex: 'create_name',
      width: 200,
    },
    {
      title: 'Dibuat Pada',
      key: 'create_date',
      dataIndex: 'create_date',
      width: 160,
      isFormatDate: true,
    },
    {
      title: 'Diubah Oleh',
      key: 'write_name',
      dataIndex: 'write_name',
      width: 200,
    },
    {
      title: 'Diubah Pada',
      key: 'write_date',
      dataIndex: 'write_date',
      width: 160,
      isFormatDate: true,
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: 180,
    },
  ],
  SEARCH_COLUMN_KEY: [
    { key: 'key', componentType: 'search-input' },
    { key: 'promotion_name', componentType: 'text' },
  ],
  DEFAULT_EMPTY_DATA_SOURCE: [
    {
      key: 'empty-data',
      promotion_name: '',
      start_date: '',
      end_date: '',
      position: '',
      status: '',
      create_name: '',
      create_date: '',
      write_name: '',
      write_date: '',
    },
  ],
};
