import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Moment from 'moment';
import { Table, Button, Input, Space, Pagination, DatePicker, Select, Col, Dropdown, Menu } from 'antd';
import './LandingListTemplate.less';
import {
  LandingListTemplateButton,
  LandingListTemplatePagination,
  LandingListTemplatePropsType,
} from './LandingListTemplate.interface';
import { DownOutlined, CalendarOutlined } from '@ant-design/icons';
import BottomLoading from '../../components/bottom-loading/BottomLoading';
const { RangePicker } = DatePicker;

let output = {
  pagination: null,
  sort: null,
  searchColumn: null,
  selection: null,
  tableAction: null,
  action: null,
  tableApproval: null,
};

export default function LandingListTemplate(props: LandingListTemplatePropsType) {
  let [addButton, setAddButton] = useState<LandingListTemplateButton>();
  let [exportButton, setExportButton] = useState<LandingListTemplateButton>();
  let [deleteButton, setDeleteButton] = useState<LandingListTemplateButton>();
  let [changeModeButton, setChangeModeButton] = useState<LandingListTemplateButton>();
  let [dropdownButton, setDropdownButton] = useState<LandingListTemplateButton>();
  let [dataSource, setDataSource] = useState([]);
  let [columns, setColumns] = useState<any>();
  let [tableRowSelectionActive, setTableRowSelectionActive] = useState();
  let [selectedRowKeys, setSelectedRowKeys] = useState<any>();
  const [dates, setDates] = useState([Moment(Moment(Date.now() - 7 * 24 * 3600 * 1000).format('YYYY-MM-DD')), Moment()]);
  const [value, setValue] = useState<any>();

  let [pagination, setPagination] = useState<LandingListTemplatePagination>({
    current: 1,
    pageSize: 5,
    total: 5,
    size: 'small',
  });
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    fillActionButtonState('addButton');
  }, [props.addButton]);
  useEffect(() => {
    fillActionButtonState('exportButton');
  }, [props.exportButton]);
  useEffect(() => {
    fillActionButtonState('deleteButton');
  }, [props.deleteButton]);
  useEffect(() => {
    if (props.changeModeButton) {
      fillActionButtonState('changeModeButton')
    }
  }, [props.changeModeButton]);
  useEffect(() => {
    processTableData();
  }, [props.tableData]);
  useEffect(() => {
    processTableHeader();
  }, [props.tableHeader]);
  useEffect(() => {
    processPagination();
  }, [props.tablePagination]);
  useEffect(() => {
    processLoading();
  }, [props.isLoading]);
  useEffect(() => {
    if (props.dropdownButton) {
      processDropdownButton();
    }
  }, [props.dropdownButton]);
  useEffect(() => {
    output.searchColumn = null;
  }, [props.resetFilter]);

  const fillActionButtonState = (procedure) => {
    const result: any = { visible: true, disabled: false };

    if (props[procedure]) {
      result.name = props[procedure].name === undefined ? true : props[procedure].name;
      result.visible = props[procedure].visible === undefined ? true : props[procedure].visible;
      result.disabled = props[procedure].disabled === undefined ? false : props[procedure].disabled;
    }

    switch (procedure) {
      case 'addButton':
        setAddButton(result);
        break;
      case 'exportButton':
        setExportButton(result);
        break;
      case 'deleteButton':
        setDeleteButton(result);
        break;
      case 'changeModeButton':
        setChangeModeButton(result);
        break;
      default:
        break;
    }
  };

  const processTableData = () => {
    if (props.tableData && props.tableData.dataSource && props.tableData.dataSource.length) {
      const searchColumnKey = props.tableData.searchColumnKey
        ? props.tableData.searchColumnKey
        : [];
      const searchColumnComponent = props.tableData.searchColumnComponent
        ? props.tableData.searchColumnComponent
        : [];
        
      const searchColumnResult = JSON.parse(JSON.stringify(props.tableData.dataSource[0]));
      const dataSource = props.tableData.dataSource ? props.tableData.dataSource : [];
      let result = [];

      if (searchColumnKey.length) {
        for (let key in searchColumnResult) {
          if (searchColumnKey.indexOf(key) !== -1) {
            searchColumnResult[key] = 'search-input';
          } else {
            searchColumnResult[key] = '';
          }
        }
        result.push(searchColumnResult);
      } else if (searchColumnComponent.length) {
        for (let key in searchColumnResult) {
          searchColumnResult[key] = '';
          searchColumnComponent.map(sck => {
            if (sck.key === key) {
              searchColumnResult[key] = sck.componentType
            }
          })
        }
        result.push(searchColumnResult);
      }
      
      result = result.concat(dataSource);
      
      setDataSource([...result]);
      processTableRowSelection();
    }
  };

  const RawHTML = ({ children, className = '' }) => (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: children && children.replace(/\n/g, '<br />') }}
    />
  );

  const processTableHeader = () => {
    const column: any = props.tableHeader ? props.tableHeader : [];
    const result: any = [];

    if (column.length) {
      column.forEach((header) => {
        const temp = header;
        temp.render = (text, record) => {
          switch (text) {
            case 'text':
              return <Input.Search
                allowClear
                defaultValue={null}
                onPressEnter={(e: any) => onEnterSearchColumn(e.target.value, header.key)}
                onSearch={(val) => onEnterSearchColumn(val, header.key)}
              />
            case 'date':
              return <DatePicker
                format="YYYY-MM-DD"
                defaultValue={null}
                onChange={(_, date) => onEnterSearchColumn(date, header.key)}
              />
            case 'select':
              const options = props.tableData.searchColumnComponent.find(sck => {
                return sck.key === header.key
              }).options
              return <Select
                style={{ width: '100%' }}
                options={options}
                defaultValue={null}
                onChange={(value) => onEnterSearchColumn(value, header.key)}
              />
            default:
              break;
          }
          if (text === 'search-input') {
            if (header.searchSelectOptions) {
              return (
                <Select
                  defaultValue={null}
                  style={{ width: '100%' }}
                  options={header.searchSelectOptions}
                  onChange={(value) => onEnterSearchColumn(value, header.key)}
                />
              )
            }
            return (
              <Input.Search
                allowClear
                onPressEnter={(e: any) => onEnterSearchColumn(e.target.value, header.key)}
                onSearch={(val) => onEnterSearchColumn(val, header.key)}
              />
            );
          } else if (header.isCTA !== undefined && header.isCTA) {
            return <a href={record.href}>{text}</a>;
          } else if (header.isLink !== undefined && header.isLink) {
            return <a onClick={() => onClickLinkActionButton(record)} >{text}</a>;
          } else if (header.isAction !== undefined && header.isAction) {
            return(
              <div onClick={(e) => e.stopPropagation()}>
              <Button
                type="primary"
                style={{borderRadius: 10, ...record.buttonStyle}}
                onClick={() => onClickTableActionButton(record)}
                disabled={record.is_active === 'Tidak Aktif'}
              >
              {text}
            </Button>
            </div>
            );
          } else if (header.isHtml !== undefined && header.isHtml) {
            return <RawHTML>{text}</RawHTML>;
          } else if (header.isApproval !== undefined && header.isApproval) {
            if(record.status === 'Menunggu Persetujuan'){
              return(
                <div onClick={(e) => e.stopPropagation()}>
                   <Space>
                      <Button
                        style={{borderRadius: 10, background: 'green', color: '#fff'}}
                        onClick={() => onClickTableApproval(record, 'approved')}
                      >
                        Setuju
                      </Button>
                      <Button
                        type="primary"
                        style={{borderRadius: 10}}
                        onClick={() => onClickTableApproval(record, 'rejected')}
                      >
                        Tolak
                      </Button>
                    </Space>
                  </div>
              );
            } else return null
            
          } else {
            return <span>{text}</span>;
          }
        };

        result.push(header);
      });
      setColumns([...result]);
    } else {
      // empty conf for header table
    }
  };


  const processPagination = () => {
    const resultPagination: LandingListTemplatePagination = pagination;

    if (props.tablePagination) {
      const tablePagination = props.tablePagination;
      resultPagination.current = tablePagination.current ? tablePagination.current : 1;
      resultPagination.pageSize = tablePagination.pageSize ? tablePagination.pageSize : 5;
      resultPagination.total = tablePagination.total ? tablePagination.total : 5;
      resultPagination.size = tablePagination.size ? tablePagination.size : 'small';
    }

    setPagination(resultPagination);
  };

  const processLoading = () => {
    const result = props.isLoading !== undefined ? props.isLoading : true;
    setLoading(result);
  };

  const processTableRowSelection = () => {
    const result: any = props.tableData.tableRowSelectionActive
      ? props.tableData.tableRowSelectionActive
      : false;
    setTableRowSelectionActive(result);
  };

  const processDropdownButton = () => {
    if(props.dropdownButton) {
      const result: any = { visible: false, disabled: false, options: [] };
      result.visible = props.dropdownButton.visible === undefined ? true : props.dropdownButton.visible;
      result.disabled = props.dropdownButton.disabled === undefined ? false : props.dropdownButton.disabled;
      result.options = props.dropdownButton.options
        ? props.dropdownButton.options
        : [{ label: 'Option 1', value: 'option_1' }];
  
      setDropdownButton(result)
    }
  }

  const sendOutputTable = () => {
    if (props.tableOnChange) {
      props.tableOnChange(output);
    }
    output.tableAction = null;
    output.action = null;
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys([...selectedRowKeys]);

    output.selection = selectedRowKeys;
    sendOutputTable();
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
    getCheckboxProps: (record) => {
      return {
        disabled: record.key === 'search-input' || record.key === 'empty-data' || record.key === 'text'
      }
      
    },
  };

  const onClickActionButton = (procedure) => {
    if (props[procedure] && props[procedure].onClick) {
      props[procedure].onClick();
    }
  };

  const onEnterSearchColumn = (value, columnName) => {
    if (!output.searchColumn) {
      output.searchColumn = {};
    }
    output.searchColumn[columnName] = value;

    sendOutputTable();
  };

  const onClickTableActionButton = (record) => {
    output.tableAction = record;
    sendOutputTable();
  };

  const onClickTableApproval = (record, status) => {
    output.tableApproval = {record, status};
    sendOutputTable();
  };

  const onClickLinkActionButton = (record) => {
    output.action = record;
    sendOutputTable();
  };


  const tableOnChange = (pagination, filters, sort, extra) => {
    output.sort = {
      columnKey: sort.columnKey,
      field: sort.field,
      order: sort.order,
    };
    sendOutputTable();
  };

  const paginationOnChange = (page, pageSize) => {
    output.pagination = { page, pageSize };
    sendOutputTable();
  };

  const onRow = (record) => {
    if (props.onRow) {
      props.onRow(record)
    } 
  }

  const AddButton = () => {
    return addButton && addButton.visible ? (
      <Button
        type="primary"
        disabled={addButton.disabled}
        onClick={() => onClickActionButton('addButton')}
      >
        Tambah
      </Button>
    ) : null;
  };

  const ExportButton = () => {
    return exportButton && exportButton.visible ? (
      <Button
        type="primary"
        disabled={exportButton.disabled}
        onClick={() => onClickActionButton('exportButton')}
      >
        Export
      </Button>
    ) : null;
  };

  const DeleteButton = () => {
    return deleteButton && deleteButton.visible ? (
      <Button
        type="primary"
        disabled={deleteButton.disabled}
        onClick={() => onClickActionButton('deleteButton')}
      >
        {deleteButton.name ? deleteButton.name : "Delete"}
      </Button>
    ) : null;
  };
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') >= 7;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 7;
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const ChangeModeButton = () => {
    return changeModeButton && changeModeButton.visible ? (
      <Button
        type="primary"
        disabled={changeModeButton.disabled}
        onClick={() => onClickActionButton('changeModeButton')}
      >
        <CalendarOutlined />
      </Button>
    ) : null
  }

  const DropdownButton = () => {
    const menu = dropdownButton && dropdownButton.visible ? (
      <Menu>
        {dropdownButton?.options?.map((opt) => (
          <Menu.Item
            disabled={opt.disabled}
            onClick={() => opt.onClick()}
            key={opt.key}
          >
            {opt.label}
          </Menu.Item>
        ))}
      </Menu>
    ) : null

    return dropdownButton && dropdownButton.visible ? (
      <Dropdown
        disabled={dropdownButton.disabled}
        overlay={menu}
      >
        <Button
          type='primary'
        >
          Tindakan <DownOutlined />
        </Button>
      </Dropdown>
    ) : null
  };

  return (
    <div id="landing-list-template">
      <Breadcrumb />

      <div className="toolbar">
        <Space>
          <AddButton />
          <ExportButton />
          <DeleteButton />
        </Space>
        <DropdownButton />
        <Col
          className={changeModeButton &&
            changeModeButton.visible ? 'left-section'
            : ''}
        >
          <ChangeModeButton />
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            size={pagination.size}
            showSizeChanger={false}
            onChange={paginationOnChange}
          />
        </Col>
      </div>
      {
        props.isDateFilter && (
          <div className="date-wrapper">
            <span>Periode </span>
            <RangePicker
              placeholder={['Dari', 'Sampai']}
              value={dates || value}
              disabledDate={ !props.isDisabledDate ? disabledDate : null}
              onCalendarChange={(val) => {
                setDates(val);
              }}
              onChange={(val) => {
                setValue(val);
                props.onChangeDateFilter(val);
              }}
              onOpenChange={onOpenChange}
              allowClear={false}
            />
          </div>
        )
      }
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        rowSelection={tableRowSelectionActive ? rowSelection : null}
        onChange={tableOnChange}
        scroll={props.tableScroll ? props.tableScroll : false}
        onRow={(record) => ({
          onClick: () => onRow(record),
        })}
      />

      <BottomLoading active={loading} />
    </div>
  );
}
