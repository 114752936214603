import { Col, Form, Row, Space, Table, Tabs } from "antd"
import { SubmissionOvertimeTemplatePropsType } from "./AddSubmissionOvertimeTemplate.interface"
import BottomLoading from "../../../../../components/bottom-loading/BottomLoading"
import FormBuilder from "antd-form-builder"
import ToolbarOvtConfigDetailTemplate from "../overtime-configuration-template/components/toolbar-configuration-ovt-template/ToolbarOvtConfigDetailTemplate"
import Breadcrumb from "../../../../../components/breadcrumb/Breadcrumb"
import { DateTimeSubmissionOvertime } from "./components/DateTimeSubmissionOvertime"
import moment from "moment"
import { DurationSubmissionOvertime } from "./components/DurationSubmissionOvertime"
import { MemoizeHistoryTabs } from "../../../component/history-payroll-period-tabs/HistoryPayrollPeriodTabs"
import { useEffect } from "react"
import { EmployeeNameSubmissionOvertime } from "./components/EmployeeNameSubmissionOvertime"
import { getDuration, validateStartTime } from "./AddSubmissionOvertimeTemplate.service"
import DynamicStepsDetail from "./components/dynamic-steps/DynamicStepsDetail"
import ToolbarButtonDetailTemplate from "../../../component/toolbar-button-detail-template/ToolbarButtonDetailTemplate"
import "./AddSubmissionOvertimeTemplate.less"

export default function AddSubmissionOverTimeTemplate(props: SubmissionOvertimeTemplatePropsType) {
  const [form] = Form.useForm()
    const columns = [
    { title: 'Nama', dataIndex: 'employee_name' },
    { title: 'Jabatan', dataIndex: 'jabatan' },
  ];

  useEffect(() => {
    form.setFieldsValue({ ...props.dataSource })
  }, [props.dataSource])

  const onFinish = (value) => {
    if (props.onFinish) {
      props.onFinish(form.getFieldsValue());
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (props.onFinishFailed) {
      props.onFinishFailed(form.getFieldsValue());
    }
  };

  const onFieldsChange = (changedFields, allFields) => {
    if (props.onFieldChange) {
      props.onFieldChange(changedFields, form);
    }
  };

  const ApprovalTab = ({ dataSource }) => {
    return <Table columns={columns} dataSource={dataSource} pagination={false} />;
  };

  const meta = {
    columns: 1,
    formItemLayout: { labelCol: { span: 4 }, wrapperCol: { span: 11 } },
    initialValues: { obj: { input: 100 } },
    colon: false,
    fields: [
      {
        key: 'employee',
        label: 'Karyawan',
        required: true,
        message: 'Nama Karyawan Harus Diisi',
        viewMode: props.viewMode || props.isMySubmission,
        initialValue: props.dataSource?.employee || "",
        widget: EmployeeNameSubmissionOvertime,
        widgetProps: {
          options: [...props.employeeList],
          isLoading: props.isLoading,
        },
      },
      {
        key: 'submission_date',
        label: 'Tanggal Pengajuan',
        readOnly: true,
        initialValue: initialValueSubmission(),
      },
      {
        key: 'start_time',
        label: 'Waktu Mulai',
        disabled: props.isLoading,
        viewMode: props.viewMode,
        widget: DateTimeSubmissionOvertime,
        initialValue: props.dataSource?.start_time || "",
        widgetProps: { onChange: (evt) => getDuration(evt, 'end_time', form) },
        rules: [
          { required: true, message: '' },
          { validator: (_, value) => validateStartTime(value, form) }
        ],
      },
      {
        key: 'end_time',
        label: 'Waktu Berakhir',
        disabled: props.isLoading,
        viewMode: props.viewMode,
        required: true,
        message: 'Waktu Berakhir Harus Diisi',
        widget: DateTimeSubmissionOvertime,
        initialValue: props.dataSource?.end_time || "",
        widgetProps: {
          onChange: (evt) => {
            form.validateFields(['start_time'])
            getDuration(evt, 'start_time', form)
          }
        }
      },
      {
        key: 'duration',
        label: 'Total Durasi',
        widget: DurationSubmissionOvertime,
        viewMode: false,
      },
      {
        key: 'overtime_reason',
        label: 'Alasan Lembur',
        widget: 'textarea',
        required: true,
        message: 'Alasan Lembur Harus Diisi',
        disabled: props.isLoading,
        viewMode: props.viewMode,
        initialValue: props.dataSource?.overtime_reason || "",
      },
    ]
  }

  function initialValueSubmission(){
    if (!props.viewMode) {
      return moment().format("YYYY-MM-DD")
    }

    return props.dataSource?.date_request
  }

  return (
    <div>
      <Breadcrumb breadcrumbConf={props.breadcrumbConf} />

      <Form
        name="basic"
        layout="horizontal"
        form={form}
        labelAlign="left"
        style={{ width: '100%' }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={onFieldsChange}
      >
        <Row justify="space-between" align="middle">
          <ToolbarButtonDetailTemplate
            submitButton={props.submitButton}
            firstActionButton={props.firstActionButton}
            secondActionButton={props.secondActionButton}
            thirdActionButton={props.thirdActionButton}
            fourthActionButton={props.fourthActionButton}
            dropdownActionButton={props.dropdownActionButton}
          />

          <DynamicStepsDetail activeStep={props.activeSteps} steps={props.steps} />
        </Row>

        <div className="scrollable-content">
          <div className="form-wrapper">
            <FormBuilder
              meta={meta}
              form={form}
              viewMode={false}
              disabled={props.isLoading}
            />
            {props.historyId ? (
              <div className="tabs-wrapper">
                <Tabs defaultActiveKey="1" type="card">
                  <Tabs.TabPane tab="Persetujuan" key="1">
                    <Row>
                      <Col span={4}>
                        <label className="label-table">Persetujuan Satu</label>
                      </Col>
                      <Col span={7}>
                        <ApprovalTab key='approval-satu' dataSource={props.approval1} />
                      </Col>
                      <Col span={4}>
                        <label className="label-table">Persetujuan Dua</label>
                      </Col>
                      <Col span={7}>
                        <ApprovalTab key='approval-dua' dataSource={props.approval2} />
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="History" key="2">
                    <MemoizeHistoryTabs history_id={props.historyId} />
                  </Tabs.TabPane>
                </Tabs>
              </div>
            ) : null}
          </div>
        </div>
      </Form>

      <BottomLoading active={props.isLoading} />
    </div>
  )
}