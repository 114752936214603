import moment from 'moment';
import { DetailMySubmissionClaimType } from '../../../pages/hr/claim/template/AddMySubmissionClaimTemplate.interface';
import { LeaveStatus } from '../../entity/Leave.entity';
import { GetDetailSubmissionClaimInterface } from './ListClaimMapper.interface';

export function getDetailClaimMapper(
  detailData: GetDetailSubmissionClaimInterface,
  detailPlafon,
): DetailMySubmissionClaimType {
  return {
    name: detailData.name,
    employee: `${detailData.nik} - ${detailData.employee_name}`,
    submission_date: moment(detailData.date_claim).format('YYYY-MM-DD'),
    claim_type: detailData.claim_type,
    claim_limit: detailPlafon.claim_limit,
    sisa_plafon: detailPlafon.claim_rem,
    submission_total: detailData.total_value,
    state: MappingActiveState(detailData.state),
    history_id: detailData.history_id,
    is_active: detailData.is_active,
    detail: detailData.description,
    approval1: detailData.approval1,
    approval2: detailData.approval2,
    tabs_wrapper: {
      dataSource: mappingDataSource(detailData.detail_claim),
      total: detailData.total_value,
    },
  };
}

function mappingDataSource(dataSource) {
  return dataSource?.map((data) => {
    return {
      key: data.claim_id,
      date_start: moment(data.date_start),
      date_end: moment(data.date_end),
      information: data.description,
      lampiran: data.lampiran ? {
        file: { name: data.filename },
        url: data.lampiran
      } : null,
      total: data.total,
    };
  });
}

function MappingActiveState(state) {
  switch (state) {
    case 'submit':
      return 'Pengajuan';
    case 'wait-approve2':
      return 'Persetujuan Ke-2';
    case 'reject':
      return 'Ditolak';
    case 'rejected':
      return 'Ditolak';
    case 'cancel':
      return "Batal"
    case 'done':
      return 'Selesai';
    case 'paid':
      return 'Dibayar';
    default:
      return null;
  }
}
