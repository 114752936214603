import { Button, Result } from 'antd';
import { StopOutlined } from '@ant-design/icons';


export default function NoAccess(props) {
  return (
    <Result
      style={{marginTop: '15%'}}
      icon={<StopOutlined />}
      title="Tidak Ada Akses"
      subTitle="Anda tidak mempunyai akses ke menu ini"
      extra={[
        <Button type="primary" key="console" onClick={() => props.history.replace('/')}>
          Kembali
        </Button>,
      ]}
    />
  );
}
