import uploadImage from './UploadImage.interaction';
import {
  UploadAllImageDTO,
  UploadAllImageResp,
} from '../../interface/asset/UploadAllImage.interface';

export default function uploadAllImage(archiveImg: UploadAllImageDTO): Promise<UploadAllImageResp> {
  return new Promise(async (resolve, reject) => {
    let totalImage = 0;
    let count = 0;
    const images = [];

    function checkUrlImages() {
      setTimeout(() => {
        if (totalImage === count) {
          resolve(images);
        } else {
          checkUrlImages();
        }
      }, 2000);
    }

    archiveImg.forEach((img) => {
      if (img) {
        totalImage += 1;
        uploadImage(img).then((res: any) => {
          images.push({ url: res.data.content.url });
          count += 1;
        });
      }
    });

    checkUrlImages();
  });
}
