import { useEffect, useState } from "react";
import { ToolbarDetailActionButton, ToolbarDetailDropdownButton } from "../../../../component/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface";
import AddEmployeeSubmissionOvertimeUsecase from "./DetailEmployeeApprovalOvertime.usecase";
import AddSubmissionOverTimeTemplate from "../../../templates/submission-overtime-template/AddSubmissionOvertimeTemplate";
import ToastService from "../../../../../../services/Toast.service";
import LocalNotificationService from "../../../../../../services/LocalNotification.service";
import { DetailApprovalOvertimeMapper, DetailSubmissionOvertimeMapper } from "../../../../../../@core/mapper/overtime/ListSubmissionOvertimeMapper";
import { Modal } from "antd";

const usecase = new AddEmployeeSubmissionOvertimeUsecase()
const toastService = new ToastService()
const localNotificationService = new LocalNotificationService()
const steps = [
  "Pengajuan", "Disetujui",
  "Persetujuan Ke-2", "Ditolak",
  "Batal", "Selesai"
]
let state: "approve" | "reject"

export default function DetailEmployeeApprovalOvertime({ match }) {
  const overtimeId = match.params.overtimeId

  // state view
  const [isLoading, setIsLoading] = useState(false)
  const [viewMode, setViewMode] = useState(true)
  const [detailDataSource, setDetailData] = useState<any>()
  const [visibleWarningModal, setVisibleWarningModal] = useState(false)

  useEffect(() => {
    init()
  }, [])

  function init() {
    setIsLoading(true)
    usecase.getDetail(overtimeId).then(
      (res) => {
        setIsLoading(false)
        const content = res.data.content

        const result = DetailApprovalOvertimeMapper(content)
        setDetailData({...result})
      }
    ).catch(err => {
      setIsLoading(false)
    })
  }

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Persetujuan Lembur',
        href: "/v2/app/hr-overtime/employee-approval-overtime",
      },
      {
        clickable: false,
        displayPathName: detailDataSource?.submissionNumber,
        href: "/v2/app/hr-overtime/employee-approval-overtime/new/",
      }
    ],
  };

  const firstActionButton: ToolbarDetailActionButton = {
    label: 'SETUJU',
    type: 'primary',
    active: true,
    disabled: isLoading,
    onClick: () => {
      state = "approve"
      setVisibleWarningModal(true)
    },
  };

  const secondActionButton: ToolbarDetailActionButton = {
    label: 'TOLAK',
    type: 'primary',
    active: true,
    disabled: isLoading,
    onClick: () => {
      state = "reject"
      setVisibleWarningModal(true)
    },
  };

  const thirdActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: isLoading,
    onClick: () => {
      window.location.href = "/v2/app/hr-overtime/employee-approval-overtime"
    },
  };

  const approveData = () => {
    setIsLoading(true)
    const body = { id: overtimeId, state }

    usecase.approve(body).then(
      (res) => {
        const content = res.data.content
        if (content.code === 200) {
          localNotificationService.openSuccess('Pengajuan Lembur telah ' + state ? 'disetujui' : 'ditolak', '', 1)
        }
        setTimeout(() => {
          window.location.href = "/v2/app/hr-overtime/employee-approval-overtime"
        }, 1500)
      }).catch(err => {
        setIsLoading(false)
        toastService.error(err)
      })
  }

  return (
    <div className="add-employee-submission-overtime">
      <Modal
        cancelText="BATALKAN"
        centered={true}
        visible={visibleWarningModal}
        cancelButtonProps={{ type: 'ghost' }}
        onOk={approveData}
        onCancel={() => setVisibleWarningModal(false)}
      >
        <div style={{
          display: "flex",
          width: '100%',
          flexDirection: "column",
          alignItems: 'center',
        }}>
          <p>
            Apakah anda yakin ingin 
            {state === "approve" ? ' menyetujui ' : ' menolak '} 
            Pengajuan Lembur
          </p>
          <a href="">{detailDataSource?.submissionNumber}</a>
        </div>
      </Modal>
      <AddSubmissionOverTimeTemplate
        breadcrumbConf={breadcrumbConf}
        isDetail={!!overtimeId}
        dataSource={detailDataSource?.dataSource}
        activeSteps={detailDataSource?.activeSteps}
        approval1={detailDataSource?.approval1}
        approval2={detailDataSource?.approval2}
        historyId={detailDataSource?.historyId}
        steps={steps}
        viewMode={viewMode}
        isLoading={isLoading}
        employeeList={[]}
        submitButton={{ active: false }}
        firstActionButton={firstActionButton}
        secondActionButton={secondActionButton}
        thirdActionButton={thirdActionButton}
        fourthActionButton={{ active: false }}
        dropdownActionButton={{ visible: false }}
      />
    </div>
  )
}