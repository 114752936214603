import uploadFile from "./UploadFIle.interaction";
import uploadImage from "./UploadImage.interaction";

export default function uploadAllFile(archiveFile): Promise<any> {
  return new Promise(async (resolve, reject) => {
    let totalFile = 0;
    let count = 0;
    const files = [];

    function checkUrlImages() {
      setTimeout(() => {
        if (totalFile === count) {
          resolve(files);
        } else {
          checkUrlImages();
        }
      }, 2000);
    }

    archiveFile.forEach((acvFile) => {
      const lampiran = acvFile.lampiran;
      totalFile += 1;
      if (lampiran) {
        const file = lampiran.file
        if (file) {
          if (file.type?.split('/')[0] === 'image') {
            uploadImage(file).then((res: any) => {
              files.push({ ...acvFile, lampiran: res.data.content.filepath });
              count += 1;
            }).catch(err => reject(err));
          } else {
            uploadFile(file).then((res: any) => {
              files.push({ ...acvFile, lampiran: res.data.content.filepath });
              count += 1;
            }).catch(err => reject(err));
          }
        } 
      } else {
        files.push({ ...acvFile, lampiran: undefined });
        count += 1;
      }
    });

    checkUrlImages();
  });
}
