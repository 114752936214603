import { useState, useEffect } from 'react';
import { Button, Space, Form, Col, Menu, Dropdown, Row } from 'antd';
import './ToolbarButtonDetailTemplate.less';
import {
  ToolbarDetailActionButton,
  ToolbarDetailDropdownButton,
  ToolbarDetailSubmitButton,
  ToolbarDetailTemplatePropsType,
} from './ToolbarButtonDetailTemplate.interface';
import { DownOutlined } from '@ant-design/icons';
import StepsLeaveDetail from '../../../leaves/templates/employee-leave-detail-template/components/steps/StepsLeaveDetail';

export default function ToolbarButtonDetailTemplate(props: ToolbarDetailTemplatePropsType) {
  let [firstActionButton, setFirstActionButton] = useState<ToolbarDetailActionButton>();
  let [secondActionButton, setSecondActionButton] = useState<ToolbarDetailActionButton>();
  let [thirdActionButton, setThirdActionButton] = useState<ToolbarDetailActionButton>();
  let [fourthActionButton, setFourthActionButton] = useState<ToolbarDetailActionButton>();
  let [dropdownButton, setDropdownButton] = useState<ToolbarDetailDropdownButton>();
  let [submitButton, setSubmitButton] = useState<ToolbarDetailSubmitButton>();

  useEffect(() => {
    processActionButtonProps('firstActionButton');
  }, [props.firstActionButton]);
  useEffect(() => {
    processActionButtonProps('secondActionButton');
  }, [props.secondActionButton]);
  useEffect(() => {
    processActionButtonProps('thirdActionButton');
  }, [props.thirdActionButton]);
  useEffect(() => {
    processActionButtonProps('fourthActionButton');
  }, [props.fourthActionButton]);
  useEffect(() => {
    processDropdownButton();
  }, [props.dropdownActionButton]);
  useEffect(() => {
    processSubmitButton();
  }, [props.submitButton]);

  const processActionButtonProps = (procedure) => {
    const result: any = {
      label: 'Untitled Button',
      type: 'primary',
      active: true,
      disabled: false,
    };

    if (props[procedure]) {
      result.label = props[procedure].label ? props[procedure].label : 'Untitled Button';
      result.type = props[procedure].type ? props[procedure].type : 'primary';
      result.active = props[procedure].active !== undefined ? props[procedure].active : true;
      result.disabled = props[procedure].disabled !== undefined ? props[procedure].disabled : false;
    }

    switch (procedure) {
      case 'firstActionButton':
        setFirstActionButton(result);
        break;
      case 'secondActionButton':
        setSecondActionButton(result);
        break;
      case 'thirdActionButton':
        setThirdActionButton(result);
        break;
      case 'fourthActionButton':
        setFourthActionButton(result);
        break;
      default:
        break;
    }
  };

  const processDropdownButton = () => {
    const result: any = { visible: true, disabled: false, options: [] };

    result.visible =
      props.dropdownActionButton.visible === undefined ? true : props.dropdownActionButton.visible;
    result.disabled =
      props.dropdownActionButton.disabled === undefined
        ? false
        : props.dropdownActionButton.disabled;
    result.options = props.dropdownActionButton.options
      ? props.dropdownActionButton.options
      : [{ label: 'Option 1', value: 'option_1' }];

    setDropdownButton(result);
  };

  const processSubmitButton = () => {
    const result: any = {
      active: true,
      disabled: false,
    };

    if (props.submitButton) {
      result.active = props.submitButton.active !== undefined ? props.submitButton.active : true;
      result.disabled =
        props.submitButton.disabled !== undefined ? props.submitButton.disabled : false;
    }

    setSubmitButton(result);
  };

  const onClick = (procedure) => {
    if (props[procedure] && props[procedure].onClick) {
      props[procedure].onClick(procedure);
    }
  };

  const FirstActionButton = () => {
    return firstActionButton && firstActionButton.active ? (
      <Button
        type={firstActionButton.type}
        disabled={firstActionButton.disabled}
        onClick={() => onClick('firstActionButton')}
      >
        {firstActionButton.label}
      </Button>
    ) : null;
  };

  const SecondActionButton = () => {
    return secondActionButton && secondActionButton.active ? (
      <Button
        type={secondActionButton.type}
        disabled={secondActionButton.disabled}
        onClick={() => onClick('secondActionButton')}
      >
        {secondActionButton.label}
      </Button>
    ) : null;
  };

  const ThirdActionButton = () => {
    return thirdActionButton && thirdActionButton.active ? (
      <Button
        type={thirdActionButton.type}
        disabled={thirdActionButton.disabled}
        onClick={() => onClick('thirdActionButton')}
      >
        {thirdActionButton.label}
      </Button>
    ) : null;
  };

  const FourthActionButton = () => {
    return fourthActionButton && fourthActionButton.active ? (
      <Button
        type={fourthActionButton.type}
        disabled={fourthActionButton.disabled}
        onClick={() => onClick('fourthActionButton')}
      >
        {fourthActionButton.label}
      </Button>
    ) : null;
  };

  const SubmitButton = () => {
    return submitButton && submitButton.active ? (
      <Form.Item style={{ marginBottom: '0px' }}>
        <Button
          type="primary"
          htmlType="submit"
          disabled={submitButton.disabled}
          onClick={() => onClick('submitButton')}
        >
          SIMPAN
        </Button>
      </Form.Item>
    ) : null;
  };

  const DropdownButton = () => {
    const menu =
      dropdownButton && dropdownButton.visible ? (
        <Menu>
          {dropdownButton.options.map((opt) => (
            <Menu.Item disabled={opt.disabled} onClick={() => opt.onClick(opt.key)} key={opt.key}>
              {opt.label}
            </Menu.Item>
          ))}
        </Menu>
      ) : null;

    return dropdownButton && dropdownButton.visible ? (
      <Dropdown disabled={dropdownButton.disabled} overlay={menu}>
        <Button type="primary">
          TINDAKAN <DownOutlined />
        </Button>
      </Dropdown>
    ) : null;
  };

  return (
    <div className="toolbar-leave-detail-template">
      <div className="toolbar-row-one">
        <Space style={{ margin: '20px 0px', display: 'flex', justifyContent: 'space-between' }}>
          <Space style={{ width: '35vw' }}>
            <SubmitButton />
            <FirstActionButton />
            <SecondActionButton />
            <ThirdActionButton />
            <FourthActionButton />
          </Space>
          <Space>
            <DropdownButton />
            {props.stepState ? <div style={{ marginLeft: '100px' }} className="step-leave-detail-wrapper">
              <StepsLeaveDetail activeStep={props.stepState} />
            </div> : ""}
          </Space>
        </Space>
      </div>
    </div>
  );
}
