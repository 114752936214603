import { useForm } from 'antd/lib/form/Form';
import UploadBannerTemplate from '../template/UploadBannerTemplate';
import { useEffect, useState } from 'react';
import { UploadBannerUsecase } from './UploadBanner.usecase';
import { ToolbarDetailActionButton } from '../../../hr/component/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface';
import uploadImage from '../../../../@core/interaction/asset/UploadImage.interaction';
import moment from 'moment';
import ToastService from '../../../../services/Toast.service';
import { BodyUploadBannerType } from './UploadBanner.interface';

const usecase = new UploadBannerUsecase();
const toastService = new ToastService();

export default function UploadBanner() {
  const [form] = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [listPosition, setListPosition] = useState([]);

  useEffect(() => {
    init()
  }, [])

  function init() {
    setIsLoading(true)
    usecase.getPosition().then(
      (res) => {
        const content = res.data.content
        setListPosition(content)
        setIsLoading(false)
      }
    )
  }

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Banner Promosi',
        href: '/v2/app/admin/banner-list',
      },
      {
        clickable: false,
        displayPathName: 'Upload',
        href: '/v2/app/admin/banner/new/',
      },
    ],
  };

  const submitButton = { active: true, disabled: isLoading };
  const firstActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: false,
    onClick: () => {
      const back = window.confirm('Anda Ingin Kembali');
      if (back) {
        window.location.href = '/v2/app/admin/banner-list';
      }
    },
  };

  const onFieldsChange = (e) => {
    if (e.length === 1) {
      const fieldName = e[0].name[0];
      const value = e[0].value;

      switch (fieldName) {
        default:
          break;
        case 'upload-file':
          form.setFieldsValue({ img: value.file || null });
          break;
      }
    }
  };

  const onFinish = async (e) => {
    setIsLoading(true)
    const image_content = (await uploadImage(e.upload_file?.file)).data.content
    const image_url = image_content?.filepath?.split("/hira")[1]

    const body: BodyUploadBannerType = {
      name: e.name,
      image_url,
      position: e.position,
      action_type: 2,
      redirection: e.redirect_link,
      platform_type: 1,
      content: e.content,
      start_date: moment(e.start_date).format("YYYY-MM-DD HH:mm:ss"),
      end_date: moment(e.end_date).format("YYYY-MM-DD HH:mm:ss"),
      is_active: true,
      is_repeated: false,
    };

    usecase.create(body).then(
      (res) => {
        const content = res.data.content
        window.location.href = '/v2/app/admin/banner/' + content.id
      }
    ).catch(err => {
      const message = err?.data?.error?.message
      toastService.error(message)
      setIsLoading(false)
    })
  };

  return (
    <div className="upload-banner">
      <UploadBannerTemplate
        breadcrumbConf={breadcrumbConf}
        submitButton={submitButton}
        firstActionButton={firstActionButton}
        secondActionButton={{ active: false }}
        thirdActionButton={{ active: false }}
        fourthActionButton={{ active: false }}
        dropdownActionButton={{ visible: false }}
        listPosition={listPosition}
        onFieldChange={onFieldsChange}
        onFinish={onFinish}
        isLoading={isLoading}
      />
    </div>
  );
}
