import Constant from "../../../../../config/Constants";
import API from "../../../../../services/API.service";

export default class UserListUsecase {

  getUserList(params) {
    const URL = Constant.URL_BASE_USER+ "/v3/users"
    return API.get(URL, { params })
  }

  export(params) {
    const URL =  Constant.URL_BASE_USER + "/v3/users/export"
    return API.get(URL, { params })
  }

  changeStatus(body) {
    const URL = Constant.URL_BASE_USER + `/v3/users/active`
    return API.put(URL, body)
  }
}