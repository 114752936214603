import { useEffect, useState } from 'react';
import LocalNotificationService from '../../../../services/LocalNotification.service';
import HrLeaveService from '../HrLeaveService';
import SharedService from '../../../../services/Shared.service';
import ToastService from '../../../../services/Toast.service';
import ErrorButtonHover from '../../../../templates/template-bulk-upload/component/ErrorButtonHover';
import ErrorDetailButton from '../../../../templates/import-csv-template/component/error-detail-button/ErrorDetailButton';
import { LeaveCorrectionTableColumn } from './ImportLeaveCorrection.constant';
import ImportExcelFileTemplate from '../../../../templates/import-excel-file/ImportExcelFileTemplate';
import ImportLeaveCorrectionUseCase from './ImportLeaveCorrection.usecase';
import { mapperExcelData } from './ImportLeaveCorrection.service';

const localNotificationService = new LocalNotificationService();
const hrLeaveService = new HrLeaveService();
const sharedService = new SharedService();
const toastService = new ToastService();
let selectedFile;
let body;

export default function ImportLeaveCorrection() {
  const useCase = new ImportLeaveCorrectionUseCase()

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Koreksi Cuti',
        href: `/v2/app/hr-leave/correction`,
      },
      {
        clickable: false,
        displayPathName: 'Import',
        href: '/v2/app/hr-leave/employee-approval/new',
      },
    ],
  };
  let [isLoading, setIsLoading] = useState(false);
  let [disabledValidationButton, setDisabledValidationButton] = useState(true);
  let [disabledSaveButton, setDisabledSaveButton] = useState(true);
  let [disabledDownloadTemplateButton] = useState(false);
  let tableHeader = LeaveCorrectionTableColumn
  let [tableData, setTableData] = useState([]);
  let [tableScroll, setTableScroll] = useState({ y: 200, x: 'max-content' });

  useEffect(() => {
    tableHeader[0].render = (status, record) => {
      return <ErrorButtonHover status={status} record={record} />
    };
    
    for (let index = 1; index <= 8; index++) {
      tableHeader[index].render = (text, record) => {
        return {
          props: {
            style: {
              background:
                record.error_list[tableHeader[index].key] ?
                  "rgba(200, 0, 0, 0.2)" : ""
            }
          },
          children: <div>{text}</div>
        };
      }
    }
    
    tableHeader[9].render = (text, record) => {
      return !record.status ? <ErrorDetailButton data={record} /> : null;
    };

    const clientHeight = document.getElementById('import-leave-correction').clientHeight;
    setTableScroll({ y: clientHeight - 310, x: 'max-content' });

    selectedFile = undefined;
  }, []);

  const onFileSelected = (e: any) => {
    setTableData([...[]]);

    if (e.status === 'success') {
      setDisabledValidationButton(false);
      setDisabledSaveButton(true);
      selectedFile = e;
    } else {
      setDisabledValidationButton(true);
      setDisabledSaveButton(true);

      const error_message = e.content.error_message;
      toastService.error(error_message);
    }
  };

  const onClickValidate = () => {
    setIsLoading(true);
    const excelFile = selectedFile.content.excel_file
    useCase.validateToServer(excelFile).then(
      (result) => {
        const byte = result.data.content.data
        hrLeaveService.downloadFromByteExcel(byte).then(
          (resolve: any) => {
            if (resolve.status === 'success') {
              const excelData = resolve.content.excel_arrays
              setDisabledValidationButton(true)
              mapperExcelData(excelData).then(
                (res: any) => {
                  setDisabledSaveButton(res.status === 'error')
                  const dataSource = res.dataSource
                  setTableData([...dataSource])
                  setIsLoading(false)
                }
              )
            }
          }
        ).catch(err => {
          toastService.error(err.message)
        })        
      }
    )
  };


  const onClickSave = () => {
    setIsLoading(true);
    const excelFile = selectedFile.content.excel_file
    useCase.saveSchedule(excelFile).then(
      (res: any) => {
        const data = res.data;

        if (data.status === 200) {
          localNotificationService.openSuccess('Impor data berhasil.', '', 3);

          setTimeout(() => {
            const backLink = `/v2/app/hr-leave/correction`;
            window.location.href = backLink
          }, 1500);
        } else {
          setIsLoading(false);
        }
      },
      (err: any) => {
        setIsLoading(false);
      },
    );
  };

  const onClickDownloadTemplate = () => {
    setIsLoading(true);

    useCase.downloadTemplate().then((res: any) => {
      setIsLoading(false);
      const data = res.data;

      if (data.status === 200) {
        hrLeaveService.createAndExecuteHref(data.content.url);
      }
    });
  };

  return (
    <div id="import-leave-correction">
      <ImportExcelFileTemplate
        breadcrumbConf={breadcrumbConf}
        chooseFileButton={{
          onSelected: onFileSelected,
        }}
        validateButton={{
          disabled: disabledValidationButton || isLoading,
          onClick: onClickValidate,
        }}
        saveButton={{
          disabled: disabledSaveButton || isLoading,
          onClick: onClickSave,
        }}
        downloadTemplateButton={{
          disabled: disabledDownloadTemplateButton || isLoading,
          onClick: onClickDownloadTemplate,
        }}
        tableHeader={tableHeader}
        tableData={tableData}
        tableScroll={tableScroll}
        isLoading={isLoading}
      />
    </div>
  );
}
