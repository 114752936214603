import { useHistory } from 'react-router-dom';
import { Card } from 'antd';


export default function News(props) {
  const { data } = props;
  const history = useHistory();

  const style = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical' as const,
    WebkitLineClamp: 2,
    lineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 'normal',
  }

  return (
    <Card title="Berita" extra={<a href='/v2/app/employee/list-news' style={{ fontWeight: 'bold' }}>Lihat Semua</a>} style={{ minWidth: '100%', minHeight: '100%' }}>
      {data.map(item=> {
        return(
          <div style={{ marginBottom: 30, cursor: 'pointer' }} onClick={() => history.push(`/app/employee/detail-news/${item.id}`)}>
            <p style={{fontWeight: 'normal', fontSize: 12 }}>{item.start_date}</p>
            <p style={style}>{item.news_name}</p>
          </div>
        )
      })}
    </Card>
  );
}
