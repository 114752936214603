import Constant from '../../../config/Constants';
import API from '../../../services/API.service';

export function getRecapAttendance(params) {
  const URL = Constant.URL_BASE_ATTENDANCE + '/v3/absensi/recap';
  return API.get(URL, { params });
}

export function getMyRecapAttendance(params) {
  const URL = Constant.URL_BASE_ATTENDANCE + '/v3/absensi/me/recap';
  return API.get(URL, { params });
}