import { GetJobListDTO } from '../../interface/job/GetJobList.interface';
import { JobListObjById } from '../../entity/Job.entity';
import {
  GetLeaveDetailDTO,
  GetLeaveDetailResp,
} from '../../interface/leave/GetLeaveDetail.interface';
import { LeaveId } from '../../entity/Leave.entity';
import {
  UpdateLeaveStateResp,
  UpdateLeaveStateDTO,
} from '../../interface/leave/UpdateLeaveState.interface';
import { LeaveDetailApproval } from '../../entity/SubmissionLeaveDetail.entity';

export abstract class EmployeeApprovalLeaveDetailUseCase {
  abstract getJobList(params: GetJobListDTO): Promise<JobListObjById>;
  abstract getLeaveDetail(params: GetLeaveDetailDTO): Promise<GetLeaveDetailResp>;
  abstract getApprovalLeave(
    leaveId: LeaveId,
    jobList: JobListObjById,
  ): Promise<LeaveDetailApproval>;
  abstract approveLeave(params: UpdateLeaveStateDTO): Promise<UpdateLeaveStateResp>;
  abstract rejectLeave(leaveId: LeaveId[]): Promise<UpdateLeaveStateResp>;
}
