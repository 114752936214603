import {
  CreateLeaveRequestDTO,
  CreateLeaveRequestResp,
} from './../../interface/leave/CreateLeaveRequest.interface';
import Constant from '../../../config/Constants';
import API from '../../../services/API.service';

export default function createLeaveRequest(
  body: CreateLeaveRequestDTO,
): Promise<CreateLeaveRequestResp> {
  const URL = Constant.URL_BASE_ATTENDANCE + '/v3/leave/request';
  return API.post(URL, body);
}
