import { useEffect, useState } from 'react';
import LandingListTemplate from '../../../../../../templates/landing-list-template/LandingListTemplate';
import HrLeaveService from '../../../HrLeaveService';
import { LeaveConstant } from '../../../../../../config/Leave.constant';
import { useUserState } from '../../../../../../context/UserContext';
import { LandingListTemplatePagination } from '../../../../../../templates/landing-list-template/LandingListTemplate.interface';
import EmployeeApprovalLeaveUseCaseImpl from './HrEmployeeApprovalLeave.usecase.impl';
import { GetLeavesDTO } from '../../../../../../@core/interface/leave/GetLeaves.interface';
import moment from 'moment';

const hrLeaveService = new HrLeaveService();
const useCaseImpl = new EmployeeApprovalLeaveUseCaseImpl();

let page = 1;
let loadPagination = true;
let sort = 'name|desc';
let name = '';
let nik = '';
let employee = '';
let leave_type_id = [];
let leaveTypes = {};
let leave_config
let leave_all
let selectionLeaveId = []

export default function HrEmployeeApprovalLeave() {
  const userState: any = useUserState();

  const tableHeader: any = LeaveConstant.TABLE_HEADER;
  const searchColumnKey = LeaveConstant.SEARCH_COLUMN_KEY;
  const limit = 10;

  let [pagination, setPagination] = useState<LandingListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });
  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([]);
  let [tableScroll, setTableScroll] = useState({ y: 200 });
  let [disableExportButton, setDisableExportButton] = useState(false);

  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: false };

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {
    loadList();

    const clientHeight = document.getElementById('employee-approval-leave-page').clientHeight;
    setTableScroll({ y: clientHeight - 220 });
  }

  function loadList() {
    setIsLoading(true);

    const params: GetLeavesDTO = {
      page,
      limit,
      sort,
      name,
      nik,
      employee,
      leave_type_id,
      leave_config,
      leave_all,
      with_meta: true,
    };

    useCaseImpl.getAll(params).then(
      (res: any) => {
        setIsLoading(false);

        const content = res.data.content;
        const metaData = res.data.metadata;

        const resultDataSource = content ? mapperApprovalLeave(content) : LeaveConstant.DEFAULT_EMPTY_DATA_SOURCE;
        setDataSource([...resultDataSource]);
        updatePaginationState(metaData.total_records);

        loadPagination = true;
        setDisableExportButton(content ? false : true);
      },
      (err) => {
        setIsLoading(false);
        loadPagination = true;
        setDisableExportButton(true);
      },
    );
  }

  function mapperApprovalLeave(approval) {
    return approval.map(app => {
      return {
        key: app.id,
        numberSubmissionLeave: app.name,
        employeeNumber: app.nik,
        employeeName: app.employee_name,
        submissionDate: moment(app.date_request).format("DD/MM/YYYY"),
        leaveDuration: app.duration,
        leaveConfig: app.leave_type,
        isLeaveAll: app.cuti_bersama,
        leaveStatus: app.lbl_state,
        href: '/v2/app/hr-leave/employee-approval/' + app.id
      }
    })
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    const outputSort = output.sort;
    const outputSearchColumn = output.searchColumn;

    processSort(outputSort);
    processSearchColumn(outputSearchColumn);
    processSelection(output.selection);
    processPagination(outputPagination);
  };

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  function processSort(outputSort) {
    if (outputSort && hrLeaveService.convertOutputSortToEndPointFormat(outputSort) !== sort) {
      page = 1;
      loadPagination = false;

      sort = hrLeaveService.convertOutputSortToEndPointFormat(outputSort);
      loadList();
    }
  }

  function processSearchColumn(outputSearchColumn) {
    let isLoadList = false;

    if (outputSearchColumn) {
      if (
        outputSearchColumn.numberSubmissionLeave !== undefined &&
        outputSearchColumn.numberSubmissionLeave !== name
      ) {
        name = outputSearchColumn.numberSubmissionLeave;
        isLoadList = true;
      }

      if (
        outputSearchColumn.employeeNumber !== undefined &&
        outputSearchColumn.employeeNumber !== nik
      ) {
        nik = outputSearchColumn.employeeNumber;
        isLoadList = true;
      }

      if (
        outputSearchColumn.employeeName !== undefined &&
        outputSearchColumn.employeeName !== employee
      ) {
        employee = outputSearchColumn.employeeName;
        isLoadList = true;
      }

      if (
        outputSearchColumn.isLeaveAll !== undefined &&
        outputSearchColumn.isLeaveAll !== leave_all
      ) {
        leave_all = outputSearchColumn.isLeaveAll
        isLoadList = true
      }

      if (
        outputSearchColumn.leaveConfig !== undefined &&
        outputSearchColumn.leaveConfig !== leave_config
      ) {
        leave_config = outputSearchColumn.leaveConfig
        isLoadList = true
      }

      if (
        outputSearchColumn.leaveType !== undefined &&
        hrLeaveService.convertLeaveTypeIdParam(outputSearchColumn.leaveType, leaveTypes).length !==
          leave_type_id.length
      ) {
        leave_type_id = hrLeaveService.convertLeaveTypeIdParam(
          outputSearchColumn.leaveType,
          leaveTypes,
        );
        isLoadList = true;
      }

      if (isLoadList) {
        page = 1;
        loadPagination = false;
        loadList();
      }
    }
  }

  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionLeaveId) {
      selectionLeaveId = outputSelection;
    }
  }

  const exportButton = {
    onClick: () => {
      setIsLoading(true);

      const params: GetLeavesDTO = {
        id: selectionLeaveId,
        sort,
        name,
        nik,
        employee,
        leave_type_id,
        leave_config,
        leave_all,
        list_user_approval: [userState.auth.user_id],
        with_meta: true,
      };

      useCaseImpl.exportSubmissionLeaves(params).then(
        (res: any) => {
          const data = res.data;
          if (data.status === 200) {
            hrLeaveService.createAndExecuteHref(data.content.url);
          }
          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
        },
      );
    },
    disabled: disableExportButton || isLoading,
  };

  return (
    <div id="employee-approval-leave-page" style={{ height: 'calc(100vh - 100px)' }}>
      <LandingListTemplate
        addButton={{ visible: false }}
        exportButton={exportButton}
        deleteButton={{ visible: false }}
        tableHeader={tableHeader}
        tableData={tableData}
        tablePagination={pagination}
        tableOnChange={tableOnChange}
        isLoading={isLoading}
        tableScroll={tableScroll}
      />
    </div>
  );
}
