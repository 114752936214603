import { LeaveId } from "../../../@core/entity/Leave.entity";
import updateLeaveState from "../../../@core/interaction/leave/UpdateLeaveState.interaction";
import { UpdateLeaveStateDTO, UpdateLeaveStateResp } from "../../../@core/interface/leave/UpdateLeaveState.interface";
import Constant from "../../../config/Constants";
import API from "../../../services/API.service";

export class DashboardManagerUsecase {
  getApprovalLeave(params) {
    const URL = Constant.URL_BASE_ATTENDANCE + '/v3/leave/approval/pending';
    return API.get(URL, { params });
  }

  approveLeave(params: UpdateLeaveStateDTO): Promise<UpdateLeaveStateResp> {
    return updateLeaveState(params);
  }

  rejectLeave(leaveId: LeaveId[]): Promise<UpdateLeaveStateResp> {
    const params: UpdateLeaveStateDTO = {
      leaveId,
      leaveState: 'reject',
    };
    return updateLeaveState(params);
  }

  getApprovalClaim(params) {
    const URL = Constant.URL_BASE_CLAIM + '/v3/request/approval/claim/pending';
    return API.get(URL, { params });
  }

  approveClaim(body, id) {
    const URL = Constant.URL_BASE_CLAIM + '/v3/request/approval/claim/' + id;
    return API.put(URL, body);
  }

  getApprovalLoan(params) {
    const URL = Constant.URL_BASE_LOAN + '/v3/request/loan/approval/pending';
    return API.get(URL, { params });
  }

  approveLoan(body) {
    const URL = Constant.URL_BASE_LOAN + '/v3/request/loan/update';
    return API.put(URL, body);
  }

  getApprovalOvertime(params) {
    const URL = Constant.URL_BASE_ATTENDANCE + '/v3/overtime/approval';
    return API.get(URL, { params });
  }

  approveOvertime(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/overtime/approval"
    return API.post(URL, body)
  }
}
