import { Modal } from "antd";

export default function ModalShowImageLeave(props: any) {
  return (
    <Modal 
      centered 
      visible={props.isShowModal} 
      onCancel={props.showModal} 
      footer={null}
      width={"60rem"}
    >
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <img src={props.imageSrc} width="95%" />
        </div>
    </Modal>
  )
}