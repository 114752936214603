import moment from "moment-timezone";
import { DetailSubmissionClaim } from "../../../pages/hr/claim/template/AddMySubmissionClaimTemplate.interface";
import { AddSubmissionClaimInterface } from "./ListClaimMapper.interface";

export function AddSubmissionClaimMapper(body, employeeList, assets): AddSubmissionClaimInterface {
  const employeeDetail = employeeList.find(e => e.value === body.employee)

  return {
    employee_id: body.employee,
    date_claim: moment().format("YYYY-MM-DD"),
    claim_config_id: body.claim_type,
    description: body.detail,
    nik: employeeDetail?.nik,
    job_id: employeeDetail?.job_id,
    job_name: employeeDetail?.job_name,
    department_id: employeeDetail?.department_id,
    total_request: body.tabs_wrapper.total,
    detail_claim: mapperDetailClaim(assets)
  }
}

function mapperDetailClaim(detail): DetailSubmissionClaim[] {
  const result = detail.map((dt) => {
    return {
      date_start: dt.date_start.format("YYYY-MM-DD"),
      date_end: dt.date_end.format("YYYY-MM-DD"),
      description: dt.information,
      total: dt.total,
      lampiran: dt.lampiran,
    }
  })

  return result
}