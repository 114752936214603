export function convertOutputSortToEndPointFormat(tableOutputSort) {
  let result = '';
  const columnKey = tableOutputSort.columnKey;
  const order = tableOutputSort.order;

  if (order) {
    switch (columnKey) {
      default:
        break;
      case 'employee_name':
        result += 'nama_Karyawan|';
        break;
      case 'effective_date':
        result += 'tanggal_berlaku|';
        break;
      case 'department':
        result += 'departemen|';
        break;
      case 'job':
        result += 'jabatan';
        break;
      case 'structure_name':
        result += 'struktur_gaji|'
        break;
      case 'period_name':
        result += 'nama_periode|';
        break;
      case 'status':
        result += 'status|';
        break;
    }

    switch (order) {
      default:
        break;
      case 'ascend':
        result += 'asc';
        break;
      case 'descend':
        result += 'desc';
        break;
    }
  }

  return result
}

export function createExcelFromByte(base64, fileName) {
  let binaryString = window.atob(base64);
  let binaryLen = binaryString.length;
  let bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    let ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  let blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  let link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;

  link.click()
}
