import { DatePicker, Form } from "antd";
import moment from "moment";
import { DownOutlined } from '@ant-design/icons';

const { MonthPicker } = DatePicker;

export default function MonthPickerField({ name }) {
  return (
    <Form.Item
      name={name}
      initialValue={moment()}
    >
      <MonthPicker
        className="month-picker"
        format="MMM YYYY"
        suffixIcon={<DownOutlined className="primary-icon" />}
        disabledDate={(current) => {
          if (!current) return false;
          return current < moment().subtract(1, 'years').startOf('month') || current > moment().add(1, 'month').startOf('month');
        }}
      />
    </Form.Item>
  );
}
