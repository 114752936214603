import Constant from "../../../../config/Constants"
import API from "../../../../services/API.service"

export class DetailBannerUsecase {
  getDetail(id) {
    const URL = Constant.URL_BASE_MISC + `/res/banner/${id}`
    return API.get(URL)
  }

  update(body, id) {
    const URL = Constant.URL_BASE_MISC + '/v3/res/banner/' + id;
    return API.put(URL, body);
  }
}