import { Button, Calendar, Row, Space, Table } from "antd";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import BottomLoading from "../../components/bottom-loading/BottomLoading";
import { useEffect, useState } from "react";
import { ArrowLeftOutlined, ArrowRightOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { CalendarTemplateButton, CalendarTemplatePagePropsType, CalendarTemplateTodayButton, EventDatesPropsType } from "./CalendarTemplatePage.interface";
import './CalendarTemplatePage.less'

const moment = require('moment');

export default function CalendarTempltePage(props: CalendarTemplatePagePropsType) {
  let [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment());
  const [todayButton, setTodayButton] = useState<CalendarTemplateTodayButton>()
  const [addButton, setAddButton] = useState<CalendarTemplateButton>();
  const [changeModeButton, setChangeModeButton] = useState<CalendarTemplateButton>();
  const [eventDates, setEventDates] = useState<EventDatesPropsType[]>()
  const [breadcrumb, setBreadcrumb] = useState<any>()

  const handleTodayClick = () => {
    setSelectedDate(moment());
  };

  const handlePrevClick = () => {
    setSelectedDate(selectedDate.clone().subtract(1, 'month'));
  };

  const handleNextClick = () => {
    setSelectedDate(selectedDate.clone().subtract(-1, 'month'));
  };

  const isYearly = (date: moment.Moment, eventDate: EventDatesPropsType) => {
    const startDate = moment(eventDate.startDate).date()
    const startMonth = moment(eventDate.startDate).month()
    const startYear = moment(eventDate.startDate).year()
    const endDate = moment(eventDate.endDate).date()
    const endMonth = moment(eventDate.endDate).month()
    const endYear = moment(eventDate.endDate).year()

    if (startYear !== endYear) {
      if (date.month() === startMonth) {
        if (date.date() >= startDate) return true
      }

      if (date.month() === endMonth) {
        if (date.date() <= endDate) return true
      }
    } else {
      if (date.month() >= startMonth) {
        if (date.month() <= endMonth) {
          if (startMonth !== endMonth) {
            if (date.month() === startMonth) {
              if (date.date() >= startDate) return true
            }
  
            if (date.month() === endMonth) {
              if (date.date() <= endDate) return true
            }
          } else {
            if (date.date() >= startDate && date.date() <= endDate) return true
          }
        }
      } 
    }

    return false
  }

  const renderDateCell = (date: moment.Moment) => {
    const event = eventDates && eventDates.length ? eventDates.filter(
    (eventDate) =>
      moment(eventDate.startDate).isSameOrBefore(date, 'day') &&
      moment(eventDate.endDate).isSameOrAfter(date, 'day') ||
      (eventDate.yearly && isYearly(date, eventDate))
    ) : []

    const color = (color: string) => {
      let result = ''
      switch (color) {
        case 'red':
          result = '#d42534'
          break;
        case 'blue':
          result = '#313D97'
          break;
        case 'green':
          result = '#00A567'
          break;
        case 'yellow':
          result = '#ECD715'
          break;
        default:
          break
      }
      return result
    }

    return event && (
      <div className="events">
        {event.map((item) => (
          item.status &&
          <div
            className="event-component"
            style={{ background: color(item.color) }}
            onClick={() => null}
          >
            {item.name}
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    processLoading();
  }, [props.isLoading]);
  useEffect(() => {
    fillActionButtonState('todayButton');
  }, [props.todayButton]);
  useEffect(() => {
    fillActionButtonState('addButton');
  }, [props.addButton]);
  useEffect(() => {
    fillActionButtonState('changeModeButton');
  }, [props.changeModeButton]);
  useEffect(() => {
    procceseventDates()
  }, [props.eventDates])
  useEffect(() => {
    processBreadcrumb()
  }, [selectedDate])

  const fillActionButtonState = (procedure) => {
    const result: any = { visible: true, disabled: false };

    if (props[procedure]) {
      result.visible = props[procedure].visible === undefined ? true : props[procedure].visible;
      result.disabled = props[procedure].disabled === undefined ? false : props[procedure].disabled;
    }

    switch (procedure) {
      case 'todayButton':
        setTodayButton(result);
        break;
      case 'addButton': 
        setAddButton(result)
        break;
      case 'changeModeButton': 
        setChangeModeButton(result)
        break;
      default:
        break;
    }
  };

  const onClickActionButton = (procedure) => {
    if (props[procedure] && props[procedure].onClick) {
      props[procedure].onClick();
    }
  };

  const procceseventDates = () => {
    const result: any = props.eventDates ? props.eventDates : []
    setEventDates([...result])
  }

  const processLoading = () => {
    const result = props.isLoading !== undefined ? props.isLoading : true;
    setLoading(result);
  };

  const processBreadcrumb = () => {
    const result = selectedDate.format('MMMM, YYYY')
    setBreadcrumb(result)
  }

  const AddButton = () => {
    return addButton && addButton.visible ? (
      <Button
        type="primary"
        disabled={addButton.disabled}
        onClick={() => onClickActionButton('addButton')}
      >
        Tambah
      </Button>
    ) : null;
  };

  const TodayButton = () => {
    return todayButton && todayButton.visible ? (
      <Row>
        <Button 
          type="primary" 
          onClick={() => handlePrevClick()}
        >
          <ArrowLeftOutlined />
        </Button>
        <Button
          type="primary"
          style={{ margin: '0 20px'}}
          disabled={todayButton.disabled}
          onClick={() => handleTodayClick()}
        >
          Hari ini
        </Button>
        <Button 
          type="primary" 
          onClick={() => handleNextClick()}
        >
          <ArrowRightOutlined />
        </Button>
      </Row>

    ) : null;
  };

  const ChangeModeButton = () => {
    return changeModeButton && changeModeButton.visible ? (
      <Button
        type="primary"
        disabled={changeModeButton.disabled}
        onClick={() => onClickActionButton('changeModeButton')}
      >
        <UnorderedListOutlined />
      </Button>
    ) : null
  }

  return (
    <div id='calendar-template-page'>
      <Breadcrumb breadcrumbConf={props.breadcrumbConf} />

      <div className="toolbar">
        <Space>
          <AddButton />
          <TodayButton />
        </Space>

        <ChangeModeButton />
      </div>
      
      <div
        style={{
          marginBottom: '20px',
          fontSize: 16,
          fontWeight: 'bold'
        }}
      >
        {breadcrumb}
      </div>

      <Calendar
        value={selectedDate}
        dateCellRender={renderDateCell}
        headerRender={() => null}
      />

      <BottomLoading active={loading} />
    </div>
  )
}