import Constant from "../../../../../../config/Constants";
import API from "../../../../../../services/API.service";

export default class AddEmployeeSubmissionOvertimeUsecase {
  getDetail(id) {
    const URL = Constant.URL_BASE_ATTENDANCE + `/v3/overtime/approval/${id}`
    return API.get(URL)
  }

  approve(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/overtime/approval"
    return API.post(URL, body)
  }
}