// import AttendanceUseCase from '../../../../../../@core/use-case/attendance/Attendance.usecase';
import { recapAttendanceListMapper, recapCalendarListMapper } from '../../../../../../@core/mapper/attendance/RecapAttendanceRequestList.mapper';
import { getMyRecapAttendance } from '../../../../../../@core/interaction/attendance/GetRecapAttendance.interaction';
import exportMyRecapAttendance from '../../../../../../@core/interaction/attendance/ExportMyRecapAttendance.interaction';


export default class MyRecapAttendance {
  getMyRecapAttendance(params, hrefPath) {
    return getMyRecapAttendance(params).then((res: any) =>
      recapAttendanceListMapper(res.data, hrefPath),
    );
  }
  getCalendarData(params) {
    return getMyRecapAttendance(params).then((res: any) =>
      recapCalendarListMapper(res.data),
    );
  }

  exportMyRecapAttendance(params) {
    return exportMyRecapAttendance(params);
  }
}
