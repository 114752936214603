import { Form } from "antd";
import { AddEmployeeRegionTemplatePropsType } from "./AddMasterRegionTemplate.interface";
import Breadcrumb from "../../../../../components/breadcrumb/Breadcrumb";
import FormBuilder from "antd-form-builder";
import BottomLoading from "../../../../../components/bottom-loading/BottomLoading";
import LocationList from "./component/LocationList";
import ToolbarButtonDetailTemplate from "../../../component/toolbar-button-detail-template/ToolbarButtonDetailTemplate";

export default function AddMasterRegionTemplate(props: AddEmployeeRegionTemplatePropsType) {
  const [form] = Form.useForm()

  const meta = {
    columns: 4,
    colon: false,
    fields: [
      {
        key: 'name',
        label: 'Region',
        rules: [{ required: true, whitespace: true, message: "Region harus diisi."}],
        viewMode: props.viewMode,
        colSpan: 4,
        initialValue: props.detailDataSource?.name || "",
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 }
        },
      }
    ]
  }

  const onFinish = (values) => {
    if (props.onFinish) {
      props.onFinish(form.getFieldsValue());
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (props.onFinishFailed) {
      props.onFinishFailed(form.getFieldsValue());
    }
  };

  const onFieldsChange = (changedFields) => {
    if (props.onFieldChange) {
      props.onFieldChange(changedFields, form);
    }
  };

  return (
    <div id="add-master-department-template" style={{ height: 'calc(100vh - 100px)' }}>
      <Breadcrumb breadcrumbConf={props.breadcrumbConf} />

      <Form
        name="basic"
        style={{ width: '100%' }}
        layout="horizontal"
        labelAlign="left"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={onFieldsChange}
      >
        <ToolbarButtonDetailTemplate
          submitButton={{
            ...props.submitButton,
            disabled: props.isLoading
          }}
          firstActionButton={{ ...props.firstActionButton, disabled: props.isLoading }}
          secondActionButton={props.secondActionButton}
          thirdActionButton={props.thirdActionButton}
          fourthActionButton={props.fourthActionButton}
          dropdownActionButton={props.dropdownActionButton}
        />

        <div className="scrollable-content">
          <div className="form-wrapper-schedule">
            <FormBuilder form={form} meta={meta} />
            {props.isDetail ? <LocationList content={props.listTableDataDetail} /> : null}
          </div>
        </div>
      </Form>

      <BottomLoading active={props.isLoading} />
    </div>
  )
}