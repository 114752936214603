import { useEffect, useState } from "react";
import { ToolbarDetailActionButton } from "../../../../component/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface";
import AddEmployeeSubmissionOvertimeUsecase from "./AddEmployeeSubmissionOvertime.usecase";
import AddSubmissionOverTimeTemplate from "../../../templates/submission-overtime-template/AddSubmissionOvertimeTemplate";
import { ValueFormSubmissionOvertimeType } from "../../../templates/submission-overtime-template/AddSubmissionOvertimeTemplate.interface";
import moment from "moment";
import ToastService from "../../../../../../services/Toast.service";
import LocalNotificationService from "../../../../../../services/LocalNotification.service";
import { useUserState } from "../../../../../../context/UserContext";

const usecase = new AddEmployeeSubmissionOvertimeUsecase()
const toastService = new ToastService()
const localNotificationService = new LocalNotificationService()
const steps = [
  "Pengajuan", "Disetujui",
  "Persetujuan Ke-2", "Ditolak",
  "Selesai"
]

export default function AddEmployeeSubmissionOvertime() {
  const userState: any = useUserState();
  
  // state view
  const [isLoading, setIsLoading] = useState(false)
  const [historyId, setHistoryId] = useState(null)
  const [listEmployee, setListEmployee] = useState([])

  useEffect(() => {
    init()
  }, [])

  function init() {
    setIsLoading(true)
    usecase.getEmployeeList({ timezone: userState?.auth?.tz }).then(
      (res) => {
        const content = res.data.content
        if(content?.length) {
          const employeeList = content.map(employee => {
            return {
              value: employee.id,
              label: employee.alternative_name,
            }
          })
          setListEmployee([...employeeList])
        }
        setIsLoading(false)
    })
  }

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Pengajuan Lembur Karyawan',
        href: "/v2/app/hr-overtime/employee-submission-overtime",
      },
      {
        clickable: false,
        displayPathName: 'Baru',
        href: "/v2/app/hr-overtime/employee-submission-overtime/new/",
      }
    ],
  };

  const submitButton = { active: true, disabled: isLoading };
  const firstActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: isLoading,
    onClick: () => {
      window.location.href = "/v2/app/hr-overtime/employee-submission-overtime"
    },
  };

  const onFieldChange = (e) => {
    if (e.length === 1) {
      const fieldName = e[0].name[0];
      const value = e[0].value;

      switch (fieldName) {
        default:
          break;
      }
    }
  }

  const onFinish = (value) => {
    setIsLoading(true)
    const startTime = new Date(value.start_time).setSeconds(0)
    const endTime = new Date(value.end_time).setSeconds(0)
    const body = {
      employee_id: value.employee,
      submission_date: moment().format("YYYY-MM-DD"),
      start_time: moment(startTime).format("YYYY-MM-DD HH:mm"),
      end_time: moment(endTime).format("YYYY-MM-DD HH:mm"),
      duration: value.duration,
      overtime_reason: value.overtime_reason,
    }

    usecase.save(body).then(
      (res) => {
        if (res.data.status === 200) {
          const message = "Pengajuan Lembur Berhasil"
          localNotificationService.openSuccess(message, '', 1)
        }
        setTimeout(() => {
          window.location.href = "/v2/app/hr-overtime/employee-submission-overtime"
        }, 1500)
      }
    ).catch((err) => {
      toastService.error(err)
      setIsLoading(false)
    })
  }

  return (
    <div className="add-employee-submission-overtime">
      <AddSubmissionOverTimeTemplate
        breadcrumbConf={breadcrumbConf}
        historyId={historyId}
        steps={steps}
        activeSteps="Pengajuan"
        employeeList={listEmployee}
        isLoading={isLoading}
        submitButton={submitButton}
        firstActionButton={firstActionButton}
        secondActionButton={{ active: false }}
        thirdActionButton={{ active: false }}
        fourthActionButton={{ active: false }}
        dropdownActionButton={{ visible: false }}
        onFieldChange={onFieldChange}
        onFinish={onFinish}
      />
    </div>
  )
}