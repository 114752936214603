import { useEffect, useState } from "react";
import { ToolbarDetailActionButton } from "../../components/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface";
import AddEmployeeTerminateTemplate from "../termplate/AddEmployeeTerminateTemplate";
import AddEmployeeTerminateUsecase from "./AddEmployeeTerminate.usecase";
import LocalNotificationService from "../../../../../services/LocalNotification.service";
import ToastService from "../../../../../services/Toast.service";
import { GetJobListDTO } from "../../../../../@core/interface/job/GetJobList.interface";
import { SaveEmployeeTerminateMapper } from "../../../../../@core/mapper/employee/EmployeeTerminate.mapper";

const usecase = new AddEmployeeTerminateUsecase();
const localNotificationService = new LocalNotificationService();
const toastService = new ToastService()

let jobList

export default function AddEmployeeTerminate() {
  const [isLoading, setIsLoading] = useState(false);
  const [employeeList, setEmployeeList] = useState([])
  const [categoryList, setCategoryList] = useState([])

  useEffect(() => {
    init()
  }, [])
  
  async function init() {
    setIsLoading(true)
    const empList = await usecase.getEmployeeList({
      limit: 500,
      sort: 'name_related|asc',
    });

    setEmployeeList(empList)

    usecase.getCategory().then(
      (res) => setCategoryList(res))

    const params: GetJobListDTO = {
      limit: 300,
      sort: 'id|asc',
    };

    jobList = await usecase.getJobList(params);
    setIsLoading(false)
  }

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Terminasi Karyawan',
        href: "/v2/app/hr-employee/employee-terminate",
      },
      {
        clickable: false,
        displayPathName: 'Baru',
        href: "/v2/app/hr-employee/employee-terminate/new/",
      }
    ],
  };

  const submitButton = { active: true, disabled: false };
  const firstActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: false,
    onClick: () => {
      const back = window.confirm("Anda Ingin Kembali")
      if (back) {
        window.location.href = "/v2/app/hr-employee/employee-terminate"
      }
    },
  };

  const onFinish = (e, leave_queue) => {
    setIsLoading(true)
    const body = SaveEmployeeTerminateMapper(e, jobList, employeeList, leave_queue)

    usecase.create(body).then(
      (res) => {
        const data = res.data
        if (data.status === 200) {
          const message = data.content
          localNotificationService.openSuccess(message, '', 1)
        }
        setTimeout(() => {
          window.location.href = "/v2/app/hr-employee/employee-terminate"
        }, 1500)
      }
    ).catch((err) => {
      toastService.error(err)
      setIsLoading(false)
    })
  }

  return (
    <div className="add-employee-terminate">
      <AddEmployeeTerminateTemplate 
        isLoading={isLoading}
        viewMode={false}
        breadcrumbConf={breadcrumbConf}
        firstActionButton={firstActionButton}
        employeeList={employeeList}
        jobList={jobList}
        categoryList={categoryList}
        secondActionButton={{ active: false }}
        thirdActionButton={{ active: false }}
        fourthActionButton={{ active: false }}
        dropdownActionButton={{ visible: false }}
        submitButton={submitButton}
        stepState="Pengajuan"
        onFinish={onFinish}
      />
    </div>
  )
}