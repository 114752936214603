import { useEffect, useState } from 'react';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import ToastService from '../../../../../services/Toast.service';
import { ToolbarDetailActionButton } from '../../../employee/components/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface';
import AddSubmissionLoanTemplate from '../template/AddSubmissionLoanTemplate';
import AddSubmissionLoanUsecase from './AddSubmissionLoan.usecase';
import { AddSubmissionLoanMapper } from '../../../../../@core/mapper/loan/AddSubmissionLoan.mapper';

const usecase = new AddSubmissionLoanUsecase();
const localNotificationService = new LocalNotificationService();
const toastService = new ToastService();
const steps = [
  "Pengajuan", "Disetujui",
  "Persetujuan Ke-2", "Ditolak",
  "Batal", "Selesai"
]

let department_id
let employee_id
let job_id

export default function AddSubmissionLoan() {
  const [isLoading, setIsLoading] = useState(false);
  const [listConfigLoan, setListConfigLoan] = useState([]);
  const [employeeName, setEmployeeName] = useState('')

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Pengajuan Pinjaman',
        href: '/v2/app/hr-loan/submission-loan',
      },
      {
        clickable: false,
        displayPathName: 'Baru',
        href: '/v2/app/hr-loan/submission-loan/new/',
      },
    ],
  };

  const submitButton = { active: true, disabled: false };
  const firstActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: false,
    onClick: () => {
      const back = window.confirm('Anda Ingin Kembali');
      if (back) {
        window.location.href = '/v2/app/hr-loan/submission-loan';
      }
    },
  };

  useEffect(() => {
    init();
  }, []);

  async function init() {
    setIsLoading(true);
    usecase.getEmployeeMe().then(
      (res) => {
        setEmployeeName(`${res.nik} ${res.name_related}`);
        department_id = res.department_id
        employee_id = res.id
        job_id = res.job_id
        usecase.getConfigLoan().then(
          (res) => {
            setListConfigLoan(res)
            setIsLoading(false)
          }
        )
      }
    )
  }

  const onFinish = (e) => {
    setIsLoading(true);
    const body = AddSubmissionLoanMapper(e, listConfigLoan, { employee_id, job_id, department_id })

    usecase
      .create(body)
      .then((res) => {
        const data = res.data;
        if (data.code === 200) {
          localNotificationService.openSuccess(data.message, '', 1);
        }
        setTimeout(() => {
          window.location.href = '/v2/app/hr-loan/submission-loan';
        }, 1500);
      })
      .catch((err) => {
        toastService.error(err);
        setIsLoading(false);
      });
  };

  return (
    <div className="add-submission-loan">
      <AddSubmissionLoanTemplate
        key='add-submission-loan'
        breadcrumbConf={breadcrumbConf}
        firstActionButton={firstActionButton}
        secondActionButton={{ active: false }}
        thirdActionButton={{ active: false }}
        fourthActionButton={{ active: false }}
        dropdownActionButton={{ visible: false }}
        submitButton={submitButton}
        empoyeeName={employeeName}
        listConfigLoan={listConfigLoan}
        onFinish={onFinish}
        steps={steps}
        activeSteps="Pengajuan"
        onFinishFailed={null}
        viewMode={false}
        historyId={null}
        isLoading={isLoading}
      />
    </div>
  );
}
