import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import FormBuilder from 'antd-form-builder';
import { useHistory } from 'react-router-dom';
import PageLoader from '../../../../../components/loader/PageLoader';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import HrAddEmployeeUsecase from './UpdateUser.usecase';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
const useCaseImpl = new HrAddEmployeeUsecase()
const localNotificationService = new LocalNotificationService();

let userId = '';
export default function HrUpdateUsere({ match }) {
  userId = match.params.userId;
  const history = useHistory();
  const [form] = Form.useForm();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [, updateState] = React.useState<any>();
  const [initialData, setInitialData] = useState<any>({});
  const [AccessList, setAccessList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Users',
        href: "/v2/app/hr-employee/list-user",
      },
      {
        clickable: false,
        displayPathName: initialData.user_name,
      }
    ],
  };



  useEffect(() => {
    setIsLoading(true);
    initialPage();
  }, []);

  async function initialPage() {
    const getAccessList = await useCaseImpl.getAccessList();
    const userDetail: any = await useCaseImpl.getUserDetail(userId);
    let hak_akses = []

    const tempAkses = userDetail?.list_group_id && userDetail?.list_group_id.map(item => item.id)
    if (tempAkses && tempAkses.length > 0) {
      hak_akses = getAccessList.reduce((acc, val, i) => {

        if (tempAkses.map(item => item).includes(val.key)) {
          acc.push(val.value);
        }
        return acc;
      }, [])
    }

    let tempInitial = {
      user_type: userDetail.user_type,
      user_name: userDetail.user_name,
      timezone: userDetail.tz,
      hak_akses: hak_akses,
      is_lock: userDetail.locked,
      employee_name: userDetail.employee_name,
      employee_id: userDetail.employee_id,
      history_id: userDetail.history_id,
      is_active: userDetail.active,
    }
    setInitialData(tempInitial);
    setAccessList(getAccessList)
    setIsLoading(false);
  }

  async function onFinish(e) {
    setIsLoading(true);
    const list_access = e.hak_akses && e.hak_akses.map(item => JSON.parse(item)).map(function (row) {
      return {
        id: row.id,
        level: row.level,
        name: row.name,
      }
    })
    let tempInitialAkses = initialData.hak_akses;
    const list_old_access = tempInitialAkses && tempInitialAkses.map(item => JSON.parse(item)).map(function (row) {
      return {
        id: row.id,
        level: row.level,
        name: row.name,
      }
    })
    let tempParam: any = {}
    initialData.employee_id && (tempParam['employee_id'] = initialData.employee_id)
    initialData.employee_name && (tempParam['employee_name'] = initialData.employee_name)
    e.hak_akses && (tempParam['list_group_id'] = list_access)
    tempInitialAkses && (tempParam['old_group_id'] = list_old_access)

    e.is_password && (tempParam['password'] = e.password)

    const params = {
      id: userId,
      user_type: e.user_type,
      timezone: e.timezone,
      user_name: e.user_name,
      history_id: initialData.history_id,
      locked: e.is_lock,
      active: initialData.is_active,
      ...tempParam
    }

    useCaseImpl.update(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          history.push('/app/hr-employee/list-user');
          localNotificationService.openSuccess('User Berhasil Diubah', '', 3);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }

  async function handleChangeStatus(status) {
    setIsLoading(true);
    const params = {
      id: [userId],
      status: status ? 'aktif' : 'tidak_aktif'
    }
    useCaseImpl.changeStatus(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          const message = data.message
          localNotificationService.openSuccess(message, '', 1)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } else {
          localNotificationService.openToast('topRight', data?.error?.message, '', { color: '#fff', background: 'red' }, 3)
        }
        setIsLoading(false);
      },
      (err) => {
        localNotificationService.openToast('topRight', err, '', { color: '#fff', background: 'red' }, 3)
        setIsLoading(false);
      },
    );
  }


  const meta: any = {
    columns: 1,
    fields: [
      {
        key: 'user_type',
        label: 'Tipe Pengguna',
        disabled: true,
        widget: 'select',
        options: [
          { value: 'client', label: 'ESS' },
          { value: 'system', label: 'System Admin' },
        ],
      },
      {
        key: 'user_name',
        label: 'Nama Pengguna',
        disabled: true,
      },
      { key: 'is_password', label: 'Ubah Password', widget: 'checkbox' },
      {
        key: 'timezone',
        label: 'Zona Waktu',
        required: true,
        message: 'Zona Waktu harus diisi',
        widget: 'select',
        options: [
          { value: 'WIB', label: 'WIB' },
          { value: 'WITA', label: 'WITA' },
          { value: 'WIT', label: 'WIT' },
        ],
      },
      { key: 'is_lock', label: 'Dikunci', widget: 'checkbox' },
      {
        key: 'separator',
        colSpan: 4,
        render() {
          return (
            <hr />
          );
        },
      },
      {
        key: 'hak_akses',
        label: 'Hak Akses',
        widget: 'checkbox-group',
        forwardRef: true,
        options: AccessList,
        widgetProps: {
          defaultChecked: initialData.hak_akses,
          checked: initialData.hak_akses,
        }
      },
    ],
  };
  let newMeta = meta;

  if (initialData.user_type !== 'system') {
    newMeta.fields.splice(2, 0, {
      key: 'employee_name',
      label: 'Karyawan',
      disabled: true,
    },)
  }

  if (!!form.getFieldValue('is_password')) {
    const objIndex = newMeta.fields.findIndex((obj => obj.key === 'is_password'));
    newMeta.fields.splice(objIndex + 1, 0, {
      key: 'password',
      label: 'Password',
      widget: 'password',
      required: true,
      message: 'Password Harus diisi'
    },)
  }

  const dropdownButton = {
    visible: true,
    disabled: false,
    options: [
      { key: 'change_status', label: initialData.is_active ? 'Non-aktifkan' : 'Aktifkan', onClick: () => handleChangeStatus(!initialData.is_active) },
    ]
  };

  const DropdownButton = () => {
    const menu = dropdownButton && dropdownButton.visible ? (
      <Menu>
        {dropdownButton?.options?.map((opt) => (
          <Menu.Item
            disabled={false}
            onClick={() => opt.onClick()}
            key={opt.key}
          >
            {opt.label}
          </Menu.Item>
        ))}
      </Menu>
    ) : null

    return dropdownButton && dropdownButton.visible ? (
      <Dropdown
        overlay={menu}
      >
        <Button
          type='primary'
        >
          Tindakan <DownOutlined />
        </Button>
      </Dropdown>
    ) : null
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div
      style={{ position: 'relative', height: 'calc(100vh - 100px)' }}
    >
      <Form form={form} initialValues={initialData} layout="horizontal" onFinish={onFinish} onValuesChange={forceUpdate}>
        <div>
          <div className="form-wrapper">
            <div>
              <Breadcrumb breadcrumbConf={breadcrumbConf} />
              <Row>
                <Form.Item
                  className="form-footer"
                  style={{ width: '50%', marginTop: 20 }}
                >
                  <Button
                    type="primary"
                    onClick={() => form.submit()}
                    disabled={isLoading}
                  >
                    Simpan
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    onClick={() => history.push('/app/hr-employee/list-user')}
                  >
                    Batal
                  </Button>
                </Form.Item>
                <div style={{ marginTop: 20 }}>
                  {
                    initialData.user_type === 'system' && (
                      <DropdownButton />
                    )
                  }
                </div>

              </Row>
              <div style={{ border: '1px solid #000', padding: 10, overflowY: 'scroll', height: '70vh' }}>
                <FormBuilder form={form} meta={meta} />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
