import { useEffect } from 'react';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import { Form } from 'antd';
import ToolbarLeaveDetailTemplate from './components/toolbar-leave-detail-template/ToolbarLeaveDetailTemplate';
import EmployeeLeaveDetailForm from './components/employee-leave-detail-form/EmployeeLeaveDetailForm';
import ApprovalAndArchiveTabs from './components/approval-and-archive-tabs/ApprovalAndArchiveTabs';
import BottomLoading from '../../../../../components/bottom-loading/BottomLoading';
import { employeeLeaveDetailTemplatePropsType } from './EmployeeLeaveDetailTemplate.interface';
import './EmployeeLeaveDetailTemplate.less';

export default function EmployeeLeaveDetailTemplate(props: employeeLeaveDetailTemplatePropsType) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.formInitEvent) {
      setTimeout(() => {
        props.formInitEvent(form.getFieldsValue());
      }, 50);
    }
  }, []);

  const onFinish = (values) => {
    if (props.onFinish) {
      props.onFinish(form.getFieldsValue());
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (props.onFinishFailed) {
      props.onFinishFailed(form.getFieldsValue());
    }
  };

  const onFieldsChange = (changedFields, allFields) => {
    if (props.onFieldChange) {
      props.onFieldChange(changedFields, form);
    }
  };

  return (
    <div id="employee-leave-detail-template">
      <Breadcrumb breadcrumbConf={props.breadcrumbConf} />

      <Form
        name="basic"
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 6 }}
        layout="horizontal"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={onFieldsChange}
      >
        <ToolbarLeaveDetailTemplate
          submitButton={props.submitButton}
          firstActionButton={props.firstActionButton}
          secondActionButton={props.secondActionButton}
          thirdActionButton={props.thirdActionButton}
          fourthActionButton={props.fourthActionButton}
          stepState={props.stepState}
        />

        <div className="scrollable-content">
          <div className="form-wrapper">
            <EmployeeLeaveDetailForm
              submissionDate={props.submissionDate}
              employee={props.employee}
              position={props.position}
              department={props.department}
              description={props.description}
              leaveType={props.leaveType}
              leaveAll={props.leaveAll}
              remainsDayOff={props.remainsDayOff}
              leaveDate={props.leaveDate}
              leaveDuration={props.leaveDuration}
              unfinishLeave={props.unfinishLeave}
              availPlafon={props.availPlafon}
            />
          </div>

          <ApprovalAndArchiveTabs
            firstApprovalData={props.firstApprovalData}
            secondApprovalData={props.secondApprovalData}
            firstArchiveImg={props.firstArchiveImg}
            secondArchiveImg={props.secondArchiveImg}
            thirdArchiveImg={props.thirdArchiveImg}
            fourthArchiveImg={props.fourthArchiveImg}
            onChangeArchiveImg={props.onChangeArchiveImg}
          />
        </div>
      </Form>

      <BottomLoading active={props.isLoading} />
    </div>
  );
}
