export const LeaveDetailConstant = {
  INIT_EMPLOYEE_DETAIL_FORM: {
    submissionDate: { value: '' },
    employee: { value: '' },
    position: { value: '' },
    department: { value: '' },
    description: { value: '' },
    leaveType: { value: '' },
    remainsDayOff: { value: '' },
    leaveDate: { value: '' },
    leaveDuration: { value: '' },
  },
  INIT_APPROVAL_DATA: {
    firstApprovalData: [{ name: '', position: '' }],
    secondApprovalData: [{ name: '', position: '' }],
  },
  INIT_ARCHIVE_IMAGE: {
    firstArchiveImg: {
      sourceImg: '',
      canEdit: false,
    },
    secondArchiveImg: {
      sourceImg: '',
      canEdit: false,
    },
    thirdArchiveImg: {
      sourceImg: '',
      canEdit: false,
    },
    fourthArchiveImg: {
      sourceImg: '',
      canEdit: false,
    },
  },
};
