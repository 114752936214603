import Constant from "../../../../../config/Constants"
import API from "../../../../../services/API.service"
import getLoanRel from '../../../../../@core/interaction/loan/GetLoanRel.interaction'

export default class HrUpdateConfigurationLoanUseCaseImpl {

  getJob(temp = {}) {
    const params: any = {
      ...temp
    }
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/job"
    return API.get(URL, { params }).then((res:any) => 
      {
        const result = res.data.content.map(element => {
          return { label: element['job_name'], value: JSON.stringify(element), key: element['id'] }
        })
        return result;
      })
  }
  getEmpl() {
    const param: any = {
      page: 1,
      limit: 500
    }
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/emp"
    return API.get(URL, param).then((res: any) => {
      const result = res.data.content.map(element => {
        return { label: element['alternative_name'], value: JSON.stringify(element), key: element['id'] }
      })
      return result;
    })
  }

  getLoanConfigurationDetail(params): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const URL = Constant.URL_BASE_LOAN + "/v3/configuration/loan/" + params
      return API.get(URL).then((res: any) => {
        console.log(res, 'res')
        if (res.status === 200) {
          const resultMapper = res.data.content;
          const body: any = {
            limit: 300,
            page: 1,
            with_meta: true,
            history_id: resultMapper.history_id
          };
          // let tempData = await getConfigLeaveHistory(body);

          // const listHistory = tempData.data.content;
          // resultMapper.historyList = listHistory;

          resolve(resultMapper);
        } else {
          reject(res);
        }
      })
    });
  }

  getLoanRel(params): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const loanRel = await getLoanRel(params);
      if (loanRel.status === 200 && loanRel.data?.content) {
        resolve(loanRel?.data);
      } else {
        reject(loanRel?.data);
      }
    });
  }


  submitLoanConfiguration(body): Promise<any> {
    const URL = Constant.URL_BASE_LOAN + `/v3/configuration/loan`;
    return API.put(URL, body);
  }
}
