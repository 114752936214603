import { useReducer, useState } from "react";
import AddRecurringHolidaysTemplate from "../template/AddRecurringHolidaysTemplate";
import { RecurringHolidaysFormReducer } from "./AddRecurringHolidays.reducer";
import { ACTION_FORM_TYPE, INITIAL_FORM_STATE } from "./AddRecurringHolidays.constant";
import { ToolbarDetailActionButton, ToolbarDetailDropdownButton } from "../../../../component/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface";
import moment from "moment";
import RecurringHolidaysListUseCase from "../list-recurring-holidays/ListRecurringHolidays.usecase";
import ToastService from "../../../../../../services/Toast.service";
import LocalNotificationService from "../../../../../../services/LocalNotification.service";

const usecase = new RecurringHolidaysListUseCase()
const toastService = new ToastService()
const localNotificationService = new LocalNotificationService()

export default function AddRecurringHolidays() {
  // useReducer
  const [isLoading, setIsLoading] = useState(false)
  const [stateForm, dispatchForm] = useReducer(RecurringHolidaysFormReducer, INITIAL_FORM_STATE)

  // constant view
  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Hari Libur Berulang',
        href: "/v2/app/attendance/recurring-holidays",
      },
      {
        clickable: false,
        displayPathName: 'Baru',
        href: "/v2/app/attendance/recurring-holidays/new/",
      }
    ],
  };

  const submitButton = { active: true, disabled: isLoading };

  const firstActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: isLoading,
    onClick: () => {
      const back = window.confirm("Anda Ingin Kembali")
      if (back) {
        window.location.href = "/v2/app/attendance/recurring-holidays"
      }
    },
  };

  function processDate(startDate, endDate) {
    if (startDate && endDate) {
      const result = endDate.diff(startDate, 'days')
      
      dispatchForm({ 
        type: ACTION_FORM_TYPE.SET_HOLIDAYS_LENGTH, 
        payload: `${endDate >= startDate ? result + 1 : result - 1} Hari`
      })
    }
  }

  const onFieldChange = (e, form) => {
    if (e.length === 1) {
      const fieldName = e[0].name[0];
      const value = e[0].value;
      const startDate = form.getFieldsValue().start_date
      const endDate = form.getFieldsValue().end_date

      switch (fieldName) {
        default:
          break;
        case 'start_date': 
          processDate(startDate, endDate)
          break;
        case 'end_date': 
          processDate(startDate, endDate)
          break;
      }
    }
  }

  const onFinish = (value) => {
    const holidayTotal = stateForm.holidayLength.value.split(" ")[0]

    const body = {
      recurring: true,
      name: value.holiday_name?.trim(),
      date_start: moment(value.start_date).format('YYYY-MM-DD'),
      date_stop: moment(value.end_date).format('YYYY-MM-DD'),
      total_holidays: Number(holidayTotal)
    }

    setIsLoading(true)
    usecase.save(body).then(
      (res) => {
        const data = res.data
        if (data.code === 200) {
          const message = data.message
          localNotificationService.openSuccess(message, '', 1)
        }
        setTimeout(() => {
          window.location.href = '/v2/app/attendance/recurring-holidays'
        }, 1500)
    }).catch(err => {
      toastService.error(err)
      setIsLoading(false)
    })
  }
  
  return (
    <AddRecurringHolidaysTemplate
      breadcrumbConf={breadcrumbConf}
      holidayName={stateForm.holidayName}
      startDate={stateForm.startDate}
      endDate={stateForm.endDate}
      holidayLength={stateForm.holidayLength}
      isRecurring={stateForm.isRecurring}
      dropdownActionButton={{ visible: false }}
      firstActionButton={firstActionButton}
      secondActionButton={{ active: false }}
      thirdActionButton={{ active: false }}
      fourthActionButton={{ active: false }}
      submitButton={submitButton}
      onFieldChange={onFieldChange}
      onFinish={onFinish}
    />
  )
}