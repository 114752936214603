import Constant from "../../../config/Constants";
import API from "../../../services/API.service";

export class NotificationUsecase {
  getTermination(params) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/dashboard/admin/notification/termination-employee"
    return API.get(URL, { params })
  }
  getScheduleWork(params) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/dashboard/admin/notification/schedule-work-employee"
    return API.get(URL, { params })
  }
}