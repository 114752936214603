import Constant from "../../../../../config/Constants"
import API from "../../../../../services/API.service"

export default class FilterLoanReportUsecase {
  getJobList() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/job"
    return API.get(URL).then((res:any) => 
      {
        const result = res.data.content.map(element => {
          return { label: element['job_name'], value: element['id'], key: element['id'] }
        })
        return result;
      })
  }
  getEmployeeList() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/emp"
    return API.get(URL).then((res:any) => 
      {
        const result = res.data.content.map(element => {
          return { label: element.alternative_name, value: element['id'], key: element['id'], job_id: element?.job_id }
        })
        return result;
      })
  }
  getConfigLoan() {
    const URL = Constant.URL_BASE_LOAN + '/v3/configuration/loan';
    return API.get(URL).then((res) => {
      const content = res.data.content;
      const result = content?.map((config) => {
        return { key: config.id, value: config.id, label: config.name };
      });
      return result
    });
  }
}