import { Checkbox, Form } from "antd";
import { FormItemCheckBoxPropsType } from "./FormItem.interface";

export default function FormItemCheckbox({ label, name, isLoading, state, labelAfter }: FormItemCheckBoxPropsType) {
  return state && state.active === true ? (
    <Form.Item
      label={label}
      name={name}
      labelAlign="left"
      colon={false}
      rules={state.rules}
      valuePropName="checked"
      initialValue={state.value}
    >
      <Checkbox 
        disabled={state.disabled || isLoading} 
        defaultChecked={state.value}
        name={name} 
      >
        {labelAfter}
      </Checkbox>
    </Form.Item>
  ) : null;
};