import { memo, useMemo } from "react";
import { RecurringHolidayFormPropsType, SectionBluePrintType, SectionStateType } from "./RecurringHolidaysForm.interface";
import { Col, DatePicker, Form, Input, Tabs } from "antd";
import FormItemValue from "../../../../../components/form-item/FormItemValue";
import FormItemCheckbox from "../../../../../components/form-item/FormItemCheckbox";
import { MemoizeHistoryTabs } from "../../../component/history-payroll-period-tabs/HistoryPayrollPeriodTabs";

const moment = require("moment")

export default memo(function RecurringHolidayForm(props: RecurringHolidayFormPropsType) {
  const holidayName: SectionStateType = useMemo(() => {
    return processGeneralPropsType("holidayName")
  }, [props.holidayName])
  const startDate: SectionStateType = useMemo(() => {
    return processGeneralPropsType("startDate")
  }, [props.startDate])
  const endDate: SectionStateType = useMemo(() => {
    return processGeneralPropsType("endDate")
  }, [props.endDate])
  const holidayLength: SectionStateType = useMemo(() => {
    return processGeneralPropsType("holidayLength")
  }, [props.holidayLength])
  const isRecurring: SectionStateType = useMemo(() => {
    return processGeneralPropsType("isRecurring")
  }, [props.isRecurring])

  const bluePrints: SectionBluePrintType[] = [
    { label: 'Nama Hari Libur', name: 'holiday_name', componentType: 'text', state: holidayName },
    { label: 'Dari', name: 'start_date', componentType: 'date', state: startDate },
    { label: 'Sampai', name: 'end_date', componentType: 'date', state: endDate },
    { label: 'Lama Libur', name: 'length_holiday', componentType: '', state: holidayLength },
    { label: 'Berulang Setiap Tahun', name: 'is_recurring', componentType: 'checkbox', state: isRecurring },
  ]

  function processGeneralPropsType(procedure): SectionStateType {
    const result: any = {
      active: false,
      value: '',
      editMode: true,
      rules: [{ required: false, message: '' }],
    };

    if (props[procedure]) {
      result.active = props[procedure].active !== undefined ? props[procedure].active : true;
      result.value = props[procedure].value ? props[procedure].value : '';
      result.editMode = props[procedure].editMode ? props[procedure].editMode : false;
      result.rules = props[procedure].rules
        ? props[procedure].rules
        : [{ required: false, message: '' }];
    }

    return result
  }

  const FormItemDate = ({ label, name, isLoading, state }) => {
    const defaultValue = useMemo(
      () => {
        return state.value
    }, [state?.value])

    return state && state.active === true 
      && (props.isDetail ? defaultValue : true) ? (
      <Form.Item
        label={label}
        name={name}
        labelAlign="left"
        colon={false}
        rules={state.rules}
        initialValue={defaultValue ? moment(defaultValue, "YYYY-MM-DD", true) : null}
      >
        <DatePicker 
          format={"YYYY-MM-DD"}
          disabled={state.disabled || isLoading} 
        />
      </Form.Item>
    ) : null;
  };

  const FormItemText = ({ label, name, isLoading, state }) => {
    const defaultValue = useMemo(
      () => {
        return state.value
    }, [state?.value])

    return state && state.active === true 
      && (props.isDetail ? defaultValue : true) ? (
      <Form.Item
        label={label}
        name={name}
        labelAlign="left"
        colon={false}
        rules={state.rules}
        initialValue={defaultValue}
      >
        <Input disabled={state.disabled || isLoading} name={name} style={{ width: '96%' }} />
      </Form.Item>
    ) : null;
  };

  const FormItem = ({ bluePrint }) => {
    const label = bluePrint.label;
    const name = bluePrint.name;
    const componentType = bluePrint.componentType;
    const state = bluePrint.state;

    if (state && !state.editMode) {
      return <FormItemValue isLoading={props.isLoading} label={label} name={name} state={state} />;
    } else {
      switch (componentType) {
        default:
          return <FormItemValue isLoading={props.isLoading} label={label} name={name} state={state} />;
        case 'text':
          return <FormItemText isLoading={props.isLoading} label={label} name={name} state={state} />;
        case 'date':
          return <FormItemDate isLoading={props.isLoading} label={label} name={name} state={state} />;
        case 'checkbox':
          return <FormItemCheckbox isLoading={true} label={label} name={name} state={state} />
      }
    }
  }

  const FirstSection = () => {
    return (
      <Col span={12}>
        {bluePrints.map((bluePrint) => (
          <FormItem key={bluePrint.label} bluePrint={bluePrint} />
        ))}
      </Col>
    )
  }

  return (
    <Col>
      <FirstSection />
    </Col>
  )
}, (prev, next) => JSON.stringify(prev) === JSON.stringify(next))