import Constant from "../../../../../config/Constants";
import API from "../../../../../services/API.service";

export default class ListLoanReportUseCase {
  getAll(params) {
    const url = Constant.URL_BASE_LOAN + "/v3/report/loan/list"
    const URL = this.paramsHelper(url, params)
    return API.get(URL)
  }

  export(params) {
    const URL =  Constant.URL_BASE_LOAN + "/v3/report/loan/export"
    return API.get(URL,{ params })
  }

  paramsHelper(Url, params) {
    const url = new URL(Url);
    const queryParams = Object.keys(params).map(key => {
      const values = params[key];
      return values ? values?.length ? values.map(value => `${key}=${value}`).join('&') 
        : `${key}=${values}` : "";
    }).filter(e => e !== '').join('&');

    url.search = queryParams;
    return url.toString()
  }
}