import { GetJobListDTO } from '../../interface/job/GetJobList.interface';
import { JobListObjById } from '../../entity/Job.entity';
import { DeleteLeaveDTO, DeleteLeaveResp } from '../../interface/leave/DeleteLeave.interface';
import { UpdateLeaveStateResp } from '../../interface/leave/UpdateLeaveState.interface';
import {
  GetLeaveDetailDTO,
  GetLeaveDetailResp,
} from '../../interface/leave/GetLeaveDetail.interface';
import { LeaveId } from '../../entity/Leave.entity';
import { LeaveDetailApproval } from '../../entity/SubmissionLeaveDetail.entity';

export default abstract class MySubmissionLeaveDetailUseCase {
  abstract getLeaveDetail(params: GetLeaveDetailDTO): Promise<GetLeaveDetailResp>; //custom data resp, 1 usecase call more than 1 interaction
  abstract getJobList(params: GetJobListDTO): Promise<JobListObjById>;
  abstract getApprovalLeave(
    leaveId: LeaveId,
    jobList: JobListObjById,
  ): Promise<LeaveDetailApproval>;
  abstract cancelLeave(leaveId: LeaveId[]): Promise<UpdateLeaveStateResp>;
  abstract doneLeave(leaveId: LeaveId[]): Promise<UpdateLeaveStateResp>;
}
