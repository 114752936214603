import { useState } from 'react';
import { Table, Tabs, Button, Modal, Row, Col } from 'antd';
import moment from 'moment';
import GoogleMap from 'google-map-react';
import { AttendanceConstant } from '../../../../../../../config/Attendance.constant';

const { TabPane } = Tabs;

export default function DetailAbsentTab(props) {
  const { dataSource } = props;
  const [modalAbsensi, setModalAbsensi] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<any>();
  let tempDataSource = dataSource.map(item => {
    const dataItem = {
      id: item.id,
      key: item.id,
      tanggal: moment(item.tanggal).format('YYYY-MM-DD'),
      check_in: moment(item.check_in).format('YYYY-MM-DD HH:mm:ss'),
      check_out: item.check_out ? moment(item.check_out).format('YYYY-MM-DD HH:mm:ss') : '-',
      description: item.description,
      total: item.total,
      location:
        <div>
          <Button
            type="primary"
            style={{ borderRadius: 10 }}
            onClick={() => {
              setSelectedData(item);
              setModalAbsensi(true)
            }}
          >
            Tampilkan
          </Button>
        </div>

    };

    return dataItem;
  })

  const MapWrapper: React.FC<any> = ({ location }) => {
    const tempLocation = JSON.parse(location)
    let checkinLocation = tempLocation.position;
    let checkoutLocation = tempLocation.position_out;
    const renderMarkers = (map, maps) => {
      return [0, 1].map((location, i) => {
        if (i === 0) {
          return new maps.Marker({
            position: { lat: checkinLocation.lat, lng: checkinLocation.lng },
            map,
            label: 'Checkin'
          })
        } else if (i === 1) {
          return new maps.Marker({
            position: { lat: checkoutLocation.lat, lng: checkoutLocation.lng },
            map,
            label: 'Checkout'
          })
        }
      })
    }

    return (
      <Row>
        <div style={{ position: 'relative', width: '60%', height: '300px', borderRadius: 10 }}>
          <GoogleMap
            defaultZoom={12}
            defaultCenter={[checkinLocation.lat || -6.300400, checkinLocation.lng || 106.635039]}
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => !!checkinLocation ? renderMarkers(map, maps) : {}}
          >
          </GoogleMap>
        </div>
      </Row>
    )
  }

  return (
    <div className="tabs-wrapper">
      {
        selectedData && (
          <Modal
            visible={modalAbsensi}
            onCancel={() => setModalAbsensi(false)}
            title={null}
            width="80%"
            footer={[
              <Button key="ok" type="primary" onClick={() => setModalAbsensi(false)}>
                Tutup
              </Button>,
            ]}
          >
            <div style={{ padding: 30 }}>
              <Row justify="space-between" style={{ marginBottom: 20 }}>
                <Col style={{ fontWeight: 'bold', width: '25%' }}>Check In: </Col>
                <Col style={{ width: '25%' }}>{selectedData.check_in}</Col>
                <Col style={{ fontWeight: 'bold', width: '25%' }}>Total Jam Kerja:</Col>
                <Col style={{ width: '25%' }}>{selectedData.total}</Col>
              </Row>
              <Row justify="space-between" style={{ marginBottom: 20 }}>
                <Col style={{ fontWeight: 'bold', width: '25%' }}>Check Out: </Col>
                <Col style={{ width: '25%' }}>{selectedData.check_out}</Col>
                <Col style={{ fontWeight: 'bold', width: '25%' }}>Keterangan: </Col>
                <Col style={{ width: '25%' }}>{selectedData.description}</Col>
              </Row>
              Lokasi
              <hr />
              <MapWrapper location={selectedData.gmap_loc} />
            </div>
          </Modal>
        )
      }
      <Tabs defaultActiveKey="1" type="card">
        <TabPane tab="Detail Absensi" key="1">
          <Table columns={AttendanceConstant.DETAIL_ABSENT_TABLE_HEADER} dataSource={tempDataSource} />
        </TabPane>
      </Tabs>
    </div>
  );
}
