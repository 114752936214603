import { Button, Form } from "antd";
import Breadcrumb from "../../../../../components/breadcrumb/Breadcrumb";
import FormBuilder from "antd-form-builder";
import BottomLoading from "../../../../../components/bottom-loading/BottomLoading";
import { useForm } from "antd/lib/form/Form";
import React, { useState } from "react";
import { FilterLoanInstallmentReportInterface } from "./LoanInstallmentReportTemplate.interface";

export default function FilterLoanInstallmentReportTemplate(props: FilterLoanInstallmentReportInterface) {
  const [form] = useForm()
  const [, updateState] = React.useState<any>(); const forceUpdate = React.useCallback(() => updateState({}), []);
  const [listLoanNumber, setListLoanNumber] = useState([])
 
  const onFinish = (values) => {
    if (props.onFinish) {
      props.onFinish(form.getFieldsValue());
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (props.onFinishFailed) {
      props.onFinishFailed(form.getFieldsValue());
    }
  };

  const onFieldsChange = (changedFields) => {
    if (changedFields.length === 1) {
      const fieldName = changedFields[0].name[0];
      const value = changedFields[0].value;
      switch (fieldName) {
        default:
          break;
        case 'employee':
          const selectedEmployee = props.employeeList.find(e => e.value === value)
          setListLoanNumber(selectedEmployee?.list_loan_number)
          break;
      }}
  };

  const SubmitButton = () => {
    return (
      <Form.Item style={{ marginTop: '70px' }}>
        <Button
          type="primary"
          htmlType="submit"
          disabled={props.isLoading}
        >
          TAMPILKAN
        </Button>
      </Form.Item>
    )
  };

  const meta = {
    columns: 1,
    fields: [
      {
        key: 'separator',
        colSpan: 4,
        extra: " ",
        widget: () => {
          return (
            <p style={{ fontWeight: 500, fontSize: "16px" }}>Silahkan pilih filter dibawah ini sebelum menampilkan data:</p>
          );
        },
      },
      {
        key: 'employee',
        label: 'Karyawan',
        widget: 'select',
        disabled: props.isLoading,
        required: true,
        extra: " ",
        options: props.employeeList,
        formItemLayout: { labelCol: { span: 4 }, wrapperCol: { span: 9 } },
      },
      {
        key: 'loan_number',
        label: 'No Pinjaman',
        widget: 'select',
        disabled: props.isLoading,
        required: true,
        extra: " ",
        options: listLoanNumber,
        formItemLayout: { labelCol: { span: 4 }, wrapperCol: { span: 9 } },
      },
    ]
  }

  return (
    <div id="filter-loan-installment-report" style={{ height: 'calc(100vh - 100px)' }}>
      <Breadcrumb breadcrumbConf={props.breadcrumbConf} />

      <Form
        name="basic"
        layout="horizontal"
        form={form}
        labelAlign="left"
        colon={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={onFieldsChange}
        onValuesChange={forceUpdate}
      >
        <div className="scrollable-content">
          <div className="form-wrapper">
            <FormBuilder form={form} meta={meta} />
          </div>
          
          <SubmitButton />
        </div>
      </Form>

      <BottomLoading active={props.isLoading} />
    </div>
  )
}