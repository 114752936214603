import Constant from "../../../../../../config/Constants";
import API from "../../../../../../services/API.service";

export default class EmployeeWorkingScheduleUseCase {
  convertOutputSortToEndPointFormat(tableOutputSort) {
    let result = '';
    const columnKey = tableOutputSort.columnKey;
    const order = tableOutputSort.order;

    if (order) {
      switch (columnKey) {
        default:
          break;
        case 'nama_jadwal_kerja':
          result += 'nama_jadwal_kerja|';
          break;
        case 'date_start_schedule_work':
          result += 'date_start_schedule_work|';
          break;
        case 'date_end_schedule_work':
          result += 'date_end_schedule_work|';
          break;
        case 'create_date':
          result += 'create_date|';
          break;
        case 'write_date':
          result += 'write_date|';
          break;
      }

      switch (order) {
        default:
          break;
        case 'ascend':
          result += 'asc';
          break;
        case 'descend':
          result += 'desc';
          break;
      }
    }
  
    return result;
  }

  getAll(params) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/configuration/jadwal-kerja-karyawan"
    return API.get(URL, { params })
  }

  getMasterSchedule(params) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/lov/master-jadwal"
    return API.get(URL, { params })
  }

  export(params) {
    const URL =  Constant.URL_BASE_ATTENDANCE + "/v3/configuration/jadwal-kerja-karyawan/export"
    return API.get(URL, { params })
  }

  update(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/configuration/jadwal-kerja-karyawan"
    return API.post(URL, body)
  }

  delete(params) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/configuration/jadwal-kerja-karyawan"
    return API.delete(URL, { data: params })
  }
}