import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { List, Pagination, Row, Input } from 'antd';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';
import { EmployeeNewsUsecase } from './EmployeeNews.usecase';
import './EmployeeNews.less';

const usecase = new EmployeeNewsUsecase();

let page = 1;
let limit = 10;
let keyword = '';
let loadPagination = true;

export default function EmployeeNews() {
  const history = useHistory();
  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([]);
  let [pagination, setPagination] = useState({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Dashboard',
        href: '/v2/app/employee-dashboard',
      },
      {
        clickable: false,
        displayPathName: 'Berita',
      },
    ],
  };

  useEffect(() => {
    loadList();
  }, []);

  function loadList() {
    setIsLoading(true);
    const params = {
      page,
      limit,
      news_name: keyword,
      with_meta: true,
    };

    usecase
      .getEmployeeNews(params)
      .then((res) => {
        const content = res.data.content;
        const metadata = res.data.metadata;

        const resultDataSource = mappingDataSource(content);

        setDataSource([...resultDataSource]);
        updatePaginationState(metadata?.total_records);

        loadPagination = true;
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }

  function mappingDataSource(list) {
    return list?.map((e) => {
      return {
        key: e.id,
        title: e.news_name,
        description: e.description,
        start_date: e.start_date,
      };
    });
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  function paginationOnChange(value) {
    if (value && value !== page) {
      page = value;
      loadList();
    }
  }
  function onChangeKeyword(key: string) {
    if (key && key !== keyword) {
      keyword = key;
      loadList();
    }
  }

  const RawHTML = ({ children, className = 'list-news' }) => (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '<br />') }}
    />
  );

  return (
    <div style={{ width: '100%' }}>
      <Breadcrumb breadcrumbConf={breadcrumbConf} />
      <div className='news-wrapper'>
        <Row justify={'space-between'}>
          <h2>Berita</h2>
          <Input.Search
            placeholder="Cari Berita"
            style={{ borderRadius: 20, width: 300 }}
            onPressEnter={(event) => onChangeKeyword(event.currentTarget.value)}
            onSearch={(value) => onChangeKeyword(value)}
          />
        </Row>
        <List
          size="small"
          dataSource={dataSource}
          style={{ cursor: 'pointer' }}
          renderItem={(item) => (
            <List.Item onClick={() => history.push(`/app/employee/detail-news/${item.key}`)}>
              <List.Item.Meta
                title={<strong>{item.title}</strong>}
                description={<div className='news-detail'><RawHTML>{item.description}</RawHTML></div>}
              />
              <div>{item.start_date}</div>
            </List.Item>
          )}
        />
        <Pagination
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={pagination.total}
          size={'small'}
          showSizeChanger={false}
          onChange={paginationOnChange}
        />
      </div>
    </div>
  );
}
