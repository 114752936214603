import { Form, Modal, Row, Table } from "antd";
import BottomLoading from "../../../../../../components/bottom-loading/BottomLoading";
import { ReadOnlyNumber, ReadOnlyString } from "./ReadOnlyFields";
import { useForm } from "antd/lib/form/Form";
import FormBuilder from "antd-form-builder";
import { IDRFormat } from "../../../../../../@core/interaction/currency/IDRFormat";

export default function ModalSimulation(props) {
  const [form] = useForm()

  const meta = {
    columns: 4,
    colon: false,
    fields: [
      {
        key: 'proposed_loan',
        label: 'Pinjaman yang diajukan',
        colSpan: 4,
        viewMode: false,
        widget: ReadOnlyNumber,
        initialValue: props.dataFormTable?.proposed_loan || '',
        formItemLayout: {
          labelCol: { span: 5 },
          wrapperCol: { span: 10 },
        },
      },
      {
        key: 'loan_interest',
        label: '% Bunga',
        colSpan: 4,
        viewMode: false,
        widget: ReadOnlyString,
        initialValue: props.rule === "Tanpa Bunga" ? props.rule : 
          `${props.dataFormTable?.loan_interest} % (${props.dataFormTable?.loan_rule})` || '',
        formItemLayout: {
          labelCol: { span: 5 },
          wrapperCol: { span: 10 },
        },
      },
      {
        key: 'proposed_tenor',
        label: 'Tenor yang diajukan (Bulan)',
        colSpan: 4,
        viewMode: false,
        widget: ReadOnlyString,
        initialValue: props.dataFormTable?.proposed_tenor + " Bulan" || '',
        formItemLayout: {
          labelCol: { span: 5 },
          wrapperCol: { span: 10 },
        },
      },
    ]
  }

  const onCancel = () => {
    if (props.onCancel) {
      props.onCancel()
    }
  }

  return (
    <Modal
      visible={props.visible}
      closable={true}
      onCancel={onCancel}
      title="Simulasi Pinjaman"
      width="50%"
      footer={[]}
    >
      <Form
        labelAlign="left"
        labelCol={{ span: 3 }}
      >
        <FormBuilder form={form} meta={meta} />
      </Form>
      
      <Table 
        bordered={true}
        pagination={false} 
        dataSource={props.dataSource || []} 
        columns={[
          { key: 'month_to', title: 'Bulan ke-', dataIndex: '', width: 100, render: (v, r, i) => i + 1 },
          { key: 'pokok', title: 'Pokok', dataIndex: 'pokok', width: 150, render: d => IDRFormat(d) },
          { key: 'bunga', title: 'Bunga', dataIndex: 'bunga', width: 150, render: d => IDRFormat(d) },
          { key: 'total_cicilan', title: 'Total Angsuran', dataIndex: 'total_cicilan', width: 150, render: d => IDRFormat(d) },
        ]} 
        summary={(pageData) => {
          let total_pokok = 0;
          let total_bunga = 0;
          let total_cicil = 0;

          pageData.forEach(({ pokok, bunga, total_cicilan }) => {
            total_pokok += pokok;
            total_bunga += bunga;
            total_cicil += total_cicilan;
          });

          props.rule === "Anuitas" && total_pokok++

          return (
            <>
              <Table.Summary.Row style={{ fontWeight: 'bold' }}>
                <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={2}>{IDRFormat(total_pokok)}</Table.Summary.Cell>
                <Table.Summary.Cell index={3}>{IDRFormat(total_bunga)}</Table.Summary.Cell>
                <Table.Summary.Cell index={4}>{IDRFormat(total_cicil)}</Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          )
        }}
      />
      
      <Row style={{ position: 'relative', bottom: '-27px' }}>
        <BottomLoading active={props.isLoading} />
      </Row>
    </Modal>
  )
}