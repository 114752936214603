import { useHistory, useLocation } from "react-router-dom";
import ExpandableListTemplate from "../../../../../templates/expandable-list-template/ExpandableListTemplate";
import { useEffect, useState } from "react";
import { ExpandableListTemplatePagination } from "../../../../../templates/expandable-list-template/ExpandableListTemplate.interface";
import { ListLeaveUsageReportConstant } from "./ListLeaveUsageReport.constant";
import ListLeaveUsageReportUseCase from "./ListLeaveUsageReport.usecase";
import { listReportMapper } from "../mapper/LeaveUsageReportMapper";
import { ListLeaveReportType } from "../mapper/LeaveUsageReportMapper.interface";
import ModalSubmissionLeave from "../../templates/employee-leave-detail-template/components/modal-submission-list/ModalSubmissionLeave";
import { BreadcrumbConf } from "../../../../../components/breadcrumb/Breadcrumb.interface";
import { createExcelFromByte } from "../../../payroll/configuration/master-payroll/list-master-payroll/ListMasterPayroll.service";

const constant = ListLeaveUsageReportConstant
const usecase = new ListLeaveUsageReportUseCase()

let page = 1;
let loadPagination = true;
let emp_ids
let leave_config_ids
let is_expired
let job_ids
let sort
let selectionId
const limit = 10;

export default function ListLeaveUsageReport() {
  const history = useHistory();
  const location:any = useLocation();

  const [isLoading, setIsLoading] = useState(false)
  const [dataSource, setDataSource] = useState([]);
  const [tableScroll] = useState({ y: 900, x: 'max-content' });
  const [disableExportButton, setDisableExportButton] = useState(false);
  const [visibleSubmissionModal, setVisibleSubmissionModal] = useState(false)
  const [paramsSubmissionModal, setParamsSubmissionModal] = useState({ name: null, nik: null, params: null})
  const [breadcrumbConf, setBreadcrumbConf] = useState<BreadcrumbConf>({
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Laporan Penggunaan Cuti',
        href: "/v2/app/hr-leave/leave-usage-report/filter/",
      },
    ],
  })
  const [pagination, setPagination] = useState<ExpandableListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });

  let tableHeader: any = constant.TABLE_HEADER
  let searchColumnKey = constant.SEARCH_COLUMN_KEY
  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: true };

  useEffect(() => {
    tableHeader[7].render = (text, record) => {
      return <a onClick={() => getLeavePlafon(record)}>{text}</a>
    }

    initialPage()
  }, [])

  function initialPage() {
    setIsLoading(true);
    const tempFilter:any = location.state?.data;
    
    if(tempFilter) {
      emp_ids = tempFilter?.emp_ids;
      leave_config_ids = tempFilter?.leave_config_ids;
      job_ids = tempFilter?.job_ids;
      is_expired = tempFilter?.is_expired;
    }
    const body = {
      page,
      limit,
      sort,
      emp_ids,
      leave_config_ids,
      job_ids,
      is_expired,
      with_meta: true,
    };
    
    proccessFilterData({ emp_ids, leave_config_ids, job_ids, is_expired })

    usecase.getAll(body).then((res: any) => {
      const content = res.data.content;
      const metadata = res.data.metadata;

      const resultDataSource = content ? listReportMapper(content) : constant.DEFAULT_EMPTY_DATA_SOURCE
      setDataSource([...resultDataSource]);
      updatePaginationState(metadata?.total_records);

      loadPagination = true
      setDisableExportButton(!content)
      setIsLoading(false)
    })
  }

  const dropdownButton = {
    visible: true,
    disabled: isLoading || disableExportButton,
    options: [
      { key: 'export', label: 'Ekspor', onClick: () => exportData() },
    ]
  };

  function exportData() {
    setIsLoading(true)
    const params = {
      sort,
      ids: selectionId,
      emp_ids,
      leave_config_ids,
      job_ids,
      is_expired,
      with_meta: true,
    }

    usecase.export(params).then((resp: any) => {
      setIsLoading(false);
      const content = resp.data.content;
      if (resp.status === 200) {
        const bytes = content.data
        const fileName = content.filename
        createExcelFromByte(bytes, fileName);
      }
    })
  }

  async function getLeavePlafon(record: ListLeaveReportType) {
    const leave_config_id = record.leave_type_id
    const employee_id = record.employee_id
    const nik = record.nik
    const name = record.employee_name
    const params = { leave_config_id, employee_id }

    setParamsSubmissionModal({ name, nik, params })
    setVisibleSubmissionModal(true)
  }

  function actionHideModal() {
    setVisibleSubmissionModal(false);
    setParamsSubmissionModal({ name: null, nik: null, params: null})
  }

  function proccessFilterData(filterData) {
    const manual_data = []
    if (!filterData.job_ids) {
      manual_data.push(manualData('Semua Jabatan'))
    } else {
      manual_data.push(manualData('Jabatan Tertentu'))
    }

    if (!filterData.emp_ids) {
      manual_data.push(manualData('Semua Karyawan'))
    } else {
      manual_data.push(manualData('Karyawan Tertentu'))
    }

    if (!filterData.leave_config_ids) {
      manual_data.push(manualData('Semua Konfigurasi Cuti'))
    } else {
      manual_data.push(manualData('Konfigurasi Cuti Tertentu'))
    }

    if (filterData.is_expired !== null) {
      if (filterData.is_expired)  {
        manual_data.push(manualData('Cuti Kadaluarsa'))
      } else {
        manual_data.push(manualData('Cuti Aktif'))
      }
    } else {
      manual_data.push(manualData('Semua Cuti'))
    }
    
    if (manual_data.length && breadcrumbConf.manualData.length <= 1) {
      const copyBreadcrumb = JSON.parse(JSON.stringify(breadcrumbConf))
      copyBreadcrumb.manualData.push(...manual_data)
      setBreadcrumbConf(copyBreadcrumb)
    }
  }

  function manualData(displayPathName: string) {
    return { clickable: false, displayPathName, href: null }
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      initialPage();
    }
  }

  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionId) {
      selectionId = outputSelection;
    }
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    processPagination(outputPagination);
    processSelection(output.selection);
  };

  return (
    <div className="list-leave-usage-report">
      <ModalSubmissionLeave
        actionHideModal={actionHideModal}
        visibleModal={visibleSubmissionModal}
        dataSource={paramsSubmissionModal}
      />
      <ExpandableListTemplate 
        breadcrumbConf={breadcrumbConf}
        addButton={{ visible: false }}
        deleteButton={{ visible: false }}
        changeModeButton={{ visible: false }}
        exportButton={{ visible: false }}
        dropdownButton={dropdownButton}
        tableData={tableData}
        tableHeader={tableHeader}
        tableScroll={tableScroll}
        tablePagination={pagination}
        tableOnChange={tableOnChange}
        expandedRowRender={null}
        isLoading={isLoading}
        onRow={null}
      />
    </div>
  )
}