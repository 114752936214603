import moment from "moment";

export function getListAccessMapper(listAccess: any[]) {
  let result = [];
  listAccess.map((item) => {
    const temp = {
      key: item.id,
      id: item.id,
      name: item.name,
      create_name: item.create_name,
      create_date: item.create_date ? moment(item.view_create_date).format('DD-MM-YYYY HH:mm') : '-',
      write_name: item.write_name,
      write_date: item.write_date ? moment(item.view_write_date).format('DD-MM-YYYY HH:mm') : '-',
    }

    result.push(temp)
  })

  return result
}

export function getListUserMapper(listUser: any[]) {
  let result = [];
  listUser.map((item) => {
    const temp = {
      key: item.id,
      id: item.id,
      user_name: item.user_name,
      job_name: item.job_name,
      employee_name: item.employee_name,
      resource_id: item.resource_id,
      hak_akses: item.hak_akses
    }

    result.push(temp)
  })

  return result
}