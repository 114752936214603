import { floatToTimeString } from "../../../../../../@core/mapper/attendance/MasterWorkingScheduleDetail.mapper"

export const getTotalTime = (dataSource, form) => {
  let total_work_hour = 0
  let break_duration = 0

  for (const key in dataSource) {
    let break_day = 0
    const start_hours = dataSource[key].start?.format("HH:mm")
    const end_hours = dataSource[key].end?.format("HH:mm")
    const break_time = dataSource[key].break

    if (!!start_hours && !!end_hours) {
      const floatStart = start_hours.split(":")
      const start = Number(floatStart[1]) / 60 + Number(floatStart[0])
      const floatEnd = end_hours.split(":")
      const end = Number(floatEnd[1]) / 60 + Number(floatEnd[0])

      if (end < start) {
        total_work_hour += end + 24 - start
      } else {
        total_work_hour += end - start
      }
    }

    if (break_time) {
      break_day = break_time.minute() / 60 + break_time.hour()
      break_duration += break_day
    }

    total_work_hour = total_work_hour - break_day
  }

  if (total_work_hour !== 0) {
    const workTotal = floatToTimeString(total_work_hour)
    const breakTotal = floatToTimeString(break_duration)

    form.setFieldsValue({
      break_total: breakTotal,
      work_time_total: workTotal
    })
  }
}

export const onClickRadioDays = (value, form) => {
  const formValue = form.getFieldsValue()
  const tableData = formValue.table_days
  const startTime = formValue.start_time
  const endTime = formValue.end_time
  const duration = formValue.break_duration

  if (startTime) {
    setTableDataDays(value, tableData, 'start', startTime)
  }

  if (endTime) {
    setTableDataDays(value, tableData, 'end', endTime)
  }

  if (duration) {
    setTableDataDays(value, tableData, 'break', duration)
  }

  getTotalTime(tableData, form)
  form.setFieldsValue({
    start_time: null,
    end_time: null,
    break_duration: null,
    table_days: tableData,
    radio_days: {
      ...formValue.radio_days,
      selected_day: []
    }
  })
}

export const validateTime = (record, value, procedure) => {
  switch(procedure) {
    case 'start':
      return validateStart(record, value)
    case 'end':
      return validateEnd(record, value)
    case 'break':
      return validateBreak(record, value)
    default:
      return Promise.resolve()
  }
}

const validateStart = (record, value) => {
  if (!value[record.day].start && value[record.day].end) {
    return Promise.reject(new Error("Jam Mulai harus diisi"))
  }

  return Promise.resolve()
}

const validateEnd = (record, value) => {
  if (!value[record.day].end && value[record.day].start) {
    return Promise.reject(new Error("Jam Selesai harus diisi"))
  }

  return Promise.resolve()
}

const validateBreak = (record, value) => {
  if (value[record.day].break) {
    if (!value[record.day].end || !value[record.day].start) {
      return Promise.reject(new Error("Jam Mulai & Selesai harus diisi terlebih dahulu"))
    } 
  }

  return Promise.resolve()
}

const setTableDataDays = (value, tableData, procedure, data) => {
  if (value.is_all_day) {
    for (const key in tableData) {
      tableData[key][procedure] = data
    }
  } else {
    if (value.selected_day.length) {
      for (const key in tableData) {
        if (value.selected_day.includes(key.toLowerCase())) {
          tableData[key][procedure] = data
        }
      }
    }
  }
}
