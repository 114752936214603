export const TemplateHeader = ['NIK', 'Mulai Tanggal', 'Sampai Tanggal', 'Nama Jadwal Kerja'];

export const EmployeeWorkingScheduleTableColumns = [
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: null,
  },
  {
    title: 'NIK',
    dataIndex: 'nik',
    key: 'nik',
  },
  {
    title: 'Mulai Tanggal',
    dataIndex: 'start_date',
    key: 'start_date',
  },
  {
    title: 'Sampai Tanggal',
    dataIndex: 'end_date',
    key: 'end_date',
  },
  {
    title: 'Nama Jadwal Kerja',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Pesan Error',
    dataIndex: 'error_message',
    key: 'error_message',
    render: null,
  },
];
