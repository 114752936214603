import { useHistory } from 'react-router-dom';
import CardViewListTemplate from '../../../../../templates/card-view-list-template/CardViewListTemplate';
import { useEffect, useState } from 'react';
import { CardViewListTemplatePagination } from '../../../../../templates/card-view-list-template/CardViewListTemplate.interface';
import ToastService from '../../../../../services/Toast.service';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import ListEmployeeApprovalClaimUseCase from './ListEmployeeApprovalClaim.usecase';
import { ListEmployeeApprovalClaimConstant } from './ListEmployeeApprovalClaim.constant';
import { Button, Modal, Space } from 'antd';
import { getEmployeeClaimList } from '../../../../../@core/mapper/claim/ListClaim.mapper';

const usecase = new ListEmployeeApprovalClaimUseCase();
const toastService = new ToastService();
const localNotificationService = new LocalNotificationService();
const constant = ListEmployeeApprovalClaimConstant;

let page = 1;
let limit = 10;
let sort = 'write_date|desc';
let claim_number;
let nik;
let employee;
let submission_date;
let loadPagination = true;
let selectionId = [];
let id
let submissionNumber
let state: "approve" | "reject"

export default function ListEmployeeApprovalClaim() {
  const history = useHistory();

  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([]);
  let [tableScroll, setTableScroll] = useState({ y: 650, x: 'max-content' });
  let [visibleDeleteButton, setVisibleDeleteButton] = useState(false);
  let [disableExportButton, setDisableExportButton] = useState(false);
  let [visibleWarningModal, setVisibleWarningModal] = useState(false)
  let [pagination, setPagination] = useState<CardViewListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });

  let tableHeader: any = constant.TABLE_HEADER;
  let searchColumnKey = constant.SEARCH_COLUMN_KEY;
  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: true };

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: false,
        displayPathName: 'Persetujuan Klaim',
        href: '/v2/app/hr-claim/employee-approval-claim',
      },
    ],
  };

  useEffect(() => {
    loadList();
    tableHeader[11].render = (_, record) => {
      return record.key !== 'search-input' && record.key !== 'empty-data' ? (
        <Space>
          <ApproveButton rowId={record.key} 
            number={record.claim_number} />
          <RejectButton rowId={record.key} 
            number={record.claim_number} />
        </Space>
      ) : null
    }
  }, []);

  const ApproveButton = ({ rowId, number }) => {
    return (
      <Button
        size="large"
        disabled={isLoading}
        onClick={() => {
          id = rowId
          state = "approve"
          submissionNumber = number
          setVisibleWarningModal(true)
        }}
        style={{
          color: 'white',
          fontWeight: 500,
          borderRadius: '5px',
          background: '#00cc00',
        }}
      >
        Setuju
      </Button>
    )
  }

  const RejectButton = ({ rowId, number }) => {
    return (
      <Button
        size="large"
        type="primary"
        disabled={isLoading}
        onClick={() => {
          id = rowId
          state = "reject"
          submissionNumber = number
          setVisibleWarningModal(true)
        }}
        style={{
          color: 'white',
          fontWeight: 500,
          borderRadius: '5px',
        }}
      >
        Tolak
      </Button>
    )
  }

  function loadList() {
    setIsLoading(true);
    const params = {
      page,
      limit,
      sort,
      claim_number,
      nik,
      submission_date,
      employee,
      with_meta: true,
    };

    usecase.getAll(params).then(
      (res: any) => {
        setIsLoading(false);
        const content = res.data.content;
        const metaData = res.data.metadata;
        const href = "/v2/app/hr-claim/employee-approval-claim/"

        const resultDataSource = content?.length
          ? getEmployeeClaimList(content, href)
          : constant.DEFAULT_EMPTY_DATA_SOURCE;

        setDataSource(resultDataSource);
        updatePaginationState(metaData.total_records);

        loadPagination = true;
        setDisableExportButton(!content);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }

  const dropdownButton = {
    visible: true,
    disabled: isLoading,
    options: [
      {
        key: 'export',
        label: 'Ekspor',
        onClick: () => {
          setIsLoading(true);
          const params = {
            id: selectionId,
            sort,
            claim_number,
            nik,
            submission_date,
            employee,
            with_meta: true,
          };

          usecase.export(params).then((resp: any) => {
            setIsLoading(false);
            const content = resp.data.content;
            if (resp.status === 200) {
              const data = content.data;
              let sampleArr = base64ToArrayBuffer(data);
              saveByteArray(content.filename, sampleArr);
            }
          });
        },
      },
    ],
  };

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  function processSort(outputSort) {
    if (outputSort && usecase.convertOutputSortToEndPointFormat(outputSort) !== sort) {
      page = 1;
      loadPagination = false;

      sort = usecase.convertOutputSortToEndPointFormat(outputSort);
      loadList();
    }
  }

  function processSearchColumn(outputSearchColumn) {
    let isLoadList = false;

    if (outputSearchColumn) {
      if (
        outputSearchColumn.claim_number !== undefined &&
        outputSearchColumn.claim_number !== claim_number
      ) {
        claim_number = outputSearchColumn.claim_number;
        isLoadList = true;
      }

      if (outputSearchColumn.nik !== undefined && outputSearchColumn.nik !== nik) {
        nik = outputSearchColumn.nik;
        isLoadList = true;
      }

      if (
        outputSearchColumn.submission_date !== undefined &&
        outputSearchColumn.submission_date !== submission_date
      ) {
        submission_date = outputSearchColumn.submission_date;
        isLoadList = true;
      }

      if (outputSearchColumn.employee !== undefined && outputSearchColumn.employee !== employee) {
        employee = outputSearchColumn.employee;
        isLoadList = true;
      }
    }

    if (isLoadList) {
      page = 1;
      loadPagination = false;
      loadList();
    }
  }

  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionId) {
      selectionId = outputSelection;
      setVisibleDeleteButton(!!selectionId.length)
    }
  }

  function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  function saveByteArray(reportName, byte) {
    let blob = new Blob([byte], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    let fileName = reportName;
    link.download = fileName;
    link.click();
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    const outputSort = output.sort;
    const outputSearchColumn = output.searchColumn;

    processSort(outputSort);
    processSearchColumn(outputSearchColumn);
    processSelection(output.selection);
    processPagination(outputPagination);
  };

  const onRow = (e) => {
    if (e.key !== 'search-input' && e.key !== 'empty-data') {
      // history.push('/app/hr-claim/employee-approval-claim/' + e.key);
    }
  };

  const approveData = () => {
    setIsLoading(true)
    const body = { state }

    usecase.update(body, id).then(
      (res) => {
        localNotificationService.openSuccess('Pengajuan Klaim telah ' + state ? 'disetujui' : 'ditolak', '', 1)
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      }).catch(err => {
        setIsLoading(false)
        toastService.error(err)
      })
  }

  return (
    <div className="list-employee-approval-claim">
      <Modal
        cancelText="BATALKAN"
        centered={true}
        visible={visibleWarningModal}
        cancelButtonProps={{ type: 'ghost' }}
        onOk={approveData}
        onCancel={() => setVisibleWarningModal(false)}
      >
        <div style={{
          display: "flex",
          width: '100%',
          flexDirection: "column",
          alignItems: 'center',
        }}>
          <p>
            Apakah anda yakin ingin 
            {state === "approve" ? ' menyetujui ' : ' menolak '} 
            Pengajuan Klaim
          </p>
          <a href="">{submissionNumber}</a>
        </div>
      </Modal>
      <CardViewListTemplate
        breadcrumbConf={breadcrumbConf}
        addButton={{ visible: false }}
        deleteButton={{ visible: false }}
        exportButton={{ visible: false }}
        dropdownButton={dropdownButton}
        tableData={tableData}
        isOnlyTable={true}
        EditButtonCard={{ visible: false, disabled: false, onClick: null }}
        DeleteButtonCard={{ visible: false, disabled: false, onClick: null }}
        selectedRowKeys={selectionId}
        tableHeader={tableHeader}
        tableOnChange={tableOnChange}
        onRow={onRow}
        tablePagination={pagination}
        tableScroll={tableScroll}
        isLoading={isLoading}
      />
    </div>
  );
}
