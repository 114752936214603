import { useHistory } from "react-router-dom";
import { ListMassLeaveConstant } from "./ListMassLeave.constant";
import ListMassLeaveUseCase from "./ListMassLeave.usecase";
import { useEffect, useState } from "react";
import { ExpandableListTemplatePagination } from "../../../../../templates/expandable-list-template/ExpandableListTemplate.interface";
import ExpandableListTemplate from "../../../../../templates/expandable-list-template/ExpandableListTemplate";
import { createExcelFromByte } from "../../../payroll/configuration/master-payroll/list-master-payroll/ListMasterPayroll.service";
import { GetListMassLeaveMapper } from "../../../../../@core/mapper/leave/MassLeave.mapper";
import ToastService from "../../../../../services/Toast.service";
import LocalNotificationService from "../../../../../services/LocalNotification.service";

const useCase = new ListMassLeaveUseCase()
const toastService = new ToastService()
const localNotificationService = new LocalNotificationService()
const constant = ListMassLeaveConstant

let page = 1;
let loadPagination = true;
let sort = 'write_date|desc'
const limit = 10;
let selectionId = []
let name
let reduce_leave_rem
let is_active

export default function ListMassLeave() {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false)
  const [dataSource, setDataSource] = useState([]);
  const [tableScroll, setTableScroll] = useState({ y: 600, x: 'max-content' });
  const [pagination, setPagination] = useState<ExpandableListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });

  let tableHeader: any = constant.TABLE_HEADER
  let searchColumnKey = constant.SEARCH_COLUMN_KEY
  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: true };

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: false,
        displayPathName: 'Cuti Bersama',
        href: "/v2/app/hr-leave/all-request",
      },
    ],
  };

  useEffect(() => {
    loadList()
  }, [])

  const addButton = {
    onClick: () => {
      history.push('/app/hr-leave/all-request/new/');
    },
    disabled: isLoading,
  };

  const dropdownButton = {
    visible: true,
    disabled: isLoading,
    options: [
      { key: 'export', label: 'Ekspor', onClick: () => exportData() },
      { key: 'non-active', label: 'Non-Aktifkan', onClick: () => active() }
    ]
  };

  const onRow = (e) => {
    if (e.key !== 'search-input' && e.key !== 'empty-data') {
      history.push("/app/hr-leave/all-request/" + e.key)
    }
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    const outputSort = output.sort;
    const outputSearchColumn = output.searchColumn;
    
    processSort(outputSort);
    processSearchColumn(outputSearchColumn);
    processSelection(output.selection);
    processPagination(outputPagination);
  };

  function loadList() {
    setIsLoading(true)
    const params = {
      page,
      limit,
      sort,
      name,
      is_active,
      reduce_leave_rem,
      with_meta: true,
    }

    useCase.getAll(params).then((res: any) => {
      const content = res.data.content;
      const metaData = res.data.metadata;

      const resultDataSource = content?.length ? GetListMassLeaveMapper(content) 
        : constant.DEFAULT_EMPTY_DATA_SOURCE

      setDataSource([...resultDataSource]);
      updatePaginationState(metaData.total_records);

      loadPagination = true

      setIsLoading(false)
    }, (err) => {
      setIsLoading(false)
    })
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  function processSort(outputSort) {
    if (outputSort && useCase.convertOutputSortToEndPointFormat(outputSort) !== sort) {
      page = 1;
      loadPagination = false;

      sort = useCase.convertOutputSortToEndPointFormat(outputSort);
      loadList();
    }
  }

  function processSearchColumn(outputSearchColumn) {
    let isLoadList = false;

    if (outputSearchColumn) {
      if (
        outputSearchColumn.leave_name !== undefined &&
        outputSearchColumn.leave_name !== name
      ) {
        name = outputSearchColumn.leave_name;
        isLoadList = true;
      }
    }

    if (outputSearchColumn) {
      if (
        outputSearchColumn.reduce_leave_rem !== undefined &&
        outputSearchColumn.reduce_leave_rem !== reduce_leave_rem
      ) {
        reduce_leave_rem = outputSearchColumn.reduce_leave_rem;
        isLoadList = true;
      }
    }

    if (outputSearchColumn) {
      if (
        outputSearchColumn.status !== undefined &&
        outputSearchColumn.status !== is_active
      ) {
        is_active = outputSearchColumn.status;
        isLoadList = true;
      }
    }

    if (isLoadList) {
      page = 1;
      loadPagination = false;
      loadList();
    }
  }

  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionId) {
      selectionId = outputSelection;
    }
  }

  function active() {
    const params = {
      leave_all_request_ids: selectionId,
      status: false
    };

    useCase.active(params).then(
      (res: any) => {
        setIsLoading(true)
        const content = res.data.content
        if (content.code === 200) {
          const message = content.message.split("- ")[1]
          localNotificationService.openSuccess(message, '', 1)
        }
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      },
      (err) => {
        setIsLoading(false)
        toastService.error(err)
      },
    );
  }

  function exportData() {
    setIsLoading(true);

    const params = {
      sort,
      name,
      is_active,
      reduce_leave_rem,
      id: selectionId,
      with_meta: true,
    };

    useCase.export(params).then(
      (res: any) => {
        const content = res.data.content;
        if (res.data.status === 200) {
          const bytes = content.data
          const fileName = content.filename
          createExcelFromByte(bytes, fileName);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    )
  }

  return (
    <div id="mass-leave-list" style={{ height: 'calc(100vh - 100px)' }}>
      <ExpandableListTemplate
        breadcrumbConf={breadcrumbConf}
        addButton={addButton}
        deleteButton={{ visible: false }}
        exportButton={{ visible: false }}
        dropdownButton={dropdownButton}
        tableHeader={tableHeader}
        tableData={tableData}
        tablePagination={pagination}
        tableOnChange={tableOnChange}
        isLoading={isLoading}
        tableScroll={tableScroll}
        onRow={onRow}
      />
    </div>
  )
}