import { useEffect, useState } from 'react';
import LandingListTemplate from '../../../../../../templates/landing-list-template/LandingListTemplate';
import { useHistory } from 'react-router-dom';
import { Modal, DatePicker, Row } from 'antd';
import Moment from 'moment';
import { LeaveConfigConstant } from '../../../../../../config/Leave.constant';
import { LandingListTemplatePagination } from '../../../../../../templates/landing-list-template/LandingListTemplate.interface';
import ConfigurationLeaveUseCaseImpl from './HrConfigurationLeave.usecase.impl';
import LocalNotificationService from '../../../../../../services/LocalNotification.service';

const useCaseImpl = new ConfigurationLeaveUseCaseImpl();
const localNotificationService = new LocalNotificationService();

let page = 1;
let loadPagination = true;
let sort = 'write_date|desc';
let name = '';
let plafon_type = '';
let active_when = '';
let is_active = '';
let config_type = '';
let approval_1 = '';
let approval_2 = '';
let periode_reset = '';
let selectionLeaveId = [];
let selectionProcess = {};

export default function HrConfigurationLeave() {
  const history = useHistory();
  const tableHeader: any = LeaveConfigConstant.TABLE_HEADER;
  const searchColumnKey = LeaveConfigConstant.SEARCH_COLUMN_KEY;
  const searchColumnComponent = LeaveConfigConstant.SEARCH_COLUMN_COMPONENT;
  const limit = 10;

  let [pagination, setPagination] = useState<LandingListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });
  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([]);
  let [tableScroll, setTableScroll] = useState({ y: 200 });
  let [disableDeleteButton, setDisableDeleteButton] = useState(true);
  let [visibleReprocessModal, setVisibleReprocessModal] = useState(false);
  let [processPeriod, setProcessPeriod] = useState<any>();
  let [disableExportButton, setDisableExportButton] = useState(false);

  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: true, searchColumnComponent };

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {

    loadList();
    const clientHeight = document.getElementById('employee-attendance-page').clientHeight;

    setTableScroll({ y: clientHeight - 220 });
  }

  function loadList() {
    setIsLoading(true);

    const params = {
      page,
      limit,
      sort,
      name,
      plafon_type,
      periode_reset,
      active_when,
      is_active,
      config_type,
      approval_1,
      approval_2,
      with_meta: true,
    };
    useCaseImpl
      .getLeaveConfigurationList(params, '/v2/app/hr-leave/leave-configuration/')
      .then(
        (res: any) => {
          setIsLoading(false);
          const content = res.content;
          const metaData = res.metaData;
          const resultDataSource = (content && content.length) ? content : LeaveConfigConstant.DEFAULT_EMPTY_DATA_SOURCE;
          setDataSource([...resultDataSource]);
          updatePaginationState(metaData.total_records);

          loadPagination = true;
          setDisableExportButton(content ? false : true);
        },
        (err) => {
          setIsLoading(false);
          setDataSource([...LeaveConfigConstant.DEFAULT_EMPTY_DATA_SOURCE]);
          loadPagination = true;
          setDisableExportButton(true);
        }
      )
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    const outputSort = output.sort;
    const outputSearchColumn = output.searchColumn;
    const outputAction = output.tableAction;

    processAction(outputAction);
    // processSort(outputSort);
    processSearchColumn(outputSearchColumn);
    processSelection(output.selection);
    processPagination(outputPagination);
  };

  function processAction(outputAction) {
    if(outputAction && outputAction.id){
      setVisibleReprocessModal(true);
      selectionProcess = outputAction;
    }
  }

  function reProcessLeave(outputAction) {
    if (outputAction && outputAction.id) {
      const param = {
        id: outputAction.id,
        periode: processPeriod
      }
      setIsLoading(true);
      useCaseImpl.generateLeaveConfiguration(param).then(
        (res: any) => {
          setIsLoading(false);
          const data = res.data;
          if (data.status === 200) {
            localNotificationService.openToast('topRight', data.content.message || data.content.cause, '', {color: '#fff', background: 'red'}, 3)
          }
          setVisibleReprocessModal(false);
        },
        (err) => {
          setIsLoading(false);
        },
      );
    }
  }

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  // function processSort(outputSort) {
  //   if (outputSort && hrLeaveService.convertOutputSortToEndPointFormat(outputSort) !== sort) {
  //     page = 1;
  //     loadPagination = false;

  //     sort = hrLeaveService.convertOutputSortToEndPointFormat(outputSort);
  //     loadList();
  //   }
  // }

  function processSearchColumn(outputSearchColumn) {
    let isLoadList = false;
    if (outputSearchColumn) {
      if (
        outputSearchColumn.name !== undefined &&
        outputSearchColumn.name !== name
      ) {
        name = outputSearchColumn.name;
        isLoadList = true;
      }

      if (
        outputSearchColumn.plafon_type !== undefined &&
        outputSearchColumn.plafon_type !== plafon_type
      ) {
        plafon_type = outputSearchColumn.plafon_type;
        isLoadList = true;
      }
      if (
        outputSearchColumn.date_plafon_reset !== undefined &&
        outputSearchColumn.date_plafon_reset !== periode_reset
      ) {
        periode_reset = outputSearchColumn.date_plafon_reset;
        isLoadList = true;
      }
      if (
        outputSearchColumn.active_when !== undefined &&
        outputSearchColumn.active_when !== active_when
      ) {
        active_when = outputSearchColumn.active_when;
        isLoadList = true;
      }
      if (
        outputSearchColumn.is_active !== undefined &&
        outputSearchColumn.is_active !== is_active
      ) {
        is_active = outputSearchColumn.is_active;
        isLoadList = true;
      }
      if (
        outputSearchColumn.config_type !== undefined &&
        outputSearchColumn.config_type !== config_type
      ) {
        config_type = outputSearchColumn.config_type;
        isLoadList = true;
      }
      if (
        outputSearchColumn.approval_1 !== undefined &&
        outputSearchColumn.approval_1 !== approval_1
      ) {
        approval_1 = outputSearchColumn.approval_1;
        isLoadList = true;
      }
      if (
        outputSearchColumn.approval_2 !== undefined &&
        outputSearchColumn.approval_2 !== approval_2
      ) {
        approval_2 = outputSearchColumn.approval_2;
        isLoadList = true;
      }

      if (isLoadList) {
        page = 1;
        loadPagination = false;
        loadList();
      }
    }
  }

  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionLeaveId) {
      selectionLeaveId = outputSelection;
      setDisableDeleteButton(selectionLeaveId.length ? false : true);
    }
  }

  const addButton = {
    onClick: () => {
      history.push('/app/hr-leave/leave-configuration/new/');
    },
    disabled: isLoading,
  };

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
  }
  function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };

  async function changeStatus(e) {
    setIsLoading(true);
    const params = {
      ids: selectionLeaveId,
      status: e
    }
    let message = 'Konfigurasi Cuti Berhasil di non-aktifkan';
    if(e === 'aktif') {
      message = 'Konfigurasi Cuti Berhasil di aktifkan';
    }
    useCaseImpl.deactiveLeaveConfiguration(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {

          loadList();
          localNotificationService.openSuccess(message, '', 3);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }

  const exportButton = {
    onClick: () => {
      setIsLoading(true);
      const params = {
        page,
        limit,
        sort,
        name,
        plafon_type,
        periode_reset,
        active_when,
        is_active,
        config_type,
        approval_1,
        approval_2,
        with_meta: true,
      };
      useCaseImpl.exportLeaveConfiguration(params, ).then(
        (res: any) => {
          const data = res.data;
          if (data.status === 200) {
            const file = base64ToArrayBuffer(data.content.data);
            saveByteArray(data.content.filename, file);
          }

          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
        },
      );
    },
    disabled: disableExportButton || isLoading,
  };

  const dropdownButton = {
    visible: true,
    disabled: selectionLeaveId.length < 1,
    options: [
      { key: 'deactive', label: 'Non-Aktifkan', onClick: () => changeStatus(false) },
    ]
  };
  

  return (
    <div id="employee-attendance-page" style={{ height: 'calc(100vh - 100px)' }}>
      <Modal
        title="Proses Ulang"
        cancelText="Batal"
        okText="Proses Ulang"
        visible={visibleReprocessModal}
        cancelButtonProps={{ type: 'ghost' }}
        onOk={() => reProcessLeave(selectionProcess)}
        okButtonProps={{ disabled: !processPeriod }}
        onCancel={() => setVisibleReprocessModal(false)}
        
      >
        <Row align='middle'>
          <span style={{ fontWeight: 'bold' }}> Periode : </span><DatePicker onChange={(val) => setProcessPeriod(Moment(val).format('YYYY-MM'))} picker="month" allowClear={false} />
        </Row>
      </Modal>
      <LandingListTemplate
        addButton={addButton}
        deleteButton={{visible: false}}
        exportButton={exportButton}
        dropdownButton={dropdownButton}
        tableHeader={tableHeader}
        tableData={tableData}
        tablePagination={pagination}
        tableOnChange={tableOnChange}
        isLoading={isLoading}
        tableScroll={tableScroll}
      />
    </div>
  );
}
