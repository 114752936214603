import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './JoinWithUs.less';
import RegisterFormTemplate from './template/RegisterFormTemplate';
import JoinWithUsUsecase from './JoinWithUs.usecase';

const usecase = new JoinWithUsUsecase();

export default function JoinWithUs() {
  const history = useHistory();
  let [isLoading, setIsLoading] = useState(true);
  let [listProvince, setListProvince] = useState<any>([]);
  
  const [listSumEmp, setListSumEmp] = useState<any>([]);
  const [listComType, setListComType] = useState<any>([]);
  const [listComCat, setListComCat] = useState<any>([]);
  const [listParam, setListParam] = useState<any>({});
  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {
    setIsLoading(true);
    const getProvince = await usecase.getProvince({ country_id: [101] });
    const getSumEmpOpt: any = await usecase.getSumEmployeeOption();
    const getCompCat: any = await usecase.getCompanyCategory();
    const getCompType: any = await usecase.getCompanyType();
    

    setListComType(getCompType?.data?.content)
    setListComCat(getCompCat?.data?.content)
    setListSumEmp(getSumEmpOpt?.data?.content);


    if (getProvince?.data?.content && getProvince?.data?.content.length > 0) {
      setListProvince(getProvince.data.content
      );
    }
    setIsLoading(false);
  }

  const onFinish = (e, index) => {
    setListParam({ ...listParam, ...e });
    if (index === 2) {
      const tempParam = { ...listParam, ...e }
      const params = {
        province_by_id: 618,
        city_by_id: 628,
        district_by_id: 10684,
        village_by_id: 287596,
        company_category_id: tempParam.bussiness_category,
        pic: tempParam.name,
        company_type_id: tempParam.enterprices,
        cfg_sum_employee_id: tempParam.total_employee,
        pic_phone_number: tempParam.no_telp,
        email: tempParam.email,
        zip_code: '11530',
        phone_number: tempParam.company_telp,
        name: tempParam.company_name,
        company_address: tempParam.detail_company_address,
        bank_account_number: tempParam.ocbc_account_number,
        is_terms_conditions: true,
        is_full_tc: true,
        company_code: tempParam.company_code,
      };
      usecase.submitCompanyData(params).then(
        (res: any) => {
          const data = res.data;
          if (data.status === 200) {
            window.location.href = '/v2/web/thank-you';
          }
          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
        },
      );
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className="join-with-us">
      <img
        src="https://www.hira-hr.com/hira_homepage/static/img/background/bg-design-1.jpg"
        alt=""
      />
      <div className="register-form-card">
        <h1>JOIN WITH US</h1>
        <RegisterFormTemplate
          onFinish={onFinish}
          listProvince={listProvince}
          listSumEmp={listSumEmp}
          listComCat={listComCat}
          listComType={listComType}
        />
      </div>
    </div>
  );
}
