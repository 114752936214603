export const EmployeeTerminateListConstant = {
  TABLE_HEADER: [
    {
      title: 'Nomor Terminasi',
      key: 'terminate_number',
      dataIndex: 'terminate_number',
      width: 240,
      editable: false,
      isCTA: true
    },
    {
      title: 'Nomor Induk Karyawan',
      key: 'nomor_induk_karyawan',
      dataIndex: 'nomor_induk_karyawan',
      width: 240,
      editable: false,
    },
    {
      title: 'Nama',
      key: 'name',
      dataIndex: 'name',
      width: 240,
      editable: false,
    },
    {
      title: 'Jabatan',
      key: 'job',
      dataIndex: 'job',
      width: 240,
      editable: false,
    },
    {
      title: 'Departemen',
      key: 'department',
      dataIndex: 'department',
      width: 240,
      editable: false,
    },
    {
      title: 'Kategori Terminasi',
      key: 'terminate_category',
      dataIndex: 'terminate_category',
      width: 240,
      editable: false,
    },
    {
      title: 'Keterangan',
      key: 'keterangan',
      dataIndex: 'keterangan',
      width: 240,
      editable: false,
    },
    {
      title: 'Tanggal Efektif Terminasi',
      key: 'terminate_date',
      dataIndex: 'terminate_date',
      width: 240,
      editable: false,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 240,
      editable: false,
    },
    {
      title: 'Dibuat Oleh',
      key: 'create_name',
      dataIndex: 'create_name',
      editable: false,
      width: 200,
    },
    {
      title: 'Dibuat Pada',
      key: 'create_date',
      dataIndex: 'create_date',
      editable: false,
      width: 160,
      isFormatDate: true,
      sorter: true,
    },
    {
      title: 'Diubah Oleh',
      key: 'write_name',
      dataIndex: 'write_name',
      editable: false,
      width: 200,
    },
    {
      title: 'Diubah Pada',
      key: 'write_date',
      dataIndex: 'write_date',
      editable: false,
      width: 160,
      isFormatDate: true,
      sorter: true,
    },
  ],
  SEARCH_COLUMN_KEY: [
    { key: 'key', componentType: 'search-input' },
    { key: 'terminate_number', componentType: 'text' },
    { key: 'nomor_induk_karyawan', componentType: 'text' },
    { key: 'name', componentType: 'text' },
    { key: 'job', componentType: 'text' },
    { key: 'department', componentType: 'text' },
    { key: 'terminate_category', componentType: 'select', options: [] },
    { key: 'status', componentType: 'select', options: [
      { key: 'all', label: 'Semua', value: null },
      { key: 'active', label: 'Aktif', value: true },
      { key: 'non-active', label: 'Non-Aktif', value: false },
    ] },
  ],
  DEFAULT_EMPTY_DATA_SOURCE: [
    {
      key: 'empty-data',
      terminate_number: '',
      nomor_induk_karyawan: '',
      name: '',
      job: '',
      department: '',
      terminate_category: '',
      keterangan: '',
      terminate_date: '',
      status: '',
      create_name: '',
      create_date: '',
      write_name: '',
      write_date: '',
    },
  ],
};
