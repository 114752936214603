import React from 'react';
import { Spin, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

function PageLoader() {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const style:any = {
    position: 'absolute',
    height: '80vh',
    width: '80vw',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    };
  return (
    <div style={style} className="page-loader">
      <div>
        <div style={{ paddingBottom: '20px' }}>
          <Spin indicator={antIcon} style={{ fontSize: 50, color: 'green' }} />
        </div>
        <Typography.Text>loading</Typography.Text>
      </div>
    </div>
  );
}


export default PageLoader;