import { ExportLeaveResp } from '../../interface/leave/ExportLeave.interface';
import { GetLeavesDTO } from '../../interface/leave/GetLeaves.interface';
import { SubmissionLeaveList } from '../../entity/SubmissionLeaveList.entity';
import { LeaveTypeObjById } from '../../entity/LeaveType.entity';

export default abstract class ApprovalSubmissionLeaveUseCase {
  abstract getLeaveTypes(): Promise<LeaveTypeObjById>;
  abstract getSubmissionLeaves(
    params: GetLeavesDTO,
    leaveTypeObj: LeaveTypeObjById,
    hrefPath: string,
  ): Promise<SubmissionLeaveList>;
  abstract exportSubmissionLeaves(params: GetLeavesDTO): Promise<ExportLeaveResp>;
}
