import { RecurringHolidaysFormStateType } from "./AddRecurringHolidays.interface";

export const INITIAL_FORM_STATE: RecurringHolidaysFormStateType = {
  holidayName: {
    active: true,
    value: '',
    editMode: true,
    rules: [{ required: true, whitespace: true, message: 'Mohon Lengkapi kolom ini'}]
  },
  startDate: {
    active: true,
    value: '',
    editMode: true,
    rules: [{ required: true, message: 'Mohon Lengkapi kolom ini'}]
  },
  endDate: {
    active: true,
    value: '',
    editMode: true,
    rules: [{ required: true, message: 'Mohon Lengkapi kolom ini'}]
  },
  holidayLength: {
    active: true,
    value: '',
    editMode: true,
    rules: [{ required: false, message: 'Mohon Lengkapi kolom ini'}]
  },
  isRecurring: {
    active: true,
    value: true,
    editMode: true,
    rules: [{ required: false, message: 'Mohon Lengkapi kolom ini'}]
  },
}

export const ACTION_FORM_TYPE = {
  SET_HOLIDAYS_LENGTH: "SET_HOLIDAYS_LENGTH",
  CHANGE_TO_EDIT_MODE: "CHANGE_TO_EDIT_MODE",
  CHANGE_TO_DETAIL_MODE: "CHANGE_TO_DETAIL_MODE",
}