import { MasterWorkingScheduleDetailResp } from "../../../../../../@core/mapper/attendance/MasterWorkingScheduleMapper.interface"
import Constant from "../../../../../../config/Constants"
import API from "../../../../../../services/API.service"

export default class AddMasterWorkingScheduleUseCase {
  getDetail(id) {
    const URL = Constant.URL_BASE_ATTENDANCE + `/v3/configuration/master-jadwal-kerja/${id}`
    return API.get(URL)
  }

  save(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/configuration/master-jadwal-kerja"
    return API.post(URL, body)
  }

  update(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + `/v3/configuration/master-jadwal-kerja`
    return API.put(URL, body)
  }

  active(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/configuration/master-jadwal-kerja/active"
    return API.put(URL, body)
  }
}