import { useEffect, useState } from 'react';
import ImportCsvTemplate from '../../../../../../templates/import-csv-template/ImportCsvTemplate';
import { EmployeeWorkingScheduleTableColumns } from './ImportEmployeeWorkingSchedule.constant';
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import ImportEmployeeWorkingScheduleUseCase from './ImportEmployeeWorkingSchedule.usecase';
import HrLeaveService from '../../../../leaves/HrLeaveService';
import SharedService from '../../../../../../services/Shared.service';
import { mapDataSourceToParam } from './ImportEmployeeWorkingSchedule.service';
import ErrorDetailButton from '../../../../../../templates/import-csv-template/component/error-detail-button/ErrorDetailButton';
import LocalNotificationService from '../../../../../../services/LocalNotification.service';
import ToastService from '../../../../../../services/Toast.service';

const localNotificationService = new LocalNotificationService();
const useCase = new ImportEmployeeWorkingScheduleUseCase();
const hrLeaveService = new HrLeaveService();
const sharedService = new SharedService();
const toastService = new ToastService();
let selectedFile;
let body;
let masterSchedule;

export default function ImportEmployeeWorkingSchedule() {
  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Jadwal Kerja Karyawan',
        href: `/v2/app/attendance/employee-working-schedule`,
      },
      {
        clickable: false,
        displayPathName: 'Import',
        href: '/v2/app/hr-leave/employee-approval/new',
      },
    ],
  };
  let [disabledValidationButton, setDisabledValidationButton] = useState(true);
  let [disabledSaveButton, setDisabledSaveButton] = useState(true);
  let [disabledDownloadTemplateButton, setDisabledDownloadTemplateButton] = useState(false);
  let tableHeader = EmployeeWorkingScheduleTableColumns;
  let [isLoading, setIsLoading] = useState(true);
  let [tableData, setTableData] = useState([]);
  let [tableScroll, setTableScroll] = useState({ y: 200 });

  useEffect(() => {
    tableHeader[0].render = (status) => {
      return status ? (
        <CheckCircleOutlined style={{ fontSize: '18px', color: '#60b23c' }} />
      ) : (
        <WarningOutlined style={{ fontSize: '18px', color: '#d64219' }} />
      );
    };

    tableHeader[5].render = (text, record) => {
      return !record.status ? <ErrorDetailButton data={record} /> : null;
    };

    const clientHeight = document.getElementById('import-employee-working-schedule').clientHeight;
    setTableScroll({ y: clientHeight - 310 });

    selectedFile = undefined;

    useCase.getMasterSchedule().then((res: any) => {
      masterSchedule = res;
      setIsLoading(false);
    });
  }, []);

  const onFileSelected = (e) => {
    setTableData([...[]]);

    if (e.status === 'success') {
      setDisabledValidationButton(false);
      setDisabledSaveButton(true);
      selectedFile = e;
    } else {
      setDisabledValidationButton(true);
      setDisabledSaveButton(true);

      const error_message = e.content.error_message;
      toastService.error(error_message);
    }
  };

  const onClickValidate = () => {
    setIsLoading(true);

    useCase.doValidate(selectedFile, masterSchedule).then(
      async (res: any) => {
        const dataSource = res.content.dataSource;

        body = await mapDataSourceToParam(dataSource, masterSchedule);

        useCase.validateToServer(body).then(
          (res: any) => {
            const data = res.data;

            if (data.content.valid === true) {
              setTableData([...dataSource]);
              setDisabledSaveButton(false);
            } else {
              const errors = data.content.errors;

              processValidateFromServer(dataSource, errors);
              setDisabledSaveButton(true);
            }

            setDisabledValidationButton(true);
            setIsLoading(false);
          },
          (err) => {
            setTableData([...dataSource]);
            setDisabledSaveButton(true);
            setDisabledValidationButton(true);
            setIsLoading(false);
          },
        );
      },
      (err: any) => {
        const error_message = err.content.errorMessage;
        toastService.error(error_message);
        setIsLoading(false);
        setDisabledSaveButton(true);
        setDisabledValidationButton(true);
      },
    );
  };

  const processValidateFromServer = (dataSource, errors) => {
    if (errors.length) {
      let temp = JSON.parse(JSON.stringify(dataSource));

      errors.forEach((error: any, index) => {
        const indexError = error.index;
        if (error.message.includes(';')) {
          const rowErrors = error.message.split('; ');
          rowErrors.forEach((data) => {
            const rawError = data.split(': ');
            temp = proceedError(temp, rawError, indexError);
          });
        } else {
          const rawError = error.message.split(': ');
          temp = proceedError(temp, rawError, indexError);
        }

        if (index === errors.length - 1) {
          setTableData([...temp]);
        }
      });
    }
  };

  function proceedError(dataSource, rawError, indexError) {
    const ignoreDateValidate = [
      'Harus diisi',
      'Format tanggal salah',
      'Sampai Tanggal harus lebih besar dari Mulai Tanggal',
    ];

    const errorField = getKeyAttribute(rawError[0]);
    const errorMessage = rawError[1];

    if (errorField !== 'ignore_validate') {
      if (errorField === 'start_date' || errorField === 'end_date') {
        if (ignoreDateValidate.indexOf(errorMessage) === -1 && dataSource[indexError]) {
          dataSource[indexError].status = false;
          dataSource[indexError].error_list[errorField].status = false;
          dataSource[indexError].error_list[errorField].message.push(errorMessage);
        }
      } else if (dataSource[indexError]) {
        dataSource[indexError].status = false;
        dataSource[indexError].error_list[errorField].status = false;
        dataSource[indexError].error_list[errorField].message.push(errorMessage);
      }
    }

    return dataSource;
  }

  const getKeyAttribute = (key) => {
    switch (key) {
      default:
        return 'field tidak dikenal';
      case 'employee_nik':
        return 'nik';
      case 'date_start':
        return 'start_date';
      case 'date_end':
        return 'end_date';
      case 'name':
        return 'name';
      case 'hira_absent_id':
        return 'ignore_validate'; // already handle in front
    }
  };

  const onClickSave = () => {
    setIsLoading(true);
    useCase.saveSchedule(body).then(
      (res: any) => {
        const data = res.data;

        if (data.status === 200) {
          localNotificationService.openSuccess('Impor data berhasil.', '', 3);

          setTimeout(() => {
            const backLink = `/v2/app/attendance/employee-working-schedule`;
            sharedService.backToOdooLink(backLink);
          }, 1500);
        } else {
          setIsLoading(false);
        }
      },
      (err: any) => {
        setIsLoading(false);
      },
    );
  };

  const onClickDownloadTemplate = () => {
    setIsLoading(true);

    useCase.downloadTemplate().then((res: any) => {
      setIsLoading(false);
      const data = res.data;

      if (data.status === 200) {
        hrLeaveService.createAndExecuteHref(data.content.url);
      }
    });
  };

  return (
    <div id="import-employee-working-schedule">
      <ImportCsvTemplate
        breadcrumbConf={breadcrumbConf}
        chooseFileButton={{
          onSelected: onFileSelected,
        }}
        validateButton={{
          disabled: disabledValidationButton,
          onClick: onClickValidate,
        }}
        saveButton={{
          disabled: disabledSaveButton,
          onClick: onClickSave,
        }}
        downloadTemplateButton={{
          disabled: disabledDownloadTemplateButton,
          onClick: onClickDownloadTemplate,
        }}
        tableHeader={tableHeader}
        tableData={tableData}
        isLoading={isLoading}
        tableScroll={tableScroll}
      />
    </div>
  );
}
