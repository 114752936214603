import AddEmployeeDepartmentTemplate from "../template/AddMasterDepartmentTemplate";
import { ToolbarDetailActionButton } from "../../components/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface";
import { useEffect, useState } from "react";
import DetailMasterDepartmentUsecase from "./DetailMasterDepartment.usecase";
import ToastService from "../../../../../services/Toast.service";
import LocalNotificationService from "../../../../../services/LocalNotification.service";
import { DetailMasterDepartmentType, ListTableDepartmentType } from "../template/AddMasterDepartmentTemplate.interface";
import { getChildDepartmentListMapper, getDetailDepartmetMapper } from "../../../../../@core/mapper/employee/GetDepartmentList.mapper";

const usecase = new DetailMasterDepartmentUsecase()
const toastService = new ToastService()
const localNotificationService = new LocalNotificationService();

let page = 1
let limit = 5
let parent_id

export default function DetailMasterDepartment({ match }) {
  const departmentId = match.params.departmentId
  
  const [isLoading, setIsLoading] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [viewMode, setViewMode] = useState(true)
  const [departmentList, setDepartmentList] = useState([])
  const [listChildDepartment, setListChildDepartment] = useState([])
  const [visibleWarningModal, setVisibleWarningModal] = useState(false)
  const [detailDataSource, setDetailDataSource] = useState<DetailMasterDepartmentType>({
    name: null,
    parent_id: null,
    parent_name: null,
  })

  let [pagination, setPagination] = useState({
    current: page,
    pageSize: limit,
    total: 0,
  });

  const listTableDataDetail: ListTableDepartmentType = {
    ...pagination,
    department_list: listChildDepartment,
    paginationOnchange: paginationOnChange,
  }

  useEffect(() => {
    init()
  }, [])

  async function init() {
    setIsLoading(true)
    await usecase.getDetail(departmentId).then(
      async (res) => {
        const detail = getDetailDepartmetMapper(res.data.content)

        const params = {
          is_active: true,
          exc_parent: detail.id
        }
    
        const listDept = await usecase.getDept(params)
        setDepartmentList([...listDept])

       
        parent_id = detail.id
        detailDataSource.name = detail.name
        detailDataSource.parent_id = detail.parent_id
        detailDataSource.parent_name = getDeptName(detail.parent_id, listDept)


        setDetailDataSource(JSON.parse(JSON.stringify(detailDataSource)))
        getChildDepartmentList(page)
        setIsActive(detail.is_active)
        setIsLoading(false)
      }
    ).catch(err => {
      setIsLoading(false)
    })
  }

  function getChildDepartmentList(page) {
    const params = {
      page,
      limit,
      parent_id,
      with_meta: true,
    }
    usecase.getChildDept(params).then(
      (res) => {
        const content = getChildDepartmentListMapper(res.data.content)
        const metadata = res.data.metadata;
        setListChildDepartment([...content])
        updatePaginationState(metadata.total_records)
        setIsLoading(false)
      }
    ).catch(err => {
      setIsLoading(false)
    })
  }

  function getDeptName(id, listDept) {
    return listDept.find((dept) => dept.value === id)?.label
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  function paginationOnChange(page) {
    const tempPagination = pagination;

    tempPagination.current = page;
    getChildDepartmentList(tempPagination.current)
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Master Departemen',
        href: "/v2/app/hr-employee/master-department",
      },
      {
        clickable: false,
        displayPathName: detailDataSource.name,
        href: "/v2/app/hr-employee/master-department/new/",
      }
    ],
  };

  const submitButton = { active: !viewMode, disabled: false };
  const firstActionButton: ToolbarDetailActionButton = {
    label: 'UBAH',
    type: 'primary',
    active: viewMode,
    disabled: false,
    onClick: () => {
      setViewMode(false)
    },
  };
  const secondActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: false,
    onClick: () => {
      if (!viewMode) {
        return window.location.reload()
      }
      const back = window.confirm("Anda Ingin Kembali")
      if (back) {
        window.location.href = "/v2/app/hr-employee/master-department"
      }
    },
  };

  const dropdownButton = {
    visible: true,
    disabled: isLoading,
    options: [
      { key: 'active', label: isActive ? 'Non-Aktifkan' : 'Aktifkan', onClick: () => setActive(!isActive) },
    ]
  };

  function setActive(status) {
    setIsLoading(true);
    setVisibleWarningModal(false);

    const params = {
      ids: [departmentId],
      status
    };

    usecase.active(params).then(
      (res: any) => {
        setIsLoading(false);
        const content = res.data
        if (content.code === 200) {
          const message = content.message
          localNotificationService.openSuccess(message, '', 1)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }
      },
      (err) => {
        setIsLoading(false);
        toastService.error(err)
      },
    );
  }

  const onFinish = (e) => {
    setIsLoading(true)
    const body = {
      id: departmentId,
      name: e.name?.trim(),
      parent_id: e.parent_id,
      active: true,
    }
    usecase.update(body).then(
      (res) => {
        const data = res.data
        if (data.code === 200) {
          const message = data.message
          localNotificationService.openSuccess(message, '', 1)
        }
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      }
    ).catch((err) => {
      toastService.error(err)
      setIsLoading(false)
    })
  }

  return (
    <div className="add-employee-department">
      <AddEmployeeDepartmentTemplate
        breadcrumbConf={breadcrumbConf}
        listTableDataDetail={listTableDataDetail}
        listDepartment={departmentList}
        dropdownActionButton={dropdownButton}
        firstActionButton={firstActionButton}
        secondActionButton={secondActionButton}
        thirdActionButton={{ active: false }}
        fourthActionButton={{ active: false }}
        onFinish={onFinish}
        viewMode={viewMode}
        submitButton={submitButton}
        detailDataSource={detailDataSource}
        isLoading={isLoading}
      />
    </div>
  )
}