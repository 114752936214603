import { TimePicker } from "antd"

export const TimePickerMasterSchedule = ({ value, onChange, viewMode }) => {
  return (
    <TimePicker
      disabled={viewMode}
      format={"HH:mm"}
      value={value}
      onChange={onChange}
      placeholder="HH:mm"
    />
  )
}