export function mapperExcelData(excelArrays) {
  return new Promise((resolve, reject) => {
    const result = {
      status: 'success',
      dataSource: [],
    };

    excelArrays.forEach((column, index) => {
      const rowTable: any = {
        status: column.is_error === 'false',
        nik: column.nik,
        nama_karyawan: column.nama_karyawan,
        nama_jabatan: column.nama_jabatan,
        tipe_cuti: column.tipe_cuti,
        ubah_plafon: proccessChange(column.ubah_plafon),
        koreksi_plafon: column.koreksi_plafon,
        ubah_sisa_cuti: proccessChange(column.ubah_sisa_cuti),
        koreksi_sisa_cuti: column.koreksi_sisa_cuti,
        error_list: proccessError(column.description),
        error_message: new Set(),
      };

      for (const key in rowTable.error_list) {
        if (rowTable.error_list[key].status === false) {
          rowTable.status = false;
          rowTable.error_message.add(...rowTable.error_list[key].message);
        }
      }

      result.dataSource.push(rowTable);
      if (Object.keys(rowTable.error_list).length) {
        result.status = 'error'
      }
      if (index === excelArrays.length - 1) {
        if (result.status === 'error') {
          resolve(result)
        } else {
          resolve(result);
        }
      }
    });
  });
}

function proccessChange(change: string) {
  const str = change.toLowerCase()
  switch(str) {
    case 'yes':
      return 'YES'
    case 'no':
      return 'NO'
    default:
      return change
  }
}

function proccessError(rawError: string) {
  let result = {}
  if (rawError.includes(';')) {
    const rowErrors = rawError.split('; ');
    rowErrors.forEach((data) => {
      const errorField = data.split(': ')[0]
      const errorMessage = data.split(': ')[1]
      if (!result[errorField]) {
        result[errorField] = {
          status: false,
          message: [errorMessage],
          field_name: proccessFieldName(errorField)
        }
      } else {
        result[errorField].message.push(errorMessage)
      }
    });
  } else if(rawError) {
    const errorField = rawError.split(': ')[0]
    const errorMessage = rawError.split(': ')[1]
    result[errorField] = {
      status: false,
      message: [errorMessage],
      field_name: proccessFieldName(errorField)
    }
  }
  return result
}

function proccessFieldName(fieldName: string) {
  if (fieldName === 'nik') {
    return 'NIK'
  }
  const words = fieldName.split("_");
  const capitalizedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1)
  })
  const resultString = capitalizedWords.join(" ");
  return resultString
}