import API from '../../../services/API.service';
import Constant from '../../../config/Constants';
import { GetLeaveApprovalDetailResp } from '../../interface/leave/GetLeaveApprovalDetail.interface';
import { LeaveId } from '../../entity/Leave.entity';

export default function getLeaveApprovalDetail(
  leaveId: LeaveId,
): Promise<GetLeaveApprovalDetailResp> {
  const URL = `${Constant.URL_BASE_ATTENDANCE}/v3/leave/request/${leaveId}/approval`;
  return API.get(URL);
}
