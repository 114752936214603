import { useEffect, useState } from 'react';
import ToastService from '../../../../../services/Toast.service';
import { ToolbarDetailActionButton } from '../../../employee/components/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface';
import AddSubmissionLoanUsecase from './DetailApprovalLoan.usecase';
import { GetDetailApprovalLoan, GetDetailHistory } from '../../../../../@core/mapper/loan/AddSubmissionLoan.mapper';
import { DetailSubmisiionLoanType } from '../../submission-loan/template/AddSubmissionLoanTemplate.interface';
import AddSubmissionLoanTemplate from '../../submission-loan/template/AddSubmissionLoanTemplate';
import { Modal } from 'antd';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import { DetailLoanHistoryInterface } from '../../../../../@core/mapper/loan/SubmissionLoan.interface';

const usecase = new AddSubmissionLoanUsecase();
const localNotificationService = new LocalNotificationService()
const toastService = new ToastService();
const steps = [
  "Pengajuan", "Disetujui",
  "Persetujuan Ke-2", "Ditolak",
  "Batal", "Selesai"
]

let state

export default function DetailApprovalLoan({ match }) {
  const loanId = match.params.loanId
  const [isLoading, setIsLoading] = useState(false);
  const [visibleWarningModal, setVisibleWarningModal] = useState(false)
  const [detailDataSource, setDetailDataSource] = useState<DetailSubmisiionLoanType>();
  const [detailInstallment, setDetailInstallment] = useState<DetailLoanHistoryInterface[]>();

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Persetujuan Pinjaman',
        href: '/v2/app/hr-loan/approval-loan',
      },
      {
        clickable: false,
        displayPathName: detailDataSource?.name,
        href: '/v2/app/hr-loan/approval-loan/new/',
      },
    ],
  };

  const submitButton = { active: false, disabled: false };
  const firstActionButton: ToolbarDetailActionButton = {
    label: 'SETUJU',
    type: 'primary',
    active: true,
    disabled: isLoading,
    onClick: () => {
      state = "approve"
      setVisibleWarningModal(true)
    },
  };

  const secondActionButton: ToolbarDetailActionButton = {
    label: 'TOLAK',
    type: 'primary',
    active: true,
    disabled: isLoading,
    onClick: () => {
      state = "reject"
      setVisibleWarningModal(true)
    },
  };

  const thirdActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: isLoading,
    onClick: () => {
      window.location.href = "/v2/app/hr-loan/approval-loan"
    },
  };

  useEffect(() => {
    init();
  }, []);

  function init() {
    setIsLoading(true);
    usecase.getDetail(loanId).then((res) => {
      const { loan_history, loan_request } = res.data.content;

      usecase.getConfigLoan().then((cfg) => {
        const resultDetail = GetDetailApprovalLoan(loan_request);
        const resultInstall = GetDetailHistory(loan_history);
  
        setDetailDataSource(resultDetail);
        setDetailInstallment(resultInstall);
  
        setIsLoading(false);
      })
    }).catch(e => {
      toastService.error(e)

      setIsLoading(false);
    });
  }

  const approveData = () => {
    setIsLoading(true)
    const body = { ids: [loanId], state }

    usecase.update(body).then(
      (res) => {
        localNotificationService.openSuccess(`Pengajuan Pinjaman telah ${state ? 'disetujui' : 'ditolak'}`, '', 1)
        setTimeout(() => {
          window.location.href = "/v2/app/hr-loan/approval-loan"
        }, 1500)
      }).catch(err => {
        setIsLoading(false)
        toastService.error(err)
      })
  }

  return (
    <div className="detail-approval-loan">
      <Modal
        cancelText="BATALKAN"
        centered={true}
        visible={visibleWarningModal}
        cancelButtonProps={{ type: 'ghost' }}
        onOk={approveData}
        onCancel={() => setVisibleWarningModal(false)}
      >
        <div style={{
          display: "flex",
          width: '100%',
          flexDirection: "column",
          alignItems: 'center',
        }}>
          <p>
            Apakah anda yakin ingin 
            {state === "approve" ? ' menyetujui ' : ' menolak '} 
            Pengajuan Pinjaman
          </p>
          <a>{detailDataSource?.name}</a>
        </div>
      </Modal>
      <AddSubmissionLoanTemplate
        key="detail-approval-loan"
        breadcrumbConf={breadcrumbConf}
        firstActionButton={firstActionButton}
        secondActionButton={secondActionButton}
        thirdActionButton={thirdActionButton}
        fourthActionButton={{ active: false }}
        dropdownActionButton={{ visible: false }}
        submitButton={submitButton}
        steps={steps}
        activeSteps={detailDataSource?.state}
        detailDataSource={detailDataSource}
        detailHistory={detailInstallment}
        onFinishFailed={null}
        viewMode={true}
        historyId={null}
        is_approval={true}
        isLoading={isLoading}
      />
    </div>
  );
}
