export default abstract class AddLeaveConfigurationUseCase {
    constructor() {}
  
    abstract getJobList(params): Promise<any>;
    abstract getEmployeeList(params): Promise<any>;
    abstract submitLeaveConfiguration(params): Promise<any>;
    abstract getJob(): Promise<any>;
    abstract getEmpl(): Promise<any>;

  }
  