export const ListMySubmissionClaimConstant = {
  TABLE_HEADER: [
    {
      title: 'Nomor Klaim',
      key: 'claim_number',
      dataIndex: 'claim_number',
      width: 180,
      editable: false,
      isCTA: true,
    },
    {
      title: 'NIK',
      key: 'nik',
      dataIndex: 'nik',
      width: 120,
      editable: false,
    },
    {
      title: 'Karyawan',
      key: 'employee',
      dataIndex: 'employee',
      width: 220,
      editable: false,
    },
    {
      title: 'Tanggal Pengajuan',
      key: 'submission_date',
      dataIndex: 'submission_date',
      width: 180,
      editable: false,
    },
    {
      title: 'Tipe Klaim',
      key: 'claim_type',
      dataIndex: 'claim_type',
      width: 280,
      editable: false,
    },
    {
      title: 'Total Pengajuan',
      key: 'submission_total',
      dataIndex: 'submission_total',
      width: 240,
      editable: false,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 140,
      editable: false,
    },
    {
      title: 'Dibuat Oleh',
      key: 'create_name',
      dataIndex: 'create_name',
      editable: false,
      width: 200,
    },
    {
      title: 'Dibuat Pada',
      key: 'create_date',
      dataIndex: 'create_date',
      editable: false,
      width: 160,
      isFormatDate: true,
      sorter: true,
    },
    {
      title: 'Diubah Oleh',
      key: 'write_name',
      dataIndex: 'write_name',
      editable: false,
      width: 200,
    },
    {
      title: 'Diubah Pada',
      key: 'write_date',
      dataIndex: 'write_date',
      editable: false,
      width: 160,
      isFormatDate: true,
      sorter: true,
    },
  ],
  SEARCH_COLUMN_KEY: [
    { key: 'key', componentType: 'search-input' },
    { key: 'claim_number', componentType: 'text' },
    { key: 'submission_date', componentType: 'date' },
  ],
  DEFAULT_EMPTY_DATA_SOURCE: [
    {
      key: 'empty-data',
      claim_number: '',
      nik: '',
      employee: '',
      submission_date: '',
      claim_type: '',
      claim_total: '',
      status: '',
      create_name: '',
      create_date: '',
      write_name: '',
      write_date: '',
    },
  ],
};
