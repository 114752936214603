import getEmployeeMe from '../../../../../@core/interaction/employee/GetEmployeeMe.interaction';
import Constant from '../../../../../config/Constants';
import API from '../../../../../services/API.service';

export default class ListMySubmissionClaimUseCase {
  convertOutputSortToEndPointFormat(tableOutputSort) {
    let result = '';
    const columnKey = tableOutputSort.columnKey;
    const order = tableOutputSort.order;

    if (order) {
      switch (columnKey) {
        default:
          break;
        case 'department_name':
          result += 'nama_departemen|';
          break;
        case 'parent_department':
          result += 'departemen_induk|';
          break;
        case 'create_date':
          result += 'create_date|';
          break;
        case 'write_date':
          result += 'write_date|';
          break;
      }

      switch (order) {
        default:
          break;
        case 'ascend':
          result += 'asc';
          break;
        case 'descend':
          result += 'desc';
          break;
      }
    }

    return result;
  }

  getAll(params) {
    const URL = Constant.URL_BASE_CLAIM + '/v3/request/claim';
    return API.get(URL, { params });
  }

  export(params) {
    const URL = Constant.URL_BASE_CLAIM + '/v3/request/claim/export';
    return API.get(URL, { params });
  }

  update(body) {
    const URL = Constant.URL_BASE_CLAIM + '/v3/request/claim/update';
    return API.post(URL, body);
  }

  active(body) {
    const URL = Constant.URL_BASE_CLAIM + '/v3/request/claim/update-state';
    return API.put(URL, body);
  }

  getEmployeeMe() {
    return getEmployeeMe().then((res: any) => {
      return res.data.content;
    });
  }
}
