import API from '../../../services/API.service';
import Constant from '../../../config/Constants';
import getLeavePlafon from '../../../@core/interaction/leave/GetLeavePlafon.interaction';
import { GetLeavePlafonDTO } from '../../../@core/interface/leave/GetLeavePlafon.interface';
const XLSX = require('xlsx')

class HrLeaveService {
  getList(page, limit, filterLeaveTypeID, filterEmployeeID, plafonType) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/lov/leave-config";
    let params: any = {
      params: {
        page: page,
        limit: limit,
        plafon_type: plafonType,
        leave_type_id: filterLeaveTypeID,
      },
    };
    if (filterEmployeeID) {
      params.params.employee_id = filterEmployeeID;
    }
    return new Promise((resolve, reject) => {
      API.get(URL, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  update(data) {
    const URL = Constant.URL_BASE_PATH + Constant.URL_EMPLOYEE_CONFIG_LINE;
    return new Promise((resolve, reject) => {
      API.put(URL, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getDetail = (id) => {
    const URL = Constant.URL_BASE_PATH + Constant.URL_EMPLOYEE_CONFIG_LINE + `/${id}`;
    return new Promise((resolve, reject) => {
      API.get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getListCorrection(page, limit, filter, sort) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/leave/corrections";
    const params = {
      params: {
        page: page,
        limit: limit,
        items: filter,
        sort: sort,
      },
    };
    return new Promise((resolve, reject) => {
      API.get(URL, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getDetailCorrection = (id) => {
    const URL = Constant.URL_BASE_ATTENDANCE + Constant.URL_LEAVE_CORRECTION + `/${id}/list`;
    return new Promise((resolve, reject) => {
      const params = { limit: 100 };

      API.get(URL, { params })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getListEmployees(page, limit) {
    const URL = Constant.URL_BASE_COMPANY + Constant.URL_EMPLOYEE;
    const params = {
      params: {
        page: page,
        limit: limit,
      },
    };
    return new Promise((resolve, reject) => {
      API.get(URL, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  createCorrection = (data) => {
    const body = {
      leaves: data,
    };
    const URL = Constant.URL_BASE_ATTENDANCE + Constant.URL_LEAVE_CORRECTION;
    return new Promise((resolve, reject) => {
      API.post(URL, body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getListRequest = (page, limit, filterLeaveConfigID, filterEmployeeID) => {
    const URL = Constant.URL_BASE_ATTENDANCE + Constant.URL_LEAVE_REQUEST;
    const params = {
      params: {
        page: page,
        limit: limit,
        employee_id: [filterEmployeeID],
        leave_config_id: [filterLeaveConfigID],
        state: ['draft', 'approve1', 'approve2', 'approved'],
      },
    };
    return new Promise((resolve, reject) => {
      API.get(URL, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  createAndExecuteHref(url) {
    const hyperlinkElement = document.createElement('a');
    hyperlinkElement.href = url;
    hyperlinkElement.setAttribute('download', `FileName.pdf`);

    document.body.appendChild(hyperlinkElement);
    hyperlinkElement.click();
    hyperlinkElement.parentNode.removeChild(hyperlinkElement);
  }

  convertOutputSortToEndPointFormat(tableOutputSort) {
    let result = '';
    const columnKey = tableOutputSort.columnKey;
    const order = tableOutputSort.order;

    if (order) {
      switch (columnKey) {
        default:
          break;
        case 'numberSubmissionLeave':
          result += 'name|';
          break;
        case 'employeeNumber':
          result += 'nik|';
          break;
        case 'employeeName':
          result += 'employee|';
          break;
        case 'submissionDate':
          result += 'date_request|';
          break;
        case 'leaveDuration':
          result += 'leave_duration_int|';
          break;
        case 'leaveType':
          result += 'leave_type_id|';
          break;
        case 'leaveStatus':
          result += 'state|';
          break;
      }

      switch (order) {
        default:
          break;
        case 'ascend':
          result += 'asc';
          break;
        case 'descend':
          result += 'desc';
          break;
      }
    }

    return result;
  }

  convertLeaveTypeIdParam(input, leaveTypes) {
    const result = [];
    if (input) {
      let key: any;
      let val: any;

      for ([key, val] of Object.entries(leaveTypes)) {
        const getLowerCaseOfVal = val.name.toLowerCase();
        if (getLowerCaseOfVal.includes(input)) {
          result.push(key);
        }
      }
    }

    return result;
  }

  downloadFromByteExcel(byte) {
    return new Promise((resolve, reject) => {
      let binaryString = window.atob(byte);
      let binaryLen = binaryString.length;
      let bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
         let ascii = binaryString.charCodeAt(i);
         bytes[i] = ascii;
      }
      let blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const reader = new FileReader();

      reader.onload = function (e) {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];

        const excelData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
        const headers = excelData[0];
        const dataArray = excelData.slice(1);

        const arrayOfObjects = dataArray.map((row) => {
          const obj = {};
          headers.forEach((header, index) => {
            const newKey = header.toLowerCase().replace(/\s+/g, '_');
            obj[newKey] = row[index];
          });
          return obj;
        });

        resolve({
          status: 'success',
          content: {
            excel_arrays: arrayOfObjects,
            error_message: '',
          },
        })
      };

      reader.readAsBinaryString(blob)
    })
  }

  getEmployeeLeavePlafon(params: GetLeavePlafonDTO) {
    return getLeavePlafon(params)
  }
}

export default HrLeaveService;
