import moment from "moment";

export function getRegionListMapper(regionList) {
  const result = [];

  if (regionList.length) {
    regionList.forEach((region) => {
      result.push({
        key: region.id,
        region_name: region.region_name,
        status: region.active ? "Aktif" : "Non-Aktif",
        create_name: region.create_name,
        create_date: region.view_create_date,
        write_name: region.write_name,
        write_date: region.view_write_date
      });
    });
  }

  return result;
}

export function getDetailMasterRegionMapper(regionDetail) {
  return {
    name: regionDetail.region_name,
    is_active: regionDetail.active,
    list_location: []
  }
}

export function getDetailLocationList(locationList) {
  const result = [];

  if (locationList.length) {
    locationList.forEach((location) => {
      result.push({
        key: location.id,
        name: location.name,
      });
    });
  }

  return result;
}

function formatDate(dateString: string) {
  return moment(dateString).format("DD/MM/YYYY HH:mm:ss")
}