import { useEffect, useState } from 'react';
import { Card, Result } from 'antd';
import PageLoader from '../../components/loader/PageLoader';

import EmailVerifyUsecase from './VerifyEmail.usecase';

const usecase = new EmailVerifyUsecase();
export default function EmailVerify({ match }) {
  var Id = match.params.id;
  const [isLoading, setIsLoading] = useState(false);
  const [picName, setPicName] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    verifyEmail();
  }, [Id]);

  async function verifyEmail() {
    setIsLoading(true);
    if (Id) {
      usecase
        .getVerify(Id)
        .then((response: any) => {
          if (response.status === 200) {
            setPicName(response.data.content.pic);
          }
        })
        .catch((error) => {
          setErrorMessage(error?.data?.error?.message);
        });
    }

    setIsLoading(false);
  }

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div>
      <Card>
        {picName ? (
          <Result
            status="success"
            title={
              <pre>
                Halo, {picName} <br />
                Selamat, email anda sudah <b>terverifikasi.</b>
              </pre>
            }
            subTitle={
              <pre>
                Selanjutnya admin kami akan melakukan verifikasi terhadap data pendaftaran
                perusahaan yang sudah anda input di tahapan sebelumnya.
                <br />
                <b>Terima kasih</b>
              </pre>
            }
          />
        ) : (
          <Result status="error" title={errorMessage} />
        )}
      </Card>
    </div>
  );
}
