import getEmployeeMe from "../../../../../@core/interaction/employee/GetEmployeeMe.interaction"
import Constant from "../../../../../config/Constants"
import API from "../../../../../services/API.service"

export class AddMassLeaveUseCase {
  getEmp(){
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/emp"
    return API.get(URL)
  }

  getEmpByListJob(jobList) {  
    const job_id = jobList?.map(e => e.job_id)
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/emp"
    return API.get(URL, { params: { job_id }})
  }

  save(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/leave/all-request"
    return API.post(URL, body)
  }

  getLeaveConfig() {
    const URL = `${Constant.URL_BASE_ATTENDANCE}/v3/configuration/leave`;
    return API.get(URL, { params: { is_active: "aktif" } })
  }

  getLeaveConfigDetail(id) {
    const URL = `${Constant.URL_BASE_ATTENDANCE}/v3/lov/${id}/rel`;
    return API.get(URL)
  }

  getEmployeeMe() {
    return getEmployeeMe().then(
      (res: any) => {
        const data = res.data
        if (data.status === 200) {
          return data.content.id
        }
      }
    ).catch(err => {
      return err
    })
  }

  paramsHelper(Url, params) {
    const url = new URL(Url);
    const queryParams = Object.keys(params).map(key => {
      const values = params[key];
      return values.length ? values.map(value => `${key}=${value}`).join('&') : "";
    }).join('&');

    url.search = queryParams;
    return url.toString()
  }
}