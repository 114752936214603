export const DashboardManagerConstant = {
  TABLE_HEADER_LEAVE: [
    {
      key: 'submission_number',
      title: 'Nomor Pengajuan',
      dataIndex: 'submission_number',
    },
    {
      key: 'submission_type',
      title: 'Jenis Cuti',
      dataIndex: 'submission_type',
    },
    {
      key: 'submission_date',
      title: 'Tanggal Cuti',
      dataIndex: 'submission_date',
    },
    {
      key: 'additional',
      title: 'Durasi',
      dataIndex: 'additional',
    },
  ],
  TABLE_HEADER_CLAIM: [
    {
      key: 'submission_number',
      title: 'Nomor Pengajuan',
      dataIndex: 'submission_number',
    },
    {
      key: 'submission_type',
      title: 'Jenis Klaim',
      dataIndex: 'submission_type',
    },
    {
      key: 'submission_date',
      title: 'Tanggal Klaim',
      dataIndex: 'submission_date',
    },
    {
      key: 'additional',
      title: 'Jumlah Klaim',
      dataIndex: 'additional',
      isCTA: true,
    },
  ],
  TABLE_HEADER_LOAN: [
    {
      key: 'submission_number',
      title: 'Nomor Pengajuan',
      dataIndex: 'submission_number',
    },
    {
      key: 'submission_type',
      title: 'Jenis Pinjaman',
      dataIndex: 'submission_type',
    },
    {
      key: 'submission_date',
      title: 'Tanggal Pinjaman',
      dataIndex: 'submission_date',
    },
    {
      key: 'additional',
      title: 'Jumlah Pinjaman',
      dataIndex: 'additional',
      isCTA: true,
    },
  ],
  TABLE_HEADER_OVERTIME: [
    {
      key: 'submission_number',
      title: 'Waktu Mulai',
      dataIndex: 'submission_number',
    },
    {
      key: 'submission_type',
      title: 'Waktu Berakhir',
      dataIndex: 'submission_type',
    },
    {
      key: 'submission_date',
      title: 'Tanggal Lembur',
      dataIndex: 'submission_date',
    },
  ],
}