import Constant from "../../../../../config/Constants";
import API from "../../../../../services/API.service";

export default class MasterJobListUsecase {

  getJobList(params) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/master-jabatan"
    return API.get(URL, { params })
  }

  export(params) {
    const URL =  Constant.URL_BASE_COMPANY + "/v3/master-jabatan/export"
    return API.get(URL, { params })
  }

  changeStatus(body) {
    const URL = Constant.URL_BASE_COMPANY + `/v3/master-jabatan/active`
    return API.put(URL, body)
  }
}