import { Col, Row, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { fileToFormDataFormat } from './InsertFileTable.service';

export default function InsertFileTable({ value, onChange, disabled }) {
  const onSelectedFile = async (e) => {
    const file = e.target.files[0];
    fileToFormDataFormat(file).then((resolve) => {
      const copyData = [...value.dataSource];
      copyData.splice(copyData.length - 1, 0, resolve);
      onChange({ dataSource: copyData })
    });
  };

  const onClickDelete = (v) => {
    const key = value.dataSource.indexOf(v);
    const copyData = [...value.dataSource];
    copyData.splice(key, 1)
    onChange({ dataSource: copyData })
  }

  return (
    <Table
      columns={[
        {
          dataIndex: 'name',
          title: 'File',
          width: '20%',
          render: (i, row) => {
            return i === 'button' ? (
              <div className="flex-container">
                <Row>
                  <Col flex="100%">
                    <label 
                      style={{ cursor: 'pointer' }} 
                      htmlFor="csv-section-selected-file"
                    >
                      Tambahkan Item
                    </label>
                  </Col>
                </Row>
                <input
                  id="csv-section-selected-file"
                  style={{ display: 'none' }}
                  className="toggle-file"
                  disabled={disabled}
                  type="file"
                  onChange={(e: any) => onSelectedFile(e)}
                  onClick={(e: any) => {
                    e.target.value = null;
                  }}
                />
              </div>
            ) : (
              i
            );
          },
        },
        {
          dataIndex: 'action',
          title: ' ',
          width: '2%',
          render: (i, row) => {
            return row.name !== 'button' ? (
              <DeleteOutlined 
                style={{ fontSize: '24px', cursor: 'pointer', color: 'red' }} 
                onClick={() => onClickDelete(row)}
              />
            ) : (
              null
            );
          },
        },
      ]}
      dataSource={value.dataSource}
      pagination={false}
    />
  );
}
