import Constant from "../../../../../../config/Constants"
import API from "../../../../../../services/API.service"
import createLeaveConfiguration from '../../../../../../@core/interaction/leave/CreateLeaveConfiguration.interaction';


export default class OvertimeReportFilterUseCaseImpl {
  
  getJobList() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/job"
    return API.get(URL).then((res:any) => 
      {
        const result = res.data.content.map(element => {
          return { label: element['job_name'], value: JSON.stringify(element), key: element['id'] }
        })
        return result;
      })
  }
  getEmployeeList() {
    const param: any = {
      page: 1,
      limit: 500
    }
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/emp"
    return API.get(URL, param).then((res: any) => {
      const result = res.data.content.map(element => {
        return { label: element['alternative_name'], value: JSON.stringify(element), key: element['id'] }
      })
      return result;
    })
  }

  submitLeaveConfiguration(params): Promise<any> {
    return createLeaveConfiguration(params);
  }

}
