import { RecurringHolidayFormPropsType } from "../../../component/recurring-holidays-form/RecurringHolidaysForm.interface"
import { ACTION_FORM_TYPE } from "./AddRecurringHolidays.constant"

export const RecurringHolidaysFormReducer = (state :RecurringHolidayFormPropsType, action) => {
  switch (action.type) {
    case ACTION_FORM_TYPE.SET_HOLIDAYS_LENGTH:
      return { 
        ...state,  
        holidayLength: {
          ...state.holidayLength,
          value: action.payload
        }
      }
    default:
      return state
  }
}