import { useEffect, useState } from 'react';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import { Table, Button, Input, Space, Pagination, Menu, Dropdown, DatePicker, Select } from 'antd';
import BottomLoading from '../../components/bottom-loading/BottomLoading';
import { DownOutlined } from '@ant-design/icons';
import { DataItem, EditableListTemplateButton, EditableListTemplateDropdownButton, EditableListTemplatePagination, EditableListTemplatePropsType, SearchColumnKeyType } from './EditableListTemplate.interface';


let output = {
  pagination: null,
  sort: null,
  searchColumn: null,
  selection: null,
  tableAction: null,
};

const moment = require('moment')

export default function EditableListTemplate(props: EditableListTemplatePropsType) {
  let [addButton, setAddButton] = useState<EditableListTemplateButton>();
  let [editableRowKey, setEditableRowKey] = useState('');
  let [exportButton, setExportButton] = useState<EditableListTemplateButton>();
  let [saveButton, setSaveButton] = useState<EditableListTemplateButton>();
  let [deleteButton, setDeleteButton] = useState<EditableListTemplateButton>();
  let [importButton, setImportButton] = useState<EditableListTemplateButton>();
  let [dropdownButton, setDropdownButton] = useState<EditableListTemplateDropdownButton>();
  let [dataSource, setDataSource] = useState([]);
  let [columns, setColumns] = useState<any>();
  let [tableRowSelectionActive, setTableRowSelectionActive] = useState();
  let [selectedRowKeys, setSelectedRowKeys] = useState<any>();
  let [pagination, setPagination] = useState<EditableListTemplatePagination>({
    current: 1,
    pageSize: 5,
    total: 5,
    size: 'small',
  });
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    fillActionButtonState('addButton');
  }, [props.addButton]);
  useEffect(() => {
    fillActionButtonState('exportButton');
  }, [props.exportButton]);
  useEffect(() => {
    fillActionButtonState('deleteButton');
  }, [props.deleteButton]);
  useEffect(() => {
    fillActionButtonState('saveButton');
  }, [props.saveButton]);
  useEffect(() => {
    fillActionButtonState('importButton');
  }, [props.importButton]);
  useEffect(() => {
    if (props.dropdownButton) {
      processDropdownButton();
    }
  }, [props.dropdownButton]);
  useEffect(() => {
    processTableData();
  }, [props.tableData]);
  useEffect(() => {
    processTableHeader();
  }, [props.tableHeader]);
  useEffect(() => {
    processPagination();
  }, [props.tablePagination]);
  useEffect(() => {
    processLoading();
  }, [props.isLoading]);

  const fillActionButtonState = (procedure) => {
    const result: any = { visible: true, disabled: false };

    if (props[procedure]) {
      result.visible = props[procedure].visible === undefined ? true : props[procedure].visible;
      result.disabled = props[procedure].disabled === undefined ? false : props[procedure].disabled;
    }

    switch (procedure) {
      case 'addButton':
        setAddButton(result);
        break;
      case 'exportButton':
        setExportButton(result);
        break;
      case 'deleteButton':
        setDeleteButton(result);
        break;
      case 'saveButton':
        setSaveButton(result);
        break;
      case 'importButton':
        setImportButton(result)
        break;
      default:
        break;
    }
  };

  const processDropdownButton = () => {
    const result: any = { visible: true, disabled: false, options: [] };

    result.visible = props.dropdownButton.visible === undefined ? true : props.dropdownButton.visible;
    result.disabled = props.dropdownButton.disabled === undefined ? false : props.dropdownButton.disabled;
    result.options = props.dropdownButton.options
      ? props.dropdownButton.options
      : [{ label: 'Option 1', value: 'option_1' }];

    setDropdownButton(result)
  }

  const processTableData = () => {
    if (props.tableData && props.tableData.dataSource && props.tableData.dataSource.length) {
      const searchColumnKey: SearchColumnKeyType[] = props.tableData.searchColumnKey
        ? props.tableData.searchColumnKey
        : [];
      const searchColumnResult = JSON.parse(JSON.stringify(props.tableData.dataSource[0]));
      const dataSource = props.tableData.dataSource ? props.tableData.dataSource : [];
      let result = [];

      if (searchColumnKey.length) {
        for (let key in searchColumnResult) {
          searchColumnResult[key] = '';
          searchColumnKey.map(sck => {
            if (sck.key === key) {
              searchColumnResult[key] = sck.componentType
            }
          })
        }

        result.push(searchColumnResult);
      }
      result = result.concat(dataSource);

      setDataSource([...result]);
      processTableRowSelection();
    }
  };

  const processTableHeader = () => {
    const column: any = props.tableHeader ? props.tableHeader : [];
    const result: any = [];

    if (column.length) {
      column.forEach((header) => {
        const temp = header;
        temp.render = (text, record) => {
          switch (text) {
            case 'text':
              return <Input.Search
                allowClear
                onPressEnter={(e: any) => onEnterSearchColumn(e.target.value, header.key)}
                onSearch={(val) => onEnterSearchColumn(val, header.key)}
              />
            case 'date':
              return <DatePicker
                format="YYYY/MM/DD"
                onChange={(_, date) => onEnterSearchColumn(date, header.key)}
              />
            case 'select':
              const options = props.tableData.searchColumnKey.find(sck => {
                return sck.key === header.key
              }).options
              return <Select
                style={{ width: '100%' }}
                options={options}
                onChange={(value) => onEnterSearchColumn(value, header.key)}
              />
            default:
              break;
          }
          if (header.isCTA !== undefined && header.isCTA) {
            return <a href={record.href}>{text}</a>;
          } else {
            return <span>{text}</span>;
          }
        };

        result.push(header);
      });
      setColumns([...result]);
    } else {
      // empty conf for header table
    }
  };

  const processPagination = () => {
    const resultPagination: EditableListTemplatePagination = pagination;

    if (props.tablePagination) {
      const tablePagination = props.tablePagination;
      resultPagination.current = tablePagination.current ? tablePagination.current : 1;
      resultPagination.pageSize = tablePagination.pageSize ? tablePagination.pageSize : 5;
      resultPagination.total = tablePagination.total ? tablePagination.total : 5;
      resultPagination.size = tablePagination.size ? tablePagination.size : 'small';
    }

    setPagination(resultPagination);
  };

  const processLoading = () => {
    const result = props.isLoading !== undefined ? props.isLoading : true;
    setLoading(result);
  };

  const processTableRowSelection = () => {
    const result: any = props.tableData.tableRowSelectionActive
      ? props.tableData.tableRowSelectionActive
      : false;
    setTableRowSelectionActive(result);
  };

  const sendOutputTable = () => {
    if (props.tableOnChange) {
      props.tableOnChange(output);
    }
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys([...selectedRowKeys]);

    output.selection = selectedRowKeys;
    sendOutputTable();
  };

  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
    getCheckboxProps: (record) => {
      return {
        disabled: record.key === 'search-input' || record.key === 'empty-data' || record.key === 'text'
      }
      
    },
  };

  const renderCell = (text: any, record: DataItem, dataIndex: string) => {
    const column = columns.find(col => col.dataIndex === dataIndex);
    const header = column

    if (record.key !== 'search-input') {
      if (header.isFormatDate !== undefined && header.isFormatDate) {
        return text ? moment(text).format('YYYY-MM-DD') : null
      }
      if (record.key && editableRowKey === record.key) {
        if (column && column.editable) {
          switch (column.type) {
            case 'select':
              return (
                <Select
                  style={{ width: "100%" }}
                  key={`${column.dataIndex}${record.key}`}
                  defaultValue={text}
                  onChange={(value) => handleSelectChange(value, dataIndex, record.key)}
                >
                  {column.options.map(option => (
                    <Select.Option key={option.key} value={option.value}>{option.value}</Select.Option>
                  ))}
                </Select>
              )
            case 'date':
              const defaultDate = text ? moment(text, 'YYYY-MM-DD') : null
              return <DatePicker
                key={`${column.dataIndex}${record.key}`}
                defaultValue={defaultDate}
                format="YYYY-MM-DD"
                onChange={(_, date) => handleSelectChange(date, dataIndex, record.key)}
              />
            case 'button':
              return <Button
                type="primary"
                style={{ borderRadius: 10 }}
                onClick={() => onClickTableActionButton(record)}
              >
                {text}
              </Button>
            default:
              break;
          }
        }
      } else {
        switch (column.type) {
          case 'button':
            return <Button
              type="primary"
              style={{ borderRadius: 10 }}
              onClick={() => onClickTableActionButton(record)}
            >
              {text}
            </Button>
          default:
            break;
        }
      }
    } else {
      switch (text) {
        case 'text':
          return <Input.Search
            allowClear
            onPressEnter={(e: any) => onEnterSearchColumn(e.target.value, header.key)}
            onSearch={(val) => onEnterSearchColumn(val, header.key)}
          />
        case 'date':
          return <DatePicker
            format="YYYY/MM/DD"
            onChange={(_, date) => onEnterSearchColumn(date, header.key)}
          />
        case 'select':
          const options = props.tableData.searchColumnKey.find(sck => {
            return sck.key === header.key
          }).options
          return <Select
            style={{ width: '100%' }}
            options={options}
            onChange={(value) => onEnterSearchColumn(value, header.key)}
          />
        default:
          break;
      }

      if (header.isCTA !== undefined && header.isCTA) {
        return <a href={record.href}>{text}</a>;
      } else {
        return <span>{text}</span>;
      }
    }

    return text;
  };

  const handleSelectChange = (value: any, field: string, key: string) => {
    if (props.onInputRowChange) {
      props.onInputRowChange(key, value, field)
    }
  };

  const onClickActionButton = (procedure) => {
    if (props[procedure] && props[procedure].onClick) {
      props[procedure].onClick();
    }
  };

  const onEnterSearchColumn = (value, columnName) => {
    if (!output.searchColumn) {
      output.searchColumn = {};
    }
    output.searchColumn[columnName] = value;

    sendOutputTable();
  };

  const onClickTableActionButton = (record) => {
    output.tableAction = record;
    sendOutputTable();
  };

  const tableOnChange = (pagination, filters, sort, extra) => {
    output.sort = {
      columnKey: sort.columnKey,
      field: sort.field,
      order: sort.order,
    };
    sendOutputTable();
  };

  const paginationOnChange = (page, pageSize) => {
    output.pagination = { page, pageSize };
    sendOutputTable();
  };

  const AddButton = () => {
    return addButton && addButton.visible ? (
      <Button
        type="primary"
        disabled={addButton.disabled}
        onClick={() => onClickActionButton('addButton')}
      >
        Tambah
      </Button>
    ) : null;
  };

  const ExportButton = () => {
    return exportButton && exportButton.visible ? (
      <Button
        type="primary"
        disabled={exportButton.disabled}
        onClick={() => onClickActionButton('exportButton')}
      >
        Export
      </Button>
    ) : null;
  };

  const DeleteButton = () => {
    return deleteButton && deleteButton.visible ? (
      <Button
        type="primary"
        disabled={deleteButton.disabled}
        onClick={() => onClickActionButton('deleteButton')}
      >
        Delete
      </Button>
    ) : null;
  };

  const SaveButton = () => {
    return saveButton && saveButton.visible ? (
      <Button
        type="primary"
        disabled={saveButton.disabled}
        onClick={() => {
          setEditableRowKey('')
          onClickActionButton('saveButton')
        }}
      >
        Simpan
      </Button>
    ) : null
  };

  const ImportButton = () => {
    return importButton && importButton.visible ? (
      <Button
        type="primary"
        disabled={importButton.disabled}
        onClick={() => onClickActionButton('importButton')}
      >
        Import
      </Button>
    ) : null
  };

  const DropdownButton = () => {
    const menu = dropdownButton && dropdownButton.visible ? (
      <Menu>
        {dropdownButton.options.map((opt) => (
          <Menu.Item
            disabled={opt?.disabled}
            onClick={() => opt.onClick()}
            key={opt.key}
          >
            {opt.label}
          </Menu.Item>
        ))}
      </Menu>
    ) : null

    return dropdownButton && dropdownButton.visible ? (
      <Dropdown
        disabled={dropdownButton.disabled}
        overlay={menu}
      >
        <Button
          type='primary'
        >
          Tindakan <DownOutlined />
        </Button>
      </Dropdown>
    ) : null
  };

  const onRow = (record: DataItem) => {
    return {
      onClick: () => {
        setEditableRowKey(record.key);
      },
    };
  };

  const updatedColumns = columns && columns.map(column => {
    return {
      ...column,
      render: (text: any, record: DataItem) => renderCell(text, record, column.dataIndex),
    }
  });

  return (
    <div id="editable-list-template">
      {props.breadcrumbConf && <Breadcrumb breadcrumbConf={props.breadcrumbConf} />}

      <div className="toolbar">
        <Space>
          <AddButton />
          <ExportButton />
          <DeleteButton />
          <SaveButton />
          <ImportButton />
        </Space>

        <DropdownButton />

        <Pagination
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={pagination.total}
          size={pagination.size}
          showSizeChanger={false}
          onChange={paginationOnChange}
        />
      </div>

      <Table
        className="no-expand-icon-table"
        dataSource={dataSource}
        pagination={false}
        rowSelection={tableRowSelectionActive ? rowSelection : null}
        onChange={tableOnChange}
        scroll={props.tableScroll ? props.tableScroll : false}
        onRow={onRow}
        columns={updatedColumns}
      />

      <BottomLoading active={loading} />
    </div>
  );
}
