import moment from "moment";

export function getListUserMapper(listUser: any[]) {
  let result = [];
  listUser.map((item) => {
    const temp = {
      key: item.id,
      user_name: item.user_name,
      employee_name: item.employee_name || '-',
      job_name: item.job_name || '-',
      user_type: item.user_type,
      timezone: item.tz,
      status: item.active ? 'Aktif' : 'Tidak Aktif',
      is_locked: item.locked ? 'Ya' : 'Tidak',
      hak_akses: item.hak_akses,
      create_name: item.create_name || '-',
      create_date: moment(item.view_create_date).format('DD-MM-YYYY HH:mm'),
      write_name: item.write_name || '-',
      write_date: moment(item.view_write_date).format('DD-MM-YYYY HH:mm'),
    }

    result.push(temp)
  })

  return result
}
