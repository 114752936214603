import Constant from "../../../../../../config/Constants";
import API from "../../../../../../services/API.service";

export default class MasterHolidayListUseCase {
  convertOutputSortToEndPointFormat(tableOutputSort) {
    let result = '';
    const columnKey = tableOutputSort.columnKey;
    const order = tableOutputSort.order;

    if (order) {
      switch (columnKey) {
        default:
          break;
        case 'holiday_name':
          result += 'name|';
          break;
        case 'effective_date':
          result += 'date_start|';
          break;
        case 'end_date':
          result += 'date_stop|';
          break;
        case 'create_date':
          result += 'create_date|';
          break;
        case 'write_date':
          result += 'write_date|';
          break;
      }

      switch (order) {
        default:
          break;
        case 'ascend':
          result += 'asc';
          break;
        case 'descend':
          result += 'desc';
          break;
      }
    }

    return result;
  }

  getAll(params) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/configuration/holiday/master"
    return API.get(URL, { params })
  }

  active(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/configuration/holiday/master/active"
    return API.put(URL, body)
  }

  export(params) {
    const URL =  Constant.URL_BASE_ATTENDANCE + "/v3/configuration/holiday/master/export"
    return API.get(URL, { params })
  }

  getDetail(id) {
    const URL = Constant.URL_BASE_ATTENDANCE + `/v3/configuration/holiday/master/${id}`
    return API.get(URL)
  }

  save(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/configuration/holiday/master"
    return API.post(URL, body)
  }

  update(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + `/v3/configuration/holiday/master`
    return API.put(URL, body)
  }
}