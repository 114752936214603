import { Button, Col, Form, Input, Radio, Select } from 'antd';
import { Option } from 'antd/lib/mentions';

export const SearchByJobOrEmployee = ({ value, onChange, listJob, onClick, disabled }) => {
  return (
    <Col span={24}>
      <Radio.Group
        style={{ width: '100%' }}
        value={value.is_select_job}
        onChange={(v) => onChange({ ...value, is_select_job: v.target.value })}
      >
        <Radio
          value={true}
          style={{
            width: '100%',
            marginBottom: '15px',
          }}
        >
          <div style={{ fontWeight: '600' }}>Cari Berdasarkan Jabatan</div>
        </Radio>

        {value.is_select_job ? (
          <Form.Item
            key={'job'}
            label={'Jabatan'}
            name={'job'}
            labelAlign="left"
            labelCol={{ span: 6 }}
            colon={false}
            style={{ marginLeft: '100px' }}
          >
            <Select
              style={{ width: '96%' }}
              showSearch={true}
              value={value.selected_job}
              mode="multiple"
              onChange={(v) => onChange({ ...value, selected_job: v })}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {[...listJob].map((option) => (
                <Option value={option.value} key={option.id}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}

        <Radio
          value={false}
          style={{
            width: '100%',
            marginBottom: '15px',
          }}
        >
          <div style={{ fontWeight: '600' }}>Cari Berdasarkan Karyawan</div>
        </Radio>

        {!value.is_select_job ? (
          <Form.Item
            key={'employee'}
            label={'NIK / Karyawan'}
            name={'employee'}
            labelAlign="left"
            labelCol={{ span: 6 }}
            colon={false}
            style={{ marginLeft: '100px' }}
          >
            <Input
              value={value.employee}
              onChange={(v) => onChange({ ...value, employee: v.target.value })}
            />
          </Form.Item>
        ) : null}
      </Radio.Group>

      <Button
        type="primary"
        disabled={disabled}
        onClick={() => onClick(value)}
        style={{ marginTop: '15px' }}
      >
        Cari
      </Button>
    </Col>
  );
};
