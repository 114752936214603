import SubmissionLeaveUseCase from '../../../../../../@core/use-case/leave/SubmissionLeave.usecase';
import getLeaveTypes from '../../../../../../@core/interaction/leave/GetLeaveTypes.interaction';
import { leaveTypeToObjById } from '../../../../../../@core/mapper/leave/GetLeaveTypes.mapper';
import { leaveListMapper } from '../../../../../../@core/mapper/leave/LeaveRequestList.mapper';
import exportSubmissionLeaves from '../../../../../../@core/interaction/leave/ExportSubmissionLeaves.interaction';
import getSubmissionLeaves from '../../../../../../@core/interaction/leave/GetSubmissionLeaves.interaction';
import { LeaveId } from '../../../../../../@core/entity/Leave.entity';
import { UpdateLeaveStateDTO } from '../../../../../../@core/interface/leave/UpdateLeaveState.interface';
import updateLeaveState from '../../../../../../@core/interaction/leave/UpdateLeaveState.interaction';

export default class EmployeeSubmissionLeaveUseCaseImpl extends SubmissionLeaveUseCase {
  getLeaveTypes() {
    return getLeaveTypes().then((res: any) => leaveTypeToObjById(res.content));
  }

  getSubmissionLeaves(params, leaveTypeObj, hrefPath) {
    return getSubmissionLeaves(params).then((res: any) =>
      leaveListMapper(res.data, leaveTypeObj, hrefPath),
    );
  }

  cancelLeave(leaveId: LeaveId[]) {
    const params: UpdateLeaveStateDTO = {
      leaveId,
      leaveState: 'cancel',
    };
    return updateLeaveState(params);
  }

  exportSubmissionLeaves(params) {
    return exportSubmissionLeaves(params);
  }
}
