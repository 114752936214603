
  
  export const OvertimeConstant = {
    TABLE_HEADER: [
      {
        title: 'NIK',
        key: 'nik',
        dataIndex: 'nik',
        sorter: true,
        width: 150,
      },
      { title: 'Nama Karyawan', key: 'emp_name', dataIndex: 'emp_name', width: 150 },
      { title: 'Jabatan', key: 'job', dataIndex: 'job', width: 150 },
      {
        title: 'Tanggal Lembur',
        key: 'date_start',
        dataIndex: 'date_start',
        width: 120
      },
      {
        title: 'Durasi',
        key: 'duration',
        dataIndex: 'duration',
        width: 120
      },
      { title: 'Total Upah', key: 'price_total', dataIndex: 'price_total', width: 150 },
      { title: 'Alasan Lembur', key: 'reason', dataIndex: 'reason', width: 150 },
      { title: 'Persetujuan 1', key: 'approval_1', dataIndex: 'approval_1',  width: 130 },
      { title: 'Tanggal 1', key: 'date_approved_1', dataIndex: 'date_approved_1',  width: 130 },
      { title: 'Persetujuan 2', key: 'approval_2', dataIndex: 'approval_2',  width: 130 },
      { title: 'Tanggal 2', key: 'date_approved_2', dataIndex: 'date_approved_2',  width: 130 },
      { title: 'Status', key: 'status', dataIndex: 'status', width: 120 },
    ],
    SEARCH_COLUMN_KEY: [],
    SEARCH_COLUMN_COMPONENT: [],
    DEFAULT_EMPTY_DATA_SOURCE: [
      {
        key: 'empty-data',
      },
    ],
  };

  export const OvertimeConfigConstant = {
    TABLE_HEADER: [
      {
        title: 'Nama Lembur',
        key: 'name',
        dataIndex: 'name',
        sorter: true,
        isCTA: true,
        width: 150,
      },
      { title: 'Kompensasi', key: 'ovt_type', dataIndex: 'ovt_type', width: 150 },
      { title: 'Status', key: 'status', dataIndex: 'status', width: 150 },
      {
        title: 'Berlaku Untuk',
        key: 'ovt_type_rel',
        dataIndex: 'ovt_type_rel',
        width: 120
      },
      { title: 'Dibuat Oleh', key: 'create_name', dataIndex: 'create_name', width: 120 },
      { title: 'Dibuat Pada', key: 'create_date', dataIndex: 'create_date', width: 120 },
      { title: 'Diubah Oleh', key: 'write_name', dataIndex: 'write_name', width: 120 },
      { title: 'Diubah Pada', key: 'write_date', dataIndex: 'write_date', width: 120 },
    ],
    SEARCH_COLUMN_KEY: [],
    SEARCH_COLUMN_COMPONENT: [
      { key: 'key', componentType: "text" },
      { key: 'name', componentType: "text" },
      { key: 'ovt_type', componentType: "select", options: [
        { label: 'Semua', value: '' },
        { label: 'Default', value: 'default' },
        { label: 'Nominal tetap', value: 'normal' },
      ]},
      { key: 'status', componentType: "select", options: [
        { label: 'Semua', value: '' },
        { label: 'Aktif', value: 'Aktif' },
        { label: 'Tidak Aktif', value: 'Tidak Aktif' },
      ]},
      { key: 'ovt_type_rel', componentType: "select", options: [
        { label: 'Semua', value: '' },
        { label: 'Karyawan', value: 'employee' },
        { label: 'Jabatan', value: 'job' },
      ]},
    ],
    DEFAULT_EMPTY_DATA_SOURCE: [
      {
        key: 'text',
        name: '',
        ovt_type: '',
        status: '',
        ovt_type_rel: '',
      },
    ],
  };
  