import { Button, Col, Form, Row, Table, Tabs } from 'antd';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import { useForm } from 'antd/lib/form/Form';
import FormBuilder from 'antd-form-builder';
import BottomLoading from '../../../../../components/bottom-loading/BottomLoading';
import { AddSubmissionLoanTemplatePropsType } from './AddSubmissionLoanTemplate.interface';
import ToolbarButtonDetailTemplate from '../../../employee/components/toolbar-button-detail-template/ToolbarButtonDetailTemplate';
import { MemoizeHistoryTabs } from '../../../employee/components/history-payroll-period-tabs/HistoryPayrollPeriodTabs';
import { useEffect, useState } from 'react';
import ModalSimulation from './component/ModalSimulation';
import AddSubmissionLoanTemplateUsecase from './AddSubmisiionLoanTemplate.usecase';
import DynamicStepsDetail from '../../../overtime/templates/submission-overtime-template/components/dynamic-steps/DynamicStepsDetail';
import ApprovalTableTabs from './component/ApprovalTableTabs';
import { IDRFormat } from '../../../../../@core/interaction/currency/IDRFormat';
import { ReadOnlyNumber, ReadOnlyString } from './component/ReadOnlyFields';

const moment = require("moment")
const usecase = new AddSubmissionLoanTemplateUsecase()

const columns = [
  { key: 'alternative_name', dataIndex: 'alternative_name', title: 'Nama' },
  { key: 'job_name', dataIndex: 'job_name', title: 'Jabatan' },
];

export default function AddSubmissionLoanTemplate(props: AddSubmissionLoanTemplatePropsType) {
  const [form] = useForm();
  const [dataSource, setDataSource] = useState([])
  const [disabledProposed, setDisabledProposed] = useState(true)
  const [visibleSimulationModal, setVisibleSimulationModal] = useState(false);
  const [disabledSimulationButton, setDisabledSimulationButton] = useState(false);
  const [dataFormTable, setDataFormTable] = useState({ proposed_loan: "", proposed_tenor: "", loan_interest: "", loan_rule: ''})
  
  useEffect(() => {
    form.setFieldsValue({ employee: props.empoyeeName })
  }, [props.empoyeeName])

  useEffect(() => {
    form.setFieldsValue({ ...props.detailDataSource })
  }, [props.detailDataSource])

  useEffect(() => {
    setDataSource(props.detailInstallment)
  }, [props.detailInstallment])

  const meta = {
    columns: 4,
    colon: false,
    fields: [
      {
        key: 'employee',
        label: 'Karyawan',
        colSpan: 4,
        viewMode: false,
        widget: ReadOnlyString,
        initialValue: props.empoyeeName || '',
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'submission_date',
        label: 'Tanggal Pengajuan',
        readOnly: true,
        colSpan: 4,
        initialValue: initialValueSubmission(),
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'loan_type',
        label: 'Tipe Pinjaman',
        colSpan: 4,
        disabled: props.isLoading,
        viewMode: props.viewMode,
        widget: 'select',
        options: props.listConfigLoan,
        required: true,
        initialValue: props.detailDataSource?.loan_type,
        message: 'Tipe Pinjaman harus diisi.',
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'max_plafon',
        label: 'Maksimum Plafon',
        colSpan: 4,
        viewMode: false,
        initialValue: props.detailDataSource?.max_plafon,
        widget: ReadOnlyNumber,
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'percent_interest',
        label: '% Bunga',
        colSpan: 4,
        viewMode: false,
        initialValue: props.detailDataSource?.percent_interest,
        widget: ReadOnlyString,
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'max_tenor',
        label: 'Maksimum Tenor',
        colSpan: 4,
        viewMode: false,
        initialValue: props.detailDataSource?.max_tenor,
        widget: ReadOnlyString,
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'proposed_loan',
        label: 'Pinjaman yang diajukan',
        colSpan: 4,
        widget: 'number',
        disabled: props.isLoading || disabledProposed,
        viewMode: props.viewMode,
        required: true,
        initialValue: props.detailDataSource?.proposed_loan,
        message: ' ',
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
        widgetProps: {
          style: { width: "100%" },
          onBlur: () => getSimulationData(),
          formatter: value => `${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ','),
        },
        rules: [
          {
            validator: (rule, value, callback) => {
              return new Promise((resolve, reject) => {
                if (value) {
                  const max_plafon = form.getFieldValue('max_plafon')
                  if (max_plafon) {
                    if (value > max_plafon) {
                      reject("Pinjaman yang diajukan melebihi Maksimum plafon.")
                    }
                  } else {
                    reject("Silahkan pilih Tipe Pinjaman terlebih dahulu.")
                  }

                  resolve("")
                } else {
                  reject('Pinjaman yang diajukan harus diisi.')
                }
              })
            },
          },
        ],
      },
      {
        key: 'proposed_tenor',
        label: 'Tenor yang diajukan',
        colSpan: 4,
        disabled: props.isLoading || disabledProposed,
        viewMode: props.viewMode,
        required: true,
        widget: 'number',
        initialValue: props.detailDataSource?.proposed_tenor,
        message: ' ',
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
        widgetProps: {
          onBlur: () => getSimulationData(),
          min: 0,
        },
        rules: [
          {
            validator: (rule, value, callback) => {
              return new Promise((resolve, reject) => {
                if (value) {
                  const max_tenor = parseInt(form.getFieldValue('max_tenor')?.split(" ")[0])
                  if (max_tenor) {
                    if (value > max_tenor) {
                      reject("Tenor yang diajukan melebihi Maksimum plafon.")
                    }
                  } else {
                    reject("Silahkan pilih Tipe Pinjaman terlebih dahulu.")
                  }

                  resolve("")
                } else {
                  reject('Tenor yang diajukan harus diisi.')
                }
              })
            },
          },
        ],
      },
      {
        key: 'estimation',
        label: 'Estimasi angsuran',
        colSpan: 4,
        viewMode: false,
        initialValue: props.detailDataSource?.estimation,
        widget: ReadOnlyNumber,
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'detail',
        label: 'Keterangan',
        colSpan: 4,
        disabled: props.isLoading,
        viewMode: props.viewMode,
        widget: 'textarea',
        required: true,
        initialValue: props.detailDataSource?.detail,
        message: 'Keterangan harus diisi.',
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
    ],
  };

  function processLoanType(value) {
    const {
      loan_plafon,
      loan_interest,
      max_installment,
      loan_rule,
    } = props.listConfigLoan.filter(cfg => cfg.key === value)[0];
    
    form.setFieldsValue({
      max_plafon: loan_plafon,
      percent_interest: loan_rule === 'tanpa_bunga' ? "Tanpa Bunga" : `${loan_interest} % (${loan_rule})`,
      max_tenor: max_installment + " Bulan",
    })

    setDisabledProposed(false)
  }

  function processProposed() {
    const { 
      proposed_loan, 
      proposed_tenor,
    } = form.getFieldsValue(["proposed_loan", "proposed_tenor"]);

    const error_loan = form.getFieldError('proposed_loan').length;
    const error_tenor = form.getFieldError('proposed_tenor').length;
    
    setDisabledSimulationButton(proposed_loan && proposed_tenor && !error_loan && !error_tenor);
  }

  function getSimulationData() {
    const { 
      proposed_loan, 
      proposed_tenor,
      loan_type,
    } = form.getFieldsValue(["proposed_loan", "proposed_tenor", "loan_type"]);

    const {
      loan_interest,
      loan_rule,
    } = props.listConfigLoan.filter(e => e.key === loan_type)[0]

    const params = {
      loan_value_requested: proposed_loan,
      term_of_installment: proposed_tenor,
      loan_interest,
      loan_rule
    }

    const error_loan = form.getFieldError('proposed_loan').length;
    const error_tenor = form.getFieldError('proposed_tenor').length;
    
    if(!error_loan && !error_tenor && proposed_loan && proposed_tenor) {
      usecase.getSimulation(params).then(
        (res) => {
          const content = res.data.content
          const estimation = content[0]?.total_cicilan
          
          form.setFieldsValue({ estimation })
          setDataSource([...content])
          setDataFormTable({ proposed_loan, proposed_tenor, loan_interest, loan_rule })
        }
      )
    }
  }
  const onFinish = (values) => {
    if (props.onFinish) {
      props.onFinish(form.getFieldsValue());
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (props.onFinishFailed) {
      props.onFinishFailed(form.getFieldsValue());
    }
  };

  const onFieldsChange = (e) => {
    if (e.length === 1) {
      const fieldName = e[0].name[0];
      const value = e[0].value;

      switch (fieldName) {
        default:
          break;
        case 'loan_type':
          processLoanType(value);
          break;
        case 'proposed_loan':
          processProposed();
          break;
        case 'proposed_tenor':
          processProposed();
          break;
      }
    }
  };

  const onCancel = () => {
    setVisibleSimulationModal(false);
  };

  const onShowModal = () => {
    setVisibleSimulationModal(true);
  };

  function initialValueSubmission(){
    if (!props.viewMode) {
      return moment().format("YYYY-MM-DD")
    }

    return props.detailDataSource?.submission_date
  }

  const ApprovalTab = ({ dataSource }) => {
    return <Table columns={columns} dataSource={dataSource} pagination={false} />;
  };

  return (
    <div className="add-submission-loan-template">
      <Breadcrumb breadcrumbConf={props.breadcrumbConf} />

      <ModalSimulation
        visible={visibleSimulationModal}
        onCancel={onCancel}
        isLoading={false}
        dataSource={dataSource}
        dataFormTable={dataFormTable}
        rule={form.getFieldValue('percent_interest')}
      />

      <Form
        form={form}
        name="basic"
        style={{
          width: '100%',
          height: 'calc(100vh - 130px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
        layout="horizontal"
        labelAlign="left"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={onFieldsChange}
      >
        <Row justify="space-between" align="middle">
          <ToolbarButtonDetailTemplate
            submitButton={{
              ...props.submitButton,
              disabled: props.isLoading,
            }}
            firstActionButton={{ ...props.firstActionButton, disabled: props.isLoading }}
            secondActionButton={props.secondActionButton}
            thirdActionButton={props.thirdActionButton}
            fourthActionButton={props.fourthActionButton}
            dropdownActionButton={props.dropdownActionButton}
          />

          <DynamicStepsDetail activeStep={props.activeSteps} steps={props.steps} />
        </Row>

        <FormBuilder form={form} meta={meta} />

        {props.historyId ? (
          <div className="tabs-wrapper">
            <Tabs defaultActiveKey="1" type="card">
              <Tabs.TabPane tab="Detail Angsuran" key="1">
                <Row justify="center">
                  <Col span={18}>
                    <Table
                      bordered={true}
                      pagination={false}
                      dataSource={dataSource}
                      columns={[
                        { key: 'month_to', title: 'Bulan ke-', dataIndex: 'month_to', width: 80, align: "center" as const },
                        { key: 'pokok', title: 'Pokok', dataIndex: 'pokok', width: 150, render: t => IDRFormat(t), align: "center" as const },
                        { key: 'bunga', title: 'Bunga', dataIndex: 'bunga', width: 150, render: t => IDRFormat(t), align: "center" as const },
                        {
                          key: 'total_cicilan',
                          title: 'Total Angsuran',
                          dataIndex: 'total_cicilan',
                          width: 150, 
                          render: t => IDRFormat(t),
                          align: "center" as const 
                        },
                        {
                          key: 'status_pembayaran',
                          title: 'Status Pembayaran',
                          dataIndex: 'status_pembayaran',
                          width: 180,
                          align: "center" as const 
                        },
                        {
                          key: 'no_pembayaran',
                          title: 'No Pembayaran',
                          dataIndex: 'no_pembayaran',
                          width: 180,
                          render: t => <a>{t}</a>,
                          align: "center" as const 
                        },
                      ]}
                      summary={(pageData) => {
                        let total_pokok = 0;
                        let total_bunga = 0;
                        let total_cicil = 0;
                        let est = 0

                        pageData.forEach(({ bunga, total_cicilan, pokok }) => {
                          est = total_cicilan
                          total_pokok += pokok
                          total_bunga += bunga;
                          total_cicil += total_cicilan;
                        });

                        form.setFieldsValue({ estimation: est })

                        return (
                          <>
                            <Table.Summary.Row style={{ fontWeight: 'bold' }}>
                              <Table.Summary.Cell align="center" index={1}>Total</Table.Summary.Cell>
                              <Table.Summary.Cell align="center" index={2}>{IDRFormat(total_pokok)}</Table.Summary.Cell>
                              <Table.Summary.Cell align="center" index={3}>{IDRFormat(total_bunga)}</Table.Summary.Cell>
                              <Table.Summary.Cell align="center" index={4}>{IDRFormat(total_cicil)}</Table.Summary.Cell>
                            </Table.Summary.Row>
                          </>
                        );
                      }}
                    />
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Persetujuan" key="2">
                <Row>
                  <Col span={4}>
                    <label className="label-table">Persetujuan Satu</label>
                  </Col>
                  <Col span={7}>
                    <ApprovalTab dataSource={props.firstApprovalData} />
                  </Col>
                  <Col span={4}>
                    <label className="label-table">Persetujuan Dua</label>
                  </Col>
                  <Col span={7}>
                    <ApprovalTab dataSource={props.secondApprovalData} />
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane tab="History" key="3">
                <MemoizeHistoryTabs key="history-master" history_id={props.historyId} />
              </Tabs.TabPane>
            </Tabs>
          </div>
        ) : !props.is_approval ? (
          <Button
            key="show-simulation-button"
            disabled={!disabledSimulationButton}
            type="primary"
            onClick={onShowModal}
          >
            LIHAT SIMULASI
          </Button>
        ) : (
          <Tabs defaultActiveKey="1" type="card">
            <Tabs.TabPane tab="Pinjaman Lain" key="1">
              <ApprovalTableTabs dataSource={props.detailHistory} form={form} />
            </Tabs.TabPane>
          </Tabs>
        )}
      </Form>

      <BottomLoading active={props.isLoading} />
    </div>
  );
}
