export const MasterDepartmentConstant = {
  TABLE_HEADER: [
    {
      title: 'Nama Departemen',
      key: 'title',
      dataIndex: 'title',
      width: 240,
      editable: false,
    },
    {
      title: 'Departemen Induk',
      key: 'department_parent',
      dataIndex: 'department_parent',
      editable: true,
      width: 180,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      editable: true,
      width: 180,
    },
    { title: 'Dibuat Oleh', key: 'create_name', dataIndex: 'create_name', editable: false, width: 200 },
    { title: 'Dibuat Pada', key: 'create_date', dataIndex: 'create_date', editable: false, width: 160, isFormatDate: true, sorter: true },
    { title: 'Diubah Oleh', key: 'write_name', dataIndex: 'write_name', editable: false, width: 200 },
    { title: 'Diubah Pada', key: 'write_date', dataIndex: 'write_date', editable: false, width: 160, isFormatDate: true, sorter: true },
  ],
  SEARCH_COLUMN_KEY: [
    { key: 'key', componentType: "search-input" },
    { key: 'title', componentType: "text" },
    { key: 'department_parent', componentType: "text" },
  ],
  DEFAULT_EMPTY_DATA_SOURCE: [
    {
      key: 'search-input',
      title: '',
      department_parent: '',
      create_name: '',
      create_date: '',
      write_name: '',
      write_date: '',
    },
  ],
}