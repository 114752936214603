import Moment from 'moment';
import { getLeaveStatus } from './LeaveState.mapper';
import { GetLeavesResp } from '../../interface/leave/GetLeaves.interface';
import { LeaveItem, SubmissionLeaveList } from '../../entity/SubmissionLeaveList.entity';
import { LeaveTypeObjById } from '../../entity/LeaveType.entity';

export function leaveListMapper(
  getMyLeavesResp: GetLeavesResp,
  leaveTypeObj: LeaveTypeObjById,
  hrefPath: string,
): SubmissionLeaveList {
  const content = getMyLeavesResp.content ? getMyLeavesResp.content : null;
  let contentMap: LeaveItem[] = null;

  if (content) {
    contentMap = content.map((data) => {
      const leaveItem: LeaveItem = {
        key: data.id,
        employeeName: data.employee,
        employeeNumber: data.nik,
        href: `${hrefPath}${data.id}`,
        leaveDuration: `${data.leave_duration_int} Hari`,
        leaveStatus: getLeaveStatus(data.state),
        leaveConfig: data.leave_config_name ? data.leave_config_name : '-',
        numberSubmissionLeave: data.name,
        submissionDate: Moment(data.date_request).format('DD/MM/YYYY'),
        isLeaveAll: renderAllLeave(data.leave_all_request_id)
      };

      return leaveItem;
    });
  }

  return {
    content: contentMap,
    metaData: getMyLeavesResp.metadata,
  };
}

function renderAllLeave(leaveAllRequest: any) :string {
  if (!leaveAllRequest) {
    return "Tidak"
  }

  return "Ya"
}