export const NewsConstant = {
    TABLE_HEADER: [
      {
        title: 'Nama Berita',
        key: 'name',
        dataIndex: 'name',
        width: 150,
      },
      {
        title: 'Tanggal Kirim',
        key: 'send_date',
        dataIndex: 'send_date',
        width: 150,
      },
      {
        title: 'Tanggal Kadaluarsa',
        key: 'expired_date',
        dataIndex: 'expired_date',
        width: 150,
      },
      {
        title: 'Penerima',
        key: 'receiver',
        dataIndex: 'receiver',
        width: 120 
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        width: 170 
      },
      { title: 'Dibuat Oleh', key: 'create_name', dataIndex: 'create_name', width: 150 },
      { title: 'Dibuat Pada', key: 'create_date', dataIndex: 'create_date', width: 150, sorter: true, },
      { title: 'Diubah Oleh', key: 'write_name', dataIndex: 'write_name', width: 150 },
      { title: 'Diubah Pada', key: 'write_date', dataIndex: 'write_date', width: 150, sorter: true, },
    ],
    SEARCH_COLUMN_KEY: [],
    SEARCH_COLUMN_COMPONENT: [
      { key: 'key', componentType: "text" },
      { key: 'name', componentType: "text" },
      { key: 'status', componentType: 'select', options: [
        { label: 'Semua', value: '' },
        { label: 'Akan Tayang', value: 'akan_tayang' },
        { label: 'Sedang Tayang', value: 'sedang_tayang' },
        { label: 'Kadaluarsa', value: 'kadaluarsa' },
        { label: 'Non Aktif', value: 'tidak_aktif' },
      ]},
    ],
    DEFAULT_EMPTY_DATA_SOURCE: [
      {
        key: 'empty-data',
        name: '',
        send_date: '',
        expired_date: '',
        receiver: '',
        status: '',
      },
    ],
  
  }