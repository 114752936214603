import Constant from '../../../config/Constants';
import API from "../../../services/API.service"

export default class JoinWithUsUsecase {
  getProvince(params) {
    const URL = Constant.URL_BASE_REGISTER + '/v3/lov/country-state'
    return API.get(URL, { params })
  }
  getCities(params) {
    const URL = Constant.URL_BASE_REGISTER + '/v3/lov/kab-kota'
    return API.get(URL, { params })
  }
  
  getDistrict(params) {
    const URL = Constant.URL_BASE_REGISTER + '/v3/lov/kecamatan'
    return API.get(URL, { params })
  }

  getSubdistrict(params) {
    const URL = Constant.URL_BASE_REGISTER + '/v3/lov/desa'
    return API.get(URL, { params })
  }

  getCompanyType() {
    const URL = Constant.URL_BASE_REGISTER + `/v3/lov/comp-type`;
    return API.get(URL)
  }

  getCompanyCategory() {
    const URL = Constant.URL_BASE_REGISTER + `/v3/lov/comp-cat`;
    return API.get(URL)
  }

  getSumEmployeeOption() {
    const URL = Constant.URL_BASE_REGISTER + `/v3/lov/sum-employee`;
    return API.get(URL)
  }

  submitCompanyData(body): Promise<any> {
    const URL = Constant.URL_BASE_REGISTER + `/v3/register`;
    return API.post(URL, body);
  }
  
}