import moment from "moment";
import { MasterHolidayCalendar, MasterHolidayList } from "./MasterHolidayMapper.interface";
import { FormatDateWriteDate } from "../leave/MassLeave.mapper";

export function MapperMasterHolidayCalendar(
  listHolidays: any[]
): MasterHolidayCalendar[] {
  let result: MasterHolidayCalendar[] = []
  listHolidays.map((holiday) => {
    const res: MasterHolidayCalendar = {
      key: holiday.id,
      color: 'red',
      startDate: holiday.date_start,
      endDate: holiday.date_stop,
      name: holiday.name,
      yearly: holiday.recurring,
      status: true,
    }

    result.push(res)
  })

  return result
}

export function MapperMasterHolidayList(
  listHolidays: any[]
): MasterHolidayList[] {
  let result: MasterHolidayList[] = []

  listHolidays.forEach((holiday) => {
    const res: MasterHolidayList = {
      key: holiday.id,
      effective_date: holiday.date_start,
      end_date: holiday.date_stop,
      holiday_name: holiday.name,
      recurring: holiday.recurring ? 'Ya' : 'Tidak',
      holiday_duration: `${holiday.total_holidays} Hari`,
      status: holiday.is_active ? 'Aktif' : 'Non-Aktif',
      history_id: holiday.history_id,
      company_id: holiday.company_id,
      create_date: holiday.view_create_date,
      create_name: holiday.create_name,
      write_date: holiday.view_write_date,
      write_name: holiday.write_name,
      create_uid: holiday.create_uid,
      write_uid: holiday.write_uid,
    }

    result.push(res)
  })

  return result
}