import getEmployeeMe from "../../../../../@core/interaction/employee/GetEmployeeMe.interaction"
import Constant from "../../../../../config/Constants"
import API from "../../../../../services/API.service"

export class DetailMassLeaveUseCase {
  getEmp(){
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/emp"
    return API.get(URL)
  }

  getDetail(id) {
    const URL = Constant.URL_BASE_ATTENDANCE + `/v3/leave/all-request/${id}`
    return API.get(URL)
  }

  update(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + `/v3/leave/all-request/update`
    return API.put(URL, body)
  }

  regenerate(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/leave/all-request/regenerate"
    return API.put(URL, body)
  }

  active(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + `/v3/leave/all-request`
    return API.put(URL, body)
  }

  check(id) {
    const URL = Constant.URL_BASE_ATTENDANCE + `/v3/leave/all-request/${id}/check`
    return API.get(URL)
  }

  getEmployeeMe() {
    return getEmployeeMe().then(
      (res: any) => {
        const data = res.data
        if (data.status === 200) {
          return data.content.id
        }
      }
    ).catch(err => {
      return err
    })
  }
}