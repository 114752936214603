import React, { useEffect, useRef, useState } from 'react';
import Chartjs from 'chart.js/auto';

const BarChart = (props) => {
  const chartBarContainer = useRef(null);
  const [chartBarInstance, setChartBarInstance] = useState<any>(null);

  const chartBarConfig:any = {
    type: 'bar',
    data: {
      labels: props.data.labels,
      datasets: [
        {
          labels: 'none',
          barThickness: 30,
          minBarLength: 2,
          data: props.data.data,
          backgroundColor: 'red',
          borderColor: 'red',
        },
      ],
    },
    options: {
      plugins: {
        // Change options for ALL labels of THIS CHART
        datalabels: {
            color: '#fff',
            fontStyle: 'bold',
        },
        legend: {
          display: false,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      hover: {
        mode: 'nearest',
        intersect: true,
      },
      scales: {
        x: {
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Month',
          },
        },
        y: {
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Value',
          },
        },
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                return value;
              },
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              fontSize: 12,
              fontStyle: 'bold',
              color: '#000',
              callback: function (value, index, values) {
                return value;
              },
            },
          },
        ],
      },
    },
  };

  useEffect(() => {
    if (chartBarContainer && chartBarContainer.current) {
      const newChartInstance = new Chartjs(chartBarContainer.current, chartBarConfig);
      setChartBarInstance(newChartInstance);
    }
  }, [chartBarContainer]);

  useEffect(() => {
    if(props && !!props?.data && chartBarInstance !== null){
      chartBarInstance.data.labels = props.data.labels ? props.data.labels : [];
      chartBarInstance.data.datasets[0].data = props?.data?.data;
      chartBarInstance.update();
    }
  }, [props]);

  // display: props.reportAge.length < 1   ? 'none' : 'block' 
  return (
    <div style={{ position: 'relative', height: '40vh'}}>
      <canvas ref={chartBarContainer} />
    </div>
  );
};

export default BarChart;
