import { useEffect, useState } from "react";
import AddMasterWorkingScheduleTemplate from "../template/AddMasterWorkingScheduleTemplate";
import { ToolbarDetailActionButton, ToolbarDetailDropdownButton } from "../../../../component/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface";
import ToastService from "../../../../../../services/Toast.service";
import LocalNotificationService from "../../../../../../services/LocalNotification.service";
import AddMasterWorkingScheduleUseCase from "../add-master-working-schedule/AddMasterWorkingSchedule.usecase";
import { ScheduleMasterDetailMapper } from "../../../../../../@core/mapper/attendance/MasterWorkingScheduleDetail.mapper";
import { MapperSaveMasterWorkingSchedule } from "../../../../../../@core/mapper/attendance/SaveMasterWorkingSchedule.mapper";

const usecase = new AddMasterWorkingScheduleUseCase()
const toastService = new ToastService()
const localNotificationService = new LocalNotificationService()
let historyId
let tempDetailData

export default function DetailMasterWorkingSchedule({ match }) {
  const masterId = match.params.masterId

  const [isLoading, setIsLoading] = useState(false)
  const [viewMode, setViewMode] = useState(true)
  const [disabledDropdownButton, setDisableDropdownButton] = useState(true)
  const [dataSource, setDataSource] = useState<any>()

  useEffect(() => {
    setIsLoading(true)
    usecase.getDetail(masterId).then(
      (res) => {
        const content = res.data.content
        tempDetailData = content
        const result = ScheduleMasterDetailMapper(content)
        historyId = result.historyId
        const detailData = result.scheduleMasterDetail
        setDisableDropdownButton(!result.status)
        setDataSource({...detailData})
        setIsLoading(false)
      }
    ).catch(err => {
      setIsLoading(false)
    })

    return () => {
      window.location.reload()
    }
  }, [])

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Master Jadwal Kerja',
        href: "/v2/app/attendance/master-working-schedule",
      },
      {
        clickable: false,
        displayPathName: tempDetailData?.name,
        href: "/v2/app/attendance/master-working-schedule/new/",
      }
    ],
  };

  const submitButton = { active: !viewMode, disabled: isLoading };
  const firstActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: !viewMode,
    disabled: isLoading,
    onClick: () => {
      const back = window.confirm("Anda Ingin Kembali")
      if (back) {
        window.location.reload()
      }
    },
  };

  const secondActionButton: ToolbarDetailActionButton = {
    label: 'UBAH',
    type: 'primary',
    active: viewMode,
    disabled: isLoading,
    onClick: () => {
      setViewMode(false)
    },
  };

  const thirdActionButton: ToolbarDetailActionButton = {
    label: 'TAMBAH',
    type: 'primary',
    active: viewMode,
    disabled: isLoading,
    onClick: () => {
      window.location.href = "/v2/app/attendance/master-working-schedule/new/"
    },
  };

  const dropdownActionButton: ToolbarDetailDropdownButton = {
    disabled: false,
    visible: !!masterId,
    options: [
      {
        key: 'non-aktif', disabled: disabledDropdownButton, label: 'Non-Aktifkan', onClick(procedure) {
          setIsLoading(true)
          const params = {
            ids: [masterId],
            status: false
          }
          usecase.active(params).then(
            (res) => {
              const content = res.data.content
              if (content.code === 200) {
                const message = content.message.split("- ")[1]
                localNotificationService.openSuccess(message, '', 1)
              }
              setTimeout(() => {
                window.location.reload()
              }, 1500)
            }).catch(err => {
              setIsLoading(false)
              toastService.error(err)
            })
        },
      }
    ]
  }

  const onFieldChange = (e) => {
    if (e.length === 1) {
      const fieldName = e[0].name[0];
      const value = e[0].value;

      switch (fieldName) {
        default:
          break;
      }
    }
  }

  const onFinish = (value) => {
    setIsLoading(true)
    const body = MapperSaveMasterWorkingSchedule(value)
    const result = {
      hira_absent: [
        {
          ...tempDetailData,
          id: masterId,
          history_id: historyId,
          ...body.hira_absent[0]
        }
      ]
    }
    usecase.update(result).then((res) => {
      const data = res.data
      if (data.code === 200) {
        const message = data.message
        localNotificationService.openSuccess(message, '', 1)
      }
      setTimeout(() => {
        window.location.reload()
      }, 1500)
    }).catch(err => {
      setIsLoading(false)
      toastService.error(err)
    })
  }

  return (
    <div className="detail-master-schedule">
      <AddMasterWorkingScheduleTemplate
        key={'detail-master-schedule'}
        breadcrumbConf={breadcrumbConf}
        dataSource={dataSource}
        viewMode={viewMode}
        isDetail={true}
        historyId={historyId}
        submitButton={submitButton}
        firstActionButton={firstActionButton}
        secondActionButton={secondActionButton}
        thirdActionButton={thirdActionButton}
        fourthActionButton={{ active: false }}
        dropdownActionButton={dropdownActionButton}
        onFieldChange={onFieldChange}
        onFinish={onFinish}
        isLoading={isLoading}
      />
    </div>
  )
}