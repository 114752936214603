import { Job, JobListObjById } from '../../entity/Job.entity';

export function jobListToObjById(jobList: Job[]): JobListObjById {
  const result = jobList.reduce((obj, job) => ((obj[job.id] = job), obj), {});

  return result;
}

export function jobListToOption(jobList: Job[]) {
  const result = [];
  if (jobList.length) {
    jobList.forEach((job) => {
      result.push({
        label: job.job_name,
        value: job.id,
        id: job.id,
        department_id: job.dept_id,
        department_name: job.dept_name
       
      });
    });
  }
  
  return result;
}
