import React, { useEffect, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import Constant from "../../../../../config/Constants"
import API from "../../../../../services/API.service"
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import { Form, Row, Col, Space, Tag, Button, Modal, Table } from 'antd';
import PageLoader from '../../../../../components/loader/PageLoader';
import HistoryTabs from './components/history-tabs/HistoryTabs'
import ToolbarLeaveDetailTemplate from './components/toolbar-configuration-leave-template/ToolbarLeaveConfigDetailTemplate';
import BottomLoading from '../../../../../components/bottom-loading/BottomLoading';
import './LeaveConfigurationAddTemplate.less';
const MonthOptions = {
  1: 'Januari',
  2: 'Februari',
  3: 'Maret',
  4: 'April',
  5: 'Mei',
  6: 'Juni',
  7: 'Juli',
  8: 'Agustus',
  9: 'September',
  10: 'Oktober',
  11: 'November',
  12: 'Desember',
}
const AcitveWhenOptions = [
  { value: 'mulai_bergabung', label: 'Mulai Bergabung' },
  { value: 'bergabung_dibulan', label: 'Bergabung di Bulan ke' },
]
const PlafonOptions = [
  { value: 'terbatas', label: 'Terbatas' },
  { value: 'tidak_terbatas', label: 'Tidak Terbatas' },
]
const ApprovalOptions = [
  { value: 'tanpa_persetujuan', label: 'Tanpa Persetujuan' },
  { value: 'atasan_langsung', label: 'Atasan Langsung' },
  { value: 'jabatan', label: 'Jabatan' },
]
export default function LeaveConfigurationDetailTemplate(props) {
  const [form] = Form.useForm();
  const { dataSource, jobList } = props;

  const [visibleEmployeeModal, setVisibleEmployeeModal] = useState(false);
  const [visibleJobModal, setVisibleJobModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(true);
  const [leaveRel, setLeaveRel] = useState([]);
  const [totalRel, setTotalRel] = useState(0);
  const [page, setPage] = useState(1);

  const selectedEmployee = (value) => {
    return (
      <Space direction='vertical' style={{ width: '70%' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {
            value && value.map(item => {
              return (
                <div style={{ marginBottom: 10 }}>
                  <Tag color='red'>
                    <span key={item.id}>
                      {item.alternative_name}
                    </span>
                  </Tag>
                </div>
              );
            })
          }
          {
            dataSource?.view_list_emp?.length > 9 && (
              <div style={{ marginBottom: 10 }}>
                <Tag color='red'>
                  <span>
                    ...
                  </span>
                </Tag>
              </div>
            )
          }
        </div>
        <div>
          <Button type="link" onClick={() => setVisibleEmployeeModal(true)}>Tampilkan Semua</Button>
        </div>
      </Space>
    )
  }
  const selectedJob = (value) => {
    return (
      <Space direction='vertical' style={{ width: '70%' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {
            value && value.map(item => {
              return (
                <div style={{ marginBottom: 10 }}>
                  <Tag color='red'>
                    <span key={item.id}>
                      {item.name}
                    </span>
                  </Tag>
                </div>
              );
            })
          }
          {
            dataSource?.view_list_job?.length > 9 && (
              <Tag color='red'>
                <span>...</span>
              </Tag>
            )
          }
        </div>
        <div>
          <Button type="link" onClick={() => setVisibleJobModal(true)}>Tampilkan Semua</Button>
        </div>
      </Space>
    )
  }

  const JOB_TABLE_HEADER = [
    {
      title: 'Jabatan',
      dataIndex: 'name',
    },
    {
      title: 'Departement',
      dataIndex: 'dept_name',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (value, result) => {
        return (
          <DeleteOutlined onClick={(e) => {
            e.preventDefault();
            const param: any = {
              ids: [result.id],
              config_type: "job"
            }
            const URL = Constant.URL_BASE_ATTENDANCE + `/v3/configuration/leave/${dataSource.id}/rel`
            return API.delete(URL, { data: param }).then((res: any) => {
              setModalLoading(true);
              if (res.status === 200) {
                props.setIsRefresh(!props.isRefresh);
              }
              getLeaveRel();
            })
          }} />
        )
      }
    },
  ]

  const EMPLOYEE_TABLE_HEADER = [
    {
      title: 'NIK',
      dataIndex: 'nik',
    },
    {
      title: 'Nama',
      dataIndex: 'name',
    },
    {
      title: 'Jabatan',
      dataIndex: 'job_name',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (value, result) => {
        return (
          <DeleteOutlined onClick={(e) => {
            e.preventDefault();
            const param: any = {
              ids: [result.id],
              config_type: "employee"
            }
            const URL = Constant.URL_BASE_ATTENDANCE + `/v3/configuration/leave/${dataSource.id}/rel`
            return API.delete(URL, { data: param }).then((res: any) => {
              setModalLoading(true);
              if (res.status === 200) {
                props.setIsRefresh(!props.isRefresh);
              }
              getLeaveRel();
            })
          }} />
        )
      }
    },
  ]

  useEffect(() => {
    getLeaveRel()
    if (props.formInitEvent) {
      setTimeout(() => {
        props.formInitEvent(form.getFieldsValue());
      }, 50);
    }
  }, [props.dataSource]);

  async function getLeaveRel(current = 1) {
    setModalLoading(true);
    setPage(current);
    if (dataSource) {
      let params = {
        leaveId: dataSource.id,
        page: current,
        limit: 10,
        config_type: dataSource.config_type,
        with_meta: true
      }
      const tempRel = await props.useCaseImpl.getLeaveRel(params);
      setLeaveRel(tempRel?.content)
      setTotalRel(tempRel.metadata?.total_records)
    }
    setModalLoading(false);
    setIsLoading(false);
  }

  if (!dataSource || isLoading) {
    return <PageLoader />;
  }

  return (
    <div>
      <Breadcrumb breadcrumbConf={props.breadcrumbConf} />

      <Modal
        visible={visibleEmployeeModal}
        onCancel={() => setVisibleEmployeeModal(false)}
        footer={null}
      >
        <div>
          <Table
            loading={modalLoading}
            columns={EMPLOYEE_TABLE_HEADER}
            dataSource={leaveRel}
            pagination={{
              showSizeChanger: false,
              onChange: (page: number) => getLeaveRel(page),
              current: page,
              total: totalRel,
            }}
          />
        </div>
      </Modal>

      <Modal
        visible={visibleJobModal}
        onCancel={() => setVisibleJobModal(false)}
        footer={null}
      >
        <div>
          <Table
            loading={modalLoading}
            columns={JOB_TABLE_HEADER}
            dataSource={leaveRel}
            pagination={{
              showSizeChanger: false,
              onChange: (page: number) => getLeaveRel(page),
              current: page,
              total: totalRel,
            }}
          />
        </div>
      </Modal>

      <>
        <ToolbarLeaveDetailTemplate
          submitButton={props.submitButton}
          firstActionButton={props.firstActionButton}
          secondActionButton={props.secondActionButton}
          thirdActionButton={props.thirdActionButton}
          fourthActionButton={props.fourthActionButton}
          dropdownButton={props.dropdownButton}
        />

        <div className="scrollable-content-config-leave">
          <div className="form-wrapper">
            <Row className='detail-item'>
              <Col flex={'20%'}>Nama Cuti</Col>
              <Col flex={'60%'}>{dataSource.name}</Col>
            </Row>
            <Row className='detail-item'>
              <Col flex={'20%'}>Tipe Plafon</Col>
              <Col flex={'60%'}>{PlafonOptions.find((obj) => obj.value === dataSource.plafon_type)?.label}</Col>
            </Row>
            {
              dataSource.plafon_type === 'terbatas' &&
              (<Row className='detail-item'>
                <Col flex={'20%'}>Jumlah hari per tahun</Col>
                <Col flex={'60%'}>{dataSource.plafon_value}</Col>
              </Row>)
            }

            <Row className='detail-item'>
              <Col flex={'20%'}>Periode Reset</Col>
              <Col flex={'60%'}>{dataSource.date_plafon_reset} {MonthOptions[dataSource.month_plafon_reset]}</Col>
            </Row>
            <Row className='detail-item'>
              <Col flex={'20%'}>Aktif Ketika</Col>
              <Col flex={'60%'}>
                {AcitveWhenOptions.find((obj) => obj.value === dataSource.active_when)?.label}
                {(dataSource.active_when === 'bergabung_dibulan' && dataSource.month_join) ? - dataSource.month_join : ''}
              </Col>
            </Row>
            {
              dataSource.view_list_job && (
                <Row className='detail-item'>
                  <Col flex={'20%'}>Jabatan yang sudah dipilih</Col>
                  <Col flex={'60%'}>{selectedJob(dataSource.view_list_job)}</Col>
                </Row>
              )
            }
            {
              dataSource.view_list_emp && (
                <Row className='detail-item'>
                  <Col flex={'20%'}>karyawan yang sudah dipilih</Col>
                  <Col flex={'60%'}>{selectedEmployee(dataSource.view_list_emp)}</Col>
                </Row>
              )
            }
            <Row className='detail-item'>
              <Col flex={'20%'}>Persetujuan 1</Col>
              <Col flex={'60%'}>{ApprovalOptions.find((obj) => obj.value === dataSource.approval_1)?.label}</Col>
            </Row>
            {
              dataSource.approval_1 === 'jabatan' && (
                <Row className='detail-item'>
                  <Col flex={'20%'}>Nama Jabatan</Col>
                  <Col flex={'60%'}>{jobList.find((obj) => obj.id === dataSource.job_approval_1)?.label || '-'}</Col>
                </Row>
              )
            }
            {
              !!dataSource.approval_2 && (
                <Row className='detail-item'>
                  <Col flex={'20%'}>Persetujuan 2</Col>
                  <Col flex={'60%'}>{ApprovalOptions.find((obj) => obj.value === dataSource.approval_2)?.label}</Col>
                </Row>
              )
            }
            {
              dataSource.approval_2 === 'jabatan' && (
                <Row className='detail-item'>
                  <Col flex={'20%'}>Nama Jabatan</Col>
                  <Col flex={'60%'}>{jobList.find((obj) => obj.id === dataSource.job_approval_2)?.label || '-'}</Col>
                </Row>
              )
            }

          </div>
          <HistoryTabs dataSource={dataSource?.historyList} />
        </div>
      </>

      <BottomLoading active={props.isLoading} />
    </div>
  );
}
