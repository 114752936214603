export const ListLoanReportConstant = {
  TABLE_HEADER: [
    {
      title: 'Nomor Pengajuan',
      key: 'submission_number',
      dataIndex: 'submission_number',
      width: 200,
      isCTA: true,
    },
    {
      title: 'NIK',
      key: 'nik',
      dataIndex: 'nik',
      width: 180
    },
    {
      title: 'Nama Karyawan',
      key: 'employee_name',
      dataIndex: 'employee_name',
      width: 210
    },
    {
      title: 'Jabatan',
      key: 'job',
      dataIndex: 'job',
      width: 220
    },
    {
      title: 'Tipe Pinjaman',
      key: 'loan_config',
      dataIndex: 'loan_config',
      width: 280
    },
    {
      title: 'Tenor (Bulan)',
      key: 'tenor',
      dataIndex: 'tenor',
      width: 240,
      editable: false,
    },
    {
      title: 'Total Pokok',
      key: 'total_pokok',
      dataIndex: 'total_pokok',
      width: 200, 
    },
    {
      title: "Total Pokok Dibayar",
      key: 'total_payment',
      dataIndex: 'total_payment',
      width: 150
    },
    { title: 'Sisa', key: 'remnant', dataIndex: 'remnant', width: 180 },
  ],
  SEARCH_COLUMN_KEY: [],
  DEFAULT_EMPTY_DATA_SOURCE: [
    {
      holiday_name: '1',
      madeBy: '',
      madeOn: '',
      changeBy: '',
      changeOn: '',
    },
  ],
}