import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import ExpandableListTemplate from "../../../../../../templates/expandable-list-template/ExpandableListTemplate";
import { ExpandableListTemplateDropdownButton, ExpandableListTemplatePagination } from "../../../../../../templates/expandable-list-template/ExpandableListTemplate.interface";
import { SubmissionOvertimeListConstant } from "./EmployeeSubmissionOvertimeList.constant";
import { createExcelFromByte } from "../../../../payroll/configuration/master-payroll/list-master-payroll/ListMasterPayroll.service";
import EmployeeSubmissionOvertimeUseCase from "./EmployeeSubmissionOvertimeList.usecase";
import { ListSubmissionOvetimeMapper } from "../../../../../../@core/mapper/overtime/ListSubmissionOvertimeMapper";

let page = 1;
let loadPagination = true;
let sort = 'write_date|desc'
let number_request
let nik
let employee_name
let date_request
let selectionSubmissionId = [];
let is_active = true
const limit = 10
const useCase = new EmployeeSubmissionOvertimeUseCase()

export default function ListEmployeeSubmissionOvertime() {
  const history = useHistory();
  const tableHeader: any = SubmissionOvertimeListConstant.TABLE_HEADER
  const searchColumnKey = SubmissionOvertimeListConstant.SEARCH_COLUMN_KEY
  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: false,
        displayPathName: 'Pengajuan Lembur Karyawan',
        href: "/v2/app/payroll/configuration/Master-gaji",
      },
    ],
  };
  let [pagination, setPagination] = useState<ExpandableListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });
  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([
    ...SubmissionOvertimeListConstant.DEFAULT_EMPTY_DATA_SOURCE
  ]);
  let [tableScroll, setTableScroll] = useState({ y: 700 });
  let [disableDeleteButton, setDisableDeleteButton] = useState(true);
  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: true };

  useEffect(() => {
    loadList()
  }, [])

  function loadList() {
    setIsLoading(true);

    const params = {
      page,
      limit,
      sort,
      number_request,
      nik,
      is_active,
      date_request,
      employee_name,
      with_meta: true,
    };

    useCase.getAll(params).then(
      (res: any) => {
        setIsLoading(false)

        const content = res.data.content;
        const metaData = res.data.metadata;

        const resultDataSource = content?.length ? ListSubmissionOvetimeMapper(content) : 
          SubmissionOvertimeListConstant.DEFAULT_EMPTY_DATA_SOURCE;
        setDataSource([...resultDataSource]);
        updatePaginationState(metaData?.total_records || 0);

        loadPagination = true;
      },
      (err) => {
        setIsLoading(false);
        loadPagination = true;
      })
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    const outputSort = output.sort;
    const outputSearchColumn = output.searchColumn;

    processSort(outputSort);
    processSearchColumn(outputSearchColumn);
    processSelection(output.selection);
    processPagination(outputPagination);
  };

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  function processSort(outputSort) {
    if (outputSort && useCase.convertOutputSortToEndPointFormat(outputSort) !== sort) {
      page = 1;
      loadPagination = false;

      sort = useCase.convertOutputSortToEndPointFormat(outputSort);
      loadList();
    }
  }

  function processSearchColumn(outputSearchColumn) {
    let isLoadList = false;

    if (outputSearchColumn) {
      if (
        outputSearchColumn.number_submission_overtime !== undefined &&
        outputSearchColumn.number_submission_overtime !== number_request
      ) {
        number_request = outputSearchColumn.number_submission_overtime;
        isLoadList = true;
      }

      if (
        outputSearchColumn.employee_nik !== undefined &&
        outputSearchColumn.employee_nik !== nik
      ) {
        nik = outputSearchColumn.employee_nik;
        isLoadList = true;
      }

      if (
        outputSearchColumn.employee_name !== undefined &&
        outputSearchColumn.employee_name !== employee_name
      ) {
        employee_name = outputSearchColumn.employee_name;
        isLoadList = true;
      }

      if (
        outputSearchColumn.submission_date !== undefined &&
        outputSearchColumn.submission_date !== date_request
      ) {
        date_request = outputSearchColumn.submission_date;
        isLoadList = true;
      }

      if (isLoadList) {
        page = 1;
        loadPagination = false;
        loadList();
      }
    }
  }

  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionSubmissionId) {
      selectionSubmissionId = outputSelection;
      setDisableDeleteButton(!selectionSubmissionId.length);
    }
  }

  function softDelete(status) {
    setIsLoading(true)
    const params = {
      ids: selectionSubmissionId,
    }

    useCase.active(params).then(
      (res) => {
        setIsLoading(false)
        loadList()
      }
    ).catch(err => {
      setIsLoading(false)
    })
  }

  function exportData() {
    setIsLoading(true);

    const params = {
      sort,
      number_request,
      date_request,
      nik,
      employee_name,
      id: selectionSubmissionId,
      with_meta: true,
    };

    useCase.export(params).then(
      (res: any) => {
        const content = res.data.content;
        if (res.data.status === 200) {
          const bytes = content.data
          const fileName = content.filename
          createExcelFromByte(bytes, fileName);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    )
  }

  const addButton = {
    onClick: () => {
      history.push("/app/hr-overtime/employee-submission-overtime/new/")
    }
  }

  const dropdownButton: ExpandableListTemplateDropdownButton = {
    visible: true,
    disabled: isLoading,
    options: [
      { key: 'ekspor', label: 'Ekspor', onClick: () => exportData() },
      { key: 'non-active', disabled: disableDeleteButton, label: 'Non-Aktifkan', onClick: () => softDelete(false) }
    ]
  };

  const onRow = (e) => {
    if (e.key && e.key !== 'search-input' && e.key !== 'empty-data') {
      history.push("/app/hr-overtime/employee-submission-overtime/" + e.key)
    }
  }

  return (
    <div className="list-salary-approval">
      <ExpandableListTemplate
        breadcrumbConf={breadcrumbConf}
        dropdownButton={dropdownButton}
        tableData={tableData}
        tableHeader={tableHeader}
        deleteButton={{ visible: false }}
        addButton={addButton}
        tablePagination={pagination}
        tableOnChange={tableOnChange}
        tableScroll={tableScroll}
        isLoading={isLoading}
        exportButton={{ visible: false }}
        expandedRowRender={null}
        onRow={onRow}
      />
    </div>
  )
}