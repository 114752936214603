import { useState, useEffect } from 'react';
import { PictureOutlined, DeleteFilled } from '@ant-design/icons';
import { UploadImageProps } from './UploadImage.interface';
import './UploadImage.less';
import ModalShowImageLeave from './ModalShowImageLeave';

export default function UploadImage(props: UploadImageProps) {
  const acceptedFile = ['image/png', 'image/jpeg', 'image/jpg'];

  let [selectedImg, setSelectedImg] = useState(null);
  let [firstTime, setFirstTime] = useState(true);
  let [componentId, setComponentId] = useState('');
  let [canEdit, setCanEdit] = useState(true);
  let [isShowModal, setIsShowModal] = useState(false);

  useEffect(() => {
    processSelectedImage();
  }, [props.selectedImg]);
  useEffect(() => {
    setComponentId(props.componentId);
  }, [props.componentId]);
  useEffect(() => {
    processCanEdit();
  }, [props.canEdit]);

  const processSelectedImage = () => {
    const resultSelectedImage = props.selectedImg ? props.selectedImg : null;
    const resultFirstTime = props.selectedImg ? false : true;
    setSelectedImg(resultSelectedImage);
    setFirstTime(resultFirstTime);
  };

  const processCanEdit = () => {
    let result = true;

    if (props.canEdit !== undefined) {
      result = props.canEdit;
    }

    setCanEdit(result);
  };

  async function onChange(e, procedure) {
    const file = e.target.files[0];

    if (acceptedFile.indexOf(file.type) !== -1) {
      setSelectedImg(URL.createObjectURL(file));

      if (procedure === 'add') {
        setFirstTime(false);
      }
      if (props.onChange) {
        const base64Result = await getBase64(file);

        const result = {
          base64Result,
          file,
        };
        props.onChange(result, componentId);
      }
    }
  }
  const onDelete = () => {
    setFirstTime(true);
    if (props.onChange) {
      props.onChange('', componentId);
    }
  };

  function getBase64(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        resolve(error);
      };
    });
  }

  const setModalImage = () => {
    const newIsShowModal = !isShowModal
    if (!canEdit) {
      setIsShowModal(newIsShowModal)
    }
  }

  const AddImage = () => {
    return (
      <div className="add-image-wrapper">
        <label className="full-box-select-img" htmlFor={componentId}>
          <PictureOutlined
            style={{ fontSize: '22px', margin: '12px auto auto auto', display: 'block' }}
          />
          <span className="select-img-text">{canEdit ? 'Pilih Gambar...' : ''}</span>
        </label>

        {canEdit && (
          <input
            className="toogle-file"
            type="file"
            id={componentId}
            name={componentId}
            accept="image/png, image/jpeg, image/jpg"
            onChange={(e) => onChange(e, 'add')}
          />
        )}
      </div>
    );
  };

  const EditImage = () => {
    return (
      <div className="edit-image-wrapper">
        <div className="selected-img-wrapper">
          <img alt="selected-img" src={selectedImg} style={{cursor: 'pointer'}} onClick={setModalImage} />
          {canEdit && (
            <button className="delete-image-btn" onClick={onDelete}>
              <DeleteFilled />
            </button>
          )}
        </div>

        {canEdit && (
          <label className="change-img-btn" htmlFor={componentId}>
            Ubah Gambar
          </label>
        )}

        {canEdit && (
          <input
            className="toogle-file"
            type="file"
            id={componentId}
            name={componentId}
            accept="image/png, image/jpeg"
            onChange={(e) => onChange(e, 'edit')}
          />
        )}

        <ModalShowImageLeave 
          imageSrc={selectedImg} 
          isShowModal={isShowModal}
          showModal={setModalImage}
        />
      </div>
    );
  };

  return <div className="hira-upload-image">{firstTime ? <AddImage /> : <EditImage />}</div>;
}
