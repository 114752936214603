import React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { logoutUser, useUserDispatch } from '../../context/UserContext';

export default function Logout() {
  const userDispatch = useUserDispatch();
  const history = useHistory();

  useEffect(() => {
    logoutUser(userDispatch, history);
  }, []);

  return <div>Logging out</div>;
}
