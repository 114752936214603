import {
  EmployeeLeaveConfig,
  EmployeeLeaveConfigOption,
} from '../../entity/EmployeeLeaveConfig.entity';

export function employeeConfigListToOptions(
  leaveConfigList: EmployeeLeaveConfig[],
): EmployeeLeaveConfigOption[] {
  const result = leaveConfigList.map((leaveConfig) => {
    return {
      label: leaveConfig.name,
      value: leaveConfig.id,
      id: leaveConfig.id,
      configLineId: leaveConfig.leave_config_emp_id,
    };
  });

  return result;
}
