import { useHistory } from "react-router-dom";
import EditableListTemplate from "../../../../../../templates/editable-list-template/EditableListTemplate";
import { useEffect, useState } from "react";
import { EditableListTemplatePagination } from "../../../../../../templates/editable-list-template/EditableListTemplate.interface";
import { EmployeeWorkingScheduleConstant } from "./ListEmployeeWorkingSchedule.constant";
import EmployeeWorkingScheduleUseCase from "./ListEmployeeWorkingSchedule.usecase";
import { ScheduleWorkListMapper } from "../../../../../../@core/mapper/attendance/GetEmployeeWorkingSchedule.mapper";
import { MapperUpdateScheduleWork } from "../../../../../../@core/mapper/attendance/SaveEmployeeWorkingSchedule.mapper";
import ToastService from "../../../../../../services/Toast.service";
import LocalNotificationService from "../../../../../../services/LocalNotification.service";
import { Modal } from "antd";

let page = 1;
let loadPagination = true;
let sort = 'write_date|desc'
const limit = 10;
let selectionId = []
let nama_jadwal_kerja
let q

const constant = EmployeeWorkingScheduleConstant
const usecase = new EmployeeWorkingScheduleUseCase()
const toastService = new ToastService()
const localNotificationService = new LocalNotificationService();


export default function ListEmployeeWorkingSchedule() {
  const history = useHistory();

  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([]);
  let [tableScroll, setTableScroll] = useState({ y: 600, x: 'max-content' });
  let [visibleDeleteButton, setVisibleDeleteButton] = useState(false);
  let [disableExportButton, setDisableExportButton] = useState(false);
  let [visibleWarningModal, setVisibleWarningModal] = useState(false);
  let [visibleSaveButton, setVisibleSaveButton] = useState(false);
  let [rowTableChange, setRowTableChange] = useState({})
  let [scheduleList, setScheduleList] = useState([])
  let [pagination, setPagination] = useState<EditableListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });

  let tableHeader: any = constant.TABLE_HEADER
  let searchColumnKey = constant.SEARCH_COLUMN_KEY
  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: true };

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: false,
        displayPathName: 'Jadwal Kerja Karyawan',
        href: "/v2/app/attendance/employee-working-schedule",
      },
    ],
  };

  useEffect(() => {
    loadList()
    getScheduleWork()
  }, [])

  useEffect(() => {
    initDataSource(dataSource)
  }, [rowTableChange])

  function initDataSource(data) {
    if (Object.keys(rowTableChange).length) {
      setVisibleSaveButton(true)
      const newData = data.map((data) => {
        if (rowTableChange[data.key]) {
          return {
            ...data,
            ...rowTableChange[data.key]
          }
        }

        return data
      })

      setDataSource(JSON.parse(JSON.stringify(newData)))
    } else {
      setDataSource([...data])
    }
  }

  function loadList() {
    setIsLoading(true)
    const params = {
      page,
      limit,
      sort,
      nama_jadwal_kerja,
      q,
      with_meta: true,
    }

    usecase.getAll(params).then((res: any) => {
      setIsLoading(false)
      const content = res.data.content;
      const metaData = res.data.metadata;

      const resultDataSource = content && content.length ? ScheduleWorkListMapper(content)
        : constant.DEFAULT_EMPTY_DATA_SOURCE

      initDataSource([...resultDataSource])
      updatePaginationState(metaData.total_records);

      loadPagination = true
      setDisableExportButton(content ? false : true)
    }, (err) => {
      setIsLoading(false)
    })
  }

  function getScheduleWork() {
    setIsLoading(true)
    const params = {
      page: 1,
      limit: 30,
      sort,
      with_meta: false,
    }

    usecase.getMasterSchedule(params).then((res: any) => {
      setIsLoading(false)
      const content = res.data.content;

      const resultDataSource = content && content.length ? mapperOptions(content)
        : constant.DEFAULT_EMPTY_DATA_SOURCE

      setScheduleList([...resultDataSource])
      tableHeader[3].options = [...resultDataSource]
    }, (err) => {
      setIsLoading(false)
      toastService.error(err)
    })
  }

  function mapperOptions(content) {
    return content.map((c) => {
      return { key: c.id, value: c.name }
    })
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  function processSort(outputSort) {
    if (outputSort && usecase.convertOutputSortToEndPointFormat(outputSort) !== sort) {
      page = 1;
      loadPagination = false;

      sort = usecase.convertOutputSortToEndPointFormat(outputSort);
      loadList();
    }
  }

  function processSearchColumn(outputSearchColumn) {
    let isLoadList = false;

    if (outputSearchColumn) {
      if (
        outputSearchColumn.nama_jadwal_kerja !== undefined &&
        outputSearchColumn.nama_jadwal_kerja !== nama_jadwal_kerja
      ) {
        nama_jadwal_kerja = outputSearchColumn.nama_jadwal_kerja;
        isLoadList = true;
      }

      if (
        outputSearchColumn.employee_name !== undefined &&
        outputSearchColumn.employee_name !== q
      ) {
        q = outputSearchColumn.employee_name
        isLoadList = true
      }
    }

    if (isLoadList) {
      page = 1;
      loadPagination = false;
      loadList();
    }
  }

  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionId) {
      selectionId = outputSelection;
      setVisibleDeleteButton(!!selectionId.length)
    }
  }

  const deleteButton = {
    visible: visibleDeleteButton,
    disabled: isLoading,
    onClick: () => {
      setVisibleWarningModal(true)
    }
  };

  const addButton = {
    onClick: () => {
      history.push('/app/attendance/employee-working-schedule/new/');
    },
    disabled: isLoading,
  };

  const importButton = {
    onClick: () => {
      history.push('/app/attendance/employee-working-schedule/import/');
    },
    disabled: isLoading,
  };

  const saveButton = {
    onClick: () => {
      setIsLoading(true)
      const body = MapperUpdateScheduleWork(rowTableChange, scheduleList)
      usecase.update(body).then(
        (res) => {
          const data = res.data
          if (data.code === 200) {
            const message = data.message
            localNotificationService.openSuccess(message, '', 1)
          }
          setTimeout(() => {
            window.location.reload()
          }, 750)
        }
      ).catch((err) => {
        toastService.error(err)
        setIsLoading(false)
      })
    },
    disabled: isLoading,
    visible: visibleSaveButton,
  };

  const exportButton = {
    disabled: isLoading || disableExportButton,
    onClick: () => {
      setIsLoading(true)
      const params = {
        sort,
        nama_jadwal_kerja,
        q,
        id: selectionId,
        with_meta: true,
      }

      usecase.export(params).then((resp: any) => {
        setIsLoading(false);
        const content = resp.data.content;
        if (resp.status === 200) {
          const data = content.data
          let sampleArr = base64ToArrayBuffer(data)
          saveByteArray(content.filename, sampleArr);
        }
      })
    }
  }

  function deleteToEndPoint() {
    setIsLoading(true)
    const body = {
      ids: selectionId
    }

    usecase.delete(body).then((res) => {
      setVisibleWarningModal(false)
      const content = res.data.content
      if (content.code === 200) {
        const message = content.message.split("- ")[1]
        localNotificationService.openSuccess(message, '', 1)
      }
      setTimeout(() => {
        window.location.reload()
      }, 750)
    }).catch(err => {
      toastService.error(err)
      setIsLoading(false)
      setVisibleWarningModal(false)
    })
  }

  function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  function saveByteArray(reportName, byte) {
    let blob = new Blob([byte], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    let fileName = reportName;
    link.download = fileName;
    link.click();
  };

  const onInputRowChange = (key, value, field) => {
    if (!rowTableChange[key]) {
      rowTableChange[key] = dataSource.find(
        (data) => {
          return data.key === key
        })
    }
    rowTableChange[key][field] = value
    setRowTableChange(JSON.parse(JSON.stringify(rowTableChange)))
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    const outputSort = output.sort;
    const outputSearchColumn = output.searchColumn;

    processSort(outputSort);
    processSearchColumn(outputSearchColumn);
    processSelection(output.selection);
    processPagination(outputPagination);
  };

  return (
    <div className="list-employee-working-schedule">
      <Modal
        title="Hapus Data"
        cancelText="BATALKAN"
        visible={visibleWarningModal}
        cancelButtonProps={{ type: 'ghost' }}
        onOk={deleteToEndPoint}
        onCancel={() => setVisibleWarningModal(false)}
      >
        <p>Apakah yakin ingin menghapus data-data ini?</p>
      </Modal>
      <EditableListTemplate
        breadcrumbConf={breadcrumbConf}
        tableData={tableData}
        dropdownButton={{ visible: false }}
        addButton={addButton}
        exportButton={exportButton}
        deleteButton={deleteButton}
        saveButton={saveButton}
        importButton={importButton}
        tableScroll={tableScroll}
        isLoading={isLoading}
        tableHeader={tableHeader}
        tableOnChange={tableOnChange}
        tablePagination={pagination}
        selectedRowKeys={selectionId}
        onInputRowChange={onInputRowChange}
      />
    </div>
  )
}