import Constant from "../../../../../../config/Constants"
import API from "../../../../../../services/API.service"
import createOvtConfiguration from '../../../../../../@core/interaction/overtime/CreateOvertimeConfiguration.interaction';
import updateOvtConfiguration from '../../../../../../@core/interaction/overtime/UpdateOvertimeConfiguration.interaction'
import getOvtConfiguration from '../../../../../../@core/interaction/overtime/GetOvertimetConfiguration.interaction';
export default class HrAddCompanyOvtConfiguration {

  getJob(temp={}) {
    const params:any = {
      ...temp
    }
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/job"
    return API.get(URL, {params}).then((res:any) => 
      {
        const result = res.data.content.map(element => {
          return { label: element['job_name'], value: JSON.stringify(element), key: element['id'] }
        })
        return result;
      })
  }

  getOvtConfiguration(): Promise<any> {
    return getOvtConfiguration();
  }
  

  submitOvtConfiguration(params): Promise<any> {
    if(!!params.id){
      return updateOvtConfiguration(params);
    } else {
      return createOvtConfiguration(params);
    }
  }

}
