export function missingAttendanceMapper(getResp, hrefPath: string) {
  const content = getResp.content ? getResp.content : null;
  let contentMap = null;

  if (content) {
    contentMap = content.map((data) => {
      const item = {
        id: data.id,
        key: data.key,
        employee_id: data.employee_id,
        nik: data.nik,
        name_related: data.name_related,
        job_name: data.job_name,
        sch_work: data.sch_work,
        status_absent_date: data.status_absent_date,
        desc: data.desc,
      };

      return item;
    });
  }

  return {
    content: contentMap,
    metaData: getResp.metadata,
  };
}
