export const ListApprovalLoanConstant = {
  TABLE_HEADER: [
    {
      title: 'Nomor Pengajuan',
      key: 'submission_number',
      dataIndex: 'submission_number',
      width: 180,
      editable: false,
      isCTA: true,
    },
    {
      title: 'NIK',
      key: 'nik',
      dataIndex: 'nik',
      width: 160,
      editable: false,
    },
    {
      title: 'Karyawan',
      key: 'employee',
      dataIndex: 'employee',
      width: 220,
      editable: false,
    },
    {
      title: 'Tanggal Pengajuan',
      key: 'submission_date',
      dataIndex: 'submission_date',
      width: 180,
      editable: false,
    },
    {
      title: 'Tipe Pinjaman',
      key: 'loan_type',
      dataIndex: 'loan_type',
      width: 280,
      editable: false,
    },
    {
      title: 'Jumlah Pinjaman',
      key: 'loan_total',
      dataIndex: 'loan_total',
      width: 240,
      editable: false,
    },
    {
      title: 'Keterangan',
      key: 'description',
      dataIndex: 'description',
      width: 240,
      editable: false,
    },
    {
      title: 'Dibuat Oleh',
      key: 'create_name',
      dataIndex: 'create_name',
      editable: false,
      width: 200,
    },
    {
      title: 'Dibuat Pada',
      key: 'create_date',
      dataIndex: 'create_date',
      editable: false,
      width: 160,
      isFormatDate: true,
      sorter: true,
    },
    {
      title: 'Diubah Oleh',
      key: 'write_name',
      dataIndex: 'write_name',
      editable: false,
      width: 200,
    },
    {
      title: 'Diubah Pada',
      key: 'write_date',
      dataIndex: 'write_date',
      editable: false,
      width: 160,
      isFormatDate: true,
      sorter: true,
    },
    {
      title: 'Tindakan',
      key: 'action',
      dataIndex: 'action',
      width: 140,
      editable: false,
    },
  ],
  SEARCH_COLUMN_KEY: [
    { key: 'key', componentType: 'search-input' },
    { key: 'submission_number', componentType: 'text' },
    { key: 'nik', componentType: 'text' },
    { key: 'employee', componentType: 'text' },
    { key: 'submission_date', componentType: 'date' },
  ],
  DEFAULT_EMPTY_DATA_SOURCE: [
    {
      key: 'empty-data',
      submission_number: '',
      nik: '',
      employee: '',
      submission_date: '',
      loan_type: '',
      claim_total: '',
      status: '',
      create_name: '',
      create_date: '',
      write_name: '',
      write_date: '',
    },
  ],
};
