import { useEffect, useState } from 'react';
import LandingListTemplate from '../../../../../templates/landing-list-template/LandingListTemplate';
import { useHistory } from 'react-router-dom';
import { Row } from 'antd';
import { LoanConfigConstant } from './ListLoanConfiguration.constant';
import { LandingListTemplatePagination } from '../../../../../templates/landing-list-template/LandingListTemplate.interface';
import ConfigurationLoanUseCaseImpl from './ListLoanConfiguration.usecase';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import Breadcrumb from "../../../../../components/breadcrumb/Breadcrumb";

const useCaseImpl = new ConfigurationLoanUseCaseImpl();
const localNotificationService = new LocalNotificationService();

let page = 1;
let loadPagination = true;
let sort = 'write_date|desc';
let name = '';
let loan_rule = '';
let active_when = '';
let is_active = '';
let config_type = '';
let approval_1 = '';
let approval_2 = '';
let selectionLoanId = [];

export default function HrConfigurationLoan() {
  const history = useHistory();
  const tableHeader: any = LoanConfigConstant.TABLE_HEADER;
  const searchColumnKey = LoanConfigConstant.SEARCH_COLUMN_KEY;
  const searchColumnComponent = LoanConfigConstant.SEARCH_COLUMN_COMPONENT;
  const limit = 10;

  let [pagination, setPagination] = useState<LandingListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });
  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([]);
  let [tableScroll, setTableScroll] = useState({ y: 200 });
  let [disableDeleteButton, setDisableDeleteButton] = useState(true);
  let [visibleWarningModal, setVisibleWarningModal] = useState(false);
  let [disableExportButton, setDisableExportButton] = useState(false);

  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: true, searchColumnComponent };
  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: false,
        displayPathName: 'Konfigurasi Pinjaman',
      },
    ],
  };

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {

    loadList();
    const clientHeight = document.getElementById('employee-attendance-page').clientHeight;

    setTableScroll({ y: clientHeight - 220 });
  }

  function loadList() {
    setIsLoading(true);

    const params = {
      page,
      limit,
      sort,
      name,
      loan_rule,
      active_when,
      is_active,
      config_type,
      approval_1,
      approval_2,
      with_meta: true,
    };
    useCaseImpl
      .getLoanConfigList(params)
      .then(
        (res: any) => {
          setIsLoading(false);
          const content = res?.content;
          const metaData = res?.metaData;

          const resultDataSource = (content && content.length) ? content : LoanConfigConstant.DEFAULT_EMPTY_DATA_SOURCE;
          setDataSource([...resultDataSource]);
          updatePaginationState(metaData?.total_records);

          loadPagination = true;
          setDisableExportButton(content ? false : true);
        },
        (err) => {
          setIsLoading(false);
          setDataSource([...LoanConfigConstant.DEFAULT_EMPTY_DATA_SOURCE]);
          loadPagination = true;
          setDisableExportButton(true);
        }
      )
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    const outputSort = output.sort;
    const outputSearchColumn = output.searchColumn;
    // processSort(outputSort);
    processSearchColumn(outputSearchColumn);
    processSelection(output.selection);
    processPagination(outputPagination);
  };

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  function processSearchColumn(outputSearchColumn) {
    let isLoadList = false;
    if (outputSearchColumn) {
      if (
        outputSearchColumn.name !== undefined &&
        outputSearchColumn.name !== name
      ) {
        name = outputSearchColumn.name;
        isLoadList = true;
      }

      if (
        outputSearchColumn.interest_type !== undefined &&
        outputSearchColumn.interest_type !== loan_rule
      ) {
        loan_rule = outputSearchColumn.interest_type;
        isLoadList = true;
      }
      if (
        outputSearchColumn.active_when !== undefined &&
        outputSearchColumn.active_when !== active_when
      ) {
        active_when = outputSearchColumn.active_when;
        isLoadList = true;
      }
      if (
        outputSearchColumn.is_active !== undefined &&
        outputSearchColumn.is_active !== is_active
      ) {
        is_active = outputSearchColumn.is_active;
        isLoadList = true;
      }
      if (
        outputSearchColumn.config_type !== undefined &&
        outputSearchColumn.config_type !== config_type
      ) {
        config_type = outputSearchColumn.config_type;
        isLoadList = true;
      }
      if (
        outputSearchColumn.approval_1 !== undefined &&
        outputSearchColumn.approval_1 !== approval_1
      ) {
        approval_1 = outputSearchColumn.approval_1;
        isLoadList = true;
      }
      if (
        outputSearchColumn.approval_2 !== undefined &&
        outputSearchColumn.approval_2 !== approval_2
      ) {
        approval_2 = outputSearchColumn.approval_2;
        isLoadList = true;
      }

      if (isLoadList) {
        page = 1;
        loadPagination = false;
        loadList();
      }
    }
  }


  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionLoanId) {
      selectionLoanId = outputSelection;
      setDisableDeleteButton(selectionLoanId.length ? false : true);
    }
  }

  const addButton = {
    onClick: () => {
      history.push('/app/hr-loan/configuration/new/');
    },
    disabled: isLoading,
  };

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
  }
  function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };

  async function changeStatus(e) {
    setIsLoading(true);
    const params = {
      ids: selectionLoanId,
      status: e
    }
    let message = 'Konfigurasi Pinjaman Berhasil di non-aktifkan';
    if(e === 'aktif') {
      message = 'Konfigurasi Pinjaman Berhasil di aktifkan';
    }
    useCaseImpl.deactiveLoanConfiguration(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {

          loadList();
          localNotificationService.openSuccess(message, '', 3);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }
  async function exportData() {
    setIsLoading(true);
      const params = {
        // page,
        // limit,
        // sort,
        name,
        loan_rule,
        active_when,
        is_active,
        config_type,
        approval_1,
        approval_2,
        // with_meta: true,
      };
      useCaseImpl.export(params, ).then(
        (res: any) => {
          const data = res.data;
          if (data.status === 200) {
            const file = base64ToArrayBuffer(data.content.data);
            saveByteArray(data.content.filename, file);
          }

          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
        },
      );
  }

  const exportButton = {
    onClick: () => {
      
    },
    disabled: disableExportButton || isLoading,
  };

  const dropdownButton = {
    visible: true,
    options: [
      { key: 'export', label: 'Export', onClick: () => exportData() },
      // { key: 'deactive', label: 'Non-Aktifkan', onClick: () => changeStatus(false) },
    ]
  };

  const onRow = (e) => {
    if (e.key && e.key !== 'text' && e.key !== 'empty-data') {
      history.push("/app/hr-loan/update-loan-configuration/" + e.key)
    }
  }

  

  return (
    <div id="employee-attendance-page" style={{ height: 'calc(100vh - 100px)' }}>
      <Row justify='space-between'>
        <Breadcrumb breadcrumbConf={breadcrumbConf} />
      </Row>
      <LandingListTemplate
        addButton={addButton}
        deleteButton={{visible: false}}
        exportButton={{visible: false}}
        dropdownButton={dropdownButton}
        tableHeader={tableHeader}
        tableData={tableData}
        tablePagination={pagination}
        tableOnChange={tableOnChange}
        isLoading={isLoading}
        tableScroll={tableScroll}
        onRow={onRow}
      />
    </div>
  );
}
