import { Table } from 'antd';

export const ClaimPaymentConstant = {
    TABLE_HEADER: [
      { title: 'Nomor Klaim', key: 'no_claim', dataIndex: 'no_claim', width: 120 },
      {
        title: 'NIK',
        key: 'nik',
        dataIndex: 'nik',
        width: 120,
      },
      { title: 'Karyawan', key: 'employee_name', dataIndex: 'employee_name', width: 150 },
      { title: 'Tanggal Pengajuan', key: 'date_claim', dataIndex: 'date_claim', width: 150 },
      {
        title: 'Tipe Klaim',
        key: 'claim_type',
        dataIndex: 'claim_type',
        width: 150
      },
      {
        title: 'Keterangan',
        key: 'description',
        dataIndex: 'description',
        width: 150
      },
      {
        title: 'Total Pengajuan',
        key: 'amount',
        dataIndex: 'amount',
        width: 150
      },
      {
        title: 'Disetujui Oleh',
        key: 'approved_by',
        dataIndex: 'approved_by',
        width: 150
      },
      {
        title: 'Status Pembayaran',
        key: 'state_payment',
        dataIndex: 'state_payment',
        width: 150,
      },
      Table.SELECTION_COLUMN,
      {
        title: 'Ubah Status',
        key: 'change_status',
        dataIndex: 'change_status',
        width: 120
      },
      {
        title: 'Lihat Detail',
        isAction: true,
        key: 'action',
        dataIndex: 'action',
        width: 120
      },
      
    ],
    TABLE_DETAIL_HEADER: [
      { title: 'Tanggal Mulai', key: 'date_start', dataIndex: 'date_start', width: 120 },
      { title: 'Tanggal Berakhir', key: 'date_end', dataIndex: 'date_end', width: 120 },
      { title: 'Keterangan', key: 'description', dataIndex: 'description', width: 120 },
      { title: 'Total', key: 'total', dataIndex: 'total', width: 120 },
    ],
    SEARCH_COLUMN_KEY: [],
    SEARCH_COLUMN_COMPONENT: [],
    DEFAULT_EMPTY_DATA_SOURCE: [
      {
        key: ''
      }
    ],
  };
