import { employeeOvtMapper } from '../../../../../../@core/mapper/overtime/EmployeeOvt.mapper';
import getEmployeeOvertime from '../../../../../../@core/interaction/overtime/GetEmployeeOvertimeConfig.interaction';
import exportEmployeeOvtConfiguration from '../../../../../../@core/interaction/overtime/ExportEmployeeOvtConfiguration.interaction'
import deactiveOvtConfiguration from '../../../../../../@core/interaction/overtime/DeactiveEmployeeOvtConfiguration.interaction'


export default class HrEmployeeOvtConfigurationUseCaseImpl {
  getEmployeeOvertime(params, hrefPath) {
    return getEmployeeOvertime(params).then((res: any) =>
    employeeOvtMapper(res.data, hrefPath),
    );
  }
  exportEmployeeOvtConfiguration(params) {
    return exportEmployeeOvtConfiguration(params);
  }
  deactiveOvtConfiguration(params): Promise<any> {
    return deactiveOvtConfiguration(params);
  }
}
