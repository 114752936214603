import getLeaveTypes from '../../../../../../@core/interaction/leave/GetLeaveTypes.interaction';
import { leaveTypeToObjById } from '../../../../../../@core/mapper/leave/GetLeaveTypes.mapper';
import { GetJobListDTO } from '../../../../../../@core/interface/job/GetJobList.interface';
import getJobList from '../../../../../../@core/interaction/job/GetJobList.interaction';
import { jobListToObjById } from '../../../../../../@core/mapper/job/GetJobList.mapper';
import AddEmployeeSubmissionLeaveUseCase from '../../../../../../@core/use-case/leave/AddEmployeeSubmissionLeave.usecase';
import { GetEmployeeLeaveConfigListDTO } from '../../../../../../@core/interface/leave/GetEmployeeLeaveConfigList.interface';
import getEmployeeLeaveConfigList from '../../../../../../@core/interaction/leave/GetEmployeeLeaveConfigList.interaction';
import { employeeConfigListToOptions } from '../../../../../../@core/mapper/leave/GetEmployeeConfigList.mapper';
import { GetLeaveDurationDTO } from '../../../../../../@core/interface/leave/GetLeaveDuration.interface';
import getLeaveDuration from '../../../../../../@core/interaction/leave/GetLeaveDuration.interaction';
import { UploadAllImageDTO } from '../../../../../../@core/interface/asset/UploadAllImage.interface';
import uploadAllImage from '../../../../../../@core/interaction/asset/UploadAllImage.interaction';
import createLeaveRequest from '../../../../../../@core/interaction/leave/CreateLeaveRequest.interaction';
import { EmployeeOption } from '../../../../../../@core/entity/Employee.entity';
import { GetEmployeeListDTO } from '../../../../../../@core/interface/employee/GetEmployeeList.interface';
import getEmployeeList from '../../../../../../@core/interaction/employee/GetEmployeeList.interaction';
import { employeeListToOptionsMapper } from '../../../../../../@core/mapper/employee/GetEmployeeList.mapper';
import DateService from '../../../../../../services/Date.service';
import getLeavePlafon from '../../../../../../@core/interaction/leave/GetLeavePlafon.interaction';
import { getPendingSubmissionList } from '../../../../../../@core/interaction/leave/GetPendingSubmissionList.interaction';

const dateService = new DateService();

export default class AddEmployeeSubmissionLeaveImpl implements AddEmployeeSubmissionLeaveUseCase {
  getLeaveTypes() {
    return getLeaveTypes().then((res: any) => leaveTypeToObjById(res.content));
  }

  getJobList(params: GetJobListDTO) {
    return getJobList(params).then((res: any) => jobListToObjById(res.data.content));
  }

  getEmployeeList(params: GetEmployeeListDTO): Promise<EmployeeOption[]> {
    return getEmployeeList(params).then((res: any) =>
      employeeListToOptionsMapper(res.data.content),
    );
  }

  getEmployeeLeaveConfigList(params: GetEmployeeLeaveConfigListDTO) {
    return getEmployeeLeaveConfigList(params).then((res: any) =>
      employeeConfigListToOptions(res.data.content),
    );
  }

  getLeaveDuration(params: GetLeaveDurationDTO) {
    params.date_start_leave = dateService.convertToYearFirst(params.date_start_leave, '-');
    params.date_end_leave = dateService.convertToYearFirst(params.date_end_leave, '-');
    return getLeaveDuration(params);
  }

  uploadAllImage(params: UploadAllImageDTO) {
    return uploadAllImage(params);
  }

  submitLeave(params) {
    params.date_start_leave = dateService.convertToYearFirst(
      new Date(params.date_start_leave),
      '-',
    );
    params.date_end_leave = dateService.convertToYearFirst(new Date(params.date_end_leave), '-');
    return createLeaveRequest(params);
  }

  getLeavePlafon(params) {
    return getLeavePlafon(params)
  }

  getPendingSubmissionList(params) {
    return getPendingSubmissionList(params)
  }
}
