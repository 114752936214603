import Constant from '../../config/Constants';
import API from '../../services/API.service';

export default class AdminDashboardUsecase {
  getChartEducation() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/dashboard/admin/education"
    return API.get(URL)
  }
  getChartEmployeeStatus() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/dashboard/admin/status-employee"
    return API.get(URL)
  }
  getChartAge() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/dashboard/admin/ages"
    return API.get(URL)
  }
  getChartPtkp() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/dashboard/admin/status-ptkp"
    return API.get(URL)
  }
  getChartDepartment() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/dashboard/admin/department"
    return API.get(URL)
  }
  getChartTurnOver(params) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/dashboard/admin/turn-overrate"
    return API.get(URL, { params })
  }
  getEmployeeJoin(params) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/dashboard/admin/join-employee"
    return API.get(URL, { params })
  }
  getEmployeeBirthDate() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/dashboard/admin/birthdate-employee"
    return API.get(URL)
  }
  getEmployeeOvertime(params) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/dashboard/admin/ovt-employee"
    return API.get(URL, { params })
  }
  getNotification() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/dashboard/admin/notification"
    return API.get(URL)
  }
  getEmployeeTotal() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/dashboard/admin/total-employee"
    return API.get(URL)
  }
}