import moment from 'moment';
export default class DateService {
  convertToYearFirst(date, separator: string) {
    const getFullYear = date.getFullYear();
    let getMonth = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    let getDate = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

    return getFullYear + separator + getMonth + separator + getDate;
  }

  mapToDateFirst(date, separator) {
    const getFullYear = date.getFullYear();
    let getMonth = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    let getDate = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

    return getDate + separator + getMonth + separator + getFullYear;
  }

  convertFromTimezone(date) {
    const tempJoinDate = moment(date, "YYYY-MM-DDTHH:mm:ssZ").toDate();
    return tempJoinDate
  }
}
