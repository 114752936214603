export const EmployeeListConstant = {
    TABLE_HEADER: [
      {
        title: 'Nomor Induk Karyawan',
        key: 'nik',
        dataIndex: 'nik',
        width: 180,
      },
      {
        title: 'Nama',
        key: 'name',
        dataIndex: 'name',
        width: 150,
      },
      {
        title: 'Jabatan',
        key: 'job_name',
        dataIndex: 'job_name',
        width: 180,
      },
      {
        title: 'Departement',
        key: 'department_name',
        dataIndex: 'department_name',
        width: 180,
      },
      {
        title: 'Status Karyawan',
        key: 'category',
        dataIndex: 'category',
        width: 150,
      },
      {
        title: 'Tanggal Bergabung',
        key: 'join_date',
        dataIndex: 'join_date',
        width: 150,
      },
      {
        title: 'Atasan',
        key: 'atasan',
        dataIndex: 'atasan',
        width: 150,
      },
      {
        title: 'Lokasi',
        key: 'location',
        dataIndex: 'location',
        width: 150,
      },
      { title: 'Dibuat Oleh', key: 'create_name', dataIndex: 'create_name', width: 150 },
      { title: 'Dibuat Pada', key: 'create_date', dataIndex: 'create_date', width: 150, sorter: true, },
      { title: 'Diubah Oleh', key: 'write_name', dataIndex: 'write_name', width: 150 },
      { title: 'Diubah Pada', key: 'write_date', dataIndex: 'write_date', width: 150, sorter: true, },
    ],
    SEARCH_COLUMN_KEY: [],
    SEARCH_COLUMN_COMPONENT: [
      { key: 'key', componentType: "text" },
      { key: 'nik', componentType: "text" },
      { key: 'name', componentType: "text" },
      { key: 'job_name', componentType: "text" },
      { key: 'department_name', componentType: "text" },
      { key: 'location', componentType: "text" },
      { key: 'category', componentType: "select", options: [
        { label: 'Semua', value: '' },
        { label: 'Karyawan Tetap', value: 'karyawan_tetap' },
        { label: 'Karyawan Kontrak ', value: 'karyawan_kontrak' },
        { label: 'Karyawan Magang', value: 'karyawan_magang' },
        { label: 'Karyawan Paruh Waktu ', value: 'karyawan_paruh_waktu' },
      ]},
    ],
    DEFAULT_EMPTY_DATA_SOURCE: [
      {
        key: 'text',
        name: '',
        nik: '',
        job_name: '',
        department_name: '',
        location: '',
        category: '',
      },
    ],
  }