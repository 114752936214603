import moment from "moment"
import { OptionStateType } from "../../../../../components/form-item/FormItem.interface"
import { ListLeaveReportType } from "./LeaveUsageReportMapper.interface"

export function contentToOptions(content, key): OptionStateType[] {
  const result = content.map(element => {
    return { label: element[key], value: JSON.stringify(element), key: element['id'] }
  })
  return result
}

export function listReportMapper(dataSource): ListLeaveReportType[] {
  const content = dataSource || null

  const result: ListLeaveReportType[] = content.map((data) => {
    return {
      key: data.id,
      nik: data.nik,
      employee_name: data.employee_name,
      job: data.job_name,
      leave_config: data.leave_config_name,
      expired_date: moment(data.effective_date)?.format('DD/MM/YYYY'),
      leave_plafon: data.plafon_type === "terbatas" ? data.leave_plafon : "Tidak Terbatas",
      leave_not_final: data.leave_queue,
      usage_leave: data.leave_total,
      remaining_day_off: data.plafon_type === "terbatas" ? data.leave_rem : "Tidak Terbatas",
      employee_id: data.employee_id,
      leave_type_id: data.leave_config_id,
    }
  })

  return result
}