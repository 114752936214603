import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export default function EmployeeDetail({ match }) {
  const history = useHistory();
  const [dataSource, setDataSource] = useState(null);
  const [isRefresh, setIsRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [breadcrumbConf, setBreadcrumbConf] = useState({
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Master Jabatan',
        href: '/v2/app/hr-employee/master-job',
      },
      { clickable: false, displayPathName: '' },
    ],
  });

  return (
    <div
      style={{ position: 'relative', height: '100vh' }}
    >
    </div>
  );
}
