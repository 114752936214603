import { Button, Checkbox, Col, Modal, Select, Space, Table } from 'antd';
import { useState } from 'react';

export const SelectedEmployees = ({ value, onChange, disabled }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const values = value?.dataSource.map(e => `${e.nik} - ${e.employee}`)?.splice(0, 10)

  function proccesDelete(id) {
    const data = value.dataSource.filter((e) => e.id !== id)
    onChange({ ...value, dataSource: data })
  }

  function onClickDelete(label) {
    const data = value.dataSource.filter((e) => `${e.nik} - ${e.employee}` !== label)
    onChange({ ...value, dataSource: data })
  }

  return (
    <Col>
      <Modal 
        visible={visibleModal} 
        onCancel={() => setVisibleModal(false)}
        footer={null}
      >
        <Table
          pagination={{ 
            pageSizeOptions: ["5", "10", "15", "20"], 
            showSizeChanger:true, 
            defaultPageSize: 5
          }}
          dataSource={value?.dataSource}
          columns={[
            {
              key: 'nik',
              dataIndex: 'nik',
              title: 'NIK',
            },
            {
              key: 'employee',
              dataIndex: 'employee',
              title: 'Karyawan',
            },
            {
              key: 'action',
              dataIndex: 'action',
              title: 'Action',
              render: (_, record) => {
                return <Button type='primary' disabled={disabled} onClick={() => proccesDelete(record.id)}>Hapus</Button>;
              },
            },
          ]}
        />
      </Modal>
      <Select
        showSearch={true}
        defaultValue={values}
        value={values}
        mode="multiple"
        options={[]}
        onDeselect={(e) => onClickDelete(e)}
        showArrow={false}
        disabled={disabled}
        dropdownStyle={{ display: 'none' }}
      />
      <Space className="toolbar" direction="horizontal" align="baseline">
        <div>
          <Checkbox
            disabled={disabled}
            defaultChecked={value?.checkbox}
            checked={value?.checkbox}
            onChange={(e) => onChange({ ...value, checkbox: e.target.checked })}
          />{' '}
          Ubah
        </div>
        <a onClick={() => setVisibleModal(true)}>Tampilkan Semua</a>
      </Space>
    </Col>
  );
};
