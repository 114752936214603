import { CustomStepsComponentType } from './CustomStepsComponent.interface';
import './ComponentStyles.less';

export default function CustomStepsComponent(props: CustomStepsComponentType) {
  return (
    <div
      id="step-container"
      className="ant-steps ant-steps-horizontal ant-steps-label-vertical ant-steps-dot"
    >
      {props.stepsState?.map((step, index) => {
        return (
          <div className={`ant-steps-item ant-steps-item-${step.status} step-index-${index+1}`}>
            <div className="ant-steps-item-container">
              <div className="ant-steps-item-tail"></div>
              <div className="ant-steps-item-icon">
                <span className="ant-steps-icon">
                  <span className="ant-steps-icon-dot"></span>
                </span>
              </div>
              <div className="ant-steps-item-content">
                <div className="ant-steps-item-title">{step.title}</div>
                <div className="ant-steps-item-description">{step.description}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
