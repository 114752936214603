import Constant from "../../../../../config/Constants";
import API from "../../../../../services/API.service";

export default class ListLoanInstallmentReportUseCase {
  getAll(params) {
    const URL = Constant.URL_BASE_LOAN + "/v3/report/installment"
    return API.get(URL, { params })
  }

  export(params) {
    const URL =  Constant.URL_BASE_LOAN + "/v3/report/installment/export"
    return API.get(URL,{ params })
  }
}