import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Radio, Space, InputNumber, Modal, Tag, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import FormBuilder from 'antd-form-builder';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import PageLoader from '../../../../../components/loader/PageLoader';
import { useHistory } from 'react-router-dom';
import Constant from '../../../../../config/Constants';
import API from '../../../../../services/API.service';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import UpdateClaimConfig from './UpdateClaimConfiguration.usecase';

const useCaseImpl = new UpdateClaimConfig();
const localNotificationService = new LocalNotificationService();
let claimId = '';
export default function HrUpdateonfigurationClaim({ match }) {
  const history = useHistory();
  claimId = match.params.claimId;
  const [form] = Form.useForm();
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [employeeList, setEmployeeList] = useState([]);
  const [jobListOption, setJobListOption] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [initialData, setInitialData] = useState<any>({});
  const [showModalJob, setShowModalJob] = useState<any>(false);
  const [showModalEmployee, setShowModalEmployee] = useState<any>(false);
  const [modalLoading, setModalLoading] = useState(true);
  const [monthJoin, setMonthJoin] = useState<any>();
  const [claimIntensity, setClaimIntensity] = useState<any>();
  const [monthIntensity, setMonthIntensity] = useState<any>();
  const [claimRel, setClaimRel] = useState([]);
  const [totalRel, setTotalRel] = useState<any>(0);
  const [page, setPage] = useState(1);
  const [visibleModalProcess, setVisibleModalProcess] = useState(false);

  const [breadcrumbConf, setBreadcrumbConf] = useState<any>({
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Konfigurasi Klaim',
        href: '/v2/app/hr-claim/configuration/list-configuration',
      },
      { clickable: false, displayPathName: '' },
    ],
  });

  const ActiveWhen = ({ value, onChange }) => {
    return (
      <Radio.Group onChange={onChange} value={value}>
        <Space direction="vertical">
          <Radio value={'mulai_bergabung'}>Mulai Bergabung</Radio>
          <Radio value={'bergabung_dibulan'}>
            Bergabung di Bulan ke
            {value === 'bergabung_dibulan' ? (
              <InputNumber
                min={1}
                defaultValue={monthJoin}
                onChange={(e) => setMonthJoin(e)}
                style={{ width: 50, marginLeft: 10 }}
              />
            ) : null}
          </Radio>
        </Space>
      </Radio.Group>
    );
  };
  const IntensityComp = ({ value, onChange }) => {
    return (
      <Space direction="horizontal" size={3}>
        <Form.Item
          style={{ marginBottom: 0 }}
          label={null}
          name="claim_intensity"
          rules={[{ required: true, message: 'Please input!' }]}
        >
          <InputNumber
            min={1}
            defaultValue={claimIntensity}
            onChange={(e) => setClaimIntensity(e)}
            style={{ width: 60 }}
          />
        </Form.Item>
        Klaim Dalam
        <Form.Item
          style={{ marginBottom: 0 }}
          label={null}
          name="month_intensity"
          rules={[
            { required: true, message: 'Please input!' },
            {
              validator: (rule, value, callback) => {
                return new Promise((resolve, reject) => {
                  if (value && value > 12) {
                    reject(new Error('Tidak boleh lebih dari 12'));
                  } else {
                    resolve(value);
                  }
                });
              },
            },
          ]}
        >
          <InputNumber
            min={1}
            defaultValue={monthIntensity}
            onChange={(e) => setMonthIntensity(e)}
            onBlur={() => {
              onChange = forceUpdate;
            }}
            onStep={() => {
              onChange = forceUpdate();
            }}
            style={{ width: 60 }}
          />
        </Form.Item>
        Bulan Terakhir
      </Space>
    );
  };

  const JOB_TABLE_HEADER = [
    {
      title: 'Jabatan',
      dataIndex: 'name',
    },
    {
      title: 'Departement',
      dataIndex: 'dept_name',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (value, result) => {
        return (
          <DeleteOutlined
            onClick={(e) => {
              e.preventDefault();
              const param: any = {
                ids: [result.id],
                config_type: 'job',
              };
              const URL = Constant.URL_BASE_CLAIM + `/v3/configuration/claim/${claimId}/rel`;
              return API.delete(URL, { data: param }).then((res: any) => {
                setModalLoading(true);
                initialPage();
              });
            }}
          />
        );
      },
    },
  ];

  const EMPLOYEE_TABLE_HEADER = [
    {
      title: 'NIK',
      dataIndex: 'nik',
    },
    {
      title: 'Nama',
      dataIndex: 'name',
    },
    {
      title: 'Jabatan',
      dataIndex: 'job_name',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (value, result) => {
        return (
          <DeleteOutlined
            onClick={(e) => {
              e.preventDefault();
              const param: any = {
                ids: [result.id],
                config_type: 'employee',
              };
              const URL = Constant.URL_BASE_CLAIM + `/v3/configuration/claim/${claimId}/rel`;
              return API.delete(URL, { data: param }).then((res: any) => {
                setModalLoading(true);
                initialPage();
              });
            }}
          />
        );
      },
    },
  ];

  const meta: any = {
    columns: 1,
    fields: [
      {
        key: 'name',
        label: 'Nama Klaim',
        rules: [{ required: true, whitespace: true, message: 'Nama Klaim harus diisi' }],
      },
      {
        key: 'plafon_type',
        label: 'Tipe Plafon',
        required: true,
        disabled: true,
        message: 'Tipe Plafon harus diisi',
        widget: 'select',
        options: [
          { value: 'terbatas', label: 'Terbatas' },
          { value: 'tidak_terbatas', label: 'Tidak Terbatas' },
        ],
      },
      {
        key: 'plafon_value',
        label: 'Nilai Plafon',
        widget: 'number',
        required: true,
        message: 'Nilai Plafon harus diisi',
        widgetProps: {
          style: { width: '50%' },
          formatter: (value) => `${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ','),
          min: 1,
        },
      },
      {
        key: 'limit_type',
        label: 'Limit per Klaim',
        required: true,
        message: 'Limit per Klaim harus diisi',
        widget: 'select',
        options: [
          { value: 'terbatas', label: 'Terbatas' },
          { value: 'tidak_terbatas', label: 'Tidak Terbatas' },
        ],
      },
      {
        key: 'effective_date',
        label: 'Tanggal Efektif',
        required: true,
        disabled: true,
        message: 'Tanggal Efektif harus diisi',
        widget: 'date-picker',
      },
      {
        key: 'reset_period',
        label: 'Periode Reset',
        required: true,
        message: 'Periode Reset harus diisi',
        widget: 'select',
        options: [
          { value: 'tahunan', label: 'Tahunan' },
          { value: '6bulanan', label: '6 Bulanan' },
          { value: '3bulanan', label: '3 Bulanan' },
          { value: 'bulanan', label: 'Bulanan' },
        ],
      },
      {
        key: 'is_proof_required',
        label: 'Membutuhkan Bukti',
        widget: 'checkbox',
      },
      {
        key: 'is_intensity_limit',
        label: 'Batasan Insentitas',
        widget: 'checkbox',
      },
      {
        key: 'active_when',
        label: 'Aktif Ketika',
        forwardRef: true,
        widget: ActiveWhen,
        options: [
          { value: 'mulai_bergabung', label: 'Mulai Bergabung' },
          { value: 'bergabung_dibulan', label: 'Bergabung di Bulan ke' },
        ],
        initialValue: 'mulai_bergabung',
      },
      {
        key: 'config_type',
        label: 'Berlaku Untuk',
        widget: 'radio-group',
        forwardRef: true,
        disabled: true,
        options: [
          { value: 'job', label: 'Jabatan' },
          { value: 'employee', label: 'Karyawan' },
        ],
        initialValue: 'job',
      },
      {
        key: 'current_job',
        label: 'Jabatan yang sudah dipilih',
        readOnly: true,
        viewWidget: (job) => {
          const list_job =
            job.value &&
            job.value
              .map((item) => JSON.parse(item))
              .map(function (row) {
                return {
                  job_id: row.id,
                  name: row.job_name,
                  dept_name: row.dept_name,
                  dept_id: row.dept_id,
                };
              });
          return (
            <Space direction="vertical" style={{ width: '70%' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {list_job &&
                  list_job.map((item, i) => {
                    return (
                      <div style={{ marginBottom: 10 }} key={i}>
                        <Tag color="red">
                          <span key={item.id}>{item.name}</span>
                        </Tag>
                      </div>
                    );
                  })}
              </div>
              <div>
                <Button type="link" onClick={() => setShowModalJob(true)}>
                  Tampilkan Semua
                </Button>
              </div>
            </Space>
          );
        },
      },
      {
        key: 'job',
        label: 'Jabatan',
        // required: true,
        message: 'Jabatan harus diisi',
        widget: 'select',
        options: jobListOption,
        widgetProps: {
          mode: 'multiple',
        },
      },
      {
        key: 'approval_1',
        label: 'Persetujuan 1',
        required: true,
        message: 'Persetujuan 1 harus diisi',
        widget: 'select',
        options: [
          { value: 'tanpa_persetujuan', label: 'Tanpa Persetujuan' },
          { value: 'atasan_langsung', label: 'Atasan Langsung' },
          { value: 'jabatan', label: 'Jabatan' },
        ],
      },
    ],
  };

  useEffect(() => {
    initialPage();
  }, []);

  useEffect(() => {
    getClaimRel();
  }, [initialData]);

  async function getClaimRel(current = 1) {
    setPage(current);
    let params = {
      claimId: claimId,
      page: current,
      limit: 10,
      config_type: initialData?.config_type,
      with_meta: true,
    };
    const tempRel = await useCaseImpl.getClaimRel(params);

    if (!!tempRel) {
      setClaimRel(tempRel.content);
      setTotalRel(tempRel.metadata.total_records);
    }
    setModalLoading(false);
  }

  async function initialPage() {
    setIsLoading(true);
    useCaseImpl.getCheckProcess(claimId).then(
      (res: any) => {
        const data = res.data;
        if (data?.content) {
          if (data?.content?.code === 200) {
            localNotificationService.openSuccess(data?.content?.cause, '', 3);            
          } else {
            setVisibleModalProcess(true);
            setTimeout(() => {
              history.push('/app/hr-claim/configuration/list-configuration');
            }, 2000);
          }
        }
      }
    );
    const params = {
      limit: 300,
      sort: 'id|asc',
      page: 1,
    };
    const getJobOption = await useCaseImpl.getJob();
    const employeeList = await useCaseImpl.getEmpl();
    setJobListOption(getJobOption);
    setEmployeeList(employeeList);

    const claimDetail: any = await useCaseImpl.getClaimConfigurationDetail(claimId);
    let inital_job: any = [];
    let inital_employee = [];
    if (claimDetail.view_list_job) {
      inital_job = getJobOption.reduce((acc, val, i) => {
        if (claimDetail.view_list_job.map((item) => item.job_id).includes(val.key)) {
          acc.push(val.value);
        }

        return acc;
      }, []);
    }
    if (claimDetail.view_list_emp) {
      inital_employee = employeeList.reduce((acc, val, i) => {
        if (claimDetail.view_list_emp.map((item) => item.employee_id).includes(val.key)) {
          acc.push(val.value);
        }
        return acc;
      }, []);
    }

    let initialJobApproval_1;
    let initialJobApproval_2;
    if (claimDetail.approval_1) {
      initialJobApproval_1 = await useCaseImpl.getJob({ job_id: claimDetail.job_approval_1 });
    }
    if (claimDetail.approval_2) {
      initialJobApproval_2 = await useCaseImpl.getJob({ job_id: [claimDetail.job_approval_2] });
    }
    let temp_reset;
    if (claimDetail.plafon_reset === 'bulanan') {
      claimDetail.plafon_reset_count == '1'
        ? (temp_reset = 'bulanan')
        : (temp_reset = claimDetail.plafon_reset_count + 'bulanan');
    } else {
      temp_reset = 'tahunan';
    }
    let tempInitial = {
      name: claimDetail.name,
      plafon_type: claimDetail.plafon_type,
      plafon_value: claimDetail.plafon_value,
      limit_type: claimDetail.limit_type,
      limit_value: claimDetail.limit_claim,
      effective_date: moment(claimDetail.effective_date),
      reset_period: temp_reset,
      active_when: claimDetail.active_when,
      config_type: claimDetail.config_type,
      approval_1: claimDetail.approval_1,
      approval_2: claimDetail.approval_2,
      job_approval1: initialJobApproval_1 && initialJobApproval_1[0]?.value,
      job_approval2: initialJobApproval_2 && initialJobApproval_2[0]?.value,
      view_list_emp: claimDetail.view_list_emp,
      view_list_job: claimDetail.view_list_job,
      current_job: inital_job,
      current_employee: inital_employee,
      is_proof_required: claimDetail.is_proof_required,
      is_intensity_limit: claimDetail.is_intensity_limit,
      claim_intensity: claimDetail.every_claim,
      month_intensity: claimDetail.every_month,
    };
    if (claimDetail.is_intensity_limit) {
      setClaimIntensity(claimDetail.every_claim);
      setMonthIntensity(claimDetail.every_month);
    }

    const tempBreadcrumbConf = JSON.parse(JSON.stringify(breadcrumbConf));
    tempBreadcrumbConf.manualData[1].displayPathName = claimDetail?.name;
    setBreadcrumbConf(tempBreadcrumbConf);
    setMonthJoin(claimDetail.month_join);
    setInitialData(tempInitial);
    setIsLoading(false);
    setModalLoading(false);
  }

  async function onFinish(e) {
    setIsLoading(true);
    const list_job =
      e.job &&
      e.job
        .map((item) => JSON.parse(item))
        .map(function (row) {
          return {
            job_id: row.id,
            name: row.job_name,
            dept_name: row.dept_name,
            dept_id: row.dept_id,
          };
        });
    const list_employee =
      e.employee &&
      e.employee
        .map((item) => JSON.parse(item))
        .map(function (row) {
          return {
            employee_id: row.id,
            nik: row.nik,
            name: row.name,
            alternative_name: row.alternative_name,
            job_id: row.job_id,
            job_name: row.job_name,
            dept_id: row.dept_id,
            dept_name: row.dept_name,
          };
        });
    let tempParam: any = {};
    e.job_approval1 && (tempParam['job_approval_1'] = JSON.parse(e.job_approval1)?.id);
    e.job_approval2 && (tempParam['job_approval_2'] = JSON.parse(e.job_approval2)?.id);
    e.employee && (tempParam['list_emp'] = list_employee);
    e.job && (tempParam['list_job'] = list_job);
    let plafon_reset;
    let plafon_reset_count;
    switch (e.reset_period) {
      case 'bulanan':
        plafon_reset = 'bulanan';
        plafon_reset_count = 1;
        break;
      case '3bulanan':
        plafon_reset = 'bulanan';
        plafon_reset_count = 3;
        break;
      case '6bulanan':
        plafon_reset = 'bulanan';
        plafon_reset_count = 6;
        break;
      case 'tahunan':
        plafon_reset = 'tahunan';
        plafon_reset_count = 1;
        break;
      default:
        break;
    }

    const params = {
      id: claimId,
      name: e.name.trim(),
      plafon_type: e.plafon_type,
      plafon_value: e.plafon_value && e.plafon_value.toString(),
      plafon_reset: plafon_reset,
      plafon_reset_count: plafon_reset_count && plafon_reset_count.toString(),
      limit_type: e.limit_type && e.limit_type.toString(),
      limit_claim: e.limit_value && e.limit_value.toString(),
      effective_date: moment(e.effective_date).format('YYYY-MM-DD'),
      is_proof_required: e.is_proof_required,
      is_intensity_limit: e.is_intensity_limit,
      every_claim: claimIntensity ? claimIntensity.toString() : '',
      every_month: monthIntensity ? monthIntensity.toString() : '',
      config_type: e.config_type,
      active_when: e.active_when,
      month_join: monthJoin ? monthJoin.toString() : '0',
      approval_1: e.approval_1,
      approval_2: e.approval_2,
      is_2nd_appr: e.approval_2 && e.approval_2 !== 'tanpa_persetujuan' ? true : false,
      view_list_emp: initialData.view_list_emp,
      view_list_job: initialData.view_list_job,
      ...tempParam,
    };

    useCaseImpl.submitClaimConfiguration(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          history.push('/app/hr-claim/configuration/list-configuration');
          localNotificationService.openSuccess('Konfigurasi Klaim Berhasil Diubah', '', 3);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }
  let newMeta = meta;
  if (form.getFieldValue('plafon_type')) {
    if (form.getFieldValue('plafon_type') === 'tidak_terbatas') {
      const objIndex = newMeta.fields.findIndex((obj) => obj.key === 'plafon_value');
      newMeta.fields[objIndex] = {
        key: 'hide_plafon_value',
        render: () => {
          return null;
        },
      };
    }
  }
  if (form.getFieldValue('limit_type')) {
    if (form.getFieldValue('limit_type') === 'terbatas') {
      const objIndex = newMeta.fields.findIndex((obj) => obj.key === 'limit_type');
      newMeta.fields.splice(objIndex + 1, 0, {
        key: 'limit_value',
        label: 'Jumlah Limit per Klaim',
        widget: 'number',
        required: true,
        message: 'Nilai Plafon harus diisi',
        widgetProps: {
          style: { width: '50%' },
          formatter: (value) => `${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ','),
          min: 1,
        },
      });
    }
  } else {
    if (initialData?.limit_type === 'terbatas') {
      const objIndex = newMeta.fields.findIndex((obj) => obj.key === 'limit_type');
      newMeta.fields.splice(objIndex + 1, 0, {
        key: 'limit_value',
        label: 'Jumlah Limit per Klaim',
        widget: 'number',
        required: true,
        message: 'Nilai Plafon harus diisi',
        widgetProps: {
          style: { width: '50%' },
          formatter: (value) => `${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ','),
          min: 1,
        },
      });
    }
  }
  if (form.getFieldValue('is_intensity_limit')) {
    if (!!form.getFieldValue('is_intensity_limit')) {
      const objIndex = newMeta.fields.findIndex((obj) => obj.key === 'is_intensity_limit');
      newMeta.fields.splice(objIndex + 1, 0, {
        key: 'intensity',
        label: 'Hanya Diperbolehkan',
        forwardRef: true,
        widget: IntensityComp,
      });
    }
  }

  if (form.getFieldValue('config_type')) {
    if (form.getFieldValue('config_type') === 'employee') {
      const objIndex = newMeta.fields.findIndex((obj) => obj.key === 'job');
      const objIndexCurrent = newMeta.fields.findIndex((obj) => obj.key === 'current_job');
      newMeta.fields[objIndex] = {
        key: 'employee',
        label: 'Karyawan',
        message: 'Karyawan harus diisi',
        widget: 'select',
        options: employeeList,
        widgetProps: {
          mode: 'multiple',
        },
      };
      newMeta.fields[objIndexCurrent] = {
        key: 'current_employee',
        label: 'Karyawan yang sudah dipilih',
        readOnly: true,
        viewWidget: (employee) => {
          const list_employee =
            employee.value &&
            employee.value
              .map((item) => JSON.parse(item))
              .map(function (row) {
                return {
                  key: row.id,
                  id: row.id,
                  name: row.name,
                  alternative_name: row.alternative_name,
                };
              });
          return (
            <Space direction="vertical" style={{ width: '70%' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {list_employee &&
                  list_employee.map((item, i) => {
                    return (
                      <div style={{ marginBottom: 10 }} key={i}>
                        <Tag color="red">
                          <span key={item.id}>{item.name}</span>
                        </Tag>
                      </div>
                    );
                  })}
              </div>
              <div>
                <Button type="link" onClick={() => setShowModalEmployee(true)}>
                  Tampilkan Semua
                </Button>
              </div>
            </Space>
          );
        },
      };
    } else if (form.getFieldValue('config_type') === 'job') {
      const objIndex = newMeta.fields.findIndex((obj) => obj.key === 'employee');
      newMeta.fields[objIndex] = {
        key: 'job',
        label: 'Jabatan',
        required: true,
        message: 'Jabatan harus diisi',
        widget: 'select',
        options: jobListOption,
        widgetProps: {
          mode: 'multiple',
        },
      };
    }
  } else {
    if (initialData?.config_type === 'employee') {
      const objIndex = newMeta.fields.findIndex((obj) => obj.key === 'job');
      const objIndexCurrent = newMeta.fields.findIndex((obj) => obj.key === 'current_job');
      newMeta.fields[objIndex] = {
        key: 'employee',
        label: 'Karyawan',
        message: 'Karyawan harus diisi',
        widget: 'select',
        options: employeeList,
        widgetProps: {
          mode: 'multiple',
        },
      };

      newMeta.fields[objIndexCurrent] = {
        key: 'current_employee',
        label: 'Karyawan yang sudah dipilih',
        readOnly: true,
        viewWidget: (employee) => {
          const list_employee =
            employee.value &&
            employee.value
              .map((item) => JSON.parse(item))
              .map(function (row) {
                return {
                  key: row.id,
                  id: row.id,
                  name: row.name,
                  alternative_name: row.alternative_name,
                };
              });
          return (
            <Space direction="vertical" style={{ width: '70%' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {list_employee &&
                  list_employee.map((item, i) => {
                    return (
                      <div style={{ marginBottom: 10 }} key={i}>
                        <Tag color="red">
                          <span key={item.id}>{item.name}</span>
                        </Tag>
                      </div>
                    );
                  })}
              </div>
              <div>
                <Button type="link" onClick={() => setShowModalEmployee(true)}>
                  Tampilkan Semua
                </Button>
              </div>
            </Space>
          );
        },
      };
    }
  }
  if (form.getFieldValue('approval_1')) {
    if (form.getFieldValue('approval_1') === 'jabatan') {
      newMeta.fields[newMeta.fields.length + 1] = {
        key: 'job_approval1',
        label: 'Nama Jabatan',
        widget: 'select',
        required: true,
        options: jobListOption,
        widgetProps: {
          showSearch: true,
        },
      };
      newMeta.fields[newMeta.fields.length + 2] = {
        key: 'approval_2',
        label: 'Persetujuan 2',
        required: true,
        widget: 'select',
        options: [
          { value: 'tanpa_persetujuan', label: 'Tanpa Persetujuan' },
          { value: 'atasan_langsung', label: 'Atasan Langsung' },
          { value: 'jabatan', label: 'Jabatan' },
        ],
      };
    }
    if (form.getFieldValue('approval_1') === 'atasan_langsung') {
      newMeta.fields[newMeta.fields.length + 1] = {
        key: 'approval_2',
        label: 'Persetujuan 2',
        required: true,
        widget: 'select',
        options: [
          { value: 'tanpa_persetujuan', label: 'Tanpa Persetujuan' },
          { value: 'atasan_langsung', label: 'Atasan Langsung' },
          { value: 'jabatan', label: 'Jabatan' },
        ],
      };
    }
  } else {
    if (initialData?.approval_1 === 'jabatan') {
      newMeta.fields[newMeta.fields.length + 1] = {
        key: 'job_approval1',
        label: 'Nama Jabatan',
        widget: 'select',
        required: true,
        options: jobListOption,
        widgetProps: {
          showSearch: true,
        },
      };
      newMeta.fields[newMeta.fields.length + 2] = {
        key: 'approval_2',
        label: 'Persetujuan 2',
        required: true,
        widget: 'select',
        options: [
          { value: 'tanpa_persetujuan', label: 'Tanpa Persetujuan' },
          { value: 'atasan_langsung', label: 'Atasan Langsung' },
          { value: 'jabatan', label: 'Jabatan' },
        ],
      };
    }
    if (initialData?.approval_1 === 'atasan_langsung') {
      newMeta.fields[newMeta.fields.length + 1] = {
        key: 'approval_2',
        label: 'Persetujuan 2',
        required: true,
        widget: 'select',
        options: [
          { value: 'tanpa_persetujuan', label: 'Tanpa Persetujuan' },
          { value: 'atasan_langsung', label: 'Atasan Langsung' },
          { value: 'jabatan', label: 'Jabatan' },
        ],
      };
    }
  }
  if (form.getFieldValue('approval_2')) {
    if (
      form.getFieldValue('approval_2') === 'jabatan' &&
      form.getFieldValue('approval_1') !== 'tanpa_persetujuan' &&
      form.getFieldValue('approval_2') !== 'tanpa_persetujuan'
    ) {
      newMeta.fields[newMeta.fields.length + 1] = {
        key: 'job_approval2',
        label: 'Nama Jabatan',
        widget: 'select',
        required: true,
        options: jobListOption,
        widgetProps: {
          showSearch: true,
        },
      };
    }
  } else {
    if (initialData?.approval_2 === 'jabatan') {
      newMeta.fields[newMeta.fields.length + 1] = {
        key: 'job_approval2',
        label: 'Nama Jabatan',
        widget: 'select',
        required: true,
        options: jobListOption,
        widgetProps: {
          showSearch: true,
        },
      };
    }
  }

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div
      className="update-claim-configuration-page"
      style={{ position: 'relative', height: 'calc(100vh - 100px)' }}
    >
      <Form
        form={form}
        initialValues={initialData}
        layout="horizontal"
        onFinish={onFinish}
        onValuesChange={forceUpdate}
      >
        <div>
          <div className="form-wrapper">
          {
        visibleModalProcess && (
          <Modal
            footer={null}
            visible={visibleModalProcess}
          >
            <p style={{lineHeight: 'inherit'}}>
              Silahkan tunggu beberapa saat, tipe claim untuk masing - masing karyawan
              sedang dalam proses pembentukan
            </p>
          </Modal>
        )
      }
            <div>
              <Breadcrumb breadcrumbConf={breadcrumbConf} />
              <Modal
                visible={showModalEmployee}
                onCancel={() => setShowModalEmployee(false)}
                footer={null}
              >
                <div>
                  <Table
                    loading={modalLoading}
                    columns={EMPLOYEE_TABLE_HEADER}
                    dataSource={claimRel}
                    pagination={{
                      showSizeChanger: false,
                      onChange: (page: number) => getClaimRel(page),
                      current: page,
                      total: totalRel,
                    }}
                  />
                </div>
              </Modal>

              <Modal visible={showModalJob} onCancel={() => setShowModalJob(false)} footer={null}>
                <div>
                  <Table
                    loading={modalLoading}
                    columns={JOB_TABLE_HEADER}
                    dataSource={claimRel}
                    pagination={{
                      showSizeChanger: false,
                      onChange: (page: number) => getClaimRel(page),
                      current: page,
                      total: totalRel,
                    }}
                  />
                </div>
              </Modal>
              <Form.Item className="form-footer" style={{ width: '100%', marginTop: 20 }}>
                <Button type="primary" onClick={() => form.submit()} disabled={isLoading}>
                  Simpan
                </Button>
                &nbsp; &nbsp;
                <Button
                  onClick={() => history.push('/app/hr-claim/configuration/list-configuration')}
                >
                  Batal
                </Button>
              </Form.Item>
              <div
                style={{
                  border: '1px solid #000',
                  padding: 10,
                  overflowY: 'scroll',
                  height: '70vh',
                }}
              >
                <FormBuilder disabled={false} form={form} meta={meta} />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
