import { useEffect, useState } from 'react';
import LandingListTemplate from '../../../../../../templates/landing-list-template/LandingListTemplate';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import { OvertimeConfigConstant } from '../../../../../../config/Overtime.constant';
import { LandingListTemplatePagination } from '../../../../../../templates/landing-list-template/LandingListTemplate.interface';
import EmployeeOvtConfigurationUseCaseImpl from './HrEmployeeOvtConfiguration.usecase.impl';
import LocalNotificationService from '../../../../../../services/LocalNotification.service';

const useCaseImpl = new EmployeeOvtConfigurationUseCaseImpl();
const localNotificationService = new LocalNotificationService();

let page = 1;
let loadPagination = true;
let sort = 'write_date|desc';
let name = '';
let ovt_type: '';
let ovt_type_rel = '';
let status = '';
let selectionOvtId = [];

export default function HrEmployeeOvtConfiguration() {
  const history = useHistory();
  const tableHeader: any = OvertimeConfigConstant.TABLE_HEADER;
  const searchColumnKey = OvertimeConfigConstant.SEARCH_COLUMN_KEY;
  const searchColumnComponent = OvertimeConfigConstant.SEARCH_COLUMN_COMPONENT;
  const limit = 10;

  let [pagination, setPagination] = useState<LandingListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });
  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([]);
  let [tableScroll, setTableScroll] = useState({ y: 200 });
  let [disableDeleteButton, setDisableDeleteButton] = useState(true);
  let [visibleWarningModal, setVisibleWarningModal] = useState(false);
  let [disableExportButton, setDisableExportButton] = useState(false);

  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: true, searchColumnComponent };

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {

    loadList();
    const clientHeight = document.getElementById('employee-attendance-page').clientHeight;

    setTableScroll({ y: clientHeight - 220 });
  }

  function loadList() {
    setIsLoading(true);

    const params = {
      page,
      limit,
      sort,
      name,
      ovt_type,
      ovt_type_rel,
      status,
      with_meta: true,
    };
    useCaseImpl
      .getEmployeeOvertime(params, '/v2/app/hr-overtime/employee-overtime-configuration/update/')
      .then(
        (res: any) => {
          setIsLoading(false);
          const content = res.content;
          const metaData = res.metaData;
          const resultDataSource = (content && content.length) ? content : OvertimeConfigConstant.DEFAULT_EMPTY_DATA_SOURCE;
          setDataSource([...resultDataSource]);
          updatePaginationState(metaData.total_records);

          loadPagination = true;
          setDisableExportButton(content ? false : true);
        },
        (err) => {
          setIsLoading(false);
          setDataSource([...OvertimeConfigConstant.DEFAULT_EMPTY_DATA_SOURCE]);
          loadPagination = true;
          setDisableExportButton(true);
        }
      )
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    const outputSort = output.sort;
    const outputSearchColumn = output.searchColumn;
    
    // processSort(outputSort);
    processSearchColumn(outputSearchColumn);
    processSelection(output.selection);
    processPagination(outputPagination);
  };

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  function processSearchColumn(outputSearchColumn) {
    let isLoadList = false;
    if (outputSearchColumn) {
      if (
        outputSearchColumn.name !== undefined &&
        outputSearchColumn.name !== name
      ) {
        name = outputSearchColumn.name;
        isLoadList = true;
      }
      if (
        outputSearchColumn.ovt_type !== undefined &&
        outputSearchColumn.ovt_type !== ovt_type
      ) {
        ovt_type = outputSearchColumn.ovt_type;
        isLoadList = true;
      }
      if (
        outputSearchColumn.ovt_type_rel !== undefined &&
        outputSearchColumn.ovt_type_rel !== ovt_type_rel
      ) {
        ovt_type_rel = outputSearchColumn.ovt_type_rel;
        isLoadList = true;
      }
      if (
        outputSearchColumn.status !== undefined &&
        outputSearchColumn.status !== status
      ) {
        status = outputSearchColumn.status;
        isLoadList = true;
      }

      if (isLoadList) {
        page = 1;
        loadPagination = false;
        loadList();
      }
    }
  }

  async function changeStatus(e) {
    setIsLoading(true);
    const params = {
      ids: selectionOvtId,
      status: e
    }
    let message = 'Konfigurasi Lembur Berhasil di non-aktifkan';
    if(e === 'aktif') {
      message = 'Konfigurasi Lembur Berhasil di aktifkan';
    }
    useCaseImpl.deactiveOvtConfiguration(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {

          loadList();
          localNotificationService.openSuccess(message, '', 3);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }

  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionOvtId) {
      selectionOvtId = outputSelection;
      setDisableDeleteButton(selectionOvtId.length ? false : true);
    }
  }

  const addButton = {
    onClick: () => {
      history.push('/app/hr-overtime/employee-overtime-configuration/new/');
    },
    disabled: isLoading,
  };

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
  }
  function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };

  const exportButton = {
    onClick: () => {
      setIsLoading(true);
      const params = {
        page,
        limit,
        sort,
        name,
        with_meta: true,
      };
      useCaseImpl.exportEmployeeOvtConfiguration(params, ).then(
        (res: any) => {
          const data = res.data;
          if (data.status === 200) {
            const file = base64ToArrayBuffer(data.content.data);
            saveByteArray(data.content.filename, file);
          }

          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
        },
      );
    },
    disabled: disableExportButton || isLoading,
  };

  const dropdownButton = {
    visible: true,
    disabled: selectionOvtId.length < 1,
    options: [
      { key: 'non-active', label: 'Non-Aktifkan', onClick: () => changeStatus('tidak aktif') },
      // { key: 'active', label: 'Aktifkan', onClick: () => changeStatus('aktif') }
    ]
  };
  

  return (
    <div id="employee-attendance-page" style={{ height: 'calc(100vh - 100px)' }}>
      <Modal
        title="Hapus Data"
        cancelText="BATALKAN"
        visible={visibleWarningModal}
        cancelButtonProps={{ type: 'ghost' }}
        // onOk={deleteToEndPoint}
        onCancel={() => setVisibleWarningModal(false)}
      >
        <p>Apakah yakin ingin menghapus data-data ini?</p>
      </Modal>
      <LandingListTemplate
        addButton={addButton}
        deleteButton={{visible: false}}
        exportButton={exportButton}
        dropdownButton={dropdownButton}
        tableHeader={tableHeader}
        tableData={tableData}
        tablePagination={pagination}
        tableOnChange={tableOnChange}
        isLoading={isLoading}
        tableScroll={tableScroll}
      />
    </div>
  );
}
