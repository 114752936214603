import { Form, Modal, Pagination, Row, Table } from "antd";
import { leaveModalTableHeader } from "./ModalApprovalLeave.constant";
import { useEffect, useState } from "react";
import BottomLoading from "../../../../../../../components/bottom-loading/BottomLoading";
import { ModalApprovalLeaveUsecase } from "./ModalApprovalLeave.usecase";

const moment = require('moment')
const useCase = new ModalApprovalLeaveUsecase()

export default function ModalApprovalLeave({ actionHideModal, visibleModal, dataSource }) {
  const { nik, name, params } = dataSource
  const tableHeader = leaveModalTableHeader
  const [isLoading, setIsLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    getTableDataPagination()
  }, [page, params])

  async function init() {
    tableHeader[0].render = (text) => {
      return <a>{text}</a>
    }

    tableHeader[1].render = (text) => {
      return moment(text).format('DD/MM/YYYY')
    }

    tableHeader[2].render = (text) => {
      return moment(text).format('DD/MM/YYYY')
    }

    tableHeader[3].render = (text) => {
      return moment(text).format('DD/MM/YYYY')
    }

    tableHeader[6].render = (text) => {
      return text.replace(/\b\w/g, (match) => match.toUpperCase())
    }
  }

  async function getTableDataPagination() {
    setIsLoading(true)
    const paramPagination = { ...params, page, limit: 5, with_meta: true }
    await useCase.getPendingSubmissionList(paramPagination).then(
      (res) => {
        setTableData(res.data.content)
        setTotal(res.data.metadata.total_pages)
        setIsLoading(false)
      }, (err) => {
        setIsLoading(false)
      }
    )
  }
  
  const FormItemValue = ({ value, label }) => {
    return (
      <Form.Item
        label={label}
        labelAlign="left"
      >
        <span className="form-value">{value}</span>
      </Form.Item>
    )
  };

  const onCancel = () => {
    if (actionHideModal) {
      actionHideModal()
    }
  }

  const paginationOnChange = (value) => {
    setPage(value)
  }

  return (
    <Modal
      visible={visibleModal}
      closable={true}
      onCancel={onCancel}
      title="Cuti belum final"
      width="80%"
      footer={[]}
    >
      <Form
        labelCol={{ span: 3 }}
      >
        <FormItemValue label="NIK" value={nik} />
        <FormItemValue label="Karyawan" value={name} />
      </Form>
      {
        total > 0 && (
          <Pagination
            style={{ float: 'right', marginBottom: '15px' }}
            current={page}
            pageSize={5}
            total={total}
            size='default'
            showSizeChanger={false}
            onChange={paginationOnChange}
          />
        )
      }
      
      <Table pagination={false} dataSource={tableData} columns={tableHeader} />
      
      <Row style={{ position: 'relative', bottom: '-27px' }}>
        <BottomLoading active={isLoading} />
      </Row>
    </Modal>
  )
}