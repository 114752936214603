import OrganizationChart from '@dabeng/react-orgchart';
import './OrganizationStructure.less';
import OrganizationStructureUsecase from './OrganizationStructure.usecase';
import { useEffect, useState } from 'react';
import { DataSourceOrganizationChart } from './OrganizationStructure.interface';
import BottomLoading from '../../../../components/bottom-loading/BottomLoading';

const usecase = new OrganizationStructureUsecase();

export default function OrganizationStructure() {
  const [dataSource, setDataSource] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const arrayToHTML = (array: string[]) => {
    return array.map((str) => <div>{str}</div>);
  };

  const mapperDataSource = (dataSource: DataSourceOrganizationChart) => {
    let result = {
      key: dataSource.id,
      id: dataSource.id,
      name: dataSource.name,
      title: arrayToHTML([dataSource.title[0], dataSource.title[1]]),
      children: dataSource.children.map((ds) => mapperDataSource(ds)),
    };
    return result;
  };

  useEffect(() => {
    setIsLoading(true);
    usecase
      .getData()
      .then((res) => {
        const content: DataSourceOrganizationChart = res.data.content;
        const result = mapperDataSource(content);
        setDataSource(result);
        setIsLoading(false);
      })
      .then((e) => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <OrganizationChart datasource={dataSource} />

      <BottomLoading key="loading" active={isLoading} />
    </>
  );
}
