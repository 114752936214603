import Constant from "../../../../../../config/Constants"
import API from "../../../../../../services/API.service"

export default class AddEmployeeWorkingScheduleUseCase {
  getJob() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/job"
    return API.get(URL).then(res => {
      return res.data.content.map((opt) => {
        return { key: opt.id, label: opt.job_name, value: opt.id }
      })
    })
  }

  search(params) {
    const URL = Constant.URL_BASE_ATTENDANCE + '/v3/configuration/jadwal-kerja-karyawan/search'
    return API.get(URL, { params })
  }

  getMasterSchedule(params) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/lov/master-jadwal"
    return API.get(URL, { params })
  }

  save(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/configuration/jadwal-kerja-karyawan"
    return API.post(URL, body)
  }
}