import { Col, Radio } from "antd"

export const AppliesToRadioGroup = (props) => {
  function manageValueApplies() {
    if (props.disabled) {
      return props.selected_employee?.length ?
        "Karyawan Tertentu" : "Semua Karyawan"
    }

    return !!props.selected_employee?.length
  }

  return (
    <Col span={24}>
      <Radio.Group
        disabled={props.disabled}
        defaultValue={manageValueApplies()}
        style={{ width: "100%" }}
        onChange={(e) => props.onChange(e)}
      >
        <Radio
          value={false}
          style={{
            width: "100%",
            marginBottom: "15px"
          }}>
          <div
            style={{ fontWeight: '600' }}
          >Semua Karyawan</div>
        </Radio>
        <Radio
          value={true}
          style={{
            width: "100%",
            marginBottom: "15px"
          }}>
          <div
            style={{ fontWeight: '600' }}
          >Karyawan Tertentu</div>
        </Radio>
      </Radio.Group>
    </Col>
  )
}