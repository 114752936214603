export function validateName(indexRow: number, name: string, field_name: string) {
  const result = {
    status: false,
    message: [],
    field_name,
  };

  if ((indexRow - 1) % 7 === 0) {
    if (!name) {
      result.message.push('Nama Jadwal Kerja harus diisi');
    } else if (name && name.length > 99) {
      result.message.push('Jumlah karakter melebihi batas maksimum');
    } else {
      result.status = true;
    }
  } else {
    result.status = true;
    result.message = [];
  }

  return result;
}

export function validateToleranceAbsent(
  indexRow: number,
  tolerance_absent: number,
  field_name: string,
) {
  const result = {
    status: false,
    message: [],
    field_name,
  };

  if ((indexRow - 1) % 7 === 0 || tolerance_absent) {
    if (isNaN(Number(tolerance_absent))) {
      if (tolerance_absent === undefined) {
        result.status = true;
      } else {
        result.message.push('Harus angka');
      }
    } else {
      if (tolerance_absent < 0 || tolerance_absent > 60) {
        result.message.push('Harus di antara 0-60');
      } else {
        result.status = true;
      }
    }
  } else {
    result.status = true;
  }

  return result;
}

export function validateHourFormat(hourString: string, field_name: string) {
  const result = {
    status: false,
    message: [],
    field_name,
  };

  if (!hourString) {
    // result.message.push('Harus diisi');
    result.status = true;
  } else {
    const rawTime = hourString.split(':');
    const hour = Number(rawTime[0]);
    const minute = Number(rawTime[1]);

    if (
      rawTime.length === 2 &&
      !isNaN(hour) &&
      hour >= 0 &&
      hour <= 23 &&
      !isNaN(minute) &&
      minute >= 0 &&
      minute <= 60
    ) {
      result.status = true;
    } else {
      result.message.push('Format jam salah');
    }
  }

  return result;
}

export function validateDay(indexRow: number, day: string, field_name: string) {
  const result = {
    status: true,
    message: [],
    field_name,
  };

  return result;
}

export function validateExcelData(excelArrays) {
  return new Promise((resolve, reject) => {
    const result = {
      status: 'error',
      dataSource: [],
    };

    excelArrays.forEach((column, index) => {
      if (index > 0 && column.length) {
        const rowTable: any = {
          status: true,
          name: column[0] ? column[0] : '',
          tolerance_absent: column[1] ? column[1] : 0,
          tolerance_absent_rule: !!column[1],
          sanctions_absent: false,
          day: column[3] ? column[3] : '',
          start_time: column[4] ? column[4] : '',
          end_time: column[5] ? column[5] : '',
          break_time: column[6] ? column[6] : '',
          error_list: {
            name: validateName(index, column[0], 'Nama Jadwal Kerja'),
            tolerance_absent: validateToleranceAbsent(index, column[1], 'Toleransi Kehadiran'),
            // sanctions_absent: validateToleranceAbsent(index, column[2], 'Sanksi Kehadiran'),
            day: validateDay(index, column[3], 'Hari'),
            start_time: validateHourFormat(column[4] ? column[4] : '', 'Jam Mulai'),
            end_time: validateHourFormat(column[5] ? column[5] : '', 'Jam Selesai'),
            break_time: validateHourFormat(column[6] ? column[6] : '', 'Durasi Istirahat'),
          },
        };

        if (Number(rowTable.tolerance_absent) > 0 && Number(rowTable.sanctions_absent) > 0) {
          rowTable.error_list.tolerance_absent.status = false;
          rowTable.error_list.sanctions_absent.status = false;

          rowTable.error_list.tolerance_absent.message.push(
            'Sanksi dan Toleransi Kehadiran tidak dapat diinput bersamaan. Harap input salah satu saja.',
          );
          rowTable.error_list.sanctions_absent.message.push(
            'Sanksi dan Toleransi Kehadiran tidak dapat diinput bersamaan. Harap input salah satu saja.',
          );
        }

        for (const key in rowTable.error_list) {
          if (rowTable.error_list[key].status === false) {
            rowTable.status = false;
            break;
          }
        }

        result.dataSource.push(rowTable);
      }

      if (index === excelArrays.length - 1) {
        result.status = 'success';
        resolve(result);
      }
    });
  });
}

export function groupingScheduleByName(scheduleArrays, company_id: number) {
  return new Promise((resolve, reject) => {
    const result = { hira_absent: [] };
    let weekLyActiveIndex = -1;

    scheduleArrays.forEach((data: any, index) => {
      const rawStartTime = data.start_time.split(':');
      const rawEndTime = data.end_time.split(':');
      const break_time = data.break_time.split(':');

      const daySchedule = {
        day: data.day.toLocaleLowerCase(),
        start_time: Number(rawStartTime[0]) + Number(rawStartTime[1]) / 60,
        end_time: Number(rawEndTime[0]) + Number(rawEndTime[1]) / 60,
        break_time: Number(break_time[0]) + Number(break_time[1]) / 60,
      };

      let weeklySchedule: any = {
        name: data.name,
        company_id,
        absent_rule: false,
        absent_option: 'tidak ada',
        tolerance_absent_rule: false,
        days_of_work: [daySchedule],
      };

      if (index % 7 === 0) {
        weekLyActiveIndex += 1;
        const sanctions_absent = Number(data.sanctions_absent);
        const tolerance_absent = Number(data.tolerance_absent);

        if (sanctions_absent > 0) {
          weeklySchedule.absent_option = 'sanksi';
          weeklySchedule.sanctions_absent = sanctions_absent;
        }
        if (tolerance_absent > 0) {
          weeklySchedule.absent_option = 'toleransi';
          weeklySchedule.tolerance_absent = tolerance_absent;
          weeklySchedule.tolerance_absent_rule = true
        }

        result.hira_absent.push(weeklySchedule);
      } else {
        result.hira_absent[weekLyActiveIndex].days_of_work.push(daySchedule);
      }

      if (index === scheduleArrays.length - 1) {
        resolve(result);
      }
    });
  });
}
