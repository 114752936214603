import { useState, useEffect } from 'react';
import { Button, Space, Form, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {
  ToolbarLeaveConfigDetailTemplatePropsType,
  ToolbarLeaveConfigActionButton,
  ToolbarLeaveConfigDetailSubmitButton,
  ToolbarLeaveConfigDropdown,
} from './ToolbarLeaveConfigDetailTemplate.interface';
import './ToolbarLeaveConfigDetailTemplate.less';

export default function ToolbarLeaveConfigDetailTemplate(props: ToolbarLeaveConfigDetailTemplatePropsType) {
  let [firstActionButton, setFirstActionButton] = useState<ToolbarLeaveConfigActionButton>();
  let [secondActionButton, setSecondActionButton] = useState<ToolbarLeaveConfigActionButton>();
  let [thirdActionButton, setThirdActionButton] = useState<ToolbarLeaveConfigActionButton>();
  let [fourthActionButton, setFourthActionButton] = useState<ToolbarLeaveConfigActionButton>();
  let [submitButton, setSubmitButton] = useState<ToolbarLeaveConfigDetailSubmitButton>();
  let [dropdownButton, setDropdownButton] = useState<ToolbarLeaveConfigDropdown>();

  useEffect(() => {
    processActionButtonProps('firstActionButton');
  }, [props.firstActionButton]);
  useEffect(() => {
    processActionButtonProps('secondActionButton');
  }, [props.secondActionButton]);
  useEffect(() => {
    processActionButtonProps('thirdActionButton');
  }, [props.thirdActionButton]);
  useEffect(() => {
    processActionButtonProps('fourthActionButton');
  }, [props.fourthActionButton]);
  useEffect(() => {
    processSubmitButton();
  }, [props.submitButton]);
  useEffect(() => {
    processDropdownButton();
  }, [props.dropdownButton]);

  const processActionButtonProps = (procedure) => {
    const result: any = {
      label: 'Untitled Button',
      type: 'primary',
      active: true,
      disabled: false,
    };

    if (props[procedure]) {
      result.label = props[procedure].label ? props[procedure].label : 'Untitled Button';
      result.type = props[procedure].type ? props[procedure].type : 'primary';
      result.active = props[procedure].active !== undefined ? props[procedure].active : true;
      result.disabled = props[procedure].disabled !== undefined ? props[procedure].disabled : false;
    }

    switch (procedure) {
      case 'firstActionButton':
        setFirstActionButton(result);
        break;
      case 'secondActionButton':
        setSecondActionButton(result);
        break;
      case 'thirdActionButton':
        setThirdActionButton(result);
        break;
      case 'fourthActionButton':
        setFourthActionButton(result);
        break;
      default:
        break;
    }
  };

  const processDropdownButton = () => {
    if(props.dropdownButton) {
      const result: any = { visible: false, disabled: false, options: [] };
      result.visible = props.dropdownButton.visible === undefined ? true : props.dropdownButton.visible;
      result.disabled = props.dropdownButton.disabled === undefined ? false : props.dropdownButton.disabled;
      result.options = props.dropdownButton.options
        ? props.dropdownButton.options
        : [{ label: 'Option 1', value: 'option_1' }];
  
      setDropdownButton(result)
    }
  }

  const processSubmitButton = () => {
    const result: any = {
      active: true,
      disabled: false,
    };

    if (props.submitButton) {
      result.active = props.submitButton.active !== undefined ? props.submitButton.active : true;
      result.disabled =
        props.submitButton.disabled !== undefined ? props.submitButton.disabled : false;
    }

    setSubmitButton(result);
  };

  const onClick = (procedure) => {
    if (props[procedure] && props[procedure].onClick) {
      props[procedure].onClick(procedure);
    }
  };

  const FirstActionButton = () => {
    return firstActionButton && firstActionButton.active ? (
      <Button
        type={firstActionButton.type}
        disabled={firstActionButton.disabled}
        onClick={() => onClick('firstActionButton')}
      >
        {firstActionButton.label}
      </Button>
    ) : null;
  };

  const SecondActionButton = () => {
    return secondActionButton && secondActionButton.active ? (
      <Button
        type={secondActionButton.type}
        disabled={secondActionButton.disabled}
        onClick={() => onClick('secondActionButton')}
      >
        {secondActionButton.label}
      </Button>
    ) : null;
  };

  const ThirdActionButton = () => {
    return thirdActionButton && thirdActionButton.active ? (
      <Button
        type={thirdActionButton.type}
        disabled={thirdActionButton.disabled}
        onClick={() => onClick('thirdActionButton')}
      >
        {thirdActionButton.label}
      </Button>
    ) : null;
  };

  const FourthActionButton = () => {
    return fourthActionButton && fourthActionButton.active ? (
      <Button
        type={fourthActionButton.type}
        disabled={fourthActionButton.disabled}
        onClick={() => onClick('fourthActionButton')}
      >
        {fourthActionButton.label}
      </Button>
    ) : null;
  };

  const SubmitButton = () => {
    return submitButton && submitButton.active ? (
      <Form.Item style={{ marginBottom: '0px' }}>
        <Button
          type="primary"
          htmlType="submit"
          disabled={submitButton.disabled}
          onClick={() => onClick('submitButton')}
        >
          SIMPAN
        </Button>
      </Form.Item>
    ) : null;
  };
  
  const DropdownButton = () => {
    const menu = dropdownButton && dropdownButton.visible ? (
      <Menu>
        {dropdownButton?.options?.map((opt) => (
          <Menu.Item
            disabled={opt.disabled}
            onClick={() => opt.onClick()}
            key={opt.key}
          >
            {opt.label}
          </Menu.Item>
        ))}
      </Menu>
    ) : null

    return dropdownButton && dropdownButton.visible ? (
      <Dropdown
        disabled={dropdownButton.disabled}
        overlay={menu}
      >
        <Button
          type='primary'
        >
          Tindakan <DownOutlined />
        </Button>
      </Dropdown>
    ) : null
  };

  return (
    <div className="toolbar-leave-detail-template">
      <div className="toolbar-row-one">
        <Space>
          <SubmitButton />
          <FirstActionButton />
          <SecondActionButton />
        </Space>
        <DropdownButton />
      </div>

      <div className="toolbar-row-two">
        <Space>
          <ThirdActionButton />
          <FourthActionButton />
        </Space>
      </div>
    </div>
  );
}
