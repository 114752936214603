import ApprovalSubmissionLeaveUseCase from '../../../../../../@core/use-case/leave/ApprovalSubmissionLeave.usecase';
import getLeaveTypes from '../../../../../../@core/interaction/leave/GetLeaveTypes.interaction';
import { LeaveTypeObjById } from '../../../../../../@core/entity/LeaveType.entity';
import { SubmissionLeaveList } from '../../../../../../@core/entity/SubmissionLeaveList.entity';
import { GetLeavesDTO } from '../../../../../../@core/interface/leave/GetLeaves.interface';
import { ExportLeaveResp } from '../../../../../../@core/interface/leave/ExportLeave.interface';
import { leaveTypeToObjById } from '../../../../../../@core/mapper/leave/GetLeaveTypes.mapper';
import getSubmissionLeaves from '../../../../../../@core/interaction/leave/GetSubmissionLeaves.interaction';
import { leaveListMapper } from '../../../../../../@core/mapper/leave/LeaveRequestList.mapper';
import exportSubmissionLeaves from '../../../../../../@core/interaction/leave/ExportSubmissionLeaves.interaction';
import Constant from '../../../../../../config/Constants';
import API from '../../../../../../services/API.service';

export default class EmployeeApprovalLeaveUseCaseImpl extends ApprovalSubmissionLeaveUseCase {
  getLeaveTypes(): Promise<LeaveTypeObjById> {
    return getLeaveTypes().then((res: any) => leaveTypeToObjById(res.content));
  }

  getSubmissionLeaves(
    params: GetLeavesDTO,
    leaveTypeObj: LeaveTypeObjById,
    hrefPath: string,
  ): Promise<SubmissionLeaveList> {
    return getSubmissionLeaves(params).then((res: any) =>
      leaveListMapper(res.data, leaveTypeObj, hrefPath),
    );
  }

  getAll(params) {
    const URL = Constant.URL_BASE_ATTENDANCE + '/v3/leave/approval/pending';
    return API.get(URL, { params });
  }

  exportSubmissionLeaves(params: GetLeavesDTO): Promise<ExportLeaveResp> {
    return exportSubmissionLeaves(params);
  }
}
