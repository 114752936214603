import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Button, Typography, Tabs, Tag, Row, Table, Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import FormBuilder from 'antd-form-builder';
import Constant from '../../../../../config/Constants';
import API from '../../../../../services/API.service';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import PageLoader from '../../../../../components/loader/PageLoader';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import HrAddAccessUsecase from './UpdateAccess.usecase';
import { getListUserMapper } from '../../../../../@core/mapper/employee/GetListAccess.mapper';
const { TabPane } = Tabs;
const { Title, Text } = Typography;
const { CheckableTag } = Tag;

const useCaseImpl = new HrAddAccessUsecase();
const localNotificationService = new LocalNotificationService();

let accessId = '';
export default function HrUpdateAccess({ match }) {
  const [form] = Form.useForm();
  const history = useHistory();
  accessId = match.params.accessId;
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [isLoading, setIsLoading] = useState(true);
  const [listMenu, setListMenu] = useState<any>([]);
  const [initialData, setInitialData] = useState<any>([]);
  const [referenceOption, setReferenceOption] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<string>('1');
  const [userDataSource, setUserDataSource] = useState([]);
  const [modalUser, setModalUser] = useState(false);
  const [userList, setUserList] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  let tagsData = ['R', 'C', 'U', 'D'];

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Referensi Hak Akses',
        href: '/v2/app/hr-employee/list-access',
      },
      {
        clickable: false,
        displayPathName: initialData.name,
      },
    ],
  };

  const meta: any = {
    columns: 1,
    fields: [
      {
        key: 'name',
        label: 'Nama',
        rules: [{ required: true, whitespace: true, message: "Nama harus diisi", }],
      },
      {
        key: 'reference',
        label: 'Referensi Hak Akses',
        widget: 'select',
        forwardRef: true,
        options: referenceOption.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        }),
        widgetProps: {
          allowClear: true,
        },
        onChange: async (value) => {
          if (value !== undefined) {
            onChangeReference(value);
          } else {
            const menu = await useCaseImpl.getAllMenu();
            setListMenu(menu.data.content);
          }
        },
      },
    ],
  };

  const MODAL_USER_TABLE_HEADER = [
    {
      title: 'Nama Pengguna',
      dataIndex: 'user_name',
    },
    {
      title: 'Nama Karyawan',
      dataIndex: 'employee_name',
    },
    {
      title: 'Jabatan',
      dataIndex: 'job_name',
    },
    {
      title: 'Hak Akses',
      dataIndex: 'hak_akses',
    },
  ];

  const USER_TABLE_HEADER = [
    {
      title: 'Nama Pengguna',
      dataIndex: 'user_name',
    },
    {
      title: 'Nama Karyawan',
      dataIndex: 'employee_name',
    },
    {
      title: 'Jabatan',
      dataIndex: 'job_name',
    },
    {
      title: 'Hak Akses',
      dataIndex: 'hak_akses',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (value, result) => {
        return (
          <DeleteOutlined
            onClick={(e) => {
              e.preventDefault();
              if (!result.key) {
                const param: any = {
                  uids: [result.id],
                };
                const URL = Constant.URL_BASE_USER + `/v3/hak-akses/${accessId}/user`;
                return API.delete(URL, { data: param })
                  .then((res: any) => {
                    const temp = userDataSource.reduce((acc, val, i) => {
                      if (result.id !== val.id) {
                        acc.push(val);
                      }
                      return acc;
                    }, []);
                    setUserDataSource(temp);
                    // localNotificationService.openSuccess('User Berhasil Dihapus', '', 3);
                  })
                  .catch((err: any) => {
                    localNotificationService.openError(err, '', 3);
                  });
              } else {
                const temp = userDataSource.reduce((acc, val, i) => {
                  if (result.id !== val.id) {
                    acc.push(val);
                  }
                  return acc;
                }, []);
                setUserDataSource(temp);
              }
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {
    setIsLoading(true);
    const menu = await useCaseImpl.getAllMenu();
    const detailAccess = await useCaseImpl.getAccessDetail(accessId);
    let tempInitial = {
      name: detailAccess.data.content.name,
    };
    const users = await useCaseImpl.getExisitingUser(accessId, {});
    const lovUser = await useCaseImpl.getUserList();
    if (lovUser.data.content) {
      const tempUserDataSource = getListUserMapper(lovUser.data.content);
      setUserList(tempUserDataSource);
    }
    users.data.content && setUserDataSource(users.data.content);

    let tempListMenu = menu.data.content.map((menu) => {
      let tempSub: any;

      if (!!menu.sub_menus) {
        tempSub = menu.sub_menus.map((sub) => {
          let tempSubSub: any;
          let tempResGroup;
          if (sub.res_groups) {
            if (
              !!detailAccess.data.content.list_hierarchy.find((res) => res.id === sub.res_groups.id)
            ) {
              tempResGroup = {
                ...detailAccess.data.content.list_hierarchy.find(
                  (res) => res.id === sub.res_groups.id,
                ),
                menu_id: sub.res_groups?.menu_id,
              };
            } else {
              tempResGroup = sub.res_groups;
            }
          } else {
            tempSubSub = sub.sub_menus && sub.sub_menus.map((nestedSub) => {
              let nestedTempRes;
              if (nestedSub.res_groups) {
                if (
                  !!detailAccess.data.content.list_hierarchy.find(
                    (res) => res.id === nestedSub.res_groups.id,
                  )
                ) {
                  nestedTempRes = {
                    ...detailAccess.data.content.list_hierarchy.find(
                      (res) => res.id === nestedSub.res_groups.id,
                    ),
                    menu_id: nestedSub.res_groups?.menu_id,
                  };
                } else {
                  nestedTempRes = nestedSub.res_groups;
                }
              }
              return {
                ...nestedSub,
                res_groups: nestedTempRes ? nestedTempRes : nestedSub.res_groups,
              };
            });
          }
          return {
            ...sub,
            sub_menus: tempSubSub,
            res_groups: tempResGroup ? tempResGroup : sub.res_groups,
          };
        });
      }
      return {
        ...menu,
        sub_menus: tempSub,
      };
    });
    setListMenu(tempListMenu);
    setInitialData(tempInitial);
    await useCaseImpl.getAccessList().then((res: any) => {
      setReferenceOption(res.data.content);
    });
    setIsLoading(false);
  }

  async function onFinish(e) {
    if (e) {
      submitToEndPoint(e);
    }
  }

  async function submitToEndPoint(e) {
    setIsLoading(true);
    let tempHierarchy = [];
    let tempuser = [];
    let validateAccess = false;
    listMenu.map((menu) => {
      if (menu.sub_menus) {
        menu.sub_menus.map((sub) => {
          if (sub.sub_menus) {
            sub.sub_menus.map((nestedSub) => {
              if (!nestedSub.sub_menus && nestedSub.res_groups) {
                if (
                  nestedSub.res_groups.is_create ||
                  nestedSub.res_groups.is_read ||
                  nestedSub.res_groups.is_create ||
                  nestedSub.res_groups.is_delete
                ) {
                  validateAccess = true;
                }
                tempHierarchy.push(nestedSub.res_groups);
              }
            });
          } else if (sub.res_groups) {
            if (
              sub.res_groups.is_create ||
              sub.res_groups.is_read ||
              sub.res_groups.is_create ||
              sub.res_groups.is_delete
            ) {
              validateAccess = true;
            }
            tempHierarchy.push(sub.res_groups);
          }
        });
      } else if (menu.res_groups) {
        if (
          menu.res_groups.is_create ||
          menu.res_groups.is_read ||
          menu.res_groups.is_create ||
          menu.res_groups.is_delete
        ) {
          validateAccess = true;
        }
        tempHierarchy.push(menu.res_groups);
        
      }
    });
    tempuser = userDataSource.reduce((acc, val, i) => {
      if (val.key) {
        acc.push(val);
      }

      return acc;
    }, []);

    const params = {
      id: accessId,
      name: e.name.trim(),
      list_hierarchy: tempHierarchy,
    };
    const user_params = {
      id: accessId,
      name: e.name.trim(),
      list_user_id: tempuser,
    };
    if(!validateAccess) {
      localNotificationService.openError('Anda belum memilih hak akses menu', '', 3);
      setIsLoading(false);
      return
    }
    if (tempuser.length > 0) {
      useCaseImpl.createUser(user_params).then(
        (res: any) => {},
        (err) => {
          localNotificationService.openError(err, '', 3);
        },
      );
    }
    await useCaseImpl.update(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          history.push('/app/hr-employee/list-access');
          localNotificationService.openSuccess('Referensi Hak Akses Berhasil Diubah', '', 3);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }

  const handleChange = (tag: string, checked: boolean, m) => {
    let tempListMenu = listMenu.map((menu) => {
      let tempSub: any;

      if (!!menu.sub_menus) {
        tempSub = menu.sub_menus.map((sub) => {
          let tempSubSub: any;
          let tempResGroup;
          if (sub.res_groups) {
            if (sub?.res_groups?.id && sub?.res_groups?.id === m?.res_groups?.id) {
              switch (tag) {
                case 'R':
                  tempResGroup = {
                    ...sub.res_groups,
                    is_read: checked,
                  };
                  break;
                case 'U':
                  tempResGroup = {
                    ...sub.res_groups,
                    is_write: checked,
                  };
                  break;
                case 'D':
                  tempResGroup = {
                    ...sub.res_groups,
                    is_delete: checked,
                  };
                  break;
                case 'C':
                  tempResGroup = {
                    ...sub.res_groups,
                    is_create: checked,
                  };
                  break;
              }
            }
          } else {
            tempSubSub =
              sub?.sub_menus &&
              sub?.sub_menus.map((nestedSub) => {
                let nestedTempRes;
                if (nestedSub.res_groups) {
                  if (nestedSub.res_groups.id === m.res_groups.id) {
                    switch (tag) {
                      case 'R':
                        nestedTempRes = {
                          ...nestedSub.res_groups,
                          is_read: checked,
                        };
                        break;
                      case 'U':
                        nestedTempRes = {
                          ...nestedSub.res_groups,
                          is_write: checked,
                        };
                        break;
                      case 'D':
                        nestedTempRes = {
                          ...nestedSub.res_groups,
                          is_delete: checked,
                        };
                        break;
                      case 'C':
                        nestedTempRes = {
                          ...nestedSub.res_groups,
                          is_create: checked,
                        };
                        break;
                    }
                  }
                }
                return {
                  ...nestedSub,
                  res_groups: nestedTempRes ? nestedTempRes : nestedSub.res_groups,
                };
              });
          }
          return {
            ...sub,
            sub_menus: tempSubSub,
            res_groups: tempResGroup ? tempResGroup : sub.res_groups,
          };
        });
      }
      return {
        ...menu,
        sub_menus: tempSub,
      };
    });
    setListMenu(tempListMenu);
  };
  async function onChangeReference(value) {
    setIsLoading(true);
    const hierarchy: any = await useCaseImpl.getHierarchyList({ access_level_id: value });
    if (!!hierarchy) {
      let tempListMenu = listMenu.map((menu) => {
        let tempSub: any;

        if (!!menu.sub_menus) {
          tempSub = menu.sub_menus.map((sub) => {
            let tempSubSub: any;
            let tempResGroup;
            if (sub.res_groups) {
              tempResGroup = hierarchy.data.content.find((res) => res.id === sub.res_groups.id);
            } else {
              tempSubSub = sub.sub_menus && sub.sub_menus.map((nestedSub) => {
                let nestedTempRes;
                if (nestedSub.res_groups) {
                  nestedTempRes = hierarchy.data.content.find(
                    (res) => res.id === nestedSub.res_groups.id,
                  );
                }
                return {
                  ...nestedSub,
                  res_groups: nestedTempRes,
                };
              });
            }
            return {
              ...sub,
              sub_menus: tempSubSub,
              res_groups: tempResGroup,
            };
          });
        }
        return {
          ...menu,
          sub_menus: tempSub,
        };
      });
      setListMenu(tempListMenu);
      setIsLoading(false);
    }
  }

  async function onAddUsers() {
    const temp = userList.reduce((acc, val, i) => {
      if (selectedRowKeys.map((item) => item).includes(val.key)) {
        acc.push(val);
      }

      return acc;
    }, []);

    const result = temp.reduce(
      (acc, item) => {
        let isExist = acc.find((val) => val['id'] === item.id);
        return !isExist ? [...acc, item] : acc;
      },
      [...userDataSource],
    );

    setUserDataSource(result);
    setSelectedRowKeys([]);
    setModalUser(false);
  }

  function MenuMap({ items, level }: any) {
    if (!items || !items.length) {
      return null;
    }

    return items.map((m) => {
      let selectedTags = [];
      if (m?.res_groups) {
        m?.res_groups?.is_read && selectedTags.push('R');
        m?.res_groups?.is_create && selectedTags.push('C');
        m?.res_groups?.is_write && selectedTags.push('U');
        m?.res_groups?.is_delete && selectedTags.push('D');
        switch (m.id) {
          case 366:
            tagsData = ['R'];
            break;
          case 367:
            tagsData = ['R', 'C'];
            break;
          case 368:
            tagsData = ['R', 'C'];
            break;
          case 369:
            tagsData = ['R', 'C'];
            break;
          case 370:
            tagsData = ['R'];
            break;
          case 371:
            tagsData = ['R', 'U'];
            break;
          case 372:
            tagsData = ['R'];
            break;
          case 373:
            tagsData = ['R', 'U'];
            break;
          case 374:
            tagsData = ['R', 'U'];
            break;
          case 375:
            tagsData = ['R', 'C'];
            break;
          case 376:
            tagsData = ['R', 'U'];
            break;
          case 377:
            tagsData = ['R'];
            break;
          case 363:
            tagsData = ['R'];
            break;
          case 364:
            tagsData = ['R', 'U'];
            break;
          default:
            tagsData = ['R', 'C', 'U', 'D'];
            break;
        }
      }
      return (
        <div key={'submenu-' + m.name}>
          {!!m.sub_menus && !!m.sub_menus.length ? (
            <>
              <div
                style={{
                  paddingLeft: level,
                  paddingTop: 5,
                  paddingBottom: 5,
                  margin: 5,
                  fontWeight: 'bold',
                  background: level == 5 ? '#EEEEEE' : 'none',
                  width: '90%',
                }}
              >
                {m.name}
              </div>
              <MenuMap items={m.sub_menus} level={level + 15} />
            </>
          ) : (
            <div>
              <Row justify={'space-between'}>
                <div style={{ paddingLeft: level, fontWeight: 'bold', width: '60%', margin: 10 }}>
                  {m.name}
                </div>
                <Row style={{ width: '35%' }}>
                  {tagsData.map((tag) => (
                    <CheckableTag
                      style={{ height: 'fit-content' }}
                      key={tag}
                      checked={selectedTags.indexOf(tag) > -1}
                      onChange={(checked) => handleChange(tag, checked, m)}
                    >
                      {tag}
                    </CheckableTag>
                  ))}
                </Row>
              </Row>
            </div>
          )}
        </div>
      );
    });
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div style={{ position: 'relative', height: 'calc(100vh - 100px)', overflowY: 'scroll' }}>
      <Form form={form} layout="horizontal" initialValues={initialData} onFinish={onFinish}>
        <div>
          <div className="form-wrapper">
            <div>
              <Breadcrumb breadcrumbConf={breadcrumbConf} />
              <Modal
                title={'Tambahkan Pengguna'}
                visible={modalUser}
                onCancel={() => setModalUser(false)}
                footer={[
                  <Button onClick={() => setModalUser(false)}>Batal</Button>,
                  <Button onClick={() => onAddUsers()}>Simpan</Button>,
                ]}
              >
                <div>
                  <Table
                    columns={MODAL_USER_TABLE_HEADER}
                    dataSource={userList}
                    rowSelection={rowSelection}
                    pagination={{ position: ['topRight'], showSizeChanger: false, pageSize: 80 }}
                    scroll={{ y: 400 }}
                  />
                </div>
              </Modal>
              <Form.Item className="form-footer" style={{ width: '100%', marginTop: 20 }}>
                <Button type="primary" onClick={() => form.submit()} disabled={isLoading}>
                  Simpan
                </Button>
                &nbsp; &nbsp;
                <Button onClick={() => history.push('/app/hr-employee/list-access')}>Batal</Button>
              </Form.Item>
              <div>
                <FormBuilder form={form} meta={meta} />
                <div style={{ marginTop: 20 }}>
                  <Tabs activeKey={activeTab} type="card" onChange={(value) => setActiveTab(value)}>
                    <TabPane tab="Hak Akses" key="1">
                      <Title level={3}>Keterangan: </Title>
                      <Text>Set hak akses setiap menu dengan pilihan sebagai berikut:</Text>
                      <br />
                      <Text>R untuk melihat data.</Text>
                      <br />
                      <Text>C untuk menambah/membuat data baru.</Text>
                      <br />
                      <Text>U untuk mengubah/memperbaharui data.</Text>
                      <br />
                      <Text>D untuk menghapus data.</Text>
                      <br />
                      <br />
                      <br />
                      <MenuMap items={listMenu} level={5} />
                    </TabPane>
                    <TabPane tab="Users" key="2">
                      <div>
                        <Button
                          style={{ marginBottom: 10 }}
                          type="primary"
                          onClick={() => setModalUser(true)}
                        >
                          Tambah User
                        </Button>
                        <Table columns={USER_TABLE_HEADER} dataSource={userDataSource} />
                      </div>
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
