export const ApprovalOvertimeListConstant = {
  TABLE_HEADER: [
    {
      title: 'Nomor Pengajuan Lembur',
      key: 'number_submission_overtime',
      dataIndex: 'number_submission_overtime',
      sorter: true,
      width: 260,
      isCTA: true,
    },
    {
      title: 'NIK',
      key: 'employee_nik',
      dataIndex: 'employee_nik',
      width: 160,
    },
    {
      title: 'Karyawan',
      key: 'employee_name',
      dataIndex: 'employee_name',
      width: 180,
    },
    {
      title: 'Tanggal Pengajuan',
      key: 'submission_date',
      dataIndex: 'submission_date',
      width: 180,
      sorter: true,
    },
    {
      title: 'Mulai',
      key: 'start',
      dataIndex: 'start',
      width: 180,
    },
    {
      title: 'Berakhir',
      key: 'end',
      dataIndex: 'end',
      width: 180,
    },
    {
      title: 'Durasi Lembur',
      key: 'overtime_duration',
      dataIndex: 'overtime_duration',
      sorter: true,
      width: 150,
    },
    {
      title: 'Alasan Lembur',
      key: 'overtime_reason',
      dataIndex: 'overtime_reason',
      sorter: true,
      width: 150,
    },
    { title: 'Dibuat Pada', key: 'create_date', dataIndex: 'create_date', width: 180 },
    {
      title: 'Tindakan',
      key: 'action',
      dataIndex: 'action',
      sorter: true,
      width: 230,
      render: null
    },
  ],
  SEARCH_COLUMN_KEY: [
    { key: 'key', componentType: "search-input" },
    { key: 'number_submission_overtime', componentType: "text" },
    { key: 'employee_nik', componentType: "text" },
    { key: 'employee_name', componentType: "text" },
    { key: 'submission_date', componentType: "date" },
  ],
  DEFAULT_EMPTY_DATA_SOURCE: [
    {
      key: 'empty-data',
      number_submission_overtime: '',
      employee_nik: '',
      employee_name: '',
      start: '',
      end: '',
      submission_date: '',
      overtime_duration: '',
      overtime_reason: '',
      create_date: '',
      href: ''
    },
  ],
}