import Moment from 'moment';
import { getCheckinStatus, getCheckoutStatus, getAbsentStatus } from './AttendanceStatus.mapper';

export function recapAttendanceListMapper(
  getRecapAttendanceResp,
  hrefPath
) {
  const content = getRecapAttendanceResp.content ? getRecapAttendanceResp.content : null;
  let contentMap = null;

  if (content) {
    contentMap = content.map((data) => {
      const recapAttendanceItem = {
          id: data.id,
          key: data.id,
          create_date: data.create_date ? Moment(data.create_date).format('DD/MM/YYYY hh:mm') : '-',
          write_uid: data.write_uid,
          create_uid: data.create_uid,
          check_in: data.check_in ? Moment(data.check_in).format('DD/MM/YYYY HH:mm') : '-',
          employee_id: data.employee_id,
          employee_name: data.employee_name,
          job_id: data.job_id,
          job_name: data.job_name,
          total_works_per_hour: data.total_works_per_hour,
          company_id: data.company_id,
          department_id: data.department_id,
          hr_attendance_id: data.hr_attendance_id,
          information: data.information,
          hira_absent_id: data.hira_absent_id,
          category_check_in: getCheckinStatus(data.category_check_in),
          status_absent_date: data.status_absent_date ? Moment(data.status_absent_date).format('DD/MM/YYYY hh:mm') : '-',
          write_date: Moment(data.write_date).format('DD/MM/YYYY hh:mm'),
          type_attendance: data.type_attendance,
          check_out: data.check_out ? Moment(data.check_out).format('DD/MM/YYYY HH:mm') : '-',
          worked_hours: data.worked_hours,
          name: data.name,
          status_absent: data.status_absent,
          category_check_out:  getCheckoutStatus(data.category_check_out),
          reason: data.reason,
          status_worked: data.status_worked,
          status_absent_info: getAbsentStatus(data.status_absent_info),
          total_late: data.total_late,
          jadwal_kerja: data.jadwal_kerja
      };

      return recapAttendanceItem;
    });
  }

  return {
    content: contentMap,
    metaData: getRecapAttendanceResp.metadata,
  };
}

export function recapCalendarListMapper(getRecapAttendanceResp) {
  const content = getRecapAttendanceResp.content ? getRecapAttendanceResp.content : null;
  let contentMap = null;

  if (content) {
    contentMap = content.map((data) => {
      const checkin_date= Moment(data.check_in).format('YYYY-MM-DD');
      const checkin_time= Moment(data.check_in).format('HH:mm:ss');
      const checkout_date= Moment(data.check_out).format('YYYY-MM-DD');
      const checkout_time= Moment(data.check_out).format('HH:mm:ss');
      let name = `${checkin_time}`;
      if(!!data.check_out){
        name = `${checkin_time} - ${checkout_time}`;
      }
      const recapAttendanceItem = {
          key: data.id,
          color: 'red',
          startDate: checkin_date,
          endDate: !!data.check_out ? checkout_date : checkin_date,
          name: name,
          status: true,
      };
      return recapAttendanceItem;
    });
  }

  return {
    content: contentMap,
    metaData: getRecapAttendanceResp.metadata,
  };
}