import { Avatar, Button, Col, Row, Space } from 'antd';
import { NotificationCardPropsType } from '../NotificationListTemplate.interface';
import './NotificationCard.less';

import avatar from './../../../../../components/asset/avatar.jpeg';

export default function NotificationCard(props: NotificationCardPropsType) {
  return (
    <Col className="notification-card">
      <Row className='head-section' justify="space-between">
        <p style={{ color: '#d42534', lineHeight: 1 }}>{props.title}</p>
        <p style={{ color: '#d42534', lineHeight: 1 }}>{props.extra}</p>
      </Row>

      <Row className='body-section'  justify="space-between" align='middle'>
        <Space>
          <Avatar size='large' shape="square" src={props.src || avatar} />

          <Col style={{ width: '180px', marginTop: '12px' }}>
            <h1 style={{ lineHeight: '0.9rem' }}>{props.name}</h1>

            <p style={{ color: 'black', lineHeight: '0.9rem', fontSize: '12px' }}>{props.job}</p>
          </Col>
        </Space>

        <Button danger ghost href={props.href}>{props.button}</Button>
      </Row>
    </Col>
  );
}
