import Constant from "../../../../../config/Constants"
import API from "../../../../../services/API.service"

export default class DetailMasterDepartmentUsecase {
  getDetail(id) {
    const URL = Constant.URL_BASE_COMPANY + `/v3/master-department/detail/${id}`
    return API.get(URL)
  }

  active(body) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/master-department/update-state"
    return API.put(URL, body)
  }

  update(body) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/master-department/update"
    return API.put(URL, body)
  }

  getDept(params) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/master-department/card-view"
    return API.get(URL, { params }).then(res => {
      return res.data.content.map((opt) => {
        return { key: opt.id, label: opt.name, value: opt.id }
      })
    })
  }

  getChildDept(params) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/master-department/card-view"
    return API.get(URL, { params })
  }
}