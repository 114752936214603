import Moment from 'moment';
import getMissingAttendance from '../../../../../../@core/interaction/attendance/GetMissingAttendance.interaction';
import exportMissingAttendance from '../../../../../../@core/interaction/attendance/ExportMissingAttendance.interaction';
import { missingAttendanceMapper } from '../../../../../../@core/mapper/attendance/MissingAttendance.mapper';
export default class MissingAttendanceUseCaseImpl {
  getMissingAttendanceList(params, hrefPath) {
    params.start_date = Moment(params.start_date).format('YYYY-MM-DD');
    params.end_date = Moment(params.end_date).format('YYYY-MM-DD');
    return getMissingAttendance(params).then((res: any) =>
      missingAttendanceMapper(res.data, hrefPath),
    );
  }

  exportMissingAttendance(params) {
    params.start_date = Moment(params.start_date).format('YYYY-MM-DD');
    params.end_date = Moment(params.end_date).format('YYYY-MM-DD');
    return exportMissingAttendance(params);
  }
}
