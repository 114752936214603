export const AccessListConstant = {
    TABLE_HEADER: [
      {
        title: 'Nama Hak Akses',
        key: 'name',
        dataIndex: 'name',
        width: 150,
      },
      { title: 'Dibuat Oleh', key: 'create_name', dataIndex: 'create_name', width: 150 },
      { title: 'Dibuat Pada', key: 'create_date', dataIndex: 'create_date', width: 150, sorter: true, },
      { title: 'Diubah Oleh', key: 'write_name', dataIndex: 'write_name', width: 150 },
      { title: 'Diubah Pada', key: 'write_date', dataIndex: 'write_date', width: 150, sorter: true, },
    ],
    SEARCH_COLUMN_KEY: [],
    SEARCH_COLUMN_COMPONENT: [
      { key: 'key', componentType: "text" },
      { key: 'name', componentType: "text" },
    ],
    DEFAULT_EMPTY_DATA_SOURCE: [
      {
        key: '',
        name: '',
      },
    ],
  }