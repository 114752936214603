import { Form, Tabs } from "antd"
import Breadcrumb from "../../../../../../components/breadcrumb/Breadcrumb"
import BottomLoading from "../../../../../../components/bottom-loading/BottomLoading"
import { AddRecurringHolidaysTemplatePropsType } from "./AddRecurringHolidaysTemplate.interface";
import ToolbarButtonDetailTemplate from "../../../../component/toolbar-button-detail-template/ToolbarButtonDetailTemplate";
import RecurringHolidaysForm from "../../../component/recurring-holidays-form/RecurringHolidaysForm";
import { MemoizeHistoryTabs } from "../../../../component/history-payroll-period-tabs/HistoryPayrollPeriodTabs";

export default function AddRecurringHolidaysTemplate(props: AddRecurringHolidaysTemplatePropsType) {
  const [form] = Form.useForm()

  const onFinish = (values) => {
    if (props.onFinish) {
      props.onFinish(form.getFieldsValue());
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (props.onFinishFailed) {
      props.onFinishFailed(form.getFieldsValue());
    }
  };

  const onFieldsChange = (changedFields) => {
    if (props.onFieldChange) {
      props.onFieldChange(changedFields, form);
    }
  };

  return (
    <div id="add-salary-component" style={{ height: 'calc(100vh - 100px)' }}>
      <Breadcrumb breadcrumbConf={props.breadcrumbConf} />

      <Form
        name="basic"
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 8 }}
        layout="horizontal"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={onFieldsChange}
      >
        <ToolbarButtonDetailTemplate
          submitButton={props.submitButton}
          firstActionButton={props.firstActionButton}
          secondActionButton={props.secondActionButton}
          thirdActionButton={props.thirdActionButton}
          fourthActionButton={props.fourthActionButton}
          dropdownActionButton={props.dropdownActionButton}
        />

        <div className="scrollable-content">
          <div className="form-wrapper">
            <RecurringHolidaysForm
              holidayName={props.holidayName}
              startDate={props.startDate}
              endDate={props.endDate}
              holidayLength={props.holidayLength}
              isRecurring={props.isRecurring}
              isEdit={props.isEdit}
              isLoading={props.isLoading}
              isDetail={props.isDetail}
              historyId={props.historyId}
            />
            {props.historyId ? (
              <div className="tabs-wrapper">
                <Tabs defaultActiveKey="1" type="card">
                  <Tabs.TabPane tab="History" key="1">
                    <MemoizeHistoryTabs history_id={props.historyId} />
                  </Tabs.TabPane>
                </Tabs>
              </div>
            ) : null}
          </div>
        </div>
      </Form>

      <BottomLoading active={props.isLoading} />
    </div>
  )
}