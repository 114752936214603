import Constant from "../../../../../../config/Constants"
import API from "../../../../../../services/API.service"
import DetailLeaveConfigurationUseCase from '../../../../../../@core/use-case/leave/DetailLeaveConfiguration.usecase';
import getJobList from '../../../../../../@core/interaction/job/GetJobList.interaction';
import getEmployeeList from '../../../../../../@core/interaction/employee/GetEmployeeList.interaction';
import getCheckProcess from '../../../../../../@core/interaction/leave/CheckProcessLeaveConfiguration.interaction';
import { jobListToOption } from '../../../../../../@core/mapper/job/GetJobList.mapper';
import getLeaveConfigurationDetail from '../../../../../../@core/interaction/leave/GetLeaveConfigurationDetail.interaction';
import getHistory from '../../../../../../@core/interaction/history/GetHistory.interaction'
import updateLeaveConfiguration from '../../../../../../@core/interaction/leave/UpdateLeaveConfiguration.interaction';
import { employeeListToOptionsMapper } from '../../../../../../@core/mapper/employee/GetEmployeeList.mapper';
import getLeaveRel from '../../../../../../@core/interaction/leave/GetLeaveRel.interaction';

export default class HrConfigurationLeaveDetailUseCaseImpl {
  getJobList(params) {
    return getJobList(params).then((res: any) => jobListToOption(res.data.content));
  }
  getJob(temp={}) {
    const params:any = {
      ...temp
    }
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/job"
    return API.get(URL, {params}).then((res:any) => 
      {
        const result = res.data.content.map(element => {
          return { label: element['job_name'], value: JSON.stringify(element), key: element['id'] }
        })
        return result;
      })
  }
  
  getEmpl() {
    const param:any = {
      page:1,
      limit: 500
    }
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/emp"
    return API.get(URL, param).then((res:any) => 
      {
        const result = res.data.content.map(element => {
          return { label: element['alternative_name'], value: JSON.stringify(element), key: element['id'] }
        })
        return result;
      })
  }

  getEmployeeList(params): Promise<any> {
    return getEmployeeList(params).then((res: any) =>
      employeeListToOptionsMapper(res.data.content),
    );
  }

  getLeaveConfigurationDetail(params): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const leaveId = params.leaveId;

      const leaveConfigDetail = await getLeaveConfigurationDetail(leaveId);
      
      if (leaveConfigDetail.status === 200 && leaveConfigDetail.data?.content) {
        
        const resultMapper = leaveConfigDetail.data.content;
        
      const body:any = {
        limit: 300,
        page: 1,
        with_meta: true,
        history_id: resultMapper.history_id
      };
      
      let tempData = await getHistory(body);
      const listHistory = tempData.data.content;
      resultMapper.historyList = listHistory;

         resolve(resultMapper);
      } else {
        reject(leaveConfigDetail);
      }
    });
  }

  getLeaveRel(params): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const leaveRel = await getLeaveRel(params);
      if (leaveRel.status === 200 && leaveRel.data?.content) {
        resolve(leaveRel?.data);
      } else {
        reject(leaveRel?.data);
      }
    });
  }
  
  getCheckProcess(params) {
    return getCheckProcess(params);
  }

  submitLeaveConfiguration(params): Promise<any> {
    return updateLeaveConfiguration(params);
  }
}
