import { useState, useEffect } from 'react';
import { Input, Form, Col, Row, DatePicker, Select, Checkbox } from 'antd';
import GoogleMap from 'google-map-react';
import HistoryTabs from '../detail-absent-tab/DetailAbsentTab';
import {
  EmployeeAttendanceDetailFromPropsType,
  EmployeeAttendanceDetailGeneralForm,
} from './EmployeeAttendanceDetailForm.interface';
import './EmployeeAttendanceDetailForm.less';

const { TextArea } = Input;
const { Option } = Select;

export default function EmployeeAttendanceDetailForm(props: EmployeeAttendanceDetailFromPropsType) {
  let [employee, setEmployee] = useState<EmployeeAttendanceDetailGeneralForm>();
  let [checkinLocation, setCheckinLocation] = useState<any>();
  let [checkoutLocation, setCheckoutLocation] = useState<any>();
  let [absentStatus, setAbsentStatus] = useState<EmployeeAttendanceDetailGeneralForm>();
  let [checkin, setCheckin] = useState<EmployeeAttendanceDetailGeneralForm>();
  let [statusCheckout, setStatusCheckout] = useState<EmployeeAttendanceDetailGeneralForm>();
  let [checkout, setCheckout] = useState<EmployeeAttendanceDetailGeneralForm>();
  let [reason, setReason] = useState<EmployeeAttendanceDetailGeneralForm>();
  let [workedHours, setWorkedHours] = useState<EmployeeAttendanceDetailGeneralForm>();

  const firstSection = [
    { label: 'Karyawan', name: 'karyawan', componentType: 'select', state: employee },
    { label: 'Tidak Masuk Kerja', name: 'absentStatus', componentType: 'checkbox', state: absentStatus },
  ];
  const secondSection = [
    { label: 'Checkin', name: 'label1' },
    { label: 'Waktu', name: 'checkin', componentType: 'date', state: checkin },
  ]

  const thirdSection = [
    { label: 'Checkout', name: 'statusCheckout', componentType: 'checkbox', state: statusCheckout },
    { label: 'Waktu', name: 'checkout', componentType: 'date', state: checkout },
  ]

  const fourthSection = [
    { label: 'Durasi Jam Kerja', name: 'workedHours', componentType: '', state: workedHours },
    { label: 'Alasan', name: 'reason', componentType: 'text_area', state: reason },
  ]


  useEffect(() => {
    processSelectType('employee');
  }, [props.employee]);
  useEffect(() => {
    processGeneralPropsType('absentStatus');
  }, [props.absentStatus]);
  useEffect(() => {
    processGeneralPropsType('checkin');
  }, [props.checkin]);
  useEffect(() => {
    processGeneralPropsType('statusCheckout');
  }, [props.statusCheckout]);
  useEffect(() => {
    processGeneralPropsType('checkout');
  }, [props.checkout]);
  useEffect(() => {
    processGeneralPropsType('workedHours');
  }, [props.workedHours]);
  useEffect(() => {
    processGeneralPropsType('reason');
  }, [props.reason]);
  useEffect(() => {
    processMapPropsType('checkinLocation');
  }, [props.checkinLocation]);
  useEffect(() => {
    processMapPropsType('checkoutLocation');
  }, [props.checkoutLocation]);

  const processMapPropsType = (procedure) => {
    switch (procedure) {
      default:
        break;
      case 'checkinLocation':
        setCheckinLocation(props[procedure])
        break;
      case 'checkoutLocation':
        setCheckoutLocation(props[procedure]);
        break;
    }
  }

  const processGeneralPropsType = (procedure) => {
    const result: any = {
      active: false,
      value: '',
      editMode: false,
      rules: [{ required: false, message: '' }],
    };

    if (props[procedure]) {
      result.active = props[procedure].active !== undefined ? props[procedure].active : true;
      result.value = props[procedure].value ? props[procedure].value : '';
      result.editMode = props[procedure].editMode !== undefined ? props[procedure].editMode : false;
      result.rules = props[procedure].rules
        ? props[procedure].rules
        : [{ required: false, message: '' }];
    }

    switch (procedure) {
      default:
        break;
      case 'employee':
        setEmployee(result);
        break;
      case 'absentStatus':
        setAbsentStatus(result);
        break;
      case 'checkin':
        setCheckin(result);
        break;
      case 'statusCheckout':
        setStatusCheckout(result);
        break;
      case 'checkout':
        setCheckout(result);
        break;
      case 'workedHours':
        if(props?.absentStatus?.value) {
          result.active = false;
          result.value = '';
        }
        setWorkedHours(result);
        break;
      case 'reason':
        setReason(result);
        break;
    }
  };

  const processSelectType = (procedure) => {
    const result: any = {
      active: true,
      value: '',
      editMode: false,
      options: [{ label: 'Option 1', value: 'option_1' }],
      rules: [{ required: false, message: '' }],
    };

    if (props[procedure]) {
      result.active = props[procedure].active !== undefined ? props[procedure].active : true;
      result.value = props[procedure].value;
      result.editMode = props[procedure].editMode !== undefined ? props[procedure].editMode : false;
      result.options = props[procedure].options
        ? props[procedure].options
        : [{ label: 'Option 1', value: 'option_1' }];
      result.rules = props[procedure].rules
        ? props[procedure].rules
        : [{ required: false, message: '' }];
    }

    switch (procedure) {
      default:
        break;
      case 'employee':
        result.showSearch = true;
        result.placeholder = 'Cari karyawan...';
        setEmployee(result);
        break;
    }
  };

  const FormItem = ({ sectionBluePrint }) => {
    const label = sectionBluePrint.label;
    const name = sectionBluePrint.name;
    const componentType = sectionBluePrint.componentType;
    const state = sectionBluePrint.state;

    if (state && !state.editMode) {
      return <FormItemValue label={label} name={name} state={state} />;
    } else {
      switch (componentType) {
        default:
          return <FormItemValue label={label} name={name} state={state} />;
        case 'text_area':
          return <FormItemTextArea label={label} name={name} state={state} />;
        case 'select':
          return <FormItemSelect label={label} name={name} state={state} />;
        case 'checkbox':
          return <FormItemCheckbox label={label} name={name} state={state} disabled={false} />;
        case 'date':
          return <FormItemDatePicker label={label} name={name} state={state} />;
      }
    }
  };

  const FormItemValue = ({ label, name, state }) => {
    return state && state.active === true ? (
      <Form.Item label={label} name={name} labelAlign="left" colon={false} rules={state.rules}>
        <span className="form-value">{state.value}</span>
        {state.showList && <a> {state.showList}</a>}
      </Form.Item>
    ) : null;
  };

  const FormItemTextArea = ({ label, name, state }) => {
    return state && state.active === true ? (
      <Form.Item label={label} name={name} labelAlign="left" colon={false} rules={state.rules}>
        <TextArea rows={4} style={{ width: '90%' }} />
      </Form.Item>
    ) : null;
  };

  const FormItemDatePicker = ({ label, name, state }) => {
    return state && state.active === true ? (
      <Form.Item label={label} name={name} labelAlign="left" colon={false} rules={state.rules}>
        <DatePicker showTime format="DD-MM-YYYY HH:mm" />
      </Form.Item>
    ) : null;
  };

  const FormItemCheckbox = ({ label, name, state, disabled }) => {
    return state && state.active === true ? (
      <Form.Item label={label} name={name} labelAlign="left" colon={false} rules={state.rules}>
        <Checkbox checked={state.value} disabled={true} />
      </Form.Item>
    ) : null;
  };

  const FormItemSelect = ({ label, name, state }) => {
    return state && state.active === true ? (
      <Form.Item label={label} name={name} labelAlign="left" colon={false} rules={state.rules}>
        <Select
          style={{ width: '96%' }}
          showSearch={state.showSearch}
          placeholder={state.placeholder}
          filterOption={(input:any, option:any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {state.options.map((option) => (
            <Option value={option.value} key={option.id}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    ) : null;
  };

  const CheckinWrapper: React.FC<any> = ({ value = {}, onChange }) => {
    function _onClickCheckin(obj) {
      setCheckinLocation({ lat: obj.lat, lng: obj.lng })
      onChange?.({ lat: obj.lat, lng: obj.lng });
      
    }
    const renderMarkers = (map, maps) => {
      let marker = new maps.Marker({
        position: { lat: checkinLocation?.lat, lng: checkinLocation?.lng },
        map,
        title: 'Checkin'
      });
      return marker;
    };

    return (
      <Row>
        <div style={{ width: '25%' }}>Lokasi</div>
        <div style={{ position: 'relative', width: '70%', height: '200px', borderRadius: 10 }}>
          <GoogleMap
            defaultZoom={12}
            defaultCenter={[checkinLocation?.lat || -6.300400, checkinLocation?.lng || 106.635039]}
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API }}
            yesIWantToUseGoogleMapApiInternals
            onClick={_onClickCheckin}
            onGoogleApiLoaded={({ map, maps }) => !!checkinLocation ? renderMarkers(map, maps) : {}}
          >
          </GoogleMap>
        </div>
      </Row>
    )
  }

  const CheckOutWrapper: React.FC<any> = ({ value = {}, onChange }) => {
    function _onClickCheckout(obj) {
      setCheckoutLocation({ lat: obj.lat, lng: obj.lng })
      onChange?.({ lat: obj.lat, lng: obj.lng });
    }
    const renderMarkers = (map, maps) => {
      let marker = new maps.Marker({
        position: { lat: checkoutLocation?.lat, lng: checkoutLocation?.lng },
        map,
        title: 'Checkout'
      });
      return marker;
    };

    return (
      <Row>
        <div style={{ width: '25%' }}>Lokasi</div>
        <div style={{ position: 'relative', width: '70%', height: '200px', borderRadius: 10 }}>
          <GoogleMap
            defaultZoom={12}
            defaultCenter={[checkoutLocation?.lat || -6.300400, checkoutLocation?.lng || 106.635039]}
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API }}
            onClick={_onClickCheckout}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
          >
          </GoogleMap>
        </div>
      </Row>
    )
  }

  const LeftSection = () => {
    return (
      <>
        <Col span={12}>
          {firstSection.map((sectionBluePrint) => (
            <FormItem key={sectionBluePrint.name} sectionBluePrint={sectionBluePrint} />
          ))}
          {
            checkin?.active && !absentStatus.value && (
              <div className='checkin-wrapper'>
                {secondSection.map((sectionBluePrint) => (
                  <FormItem key={sectionBluePrint.name} sectionBluePrint={sectionBluePrint} />
                ))}
                <Form.Item label={null} name={'checkinLocation'} initialValue={checkinLocation}>
                  <CheckinWrapper />
                </Form.Item>
              </div>
            )
          }
          {
            checkout?.active && !!checkout.value && !absentStatus.value && (
              <div className='checkout-wrapper'>
                {thirdSection.map((sectionBluePrint) => (
                  <FormItem key={sectionBluePrint.name} sectionBluePrint={sectionBluePrint} />
                ))}
                <Form.Item label={null} name={'checkoutLocation'} initialValue={checkoutLocation}>
                  <CheckOutWrapper />
                </Form.Item>
              </div>
            )
          }
          
          {fourthSection.map((sectionBluePrint) => (
            <FormItem key={sectionBluePrint.name} sectionBluePrint={sectionBluePrint} />
          ))}
          {!props.isEditMode && (
            <HistoryTabs dataSource={props.listDetail} />
          )}

        </Col>

      </>
    );
  };

  return (
    <Row>
      <LeftSection />
    </Row>
  );
}
