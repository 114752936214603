import { ColumnsMasterScheduleWork, MasterScheduleWorkListResp } from "./MasterWorkingScheduleMapper.interface"

export function MasterWorkingListMapper(
  scheduleMasterList: MasterScheduleWorkListResp[]
) : ColumnsMasterScheduleWork[] {
  const content = scheduleMasterList ? scheduleMasterList : null
  let contentMap: ColumnsMasterScheduleWork[]

  if (content) {
    contentMap = content.map((data) => {
      const result = {
        key: data.id,
        working_schedule_name: data.name,
        total_work_hour: data.view_total_works_per_hour,
        status: data.is_active ? "Aktif" : "Non-Aktif",
        radius: data.radius,
        create_name: data.create_name,
        create_date: data.view_create_date,
        write_name: data.write_name,
        write_date: data.view_write_date,
      }

      return result
    })
  }

  return contentMap
}