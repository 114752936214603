import { employeeOvtReportMapper } from '../../../../../../@core/mapper/overtime/EmployeeOvtReport.mapper';
import getEmployeeOvertimeReport from '../../../../../../@core/interaction/overtime/GetEmployeeOvertimeReport.interaction'
import exportEmployeeOvtReport from '../../../../../../@core/interaction/overtime/ExportEmployeeOvtReport.interaction'


export default class HrEmployeeOvertimeReportUseCaseImpl {
  getOvertimeReport(params) {
    return getEmployeeOvertimeReport(params).then((res: any) =>
      employeeOvtReportMapper(res.data),
    );
  }

  exportEmployeeOvtReport(params) {
    return exportEmployeeOvtReport(params);
  }
}
