import { toast } from 'react-toastify';

export default class ToastService {
  error(message: string) {
    toast.error(message);
  }
  success(message: string) {
    toast.success(message);
  }
}
