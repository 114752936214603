import { DeleteLeaveResp, DeleteLeaveDTO } from '../../interface/leave/DeleteLeave.interface';
import Constant from '../../../config/Constants';
import API from '../../../services/API.service';

export default function deleteLeave(params: DeleteLeaveDTO): Promise<DeleteLeaveResp> {
  const URL = Constant.URL_BASE_ATTENDANCE + '/v3/leave/request';
  const data = params;

  return API.delete(URL, { data });
}
