import { Table, Tabs } from 'antd';
import moment from 'moment';
import { LeaveConfigConstant } from '../../../../../../../config/Leave.constant';

const { TabPane } = Tabs;

export default function HistoryTabs(props) {

  const { dataSource } = props;
  let tempDataSource = dataSource && dataSource.map(item => {
  const dataItem = {
    ...item,
    write_date: moment(item.write_date).format('DD-MM-YYYY HH:mm'),
  };

  return dataItem;
})

  return (
    <div className="tabs-wrapper">
      <Tabs defaultActiveKey="1" type="card">
        <TabPane tab="History" key="1">
        <Table columns={LeaveConfigConstant.HISTORY_TABLE_HEADER} dataSource={tempDataSource} />
        </TabPane>
      </Tabs>
    </div>
  );
}
