import { useHistory } from "react-router-dom";
import CardViewListTemplate from "../../../../../templates/card-view-list-template/CardViewListTemplate";
import { Component, ErrorInfo, useEffect, useState } from "react";
import ToastService from "../../../../../services/Toast.service";
import LocalNotificationService from "../../../../../services/LocalNotification.service";
import { CardViewListTemplatePagination } from "../../../../../templates/card-view-list-template/CardViewListTemplate.interface";
import { EmployeeRegionListConstant } from "./MasterRegionList.constant";
import EmployeeRegionListUseCase from "./MasterRegionList.usecase";
import { getRegionListMapper } from "../../../../../@core/mapper/employee/GetMasterRegion.mapper";

let page = 1;
let loadPagination = true;
let sort = 'write_date|desc'
const limit = 10;
let selectionId = []
let region_name

const constant = EmployeeRegionListConstant
const usecase = new EmployeeRegionListUseCase()
const toastService = new ToastService()
const localNotificationService = new LocalNotificationService();

export default function MasterRegionList() {
  const history = useHistory();

  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([]);
  let [tableScroll, setTableScroll] = useState({ y: 600, x: 'max-content' });
  let [visibleDeleteButton, setVisibleDeleteButton] = useState(false);
  let [disableExportButton, setDisableExportButton] = useState(false);
  let [visibleWarningModal, setVisibleWarningModal] = useState(false);
  let [pagination, setPagination] = useState<CardViewListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });

  let tableHeader: any = constant.TABLE_HEADER
  let searchColumnKey = constant.SEARCH_COLUMN_KEY
  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: true };

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: false,
        displayPathName: 'Master Region',
        href: "/v2/app/hr-employee/master-region",
      },
    ],
  };

  useEffect(() => {
    loadList()
  }, [])

  function loadList() {
    setIsLoading(true)
    const params = {
      page,
      limit,
      sort,
      region_name,
      with_meta: true,
    }

    usecase.getAll(params).then((res: any) => {
      setIsLoading(false)
      const content = res.data.content;
      const metaData = res.data.metadata;

      const resultDataSource = content?.length ? getRegionListMapper(content)
        : constant.DEFAULT_EMPTY_DATA_SOURCE

      setDataSource(resultDataSource)
      updatePaginationState(metaData.total_records);

      loadPagination = true
      setDisableExportButton(!content)
    }, (err) => {
      setIsLoading(false)
    })
  }

  const dropdownButton = {
    visible: true,
    disabled: isLoading,
    options: [
      {
        key: 'ekspor',
        disabled: disableExportButton,
        label: 'Ekspor',
        onClick: () => exportData(),
      },
      { key: 'active', label: 'Aktifkan', disabled: !visibleDeleteButton, onClick: () => {
        setActive(true, selectionId)
      } },
      { key: 'non-active', label: 'Non-Aktifkan', disabled: !visibleDeleteButton, onClick: () => {
        setActive(false, selectionId)
      } }
    ]
  };

  function setActive(status, ids) {
    setIsLoading(true);
    setVisibleWarningModal(false);

    const params = {
      ids,
      status
    };

    usecase.active(params).then(
      (res: any) => {
        const data = res.data
        if (data.code === 200) {
          const message = data.message
          localNotificationService.openSuccess(message, '', 1)
        }
        setVisibleWarningModal(false);
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      },
      (err) => {
        setIsLoading(false);
        toastService.error(err)
      },
    );
  }

  function exportData() {
    setIsLoading(true);
    const params = {
      ids: selectionId,
      sort,
      region_name,
    };

    usecase.export(params).then((resp: any) => {
      setIsLoading(false);
      const content = resp.data.content;
      if (resp.status === 200) {
        const data = content.data;
        let sampleArr = base64ToArrayBuffer(data);
        saveByteArray(content.filename, sampleArr);
      }
    }).catch(e => setIsLoading(false));
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  function processSort(outputSort) {
    if (outputSort && usecase.convertOutputSortToEndPointFormat(outputSort) !== sort) {
      page = 1;
      loadPagination = false;

      sort = usecase.convertOutputSortToEndPointFormat(outputSort);
      loadList();
    }
  }

  function processSearchColumn(outputSearchColumn) {
    let isLoadList = false;

    if (outputSearchColumn) {
      if (
        outputSearchColumn.region_name !== undefined &&
        outputSearchColumn.region_name !== region_name
      ) {
        region_name = outputSearchColumn.region_name;
        isLoadList = true;
      }
    }

    if (isLoadList) {
      page = 1;
      loadPagination = false;
      loadList();
    }
  }

  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionId) {
      selectionId = outputSelection;
      setVisibleDeleteButton(!!selectionId.length)
    }
  }

  const deleteButton = {
    visible: visibleDeleteButton,
    disabled: isLoading,
    onClick: () => {
      setVisibleWarningModal(true)
    }
  };

  const addButton = {
    onClick: () => {
      history.push('/app/hr-employee/master-region/new/');
    },
    disabled: isLoading,
  };

  const exportButton = {
    disabled: isLoading || disableExportButton,
    onClick: () => {
      setIsLoading(true)
      const params = {
        sort,
        region_name,
        with_meta: true,
      }

      usecase.export(params).then((resp: any) => {
        setIsLoading(false);
        const content = resp.data.content;
        if (resp.status === 200) {
          const data = content.data
          let sampleArr = base64ToArrayBuffer(data)
          saveByteArray(content.filename, sampleArr);
        }
      })
    }
  }

  function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  function saveByteArray(reportName, byte) {
    let blob = new Blob([byte], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    let fileName = reportName;
    link.download = fileName;
    link.click();
  };

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    const outputSort = output.sort;
    const outputSearchColumn = output.searchColumn;

    processSort(outputSort);
    processSearchColumn(outputSearchColumn);
    processSelection(output.selection);
    processPagination(outputPagination);
  };

  const onRow = (e) => {
    if (e.key !== 'search-input') {
      history.push("/app/hr-employee/master-region/" + e.key)
    }
  }

  return (
    <CardViewListTemplate
      breadcrumbConf={breadcrumbConf}
      addButton={addButton}
      deleteButton={deleteButton}
      exportButton={exportButton}
      dropdownButton={dropdownButton}
      tableData={tableData}
      isOnlyTable={true}
      EditButtonCard={{ visible: false, disabled: false, onClick: null }}
      DeleteButtonCard={{ visible: false, disabled: false, onClick: null }}
      selectedRowKeys={selectionId}
      tableHeader={tableHeader}
      tableOnChange={tableOnChange}
      onRow={onRow}
      tablePagination={pagination}
      tableScroll={tableScroll}
      isLoading={isLoading}
    />
  )
}
