import Constant from "../../../config/Constants";
import API from "../../../services/API.service";
import { 
  GetPendingSubmissionListDTO, 
  GetPendingSubmissionListResp 
} from "../../interface/leave/GetPendingSubmissionList.interface";

export function getPendingSubmissionList(params: GetPendingSubmissionListDTO): Promise<GetPendingSubmissionListResp> {
  const URL = Constant.URL_BASE_ATTENDANCE + '/v3/leave/check/list'
  return API.get(URL, { params })
}