class HrAttendanceService {
  createAndExecuteHref(url) {
    const hyperlinkElement = document.createElement('a');
    hyperlinkElement.href = url;
    hyperlinkElement.setAttribute('download', `Data_Attendance.pdf`);

    document.body.appendChild(hyperlinkElement);
    hyperlinkElement.click();
    hyperlinkElement.parentNode.removeChild(hyperlinkElement);
  }
}

export default HrAttendanceService;
