import { Avatar, Button, Card, Col, Input, List, Row, Space } from 'antd';
import { CardSubmissionPropsType } from './CardSubmissionListComponent.interface';
import './CardSubmissionListComponent.less';
import { useEffect, useState } from 'react';
import { aesGcmDecrypt } from '../../../../services/Encrypter';

export default function CardSubmissionListComponent(props: CardSubmissionPropsType) {
  const [images, setImages] = useState(['', '', '']);

  useEffect(() => {
    const copyImages = [...images];
    props.dataSource?.forEach(async (ds, i) => {
      const image = await aesGcmDecrypt(ds.url);

      copyImages[i] = image;
      setImages([...copyImages]);
    });
  }, [props.dataSource]);

  const title = props.title.split(' ').splice(0, 2).join(' ');

  const onClick = (state, id, submission_state) => {
    if (props.onClick) {
      props.onClick(state, id, props.submission_key, submission_state);
    }
  };

  return (
    <div id="card-submission-list-component">
      <Card
        title={props.title}
        extra={
          <a href={props.href} style={{ lineHeight: 1.5 }}>
            Lihat Semua
          </a>
        }
        style={{ minWidth: '100%', minHeight: '100%' }}
      >
        {props.submission_total > 0 && (
          <div className="total-submission">{props.submission_total + ' ' + title}</div>
        )}
        <List
          dataSource={props.dataSource}
          renderItem={(item, index) => (
            <List.Item>
              <Row className="row-section-submission" justify="space-between" align="middle">
                <Space style={{ width: '20%' }}>
                  <Avatar size="large" shape="square" src={images[index]} />

                  <Col>
                    <h1>{item.title}</h1>

                    <p style={{ color: 'black' }}>{item.description}</p>
                  </Col>
                </Space>

                {props.header.map((e) => {
                  return (
                    <Col span={4} className="column-section-submission">
                      <span style={{ color: '#80838B' }}>{e.title}</span>

                      {e.isCTA ? (
                        <Input.Password readOnly value={item[e.key]} />
                      ) : (
                        <p style={{ color: '#202020' }}>{item[e.key]}</p>
                      )}
                    </Col>
                  );
                })}

                <Space>
                  <Button
                    key="reject-button"
                    disabled={props.disabledButton}
                    onClick={() => onClick('reject', item.key, item.state)}
                    className="reject"
                  >
                    Tolak
                  </Button>
                  <Button
                    key="approve-button"
                    disabled={props.disabledButton}
                    onClick={() => onClick('approve', item.key, item.state)}
                    type="primary"
                    className="approve"
                  >
                    Setuju
                  </Button>
                </Space>
              </Row>
            </List.Item>
          )}
        />
      </Card>
    </div>
  );
}
