import React, { useMemo, useState, useRef } from 'react';
import { Button, Form, Tabs, Select } from 'antd';
import FormBuilder from 'antd-form-builder';
import { useForm } from 'antd/lib/form/Form';
import { RefSelectProps } from 'antd/lib/select';
import './RegisterFormTemplate.less';
import CustomStepsComponent from './components/CustomStepsComponent';
import { FormTemplateConstant } from './RegisterFormTemplate.constant';
import { ItemsSteps } from './components/CustomStepsComponent.interface';
import SelectLocationComponent from './components/SelectLocationComponent';
import ocbcLogo from './assets/ocbc-logo.png';
import CheckboxGroup from './components/CheckboxGroup';
import JoinWithUsUsecase from '../JoinWithUs.usecase';

const usecase = new JoinWithUsUsecase();
const enumSteps = ['Langkah 1', 'Langkah 2', 'Langkah 3'];
const tabsKey = ['province', 'city', 'sub-district', 'postal-code'];

export default function RegisterFormTemplate(props: any) {
  const [form] = useForm();
  // const forceUpdate = React.useCallback(() => updateState({}), []);
  // const [, updateState] = React.useState<any>();
  // const selectRef: React.Ref<RefSelectProps> = useRef();
  const [activeSteps, setActiveSteps] = useState<string>(enumSteps[0]);
  const [items, setItems] = useState<ItemsSteps[]>(FormTemplateConstant.ITEMS);
  // const [listCity, setListCity] = useState<any>([]);
  // const [listDistrict, setListDistrict] = useState<any>([]);
  // const [listSubdistrict, setListSubdistrict] = useState<any>([]);

  useMemo(() => {
    followingChangeActive(activeSteps);
  }, [activeSteps, props]);

  const FIRST_META = {
    columns: 3,
    formItemLayout: { labelCol: { span: 10 }, wrapperCol: { span: 24 } },
    fields: [
      {
        key: 'enterprices',
        label: 'Badan Usaha',
        widget: 'select',
        placeholder: 'Silahkan pilih badan usaha',
        colSpan: 1,
        options: props.listComType.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        }),
        formItemLayout: {
          wrapperCol: { span: 24 },
        },
      },
      {
        key: 'company_name',
        label: 'Nama Perusahaan',
        placeholder: 'Masukkan nama perusahaan',
        colSpan: 2,
        formItemLayout: {
          wrapperCol: { span: 24 },
        },
      },
      {
        key: 'bussiness_category',
        label: 'Kategori Usaha',
        widget: 'select',
        placeholder: 'Silahkan pilih badan usaha',
        colSpan: 1,
        options: props.listComCat.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        }),
        formItemLayout: {
          wrapperCol: { span: 24 },
        },
      },
      {
        key: 'ocbc_account_number',
        label: 'Nomor Rekening OCBC',
        placeholder: 'Masukkan nama perusahaan',
        colSpan: 2,
        formItemLayout: {
          wrapperCol: { span: 24 },
        },
        widgetProps: {
          prefix: (
            <img
              src={ocbcLogo}
              style={{ width: '24px', height: '24px', filter: 'brightness(120%)' }}
              alt=""
            />
          ),
        },
      },
      {
        key: 'total_employee',
        label: 'Jumlah Karyawan',
        widget: 'select',
        placeholder: 'Silahkan isi Jumlah Karyawan',
        colSpan: 1,
        formItemLayout: {
          wrapperCol: { span: 24 },
        },
        options: props.listSumEmp.map((item) => {
          return {
            label: item.value_limit,
            value: item.id,
          };
        }),
      },
      {
        key: 'company_code',
        label: 'Kode Perusahaan',
        placeholder: 'Masukkan nama perusahaan',
        colSpan: 2,
        formItemLayout: {
          wrapperCol: { span: 24 },
        },
        help: '*Gunakan 3-8 huruf atau angka, kode Perusahaan ini akan digunakan saat login',
      },
    ],
  };
  const [activeMeta, setActiveMeta] = useState<any>(FIRST_META);

  const SECOND_META = {
    columns: 3,
    formItemLayout: { labelCol: { span: 10 }, wrapperCol: { span: 24 } },
    fields: [
      {
        key: 'company_telp',
        label: 'No Telp Perusahaan',
        placeholder: 'Masukan no. telepon',
        help: 'Misalnya: 021999999',
        colSpan: 3,
        formItemLayout: {
          wrapperCol: { span: 24 },
        },
      },
      {
        key: 'company_address',
        label: 'Alamat Perusahaan',
        widget: SelectLocationComponent,
        placeholder: 'Provinsi, Kota, Kecamatan, Kode Pos',
        colSpan: 2,
        formItemLayout: {
          wrapperCol: { span: 24 },
        },
        widgetProps: {
          form
        }
      },
      {
        key: 'detail_company_address',
        label: 'Alamat Detail Perusahaan',
        placeholder: 'Masukan nama jalan atau nomor gedung ',
        colSpan: 3,
        formItemLayout: {
          wrapperCol: { span: 24 },
        },
      },
    ],
  };

  const THIRD_META = {
    columns: 3,
    formItemLayout: { labelCol: { span: 10 }, wrapperCol: { span: 24 } },
    fields: [
      {
        key: 'name',
        label: 'Nama',
        placeholder: 'Masukan nama',
        colSpan: 1,
        formItemLayout: {
          wrapperCol: { span: 24 },
        },
      },
      {
        key: 'email',
        label: 'Email',
        placeholder: 'Masukan email',
        colSpan: 2,
        formItemLayout: {
          wrapperCol: { span: 24 },
        },
      },
      {
        key: 'no_telp',
        label: 'No telepon',
        placeholder: 'Masukan nomor telepon',
        help: 'Misalnya: 021999999',
        colSpan: 3,
        formItemLayout: {
          wrapperCol: { span: 24 },
        },
      },
      {
        key: 'checkbox-group',
        widget: CheckboxGroup,
        colSpan: 3,
        formItemLayout: {
          wrapperCol: { span: 24 },
        },
      },
    ],
  };

  const ALL_META = [FIRST_META, SECOND_META, THIRD_META];

  function followingChangeActive(activeSteps: string) {
    const copyItems: ItemsSteps[] = items.map((step, index) => {
      if (index < enumSteps.indexOf(activeSteps)) {
        return { ...step, status: 'finish' };
      } else if (step.title === activeSteps) {
        return { ...step, status: 'active' };
      }

      return { ...step, status: 'wait' };
    });

    setItems([...copyItems]);
  }

  const onFinish = (e) => {
    const index = enumSteps.indexOf(activeSteps);
    if (index < 2) {
      setActiveMeta(ALL_META[index + 1]);
      setActiveSteps(enumSteps[index + 1]);
    }

    if (props.onFinish) {
      props.onFinish(e, index);
    }
  };

  const onBack = () => {
    const index = enumSteps.indexOf(activeSteps);
    setActiveMeta(ALL_META[index - 1]);
    setActiveSteps(enumSteps[index - 1]);
  };
  

  return (
    <Form
      form={form}
      layout="vertical"
      size="large"
      onFinish={onFinish}
      style={{ width: '100%', height: '100%', position: 'relative' }}
    >
      <div className="progress-form-step">
        <CustomStepsComponent stepsState={items} />
      </div>

      <div className="register-form-layout">
        <FormBuilder form={form} meta={activeMeta} />
      </div>

      <div className="toolbar-button-footer">
        {enumSteps.indexOf(activeSteps) >= 1 && (
          <Button onClick={onBack} className="toolbar-button" size="large">
            Kembali
          </Button>
        )}
        <Button htmlType="submit" className="toolbar-button" size="large" type="primary">
          Lanjutkan
        </Button>
      </div>
    </Form>
  );
}
