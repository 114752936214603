import moment from "moment"
import { MassLeaveDataSourceType } from "../../../pages/hr/leaves/templates/mass-leave-detail-template/MassLeaveDetailTemplate.interface"

export const SaveMassLeaveMapper = (value, employeeList) => {
  return {
    name: value.leave_name?.trim(),
    date_start_leave: FormatDateMassLeave(value.date_start_leave),
    date_end_leave: FormatDateMassLeave(value.date_end_leave),
    reduce_leave_rem: value.reduce_leave_rem || false,
    leave_config_id: value.leave_config_id || null,
    certain_employee: value.applies_to !== "Semua Karyawan" && value.applies_to,
    employee_id: !value.applies_to || value.applies_to === "Semua Karyawan"  
      ? employeeList.map(e => e.value) : value.employee_id,
  }
}

export const updateMassLeaveMapper = (
  detailDataSource: MassLeaveDataSourceType,
  list_employee_id: number[],
  selected_employee: any[]
) => {
  const employee_id = [...list_employee_id]
  selected_employee.forEach((e) => {
    employee_id.push(e.id)
  })

  return {
    id: detailDataSource.id,
    name: detailDataSource.name?.trim(),
    date_start_leave: FormatDateMassLeave(detailDataSource.date_start_leave),
    date_end_leave: FormatDateMassLeave(detailDataSource.date_end_leave),
    status_def_leave: detailDataSource.status_def_leave,
    reduce_leave_rem: detailDataSource.reduce_leave_rem,
    leave_config_id: detailDataSource.leave_config_id || null,
    employee_id: employee_id || null,
  }
}

export const GetListMassLeaveMapper = (value) => {
  const result = value.map(
    (e) => {
      return {
        key: e.id,
        leave_duration: e.leave_duration_int,
        date_end: FormatDateMassLeave(e.date_end_leave),
        leave_name: e.name,
        date_request: e.date_request,
        date_start: FormatDateMassLeave(e.date_start_leave),
        status: statusDefLeave(e.is_active),
        reduce_leave_rem: reduceLeaveRem(e.reduce_leave_rem),
        is_active: e.is_active,
        config_name: e.config_name,
        create_name: e.create_name,
        create_date: e.view_create_date,
        write_name: e.write_name,
        write_date: e.view_write_date,
      }
    }
  )
  
  return result
}

export function listConfigLeaveMapper(leaveConfig: any[]) {
  return leaveConfig.map((e) => {
    return {
      key: e.id,
      value: e.id,
      label: e.name
    }
  })
}

function reduceLeaveRem(reduce_leave_rem) {
  if (reduce_leave_rem) {
    return "Ya"
  }

  return "Tidak"
}

function statusDefLeave(status_def_leave) {
  if (status_def_leave) {
    return "Aktif"
  }

  return "Non Aktif"
}

function FormatDateMassLeave(date: string): string {
  if (!date) {
    return ""
  }

  return moment(date).format("YYYY-MM-DD")
}

export function FormatDateWriteDate(date: string): string {
  if (!date) {
    return ""
  }

  return moment(date).utc().format("DD/MM/YYYY HH:mm:ss")
}