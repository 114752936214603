import { useEffect,  useState } from 'react';
import AddMasterHolidayTemplate from '../template/AddMasterHolidayTemplate';
import {
  ToolbarDetailActionButton,
  ToolbarDetailDropdownButton,
} from '../../../../component/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface';
import MasterHolidayListUseCase from '../list-master-holiday/ListMasterHoliday.usecase';
import ToastService from '../../../../../../services/Toast.service';
import LocalNotificationService from '../../../../../../services/LocalNotification.service';
import { DetailDataMasterHoliday } from '../template/AddMasterHolidayTemplate.interface';

const usecase = new MasterHolidayListUseCase();
const toastService = new ToastService();
const localNotificationService = new LocalNotificationService();
const moment = require('moment');

export default function AddMasterHoliday({ match }) {
  const masterId = match.params.mtrHolidayId;

  const [viewMode, setViewMode] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [detailResp, setDetailResp] = useState<any>();
  const [detailDataSource, setDetailDataSource] = useState<DetailDataMasterHoliday>(null)

  useEffect(() => {
    if (masterId) {
      usecase
        .getDetail(masterId)
        .then((res) => {
          const content = res.data.content;
          setDetailDataSource({
            holidayName: content.name,
            startDate: content.date_start,
            endDate: content.date_stop,
            holidayLength: content.total_holidays + " Hari",
            historyId: content.history_id,
            isActive: content.is_active,
          })
          setDetailResp(content)
          setIsLoading(false)
        })
        .catch((err) => {
          toastService.error(err);
        });
    } else {
      setViewMode(false)
    }
  }, []);

  // constant view
  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Master Hari Libur',
        href: '/v2/app/attendance/master-holiday',
      },
      {
        clickable: false,
        displayPathName: 'Baru',
        href: '/v2/app/attendance/master-holiday/new/',
      },
    ],
  };

  const submitButton = { active: !masterId || !viewMode, disabled: isLoading };

  const firstActionButton: ToolbarDetailActionButton = {
    label: 'UBAH',
    type: 'primary',
    active: !!masterId && viewMode,
    disabled: false,
    onClick: () => {
      setViewMode(false);
    },
  };

  const secondActionButton: ToolbarDetailActionButton = {
    label: 'TAMBAH',
    type: 'primary',
    active: !!masterId,
    disabled: false,
    onClick: () => {
      window.location.href = '/v2/app/attendance/master-holiday/new/';
    },
  };

  const thirdActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    disabled: false,
    onClick: () => {
      const back = window.confirm('Anda Ingin Kembali');
      if (back) {
        window.location.href = '/v2/app/attendance/master-holiday';
      }
    },
  };
  const dropdownActionButton: ToolbarDetailDropdownButton = {
    disabled: false,
    visible: !!masterId,
    options: [
      {
        key: 'non-aktif',
        disabled: !detailDataSource?.isActive,
        label: 'Non-Aktifkan',
        onClick() {
          setActive(false);
        },
      },
    ],
  };

  function setActive(status) {
    setIsLoading(true)

    const params = {
      ids: [masterId],
      status,
    };

    usecase.active(params).then(
      (res: any) => {
        setIsLoading(false);
        const content = res.data.content;
        if (content.code === 200) {
          const message = content.message.split('- ')[1];
          localNotificationService.openSuccess(message, '', 1);
        }
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      },
      (err) => {
        setIsLoading(false);
        toastService.error(err);
      },
    );
  }

  function getHolidayLength(startDate, endDate) {
    const start_date = new Date(startDate).setHours(0, 0, 0, 0);
    const end_date = new Date(endDate).setHours(0, 0, 0, 0);
    const result = moment(end_date).diff(moment(start_date), 'days');
    return result + 1
  }

  const onFinish = (value) => {
    setIsLoading(true);
    const body = {
      recurring: false,
      name: value.name?.trim(),
      date_start: moment(value.start_date).format('YYYY-MM-DD'),
      date_stop: moment(value.end_date).format('YYYY-MM-DD'),
      total_holidays: getHolidayLength(value.start_date, value.end_date),
    };

    if (!masterId) {
      usecase
        .save(body)
        .then((res) => {
          const data = res.data;
          if (data.code === 200) {
            const message = data.message;
            localNotificationService.openSuccess(message, '', 1);
          }
          setTimeout(() => {
            window.location.href = '/v2/app/attendance/master-holiday';
          }, 1500);
        })
        .catch((err) => {
          toastService.error(err);
          setIsLoading(false);
        });
    } else {
      const data = {
        ...detailResp,
        ...body,
      };

      usecase
        .update(data)
        .then((res) => {
          const data = res.data;
          if (data.code === 200) {
            const message = data.message;
            localNotificationService.openSuccess(message, '', 1);
          }
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        })
        .catch((err) => {
          toastService.error(err);
          setIsLoading(false);
        });
    }
  };

  return (
    <AddMasterHolidayTemplate
      breadcrumbConf={breadcrumbConf}
      dropdownActionButton={dropdownActionButton}
      firstActionButton={firstActionButton}
      secondActionButton={secondActionButton}
      thirdActionButton={thirdActionButton}
      detailDataSource={detailDataSource}
      viewMode={viewMode}
      fourthActionButton={{ active: false }}
      submitButton={submitButton}
      historyId={detailDataSource?.historyId}
      isLoading={isLoading}
      onFinish={onFinish}
      isDetail={!!masterId}
    />
  );
}
