import Constant from "../../../../../config/Constants"
import API from "../../../../../services/API.service"

export default class ClaimReportFilterUseCaseImpl {
  getJobList() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/job"
    return API.get(URL).then((res:any) => 
      {
        const result = res.data.content.map(element => {
          return { label: element['job_name'], value: JSON.stringify(element), key: element['id'] }
        })
        return result;
      })
  }
  getEmployeeList() {
    const param: any = {
      page: 1,
      limit: 500
    }
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/emp"
    return API.get(URL, param).then((res: any) => {
      const result = res.data.content.map(element => {
        return { label: element['alternative_name'], value: JSON.stringify(element), key: element['id'] }
      })
      return result;
    })
  }
  getClaimConfigList() {
    const URL = Constant.URL_BASE_CLAIM + "/v3/lov/claim"
    return API.get(URL).then((res:any) => 
      { 
        const result = res.data.content.map(element => {
          return { label: element['name'], value: JSON.stringify(element), key: element['id'] }
        })
        return result;
      })
  }

  getClaimReportList(params) {
    const URL = Constant.URL_BASE_CLAIM + "/v3/report"
    return API.get(URL, { params })
  }

}
