import { DatePicker, Pagination, Select, Table } from "antd"
import { DataItem } from "../../../../../../../templates/editable-list-template/EditableListTemplate.interface";
import moment from "moment";
import './EditableTableStyle.less'
import { useMemo, useState } from "react";

export const EditableTableEmployeeWorking = ({ value, onChange, disabled, columns }) => {
  const dataSource = useMemo(() => {
    const rowTableChange = value.rowTableChange
    if (Object.keys(rowTableChange).length) {
      const newData = value.tableData.dataSource.map((data) => {
        if (rowTableChange[data.key]) {
          return {
            ...data,
            ...rowTableChange[data.key]
          }
        }

        return data
      })

      return newData
    } else {
      return value.tableData.dataSource
    }
  }, [value.rowTableChange])

  const onInputRowChange = (v, field, key) => {
    const copyValue = JSON.parse(JSON.stringify(value))
    if (!copyValue.rowTableChange[key]) {
      copyValue.rowTableChange[key] = value.tableData.dataSource.find(
        (data) => {
          return data.key === key
        })
    }
    if (field === "nama_jadwal_kerja") {
      copyValue.rowTableChange[key][field] = value.listSchedule.find(e => e.key === v).label
    } else {
      copyValue.rowTableChange[key][field] = v
    }
    sendOutputTable(copyValue)
  }

  const onSelectChange = (selectedRowKey) => {
    const copyValue = JSON.parse(JSON.stringify(value))
    copyValue.selectedRowKeys = {
      ...copyValue.selectedRowKeys,
      [copyValue.pagination.current]: selectedRowKey
    };
    sendOutputTable(copyValue);
  };

  const rowSelection = {
    selectedRowKeys: value.selectedRowKeys[value.pagination.current],
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.key === 'search-input' || record.key === 'empty-data' || disabled,
    }),
  };

  const renderCell = (text: any, record: DataItem, dataIndex: string) => {
    const column = columns.find(col => col.dataIndex === dataIndex);
    const header = column

    if (header.isFormatDate !== undefined && header.isFormatDate) {
      return moment(text).format('YYYY-MM-DD')
    }

    if (column && (value.selectedRowKeys[value.pagination.current].includes(record.key) || value.editableRowKey === record.key)) {
      switch (column.type) {
        case 'select':
          return (
            <Select
              disabled={disabled}
              style={{ width: "100%" }}
              key={`${column.dataIndex}${record.key}`}
              defaultValue={text}
              onChange={(value) => onInputRowChange(value, dataIndex, record.key)}
            >
              {value.listSchedule.map(option => (
                <Select.Option
                  key={option.key}
                  value={option.value}
                >
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          )
        case 'date':
          const defaultDate = text ? moment(text, 'YYYY-MM-DD') : null
          return <DatePicker
            key={`${column.dataIndex}${record.key}`}
            disabled={disabled}
            defaultValue={defaultDate}
            format="YYYY-MM-DD"
            onChange={(_, date) => onInputRowChange(date, dataIndex, record.key)}
          />
        case 'payment-date':
          const defaultD = text ? moment(text, 'YYYY-MM-DD') : null
          return record.state_payment === "Dibayar" ? 
            <DatePicker
              key={`${column.dataIndex}${record.key}`}
              disabled={disabled}
              defaultValue={defaultD}
              format="YYYY-MM-DD"
              onChange={(_, date) => onInputRowChange(date, dataIndex, record.key)}
            /> : null
        default:
          break;
      }
    }
    return text;
  };

  const sendOutputTable = (v) => {
    if (onChange) {
      onChange(v);
    }
  };

  const tableOnChange = (pagination, filters, sort, extra) => {
    const copyValue = JSON.parse(JSON.stringify(value))
    copyValue.sort = {
      columnKey: sort.columnKey,
      field: sort.field,
      order: sort.order,
    };
    sendOutputTable(copyValue);
  };

  const paginationOnChange = (page, pageSize) => {
    const copyValue = JSON.parse(JSON.stringify(value))
    copyValue.pagination = { ...copyValue.pagination, current: page, pageSize };
    if (!copyValue.selectedRowKeys[page]) {
      copyValue.selectedRowKeys = { ...copyValue.selectedRowKeys, [page]: [] }
    }

    sendOutputTable(copyValue);
  };

  const onRow = (record: DataItem) => {
    return {
      onClick: () => {
        if (record.key !== value.editableRowKey) {
          onChange({ ...value, editableRowKey: record.key })
        }
      },
    };
  };

  const updatedColumns = columns && columns.map(column => {
    if (column.render) return column
    
    return {
      ...column,
      render: (text: any, record: DataItem) => renderCell(text, record, column.dataIndex),
    }
  });

  return (
    <div className="editable-table-employee">
      <div className="toolbar" style={{ display: 'flex', justifyContent: 'end' }}>
        {
          value.pagination.total ? (
            <Pagination
              current={value.pagination.current}
              pageSize={value.pagination.pageSize}
              total={value.pagination.total}
              size={value.pagination.size}
              showSizeChanger={false}
              onChange={paginationOnChange}
            />
          ) : null
        }
      </div>

      <Table
        className="no-expand-icon-table"
        dataSource={dataSource}
        pagination={false}
        rowSelection={rowSelection}
        onChange={tableOnChange}
        onRow={onRow}
        scroll={{ x: 'max-content' }}
        columns={updatedColumns}
      />
    </div>
  )
}