import moment from "moment";

export function getListMasterJobMapper(listJob: any[]) {
  let result = [];
  listJob.map((item) => {
    const temp = {
      key: item.id,
      job_name: item.job_name,
      department_name: item.department_name,
      job_atasan: item.job_atasan,
      atasan_langsung: item.atasan_langsung,
      sum_emp: item.sum_emp,
      is_active: item.is_active ? 'Aktif' : 'Tidak Aktif',
      create_name: item.create_name,
      create_date: moment(item.view_create_date).format('YYYY-MM-DD HH:mm'),
      write_name: item.write_name,
      write_date: moment(item.view_write_date).format('YYYY-MM-DD HH:mm'),
    }

    result.push(temp)
  })

  return result
}
