import Constant from '../../../config/Constants';
import API from '../../../services/API.service';
import { LeaveTypeResp } from '../../interface/leave/GetLeaveTypes.interface';

export default function getLeaveTypes(): Promise<LeaveTypeResp> {
  const URL = Constant.URL_BASE_ATTENDANCE + '/v3/lov/leave-config';
  return API.get(URL, {}).then((res) => res.data);
}

export const LeaveTypesResolvedMock: LeaveTypeResp = {
  status: 200,
  content: [
    {
      category: 'category 1',
      company_id: 111,
      create_date: '1/1/21',
      create_uid: 12,
      id: 20,
      is_active: false,
      name: 'Test User',
      write_date: '1/1/21',
      write_uid: 12,
    },
  ],
};

export const LeaveTypesRejectedMock: LeaveTypeResp = {
  status: 401,
  content: null,
  error: {
    code: '401',
    message: 'Invalid username or password',
    reasons: {},
  },
};
