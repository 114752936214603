export default function SidebarItem({ menu, children, ...props }) {
  const actions = menu.action;
  let actionType = !!actions ? actions.split(',')[0] : '';
  let actionID = !!actions ? actions.split(',')[1] : '';

  let href = '';

  // TODO rewrite url each menu
  switch (menu.id) {
    case 2:
      if (menu.isAdmin) {
        href = '/v2/app/admin-dashboard';
        break;
      }
      if (menu.isManager) {
        href = '/v2/app/manager-dashboard';
        break;
      }
      if (menu.isEmployee) {
        href = '/v2/app/employee-dashboard';
        break;
      }
      href = '/v2/app/dashboard';
      break;
    case 359:
      href = '/v2/app/hr-attendance/check-missing-attendance';
      break;
    case 354:
      href = '/v2/app/hr-leave/correction';
      break;
      case 194:
      href = '/v2/app/hr-leave/my-submission';
      break;
    case 196:
      href = '/v2/app/hr-leave/employee-submission';
      break;
    case 197:
      href = '/v2/app/hr-leave/employee-approval';
      break;
    case 170:
      href = '/v2/app/attendance/master-working-schedule';
      break;
    case 172:
      href = '/v2/app/attendance/employee-working-schedule';
      break;
    case 171:
      href = '/v2/app/attendance/master-holiday';
      break;
    case 360:
      href = '/v2/app/attendance/recurring-holidays';
      break;
    case 293:
      href = '/v2/app/hr-overtime/employee-approval-overtime';
      break;
      case 345:
      href = '/v2/app/hr-attendance/employee-attendance';
      break;
    case 175:
      href = '/v2/app/hr-attendance/recap-attendance';
      break;
    case 361:
      href = '/v2/app/hr-overtime/employee-overtime-configuration/';
      break;
    case 362:
      href = '/v2/app/hr-overtime/company-overtime-configuration/';
      break;
    case 294:
      href = '/v2/app/hr-overtime/employee-overtime-report-filter/';
      break;
    case 198:
      href = '/v2/app/hr-leave/all-request';
      break;
    case 200:
      href = '/v2/app/hr-leave/leave-usage-report/filter/';
      break;
    case 290:
      href = '/v2/app/hr-overtime/my-submission-overtime';
      break;
    case 292:
      href = '/v2/app/hr-overtime/employee-submission-overtime';
      break;
    case 193:
      href = '/v2/app/hr-leave/leave-configuration';
      break;
    case 111:
      href = '/v2/app/hr-employee/master-department';
      break;
    case 329:
      href = '/v2/app/hr-employee/master-region';
      break;
    case 330:
      href = '/v2/app/hr-employee/master-location';
      break;
    case 128:
      href = '/v2/app/hr-employee/employee-terminate';
      break;
    case 235:
      href = '/v2/app/hr-claim/employee-submission-claim';
      break;
    case 233:
      href = '/v2/app/hr-claim/my-submission-claim';
      break;
    case 280:
      href = '/v2/app/hr-loan/submission-loan';
      break;
    case 283:
      href = '/v2/app/hr-loan/approval-loan';
      break;
    case 286:
      href = '/v2/app/hr-loan/loan-report/filter/';
      break;
    case 126:
      href = '/v2/app/hr-employee/list-master-job';
      break;
    case 109:
      href = '/v2/app/hr-employee/list-employee';
      break;
    case 144:
      href = '/v2/app/hr-company/company-configuration/';
      break;
    case 142:
      href = '/v2/app/hr-employee/list-access';
      break;
    case 141:
      href = '/v2/app/hr-employee/list-user';
      break;
    case 232:
      href = '/v2/app/hr-claim/configuration/list-configuration';
      break;
    case 239:
      href = '/v2/app/hr-claim/employee-claim-report-filter/';
      break;
    case 237:
      href = '/v2/app/hr-claim/claim-payment/';
      break;
    case 278:
      href = '/v2/app/hr-loan/configuration/list-configuration';
      break;
    case 300:
      href = '/v2/app/hr-news/news-list';
      break;
    case 129:
      href = '/v2/app/hr-employee/structure-organization';
      break;
    case 236:
      href = '/v2/app/hr-claim/employee-approval-claim';
      break;
    case 284:
      href = '/v2/app/hr-loan/loan-payment/';
      break;
    case 378:
      href = '/v2/app/admin-dashboard';
      break;
    case 302:
      href = '/v2/app/employee-dashboard';
      break;
    case 323:
      href = '/v2/app/manager-dashboard';
      break;
    case 379:
      href = '/v2/app/hr-attendance/recap-radius';
      break;
    //superadmin
    case 380:
      href = '/v2/app/admin/company-approval';
      break;
    case 383:
      href = '/v2/app/admin/company-registration';
      break;
    case 384:
      href = '/v2/app/admin/banner-list';
      break;
    default:
      break;
  }

  return (
    <a href={href} data-action-model={actionType} data-action-id={actionID} {...props}>
      {children}
    </a>
  );
}
