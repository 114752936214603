import Moment from 'moment';

export function employeeOvtMapper(response, hrefPath: string) {
  const content = response.content ? response.content : null;
  let contentMap = null;

  if (content) {
    contentMap = content.map((data) => {
      const item = {
        ...data,
        key: data.id,
        create_date: Moment(data.view_create_date).format('DD/MM/YYYY hh:mm'),
        write_date: Moment(data.view_write_date).format('DD/MM/YYYY hh:mm'),
        href: `${hrefPath}${data.id}`,
    };
      return item;
    });
  }

  return {
    content: contentMap,
    metaData: response.metadata,
  };
}
