import AddEmployeeDepartmentTemplate from "../template/AddMasterDepartmentTemplate";
import { ToolbarDetailActionButton } from "../../components/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface";
import { useEffect, useState } from "react";
import ToastService from "../../../../../services/Toast.service";
import LocalNotificationService from "../../../../../services/LocalNotification.service";
import AddMasterDepartmentUsecase from "./AddMasterDepartment.usecase";

const usecase = new AddMasterDepartmentUsecase()
const toastService = new ToastService()
const localNotificationService = new LocalNotificationService();

export default function AddMasterDepartment() {
  const [departmentList, setDepartmentList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    init()
  }, [])

  async function init() {
    const listDept = await usecase.getDept()
    setDepartmentList([...listDept])
  }

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Master Departemen',
        href: "/v2/app/hr-employee/master-department",
      },
      {
        clickable: false,
        displayPathName: 'Baru',
        href: "/v2/app/hr-employee/master-department/new/",
      }
    ],
  };

  const submitButton = { active: true, disabled: false };
  const firstActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: false,
    onClick: () => {
      const back = window.confirm("Anda Ingin Kembali")
      if (back) {
        window.location.href = "/v2/app/hr-employee/master-department"
      }
    },
  };

  const onFinish = (e) => {
    setIsLoading(true)
    const body = {
      name: e.name?.trim(),
      parent_id: e.parent_id,
      active: true,
    }
    usecase.create(body).then(
      (res) => {
        const data = res.data
        if (data.code === 200) {
          const message = data.message
          localNotificationService.openSuccess(message, '', 1)
        }
        setTimeout(() => {
          window.location.href = "/v2/app/hr-employee/master-department"
        }, 1000)
      }
    ).catch((err) => {
      toastService.error(err)
      setIsLoading(false)
    })
  }
  
  return (
    <div className="add-employee-department">
      <AddEmployeeDepartmentTemplate 
        breadcrumbConf={breadcrumbConf}
        listDepartment={departmentList}
        dropdownActionButton={{ visible: false }}
        firstActionButton={firstActionButton}
        secondActionButton={{ active: false }}
        thirdActionButton={{ active: false }}
        fourthActionButton={{ active: false }}
        onFinish={onFinish}
        submitButton={submitButton}
        isLoading={isLoading}
        historyId={null}
      />
    </div>
  )
}