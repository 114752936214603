import React from 'react';
import Constants from '../../../config/Constants';
import HrLeaveService from './HrLeaveService';
import { Col, InputNumber, Row } from 'antd';
import { Table, Form, Modal, Space, Skeleton } from 'antd';
import Title from 'antd/lib/typography/Title';
import ToastService from '../../../services/Toast.service';

const hrLeaveService = new HrLeaveService();
const toastService = new ToastService();

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export default function HrLeaveList() {
  let [list, setList] = React.useState();
  let [page, setPage] = React.useState(Constants.DEFAULT_FIRST_PAGE);
  let [limit, setLimit] = React.useState(Constants.DEFAULT_PAGE_SIZE);
  let [filter, setFilter] = React.useState();
  let [detail, setDetail] = React.useState(null);
  let [isTableLoading, setTableLoading] = React.useState(false);
  let [isModalVisible, setModalVisible] = React.useState(false);
  let [employeeName, setEmployeeName] = React.useState(null);
  let [leaveRem, setLeaveRem] = React.useState(0);
  let [leaveTotal, setLeaveTotal] = React.useState(0);

  const columns: any = [
    {
      dataIndex: 'name',
      title: 'Nama Karyawan',
    },
    {
      dataIndex: 'leave_type_name',
      title: 'Jenis Cuti',
    },
    {
      dataIndex: 'leave_plafon',
      title: 'Plafon',
      align: 'right',
    },
    {
      dataIndex: 'leave_total',
      title: 'Terpakai',
      align: 'right',
    },
    {
      dataIndex: 'leave_rem',
      title: 'Sisa',
      align: 'right',
    },
    {
      key: 'action',
      title: 'Action',
      render: (row) => {
        return (
          <Space>
            <a
              href={'/v2/hr-leave/patch/edit/' + row.id}
              onClick={(e) => {
                e.preventDefault();
                showModal(row.id);
              }}
            >
              Edit
            </a>
          </Space>
        );
      },
    },
  ];

  const getList = () => {
    hrLeaveService
      .getList(page, limit, filter, null, null)
      .then((response: any) => {
        if (response.status === Constants.RESP_OK) {
          setList(response.data.content);
        }
      })
      .catch((error) => {
        setTableLoading(false);
      });
  };

  const handleChangePage = (pageNum, lmt, fltr) => {
    setLimit(lmt);
    setPage(pageNum);
    setFilter(fltr);
    getList();
  };

  const handleFilterChange = (fltr) => {
    setFilter(fltr);
    getList();
  };

  const showModal = (id) => {
    hrLeaveService
      .getDetail(id)
      .then((response: any) => {
        if (response.status === Constants.RESP_OK) {
          setDetail(response.data.content);
          setLeaveRem(response.data.content.leave_rem);
          setLeaveTotal(response.data.content.leave_total);
          setModalVisible(true);
        }
      })
      .catch((error) => {
        toastService.error('Failed getting detail information');
      });
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleOk = () => {
    let updData = detail;
    updData.leave_total = leaveTotal;
    updData.leave_rem = leaveRem;
    hrLeaveService.update(updData).then((resp: any) => {
      if (resp.status === Constants.RESP_OK) {
        getList();
      }
      setModalVisible(false);
    });
  };

  React.useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Row>
        <Col span={24}>
          <Title level={4}>Master Data Cuti Karyawan</Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {isTableLoading ? (
            <Skeleton />
          ) : (
            <Table
              loading={isTableLoading}
              columns={columns}
              dataSource={list}
              pagination={{
                onChange: (pageNum, lmt) => {
                  handleChangePage(pageNum, lmt, null);
                },
                size: 'small',
                defaultCurrent: Constants.DEFAULT_FIRST_PAGE,
                pageSize: limit,
                pageSizeOptions: Constants.DEFAULT_PAGE_OPTIONS,
                position: ['topRight', 'bottomRight'],
              }}
            />
          )}
        </Col>
      </Row>

      <Modal
        title="Ubah Data Cuti"
        visible={isModalVisible}
        onOk={handleOk}
        okText="Update"
        onCancel={handleCancel}
      >
        {detail === null ? (
          <Skeleton />
        ) : (
          <Form {...layout} name="basic" initialValues={{ remember: true }}>
            <Form.Item label="Nama Karyawan" name="employee_name">
              <Space>{detail.name}</Space>
            </Form.Item>
            <Form.Item
              label="Terpakai"
              name="leave_total"
              rules={[{ required: true, message: 'Masukkan total cuti yang sudah terpakai' }]}
            >
              <InputNumber
                min={0}
                defaultValue={leaveTotal}
                onChange={(val) => {
                  setLeaveTotal(val);
                }}
              />
            </Form.Item>
            <Form.Item
              label="Sisa Cuti"
              name="leave_rem"
              rules={[{ required: true, message: 'Masukkan sisa cuti' }]}
            >
              <InputNumber
                min={0}
                defaultValue={leaveRem}
                onChange={(val) => {
                  setLeaveRem(val);
                }}
              />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
}
