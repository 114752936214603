import Constant from "../../../../../config/Constants"
import API from "../../../../../services/API.service"

export default class DetailMasterRegionUsecase {
  getDetail(id) {
    const URL = Constant.URL_BASE_COMPANY + `/v3/master-region/detail/${id}`
    return API.get(URL)
  }

  active(body) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/master-region/update-state"
    return API.put(URL, body)
  }

  update(body) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/master-region/update"
    return API.put(URL, body)
  }

  getLocationList(params) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/master-location/list"
    return API.get(URL, { params })
  }
}