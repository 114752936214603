/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Row } from 'antd';
import FormBuilder from 'antd-form-builder';
import { useForm } from 'antd/lib/form/Form';
import Breadcrumb from '../../../../components/breadcrumb/Breadcrumb';
import UploadImage from '../../../../components/upload-image/UploadImage';
import { UploadBannerTemplatePropsType } from './UploadBannerTemplate.interface';
import { useEffect } from 'react';
import ToolbarButtonDetailTemplate from '../../../hr/employee/components/toolbar-button-detail-template/ToolbarButtonDetailTemplate';
import moment from 'moment';

export default function UploadBannerTemplate(props: UploadBannerTemplatePropsType) {
  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue(props.detailDataSource);
  }, [props.detailDataSource]);

  const UploadField = ({ onChange, value, viewMode }) => {
    function onChangeArchiveImg(output, procedure) {
      onChange?.({ file: output.file });
    }

    return (
      <UploadImage
        onChange={onChangeArchiveImg}
        componentId={'archive_img'}
        selectedImg={value?.img}
        canEdit={!viewMode}
      />
    );
  };

  const disabledStartDate = (current) => {
    const end_date = form.getFieldsValue().end_date
    let date_before = new Date()
    date_before.setDate(date_before.getDate() - 1);

    let date_after

    if (end_date) {
      date_after = moment(end_date).toDate(); 
    }
    
    return current && (current.valueOf() > date_after || current.valueOf() < date_before);
  };

  const disabledEndDate = (current) => {
    const start_date = form.getFieldsValue().start_date
    let date

    if (start_date) {
      date = moment(start_date).toDate(); 
    } else {
      date = new Date();
      date.setDate(date.getDate() - 1);
    }
    
    return current && current.valueOf() < date;
  };

  const meta = {
    columns: 2,
    formItemLayout: { wrapperCol: { span: 22 } },
    fields: [
      {
        key: 'name',
        label: 'Nama Promosi',
        placeholder: 'Ketik disini...',
        required: true,
        viewMode: props.viewMode,
        message: 'Nama Promosi harus diisi',
        colSpan: 1,
        initialValue: props.detailDataSource?.name,
      },
      {
        key: 'start_date',
        widget: 'date-picker',
        label: 'Tanggal Mulai Tayang',
        placeholder: 'Pilih tanggal...',
        required: true,
        viewMode: props.viewMode,
        message: 'Tanggal Mulai Tayang harus diisi',
        colSpan: 1,
        initialValue: formatDate(props.detailDataSource?.start_date),
        widgetProps: {
          disabledDate: disabledStartDate
        }
      },
      {
        key: 'redirect_link',
        label: 'Redirection Link',
        placeholder: 'Ketik disini...',
        required: true,
        viewMode: props.viewMode,
        message: 'Redirection Link harus diisi',
        colSpan: 1,
        initialValue: props.detailDataSource?.redirect_link,
      },
      {
        key: 'end_date',
        widget: 'date-picker',
        label: 'Tanggal Berakhir Tayang',
        placeholder: 'Pilih tanggal...',
        required: true,
        viewMode: props.viewMode,
        message: 'Tanggal Berakhir Tayang harus diisi',
        colSpan: 1,
        initialValue: formatDate(props.detailDataSource?.end_date),
        widgetProps: {
          disabledDate: disabledEndDate
        }
      },
      {
        key: 'position',
        label: 'Posisi',
        widget: 'select',
        required: true,
        viewMode: props.viewMode,
        message: 'Posisi harus diisi',
        options: props.listPosition,
        colSpan: 1,
        initialValue: props.detailDataSource?.position,
      },
      {
        key: 'content',
        label: 'Konten',
        placeholder: 'Ketik disini...',
        widget: 'textarea',
        required: true,
        viewMode: props.viewMode,
        message: 'Konten harus diisi',
        colSpan: 1,
        initialValue: props.detailDataSource?.content,
      },
      {
        key: 'upload_file',
        label: 'Upload',
        widget: UploadField,
        required: true,
        colSpan: 2,
        widgetProps: {
          viewMode: props.viewMode,
          onChange: (e) => {
            console.log(e);
          },
        },
        formItemLayout: { wrapperCol: { span: 23 } },
      },
    ],
  };

  function formatDate(date) {
    return props.viewMode ? date?.format("YYYY-MM-DD") : date
  }

  const onFieldsChange = (e) => {
    if (props.onFieldChange) {
      props.onFieldChange(e, form);
    }
  };

  const onFinish = (e) => {
    if (props.onFinish) {
      props.onFinish(form.getFieldsValue());
    }
  };

  return (
    <div className="upload-banner">
      <Row justify="space-between">
        <Breadcrumb breadcrumbConf={props.breadcrumbConf} />
      </Row>

      <Form
        form={form}
        size="large"
        layout="vertical"
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        style={{ width: '100%', height: '100%', position: 'relative', marginTop: '10px' }}
      >
        <ToolbarButtonDetailTemplate
          submitButton={props.submitButton}
          firstActionButton={props.firstActionButton}
          secondActionButton={props.secondActionButton}
          thirdActionButton={props.thirdActionButton}
          fourthActionButton={props.fourthActionButton}
          dropdownActionButton={props.dropdownActionButton}
          isLoading={props.isLoading}
        />

        <FormBuilder form={form} meta={meta} />
      </Form>
    </div>
  );
}
