import Moment from 'moment';
import { getLeaveStatus } from './LeaveState.mapper';
import { LeaveDetailApproval, LeaveApproval } from '../../entity/SubmissionLeaveDetail.entity';
import { JobListObjById } from '../../entity/Job.entity';
import { GetLeaveDetailResp } from '../../interface/leave/GetLeaveDetail.interface';
import { GetLeaveRequestDetailContent } from '../../interface/leave/GetLeaveRequestDetail.interface';
import { GetLeaveApprovalDetailContent } from '../../interface/leave/GetLeaveApprovalDetail.interface';

export function leaveDetailFormMapper(
  employeeDetail: GetLeaveRequestDetailContent,
  jobList: JobListObjById,
): GetLeaveDetailResp {
  const result = {
    submissionDate: { value: '' },
    employee: { value: '' },
    position: { value: '' },
    department: { value: '' },
    description: { value: '' },
    leaveType: { value: '' },
    remainsDayOff: { value: '' },
    leaveDate: { value: '' },
    leaveDuration: { value: '' },
    leaveAll: { value: '' },
  };

  const archiveImg = {
    firstArchiveImg: { sourceImg: '', canEdit: false },
    secondArchiveImg: { sourceImg: '', canEdit: false },
    thirdArchiveImg: { sourceImg: '', canEdit: false },
    fourthArchiveImg: { sourceImg: '', canEdit: false },
  };

  if (JSON.stringify(employeeDetail) !== '{}') {
    const date_start_leave = Moment(employeeDetail.date_start_leave).format('DD/MM/YYYY');
    const date_end_leave = Moment(employeeDetail.date_end_leave).format('DD/MM/YYYY');
    const jobDetail = jobList[employeeDetail.job_id];

    result.submissionDate.value = Moment(employeeDetail.date_request).format('DD/MM/YYYY');
    result.employee.value = `${employeeDetail.nik} ${employeeDetail.employee}`;
    result.position.value = jobDetail && jobDetail.job_name ? jobDetail.job_name : '-';
    result.department.value =
      jobDetail && jobDetail.dept_name ? jobDetail.dept_name : '-';
    result.description.value = employeeDetail.note;
    result.leaveType.value = employeeDetail.leave_config_name || "Cuti Bersama"
    result.leaveDate.value = `${date_start_leave} - ${date_end_leave}`;
    result.leaveDuration.value = employeeDetail.leave_duration_int + ' Hari';
    result.leaveAll.value = renderAllLeave(employeeDetail.leave_all_request_id)

    if (employeeDetail.images) {
      archiveImg.firstArchiveImg.sourceImg = employeeDetail.images[0]
        ? employeeDetail.images[0].url
        : '';
      archiveImg.secondArchiveImg.sourceImg = employeeDetail.images[1]
        ? employeeDetail.images[1].url
        : '';
      archiveImg.thirdArchiveImg.sourceImg = employeeDetail.images[2]
        ? employeeDetail.images[2].url
        : '';
      archiveImg.fourthArchiveImg.sourceImg = employeeDetail.images[3]
        ? employeeDetail.images[3].url
        : '';
    }
  }

  return {
    leaveDetailForm: result,
    stepState: getLeaveStatus(employeeDetail.state),
    leaveId: employeeDetail.id,
    archiveImg,
    name: employeeDetail.name,
    employee_id: employeeDetail.employee_id,
    leave_type_id: employeeDetail.leave_config_id,
    leave_config_emp_id: employeeDetail.leave_config_emp_id,
    nik: employeeDetail.nik,
    employee: employeeDetail.employee,
  };
}

export function leaveApprovalDetailMapper(
  content: GetLeaveApprovalDetailContent,
  jobList: JobListObjById,
): LeaveDetailApproval {
  const emptyColumn: LeaveApproval = { name: '', position: '' };
  const result: LeaveDetailApproval = {
    firstApprovalData: [emptyColumn, emptyColumn, emptyColumn],
    secondApprovalData: [emptyColumn, emptyColumn, emptyColumn],
  };

  if (content && content.approval1) {
    result.firstApprovalData = [];

    content.approval1.forEach((data) => {
      const column = {
        name: data.alternative_name,
        position: jobList[data.job_id] ? jobList[data.job_id].job_name : '-',
      };

      result.firstApprovalData.push(column);
    });
  }

  if (content && content.approval2) {
    result.secondApprovalData = [];

    content.approval2.forEach((data) => {
      const column = {
        name: data.alternative_name,
        position: jobList[data.job_id] ? jobList[data.job_id].job_name : '-',
      };

      result.secondApprovalData.push(column);
    });
  }

  return result;
}

function renderAllLeave(leaveAllRequest: any) :string {
  if (!leaveAllRequest) {
    return "Tidak"
  }

  return "Ya"
}