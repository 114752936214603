import { Button, Form, InputNumber, Modal, Table, message } from 'antd';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import FormBuilder from 'antd-form-builder';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';
import UploadFile from './UploadFile';
import './ComponentStyle.less';
import ModalShowImageLeave from '../../../../../components/upload-image/ModalShowImageLeave';
import { IDRFormat } from '../../../../../@core/interaction/currency/IDRFormat';

const newRow = {
  key: '',
  date_start: '',
  date_end: '',
  information: '',
  total: '',
  lampiran: null,
  action: '',
};

let total = 0;

const imageExt = ['jpg', 'jpeg', 'png'];

export default function SubmissionInformationsTable({ onChange, disabled, viewMode, value }) {
  const [form] = useForm();
  const [visibleAddModal, setVisibleAddModal] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const [editId, setEditId] = useState("")

  const onAddRow = () => {
    if (!disabled) {
      setVisibleAddModal(true);
    }
  };

  const showModalImg = (e) => {
    const fileName = e.file.name.split('.').at(-1);
    setSelectedImg(e.url);

    if (imageExt.indexOf(fileName) !== -1) {
      setIsShowModal(true);
    } else {
      downloadFromBlob(e.url);
    }
  };

  const PriceInput = ({ value, onChange }) => {
    return (
      <InputNumber
        style={{ width: '100%' }}
        value={value}
        onChange={(v) => onChange(v)}
        prefix="Rp"
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
      />
    );
  };

  const meta = {
    columns: 4,
    colon: false,
    fields: [
      {
        key: 'date_start',
        label: 'Tanggal Mulai',
        colSpan: 2,
        required: true,
        message: 'Tanggal Mulai harus diisi.',
        widget: 'date-picker',
        formItemLayout: {
          labelCol: { span: 10 },
          wrapperCol: { span: 14 },
        },
      },
      {
        key: 'lampiran',
        label: 'Lampiran',
        colSpan: 2,
        widget: UploadFile,
        formItemLayout: {
          labelCol: { span: 10 },
          wrapperCol: { span: 10 },
        },
      },
      {
        key: 'date_end',
        label: 'Tanggal Berakhir',
        colSpan: 2,
        required: true,
        message: ' ',
        widget: 'date-picker',
        formItemLayout: {
          labelCol: { span: 10 },
          wrapperCol: { span: 14 },
        },
        rules: [
          {
            validator: (rule, value, callback) => {
              return new Promise((resolve, reject) => {
                if (value) {
                  if (value.isBefore(form.getFieldValue('date_start'))) {
                    reject(new Error('Waktu berakhir harus lebih besar dari waktu mulai'));
                  } else {
                    resolve(value);
                  }
                } else {
                  reject(new Error('Tanggal Berakhir harus diisi.'));
                }
              });
            },
          },
        ],
      },
      {
        key: 'separator',
        label: ' ',
        widget: () => null,
      },
      {
        key: 'information',
        label: 'Keterangan',
        colSpan: 2,
        required: true,
        message: 'Keterangan harus diisi.',
        formItemLayout: {
          labelCol: { span: 10 },
          wrapperCol: { span: 12 },
        },
      },
      {
        key: 'total',
        label: 'Total',
        colSpan: 2,
        required: true,
        widget: PriceInput,
        message: 'Total harus diisi.',
        formItemLayout: {
          labelCol: { span: 10 },
          wrapperCol: { span: 12 },
        },
      },
    ],
  };

  const onClickSubmit = () => {
    let newData
    if (!!editId) {
      newData = value.dataSource?.map(ds => {
        if (ds.key === editId) {
          return {
            ...newRow,
            key: editId,
            ...form.getFieldsValue(),
          }
        }
        return ds
      })
      setEditId("")
    } else {
      newData = [
        ...value.dataSource,
        {
          ...newRow,
          key: Math.random().toString(),
          ...form.getFieldsValue(),
        },
      ];
    }

    total = newData.reduce((p, n) => {
      return p + n.total;
    }, 0);

    onChange({ dataSource: newData, total });
    form.setFieldsValue(newRow);
  };

  const onClickClose = () => {
    setTimeout(() => {
      const fieldsError = form.getFieldsError();
      const isValid = checkError(fieldsError);
      if (isValid) {
        setVisibleAddModal(false);
      }
    }, 100);
  };

  function checkError(fieldsError) {
    return !fieldsError.find((e) => e.errors.length);
  }

  function downloadFromBlob(blob_url) {
    var fileName = 'lampiran.pdf';
    var a = document.createElement('a');
    a.href = blob_url;
    a.download = fileName;
    a.textContent = 'DOWNLOAD ' + fileName;
    document.body.appendChild(a);
    a.click();
  }

  const ToolbarButtonFooter = () => {
    return (
      <div className="toolbar-button-footer">
        <Button key="back" onClick={() => {
          setEditId("")
          setVisibleAddModal(false)
        }}>
          Batal
        </Button>
        <Button htmlType="submit" type="primary">
          Simpan & Tambah Baru
        </Button>
        <Button onClick={onClickClose} htmlType="submit" type="primary">
          Simpan & Tutup
        </Button>
      </div>
    );
  };

  const deleteRow = (index) => {
    const filterDS = value.dataSource.filter((e) => e.key !== index);
    total = filterDS.reduce((p, n) => {
      return p + n.total;
    }, 0);
    onChange({ dataSource: filterDS, total });
  };

  const editRow = (index) => {
    const detail_data = value.dataSource.find((e) => e.key === index);
    form.setFieldsValue(detail_data)
    setEditId(detail_data?.key)
    setVisibleAddModal(true)
  };

  return (
    <>
      <Modal
        title="Membuat: Rincian Pengajuan"
        visible={visibleAddModal}
        onCancel={() => setVisibleAddModal(false)}
        width={800}
        footer={null}
      >
        <Form
          onFinish={onClickSubmit}
          form={form}
          name="basic"
          layout="horizontal"
          labelAlign="left"
        >
          <FormBuilder form={form} meta={meta} />
          <ToolbarButtonFooter />
        </Form>
      </Modal>

      <ModalShowImageLeave
        imageSrc={selectedImg}
        isShowModal={isShowModal}
        showModal={() => setIsShowModal(!isShowModal)}
      />

      <Table
        columns={[
          {
            key: 'date_start',
            dataIndex: 'date_start',
            title: 'Tanggal Mulai',
            render: (i) => !!i && i.format('DD-MM-YYYY'),
          },
          {
            key: 'date_end',
            dataIndex: 'date_end',
            title: 'Tanggal Berakhir',
            render: (i) => !!i && i.format('DD-MM-YYYY'),
          },
          {
            key: 'information',
            dataIndex: 'information',
            title: 'Keterangan',
          },
          {
            key: 'total',
            dataIndex: 'total',
            title: 'Total',
            render: (i) => !!i && IDRFormat(i),
          },
          {
            key: 'lampiran',
            dataIndex: 'lampiran',
            title: 'Lampiran',
            render: (file) => {
              if (!!file) {
                return <a onClick={() => showModalImg(file)}>Tampilkan</a>;
              }
            },
          },
          {
            key: 'action',
            dataIndex: 'action',
            title: ' ',
            render: (_, e) =>
              !viewMode ? (
                <>
                  <CloseOutlined
                    style={{ fontSize: '24px' }}
                    onClick={() => !disabled && deleteRow(e?.key)}
                  />
                  <EditOutlined
                    style={{ fontSize: '24px', marginLeft: '10px' }} 
                    onClick={() => !disabled && editRow(e?.key)}
                  /> 
                </>
              ) : null,
          },
        ]}
        summary={(pageData) => {
          return (
            <>
              {!viewMode ? (
                <Table.Summary.Row key={Math.random()}>
                  <Table.Summary.Cell index={1}>
                    <Button disabled={disabled} type="primary" onClick={(e: any) => onAddRow()}>
                      Tambahkan Item
                    </Button>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              ) : ''}
              {total ? (
                <Table.Summary.Row>
                  {['Total', '', '', IDRFormat(total)].map((e, i) => (
                    <Table.Summary.Cell index={i}>
                      <span style={{ fontWeight: 'bold' }}>{e}</span>
                    </Table.Summary.Cell>
                  ))}
                </Table.Summary.Row>
              ) : (
                ''
              )}
            </>
          );
        }}
        dataSource={value.dataSource}
        pagination={false}
      />
    </>
  );
}
