import React from 'react';
import AuthService from './AuthService';
import Constant from '../config/Constants';
import CookiesService from '../services/Cookies.service';
import ToastService from '../services/Toast.service';
import { aesGcmDecrypt } from '../services/Encrypter';
import MenuService from '../components/Layouts/MenuService';

var UserStateContext = React.createContext({});
var UserDispatchContext = React.createContext({});
const cookiesService = new CookiesService();
const menuService = new MenuService();
const toastService = new ToastService();

function userReducer(state, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isAuthenticated: true,
        auth: cookiesService.getJSON(),
      };
    case 'LOGIN_FAILURE':
      return { ...state, isAuthenticated: false };
    case 'SIGN_OUT_SUCCESS':
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  let auth = cookiesService.getJSON();
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!auth,
    auth: auth,
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

// ###########################################################
const authService = new AuthService();

function loginUser(dispatch, companyCode, login, password, history, setIsLoading, setError, goto) {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {
    authService
      .login(companyCode, login, password)
      .then(async (response: any) => {
        const data = response.data;
        let temp_company_logo = '';
        let temp_user_picture = '';
        if (response.status === Constant.RESP_OK && !!data.content) {
          if (!data.content.is_changed && !!data.content.login) {
            history.push(`/change-password/${data.content.login}`);
            return;
          }
          var inTwentyFourHour = new Date(new Date().getTime() + 24 * 3600 * 1000);
          if (data?.content?.company_logo) {
            temp_company_logo = await aesGcmDecrypt(data?.content?.company_logo);
          }
          if (data?.content?.user_picture) {
            temp_user_picture = await aesGcmDecrypt(data?.content?.user_picture);
          }
          cookiesService.set(
            'hira',
            {
              access_token: data.content.access_token,
              company_logo: temp_company_logo,
              user_picture: temp_user_picture,
              login_view: data?.content?.login_view,
              tz: data?.content?.tz,
            },
            { expires: inTwentyFourHour },
          );
          let tempMenu;
          let isAdmin;
          let isManager;
          let isEmployee;
          menuService.getMenu().then(async(resp: any) => {
            if (resp.status === Constant.RESP_OK) {
              tempMenu = resp.data.content.map((item) => {
                if (item.id === 2) {
                  isAdmin =
                    item?.sub_menus && item?.sub_menus.find((sub) => sub.id === 378)?.active;
                  isManager =
                    item?.sub_menus && item?.sub_menus.find((sub) => sub.id === 323)?.active;
                  isEmployee =
                    item?.sub_menus && item?.sub_menus.find((sub) => sub.id === 302)?.active;
                  return {
                    id: item.id,
                    active: item.active,
                    menu_icon: item.menu_icon,
                    name: item.name,
                    sequence: item.sequence,
                    isAdmin: isAdmin,
                    isManager: isManager,
                    isEmployee: isEmployee,
                  };
                } else {
                  return item;
                }
              });
              await localStorage.setItem('menu', JSON.stringify(tempMenu));
            }
          });
          setTimeout(() => {
            dispatch({ type: 'LOGIN_SUCCESS' })
            setIsLoading(false);
          }, 1000)
          
          // if (goto) {
          //   history.push(goto);
          // } else {
          //   history.push('/');
          // }
        }
        
      })
      .catch((resp) => {
        if (resp.status !== Constant.RESP_OK) {
          toastService.error(resp?.error?.message || 'Login failure.');
        }
        dispatch({ type: 'LOGIN_FAILURE' });
        setError(true);
        setIsLoading(false);
        localStorage.clear();
      });
  }
}

function logoutUser(dispatch, history) {
  cookiesService.remove();
  localStorage.clear();
  dispatch({ type: 'SIGN_OUT_SUCCESS' });
  history.push('/login');
}

export { UserProvider, useUserState, useUserDispatch, loginUser, logoutUser };
