import { ToolbarDetailActionButton } from "../../components/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface";
import { useEffect, useState } from "react";
import DetailMasterDepartmentUsecase from "./DetailMasterRegion.usecase";
import ToastService from "../../../../../services/Toast.service";
import LocalNotificationService from "../../../../../services/LocalNotification.service";
import AddMasterRegionTemplate from "../template/AddMasterRegionTemplate";
import { getDetailLocationList, getDetailMasterRegionMapper } from "../../../../../@core/mapper/employee/GetMasterRegion.mapper";
import { ListTableLocationType } from "../template/AddMasterRegionTemplate.interface";

const usecase = new DetailMasterDepartmentUsecase()
const toastService = new ToastService()
const localNotificationService = new LocalNotificationService();

let page = 1
let limit = 5
let region_name

export default function DetailMasterRegion({ match }) {
  const regionId = match.params.regionId

  const [isLoading, setIsLoading] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [viewMode, setViewMode] = useState(true)
  const [visibleWarningModal, setVisibleWarningModal] = useState(false)
  const [listLocation, setListLocation] = useState([])
  const [detailDataSource, setDetailDataSource] = useState({
    name: null,
    list_location: null
  })
  let [pagination, setPagination] = useState({
    current: page,
    pageSize: limit,
    total: 0,
  });

  const listTableDataDetail: ListTableLocationType = {
    ...pagination,
    location_list: listLocation,
    paginationOnchange: paginationOnChange,
  }

  useEffect(() => {
    init()
  }, [])

  async function init() {
    setIsLoading(true)

    usecase.getDetail(regionId).then(
      (res) => {
        const detail = getDetailMasterRegionMapper(res.data.content)
        setDetailDataSource(detail)
        setIsActive(detail.is_active)
        region_name = detail.name
        getListLocation(page)
      }
    ).catch(err => {
      setIsLoading(false)
    })
  }

  function getListLocation(page) {
    setIsLoading(true)
    const params = {
      page,
      limit,
      region_name,
      with_meta: true
    }
    usecase.getLocationList(params).then(
      (result) => {
        const content = getDetailLocationList(result.data.content)
        const metadata = result.data.metadata;

        updatePaginationState(metadata.total_records)
        setListLocation([...content])
        setIsLoading(false)
      }
    ).catch(err => {
      setIsLoading(false)
    })
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  function paginationOnChange(page) {
    const tempPagination = pagination;

    tempPagination.current = page;
    getListLocation(tempPagination.current)
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Master Region',
        href: "/v2/app/hr-employee/master-region",
      },
      {
        clickable: false,
        displayPathName: detailDataSource.name,
        href: "/v2/app/hr-employee/master-region/new/",
      }
    ],
  };

  const submitButton = { active: !viewMode, disabled: false };
  const firstActionButton: ToolbarDetailActionButton = {
    label: 'UBAH',
    type: 'primary',
    active: viewMode,
    disabled: false,
    onClick: () => {
      setViewMode(false)
    },
  };
  const secondActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: false,
    onClick: () => {
      if (!viewMode) {
        return window.location.reload()
      }
      const back = window.confirm("Anda Ingin Kembali")
      if (back) {
        window.location.href = "/v2/app/hr-employee/master-region"
      }
    },
  };

  const dropdownButton = {
    visible: true,
    disabled: isLoading,
    options: [
      { key: 'inactive', label: isActive ? 'Non-Aktifkan' : 'Aktifkan', onClick: () => setActive(!isActive) },
    ]
  };

  function setActive(status) {
    setIsLoading(true);
    setVisibleWarningModal(false);

    const params = {
      ids: [regionId],
      status
    };

    usecase.active(params).then(
      (res: any) => {
        const data = res.data
        if (data.code === 200) {
          const message = data.message
          localNotificationService.openSuccess(message, '', 1)
        }
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      },
      (err) => {
        setIsLoading(false);
        toastService.error(err)
      },
    );
  }

  const onFinish = (e) => {
    setIsLoading(true)
    const body = {
      id: regionId,
      region_name: e.name?.trim(),
      active: true,
    }

    usecase.update(body).then(
      (res) => {
        const data = res.data
        if (data.code === 200) {
          const message = data.message
          localNotificationService.openSuccess(message, '', 1)
        }
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      }
    ).catch((err) => {
      toastService.error(err)
      setIsLoading(false)
    })
  }

  return (
    <div className="add-employee-region">
      <AddMasterRegionTemplate
        breadcrumbConf={breadcrumbConf}
        dropdownActionButton={dropdownButton}
        firstActionButton={firstActionButton}
        secondActionButton={secondActionButton}
        thirdActionButton={{ active: false }}
        fourthActionButton={{ active: false }}
        onFinish={onFinish}
        viewMode={viewMode}
        submitButton={submitButton}
        listTableDataDetail={listTableDataDetail}
        isDetail={true}
        detailDataSource={detailDataSource}
        isLoading={isLoading}
      />
    </div>
  )
}