export const MasterWorkingScheduleConstant = {
  TABLE_HEADER: [
    { title: 'Nama Jadwal Kerja', key: 'working_schedule_name', dataIndex: 'working_schedule_name', width: 180 },
    { title: 'Total Jam Kerja Dalam Seminggu', key: 'total_work_hour', dataIndex: 'total_work_hour', sorter: true, width: 180 },
    { title: 'Radius Absensi', key: 'radius', dataIndex: 'radius' },
    { title: 'Status', key: 'status', dataIndex: 'status', width: 100 },
    { title: 'Dibuat Oleh', key: 'create_name', dataIndex: 'create_name' },
    { title: 'Dibuat Pada', key: 'create_date', dataIndex: 'create_date', width: 200, sorter: true },
    { title: 'Diubah Oleh', key: 'write_name', dataIndex: 'write_name' },
    { title: 'Diubah Pada', key: 'write_date', dataIndex: 'write_date', width: 200, sorter: true },
  ], 
  SEARCH_COLUMN_KEY: [
    { key: 'key', componentType: "search-input" },
    { key: 'working_schedule_name', componentType: "text" },
  ],
  DEFAULT_EMPTY_DATA_SOURCE: [
    {
      key: "empty-data",
      working_schedule_name: "",
      total_working_hours: "",
      create_name: '',
      create_date: '',
      write_name: '',
      write_date: '',
    },
  ],
}