import Constant from "../../../../../config/Constants"
import API from "../../../../../services/API.service"

export default class AddMasterLocationUsecase {
  create(body) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/master-location"
    return API.post(URL, body)
  }

  getRegionList() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/master-region/list"
    return API.get(URL).then(res => {
      return res.data.content.map((opt) => {
        return { key: opt.id, label: opt.region_name, value: opt.id }
      })
    })
  }
}