import { useEffect, useState } from "react";
import LeaveUsageReportTemplate from "../../templates/leave-usage-report-template/LeaveUsageReportTemplate";
import FilterLeaveUsageReportUsecase from "./FilterLeaveUsageReport.usecase";
import ToastService from "../../../../../services/Toast.service";
import { useHistory } from "react-router-dom";

const usecase = new FilterLeaveUsageReportUsecase()
const toastService = new ToastService()

let getEmployee
let getConfig
let getJob

export default function FirterLeaveUsageReport() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false)
  const [jobList, setJobList] = useState([])
  const [employeeList, setEmployeeList] = useState([])
  const [leaveConfigList, setLeaveConfigList] = useState([])

  useEffect(() => {
    initialPage();
  }, []);

  const breadcrumbConf = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Laporan Penggunaan Cuti',
        href: null,
      },
      {
        clickable: false,
        displayPathName: 'Filter',
        href: null,
      }
    ],

  }

  async function initialPage() {
    setIsLoading(true);
    getJob = await usecase.getJobList();
    getEmployee = await usecase.getEmployeeList();
    getConfig = await usecase.getEmployeeConfigList()
    
    setJobList(getJob);
    setEmployeeList(getEmployee);
    setLeaveConfigList(getConfig);
    setIsLoading(false);
  }

  const onFieldChange = (e, form) => {
    if (e.length === 1) {
      const fieldName = e[0].name[0];
      const value = e[0].value;

      switch (fieldName) {
        case 'list_job':
          let newEmployeeList = [...getEmployee]
          if (value?.length) {
            newEmployeeList = getEmployee.filter(emp => {
              return value?.indexOf(emp?.job_id) > -1
            }) 
          }
          setEmployeeList([...newEmployeeList])
          form.setFieldsValue({ list_employee: [] })
          break;
      }
    }
  }

  const onFinish = (value) => {
    const job_ids = value?.list_job
    const emp_ids = value?.list_employee
    const leave_config_ids = value?.list_config
    const is_expired = mapperLeaveData(value?.leave_data)

    history.push({
      pathname: "/app/hr-leave/leave-usage-report/",
      state: {
        data: {
          emp_ids,
          leave_config_ids,
          job_ids,
          is_expired
        }
      }
    });
  }

  function mapperLeaveData(leave_data: string) {
    switch(leave_data) {
      case 'all':
        return null
      case 'aktif':
        return false
      case 'non-aktif':
        return true
    }
  }

  return (
    <div className="filter-leave-usage-report">
      <LeaveUsageReportTemplate
        breadcrumbConf={breadcrumbConf}
        jobList={jobList}
        employeeList={employeeList}
        leaveConfigList={leaveConfigList}
        onFinish={onFinish}
        onFieldChange={onFieldChange}
        isLoading={isLoading}
      />
    </div>
  )
}