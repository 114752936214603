import Constant from '../../../../../config/Constants';
import API from '../../../../../services/API.service';

export default class HrAddUserUsecase {
  create(body) {
    const URL = Constant.URL_BASE_USER + `/v3/users`;
    return API.post(URL, body);
  }

  getEmployeeList() {
    const params: any = {
      is_users_not_exist: true,
    }
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/emp"
    return API.get(URL, {params}).then((res: any) => {
      const result = res.data.content.map(element => {
        return { label: element['alternative_name'], value: JSON.stringify(element), key: element['id'] }
      })
      return result;
    })
  }
  getAccessList() {
    const URL = Constant.URL_BASE_USER + "/v3/lov/hak-akses"
    return API.get(URL).then((res: any) => {
      const result = res.data.content.map(element => {
        return { label: element['name'], value: JSON.stringify(element), key: element['id'] }
      })
      return result;
    })
  }
}
