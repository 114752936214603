import { useEffect, useState } from 'react';
import moment from 'moment'
import LandingListTemplate from '../../../../../../templates/landing-list-template/LandingListTemplate';
import { useHistory } from 'react-router-dom';
import { MissingAttendanceConstant } from '../../../../../../config/Attendance.constant';
import { LandingListTemplatePagination } from '../../../../../../templates/landing-list-template/LandingListTemplate.interface';
import MissingAttendanceUseCaseImpl from './HrMissingAttendance.usecase.impl';

const useCaseImpl = new MissingAttendanceUseCaseImpl();

let page = 1;
let filterDate = [moment(Date.now() - 7 * 24 * 3600 * 1000).format('YYYY-MM-DD'), moment()]
let loadPagination = true;

export default function HrMissingAttendance() {
  const history = useHistory();
  const tableHeader: any =  MissingAttendanceConstant.TABLE_HEADER;
  const searchColumnKey = MissingAttendanceConstant.SEARCH_COLUMN_KEY;
  const limit = 10;

  let [pagination, setPagination] = useState<LandingListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });
  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([]);
  let [tableScroll, setTableScroll] = useState({ y: 200, x: 'max-content' });
  let [disableExportButton, setDisableExportButton] = useState(false);

  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: false };

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {

    loadList();
    const clientHeight = document.getElementById('employee-attendance-page').clientHeight;
    
    setTableScroll({ y: clientHeight - 220, x: 'max-content' });
  }

  function loadList() {
    setIsLoading(true);

    const params = {
      page,
      limit,      
      with_meta: true,
      start_date: filterDate[0],
      end_date: filterDate[1],
    };
    useCaseImpl
      .getMissingAttendanceList(params, '/v2/app/hr-attendance/check-missing-attendance/')
      .then(
        (res:any) => {
          setIsLoading(false);
          const content = res.content;
          const metaData = res.metaData;

          const resultDataSource = content ? content : [];
          
          setDataSource([...resultDataSource]);
          updatePaginationState(metaData.total_records);

          loadPagination = true;
          setDisableExportButton(content ? false : true);
        },
        (err) => {
          setIsLoading(false);
          setDataSource([...MissingAttendanceConstant.DEFAULT_EMPTY_DATA_SOURCE]);
          loadPagination = true;
          setDisableExportButton(true);
        }
      )
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    processPagination(outputPagination);
  };

  const onChangeDateFilter = (date) => {
    filterDate = date;
    page = 1
    loadList();
  };

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
  }
  function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };

  const exportButton = {
    onClick: () => {
      setIsLoading(true);
      const params = {
        start_date: filterDate[0],
        end_date: filterDate[1],
      };
      useCaseImpl.exportMissingAttendance(params).then(
        (res: any) => {
          const data = res.data;
          if (data.status === 200) {
            const file = base64ToArrayBuffer(data.content.data);
            saveByteArray(data.content.filename, file);
          }
          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
        },
      );
    },
    disabled: disableExportButton || isLoading,
  };

  return (
    <div id="employee-attendance-page" style={{ height: 'calc(100vh - 100px)' }}>
      <LandingListTemplate
        addButton={{visible: false}}
        deleteButton={{visible: false}}
        exportButton={exportButton}
        tableHeader={tableHeader}
        tableData={tableData}
        tablePagination={pagination}
        tableOnChange={tableOnChange}
        isLoading={isLoading}
        tableScroll={tableScroll}
        isDateFilter={true}
        onChangeDateFilter={onChangeDateFilter}
      />
    </div>
  );
}
