import Constant from "../../../config/Constants";
import API from "../../../services/API.service";
import { 
  GetLeavePlafonDTO, 
  GetLeavePlafonResp 
} from "../../interface/leave/GetLeavePlafon.interface";

export default function getLeavePlafon(params: GetLeavePlafonDTO): Promise<GetLeavePlafonResp> {
  const URL = Constant.URL_BASE_ATTENDANCE + '/v3/leave/check'
  return API.get(URL, { params })
}