import { RecurringHolidayFormPropsType } from "../../../component/recurring-holidays-form/RecurringHolidaysForm.interface"
import { ACTION_FORM_TYPE } from "./DetailRecurringHolidays.constant"
const moment = require("moment")

export const RecurringHolidaysFormReducer = (state :RecurringHolidayFormPropsType, action) => {
  switch (action.type) {
    case ACTION_FORM_TYPE.SET_HOLIDAYS_LENGTH:
      return { 
        ...state,  
        holidayLength: {
          ...state.holidayLength,
          value: action.payload
        }
      }
    case ACTION_FORM_TYPE.CHANGE_TO_EDIT_MODE:
      return changeToEditMode(state)
    case ACTION_FORM_TYPE.SET_VALUE_FORM_ITEM:
      return setValueFormItem(state, action.payload)
    default:
      return state
  }
}

const setValueFormItem = (state: RecurringHolidayFormPropsType, payload) :RecurringHolidayFormPropsType => {
  const copyState: RecurringHolidayFormPropsType = JSON.parse(JSON.stringify(state))
  copyState.holidayName.value = payload.name
  copyState.startDate.value = moment(payload.date_start).format("YYYY-MM-DD")
  copyState.endDate.value = moment(payload.date_stop).format("YYYY-MM-DD")
  copyState.holidayLength.value = `${payload.total_holidays} Hari`
  return copyState
}

const changeToEditMode = (state: RecurringHolidayFormPropsType) :RecurringHolidayFormPropsType => {
  const copyState = JSON.parse(JSON.stringify(state))
  for (const key in copyState) { 
    if (key !== 'holidayLength') {
      copyState[key].editMode = true 
    }
  }
  return copyState
}
