import Constant from "../../../../../config/Constants";
import API from "../../../../../services/API.service";

export default class EmployeeListUsecase {

  getEmployeeList(params) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/employee/master-employee"
    return API.get(URL, { params })
  }

  export(params) {
    const URL =  Constant.URL_BASE_COMPANY + "/v3/employee/master-employee/export"
    return API.get(URL, { params })
  }

  changeStatus(body) {
    const URL = Constant.URL_BASE_COMPANY + `/v3/employee/master-employee/active`
    return API.put(URL, body)
  }
}