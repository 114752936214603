import { Card, Row, Button, Empty } from 'antd';

export default function Submission(props) {

  const Content = (cont) => {
    if (!cont?.data?.is_exist) {
      return <Empty description={cont?.data?.description || cont?.data?.note} />;
    }
    switch (cont.title) {
      case 'Klaim':
        return (
          <div>
            <Row justify="space-between" gutter={[12, 12]}>
              <a href={`/v2/app/hr-claim/my-submission-claim/${cont?.data?.id}`} style={{ fontWeight: 'bold' }}>{cont?.data?.name}</a>
              <Button style={{ borderRadius: 15, fontWeight: 'bold' }} disabled={true}>
                {cont?.data?.state}
              </Button>
            </Row>
            <Row justify="space-between" gutter={[12, 12]} style={{ marginTop: 20 }}>
              <p>Tanggal Klaim</p>
              <p>{cont?.data?.date_claim}</p>
            </Row>
            <Row justify="space-between" gutter={[12, 12]} style={{ marginTop: 10 }}>
              <p>Tipe Klaim</p>
              <p>{cont?.data?.type_claim}</p>
            </Row>
            <Row justify="space-between" gutter={[12, 12]} style={{ marginTop: 10 }}>
              <p>Rincian Pengajuan</p>
              <p>{cont?.data?.pengajuan}</p>
            </Row>
            <Row justify="space-between" gutter={[12, 12]} style={{ marginTop: 10 }}>
              <p>Jumlah Klaim</p>
              <p>{cont?.data?.total}</p>
            </Row>
            <Row justify="space-between" gutter={[12, 12]} style={{ marginTop: 10 }}>
              <p>Keterangan</p>
              <p>{cont?.data?.description}</p>
            </Row>
          </div>
        );
      case 'Cuti':
        return (
          <div>
            <Row justify="space-between" gutter={[12, 12]}>
              <a href={`/v2/app/hr-leave/my-submission/${cont?.data?.id}`}  style={{ fontWeight: 'bold' }}>{cont?.data?.name}</a>
              <Button style={{ borderRadius: 15, fontWeight: 'bold' }} disabled={true}>
                {cont?.data?.state}
              </Button>
            </Row>
            <Row justify="space-between" gutter={[12, 12]} style={{ marginTop: 20 }}>
              <p>Jenis Cuti</p>
              <p>{cont?.data?.type_leave}</p>
            </Row>
            <Row justify="space-between" gutter={[12, 12]} style={{ marginTop: 10 }}>
              <p>Tanggal Pengajuan</p>
              <p>{cont?.data?.date_request}</p>
            </Row>
            <Row justify="space-between" gutter={[12, 12]} style={{ marginTop: 10, }}>
              <p>Tanggal Cuti</p>
              <p style={{width: 'min-content'}}>{cont?.data?.date_leave}</p>
            </Row>
            <Row justify="space-between" gutter={[12, 12]} style={{ marginTop: 10 }}>
              <p>Durasi</p>
              <p>{cont?.data?.durasi}</p>
            </Row>
            <Row justify="space-between" gutter={[12, 12]} style={{ marginTop: 10 }}>
              <p>Keterangan</p>
              <p>{cont?.data?.note}</p>
            </Row>
          </div>
        );
      case 'Pinjaman':
        return (
          <div>
            <Row justify="space-between" gutter={[12, 12]}>
              <a href={`/v2/app/hr-loan/submission-loan/${cont?.data?.id}`}  style={{ fontWeight: 'bold' }}>{cont?.data?.name}</a>
              <Button style={{ borderRadius: 15, fontWeight: 'bold' }} disabled={true}>
                {cont?.data?.state}
              </Button>
            </Row>
            <Row justify="space-between" gutter={[12, 12]} style={{ marginTop: 20 }}>
              <p>Tanggal Pinjaman</p>
              <p>{cont?.data?.date_request}</p>
            </Row>
            <Row justify="space-between" gutter={[12, 12]} style={{ marginTop: 10 }}>
              <p>Jumlah Pinjaman</p>
              <p>{cont?.data?.loan_value_requested}</p>
            </Row>
            <Row justify="space-between" gutter={[12, 12]} style={{ marginTop: 10 }}>
              <p>Tenor</p>
              <p>{cont?.data?.term_of_installment}</p>
            </Row>
            <Row justify="space-between" gutter={[12, 12]} style={{ marginTop: 10 }}>
              <p>Keterangan</p>
              <p>{cont?.data?.description}</p>
            </Row>
          </div>
        );
      case 'Lembur':
        return (
          <div>
            <Row justify="space-between" gutter={[12, 12]}>
              <a href={`/v2/app/hr-overtime/my-submission-overtime/${cont?.data?.id}`}  style={{ fontWeight: 'bold' }}>{cont?.data?.name}</a>
              <Button style={{ borderRadius: 15, fontWeight: 'bold' }} disabled={true}>
                {cont?.data?.state}
              </Button>
            </Row>
            <Row justify="space-between" gutter={[12, 12]} style={{ marginTop: 20 }}>
              <p>Tanggal Lembur</p>
              <p>{cont?.data?.ovt_start}</p>
            </Row>
            <Row justify="space-between" gutter={[12, 12]} style={{ marginTop: 10 }}>
              <p>Waktu Mulai</p>
              <p>{cont?.data?.lembur_start}</p>
            </Row>
            <Row justify="space-between" gutter={[12, 12]} style={{ marginTop: 10 }}>
              <p>Waktu Berakhir</p>
              <p>{cont?.data?.lembur_end}</p>
            </Row>
            <Row justify="space-between" gutter={[12, 12]} style={{ marginTop: 10 }}>
              <p>Durasi</p>
              <p>{cont?.data?.time_total}</p>
            </Row>
          </div>
        );
      default:
        return (
          <div>
            <p>def</p>
          </div>
        );
        break;
    }
  };

  return (
    <Card
      title={props.title}
      extra={<a href={props.href} style={{ fontWeight: 'bold' }}>Lihat Semua</a>}
      style={{ minWidth: '100%', minHeight: '100%' }}
    >
      <div>
        <Content title={props.title} data={props.data} />
      </div>
    </Card>
  );
}
