import {
  UpdateLeaveStateDTO,
  UpdateLeaveStateResp,
} from '../../interface/leave/UpdateLeaveState.interface';
import Constant from '../../../config/Constants';
import API from '../../../services/API.service';

export default function updateLeaveState(
  params: UpdateLeaveStateDTO,
): Promise<UpdateLeaveStateResp> {
  const URL = Constant.URL_BASE_ATTENDANCE + '/v3/leave/approval';
  const body = {
    id: params.leaveId,
    state: params.leaveState 
  };

  return API.put(URL, body);
}
