import Constant from '../../../../config/Constants';
import API from '../../../../services/API.service';

export class UploadBannerUsecase {
  getPosition() {
    const URL = Constant.URL_BASE_MISC + '/res/banner/position';
    return API.get(URL);
  }

  create(body) {
    const URL = Constant.URL_BASE_MISC + '/v3/res/banner';
    return API.post(URL, body);
  }
}