import { InsertFileTableDataSource } from "./InsertFileTable.interface";

export function fileToFormDataFormat(
  file: File
): Promise<InsertFileTableDataSource> {
  return new Promise((resolve, rejects) => {
    const formData = new FormData();
    formData.append('file', file);

    resolve({
      key: Math.random(),
      name: file.name,
      file: formData,
      error_message: '',
    });
  });
}
