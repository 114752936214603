export function employeeOvtReportMapper(response) {
  const content = response.content ? response.content : null;
  let contentMap = null;

  if (content) {
    contentMap = content.map((data) => {
      const item = {
        id: data.id,
        key: data.id,
        nik: data.nik,
        emp_name: data.emp_name,
        job: data.job,
        date_start: data.date_start,
        duration: data.time_total,
        price_total : `Rp${data.price_total.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")}`,
        reason: data.lembur_reason,
        approval_1: data.approved_1,
        approval_2: data.approved_1,
        date_approved_1: data.date_approved_1,
        date_approved_2: data.date_approved_2,
        status: data.state,
      };
      return item;
    });
  }

  return {
    content: contentMap,
    metaData: response.metadata,
  };
}
