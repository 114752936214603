import { notification } from 'antd';

export default class LocalNotificationService {
  openToast(placement, message, description, style, duration) {
    notification.open({
      placement,
      message,
      description,
      style,
      duration,
    });
  }

  openSuccess(message, description, duration) {
    const style = {
      backgroundColor: '#4db6ac',
    };
    this.openToast('bottomRight', message, description, style, duration);
  }
  
  openError(message, description, duration) {
    const style = {
      backgroundColor: 'red',
      color: '#fff'
    };
    this.openToast('bottomRight', message, description, style, duration);
  }
}
