import Constant from "../../../../../../config/Constants"
import API from "../../../../../../services/API.service"
import AddLeaveConfigurationUseCase from '../../../../../../@core/use-case/leave/AddLeaveConfiguration.usecase';
import getJobList from '../../../../../../@core/interaction/job/GetJobList.interaction';
import getEmployeeList from '../../../../../../@core/interaction/employee/GetEmployeeList.interaction';
import createLeaveConfiguration from '../../../../../../@core/interaction/leave/CreateLeaveConfiguration.interaction';
import { employeeListToOptionsMapper } from '../../../../../../@core/mapper/employee/GetEmployeeList.mapper';
import { jobListToOption } from '../../../../../../@core/mapper/job/GetJobList.mapper';

export default class AddLeaveConfigurationUseCaseImpl extends AddLeaveConfigurationUseCase {
  getJobList(params) {
    return getJobList(params).then((res: any) => jobListToOption(res.data.content.job));
  }
  getJob() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/job"
    return API.get(URL).then((res:any) => 
      {
        const result = res.data.content.map(element => {
          return { label: element['job_name'], value: JSON.stringify(element), key: element['id'] }
        })
        return result;
      })
  }
  getEmpl() {
    const param:any = {
      page:1,
      limit: 500
    }
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/emp"
    return API.get(URL, param).then((res:any) => 
      {
        const result = res.data.content.map(element => {
          return { label: element['alternative_name'], value: JSON.stringify(element), key: element['id'] }
        })
        return result;
      })
  }

  getEmployeeList(params): Promise<any> {
    return getEmployeeList(params).then((res: any) =>
      employeeListToOptionsMapper(res.data.content),
    );
  }

  submitLeaveConfiguration(params): Promise<any> {
    return createLeaveConfiguration(params);
  }

}
