import { GetAttendanceDTO } from '../../interface/attendance/GetAttendance.interface';
import { AttendanceList } from '../../entity/AttendanceList.entity';

export default abstract class AttendanceUseCase {
  constructor() {}

  abstract getAttendanceList(
    params: GetAttendanceDTO,
    hrefPath: string,
  ): Promise<AttendanceList>;
}
