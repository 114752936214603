import Constant from '../../../../../config/Constants';
import API from '../../../../../services/API.service';

export default class HrAddMasterJobUsecase {
  create(body) {
    const URL = Constant.URL_BASE_COMPANY + `/v3/master-jabatan`;
    return API.post(URL, body);
  }

  getJob(params) {
    const URL = Constant.URL_BASE_COMPANY + '/v3/lov/job';
    return API.get(URL, { params }).then((res: any) => {
      const result = res?.data?.content.map((element) => {
        return { label: element['job_name'], value: JSON.stringify(element), key: element['id'] };
      });
      return result;
    });
  }
  getDepartment() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/dept"
    return API.get(URL).then((res:any) => 
      {
        const result = res.data.content.map(element => {
          return { label: element['dept_name'], value: JSON.stringify(element), key: element['id'] }
        })
        return result;
      })
  }

  getAccessList() {
    const URL = Constant.URL_BASE_USER + "/v3/lov/hak-akses"
    return API.get(URL).then((res: any) => {
      const result = res.data.content.map(element => {
        return { label: element['name'], value: JSON.stringify(element), key: element['id'] }
      })
      return result;
    })
  }
  
  getEmpl(id) {
    const params:any = {
      job_id: id
    }
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/emp"
    return API.get(URL, { params }).then((res:any) => 
      {
        const result = res.data.content.map(element => {
          return { label: element['alternative_name'], value: JSON.stringify(element), key: element['id'] }
        })
        return result;
      })
  }
}
