export function MapperUpdateScheduleWork(
  rowTableChange: any,
  listSchedule: any
) {
  const result = []
  
  for (const key in rowTableChange) {
    delete rowTableChange[key].key
    const hira_absent_id = listSchedule.find(
      (sch) => {
        return sch.value === rowTableChange[key].nama_jadwal_kerja
      }).key
    rowTableChange[key].hira_absent_id = hira_absent_id
    result.push(rowTableChange[key])
  }
  
  return { schedule_work: result }
}

export function MapperSaveScheduleWork(value, listSchedule: any) {
  let selectionId = []
  const result = []
  
  for (const key in value.selectedRowKeys) {
    selectionId = [...selectionId, ...value.selectedRowKeys[key]]
  }
  
  if (selectionId.length) {
    selectionId.forEach((id) => {
      delete value.rowTableChange[id].key
      const hira_absent_id = listSchedule.find(
        (sch) => {
          return sch.label === value.rowTableChange[id].nama_jadwal_kerja
        }).key
      value.rowTableChange[id].hira_absent_id = hira_absent_id
      result.push(value.rowTableChange[id])
    })
  } else {
    for (const key in value.rowTableChange) {
      delete value.rowTableChange[key].key
      const hira_absent_id = listSchedule.find(
        (sch) => {
          return sch.label === value.rowTableChange[key].nama_jadwal_kerja
        }).key
      value.rowTableChange[key].hira_absent_id = hira_absent_id
      result.push(value.rowTableChange[key])
    }
  }
  
  return { schedule_work: result }
}