import Constant from '../../../../../config/Constants';
import API from '../../../../../services/API.service';
import getHistory from '../../../../../@core/interaction/history/GetHistory.interaction';

export default class HrUpdateNewsUsecase {

  updateNews(body): Promise<any> {
    const URL = Constant.URL_BASE_COMPANY + `/v3/news`;
    return API.put(URL, body);
  }


  getJob() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/job"
    return API.get(URL).then((res: any) => {
      const result = res.data.content.map(element => {
        return { label: element['job_name'], value: JSON.stringify(element), key: element['id'] }
      })
      return result;
    })
  }

  getEmpl() {
    const param: any = {
      page: 1,
      limit: 500
    }
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/emp"
    return API.get(URL, param).then((res: any) => {
      const result = res.data.content.map(element => {
        return { label: element['alternative_name'], value: JSON.stringify(element), key: element['id'] }
      })
      return result;
    })
  }

  getNewsDetail(newsId): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const URL = Constant.URL_BASE_COMPANY + "/v3/news/" + newsId
      return API.get(URL).then(async (res: any) => {
        if (res.status === 200) {
          const resultMapper = res.data.content;
          const body: any = {
            limit: 300,
            page: 1,
            with_meta: true,
            history_id: resultMapper.history_id
          };
          let tempData = await getHistory(body);

          const listHistory = tempData.data.content;
          resultMapper.historyList = listHistory;

          resolve(resultMapper);
        } else {
          reject(res);
        }
      })
    });
  }
}
