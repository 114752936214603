import Constant from "../../../../../../config/Constants";
import API from "../../../../../../services/API.service";

export default class DetailEmployeeSubmissionOvertimeUsecase {
  getDetail(id) {
    const URL = Constant.URL_BASE_ATTENDANCE + `/v3/overtime/request/${id}`
    return API.get(URL)
  }
  
  active(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/overtime/request/set-state"
    return API.put(URL, body)
  }
}