import ExpandableListTemplate from "../../../../../../templates/expandable-list-template/ExpandableListTemplate";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { ExpandableListTemplatePagination } from "../../../../../../templates/expandable-list-template/ExpandableListTemplate.interface";
import { MasterWorkingScheduleConstant } from "./ListMasterWorkingSchedule.constant";
import MasterWorkingScheduleUseCase from "./ListMasterWorkingSchedule.usecase";
import { MasterWorkingListMapper } from "../../../../../../@core/mapper/attendance/MasterWorkingScheduleList.mapper";
import { ColumnsMasterScheduleWork } from "../../../../../../@core/mapper/attendance/MasterWorkingScheduleMapper.interface";

let page = 1;
let loadPagination = true;
let sort = 'write_date|desc'
const limit = 10;
let selectionMasterId = []
let nama_jadwal_kerja

const constant = MasterWorkingScheduleConstant
const useCase = new MasterWorkingScheduleUseCase()

export default function ListMasterWorkingSchedule() {
  const history = useHistory();

  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([]);
  let [tableScroll, setTableScroll] = useState({ x: 'max-content' });
  let [disableDropdownButton, setDisableDropdownButton] = useState(true);
  let [disableExportButton, setDisableExportButton] = useState(false);
  let [pagination, setPagination] = useState<ExpandableListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });

  let tableHeader: any = constant.TABLE_HEADER
  let searchColumnKey = constant.SEARCH_COLUMN_KEY
  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: true };

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: false,
        displayPathName: 'Master Jadwal Kerja',
        href: "/v2/app/attendance/configuration/master-working-schedule",
      },
    ],
  };

  useEffect(() => {
    loadList()
    return () => {
      window.location.reload()
    }
  }, [])

  function loadList() {
    setIsLoading(true)
    const params = {
      page,
      limit,
      nama_jadwal_kerja,
      sort,
      with_meta: true,
    }

    useCase.getAll(params).then((res: any) => {
      setIsLoading(false)
      const content = res.data.content;
      const metaData = res.data.metadata;

      const resultDataSource = content && content.length ? MasterWorkingListMapper(content) 
        : constant.DEFAULT_EMPTY_DATA_SOURCE
      
      setDataSource([...resultDataSource]);
      updatePaginationState(metaData.total_records);

      loadPagination = true
      setDisableExportButton(content ? false : true)
    }, (err) => {
      setIsLoading(false)
    })
  }
  
  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  function processSort(outputSort) {
    if (outputSort && useCase.convertOutputSortToEndPointFormat(outputSort) !== sort) {
      page = 1;
      loadPagination = false;

      sort = useCase.convertOutputSortToEndPointFormat(outputSort);
      loadList();
    }
  }

  function processSearchColumn(outputSearchColumn: ColumnsMasterScheduleWork) {
    let isLoadList = false;

    if (outputSearchColumn) {
      if (
        outputSearchColumn.working_schedule_name !== undefined &&
        outputSearchColumn.working_schedule_name !== nama_jadwal_kerja
      ) {
        nama_jadwal_kerja = outputSearchColumn.working_schedule_name;
        isLoadList = true;
      }
    }

    if (isLoadList) {
      page = 1;
      loadPagination = false;
      loadList();
    }
  }

  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionMasterId) {
      selectionMasterId = outputSelection;
      setDisableDropdownButton(selectionMasterId.length ? false : true);
    }
  }

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
  }

  function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };

  function softDelete(status) {
    setIsLoading(true)
    const params = {
      ids: selectionMasterId,
      status
    }

    useCase.active(params).then(
      (res) => {
        setIsLoading(false)
        loadList()
      }
    ).catch(err => {
      setIsLoading(false)
    })
  }

  const addButton = {
    onClick: () => {
      history.push('/app/attendance/master-working-schedule/new/');
    },
    disabled: isLoading,
  };

  const importButton = {
    onClick: () => {
      history.push('/app/attendance/master-working-schedule/import/');
    },
    disabled: isLoading,
  };

  const exportButton = {
    disabled: isLoading || disableExportButton,
    onClick: () => {
      setIsLoading(true)
      const params = {
        nama_jadwal_kerja,
        sort,
        id: selectionMasterId,
        with_meta: true,
      }

      useCase.export(params).then((resp: any) => {
        setIsLoading(false);
        const content = resp.data.content;
        if (resp.status === 200) {
          const data = content.data
          let sampleArr = base64ToArrayBuffer(data)
          saveByteArray(content.filename, sampleArr);
        }
      })
    }
  }

  const dropdownButton = {
    visible: true,
    disabled: isLoading || disableDropdownButton,
    options: [
      { key: 'active', label: 'Aktifkan', onClick: () => softDelete(true) },
      { key: 'non-active', label: 'Non-Aktifkan', onClick: () => softDelete(false) }
    ]
  };

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    const outputSort = output.sort;
    const outputSearchColumn = output.searchColumn;
    
    processSort(outputSort);
    processSearchColumn(outputSearchColumn);
    processSelection(output.selection);
    processPagination(outputPagination);
  };

  const onRow = (e) => {
    if (e.key && e.key !== 'search-input' && e.key !== "empty-data") {
      history.push("/app/attendance/master-working-schedule/" + e.key)
    }
  }

  return (
    <div id="master-working-list">
      <ExpandableListTemplate
        breadcrumbConf={breadcrumbConf}
        addButton={addButton}
        exportButton={exportButton}
        dropdownButton={dropdownButton}
        importButton={importButton}
        deleteButton={{ visible: false }}
        tableHeader={tableHeader}
        tableData={tableData}
        tablePagination={pagination}
        tableOnChange={tableOnChange}
        isLoading={isLoading}
        tableScroll={tableScroll}
        onRow={onRow}
      />
    </div>
  )
}