import { Avatar, Card, Col, List, Row, Space } from 'antd';
import { EmployeeBirthDateType } from './DashboardCard.interface';
import { aesGcmDecrypt } from '../../../../../services/Encrypter';
import { useEffect, useState } from 'react';

export default function EmployeeBirthDate({ dataSource }: EmployeeBirthDateType) {
  let [images, setImages] = useState(['', '', '', '', '', '', '', '', '', '', '', '',])

  useEffect(() => {
    const copyImages = [...images];
    dataSource?.forEach(async (ds, i) => {
      copyImages[i] = !!ds.url ? await aesGcmDecrypt(ds.url) : null
      setImages([...copyImages]);
    });
  }, [dataSource]);

  return (
    <Card
      title="Ulang Tahun Karyawan"
      extra={<p style={{ lineHeight: 1.5 }}>&nbsp;</p>}
      style={{ minWidth: '100%', minHeight: '100%' }}
    >
      <List
        dataSource={dataSource}
        renderItem={(item, index) => (
          <Row className="body-section" justify="space-between" align="middle">
            <Space>
              <Avatar size="large" shape="square" src={images[index]} />

              <Col>
                <h1>{item.title}</h1>

                <p style={{ color: 'black', lineHeight: 1 }}>{item.description}</p>
              </Col>
            </Space>

            <p style={{ fontWeight: 600, fontSize: '16px', color: '#d42534' }}>{item.date}</p>
          </Row>
        )}
      />
    </Card>
  );
}
