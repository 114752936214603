export const ListRecapConstant = {
  TABLE_HEADER: [
    {
      title: 'Karyawan',
      key: 'employee',
      dataIndex: 'employee',
      width: 150,
    },
    {
      title: 'Jabatan',
      key: 'jabatan',
      dataIndex: 'jabatan',
      width: 150,
    },
    {
      title: 'Waktu Check in',
      key: 'checkin',
      dataIndex: 'checkin',
      width: 120,
    },
    { title: 'Status Check in', key: 'checkin_status', dataIndex: 'checkin_status', width: 150 },
    {
      title: 'Waktu Check out',
      key: 'checkout',
      dataIndex: 'checkout',
      width: 120,
    },
    { title: 'Status Check out', key: 'checkout_status', dataIndex: 'checkout_status', width: 150 },
    { title: 'Jadwal Kerja', key: 'jadwal_kerja', dataIndex: 'jadwal_kerja', width: 130 },
    {
      title: 'Total Jam Kerja',
      key: 'work_hour',
      dataIndex: 'work_hour',
      width: 120,
    },
    {
      title: 'Total Terlambat',
      key: 'total_late',
      dataIndex: 'total_late',
      width: 120,
    },
    {
      title: 'Media Absensi',
      key: 'media_absensi',
      dataIndex: 'media_absensi',
      width: 120,
    },
    {
      title: 'Lokasi Absensi',
      key: 'location',
      dataIndex: 'location',
      isLink: true,
      width: 120,
    },
    {
      title: 'Status Radius',
      key: 'radius_status',
      dataIndex: 'radius_status',
      width: 120,
      isHtml: true,
    },
    {
      title: 'Status Absensi',
      key: 'status_absent_info',
      dataIndex: 'status_absent_info',
      width: 120,
    },
    {
      title: 'Keterangan',
      key: 'desc',
      dataIndex: 'desc',
      width: 150,
    },
    { title: 'Dibuat Oleh', key: 'create_name', dataIndex: 'create_name', width: 150 },
    {
      title: 'Dibuat Pada',
      key: 'create_date_string',
      dataIndex: 'create_date_string',
      width: 150,
      sorter: true,
    },
    { title: 'Diubah Oleh', key: 'write_name', dataIndex: 'write_name', width: 150 },
    {
      title: 'Diubah Pada',
      key: 'write_date_string',
      dataIndex: 'write_date_string',
      width: 150,
      sorter: true,
    },
    { title: 'Tindak Lanjut', isAction: true, key: 'action', dataIndex: 'action', width: 250 },
  ],
  SEARCH_COLUMN_KEY: [],
  SEARCH_COLUMN_COMPONENT: [
    { key: 'key', componentType: 'text' },
    { key: 'employee', componentType: 'text' },
    { key: 'jabatan', componentType: 'text' },
    {
      key: 'status_absent_info',
      componentType: 'select',
      options: [
        { label: 'Semua', value: '' },
        { label: 'Tidak Masuk Kerja', value: 'TM' },
        { label: 'Masuk Kerja', value: 'MK' },
      ],
    },
    {
      key: 'checkin_status',
      componentType: 'select',
      options: [
        { label: 'Semua', value: '' },
        { label: 'Tidak Absen', value: 'TA' },
        { label: 'Terlambat', value: 'TT' },
        { label: 'Tepat Waktu', value: 'TW' },
      ],
    },
    {
      key: 'checkout_status',
      componentType: 'select',
      options: [
        { label: 'Semua', value: '' },
        { label: 'Pulang Cepat', value: 'PC' },
        { label: 'Masuk Kerja', value: 'MK' },
        { label: 'Tidak Absen', value: 'TA' },
      ],
    },
  ],
  DEFAULT_EMPTY_DATA_SOURCE: [
    {
      key: 'empty-data',
      employee: '',
      jabatan: '',
      status_absent_info: '',
      checkin_status: '',
      checkout_status: '',
    },
  ],
};
