import Constant from "../../../../../config/Constants"
import API from "../../../../../services/API.service"

import { employeeClaimReportMapper } from '../../../../../@core/mapper/claim/EmployeeClaimReport.mapper';

export default class EmployeeClaimReportUseCase {
  getClaimReportList(params) {
    const URL = Constant.URL_BASE_CLAIM + "/v3/report/claim"
    return API.get(URL, { params }).then((res: any) => 
      employeeClaimReportMapper(res.data)
    )
  }
  getClaimReportDetail(params) {
    const URL = Constant.URL_BASE_CLAIM + "/v3/report/claim/detail/" + params.id
    return API.get(URL, {params});
  }
  export(params) {
    const URL =  Constant.URL_BASE_CLAIM + "/v3/report/claim/export"
    return API.get(URL, { params })
  }
}
