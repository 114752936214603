import { useEffect, useState } from 'react';
import { Modal, Row, DatePicker } from 'antd';
import LeaveConfigurationDetailTemplate from '../../../templates/leave-configuration-template/LeaveConfigurationDetailTemplate';
import { useHistory } from 'react-router-dom';
import Moment from 'moment';
import LocalNotificationService from '../../../../../../services/LocalNotification.service';
import ConfigurationLeaveDetail from './HrConfigurationLeaveDetail.usecase.impl';

const useCaseImpl = new ConfigurationLeaveDetail();
const localNotificationService = new LocalNotificationService();

let isChange = false;
let leaveId = '';

export default function HrConfigurationLeaveDetail({ match }) {
  const history = useHistory();
  leaveId = match.params.leaveId;
  const [dataSource, setDataSource] = useState(null);
  const [employeeList, setEmployeeList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [viewMode, setViewMode] = useState(true);
  const [visibleModalProcess, setVisibleModalProcess] = useState(false);
  const [visibleReprocessModal, setVisibleReprocessModal] = useState(false);
  const [processPeriod, setProcessPeriod] = useState<any>();
  const [isRefresh, setIsRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [breadcrumbConf, setBreadcrumbConf] = useState({
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Konfigurasi Cuti',
        href: '/v2/app/hr-leave/leave-configuration',
      },
      { clickable: false, displayPathName: '' },
    ],
  });

  let submitButton = { active: false, disabled: isLoading };
  const cancelButton = {
    label: 'BATAL',
    type: 'ghost',
    onClick: onClickCancel,
  };
  let updateButton = {
    label: 'UBAH',
    type: 'primary',
    active: true,
    onClick: onClickUpdate,
    disabled: isLoading,
  };

  useEffect(() => {
    initialPage();
  }, [isRefresh]);

  async function initialPage() {
    setIsLoading(true);
    useCaseImpl.getCheckProcess(leaveId).then(
      (res: any) => {
        const data = res.data;
        if (data?.content) {
          if (data?.content?.code === 200) {
            localNotificationService.openSuccess(data?.content?.cause, '', 3);
          } else {
            setVisibleModalProcess(true);
            setTimeout(() => {
              history.push('/app/hr-leave/leave-configuration');
            }, 2000);
          }
        }
      }
    );

    const getLeaveConfigDetailParams = {
      leaveId,
    };
    const params = {
      limit: 300,
      sort: 'id|asc',
      page: 1,
    };
    const getJob = await useCaseImpl.getJobList(params);
    setJobList(getJob)
    const leaveConfigDetail: any = await useCaseImpl.getLeaveConfigurationDetail(getLeaveConfigDetailParams);

    setDataSource(leaveConfigDetail);
    const tempBreadcrumbConf = JSON.parse(JSON.stringify(breadcrumbConf));
    tempBreadcrumbConf.manualData[1].displayPathName = leaveConfigDetail?.name;
    setBreadcrumbConf(tempBreadcrumbConf);
    setIsLoading(false);

  }

  function onClickCancel(e) {
    history.push('/app/hr-leave/leave-configuration');
  }

  function onClickUpdate(e) {
    history.push(`/app/hr-leave/leave-configuration/update/${leaveId}`);
  }


  function onFieldChange(e, form) {
    if (e.length === 1) {
      const fieldName = e[0].name[0];
      const value = e[0].value;

      switch (fieldName) {
        default:
          break;
      }
    }
  }

  async function onFinish(e) {
  }

  async function changeStatus(e) {
    setIsLoading(true);

    const params = {
      ids: [leaveId],
      status: e
    }
    let message = 'Konfigurasi Cuti Berhasil di non-aktifkan';
    useCaseImpl.deactiveLeaveConfiguration(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          localNotificationService.openSuccess(message, '', 3);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }
  async function reProcessLeave() {
    const param = {
      id: leaveId,
      periode: processPeriod
    }
    useCaseImpl.generateLeaveConfiguration(param).then(
      (res: any) => {
        setIsLoading(false);
        const data = res.data;
        if (data.status === 200) {
          localNotificationService.openToast('topRight', data.content.message || data.content.cause, '', { color: '#fff', background: 'red' }, 3)
          setVisibleReprocessModal(false);
        }
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }

  const dropdownButton = {
    visible: true,
    disabled: isLoading,
    options: [
      { key: 'deactive', label: 'Non-Aktifkan', onClick: () => changeStatus(false) },
      { key: 'reprocess', label: 'Proses Ulang', onClick: () => setVisibleReprocessModal(true) },
    ]
  };

  return (
    <div
      style={{ position: 'relative', height: '100vh' }}
    >
      {
        visibleModalProcess && (
          <Modal
            footer={null}
            visible={visibleModalProcess}
          >
            <p style={{lineHeight: 'inherit'}}>
              Silahkan tunggu beberapa saat, tipe cuti untuk masing - masing karyawan
              sedang dalam proses pembentukan
            </p>
          </Modal>
        )
      }
      {
        visibleReprocessModal && (
          <Modal
            title="Proses Ulang"
            cancelText="Batal"
            okText="Proses Ulang"
            visible={visibleReprocessModal}
            cancelButtonProps={{ type: 'ghost' }}
            onOk={() => reProcessLeave()}
            okButtonProps={{ disabled: !processPeriod }}
            onCancel={() => setVisibleReprocessModal(false)}
            
          >
            <Row align='middle'>
              <span style={{ fontWeight: 'bold' }}> Periode : </span><DatePicker onChange={(val) => setProcessPeriod(Moment(val).format('YYYY-MM'))} picker="month" allowClear={false} />
            </Row>
          </Modal>
        )
      }
      <LeaveConfigurationDetailTemplate
        submitButton={submitButton}
        firstActionButton={updateButton}
        secondActionButton={cancelButton}
        dropdownButton={dropdownButton}
        thirdActionButton={{ active: false }}
        fourthActionButton={{ active: false }}
        breadcrumbConf={breadcrumbConf}
        jobList={jobList}
        employeeList={employeeList}
        dataSource={dataSource}
        useCaseImpl={useCaseImpl}
        onFieldChange={onFieldChange}
        onFinish={onFinish}
        isLoading={isLoading}
        viewMode={viewMode}
        setIsRefresh={setIsRefresh}
        isRefresh={isRefresh}
      />
    </div>
  );
}
