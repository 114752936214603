import { GetEmployeeListDTO } from "../../../../../../@core/interface/employee/GetEmployeeList.interface";
import Constant from "../../../../../../config/Constants";
import API from "../../../../../../services/API.service";

export default class AddEmployeeSubmissionOvertimeUsecase {
  getEmployeeList(params: GetEmployeeListDTO) {
    const URL = Constant.URL_BASE_COMPANY + '/v3/lov/emp'
    return API.get(URL, { params })
  }

  save(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/overtime/request"
    return API.post(URL, body)
  }
}