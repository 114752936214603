import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Tabs, Upload } from 'antd';
import FormBuilder from 'antd-form-builder';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import PageLoader from '../../../../../components/loader/PageLoader';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import CompanyConfiguration from './CompanyConfiguration.usecase';
import { aesGcmDecrypt } from '../../../../../services/Encrypter';
import './CompanyConfiguration.less';
const { TabPane } = Tabs;

const useCaseImpl = new CompanyConfiguration();
const localNotificationService = new LocalNotificationService();

export default function HrAddConfigurationOvertime() {
  const [form] = Form.useForm();
  const [, updateState] = React.useState<any>(); const forceUpdate = React.useCallback(() => updateState({}), []);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<string>('1');
  const [initialData, setInitialData] = useState<any>({});
  const [imageUrl, setImageUrl] = useState<any>();
  const [typeOption, setTypeOption] = useState<any>([])
  const [listProvince, setListProvince] = useState<any>([]);
  const [listCity, setListCity] = useState<any>([]);
  const [listDistrict, setListDistrict] = useState<any>([]);
  const [listSubdistrict, setListSubdistrict] = useState<any>([]);

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: false,
        displayPathName: 'Konfigurasi Perusahaan',
      }
    ],
  };


  const handleChange = async (info) => {
    const base64Result: any = await getBase64(info.file.originFileObj);
    setImageUrl(base64Result)
  };
  const getBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

  const meta: any = {
    columns: 1,
    fields: [
      {
        key: 'company_type',
        label: 'Jenis Badan Usaha',
        required: true,
        disabled: true,
        message: 'Jenis Badan Usaha harus diisi',
        widget: 'select',
        options: typeOption.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
      },
      {
        key: 'name',
        label: 'Nama Perusahaan',
        required: true,
        disabled: true,
        message: 'Nama Perusahaan harus diisi',
      },
      {
        key: 'npwp_num',
        label: 'NPWP Perusahaan',
        required: true,
        message: 'NPWP Perusahaan harus diisi',
      },
      {
        key: 'phone',
        label: 'Nomor Telepon Perusahaan',
        required: true,
        message: 'Nomor Telepon Perusahaan harus diisi',
      },
      {
        key: 'debitacctno',
        label: 'No Rekening Perusahaan',
        required: true,
        message: 'No Rekening Perusahaan harus diisi',
      },
      {
        key: 'company_code',
        label: 'Kode Perusahaan',
        required: true,
        disabled: true,
        message: 'Kode Perusahaan harus diisi',
      },
      {
        key: 'avatar',
        render: () => {
          return (
            <Row style={{ display: 'flex', flexFlow: 'initial' }}>
              <span style={{ color: '#4c4c4c', fontWeight: 550, width: '50%' }}>Logo Perusahaan:</span>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                accept="image/*"
                onChange={handleChange}

              >

                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : 'asdad'}
              </Upload>
            </Row>
          )
        }
      },
      {
        key: 'separator',
        colSpan: 4,
        render() {
          return (
            <hr />
          );
        },
      },

      {
        key: 'street_1_by_id',
        label: 'Alamat Perusahaan',
      },
      {
        key: 'province_by_id_id',
        label: 'Provinsi',
        placeholder: 'Pilih',
        required: true,
        message: 'Provinsi harus dipilih',
        widget: 'select',
        options: listProvince.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        }),
        onChange: async (value) => {
          form.setFieldsValue({ city_by_id_id: undefined, district_by_id_id: undefined, village_by_id_id: undefined });
          const cities: any = await useCaseImpl.getCities({ prov_id: [value] });
          if (!!cities) {
            setListCity(cities?.data?.content);
          }
        },
      },
      {
        key: 'city_by_id_id',
        label: 'Kabupaten/Kota',
        placeholder: 'Pilih',
        required: true,
        message: 'Kabupaten/Kota harus dipilih',
        widget: 'select',
        disabled: !form.getFieldValue('province_by_id_id'),
        options: listCity.map((item) => {
          return {
            label: item.Name,
            value: item.ID,
          };
        }),
        onChange: async (value) => {
          form.setFieldsValue({ district_by_id_id: undefined, subdivillage_by_id_idstrict: undefined });
          const district: any = await useCaseImpl.getDistrict({ kab_id: [value] });
          if (!!district) {
            setListDistrict(district?.data?.content);
          }
        },
      },
      {
        key: 'district_by_id_id',
        label: 'Kecamatan',
        placeholder: 'Pilih',
        required: true,
        message: 'Kecamatan harus dipilih',
        widget: 'select',
        disabled: !form.getFieldValue('city_by_id_id'),
        options: listDistrict.map((item) => {
          return {
            label: item.Name,
            value: item.ID,
          };
        }),
        onChange: async (value) => {
          form.setFieldsValue({ village_by_id_id: undefined });
          const subdistrict: any = await useCaseImpl.getSubdistrict({ kec_id: [value] });
          if (!!subdistrict) {
            setListSubdistrict(subdistrict?.data?.content);
          }
        },
      },
      {
        key: 'village_by_id_id',
        label: 'Desa/Kelurahan',
        required: true,
        message: 'Desa/Kelurahan harus dipilih',
        placeholder: 'Pilih',
        widget: 'select',
        disabled: !form.getFieldValue('district_by_id_id'),
        onChange: async (value, record, acc) => {
          form.setFieldsValue({ postal_code: listSubdistrict.find(item => item.ID === value).KodePos });
        },
        options: listSubdistrict.map((item) => {
          return {
            label: item.Name,
            value: item.ID,
          };
        }),
      },
      {
        key: 'postal_code',
        label: 'Kode Pos',
        disabled: true,
      },
      {
        key: 'separator',
        colSpan: 4,
        render() {
          return (
            <hr />
          );
        },
      },
      {
        key: 'email',
        label: 'Email PIC',
        required: true,
        message: 'Email PIC harus diisi',
        rules: [{ type: 'email', message: 'Invalid email' }],
      },
      {
        key: 'no_handphone',
        label: 'No Telepon PIC',
        required: true,
        message: 'No Telepon PIC harus diisi',
      },

    ],
  };

  const setting_meta: any = {
    columns: 1,
    fields: [
      {
        key: 'usia_pensiun',
        label: 'Usia Pensiun',
        required: true,
        widget: 'number',
        message: 'Usia Pensiun harus diisi',
      },
    ]
  }

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {
    setIsLoading(true);
    
    const getCompType = await useCaseImpl.getCompType();
    
    const getProvince = await useCaseImpl.getProvince({ country_id: [101] });
    if (
      getProvince?.data?.content &&
      getProvince?.data?.content.length > 0
    ) {
      setListProvince(getProvince?.data?.content);
    }

    const companyDetail: any = await useCaseImpl.getCompanyConfiguration();
    const temp = companyDetail.data.content;
    if (temp.province_by_id_id) {
      const cities: any = await useCaseImpl.getCities({ prov_id: [temp.province_by_id_id] });
      if (!!cities) {
        setListCity(cities?.data?.content);
      }
    }
    if (temp.city_by_id_id) {
      const district: any = await useCaseImpl.getDistrict({ kab_id: [temp.city_by_id_id] });
      if (!!district) {
        setListDistrict(district?.data?.content);
      }
    }
    if (temp.district_by_id_id) {
      const subdistrict: any = await useCaseImpl.getSubdistrict({ kec_id: [temp.district_by_id_id] });
      if (!!subdistrict) {
        setListSubdistrict(subdistrict?.data?.content);
      }
    }
    if (temp.company_logo) {
      const tempFoto = await aesGcmDecrypt(temp.company_logo);
      setImageUrl(tempFoto)
    }

    let tempInitial = {
      id: temp.id,
      company_type: temp.company_type_id,
      company_initial: temp.company_initial,
      name: temp.name,
      npwp_num: temp.npwp_num,
      phone: temp.phone,
      debitacctno: temp.debitacctno,
      company_code: temp.company_code,
      street_1_by_id: temp.street_1_by_id,
      province_by_id_id: temp.province_by_id_id,
      city_by_id_id: temp.city_by_id_id,
      district_by_id_id: temp.district_by_id_id,
      village_by_id_id: temp.village_by_id_id,
      email: temp.email,
      no_handphone: temp.no_handphone,
      usia_pensiun: temp.usia_pensiun,
      postal_code: temp.postal_code_by_id_id
    }
    setTypeOption(getCompType?.data?.content);
    setInitialData(tempInitial)
    setIsLoading(false);

  }

  async function onFinish(e) {
    if (e) {
      submitToEndPoint(e);
    }
  }

  async function submitToEndPoint(e) {
    setIsLoading(true);

    const params = {
      id: initialData?.id,
      name: e.name,
      phone: e.phone,
      no_handphone: e.no_handphone,
      email: e.email,
      usia_pensiun: e.usia_pensiun,
      debitacctno: e.debitacctno,
      npwp_num: e.npwp_num,
      street_1_by_id: e.street_1_by_id,
      country_id: 101,
      province_by_id_id: e.province_by_id_id,
      city_by_id_id: e.city_by_id_id,
      district_by_id_id: e.district_by_id_id,
      village_by_id_id: e.village_by_id_id,
      company_type: '',
      company_type_id: e.company_type,
      company_logo: imageUrl.split(',')[1],
      company_code: e.company_code,
      company_initial: initialData.company_initial,

    }

    useCaseImpl.submitCompanyConfig(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          localNotificationService.openSuccess('Konfigurasi Perusahaan Berhasil Diubah', '', 3);
        }
        setIsLoading(false);
        initialPage();
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div style={{ position: 'relative', height: 'calc(100vh - 100px)' }}>
      <Form form={form} layout="horizontal" initialValues={initialData} onFinish={onFinish}>
        <div>
          <div className="form-wrapper">
            <div>
              <Breadcrumb breadcrumbConf={breadcrumbConf} />
              <Form.Item
                className="form-footer"
                style={{ width: '100%', marginTop: 20 }}
              >
                <Button
                  type="primary"
                  onClick={() => form.submit()}
                  disabled={isLoading}
                >
                  Simpan
                </Button>
              </Form.Item>
              <Tabs activeKey={activeTab} type="card" onChange={(value) => setActiveTab(value)}>
                <TabPane tab="Informasi Umum" key="1" forceRender={true}>
                  <div style={{ border: '1px solid #000', padding: 10, overflowY: 'scroll', height: '70vh' }}>
                    <FormBuilder form={form} meta={meta} />
                  </div>
                </TabPane>

                <TabPane tab="Pengaturan" key="2" forceRender={true}>
                  <div style={{ border: '1px solid #000', padding: 10, overflowY: 'scroll', height: '70vh' }}>
                    <FormBuilder form={form} meta={setting_meta} />
                  </div>
                </TabPane>

              </Tabs>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
