import Constant from "../../../../../config/Constants"
import API from "../../../../../services/API.service"

export default class FilterLeaveUsageReportUsecase {
  getJobList() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/job"
    return API.get(URL).then((res:any) => 
      {
        const result = res.data.content.map(element => {
          return { label: element['job_name'], value: element['id'], key: element['id'] }
        })
        return result;
      })
  }
  getEmployeeList() {
    const params:any = {
      page:1,
      limit: 1000,
    }
    const URL = Constant.URL_BASE_COMPANY + '/v3/lov/emp';
    return API.get(URL, { params }).then((res:any) => 
      {
        const result = res.data.content.map(element => {
          return { label: element.alternative_name, value: element['id'], key: element['id'], job_id: element?.job_id }
        })
        return result;
      })
  }
  getEmployeeConfigList() {
    const params:any = {
      page:1,
      limit: 500,
    }
    const URL = `${Constant.URL_BASE_ATTENDANCE}/v3/configuration/leave`;
    return API.get(URL, { params }).then((res:any) => 
      {
        const result = res.data.content.map(element => {
          return { label: element['name'], value: element['id'], key: element['id'] }
        })
        return result;
      })
  }
}