import { useEffect, useState } from 'react';
import { Avatar, Button, Col, Dropdown, Image, Menu, Row } from 'antd';
import { Link } from 'react-router-dom';
import 'moment/locale/id';
import { useUserState } from '../../context/UserContext';
var moment = require('moment-timezone');

function AuthContentHeader() {
  const userState: any = useUserState();
  const tz = moment().tz(userState.auth.tz).format('z');
  const [time, setTime] = useState();

  useEffect(() => {
    setInterval(() => {
      const currTime: any = moment().tz(userState.auth.tz).format('dddd, DD MMMM YYYY, HH:mm:ss');
      setTime(currTime);
    }, 1000);
  }, []);

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/logout">
          <i className="fa fa-sign-out" /> Keluar
        </Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <Row>
      <Col lg={10} xs={12}>
        <div className="logo-top">
          {userState.auth && (
            <a href="/">
              <img src={userState.auth.company_logo} />
            </a>
          )}
        </div>
      </Col>
      <Col lg={8} xs={12}>
        <div>
          {!!time && (
            <span>
              {time} {tz}
            </span>
          )}
        </div>
      </Col>
      <Col lg={6} xs={12}>
        <div className="header-username">
          <Dropdown overlay={menu} placement="bottomRight">
            <Button type="link">
              {userState.auth && (
                <div>
                  {userState.auth.login_view} &nbsp;
                  <Avatar src={<Image src={userState.auth.user_picture} preview={false} />} />
                </div>
              )}
            </Button>
          </Dropdown>
        </div>
      </Col>
    </Row>
  );
}

export default AuthContentHeader;
