import { Card, Col, Row } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import EmptyState from './EmptyState';
import { NotificationType } from './DashboardCard.interface';

export default function Notification({ endOfWork, salaryProcces, workSchedule }: NotificationType) {
  const arraySpan = [
    <span>
      <b> {endOfWork} Karyawan</b> akan berakhir masa kerjanya
    </span>,
    <span>
      <b> {salaryProcces} Karyawan</b> belum diproses gajinya
    </span>,
    <span>
      <b> {workSchedule} Karyawan</b> belum memiliki jadwal kerja
    </span>,
  ];

  const arrayHref = [
    'employee-termination',
    '',
    'employee-schedule-work',
  ]

  return (
    <Card
      title="Notifikasi"
      extra={<p style={{ lineHeight: 1.5 }}>&nbsp;</p>}
      style={{ minWidth: '100%', minHeight: '100%' }}
    >
      {endOfWork + salaryProcces + workSchedule > 0 ? (
        <Col>
          {[endOfWork, salaryProcces, workSchedule].map((value, index) => {
            return value > 0 ? (
              <Row style={{ lineHeight: '2rem' }} justify="space-between">
                <div>
                  <MessageOutlined />
                  {arraySpan[index]}
                </div>
                <a href={`/v2/app/notification/${arrayHref[index]}`} style={{ fontWeight: 600 }}>
                  Lihat Semua
                </a>
              </Row>
            ) : null;
          })}
        </Col>
      ) : (
        <EmptyState />
      )}
    </Card>
  );
}
