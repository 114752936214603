import Constant from "../../../../../config/Constants";
import API from "../../../../../services/API.service";
import { newsListMapper } from '../../../../../@core/mapper/news/GetNewsList.mapper'

export default class LoanConfigurationListUsecase {

  getNewsList(params) {
    const URL = Constant.URL_BASE_COMPANY+ "/v3/news"
    return API.get(URL, { params }).then((res: any) => 
    newsListMapper(res.data)
    )
  }

  export(params) {
    const URL =  Constant.URL_BASE_LOAN + "/v3/configuration/export"
    return API.get(URL, { params })
  }

  deactiveNews(body) {
    const URL = Constant.URL_BASE_COMPANY + '/v3/news/non-actived';
  return API.put(URL, body);
  }
}