import { Table, Tabs } from 'antd';
import { SubordinatConstant } from '../../../../master-job/master-job-list/ListMasterJob.constant';
const { TabPane } = Tabs;

export default function HistoryTabs(props) {
  const { dataSource } = props;

  return (
    <div className="tabs-wrapper">
      <Tabs defaultActiveKey="1" type="card">
        <TabPane tab="Jabatan Bawahan" key="1">
          <Table columns={SubordinatConstant.TABLE_HEADER} dataSource={dataSource} />
        </TabPane>
      </Tabs>
    </div>
  );
}
