import AttendanceUseCase from '../../../../../../@core/use-case/attendance/Attendance.usecase';
import { attendanceListMapper } from '../../../../../../@core/mapper/attendance/AttendanceRequestList.mapper';
import getEmployeeAttendance from '../../../../../../@core/interaction/attendance/GetEmployeeAttendance.interaction';
import deleteAttendance from '../../../../../../@core/interaction/attendance/DeleteAttendance.interaction';
import exportAttendance from '../../../../../../@core/interaction/attendance/ExportAttendance.interaction';

export default class EmployeeAttendanceUseCaseImpl extends AttendanceUseCase {
  getAttendanceList(params, hrefPath) {
    return getEmployeeAttendance(params).then((res: any) =>
      attendanceListMapper(res.data, hrefPath),
    );
  }
  deleteAttendance(params) {
    return deleteAttendance(params);
  }
  exportAttendance(params) {
    return exportAttendance(params);
  }
}
