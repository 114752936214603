import moment from "moment";
import { GetListLoanInterface } from "./SubmissionLoan.interface";

export function getSubmissionLoanList(listLoan, href: string): GetListLoanInterface[] {
    const result: GetListLoanInterface[] = listLoan.map((loan) => {
      return {
        key: loan.id,
        submission_number: loan.name,
        nik: loan.nik,
        employee: loan.employee_name,
        submission_date: formatSubmissionDate(loan.date_request),
        loan_type: loan.loan_type,
        loan_total: loan.loan_value_requested?.toLocaleString(),
        tenor: loan.term_of_installment,
        status: loan.state,
        state_payment: loan.state_payment,
        description: loan?.description,
        create_name: loan.create_name,
        create_date: loan.view_create_date,
        write_name: loan.write_name,
        write_date: loan.view_write_date,
        href: href + loan.id,
      };
    });
  
    return result
}

function formatSubmissionDate(dateString: string) {
  if (dateString) {
    return moment(dateString).format("DD/MM/YYYY")
  }

  return ''
}