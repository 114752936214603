import { Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import Constant from '../../../config/Constants';
import AuthMenu from '../AuthMenu';
import MenuService from '../MenuService';
import hiraLogo from '../../../components/asset/hira_logo.png';
import './AuthSidebar.less';

const menuService = new MenuService();

function AuthSidebar() {
  let [menuList, setMenuList] = useState([]);
  let [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const currentMenu = localStorage.getItem('menu');
    if (currentMenu) {
      setMenuList(JSON.parse(currentMenu));
      setLoading(false);
    } else {
      let tempMenu;
      menuService.getMenu().then((resp: any) => {
        if (resp.status === Constant.RESP_OK) {
          tempMenu = resp.data.content.map((item) => {
            if (item.id === 2) {
              const isAdmin =
                item?.sub_menus && item?.sub_menus.find((sub) => sub.id === 378)?.active;
              const isManager =
                item?.sub_menus && item?.sub_menus.find((sub) => sub.id === 323)?.active;
              const isEmployee =
                item?.sub_menus && item?.sub_menus.find((sub) => sub.id === 302)?.active;
              return {
                id: item.id,
                active: item.active,
                menu_icon: item.menu_icon,
                name: item.name,
                sequence: item.sequence,
                isAdmin: isAdmin,
                isManager: isManager,
                isEmployee: isEmployee,
              };
            } else {
              return item;
            }
          });
          setMenuList(tempMenu);
        }
        setLoading(false);
      });
    }
  }, []);

  return (
    <div id="auth-sidebar-component">
      {isLoading ? (
        <Skeleton loading={isLoading} />
      ) : (
        <div className="list-menu-wrapper">
          <AuthMenu key="main-nav-menu" items={menuList} level={15} />
        </div>
      )}

      <div className="logo-bottom">
        Powered by{' '}
        <a href="http://www.hira-hr.com/" target="_blank">
          <img src={hiraLogo} />
        </a>
      </div>
      <div
        style={{ width: '90%', textAlign: 'center', fontSize: '8px', margin: '0px 0px 8px 10px' }}
      >
        <span>
          PT Bank OCBC NISP Tbk berizin dan diawasi oleh Otoritas Jasa Keuangan & Bank Indonesia,
          serta merupakan peserta penjaminan LPS
        </span>
      </div>
    </div>
  );
}

export default AuthSidebar;
