import './ButtonBar.less';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ButtonBarPropType } from './ButtonBar.interface';

export default function ButtonBar(props: ButtonBarPropType) {
  const { downloadTemplateButton, validateButton, saveButton } = props;

  const onClick = (procedure: string) => {
    if (props[procedure] && props[procedure].onClick) {
      props[procedure].onClick();
    }
  };

  return (
    <div className="button-bar">
      <div className="left-side">
        <Button
          type="default"
          style={{ marginRight: '20px' }}
          disabled={
            validateButton && validateButton.disabled !== undefined
              ? validateButton.disabled
              : false
          }
          onClick={() => onClick('validateButton')}
        >
          Validasi
        </Button>

        <Button
          type="primary"
          disabled={saveButton && saveButton.disabled !== undefined ? saveButton.disabled : false}
          onClick={() => onClick('saveButton')}
        >
          Simpan
        </Button>
      </div>
      <div className="right-side">
        <Button
          type="default"
          disabled={
            downloadTemplateButton && downloadTemplateButton.disabled !== undefined
              ? downloadTemplateButton.disabled
              : false
          }
          onClick={() => onClick('downloadTemplateButton')}
        >
          Download Template
          <DownloadOutlined
            style={{
              fontSize: '18px',
              marginLeft: '4px',
            }}
          />
        </Button>
      </div>
    </div>
  );
}
