import React from 'react';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import { Row, Col } from 'antd';
import PageLoader from '../../../../../components/loader/PageLoader';
import SubordinatTabs from './components/subordinat-tabs/SubordinatTab'
import ToolbarTemplate from '../toolbar-template/ToolbarTemplate';
import BottomLoading from '../../../../../components/bottom-loading/BottomLoading';
import './MasterJobDetailTemplate.less';


export default function LeaveConfigurationDetailTemplate(props) {
  const { dataSource } = props;

  if (!dataSource) {
    return <PageLoader />;
  }

  return (
    <div>
      <Breadcrumb breadcrumbConf={props.breadcrumbConf} />
      <ToolbarTemplate
        submitButton={props.submitButton}
        firstActionButton={props.firstActionButton}
        secondActionButton={props.secondActionButton}
        thirdActionButton={props.thirdActionButton}
        fourthActionButton={props.fourthActionButton}
        dropdownButton={props.dropdownButton}
      />

      <div className="scrollable-content-master-job">
        <div className="form-wrapper">
          <Row>
            <Col flex={'50%'}>
              <Row className='detail-item'>
                <Col flex={'20%'}>Jabatan</Col>
                <Col flex={'60%'}>{dataSource.job_name}</Col>
              </Row>
              <Row className='detail-item'>
                <Col flex={'20%'}>Jabatan Atasan</Col>
                <Col flex={'60%'}>{dataSource.parent_job_name}</Col>
              </Row>

              <Row className='detail-item'>
                <Col flex={'20%'}>Hak Akses</Col>
                <Col flex={'60%'}>{dataSource.hak_access_name}</Col>
              </Row>
            </Col>
            <Col flex={'50%'}>
              <Row className='detail-item'>
                <Col flex={'20%'}>Departement</Col>
                <Col flex={'60%'}>{dataSource.department_name}</Col>
              </Row>
              <Row className='detail-item'>
                <Col flex={'20%'}>Nama Atasan Langsung</Col>
                <Col flex={'60%'}>{dataSource.parent_emp_name}</Col>
              </Row>

              <Row className='detail-item'>
                <Col flex={'20%'}>Deskripsi Pekerjaan</Col>
                <Col flex={'60%'}>{dataSource.description}</Col>
              </Row>
            </Col>
          </Row>
        </div>
        <SubordinatTabs dataSource={dataSource?.job_child} />
      </div>

      <BottomLoading active={props.isLoading} />
    </div>
  );
}
