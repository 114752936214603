import { Form, Button, Input, Space, Card } from 'antd';
import { useState } from 'react';
import AuthService from '../../context/AuthService';
import ToastService from '../../services/Toast.service';
import hiraLogo from '../../components/asset/hira_logo.png';

const authService = new AuthService();
const toastService = new ToastService();


export default function ResetPassword(props) {

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [pass, setPass] = useState('');
  var [newPass, setNewPass] = useState('');
  var [confirmPass, setConfirmPass] = useState('');
  var Id = props.match.params.id;

  return (
    <div className="login">
      <Space align="center" direction="vertical">
        <div style={{ margin: '40px 0' }}>
          <img src={hiraLogo} />
        </div>
        <Card style={{ width: '480px' }}>
          <Form
            layout="vertical"
            onFinish={(e) => {
              setIsLoading(true);
              if (newPass !== confirmPass) {
                toastService.error("Konfirmasi Password Baru tidak sesuai");
                setIsLoading(false);
                return
              }
              var pw_validation = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
              if (!pw_validation.test(confirmPass)) {
                toastService.error("Password harus terdiri dari minimal 8 karakter dan terdapat huruf besar, huruf kecil, karakter spesial, & angka ");
                setIsLoading(false);
                return
              }
              authService
                .changePassword(pass, confirmPass, Id)
                .then((response: any) => {
                  const data = response.data;
                  if (response.status === 200 && !!data.content) {
                    toastService.success('Password Berhasil Diubah');
                    props.history.push(`/login`);
                  }
                  setIsLoading(false);
                })
                .catch((resp) => {
                  setIsLoading(false);
                  toastService.error(resp.message);
                });
            }}
          >
            <Form.Item name="password" label="Password Lama" rules={[{ required: true }]}>
              <Input.Password
                required
                size="large"
                onChange={(e) => setPass(e.target.value)}
              />
            </Form.Item>
            <Form.Item name="new-password" label="Password Baru" rules={[{ required: true }]}>
              <Input.Password
                required
                size="large"
                onChange={(e) => setNewPass(e.target.value)}
              />
            </Form.Item>
            <Form.Item name="confirm_password" label="Konfirmasi Password Baru" rules={[{ required: true }]}>
              <Input.Password
                required
                size="large"
                onChange={(e) => setConfirmPass(e.target.value)}
              />
            </Form.Item>

            <Form.Item name="submit">
              <Button type="primary" htmlType="submit" size="large" loading={isLoading} block>
                Submit
              </Button>
            </Form.Item>
            <div className="row text-forgot-pass">
              <div className="col-sm-6 text-left"></div>
              <div className="col-sm-6 text-right">
                <span>*) Password harus kombinasi huruf besar, huruf kecil, karakter spesial dan angka</span>
              </div>
            </div>
          </Form>
        </Card>
      </Space>
    </div>
  );
}
