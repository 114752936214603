import { useEffect, useState } from 'react';
import moment from 'moment';
import LandingListTemplate from '../../../../../templates/landing-list-template/LandingListTemplate';
import { useHistory } from 'react-router-dom';
import { Row, Modal } from 'antd';
import GoogleMap from 'google-map-react';
import { ListRecapConstant } from './ListRecapRadius.constant';
import { LandingListTemplatePagination } from '../../../../../templates/landing-list-template/LandingListTemplate.interface';
import RecapRadiusUseCaseImpl from './ListRecapRadius.usecase';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';

const useCaseImpl = new RecapRadiusUseCaseImpl();
const localNotificationService = new LocalNotificationService();

let page = 1;
let filterDate = [
  moment(Date.now() - 7 * 24 * 3600 * 1000).format('YYYY-MM-DD'),
  moment().format('YYYY-MM-DD'),
];
let loadPagination = true;
let sort = 'write_date|desc';
let employee_name = '';
let job_name = '';
let status_absent_info = '';
let status_check_out = '';
let status_check_in = '';
let selectionAbsentId = [];

export default function ListRecapRadius() {
  const history = useHistory();
  const tableHeader: any = ListRecapConstant.TABLE_HEADER;
  const searchColumnKey = ListRecapConstant.SEARCH_COLUMN_KEY;
  const searchColumnComponent = ListRecapConstant.SEARCH_COLUMN_COMPONENT;
  const limit = 10;

  let [pagination, setPagination] = useState<LandingListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });
  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([]);
  let [tableScroll, setTableScroll] = useState({ y: 200 });
  const [geoFencing, setGeoFencing] = useState<any>(null);
  let [selectedLocation, setSelectedLocation] = useState();
  let [visibleModalMap, setVisibleModalMap] = useState(false);

  const tableData = {
    searchColumnKey,
    dataSource,
    tableRowSelectionActive: true,
    searchColumnComponent,
  };
  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: false,
        displayPathName: 'Rekap Absensi diluar Radius',
      },
    ],
  };

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {
    loadList();
    const clientHeight = document.getElementById('employee-attendance-page').clientHeight;

    setTableScroll({ y: clientHeight - 220 });
  }

  function loadList() {
    setIsLoading(true);

    const params = {
      page,
      limit,
      sort,
      start_date: filterDate[0],
      end_date: filterDate[1],
      employee_name,
      job_name,
      status_absent_info,
      status_check_out,
      status_check_in,
      with_meta: true,
    };
    useCaseImpl.getListRecapRadius(params).then(
      (res: any) => {
        setIsLoading(false);
        const content = res?.content;
        const metaData = res?.metaData;
        const resultDataSource =
          content && content.length ? content : ListRecapConstant.DEFAULT_EMPTY_DATA_SOURCE;
        setDataSource([...resultDataSource]);
        updatePaginationState(metaData?.total_records);
        loadPagination = true;
      },
      (err) => {
        setIsLoading(false);
        setDataSource([...ListRecapConstant.DEFAULT_EMPTY_DATA_SOURCE]);
        loadPagination = true;
      },
    );
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    const outputSort = output.sort;
    const outputSearchColumn = output.searchColumn;
    const outputAction = output.tableAction;
    const outputLink = output.action;

    processLink(outputLink);
    processAction(outputAction);
    processSearchColumn(outputSearchColumn);
    processSelection(output.selection);
    processPagination(outputPagination);
  };

  async function processLink(outputAction) {
    if (outputAction && outputAction.id) {
      const params = {
        emp_id: outputAction.employee_id,
      };
      const getGeofencing = await useCaseImpl.getGeofencing(params);
      if (!!getGeofencing) {
        setGeoFencing(getGeofencing);
      }
      if (outputAction && outputAction.id) {
        setSelectedLocation(outputAction.gmap_loc);
        setVisibleModalMap(true);
      }
    }
  }

  function processAction(outputAction) {
    if (outputAction && outputAction.id) {
      const param = {
        id: [outputAction.id],
      };
      setIsLoading(true);
      if (outputAction.status_worked) {
        useCaseImpl.setAbsent(param).then(
          (res: any) => {
            setIsLoading(false);
            const data = res.data;
            if (data.status === 200) {
              localNotificationService.openSuccess(
                '',
                data.content || 'Berhasil Update Absensi',
                3,
              );
              loadList();
            }
          },
          (err) => {
            setIsLoading(false);
          },
        );
      } else {
        useCaseImpl.cancleAbsent(param).then(
          (res: any) => {
            setIsLoading(false);
            const data = res.data;
            if (data.status === 200) {
              localNotificationService.openSuccess(
                '',
                data.content || 'Berhasil Update Absensi',
                3,
              );
              loadList();
            }
          },
          (err) => {
            setIsLoading(false);
          },
        );
      }
    }
  }

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  function processSearchColumn(outputSearchColumn) {
    let isLoadList = false;
    if (outputSearchColumn) {
      if (
        outputSearchColumn.employee !== undefined &&
        outputSearchColumn.employee !== employee_name
      ) {
        employee_name = outputSearchColumn.employee;
        isLoadList = true;
      }

      if (outputSearchColumn.jabatan !== undefined && outputSearchColumn.jabatan !== job_name) {
        job_name = outputSearchColumn.jabatan;
        isLoadList = true;
      }
      if (
        outputSearchColumn.status_absent_info !== undefined &&
        outputSearchColumn.status_absent_info !== status_absent_info
      ) {
        status_absent_info = outputSearchColumn.status_absent_info;
        isLoadList = true;
      }
      if (
        outputSearchColumn.checkin_status !== undefined &&
        outputSearchColumn.checkin_status !== status_check_in
      ) {
        status_check_in = outputSearchColumn.checkin_status;
        isLoadList = true;
      }
      if (
        outputSearchColumn.checkout_status !== undefined &&
        outputSearchColumn.checkout_status !== status_check_out
      ) {
        status_check_out = outputSearchColumn.checkout_status;
        isLoadList = true;
      }

      if (isLoadList) {
        page = 1;
        loadPagination = false;
        loadList();
      }
    }
  }

  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionAbsentId) {
      selectionAbsentId = outputSelection;
    }
  }

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }
  function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  }

  async function exportData() {
    setIsLoading(true);
    const params = {
      id: selectionAbsentId.length ? selectionAbsentId : [],
    };
    useCaseImpl.export(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          const file = base64ToArrayBuffer(data.content.data);
          saveByteArray(data.content.filename, file);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }
  function changeStatus() {
    if (selectionAbsentId.length < 1) {
      localNotificationService.openError('Pilih Data Kehadiran terlebih dahulu', '', 1);
      return;
    }
    setIsLoading(true);
    const param = {
      id: selectionAbsentId,
    };
    useCaseImpl.setAbsent(param).then(
      (res: any) => {
        setIsLoading(false);
        const data = res.data;
        if (data.status === 200) {
          localNotificationService.openSuccess('', data.content || 'Berhasil Update Absensi', 3);
          loadList();
        }
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }

  const MapWrapper: React.FC<any> = ({ location }) => {
    const tempLocation = JSON.parse(location);
    let checkinLocation = tempLocation.position;
    let checkoutLocation = tempLocation.position_out;
    const renderMarkers = (map, maps) => {
      return [0, 1].map((location, i) => {
        if (i === 0) {
          return new maps.Marker({
            position: { lat: checkinLocation.lat, lng: checkinLocation.lng },
            map,
            label: 'Checkin',
          });
        } else if (i === 1) {
          return new maps.Marker({
            position: { lat: checkoutLocation.lat, lng: checkoutLocation.lng },
            map,
            label: 'Checkout',
          });
        }
      });
    };
    const renderCircle = (map, maps, geo) => {
      let marker = new maps.Circle({
        center: { lat: geo.lat, lng: geo.lng },
        map,
        radius: geo.radius,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.3,
      });
      return marker;
    };

    return (
      <Row>
        <div style={{ position: 'relative', width: '60%', height: '300px', borderRadius: 10 }}>
          <GoogleMap
            defaultZoom={12}
            defaultCenter={[checkinLocation.lat || -6.3004, checkinLocation.lng || 106.635039]}
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              if (!!checkinLocation) {
                renderMarkers(map, maps);
              }
              if (geoFencing && geoFencing.as_attendance && geoFencing.is_radius) {
                renderCircle(map, maps, geoFencing);
              }
            }}
          ></GoogleMap>
        </div>
      </Row>
    );
  };

  const dropdownButton = {
    visible: true,
    options: [
      { key: 'export', label: 'Export', onClick: () => exportData() },
      { key: 'change_status', label: 'Ubah ke Tidak Masuk Kerja', onClick: () => changeStatus() },
    ],
  };

  const onChangeDateFilter = (date) => {
    filterDate = [date[0].format('YYYY-MM-DD'), date[1].format('YYYY-MM-DD')];
    page = 1;
    loadList();
  };

  return (
    <div id="employee-attendance-page" style={{ height: 'calc(100vh - 100px)' }}>
      <Row justify="space-between">
        <Breadcrumb breadcrumbConf={breadcrumbConf} />
      </Row>
      <Modal
        title=""
        open={visibleModalMap}
        footer={null}
        onCancel={() => {
          setVisibleModalMap(false);
          setSelectedLocation(null);
        }}
        width={'500px'}
      >
        <div>
          <MapWrapper location={selectedLocation} />
        </div>
      </Modal>
      <LandingListTemplate
        addButton={{ visible: false }}
        deleteButton={{ visible: false }}
        exportButton={{ visible: false }}
        dropdownButton={dropdownButton}
        tableHeader={tableHeader}
        tableData={tableData}
        tablePagination={pagination}
        tableOnChange={tableOnChange}
        isLoading={isLoading}
        tableScroll={tableScroll}
        isDateFilter={true}
        isDisabledDate={true}
        onChangeDateFilter={onChangeDateFilter}
      />
    </div>
  );
}
