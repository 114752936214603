import Constant from "../../../../../config/Constants";
import API from "../../../../../services/API.service";

export default class ListMassLeaveUseCase {
  convertOutputSortToEndPointFormat(tableOutputSort) {
    let result = '';
    const columnKey = tableOutputSort.columnKey;
    const order = tableOutputSort.order;

    if (order) {
      switch (columnKey) {
        default:
          break;
        case 'leave_name':
          result += 'leave_name|';
          break;
        case 'leave_config':
          result += 'leave_config|';
          break;
        case 'is_reduce_leave':
          result += 'is_reduce_leave|';
          break;
        case 'status':
          result += 'status|';
          break;
      }

      switch (order) {
        default:
          break;
        case 'ascend':
          result += 'asc';
          break;
        case 'descend':
          result += 'desc';
          break;
      }
    }

    return result;
  }

  getAll(params) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/leave/all-request"
    return API.get(URL, { params })
  }

  delete(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/leave/all-request"
    return API.delete(URL, body)
  }

  export(params) {
    const URL =  Constant.URL_BASE_ATTENDANCE + "/v3/leave/all-request/export"
    return API.get(URL, { params })
  }
  
  active(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + `/v3/leave/all-request`
    return API.put(URL, body)
  }
}