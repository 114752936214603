import { useEffect, useState } from "react";
import CalendarTempltePage from "../../../../../../templates/calendar-template-page/CalendarTemplatePage";
import { CalendarTemplateTodayButton, EventDatesPropsType } from "../../../../../../templates/calendar-template-page/CalendarTemplatePage.interface";
import { useHistory } from "react-router-dom";
import { Modal } from "antd";
import ExpandableListTemplate from "../../../../../../templates/expandable-list-template/ExpandableListTemplate";
import { LandingListTemplatePagination } from "../../../../../../templates/landing-list-template/LandingListTemplate.interface";
import MasterHolidayListUseCase from "./ListMasterHoliday.usecase";
import { MasterHolidayListConstant } from "./ListMasterHoliday.constant";
import { MapperMasterHolidayCalendar, MapperMasterHolidayList } from "../../../../../../@core/mapper/attendance/MasterHolidayList.mapper";
import ToastService from "../../../../../../services/Toast.service";
import LocalNotificationService from "../../../../../../services/LocalNotification.service";

const useCase = new MasterHolidayListUseCase
const constant = MasterHolidayListConstant
const toastService = new ToastService()
const localNotificationService = new LocalNotificationService()

let page = 1;
let loadPagination = true;
let sort = 'write_date|desc' 
const limit = 10;
let selectionMasterId = []
let nama_hari_libur
let date_start
let date_stop
let is_active

export default function ListMasterHoliday() {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false)
  const [calendarMode, setCalendarMode] = useState(true)
  const [visibleWarningModal, setVisibleWarningModal] = useState(false);
  const [visibleDeleteButton, setVisibleDeleteButton] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [holidayDates, setHolidayDates] = useState([])
  const [tableScroll, setTableScroll] = useState({ y: 600 });
  const [pagination, setPagination] = useState<LandingListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });

  let tableHeader: any = constant.TABLE_HEADER
  let searchColumnKey = constant.SEARCH_COLUMN_KEY
  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: true };


  useEffect(() => {
    loadList()
    getAll()
  }, [])

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: false,
        displayPathName: 'Master Hari Libur',
        href: "/v2/app/attendance/master-holiday",
      },
    ],
  };

  const todayButton: CalendarTemplateTodayButton = { visible: true, disabled: isLoading }
  const addButton = {
    onClick: () => {
      history.push('/app/attendance/master-holiday/new/');
    },
    disabled: isLoading,
  };

  const changeModeButton = {
    visible: true,
    onClick: () => {
      setCalendarMode(!calendarMode)
    },
    disabled: isLoading,
  };

  const dropdownButton = {
    visible: true,
    disabled: isLoading,
    options: [
      { key: 'ekspor', label: 'Ekspor', onClick: () => exportData() },
      { key: 'non-active', label: 'Non-Aktifkan', disabled: !visibleDeleteButton, onClick: () => setActive(false) }
    ]
  };

  const onRow = (e) => {
    if (e.key !== 'search-input') {
      history.push("/app/attendance/master-holiday/" + e.key)
    }
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    const outputSort = output.sort;
    const outputSearchColumn = output.searchColumn;
    
    processSort(outputSort);
    processSearchColumn(outputSearchColumn);
    processSelection(output.selection);
    processPagination(outputPagination);
  };

  function loadList() {
    setIsLoading(true)
    const params = {
      page,
      limit,
      nama_hari_libur,
      date_start,
      date_stop,
      is_active,
      sort,
      with_meta: true,
    }

    useCase.getAll(params).then((res: any) => {
      const content = res.data.content;
      const metaData = res.data.metadata;

      const resultDataSource = content && content.length ? MapperMasterHolidayList(content) 
        : constant.DEFAULT_EMPTY_DATA_SOURCE

      setDataSource([...resultDataSource]);
      updatePaginationState(metaData.total_records);
      loadPagination = true
      setIsLoading(false)
    }, (err) => {
      setIsLoading(false)
    })
  }

  function getAll() {
    setIsLoading(true)
    const params = {
      page,
      with_meta: true,
    }

    useCase.getAll(params).then((res: any) => {
      const content = res.data.content;

      const resultDataSource = content && content.length ? MapperMasterHolidayCalendar(content) 
        : constant.DEFAULT_EMPTY_DATA_SOURCE;

      setHolidayDates([...resultDataSource])
      loadPagination = true
      setIsLoading(false)
    }, (err) => {
      setIsLoading(false)
    })
  }

  function exportData() {
    setIsLoading(true)
    const params = {
      sort,
      nama_hari_libur,
      date_start,
      date_stop,
      is_active,
      id: selectionMasterId,
      with_meta: true,
    }

    useCase.export(params).then((resp: any) => {
      setIsLoading(false);
      const content = resp.data.content;
      if (resp.status === 200) {
        const data = content.data
        let sampleArr = base64ToArrayBuffer(data)
        saveByteArray(content.filename, sampleArr);
      }
    })
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  function processSort(outputSort) {
    if (outputSort && useCase.convertOutputSortToEndPointFormat(outputSort) !== sort) {
      page = 1;
      loadPagination = false;

      sort = useCase.convertOutputSortToEndPointFormat(outputSort);
      loadList();
    }
  }

  function processSearchColumn(outputSearchColumn) {
    let isLoadList = false;

    if (outputSearchColumn) {
      if (
        outputSearchColumn.holiday_name !== undefined &&
        outputSearchColumn.holiday_name !== nama_hari_libur
      ) {
        nama_hari_libur = outputSearchColumn.holiday_name;
        isLoadList = true;
      }
    }

    if (outputSearchColumn) {
      if (
        outputSearchColumn.effective_date !== undefined &&
        outputSearchColumn.effective_date !== date_start
      ) {
        date_start = outputSearchColumn.effective_date;
        isLoadList = true;
      }
    }

    if (outputSearchColumn) {
      if (
        outputSearchColumn.end_date !== undefined &&
        outputSearchColumn.end_date !== date_stop
      ) {
        date_stop = outputSearchColumn.end_date;
        isLoadList = true;
      }
    }

    if (outputSearchColumn) {
      if (
        outputSearchColumn.status !== undefined &&
        outputSearchColumn.status !== is_active
      ) {
        is_active = outputSearchColumn.status;
        isLoadList = true;
      }
    }

    if (isLoadList) {
      page = 1;
      loadPagination = false;
      loadList();
    }
  }

  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionMasterId) {
      selectionMasterId = outputSelection;
      setVisibleDeleteButton(!!selectionMasterId.length)
    }
  }

  function setActive(status) {
    setIsLoading(true);
    setVisibleWarningModal(false);

    const params = {
      ids: selectionMasterId,
      status
    };

    useCase.active(params).then(
      (res: any) => {
        setIsLoading(false);
        const content = res.data.content
        if (content.code === 200) {
          const message = content.message.split("- ")[1]
          localNotificationService.openSuccess(message, '', 1)
        }
        setVisibleWarningModal(false);
        loadList()
      },
      (err) => {
        setIsLoading(false);
        toastService.error(err)
      },
    );
  }

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
  }

  function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };

  return calendarMode ? (
    <div id='calendar-template'>
      <CalendarTempltePage 
        breadcrumbConf={breadcrumbConf}
        eventDates={holidayDates}
        isLoading={isLoading}
        todayButton={todayButton}
        addButton={addButton}
        changeModeButton={changeModeButton}
      />
    </div>
  ) : (
    <div id="master-holiday-list" style={{ height: 'calc(100vh - 100px)' }}>
      <ExpandableListTemplate
        breadcrumbConf={breadcrumbConf}
        addButton={addButton}
        deleteButton={{ visible: false }}
        exportButton={{ visible: false }}
        changeModeButton={changeModeButton}
        dropdownButton={dropdownButton}
        tableHeader={tableHeader}
        tableData={tableData}
        tablePagination={pagination}
        tableOnChange={tableOnChange}
        isLoading={isLoading}
        tableScroll={tableScroll}
        onRow={onRow}
      />
    </div>
  )
}