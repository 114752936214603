import React, { useEffect, useState } from 'react';
import { Form, Button, Upload, Tabs, Row, Modal, Input, Table } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';
import FormBuilder from 'antd-form-builder';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { DeleteOutlined } from '@ant-design/icons';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import HrUpdateEmployeeUsecase from './UpdateEmployee.usecase';
import PageLoader from '../../../../../components/loader/PageLoader';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import Constant from '../../../../../config/Constants';
import API from '../../../../../services/API.service';
import DateService from '../../../../../services/Date.service';
import { aesGcmDecrypt } from '../../../../../services/Encrypter';
import './UpdateEmployee.less';
import { error } from 'console';
const { TabPane } = Tabs;
const useCaseImpl = new HrUpdateEmployeeUsecase();
const localNotificationService = new LocalNotificationService();
const dateService = new DateService();

let employeeId = '';
const actionOption = [
  {
    value: 'mutasi',
    label: 'Mutasi',
  },
  {
    value: 'demosi',
    label: 'Demosi',
  },
  {
    value: 'promosi',
    label: 'Promosi',
  },
  {
    value: 'update_data',
    label: 'Update Data',
  },
];
export default function HrUpdateEmployee({ match }) {
  const history = useHistory();
  employeeId = match.params.employeeId;
  const [form] = Form.useForm();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [, updateState] = React.useState<any>();
  const [initialData, setInitialData] = useState<any>({});
  const [jobList, setJobList] = useState([]);
  const [deptList, setDeptList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fileFoto, setFileFoto] = useState<any>();
  const [fileCV, setFileCV] = useState<any>();
  const [filePrivateDoc, setFilePrivateDoc] = useState<any>();
  const [activeTab, setActiveTab] = useState<string>('1');
  const [countryList, setCountryList] = useState<any>([]);
  const [religionList, setReligionList] = useState<any>([]);
  const [eduList, setEduList] = useState<any>([]);
  const [ptkpList, setPtkpList] = useState<any>([]);
  const [regionList, setRegionList] = useState<any>([]);
  const [locationList, setLocationList] = useState<any>([]);
  const [listProvince, setListProvince] = useState<any>([]);
  const [listCity, setListCity] = useState<any>([]);
  const [listDistrict, setListDistrict] = useState<any>([]);
  const [listSubdistrict, setListSubdistrict] = useState<any>([]);
  const [listCityDom, setListCityDom] = useState<any>([]);
  const [listDistrictDom, setListDistrictDom] = useState<any>([]);
  const [listSubdistrictDom, setListSubdistrictDom] = useState<any>([]);
  const [modalPrivateDoc, setModalPrivateDoc] = useState<boolean>(false);
  const [modalAddFamily, setModalAddFamily] = useState<boolean>(false);
  const [familyData, setFamilyData] = useState<any>([]);
  const [privateDoc, setPrivateDoc] = useState<any>([]);

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Database Karyawan',
        href: '/v2/app/hr-employee/list-employee',
      },
      {
        clickable: false,
        displayPathName: `${initialData.employee_name}`,
      },
    ],
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const handleChangeAvatar = async (info) => {
    const base64Result: any = await getBase64(info.file.originFileObj);
    setFileFoto({ file_foto: base64Result, file_foto_name: info.file.name });
  };
  const meta: any = {
    columns: 4,
    fields: [
      {
        key: 'avatar',
        colSpan: 0.25,
        render: () => {
          return (
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              accept="image/*"
              beforeUpload={beforeUpload}
              onChange={handleChangeAvatar}
            >
              {fileFoto?.file_foto ? (
                <img src={fileFoto.file_foto} alt="avatar" style={{ width: '100%' }} />
              ) : (
                'Upload'
              )}
            </Upload>
          );
        },
      },
      {
        key: 'employee_name',
        colSpan: 1.5,
        label: 'Nama Karyawan',
        rules: [{ required: true, whitespace: true, message: 'Nama Karyawan harus diisi' }],
      },
    ],
  };

  const personal_info_meta: any = {
    columns: 2,
    fields: [
      {
        key: 'citizenship',
        label: 'Kewarganegaraan (Negara)',
        widget: 'select',
        options: countryList,
        widgetProps: {
          showSearch: true,
        },
      },
      {
        key: 'street_1_by_id',
        label: 'Nama Jalan 1',
      },
      {
        key: 'religion',
        label: 'Agama',
        widget: 'select',
        options: religionList,
      },
      {
        key: 'street_2_by_id',
        label: 'Nama Jalan 2',
      },
      {
        key: 'nomor_kk',
        label: 'Nomor Kartu Keluarga',
        rules: [
          {
            validator: (rule, value, callback) => {
              return new Promise<void>((resolve, reject) => {
                if (value && (isNaN(value) || value.length > 20)) {
                  reject(new Error('Nomor Kartu Keluarga tidak valid'));
                } else {
                  resolve();
                }
              });
            },
          },
        ],
      },
      {
        key: 'province_by_id_id',
        label: 'Provinsi',
        widget: 'select',
        options: listProvince.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        }),
        onChange: async (value) => {
          form.setFieldsValue({
            city_by_id_id: undefined,
            district_by_id_id: undefined,
            village_by_id_id: undefined,
          });
          const cities: any = await useCaseImpl.getCities({ prov_id: [value] });
          if (!!cities) {
            setListCity(cities?.data?.content);
          }
        },
      },
      {
        key: 'nomor_ktp',
        label: 'Nomor KTP',
        rules: [
          {
            validator: (rule, value, callback) => {
              return new Promise<void>((resolve, reject) => {
                if (value && (isNaN(value) || value.length > 16)) {
                  reject(new Error('Nomor KTP tidak valid'));
                } else {
                  resolve();
                }
              });
            },
          },
        ],
      },
      {
        key: 'city_by_id_id',
        label: 'Kab/Kota',
        widget: 'select',
        disabled: !form.getFieldValue('province_by_id_id'),
        options: listCity.map((item) => {
          return {
            label: item.Name,
            value: item.ID,
          };
        }),
        onChange: async (value) => {
          form.setFieldsValue({ district_by_id_id: undefined, village_by_id_id: undefined });
          const district: any = await useCaseImpl.getDistrict({ kab_id: [value] });
          if (!!district) {
            setListDistrict(district?.data?.content);
          }
        },
      },
      {
        key: 'bank_name',
        label: 'Nama Bank',
        disabled: true,
        initialValue: 'OCBC',
      },
      {
        key: 'district_by_id_id',
        label: 'Kecamatan',
        widget: 'select',
        disabled: !form.getFieldValue('city_by_id_id'),
        options: listDistrict.map((item) => {
          return {
            label: item.Name,
            value: item.ID,
          };
        }),
        onChange: async (value) => {
          form.setFieldsValue({ village_by_id_id: undefined });
          const subdistrict: any = await useCaseImpl.getSubdistrict({ kec_id: [value] });
          if (!!subdistrict) {
            setListSubdistrict(subdistrict?.data?.content);
          }
        },
      },
      {
        key: 'bank_number',
        label: 'Nomor Rekening Bank',
        required: true,
        message: 'Nomor Rekening Harus diisi',
        rules: [
          {
            validator: (rule, value, callback) => {
              return new Promise<void>((resolve, reject) => {
                if (value && (isNaN(value) || value.length > 12)) {
                  reject(Error('Nomor Rekening tidak valid'));
                } else {
                  resolve();
                }
              });
            },
          },
        ],
      },
      {
        key: 'village_by_id_id',
        label: 'Desa/Kelurahan',
        widget: 'select',
        disabled: !form.getFieldValue('district_by_id_id'),
        onChange: async (value, record, acc) => {
          form.setFieldsValue({
            postal_code_by_id_id: listSubdistrict.find((item) => item.ID === value).KodePos,
          });
        },
        options: listSubdistrict.map((item) => {
          return {
            label: item.Name,
            value: item.ID,
          };
        }),
      },
      {
        key: 'empty',
        render: () => {
          return null;
        },
      },
      {
        key: 'postal_code_by_id_id',
        label: 'Kode Pos',
        disabled: true,
      },
      {
        key: 'last_edu_id',
        label: 'Pendidikan Terakhir',
        widget: 'select',
        options: eduList,
      },
      {
        key: 'is_domisili',
        label: 'Alamat domisili sama dengan alamat KTP?',
        widget: 'checkbox',
      },
      {
        key: 'blood_group',
        label: 'Golongan Darah',
        widget: 'select',
        options: [
          { label: 'A', value: 'a' },
          { label: 'B', value: 'b' },
          { label: 'AB', value: 'ab' },
          { label: 'O', value: 'o' },
        ],
      },
      {
        key: 'street_1_by_res',
        label: 'Nama Jalan 1',
      },
      {
        key: 'gender',
        label: 'Jenis Kelamin',
        widget: 'select',
        required: true,
        message: 'Jenis Kelamin harus diisi',
        options: [
          { label: 'Laki-laki', value: 'male' },
          { label: 'Perempuan', value: 'female' },
        ],
      },
      {
        key: 'street_2_by_res',
        label: 'Nama Jalan 2',
      },
      {
        key: 'tanggal_lahir',
        label: 'Tanggal Lahir',
        required: true,
        message: 'Tanggal Lahir harus diisi',
        widget: 'date-picker',
      },
      {
        key: 'province_by_res_id',
        label: 'Provinsi',
        widget: 'select',
        options: listProvince.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        }),
        onChange: async (value) => {
          form.setFieldsValue({
            city_by_res_id: undefined,
            district_by_res_id: undefined,
            village_by_res_id: undefined,
          });
          const cities: any = await useCaseImpl.getCities({ prov_id: [value] });
          if (!!cities) {
            setListCityDom(cities?.data?.content);
          }
        },
      },
      {
        key: 'place_of_birth',
        label: 'Tempat Lahir',
      },
      {
        key: 'city_by_res_id',
        label: 'Kab/Kota',
        widget: 'select',
        disabled: !form.getFieldValue('province_by_res_id'),
        options: listCityDom.map((item) => {
          return {
            label: item.Name,
            value: item.ID,
          };
        }),
        onChange: async (value) => {
          form.setFieldsValue({ district_by_res_id: undefined, village_by_res_id: undefined });
          const district: any = await useCaseImpl.getDistrict({ kab_id: [value] });
          if (!!district) {
            setListDistrictDom(district?.data?.content);
          }
        },
      },
      {
        key: 'empty',
        render: () => {
          return null;
        },
      },
      // {
      //   key: 'file_cv',
      //   label: 'File CV',
      // },
      {
        key: 'district_by_res_id',
        label: 'Kecamatan',
        widget: 'select',
        disabled: !form.getFieldValue('city_by_res_id'),
        options: listDistrictDom.map((item) => {
          return {
            label: item.Name,
            value: item.ID,
          };
        }),
        onChange: async (value) => {
          form.setFieldsValue({ village_by_res_id: undefined });
          const subdistrict: any = await useCaseImpl.getSubdistrict({ kec_id: [value] });
          if (!!subdistrict) {
            setListSubdistrictDom(subdistrict?.data?.content);
          }
        },
      },
      {
        key: 'empty',
        render: () => {
          return null;
        },
      },
      {
        key: 'village_by_res_id',
        label: 'Desa/Kelurahan',
        widget: 'select',
        disabled: !form.getFieldValue('district_by_res_id'),
        onChange: async (value, record, acc) => {
          form.setFieldsValue({
            postal_code_by_res_id: listSubdistrict.find((item) => item.ID === value).KodePos,
          });
        },
        options: listSubdistrictDom.map((item) => {
          return {
            label: item.Name,
            value: item.ID,
          };
        }),
      },
      {
        key: 'empty',
        render: () => {
          return null;
        },
      },
      // {
      //   key: 'family_data',
      //   label: 'Data Keluarga',
      // },
      {
        key: 'postal_code_by_res_id',
        label: 'Kode Pos',
        disabled: true,
      },
    ],
  };
  const employement_info_meta: any = {
    columns: 2,
    fields: [
      {
        key: 'nik',
        label: 'Nomor Induk Karyawan',
        rules: [{ required: true, whitespace: true, message: 'Nomor Induk Karyawan harus diisi' }],
      },
      {
        key: 'effective_date',
        label: 'Tanggal Efektif',
        widget: 'date-picker',
        required: true,
        message: 'Tanggal Efektif',
        initialValue: moment()
      },
      {
        key: 'phone_number',
        label: 'Nomor HP',
        rules: [
          {
            validator: (rule, value, callback) => {
              return new Promise<void>((resolve, reject) => {
                if (value && (isNaN(value) || value.length > 15)) {
                  reject(new Error('Nomor HP tidak valid'));
                } else {
                  resolve();
                }
              });
            },
          },
        ],
      },
      {
        key: 'action',
        label: 'Action',
        widget: 'select',
        options: actionOption,
        required: true,
        message: 'Action harus diisi',
        initialValue: 'update_data'
      },
      {
        key: 'email',
        label: 'Alamat Email',
      },
      {
        key: 'category',
        label: 'Status Karyawan',
        widget: 'select',
        options: [
          { label: 'Karyawan Tetap', value: 'karyawan_tetap' },
          { label: 'Karyawan Kontrak', value: 'karyawan_kontrak' },
          { label: 'Karyawan Magang', value: 'karyawan_magang' },
          { label: 'Karyawan Paruh Waktu', value: 'karyawan_paruh_waktu' },
        ],
        required: true,
        message: 'Status karyawan harus dipilih',
      },
      {
        key: 'region',
        label: 'Region',
        widget: 'select',
        options: regionList,
        required: true,
        message: 'Region harus dipilih',
        onChange: async (value) => {
          form.setFieldsValue({ location: undefined });
          const params = {
            region_id: value,
          };
          const getLocation = await useCaseImpl.getLocation(params);
          if (!!getLocation) {
            setLocationList(getLocation);
          }
        },
      },
      {
        key: 'date_join',
        label: 'Tanggal Bergabung',
        widget: 'date-picker',
        required: true,
        message: 'Tanggal Bergabung harus diisi',
      },
      {
        key: 'location',
        label: 'Lokasi',
        widget: 'select',
        options: locationList,
        required: true,
        message: 'Lokasi harus dipilih',
      },
      {
        key: 'date_start_probation',
        label: 'Tanggal Mulai Percobaan',
        widget: 'date-picker',
        required: true,
        message: 'Tanggal Mulai Percobaan harus diisi',
      },
      {
        key: 'description',
        label: 'Keterangan',
      },
      {
        key: 'date_end_probation',
        label: 'Tanggal Berakhir Percobaan',
        widget: 'date-picker',
        required: true,
        message: 'Tanggal Berakhir Percobaan harus diisi',
      },
      {
        key: 'empty',
        render: () => {
          return null;
        },
      },
      {
        key: 'date_start_contract',
        label: 'Tanggal Mulai Kontrak',
        widget: 'date-picker',
        required: true,
        message: 'Tanggal Mulai Kontrak harus diisi',
      },
      {
        key: 'empty',
        render: () => {
          return null;
        },
      },
      {
        key: 'date_end_contract',
        label: 'Tanggal Berakhir Kontrak',
        widget: 'date-picker',
        required: true,
        message: 'Tanggal Berakhir Kontrak harus diisi',
      },
      {
        key: 'empty',
        render: () => {
          return null;
        },
      },
      {
        key: 'department',
        label: 'Departemen',
        widget: 'select',
        options: deptList,
        required: true,
        message: 'Departemen Harus diisi',
        onChange: async (value) => {
          form.setFieldsValue({ job: undefined });
          const getJob = await useCaseImpl.getJob({
            dept_id: JSON.parse(value)?.id,
            is_parent_emp: true,
          });
          if (!!getJob) {
            setJobList(getJob);
          }
        },
      },
      {
        key: 'empty',
        render: () => {
          return null;
        },
      },
      {
        key: 'job',
        label: 'Jabatan',
        widget: 'select',
        options: jobList,
        required: true,
        message: 'Jabatan Harus diisi',
        onChange: async (value) => {
          form.setFieldsValue({
            atasan_1: JSON.parse(value).parent_emp_1_name,
            atasan_2: JSON.parse(value).parent_emp_2_name,
          });
          forceUpdate();
        },
      },
      {
        key: 'empty',
        render: () => {
          return null;
        },
      },
      {
        key: 'atasan_1',
        label: 'Atasan 1',
        readOnly: true,
      },
      {
        key: 'empty',
        render: () => {
          return null;
        },
      },
      {
        key: 'atasan_2',
        label: 'Atasan 2',
        readOnly: true,
      },
    ],
  };
  const payrol_info_meta: any = {
    columns: 2,
    fields: [
      {
        key: 'ptkp',
        label: 'PTKP',
        widget: 'select',
        options: ptkpList,
        required: true,
        message: 'PTKP harus diisi',
      },
      {
        key: 'is_bpjs_ker',
        label: 'BPJS Ketenagakerjaan',
        widget: 'radio-group',
        options: ['Ikut', 'Tidak'],
        required: true,
        message: 'BPJS Ketenagakerjaan harus dipilih',
      },
      {
        key: 'is_npwp',
        label: 'NPWP',
        widget: 'radio-group',
        options: ['Punya', 'Tidak'],
        required: true,
        message: 'NPWP harus dipilih',
      },
      {
        key: 'bpjs_ker_num',
        label: 'Nomor BPJS Ketenagakerjaan',
      },
      {
        key: 'npwp_num',
        label: 'Nomor NPWP',
      },
      {
        key: 'is_bpjs_pen',
        label: 'BPJS Pensiun',
        widget: 'radio-group',
        options: ['Ikut', 'Tidak'],
        required: true,
        message: 'BPJS Pensiun harus dipilih',
      },
      {
        key: 'salary',
        label: 'Gaji Karyawan',
        tooltip: 'Gaji karyawan akan dipakai untuk perhitungan lembur',
        widget: 'number',
        widgetProps: {
          style: { width: '50%' },
          min: 0,
          formatter: (value) => `${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ','),
        },
      },
      {
        key: 'is_bpjs_kes',
        label: 'BPJS Kesehatan',
        widget: 'radio-group',
        options: ['Ikut', 'Tidak'],
        required: true,
        message: 'BPJS Kesehatan harus dipilih',
      },
      {
        key: 'empty',
        render: () => {
          return null;
        },
      },
      {
        key: 'bpjs_kes_num',
        label: 'Nomor BPJS Kesehatan',
      },
      {
        key: 'empty',
        render: () => {
          return null;
        },
      },
      {
        key: 'bpjs_kes_kls',
        label: 'Kelas BPJS',
      },
    ],
  };
  const metaAddFamily: any = {
    columns: 2,
    fields: [
      {
        key: 'f_name',
        label: 'Nama',
      },
      {
        key: 'f_last_edu',
        label: 'Pendidikan Terakhir',
        widget: 'select',
        options: eduList,
      },
      {
        key: 'f_relationship',
        label: 'Hubungan',
        widget: 'select',
        options: ['suami', 'istri', 'anak'],
      },
      {
        key: 'f_job',
        label: 'Pekerjaan',
      },
      {
        key: 'f_birth_place',
        label: 'Tempat Lahir',
      },
      {
        key: 'f_phone',
        label: 'No. HP',
      },
      {
        key: 'f_tanggal_lahir',
        label: 'Tanggal Lahir',
        widget: 'date-picker',
      },
    ],
  };

  const FAMILY_DATA_HEADER = [
    {
      title: 'Nama',
      dataIndex: 'name',
    },
    {
      title: 'Hubungan',
      dataIndex: 'f_relationship',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (value, result) => {
        return (
          <DeleteOutlined
            onClick={(e) => {
              setIsLoading(true);
              if (result.id) {
                const param: any = {
                  ids: [result.id],
                };
                const URL =
                  Constant.URL_BASE_COMPANY + `/v3/employee/master-employee/${employeeId}/family`;
                return API.delete(URL, { data: param }).then((res: any) => {
                  setIsLoading(false);
                  const tempFile = familyData.reduce((acc, val, i) => {
                    if (result.name !== val.name) {
                      acc.push(val);
                    }
                    return acc;
                  }, []);
                  localNotificationService.openSuccess('Data Keluarga Berhasil Dihapus', '', 3);
                  setFamilyData(tempFile);
                });
              } else {
                const tempFile = familyData.reduce((acc, val, i) => {
                  if (result.name !== val.name) {
                    acc.push(val);
                  }
                  return acc;
                }, []);
                setIsLoading(false);
                setFamilyData(tempFile);
              }
            }}
          />
        );
      },
    },
  ];

  const PRIVATE_DOC_HEADER = [
    {
      title: 'Nama',
      dataIndex: 'name',
    },
    {
      title: 'Data File',
      dataIndex: 'file_name',
      render: (value, result) => {
        return (
          <div
            onClick={async (e) => {
              setIsLoading(true);
              if (result.id) {
                const params: any = {
                  file_name: result.file_name,
                  employee_id: result.employee_id,
                };
                const URL =
                  Constant.URL_BASE_COMPANY +
                  `/v3/employee/master-employee/${result.employee_id}/file`;
                return API.get(URL, { params }).then(async (res: any) => {
                  if (res?.data?.content?.url) {
                    const tempDoc = await aesGcmDecrypt(res?.data?.content?.url);
                    openInNewTab(tempDoc);
                  }
                });
              }
            }}
          >
            {value}
          </div>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (value, result) => {
        return (
          <DeleteOutlined
            onClick={(e) => {
              setIsLoading(true);
              if (result.id) {
                const param: any = {
                  ids: [result.id],
                };
                const URL =
                  Constant.URL_BASE_COMPANY + `/v3/employee/master-employee/${employeeId}/file`;
                return API.delete(URL, { data: param })
                  .then((res: any) => {
                    setIsLoading(false);
                    const tempFile = privateDoc.reduce((acc, val, i) => {
                      if (result.name !== val.name) {
                        acc.push(val);
                      }
                      return acc;
                    }, []);
                    setPrivateDoc(tempFile);
                    localNotificationService.openSuccess(
                      'Dokument Private Berhasil Dihapus',
                      '',
                      3,
                    );
                  })
                  .catch((err: any) => {
                    localNotificationService.openError(err, '', 3);
                  });
              } else {
                const tempFile = privateDoc.reduce((acc, val, i) => {
                  if (result.name !== val.name) {
                    acc.push(val);
                  }
                  return acc;
                }, []);
                setIsLoading(false);
                setPrivateDoc(tempFile);
              }
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    initialPage();
  }, []);

  async function initialPage() {
    let getJob;
    let getLocation;
    let tempCV;
    const getCountry = await useCaseImpl.getCountry();
    const getReligion = await useCaseImpl.getReligion();
    const getEducation = await useCaseImpl.getEdu();
    const getDept = await useCaseImpl.getDepartment();
    const getPtkp = await useCaseImpl.getPtkp();
    const getRegion = await useCaseImpl.getRegion();
    const employeeDetail: any = await useCaseImpl.getEmployeeDetail(employeeId);
    const getProvince = await useCaseImpl.getProvince({
      country_id: [employeeDetail.country_id || 101],
    });
    if (getProvince?.data?.content && getProvince?.data?.content.length > 0) {
      setListProvince(getProvince?.data?.content);
    }
    if (employeeDetail.province_by_id_id) {
      const cities: any = await useCaseImpl.getCities({
        prov_id: [employeeDetail.province_by_id_id],
      });
      if (!!cities) {
        setListCity(cities?.data?.content);
        if (employeeDetail.province_by_id_id === employeeDetail.province_by_res_id) {
          setListCityDom(cities?.data?.content);
        }
      }
    }
    if (
      employeeDetail.province_by_res_id &&
      employeeDetail.province_by_id_id !== employeeDetail.province_by_res_id
    ) {
      const cities: any = await useCaseImpl.getCities({
        prov_id: [employeeDetail.province_by_res_id],
      });
      if (!!cities) {
        setListCityDom(cities?.data?.content);
      }
    }
    if (employeeDetail.city_by_id_id) {
      const district: any = await useCaseImpl.getDistrict({
        kab_id: [employeeDetail.city_by_id_id],
      });
      if (!!district) {
        setListDistrict(district?.data?.content);
        if (employeeDetail.city_by_id_id === employeeDetail.city_by_res_id) {
          setListDistrictDom(district?.data?.content);
        }
      }
    }
    if (
      employeeDetail.city_by_res_id &&
      employeeDetail.city_by_id_id !== employeeDetail.city_by_res_id
    ) {
      const district: any = await useCaseImpl.getDistrict({
        kab_id: [employeeDetail.city_by_res_id],
      });
      if (!!district) {
        setListDistrictDom(district?.data?.content);
      }
    }
    if (employeeDetail.district_by_id_id) {
      const subdistrict: any = await useCaseImpl.getSubdistrict({
        kec_id: [employeeDetail.district_by_id_id],
      });
      if (!!subdistrict) {
        setListSubdistrict(subdistrict?.data?.content);
        if (employeeDetail.district_by_id_id === employeeDetail.district_by_res_id) {
          setListSubdistrictDom(subdistrict?.data?.content);
        }
      }
    }
    if (
      employeeDetail.district_by_res_id &&
      employeeDetail.district_by_id_id !== employeeDetail.district_by_res_id
    ) {
      const subdistrict: any = await useCaseImpl.getSubdistrict({
        kec_id: [employeeDetail.district_by_res_id],
      });
      if (!!subdistrict) {
        setListSubdistrictDom(subdistrict?.data?.content);
      }
    }
    if (employeeDetail.list_private_doc) {
      setPrivateDoc(employeeDetail.list_private_doc);
    }
    if (employeeDetail.list_family_data) {
      setFamilyData(employeeDetail.list_family_data);
    }
    if (employeeDetail.file_foto) {
      const tempFoto = await aesGcmDecrypt(employeeDetail.file_foto);
      setFileFoto({ file_foto: tempFoto, file_foto_name: employeeDetail.file_foto_name });
    }
    if (employeeDetail.file_cv) {
      tempCV = await aesGcmDecrypt(employeeDetail.file_cv);
    }
    if (employeeDetail.department_id) {
      getJob = await useCaseImpl.getJob({
        dept_id: employeeDetail.department_id,
        is_parent_emp: true,
      });
    }
    if (employeeDetail.region_id) {
      getLocation = await useCaseImpl.getLocation({ region_id: employeeDetail.region_id });
    }

    const tempCitizen =
      employeeDetail.country_id &&
      getCountry.find((item) => item.key === employeeDetail.country_id)?.value;
    const tempLastEdu =
      employeeDetail.last_edu_id &&
      getEducation.find((item) => item.key === employeeDetail.last_edu_id)?.value;
    const tempReligion =
      employeeDetail.religion_id &&
      getReligion.find((item) => item.key === employeeDetail.religion_id)?.value;
    const tempRegion =
      employeeDetail.region_id &&
      getRegion.find((item) => item.key === employeeDetail.region_id)?.value;
    const tempLokasi =
      employeeDetail.location_id &&
      getLocation.find((item) => item.key === employeeDetail.location_id)?.value;
    const tempDepartemen =
      employeeDetail.department_id &&
      getDept.find((item) => item.key === employeeDetail.department_id)?.value;
    const tempJob =
      employeeDetail.job_id && getJob.find((item) => item.key === employeeDetail.job_id)?.value;
    const tempPtkp =
      employeeDetail.ptkp_category_id &&
      getPtkp.find((item) => item.key === employeeDetail.ptkp_category_id)?.value;

    // const tempJoinDate = dateService.convertFromTimezone(employeeDetail.date_join);
    let tempInitial = {
      employee_name: employeeDetail.name_related,
      citizenship: tempCitizen,
      street_1_by_id: employeeDetail.street_1_by_id,
      street_2_by_id: employeeDetail.street_2_by_id,
      province_by_id_id: employeeDetail.province_by_id_id,
      city_by_id_id: employeeDetail.city_by_id_id,
      district_by_id_id: employeeDetail.district_by_id_id,
      village_by_id_id: employeeDetail.village_by_id_id,
      religion: tempReligion,
      nomor_kk: employeeDetail.nomor_kk,
      nomor_ktp: employeeDetail.nomor_ktp,
      bank_number: employeeDetail.bank_account_number,
      last_edu_id: tempLastEdu,
      blood_group: employeeDetail.blood_group,
      gender: employeeDetail.gender,
      tanggal_lahir: employeeDetail.tanggal_lahir && moment(employeeDetail.tanggal_lahir),
      place_of_birth: employeeDetail.place_of_birth,
      street_1_by_res: employeeDetail.street_1_by_res,
      street_2_by_res: employeeDetail.street_2_by_res,
      province_by_res_id: employeeDetail.province_by_res_id,
      city_by_res_id: employeeDetail.city_by_res_id,
      district_by_res_id: employeeDetail.district_by_res_id,
      village_by_res_id: employeeDetail.village_by_res_id,
      postal_code_by_id_id: employeeDetail?.postal_code_by_id_id,
      postal_code_by_res_id: employeeDetail?.postal_code_by_res_id,
      nik: employeeDetail.nik,
      category: employeeDetail.category,
      phone_number: employeeDetail.mobile_phone,
      email: employeeDetail.work_email,
      region: tempRegion,
      location: tempLokasi,
      description: employeeDetail.notes,
      date_start_probation: employeeDetail.durasi_perc_start
        ? moment(employeeDetail.durasi_perc_start)
        : '',
      date_end_probation: employeeDetail.durasi_perc_end
        ? moment(employeeDetail.durasi_perc_end)
        : '',
      date_start_contract: employeeDetail.durasi_start ? moment(employeeDetail.durasi_start) : '',
      date_end_contract: employeeDetail.durasi_end ? moment(employeeDetail.durasi_end) : '',
      date_join: employeeDetail.date_join ? moment(employeeDetail.date_join) : '',
      department: tempDepartemen,
      job: tempJob,
      ptkp: tempPtkp,
      is_npwp: employeeDetail.is_npwp ? 'Punya' : 'Tidak',
      npwp_num: employeeDetail.npwp_num,
      is_bpjs_ker: employeeDetail.is_bpjs_ker ? 'Ikut' : 'Tidak',
      is_bpjs_kes: employeeDetail.is_bpjs_kes ? 'Ikut' : 'Tidak',
      is_bpjs_pen: employeeDetail.is_bpjs_pen ? 'Ikut' : 'Tidak',
      bpjs_ker_num: employeeDetail.bpjs_ker_num,
      bpjs_kes_num: employeeDetail.bpjs_kes_num,
      bpjs_kes_kls: employeeDetail.bpjs_kes_kls,
      salary: employeeDetail.salary,
      file_foto_name: employeeDetail.file_foto_name,
      atasan_1: employeeDetail.atasan_1,
      atasan_2: employeeDetail.atasan_2,
      history_id: employeeDetail.history_id,
      file_cv: tempCV,
      file_cv_filename: employeeDetail.file_cv_filename,
    };

    setJobList(getJob);
    setDeptList(getDept);
    setCountryList(getCountry);
    setReligionList(getReligion);
    setEduList(getEducation);
    setPtkpList(getPtkp);
    setRegionList(getRegion);
    setLocationList(getLocation);
    setInitialData(tempInitial);
    setIsLoading(false);
  }

  async function onFinish(e) {
    setIsLoading(true);
    let tempParam: any = {};
    let tempAddPrivate = [];
    let tempAddFamily = [];
    e.citizenship && (tempParam['country_id'] = JSON.parse(e.citizenship)?.id);
    e.religion && (tempParam['religion_id'] = JSON.parse(e.religion)?.id);
    e.last_edu_id && (tempParam['last_edu_id'] = JSON.parse(e.last_edu_id)?.id);
    e.department && (tempParam['department_id'] = JSON.parse(e.department)?.id);
    if (e.job) {
      tempParam['job_id'] = JSON.parse(e.job)?.id;
      tempParam['atasan_1_id'] = JSON.parse(e.job)?.parent_emp_1_id;
      tempParam['atasan_2_id'] = JSON.parse(e.job)?.parent_emp_2_id;
    }
    e.ptkp && (tempParam['ptkp_category_id'] = JSON.parse(e.ptkp)?.id);

    if (!!e.is_domisili) {
      tempParam['street_1_by_res'] = e.street_1_by_id;
      tempParam['street_2_by_res'] = e.street_2_by_id;
      tempParam['province_by_res_id'] = e.province_by_id_id;
      tempParam['city_by_res_id'] = e.city_by_id_id;
      tempParam['district_by_res_id'] = e.district_by_id_id;
      tempParam['village_by_res_id'] = e.village_by_id_id;
      tempParam['street_1_by_res'] = e.street_1_by_id;
    } else {
      tempParam['street_1_by_res'] = e.street_1_by_res;
      tempParam['street_2_by_res'] = e.street_2_by_res;
      tempParam['province_by_res_id'] = e.province_by_res_id;
      tempParam['city_by_res_id'] = e.city_by_res_id;
      tempParam['district_by_res_id'] = e.district_by_res_id;
      tempParam['village_by_res_id'] = e.village_by_res_id;
    }

    if (fileCV && fileCV.length > 0) {
      const base64Result: any = await getBase64(fileCV[0].originFileObj);
      tempParam['file_cv'] = base64Result.split(',')[1];
      tempParam['file_cv_filename'] = fileCV[0].name;
    }

    if (privateDoc && privateDoc.length > 0) {
      tempAddPrivate = privateDoc.reduce((acc, val, i) => {
        if (!val.id) {
          acc.push(val);
        }
        return acc;
      }, []);
    }
    if (familyData && familyData.length > 0) {
      tempAddFamily = familyData.reduce((acc, val, i) => {
        if (!val.id) {
          acc.push(val);
        }
        return acc;
      }, []);
    }

    const params = {
      id: employeeId,
      name_related: e.employee_name.trim(),
      category: e.category,
      bank_name: 'OCBC',
      blood_group: e.blood_group,
      gender: e.gender,
      place_of_birth: e.place_of_birth,
      res_address_state: true,
      street_1_by_id: e.street_1_by_id,
      street_2_by_id: e.street_2_by_id,
      province_by_id_id: e.province_by_id_id,
      city_by_id_id: e.city_by_id_id,
      district_by_id_id: e.district_by_id_id,
      village_by_id_id: e.village_by_id_id,
      nik: e.nik.trim(),
      mobile_phone: e.phone_number,
      work_email: e.email,
      region_id: e.region,
      location_id: e.location,
      notes: e.description,
      salary: e.salary,
      is_npwp: e?.is_npwp === 'Punya',
      npwp_num: e.npwp_num,
      is_bpjs_ker: e?.is_bpjs_ker === 'Ikut',
      bpjs_ker_num: e.bpjs_ker_num,
      is_bpjs_kes: e?.is_bpjs_kes === 'Ikut',
      bpjs_kes_num: e.bpjs_kes_num,
      is_bpjs_pen: e?.is_bpjs_pen === 'Ikut',
      bpjs_kes_kls: e.bpjs_kes_kls,
      nomor_kk: e.nomor_kk,
      nomor_ktp: e.nomor_ktp,
      tanggal_lahir: moment(e.tanggal_lahir).format('YYYY-MM-DD'),
      atasan_1: e.atasan_1 || '',
      atasan_2: e.atasan_2 || '',
      bank_account_number: e.bank_number,
      history_id: initialData.history_id,
      durasi_perc_start: e.date_start_probation
        ? moment(e.date_start_probation).format('YYYY-MM-DD')
        : '',
      durasi_perc_end: e.date_end_probation
        ? moment(e.date_end_probation).format('YYYY-MM-DD')
        : '',
      durasi_start: e.date_start_contract ? moment(e.date_start_contract).format('YYYY-MM-DD') : '',
      durasi_end: e.date_end_contract ? moment(e.date_end_contract).format('YYYY-MM-DD') : '',
      date_join: e.date_join ? moment(e.date_join).format('YYYY-MM-DD') : '',
      effective_date: e.effective_date ? moment(e.effective_date).format('YYYY-MM-DD') : '',
      action: e.action,
      ...tempParam,
    };

    if (!!fileFoto && initialData?.file_foto_name !== fileFoto?.file_foto_name) {
      const paramsFoto = {
        id: employeeId,
        file_foto: fileFoto.file_foto.split(',')[1],
        file_foto_name: fileFoto.file_foto_name,
      };
      useCaseImpl.uploadFoto(paramsFoto).then(
        (res: any) => {},
        (err) => {
          localNotificationService.openError(err, '', 3);
        },
      );
    }
    if (tempAddPrivate.length > 0) {
      let paramPrivateDoc = {};
      paramPrivateDoc['list_private_doc'] = tempAddPrivate.map((item) => {
        return {
          name: item.name,
          file_name: item.file_name,
          file_doc: item.file_doc,
        };
      });
      useCaseImpl.updatePrivateDoc(paramPrivateDoc, employeeId).then(
        (res: any) => {},
        (err) => {
          localNotificationService.openError(err, '', 3);
        },
      );
    }

    if (tempAddFamily.length > 0) {
      let paramFamily = {};
      paramFamily['list_family_data'] = tempAddFamily.map((item) => {
        const tempEdu = (item.f_last_edu && JSON.parse(item.f_last_edu)?.id) || '';
        return {
          f_phone: item.f_phone,
          name: item.name,
          f_relationship: item.f_relationship,
          f_birth_place: item.f_birth_place,
          f_last_edu: tempEdu,
          f_tanggal_lahir: moment(item.f_tanggal_lahir).format('YYYY-MM-DD'),
          f_job: item.f_job,
        };
      });
      useCaseImpl.updateFamilyData(paramFamily, employeeId).then(
        (res: any) => {},
        (err) => {
          localNotificationService.openError(err, '', 3);
        },
      );
    }

    useCaseImpl.update(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          history.push('/app/hr-employee/list-employee');
          localNotificationService.openSuccess('Data Karyawan Berhasil Ditambah', '', 3);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }

  function onAddFamily() {
    const e = form.getFieldsValue();

    const temp = {
      f_tanggal_lahir: e.f_tanggal_lahir,
      f_phone: e.f_phone,
      name: e.f_name,
      f_relationship: e.f_relationship,
      f_birth_place: e.f_birth_place,
      f_last_edu: e.f_last_edu,
      f_job: e.f_job,
    };

    setFamilyData(temp);
    setFamilyData([...familyData, temp]);
    form.setFieldsValue({
      f_tanggal_lahir: '',
      f_phone: '',
      f_name: '',
      f_relationship: '',
      f_birth_place: '',
      f_last_edu: '',
      f_job: '',
    });
    setModalAddFamily(false);
  }

  async function onAddPrivateDoc() {
    const e = form.getFieldsValue();

    if (filePrivateDoc && filePrivateDoc.length > 0) {
      const base64Result: any = await getBase64(filePrivateDoc[0].originFileObj);

      const temp = {
        name: e.doc_name,
        file_doc: base64Result.split(',')[1],
        file_name: filePrivateDoc[0].name,
      };

      setPrivateDoc([...privateDoc, temp]);
      form.setFieldsValue({
        doc_name: '',
      });
      setModalPrivateDoc(false);
    }
  }

  const beforeUpload = (file: RcFile) => {
    const isLt3M = file.size / 1024 / 1024 < 3;
    if (!isLt3M) {
      localNotificationService.openError('File Harus Lebih Kecil dari 3MB', '', 3);
    }
    return isLt3M;
  };

  let new_personal_info_meta = personal_info_meta;
  let new_employement_info_meta = employement_info_meta;
  let new_payrol_info_meta = payrol_info_meta;

  if (!!form.getFieldValue('is_domisili')) {
    const street1 = new_personal_info_meta.fields.findIndex((obj) => obj.key === 'street_1_by_res');
    const street2 = new_personal_info_meta.fields.findIndex((obj) => obj.key === 'street_2_by_res');
    const province = new_personal_info_meta.fields.findIndex(
      (obj) => obj.key === 'province_by_res_id',
    );
    const city = new_personal_info_meta.fields.findIndex((obj) => obj.key === 'city_by_res_id');
    const district = new_personal_info_meta.fields.findIndex(
      (obj) => obj.key === 'district_by_res_id',
    );
    const village = new_personal_info_meta.fields.findIndex(
      (obj) => obj.key === 'village_by_res_id',
    );
    const postal = new_personal_info_meta.fields.findIndex(
      (obj) => obj.key === 'postal_code_by_res_id',
    );

    new_personal_info_meta.fields[street1] = {
      key: 'street_1_by_res',
      render: () => {
        return null;
      },
    };
    new_personal_info_meta.fields[street2] = {
      key: 'street_2_by_res',
      render: () => {
        return null;
      },
    };
    new_personal_info_meta.fields[province] = {
      key: 'province_by_res_id',
      render: () => {
        return null;
      },
    };
    new_personal_info_meta.fields[city] = {
      key: 'city_by_res_id',
      render: () => {
        return null;
      },
    };
    new_personal_info_meta.fields[district] = {
      key: 'district_by_res_id',
      render: () => {
        return null;
      },
    };
    new_personal_info_meta.fields[village] = {
      key: 'village_by_res_id',
      render: () => {
        return null;
      },
    };
    new_personal_info_meta.fields[postal] = {
      key: 'postal_code_by_res_id',
      render: () => {
        return null;
      },
    };
  }

  if (form.getFieldValue('category')) {
    if (form.getFieldValue('category') === 'karyawan_tetap') {
      const objIndexStart = new_employement_info_meta.fields.findIndex(
        (obj) => obj.key === 'date_start_contract',
      );
      const objIndexEnd = new_employement_info_meta.fields.findIndex(
        (obj) => obj.key === 'date_end_contract',
      );
      new_employement_info_meta.fields[objIndexStart] = {
        key: 'date_start_contract',
        label: 'Tanggal Mulai Kontrak',
        widget: 'date-picker',
        disabled: true,
      };
      new_employement_info_meta.fields[objIndexEnd] = {
        key: 'date_end_contract',
        label: 'Tanggal Berakhir Kontrak',
        widget: 'date-picker',
        disabled: true,
      };
      
      form.setFieldValue('date_start_contract', null)
      form.setFieldValue('date_end_contract', null)
    }
    if (
      form.getFieldValue('category') === 'karyawan_magang' ||
      form.getFieldValue('category') === 'karyawan_paruh_waktu' ||
      form.getFieldValue('category') === 'karyawan_kontrak'
    ) {
      const objIndexStart = new_employement_info_meta.fields.findIndex(
        (obj) => obj.key === 'date_start_probation',
      );
      const objIndexEnd = new_employement_info_meta.fields.findIndex(
        (obj) => obj.key === 'date_end_probation',
      );
      new_employement_info_meta.fields[objIndexStart] = {
        key: 'date_start_probation',
        label: 'Tanggal Mulai Percobaan',
        widget: 'date-picker',
        disabled: true,
      };
      new_employement_info_meta.fields[objIndexEnd] = {
        key: 'date_end_probation',
        label: 'Tanggal Berakhir Percobaan',
        widget: 'date-picker',
        disabled: true,
      };
    }
  } else {
    if (initialData.category === 'karyawan_tetap') {
      const objIndexStart = new_employement_info_meta.fields.findIndex(
        (obj) => obj.key === 'date_start_contract',
      );
      const objIndexEnd = new_employement_info_meta.fields.findIndex(
        (obj) => obj.key === 'date_end_contract',
      );
      new_employement_info_meta.fields[objIndexStart] = {
        key: 'date_start_contract',
        label: 'Tanggal Mulai Kontrak',
        widget: 'date-picker',
        disabled: true,
      };
      new_employement_info_meta.fields[objIndexEnd] = {
        key: 'date_end_contract',
        label: 'Tanggal Berakhir Kontrak',
        widget: 'date-picker',
        disabled: true,
      };
    }
    if (
      initialData.category === 'karyawan_magang' ||
      initialData.category === 'karyawan_paruh_waktu'
    ) {
      const objIndexStart = new_employement_info_meta.fields.findIndex(
        (obj) => obj.key === 'date_start_probation',
      );
      const objIndexEnd = new_employement_info_meta.fields.findIndex(
        (obj) => obj.key === 'date_end_probation',
      );
      new_employement_info_meta.fields[objIndexStart] = {
        key: 'date_start_probation',
        label: 'Tanggal Mulai Percobaan',
        widget: 'date-picker',
        disabled: true,
      };
      new_employement_info_meta.fields[objIndexEnd] = {
        key: 'date_end_probation',
        label: 'Tanggal Berakhir Percobaan',
        widget: 'date-picker',
        disabled: true,
      };
    }
  }

  if(form.getFieldValue('action') && form.getFieldValue('action') !== 'update_data') {
    new_employement_info_meta = {
      columns: 2,
      fields: [
        {
          key: 'nik',
          label: 'Nomor Induk Karyawan',
          disabled: true,
          rules: [{ required: true, whitespace: true, message: 'Nomor Induk Karyawan harus diisi' }],
        },
        {
          key: 'effective_date',
          label: 'Tanggal Efektif',
          widget: 'date-picker',
          required: true,
          message: 'Tanggal Efektif',
          initialValue: moment()
        },
        {
          key: 'phone_number',
          label: 'Nomor HP',
          disabled: true,
          rules: [
            {
              validator: (rule, value, callback) => {
                return new Promise<void>((resolve, reject) => {
                  if (value && (isNaN(value) || value.length > 15)) {
                    reject(new Error('Nomor HP tidak valid'));
                  } else {
                    resolve();
                  }
                });
              },
            },
          ],
        },
        {
          key: 'action',
          label: 'Action',
          widget: 'select',
          options: actionOption,
          required: true,
          message: 'Action harus diisi',
          initialValue: 'update_data'
        },
        {
          key: 'email',
          label: 'Alamat Email',
          disabled: true,
        },
        {
          key: 'category',
          label: 'Status Karyawan',
          widget: 'select',
          options: [
            { label: 'Karyawan Tetap', value: 'karyawan_tetap' },
            { label: 'Karyawan Kontrak', value: 'karyawan_kontrak' },
            { label: 'Karyawan Magang', value: 'karyawan_magang' },
            { label: 'Karyawan Paruh Waktu', value: 'karyawan_paruh_waktu' },
          ],
          required: true,
          disabled: true,
          message: 'Status karyawan harus dipilih',
        },
        {
          key: 'region',
          label: 'Region',
          widget: 'select',
          options: regionList,
          required: true,
          disabled: true,
          message: 'Region harus dipilih',
          onChange: async (value) => {
            form.setFieldsValue({ location: undefined });
            const params = {
              region_id: value,
            };
            const getLocation = await useCaseImpl.getLocation(params);
            if (!!getLocation) {
              setLocationList(getLocation);
            }
          },
        },
        {
          key: 'date_join',
          label: 'Tanggal Bergabung',
          widget: 'date-picker',
          required: true,
          disabled: true,
          message: 'Tanggal Bergabung harus diisi',
        },
        {
          key: 'location',
          label: 'Lokasi',
          widget: 'select',
          options: locationList,
          required: true,
          disabled: true,
          message: 'Lokasi harus dipilih',
        },
        {
          key: 'date_start_probation',
          label: 'Tanggal Mulai Percobaan',
          widget: 'date-picker',
          disabled: true,
          message: 'Tanggal Mulai Percobaan harus diisi',
        },
        {
          key: 'description',
          label: 'Keterangan',
        },
        {
          key: 'date_end_probation',
          label: 'Tanggal Berakhir Percobaan',
          widget: 'date-picker',
          disabled: true,
          message: 'Tanggal Berakhir Percobaan harus diisi',
        },
        {
          key: 'empty',
          render: () => {
            return null;
          },
        },
        {
          key: 'date_start_contract',
          label: 'Tanggal Mulai Kontrak',
          widget: 'date-picker',
          disabled: true,
          message: 'Tanggal Mulai Kontrak harus diisi',
        },
        {
          key: 'empty',
          render: () => {
            return null;
          },
        },
        {
          key: 'date_end_contract',
          label: 'Tanggal Berakhir Kontrak',
          widget: 'date-picker',
          disabled: true,
          message: 'Tanggal Berakhir Kontrak harus diisi',
        },
        {
          key: 'empty',
          render: () => {
            return null;
          },
        },
        {
          key: 'department',
          label: 'Departemen',
          widget: 'select',
          options: deptList,
          required: true,
          message: 'Departemen Harus diisi',
          onChange: async (value) => {
            form.setFieldsValue({ job: undefined });
            const getJob = await useCaseImpl.getJob({
              dept_id: JSON.parse(value)?.id,
              is_parent_emp: true,
            });
            if (!!getJob) {
              setJobList(getJob);
            }
          },
        },
        {
          key: 'empty',
          render: () => {
            return null;
          },
        },
        {
          key: 'job',
          label: 'Jabatan',
          widget: 'select',
          options: jobList,
          required: true,
          message: 'Jabatan Harus diisi',
          onChange: async (value) => {
            form.setFieldsValue({
              atasan_1: JSON.parse(value).parent_emp_1_name,
              atasan_2: JSON.parse(value).parent_emp_2_name,
            });
            forceUpdate();
          },
        },
        {
          key: 'empty',
          render: () => {
            return null;
          },
        },
        {
          key: 'atasan_1',
          label: 'Atasan 1',
          readOnly: true,
        },
        {
          key: 'empty',
          render: () => {
            return null;
          },
        },
        {
          key: 'atasan_2',
          label: 'Atasan 2',
          readOnly: true,
        },
      ],
    };
  }

  if (form.getFieldValue('is_npwp')) {
    if (form.getFieldValue('is_npwp') === 'Tidak') {
      const objIndex = new_payrol_info_meta.fields.findIndex((obj) => obj.key === 'npwp_num');
      form.setFieldsValue({ npwp_num: undefined });
      new_payrol_info_meta.fields[objIndex] = {
        key: 'npwp_num',
        label: 'Nomor NPWP',
        disabled: true,
      };
    }
  } else {
    if (initialData.is_npwp === 'Tidak') {
      const objIndex = new_payrol_info_meta.fields.findIndex((obj) => obj.key === 'npwp_num');
      form.setFieldsValue({ npwp_num: undefined });
      new_payrol_info_meta.fields[objIndex] = {
        key: 'npwp_num',
        label: 'Nomor NPWP',
        disabled: true,
      };
    }
  }

  if (form.getFieldValue('is_bpjs_ker')) {
    if (form.getFieldValue('is_bpjs_ker') === 'Tidak') {
      const objIndex = new_payrol_info_meta.fields.findIndex((obj) => obj.key === 'bpjs_ker_num');
      form.setFieldsValue({ bpjs_ker_num: undefined });
      new_payrol_info_meta.fields[objIndex] = {
        key: 'bpjs_ker_num',
        label: 'Nomor BPJS Ketenagakerjaan',
        disabled: true,
      };
    }
  } else {
    if (initialData.is_bpjs_ker === 'Tidak') {
      const objIndex = new_payrol_info_meta.fields.findIndex((obj) => obj.key === 'bpjs_ker_num');
      form.setFieldsValue({ bpjs_ker_num: undefined });
      new_payrol_info_meta.fields[objIndex] = {
        key: 'bpjs_ker_num',
        label: 'Nomor BPJS Ketenagakerjaan',
        disabled: true,
      };
    }
  }
  if (form.getFieldValue('is_bpjs_kes')) {
    if (form.getFieldValue('is_bpjs_kes') === 'Tidak') {
      const objIndex = new_payrol_info_meta.fields.findIndex((obj) => obj.key === 'bpjs_kes_num');
      const objIndex2 = new_payrol_info_meta.fields.findIndex((obj) => obj.key === 'bpjs_kes_kls');
      form.setFieldsValue({ bpjs_kes_num: undefined, bpjs_kes_kls: undefined });
      new_payrol_info_meta.fields[objIndex] = {
        key: 'bpjs_kes_num',
        label: 'Nomor BPJS Kesehatan',
        disabled: true,
      };
      new_payrol_info_meta.fields[objIndex2] = {
        key: 'bpjs_kes_kls',
        label: 'Kelas BPJS',
        disabled: true,
      };
    }
  } else {
    if (initialData.is_bpjs_kes === 'Tidak') {
      const objIndex = new_payrol_info_meta.fields.findIndex((obj) => obj.key === 'bpjs_kes_num');
      const objIndex2 = new_payrol_info_meta.fields.findIndex((obj) => obj.key === 'bpjs_kes_kls');
      form.setFieldsValue({ bpjs_kes_num: undefined, bpjs_kes_kls: undefined });
      new_payrol_info_meta.fields[objIndex] = {
        key: 'bpjs_kes_num',
        label: 'Nomor BPJS Kesehatan',
        disabled: true,
      };
      new_payrol_info_meta.fields[objIndex2] = {
        key: 'bpjs_kes_kls',
        label: 'Kelas BPJS',
        disabled: true,
      };
    }
  }

  function openInNewTab(href) {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: href,
    }).click();
  }

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div style={{ position: 'relative', height: 'calc(100vh - 100px)' }}>
      <Form
        form={form}
        layout="horizontal"
        initialValues={initialData}
        onFinish={onFinish}
        onValuesChange={forceUpdate}
        scrollToFirstError={true}
      >
        <div>
          <div>
            <div>
              <Breadcrumb breadcrumbConf={breadcrumbConf} />
              <Modal
                visible={modalPrivateDoc}
                title={'Membuat Dokumen Pribadi'}
                onCancel={() => setModalPrivateDoc(false)}
                footer={[
                  <Button onClick={() => setModalPrivateDoc(false)}>Batal</Button>,
                  <Button onClick={() => onAddPrivateDoc()}>Simpan</Button>,
                ]}
              >
                <div>
                  <Form.Item
                    label="Nama Dokumen"
                    name="doc_name"
                    // rules={[{ required: true, message: 'Nama Dokumen Harus diisi' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Row style={{ marginBottom: 20 }}>
                    <span style={{ width: '25%' }}>File Upload : </span>
                    <Upload
                      style={{ width: '30%' }}
                      listType="picture"
                      maxCount={1}
                      multiple
                      beforeUpload={beforeUpload}
                      onChange={(info: any) => {
                        if (info.fileList) {
                          setFilePrivateDoc(info.fileList);
                        }
                      }}
                    >
                      <Button>Upload File Anda</Button>
                    </Upload>
                  </Row>
                </div>
              </Modal>
              <Modal
                open={modalAddFamily}
                title={'Membuat Data Keluarga'}
                onCancel={() => setModalAddFamily(false)}
                footer={[
                  <Button onClick={() => setModalAddFamily(false)}>Batal</Button>,
                  <Button onClick={() => onAddFamily()}>Simpan</Button>,
                ]}
              >
                <FormBuilder form={form} meta={metaAddFamily} />
              </Modal>
              <Form.Item className="form-footer" style={{ width: '100%', marginTop: 20 }}>
                <Button type="primary" onClick={() => form.submit()} disabled={isLoading}>
                  Simpan
                </Button>
                &nbsp; &nbsp;
                <Button onClick={() => history.push('/app/hr-employee/list-employee')}>
                  Batal
                </Button>
              </Form.Item>
              <div style={{ padding: 10, overflowY: 'scroll', height: '80vh' }}>
                <FormBuilder form={form} meta={meta} />
                <div style={{ marginTop: 20 }}>
                  <Tabs activeKey={activeTab} type="card" onChange={(value) => setActiveTab(value)}>
                    <TabPane tab="Informasi Pribadi" key="1" forceRender={true}>
                      <FormBuilder form={form} meta={new_personal_info_meta} />

                      <Row>
                        <div style={{ paddingBottom: 25, width: '50%' }}>
                          <div style={{ marginBottom: 20 }}>
                            <Row>
                              <span className="employee-field-label" style={{ width: '15%' }}>
                                File CV :{' '}
                              </span>
                              <Upload
                                style={{ width: '30%' }}
                                listType="picture"
                                maxCount={1}
                                multiple
                                beforeUpload={beforeUpload}
                                onChange={(info: any) => {
                                  if (info.fileList) {
                                    setFileCV(info.fileList);
                                  }
                                }}
                              >
                                <Button>Upload</Button>
                              </Upload>
                            </Row>
                            {initialData.file_cv && (
                              <Button
                                style={{ color: 'blue', fontWeight: 'bold', paddingLeft: '15%' }}
                                type="link"
                                onClick={() => openInNewTab(initialData.file_cv)}
                              >
                                {initialData.file_cv_filename}
                              </Button>
                            )}
                          </div>
                          <Row>
                            <span className="employee-field-label" style={{ width: '15%' }}>
                              Dokumen Pribadi :{' '}
                            </span>
                            <Button onClick={() => setModalPrivateDoc(true)}>Tambah</Button>
                          </Row>
                          {privateDoc && privateDoc.length > 0 && (
                            <div style={{ marginLeft: '15%', marginTop: 15, marginBottom: 25 }}>
                              <Table
                                columns={PRIVATE_DOC_HEADER}
                                dataSource={privateDoc}
                                pagination={null}
                              />
                            </div>
                          )}
                        </div>
                        <div style={{ paddingBottom: 25, width: '50%' }}>
                          <Row>
                            <span className="employee-field-label" style={{ width: '25%' }}>
                              Data Keluarga :{' '}
                            </span>
                            <Button onClick={() => setModalAddFamily(true)}>Tambah</Button>
                          </Row>
                          {familyData && familyData.length > 0 && (
                            <div style={{ marginLeft: '25%', marginTop: 15, marginBottom: 25 }}>
                              <Table
                                columns={FAMILY_DATA_HEADER}
                                dataSource={familyData}
                                pagination={null}
                              />
                            </div>
                          )}
                        </div>
                      </Row>
                    </TabPane>
                    <TabPane tab="Informasi Kepegawaian" key="2" forceRender={true}>
                      <FormBuilder form={form} meta={new_employement_info_meta} />
                    </TabPane>
                    <TabPane tab="Penggajian" key="3" forceRender={true}>
                      <FormBuilder form={form} meta={payrol_info_meta} />
                    </TabPane>
                    {/* <TabPane tab="Surat Peringatan" key="4">
                    </TabPane>
                    <TabPane tab="History Karyawan" key="5">
                    </TabPane> */}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
