import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import MasterJobDetailTemplate from '../../templates/master-job-detail-template/MasterJobDetailTemplate';
import HrMasterJobDetail from './MasterJobDetail.usecase';
import LocalNotificationService from "../../../../../services/LocalNotification.service";
let jobId = '';

const localNotificationService = new LocalNotificationService()
const useCaseImpl = new HrMasterJobDetail()
export default function MasterJobDetail({ match }) {
  const history = useHistory();
  jobId = match.params.jobId;
  const [dataSource, setDataSource] = useState(null);
  const [isActive, setIsActive] = useState(false)
  const [isRefresh, setIsRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [breadcrumbConf, setBreadcrumbConf] = useState({
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Master Jabatan',
        href: '/v2/app/hr-employee/list-master-job',
      },
      { clickable: false, displayPathName: '' },
    ],
  });

  let submitButton = { active: false, disabled: isLoading };
  const cancelButton = {
    label: 'BATAL',
    type: 'ghost',
    onClick: onClickCancel,
  };
  let updateButton = {
    label: 'UBAH',
    type: 'primary',
    active: true,
    onClick: onClickUpdate,
    disabled: isLoading,
  };

  useEffect(() => {
    initialPage();
  }, [isRefresh]);

  async function initialPage() {
    setIsLoading(true);
    const jobDetail: any = await useCaseImpl.getJobDetail(jobId);
    setDataSource(jobDetail);
    setIsActive(jobDetail.is_active)
    const tempBreadcrumbConf = JSON.parse(JSON.stringify(breadcrumbConf));
    tempBreadcrumbConf.manualData[1].displayPathName = jobDetail?.job_name;
    setBreadcrumbConf(tempBreadcrumbConf);
    setIsLoading(false);

  }

  function onClickCancel(e) {
    history.push('/app/hr-employee/list-master-job');
  }

  function onClickUpdate(e) {
    history.push(`/app/hr-employee/update-master-job/${jobId}`);
  }

  async function handleChangeStatus(status) {
    setIsLoading(true);
    const params = {
      id: [jobId],
      status: status ? 'aktif' : 'non-aktif'
    }
    useCaseImpl.changeStatus(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          const message = data.message
          localNotificationService.openSuccess(message, '', 1)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } else {
          localNotificationService.openToast('topRight', data?.error?.message, '', { color: '#fff', background: 'red' }, 3)
        }
        setIsLoading(false);
      },
      (err) => {
        localNotificationService.openToast('topRight', err, '', { color: '#fff', background: 'red' }, 3)
        setIsLoading(false);
      },
    );
  }


  const dropdownButton = {
    visible: true,
    disabled: isLoading,
    options: [
      { key: 'active', label: isActive ? 'Non-Aktifkan' : 'Aktifkan', onClick: () => handleChangeStatus(!isActive) },
    ]
  };

  return (
    <div
      style={{ position: 'relative', height: '100vh' }}
    >
      <MasterJobDetailTemplate
        submitButton={submitButton}
        firstActionButton={updateButton}
        secondActionButton={cancelButton}
        dropdownButton={dropdownButton}
        thirdActionButton={{ active: false }}
        fourthActionButton={{ active: false }}
        breadcrumbConf={breadcrumbConf}
        dataSource={dataSource}
        isLoading={isLoading}
      />
    </div>
  );
}
