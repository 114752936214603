import { useEffect, useState } from "react";
import { ToolbarDetailActionButton } from "../../../employee/components/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface";
import LocalNotificationService from "../../../../../services/LocalNotification.service";
import ToastService from "../../../../../services/Toast.service";
import { DetailMySubmissionClaimType } from "../../template/AddMySubmissionClaimTemplate.interface";
import AddMySubmissionClaimTemplate from "../../template/AddMySubmissionClaimTemplate";
import DetailEmployeeApprovalClaimUsecase from "./DetailEmployeeApprovalClaim.usecase";
import { Modal } from "antd";
import { getDetailClaimMapper } from "../../../../../@core/mapper/claim/DetailClaim.mapper";

const usecase = new DetailEmployeeApprovalClaimUsecase();
const localNotificationService = new LocalNotificationService();
const toastService = new ToastService()

const steps = [
  "Pengajuan", "Disetujui",
  "Persetujuan Ke-2", "Ditolak",
  "Batal", "Selesai"
]

let historyId
let state: "approve" | "reject"

export default function DetailEmployeeApprovalClaim({ match }) {
  const id = match.params.claimId

  const [isLoading, setIsLoading] = useState(false);
  const [detailDataSource, setDetailDataSource] = useState<DetailMySubmissionClaimType>();
  const [visibleWarningModal, setVisibleWarningModal] = useState(false)

  
  useEffect(() => {
    init();
  }, []);

  function init() {
    setIsLoading(true);
    usecase.getDetail(id).then((res) => {
      const content = res.data.content
        const claim_config_id = content.claim_config_id
        const employee_id = content.employee_id
        const params = {
          claim_config_id,
          employee_id,
        };
        usecase.getPlafonClaim(params).then(
          (res) => {
            const detailConfig = res.data.content
            const detail = getDetailClaimMapper(content, detailConfig)
            setDetailDataSource(detail)
            setIsLoading(false)
          }
        )
    }).catch(e => {
      toastService.error(e)

      setIsLoading(false);
    });
  }

  const approveData = () => {
    setIsLoading(true)
    const body = { state }

    usecase.update(body, id).then(
      (res) => {
        localNotificationService.openSuccess('Pengajuan Klaim telah ' + state === "approve" ? 'disetujui' : 'ditolak', '', 1)
        setTimeout(() => {
          window.location.href = "/v2/app/hr-claim/employee-approval-claim"
        }, 1500)
      }).catch(err => {
        setIsLoading(false)
        toastService.error(err)
      })
  }

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Persetujuan Klaim',
        href: "/v2/app/hr-claim/employee-approval-claim",
      },
      {
        clickable: false,
        displayPathName: detailDataSource?.name,
        href: "/v2/app/hr-claim/employee-approval-claim/" + id,
      }
    ],
  };

  const firstActionButton: ToolbarDetailActionButton = {
    label: 'SETUJU',
    type: 'primary',
    active: true,
    disabled: isLoading,
    onClick: () => {
      state = "approve"
      setVisibleWarningModal(true)
    },
  };

  const secondActionButton: ToolbarDetailActionButton = {
    label: 'TOLAK',
    type: 'primary',
    active: true,
    disabled: isLoading,
    onClick: () => {
      state = "reject"
      setVisibleWarningModal(true)
    },
  };

  const thirdActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: isLoading,
    onClick: () => {
      window.location.href = "/v2/app/hr-claim/employee-approval-claim"
    },
  };

  return (
    <div className="detail-employee-approval-claim">
      <Modal
        cancelText="BATALKAN"
        centered={true}
        visible={visibleWarningModal}
        cancelButtonProps={{ type: 'ghost' }}
        onOk={approveData}
        onCancel={() => setVisibleWarningModal(false)}
      >
        <div style={{
          display: "flex",
          width: '100%',
          flexDirection: "column",
          alignItems: 'center',
        }}>
          <p>
            Apakah anda yakin ingin 
            {state === "approve" ? ' menyetujui ' : ' menolak '} 
            Pengajuan Klaim
          </p>
          <a>{detailDataSource?.name}</a>
        </div>
      </Modal>
      <AddMySubmissionClaimTemplate
        breadcrumbConf={breadcrumbConf}
        dropdownActionButton={{ visible: false }}
        firstActionButton={firstActionButton}
        secondActionButton={secondActionButton}
        thirdActionButton={thirdActionButton}
        fourthActionButton={{ active: false }}
        submitButton={{ active: false }}
        detailDataSource={detailDataSource}
        historyId={historyId}
        steps={steps}
        activeSteps={detailDataSource?.state}
        onFieldChange={null}
        onFinish={null}
        onFinishFailed={null}
        isLoading={isLoading}
        viewMode={true}
      />
    </div>
  )
}