export const ClaimReportConstant = {
    TABLE_HEADER: [
      {
        title: 'NIK',
        key: 'nik',
        dataIndex: 'nik',
        width: 120,
      },
      { title: 'Nama Karyawan', key: 'emp_name', dataIndex: 'emp_name', width: 150 },
      { title: 'Jabatan', key: 'job_name', dataIndex: 'job_name', width: 150 },
      {
        title: 'Konfigurasi Klaim',
        key: 'claim_name',
        dataIndex: 'claim_name',
        width: 150
      },
      {
        title: 'Data Klaim',
        key: 'data_claim',
        dataIndex: 'data_claim',
        width: 120
      },
      {
        title: 'Plafon Klaim',
        key: 'claim_plafon',
        dataIndex: 'claim_plafon',
        width: 120
      },
      {
        title: 'Penggunaan Klaim',
        key: 'claim_total',
        dataIndex: 'claim_total',
        width: 150,
        isLink: true,
      },
      {
        title: 'Sisa Klaim',
        key: 'claim_rem',
        dataIndex: 'claim_rem',
        width: 120
      },
    ],
    SEARCH_COLUMN_KEY: [],
    SEARCH_COLUMN_COMPONENT: [],
    DEFAULT_EMPTY_DATA_SOURCE: [
      {
        key: 'text',
        name: '',
        ovt_type: '',
        status: '',
        ovt_type_rel: '',
      },
    ],
  };
