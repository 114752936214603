import { useState, useEffect } from 'react';
import { Button, Space, Form } from 'antd';
import StepsLeaveDetail from '../steps/StepsLeaveDetail';
import {
  ToolbarLeaveDetailTemplatePropsType,
  ToolbarLeaveDetailActionButton,
  ToolbarLeaveDetailSubmitButton,
} from './ToolbarLeaveDetailTemplate.interface';
import './ToolbarLeaveDetailTemplate.less';

export default function ToolbarLeaveDetailTemplate(props: ToolbarLeaveDetailTemplatePropsType) {
  let [firstActionButton, setFirstActionButton] = useState<ToolbarLeaveDetailActionButton>();
  let [secondActionButton, setSecondActionButton] = useState<ToolbarLeaveDetailActionButton>();
  let [thirdActionButton, setThirdActionButton] = useState<ToolbarLeaveDetailActionButton>();
  let [fourthActionButton, setFourthActionButton] = useState<ToolbarLeaveDetailActionButton>();
  let [submitButton, setSubmitButton] = useState<ToolbarLeaveDetailSubmitButton>();

  useEffect(() => {
    processActionButtonProps('firstActionButton');
  }, [props.firstActionButton]);
  useEffect(() => {
    processActionButtonProps('secondActionButton');
  }, [props.secondActionButton]);
  useEffect(() => {
    processActionButtonProps('thirdActionButton');
  }, [props.thirdActionButton]);
  useEffect(() => {
    processActionButtonProps('fourthActionButton');
  }, [props.fourthActionButton]);
  useEffect(() => {
    processSubmitButton();
  }, [props.submitButton]);

  const processActionButtonProps = (procedure) => {
    const result: any = {
      label: 'Untitled Button',
      type: 'primary',
      active: true,
      disabled: false,
    };

    if (props[procedure]) {
      result.label = props[procedure].label ? props[procedure].label : 'Untitled Button';
      result.type = props[procedure].type ? props[procedure].type : 'primary';
      result.active = props[procedure].active !== undefined ? props[procedure].active : true;
      result.disabled = props[procedure].disabled !== undefined ? props[procedure].disabled : false;
    }

    switch (procedure) {
      case 'firstActionButton':
        setFirstActionButton(result);
        break;
      case 'secondActionButton':
        setSecondActionButton(result);
        break;
      case 'thirdActionButton':
        setThirdActionButton(result);
        break;
      case 'fourthActionButton':
        setFourthActionButton(result);
        break;
      default:
        break;
    }
  };

  const processSubmitButton = () => {
    const result: any = {
      active: true,
      disabled: false,
    };

    if (props.submitButton) {
      result.active = props.submitButton.active !== undefined ? props.submitButton.active : true;
      result.disabled =
        props.submitButton.disabled !== undefined ? props.submitButton.disabled : false;
    }

    setSubmitButton(result);
  };

  const onClick = (procedure) => {
    if (props[procedure] && props[procedure].onClick) {
      props[procedure].onClick(procedure);
    }
  };

  const FirstActionButton = () => {
    return firstActionButton && firstActionButton.active ? (
      <Button
        type={firstActionButton.type}
        disabled={firstActionButton.disabled}
        onClick={() => onClick('firstActionButton')}
      >
        {firstActionButton.label}
      </Button>
    ) : null;
  };

  const SecondActionButton = () => {
    return secondActionButton && secondActionButton.active ? (
      <Button
        type={secondActionButton.type}
        disabled={secondActionButton.disabled}
        onClick={() => onClick('secondActionButton')}
      >
        {secondActionButton.label}
      </Button>
    ) : null;
  };

  const ThirdActionButton = () => {
    return thirdActionButton && thirdActionButton.active ? (
      <Button
        type={thirdActionButton.type}
        disabled={thirdActionButton.disabled}
        onClick={() => onClick('thirdActionButton')}
      >
        {thirdActionButton.label}
      </Button>
    ) : null;
  };

  const FourthActionButton = () => {
    return fourthActionButton && fourthActionButton.active ? (
      <Button
        type={fourthActionButton.type}
        disabled={fourthActionButton.disabled}
        onClick={() => onClick('fourthActionButton')}
      >
        {fourthActionButton.label}
      </Button>
    ) : null;
  };

  const SubmitButton = () => {
    return submitButton && submitButton.active ? (
      <Form.Item style={{ marginBottom: '0px' }}>
        <Button
          type="primary"
          htmlType="submit"
          disabled={submitButton.disabled}
          onClick={() => onClick('submitButton')}
        >
          SIMPAN
        </Button>
      </Form.Item>
    ) : null;
  };

  return (
    <div className="toolbar-leave-detail-template">
      <div className="toolbar-row-one">
        <Space style={{ margin: '20px 0px' }}>
          <SubmitButton />
          <FirstActionButton />
          <SecondActionButton />
        </Space>
      </div>

      <div className="toolbar-row-two">
        <Space>
          <ThirdActionButton />
          <FourthActionButton />
        </Space>

        <div className="step-leave-detail-wrapper">
          <StepsLeaveDetail activeStep={props.stepState} />
        </div>
      </div>
    </div>
  );
}
