import { useEffect, useState } from 'react';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import ToastService from '../../../../../services/Toast.service';
import { ToolbarDetailActionButton } from '../../../employee/components/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface';
import AddEmployeeSubmissionClaimUsecase from './AddEmployeeSubmissionClaim.usecase';
import AddMySubmissionClaimTemplate from '../../template/AddMySubmissionClaimTemplate';
import { AddSubmissionClaimMapper } from '../../../../../@core/mapper/claim/AddClaim.mapper';
import uploadAllFile from '../../../../../@core/interaction/asset/UpladAllFileObject.interaction';
import { useUserState } from '../../../../../context/UserContext';

const usecase = new AddEmployeeSubmissionClaimUsecase();
const localNotificationService = new LocalNotificationService();
const toastService = new ToastService()
const steps = [
  "Pengajuan", "Disetujui",
  "Persetujuan Ke-2", "Ditolak",
  "Selesai"
]
let employee_id

export default function AddEmployeeSubmissionClaim() {
  const userState: any = useUserState();

  const [isLoading, setIsLoading] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [listConfigClaim, setListConfigClaim] = useState([]);

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Pengajuan Klaim Karyawan',
        href: "/v2/app/hr-claim/employee-submission-claim",
      },
      {
        clickable: false,
        displayPathName: 'Baru',
        href: "/v2/app/hr-claim/employee-submission-claim/new/",
      }
    ],
  };

  const submitButton = { active: true, disabled: false };
  const firstActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: false,
    onClick: () => {
      const back = window.confirm("Anda Ingin Kembali")
      if (back) {
        window.location.href = "/v2/app/hr-claim/employee-submission-claim"
      }
    },
  };

  useEffect(() => {
    init()
  }, [])

  async function init() {
    setIsLoading(true)
    const empList = await usecase.getEmployeeList({
      timezone: userState?.auth?.tz,
    });
    setEmployeeList([...empList])    
    setIsLoading(false)
  }

  function processEmployee(value, form) {
    setIsLoading(true)
    employee_id = value
    form.setFieldsValue({
      claim_type: null,
      claim_limit: '',
      sisa_plafon: '',
    })

    usecase.getConfigClaim(value).then(
      (res) => {
        setListConfigClaim(res)
        setIsLoading(false)
      }
    )
  }
  
  function processClaimType(value, form) {
    setIsLoading(true);
    const params = {
      claim_config_id: value,
      employee_id,
    };

    usecase
      .getPlafonClaim(params)
      .then((res) => {
        const content = res.data.content
        const { claim_limit, claim_rem } = content
      
        form.setFieldsValue({
          claim_type: value,
          claim_limit: claim_limit,
          sisa_plafon: claim_rem,
        })
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }


  const onFieldChange = (e, form) => {
    if (e.length === 1) {
      const fieldName = e[0].name[0];
      const value = e[0].value;

      switch (fieldName) {
        default:
          break;
        case 'employee':
          processEmployee(value, form);
          break;
        case 'claim_type':
          processClaimType(value, form);
          break;
      }
    }
  };

  const onFinish = async (e) => {
    setIsLoading(true)
    uploadAllFile(e.tabs_wrapper.dataSource)
    .then((assets) => {
      const body = AddSubmissionClaimMapper(e, employeeList, assets)
      if(!!body.detail_claim.length) {
        usecase.create(body).then(
          (res) => {
            const data = res.data
            const message = data.content
            localNotificationService.openSuccess(message, '', 1)
            setTimeout(() => {
              window.location.href = "/v2/app/hr-claim/employee-submission-claim"
            }, 1500)
          }
        ).catch((err) => {
          toastService.error(err)
          setIsLoading(false)
        })
      } else {
        toastService.error("Rincian tidak boleh kosong");
        setIsLoading(false)
      }
    })
    .catch((err) => {
      setIsLoading(false);
    });
  }

  return (
    <div className="add-employee-submission-claim">
      <AddMySubmissionClaimTemplate
        breadcrumbConf={breadcrumbConf}
        dropdownActionButton={{ visible: false }}
        firstActionButton={firstActionButton}
        secondActionButton={{ active: false }}
        thirdActionButton={{ active: false }}
        fourthActionButton={{ active: false }}
        submitButton={submitButton}
        historyId={null}
        empoyeeList={employeeList}
        listConfigClaim={listConfigClaim}
        activeSteps="Pengajuan"
        steps={steps}
        onFieldChange={onFieldChange}
        onFinish={onFinish}
        onFinishFailed={null}
        isLoading={isLoading}
        viewMode={false}
      />
    </div>
  )
}
