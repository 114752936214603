import React from 'react';
import Constants from '../../../../config/Constants';
import { Button, Col, Form, Input, Modal, Row, Skeleton, Table } from 'antd';
import Title from 'antd/lib/typography/Title';
import HrLeaveService from '../HrLeaveService';
import Moment from 'moment';
import './HrLeaveCorrectionList.less';
import ToastService from '../../../../services/Toast.service';

const hrLeaveService = new HrLeaveService();
const toastService = new ToastService();

export default function HrLeaveCorrectionList() {
  const pathname = window.location.pathname.split('/');

  let [list, setList] = React.useState();
  let [page, setPage] = React.useState(Constants.DEFAULT_FIRST_PAGE);
  let [limit, setLimit] = React.useState(Constants.DEFAULT_PAGE_SIZE);
  let [sort, setSort] = React.useState(Constants.DEFAULT_SORT);
  let [filter, setFilter] = React.useState();
  let [detail, setDetail] = React.useState(null);
  let [listDetail, setListDetail] = React.useState([]);
  let [isTableLoading, setTableLoading] = React.useState(false);
  let [isModalVisible, setModalVisible] = React.useState(false);
  let [tableScroll, setTableScroll] = React.useState({ y: 200 });

  const columns = [
    {
      dataIndex: 'create_date',
      title: 'Tanggal Koreksi',
      render: (row) => {
        return Moment(row).format('DD MMMM YYYY');
      },
    },
    {
      title: 'Nama Karyawan',
      render: (row) => {
        const names = row.items.split(', ');
        let sum = row.items;
        if (names.length > 3) {
          sum = `${names[0]}, ${names[1]}, ${names[2]} & ${names.length - 3} lainnya`;
        }
        return (
          <a
            onClick={(e) => {
              e.preventDefault();
              showModal(row);
            }}
          >
            {sum}
          </a>
        );
      },
    },
    {
      dataIndex: 'create_name',
      title: 'Dikoreksi Oleh',
    },
  ];

  const getList = () => {
    hrLeaveService
      .getListCorrection(page, limit, filter, sort)
      .then((response: any) => {
        if (response.status === Constants.RESP_OK) {
          setList(response.data.content);
          const clientHeight = document.getElementById('leave-correction-list-page').clientHeight;
          setTableScroll({ y: clientHeight - 300 });
        }
      })
      .catch((error) => {
        setTableLoading(false);
      });
  };

  const handleChangePage = (pageNum, lmt, fltr) => {
    setLimit(lmt);
    setPage(pageNum);
    setFilter(fltr);
    getList();
  };

  const showModal = (detail) => {
    setDetail(detail);
    hrLeaveService
      .getDetailCorrection(detail.id)
      .then((response: any) => {
        if (response.status === Constants.RESP_OK) {
          setListDetail(response.data.content);
          setModalVisible(true);
        }
      })
      .catch((error) => {
        toastService.error('Failed getting detail information');
      });
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  React.useEffect(() => {
    if (pathname.length === 6 && pathname['5'] === 'new') {
      // setPageAdd(true);
    } else {
      getList();
      // setPageAdd(false);
    }
  }, []);

  return (
    <div id="leave-correction-list-page">
      <Row>
        <Col span={24}>
          <Title level={4}>Koreksi Cuti</Title>
        </Col>
      </Row>
      <Form
        onFinish={(e) => {
          handleChangePage(page, limit, filter);
        }}
      >
        <Form.Item name="search_name">
          <Row style={{ marginTop: '14px' }}>
            <Col flex="60%">
              <a href="/v2/app/hr-leave/correction/new">
                <Button type="primary">Tambah Koreksi Cuti</Button>
              </a>
              <a style={{ marginLeft: '20px' }} href="/v2/app/hr-leave/correction/import/">
                <Button type="primary">Import</Button>
              </a>
            </Col>
            <Col flex="40%">
              <Input
                name="search"
                placeholder="Cari items..."
                onChange={(e: any) => setFilter(e.target.value)}
              />
            </Col>
          </Row>
        </Form.Item>
      </Form>
      <Row>
        <Col span={24}>
          {isTableLoading ? (
            <Skeleton />
          ) : (
            <Table
              loading={isTableLoading}
              columns={columns}
              dataSource={list}
              pagination={{
                onChange: (pageNum, lmt) => {
                  handleChangePage(pageNum, lmt, null);
                },
                size: 'small',
                defaultCurrent: Constants.DEFAULT_FIRST_PAGE,
                pageSize: limit,
                pageSizeOptions: Constants.DEFAULT_PAGE_OPTIONS,
                position: ['topRight', 'bottomRight'],
              }}
              scroll={tableScroll}
            />
          )}
        </Col>
      </Row>

      <Modal
        title="Detail Cuti"
        visible={isModalVisible}
        onCancel={handleCancel}
        width="50%"
        footer={null}
      >
        {detail === null ? (
          <Skeleton />
        ) : (
          <div>
            <Row>
              <Col flex="50%">
                <div>Tanggal Koreksi</div>
                <div>
                  <b>{Moment(detail.create_date).format('DD MMMM YYYY - HH:mm')}</b>
                </div>
              </Col>
              <Col flex="50%">
                <div>Dikoreksi oleh</div>
                <div>
                  <b>{detail.create_uname}</b>
                </div>
              </Col>
            </Row>
            <br />

            <Table
              columns={[
                { title: 'Nama Karyawan', dataIndex: 'employee_name' },
                { title: 'Tipe Cuti', dataIndex: 'leave_type' },
                { title: 'Plafon Terakhir', dataIndex: 'old_leave_plafon' },
                { title: 'Sisa Cuti Terakhir', dataIndex: 'old_leave_rem' },
                { title: 'Perubahan Plafon', dataIndex: 'leave_plafon' },
                { title: 'Perubahan Sisa Cuti', dataIndex: 'leave_rem' },
              ]}
              dataSource={listDetail}
              pagination={false}
              scroll={{ scrollToFirstRowOnChange: true }}
            />
          </div>
        )}
      </Modal>
    </div>
  );
}
