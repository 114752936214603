export const constant: any = {
  TABLE_HEADER: [
    {
      title: 'Karyawan',
      key: 'employee_name',
      dataIndex: 'employee_name',
      width: 400,
      editable: false,
    },
    {
      title: 'Tanggal Efektif',
      key: 'date_start_schedule_work',
      dataIndex: 'date_start_schedule_work',
      editable: true,
      type: 'date',
    },
    {
      title: 'Tanggal Berakhir',
      key: 'date_end_schedule_work',
      dataIndex: 'date_end_schedule_work',
      editable: true,
      type: 'date',
    },
    {
      title: 'Jadwal Kerja',
      key: 'nama_jadwal_kerja',
      dataIndex: 'nama_jadwal_kerja',
      width: 380,
      editable: true,
      type: 'select',
      options: []
    },
  ],
  SEARCH_COLUMN_KEY: [
    { key: 'key', componentType: "search-input" },
  ],
  DEFAULT_EMPTY_DATA_SOURCE: [],
}