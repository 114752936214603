import { Col, Form, Table, TimePicker } from "antd";
import { validateTime } from "../AddMasterWorkingScheduleTemplate.service";

export const TableHeader = [
  { title: 'Hari', key: 'day', dataIndex: 'day', render: null },
  { title: 'Jam Mulai', key: 'start_hours', dataIndex: 'start_hours', render: null },
  { title: 'Jam Selesai', key: 'end_hours', dataIndex: 'end_hours', render: null },
  { title: 'Durasi Istirahat', key: 'break_duration', dataIndex: 'break_duration', render: null },
]

const DefaultTableData = [
  {
    key: 'senin',
    day: "Senin",
    start_hours: null,
    end_hours: null,
    break_duration: null,
  },
  {
    key: 'selasa',
    day: "Selasa",
    start_hours: null,
    end_hours: null,
    break_duration: null,
  },
  {
    key: 'rabu',
    day: "Rabu",
    start_hours: null,
    end_hours: null,
    break_duration: null,
  },
  {
    key: 'kamis',
    day: "Kamis",
    start_hours: null,
    end_hours: null,
    break_duration: null,
  },
  {
    key: 'jumat',
    day: "Jumat",
    start_hours: null,
    end_hours: null,
    break_duration: null,
  },
  {
    key: 'sabtu',
    day: "Sabtu",
    start_hours: null,
    end_hours: null,
    break_duration: null,
  },
  {
    key: 'minggu',
    day: "Minggu",
    start_hours: null,
    end_hours: null,
    break_duration: null,
  },
]

export default function TableDaysMasterSchedule({ onChange, value, viewMode }) {
  const TimePickerDay = ({ record, procedure }) => {
    return !viewMode ? (
      <Col>
        <TimePicker
          bordered={false}
          placeholder="HH:mm"
          format={"HH:mm"}
          disabled={viewMode}
          value={value && value[`${record.day}`] && value[`${record.day}`][procedure]}
          onChange={(v) => {
            return onChange({
              ...value,
              [`${record.day}`]: {
                ...value[`${record.day}`],
                [procedure]: v
              }
            })
          }}
        />
        <Form.Item
          style={{ margin: '-15px' }}
          name={`${record.day}.${procedure}`}
          rules={[
            { validator: () => validateTime(record, value, procedure) }
          ]}
        ></Form.Item>
      </Col>
    ) : value && value[`${record.day}`] && value[`${record.day}`][procedure] ? value[`${record.day}`][procedure].format("HH:mm") : null
  }

  TableHeader[1].render = (_, record) => {
    return <TimePickerDay record={record} procedure={'start'} />
  }

  TableHeader[2].render = (_, record) => {
    return <TimePickerDay record={record} procedure={'end'} />
  }

  TableHeader[3].render = (_, record) => {
    return <TimePickerDay record={record} procedure={'break'} />
  }

  return (
    <Table
      key={'table-days-time-picker'}
      style={{ marginBottom: '30px' }}
      pagination={false}
      columns={TableHeader}
      dataSource={DefaultTableData}
    />
  )
}