import {
  GetLeaveDurationDTO,
  GetLeaveDurationResp,
} from './../../interface/leave/GetLeaveDuration.interface';
import Constant from '../../../config/Constants';
import API from '../../../services/API.service';

export default function getLeaveDuration(body: GetLeaveDurationDTO): Promise<GetLeaveDurationResp> {
  const URL = Constant.URL_BASE_ATTENDANCE + '/v3/leave/request/calculate/duration';
  return API.post(URL, body);
}
