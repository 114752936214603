import Constant from '../../../../../config/Constants';
import API from '../../../../../services/API.service';
import { listRecapMapper } from '../../../../../@core/mapper/attendance/GetListRecapRadius.mapper';

export default class RecapListUsecase {
  getListRecapRadius(params) {
    const URL = Constant.URL_BASE_ATTENDANCE + '/v3/recap/out-of-radius';
    return API.get(URL, { params }).then((res: any) => listRecapMapper(res.data));
  }
  setAbsent(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + '/v3/recap/out-of-radius/update';
    return API.put(URL, body);
  }
  cancleAbsent(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + '/v3/recap/out-of-radius/cancel';
    return API.put(URL, body);
  }
  export(params) {
    const URL = Constant.URL_BASE_ATTENDANCE + '/v3/recap/out-of-radius/export';
    return API.get(URL, { params });
  }
  getGeofencing(params): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const URL = Constant.URL_BASE_ATTENDANCE + "/v3/absensi/detail/location"
      return API.get(URL, {params}).then(async (res: any) => {
        if (res.status === 200) {
          const content = res.data.content;
          resolve(content);
        } else {
          reject(res);
        }
      })
    });
  }
}
