import Constant from '../../../config/Constants';
import API from '../../../services/API.service';
import {
  GetEmployeeListResp,
  GetEmployeeListDTO,
} from '../../interface/employee/GetEmployeeList.interface';

export default function getEmployeeList(params: GetEmployeeListDTO): Promise<GetEmployeeListResp> {
  const URL = Constant.URL_BASE_COMPANY + '/v3/lov/emp';
  return API.get(URL, { params });
}
