import Constant from "../../../../../config/Constants"
import API from "../../../../../services/API.service"

export default class FilterLoanInstallmentReportUsecase {
  getEmployeeList() {
    const URL = Constant.URL_BASE_LOAN + "/v3/report/installment/employee"
    return API.get(URL).then((res:any) => 
      {
        const result = res.data.content.map(element => {
          return {
            key: element.employee_id, 
            label: element.employee_name, 
            value: element.employee_id, 
            list_loan_number: element.name
          }
        })
        return result;
      })
  }
}