import { ImportCsvTemplatePropsType } from './ImportCsvTemplate.interface';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import { Table } from 'antd';
import ImportCsvSection from './component/import-csv-section/ImportCsvSection';
import ButtonBar from './component/button-bar/ButtonBar';
import BottomLoading from '../../components/bottom-loading/BottomLoading';

export default function ImportCsvTemplate(props: ImportCsvTemplatePropsType) {
  return (
    <div
      className="import-csv-template"
      style={{ height: 'calc(100vh - 100px)', position: 'relative' }}
    >
      <Breadcrumb breadcrumbConf={props.breadcrumbConf} />

      <div style={{ marginTop: '30px' }}>
        <ImportCsvSection chooseFileButton={props.chooseFileButton} />
      </div>

      <ButtonBar
        saveButton={props.saveButton}
        validateButton={props.validateButton}
        downloadTemplateButton={props.downloadTemplateButton}
      />

      <div className="data-viewer" style={{ marginTop: '30px' }}>
        <Table
          dataSource={props.tableData || []}
          columns={props.tableHeader || []}
          pagination={false}
          scroll={props.tableScroll ? props.tableScroll : false}
        />
      </div>

      <BottomLoading active={props.isLoading || false} />
    </div>
  );
}
