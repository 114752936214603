import { useEffect, useState } from "react";
import NotificationListTemplate from "../templates/notification-list-template/NotificationListTemplate";
import { NotificationCardPropsType, NotificationListTemplatePagination } from "../templates/notification-list-template/NotificationListTemplate.interface";
import { NotificationUsecase } from "./Notificartion.usecase";
import moment from "moment";

const usecase = new NotificationUsecase()

let page = 1
let limit = 12
let inquiry
let loadPagination = true

export default function TerminationNotification() {
  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([]);
  let [pagination, setPagination] = useState<NotificationListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Notifikasi',
        href: '/v2/app/admin-dashboard',
      },
      {
        clickable: false,
        displayPathName: 'Masa Kerja Berakhir',
        href: '',
      },
    ],
  }

  useEffect(() => {
    loadList()
  }, [])

  function loadList() {
    setIsLoading(true)
    const params = {
      page,
      limit,
      inquiry,
      with_meta: true,
    }

    usecase.getTermination(params).then(
      (res) => {
        const content = res.data.content
        const metaData = res.data.metadata;

        const resultDataSource = mappingDataSource(content)

        setDataSource([...resultDataSource])
        updatePaginationState(metaData?.total_records);

        loadPagination = true;
        setIsLoading(false)
      }
    ).catch(e => {
      setIsLoading(false)
    })
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  function mappingDataSource(list): NotificationCardPropsType[] {
    return list?.map((e) => {
      return {
        key: e.id,
        title: 'Masa Kerja Berakhir',
        extra: moment(e.effective_date).format('DD MMMM YYYY'),
        src: e.file_foto_name,
        name: e.employee_name,
        job: e.dept_job_name,
        button: 'Lihat Detail',
        href: "/v2/app/hr-employee/update-master-employee/" + e.id
      }
    })
  }

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;

    processPagination(outputPagination);
  };

  const onSearch = (e) => {
    inquiry = e
    loadList()    
  }

  return (
    <NotificationListTemplate
      breadcrumbConf={breadcrumbConf}
      importButton={{ visible: false }}
      dataSource={dataSource}
      tablePagination={pagination}
      tableOnChange={tableOnChange}
      onSearch={onSearch}
      isLoading={isLoading}
    />
  )
}