import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import EmployeeLeaveDetailTemplate from '../../../templates/employee-leave-detail-template/EmployeeLeaveDetailTemplate';
import { useHistory } from 'react-router-dom';
import LocalNotificationService from '../../../../../../services/LocalNotification.service';
import Constant from '../../../../../../config/Constants';
import {
  ToolbarLeaveDetailActionButton,
  ToolbarLeaveDetailSubmitButton,
} from '../../../templates/employee-leave-detail-template/components/toolbar-leave-detail-template/ToolbarLeaveDetailTemplate.interface';
import { EmployeeLeaveDetailGeneralForm, EmployeeLeaveDetailUnfinishLeave } from '../../../templates/employee-leave-detail-template/components/employee-leave-detail-form/EmployeeLeaveDetailForm.interface';
import { HrAddMySubmissionLeaveUseCase } from './HrAddMySubmissionLeave.usecase.impl';
import { GetJobListDTO } from '../../../../../../@core/interface/job/GetJobList.interface';
import { GetLeaveDurationDTO } from '../../../../../../@core/interface/leave/GetLeaveDuration.interface';
import { CreateLeaveRequestDTO } from '../../../../../../@core/interface/leave/CreateLeaveRequest.interface';
import ToastService from '../../../../../../services/Toast.service';
import ModalSubmissionLeave from '../../../templates/employee-leave-detail-template/components/modal-submission-list/ModalSubmissionLeave';
import { GetLeavePlafonDTO } from '../../../../../../@core/interface/leave/GetLeavePlafon.interface';

const useCaseImpl = new HrAddMySubmissionLeaveUseCase();
const localNotificationService = new LocalNotificationService();
const toastService = new ToastService();

const emptyColumn = { name: '', position: '' };
const approvalData = {
  firstApprovalData: [emptyColumn, emptyColumn, emptyColumn],
  secondApprovalData: [emptyColumn, emptyColumn, emptyColumn],
};
let employee_id;
let employee_name
let employee_nik
let job_id;
let department_id;
let leave_config_emp_id: string;
let archiveImage = ['', '', '', ''];
let isChange = false;
let jobList;
let uploadImagesResult = null;
let leave_category = '';
let params

export default function HrAddMySubmissionLeave() {
  const history = useHistory();
  const [visibleWarningModal, setVisibleWarningModal] = useState(false);
  const [visibleListModal, setVisibleListModal] = useState(false)
  let [submissionDate, setSubmissionDate] = useState({
    active: true,
    value: new Date().toLocaleDateString(),
    editMode: true,
  });
  let [employee, setEmployee] = useState({ active: true, value: '', editMode: false });
  let [position, setPosition] = useState({ active: true, value: '', editMode: true });
  let [department, setDepartment] = useState({ active: true, value: '', editMode: true });
  let [description, setDescription] = useState({
    active: true,
    value: '',
    editMode: true,
    rules: [{ required: true, message: 'Mohon lengkapi kolom ini' }],
  });
  let [leaveType, setLeaveType] = useState({
    active: true,
    value: null,
    editMode: true,
    options: [],
    rules: [{ required: true, message: 'Mohon lengkapi kolom ini' }],
  });
  let [remainsDayOff, setRemainsDayOff] = useState({ active: true, value: '', editMode: true });
  let [unfinishLeave, setUnfinishLeave] = useState<EmployeeLeaveDetailUnfinishLeave>({
    active: true,
    value: '',
    editMode: false,
    showList: <a onClick={() => setVisibleListModal(true)} >Tampilkan</a>,
    rules: [{}],
  });
  let [availPlafon, setAvailPlafon] = useState<EmployeeLeaveDetailGeneralForm>({
    active: true,
    value: '',
    editMode: false,
    rules: [{}],
  })
  let [leaveDate, setLeaveDate] = useState({
    active: true,
    startDate: null,
    value: '',
    endDate: null,
    editMode: true,
    rules: [{ required: true, message: 'Mohon lengkapi kolom ini' }],
  });
  let [leaveDuration, setLeaveDuration] = useState<EmployeeLeaveDetailGeneralForm>({
    active: true,
    value: '',
    editMode: true,
    rules: [{}],
  });
  let [isLoading, setIsLoading] = useState(true);

  const submitButton: ToolbarLeaveDetailSubmitButton = { active: true, disabled: isLoading };
  const firstActionButton: ToolbarLeaveDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    onClick: onClickActionButton,
  };

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {
    isChange = false;
    leave_config_emp_id = '';
    setIsLoading(true);

    const params: GetJobListDTO = {
      limit: 300,
      sort: 'id|asc',
    };
    jobList = await useCaseImpl.getJobList(params);

    const employeeDetail: any = await useCaseImpl.getEmployeeMe(jobList);
    employee_nik = employeeDetail.nik
    employee_name = employeeDetail.employee_name
    employee.value = `${employeeDetail.nik} ${employeeDetail.employee_name}`;
    position.value = employeeDetail.position;
    department.value = employeeDetail.department;

    job_id = employeeDetail?.job_id;
    department_id = employeeDetail?.department_id;
    employee_id = employeeDetail.employee_id;

    setEmployee(JSON.parse(JSON.stringify(employee)));
    setPosition(JSON.parse(JSON.stringify(position)));
    setDepartment(JSON.parse(JSON.stringify(department)));

    const employeeConfig = await useCaseImpl.getEmployeeLeaveConfigList({ employee_id });
    const tempLeaveType = JSON.parse(JSON.stringify(leaveType));

    tempLeaveType.options = employeeConfig;
    setLeaveType(tempLeaveType);

    setIsLoading(false);
  }

  function onClickActionButton(e) {
    if (isChange) {
      setVisibleWarningModal(true);
    } else {
      history.push('/app/hr-leave/my-submission');
    }
  }

  function onChangeArchiveImg(output, procedure) {
    switch (procedure) {
      default:
        break;
      case 'archive_img_1':
        archiveImage[0] = output.file;
        break;
      case 'archive_img_2':
        archiveImage[1] = output.file;
        break;
      case 'archive_img_3':
        archiveImage[2] = output.file;
        break;
      case 'archive_img_4':
        archiveImage[3] = output.file;
        break;
    }
  }

  function onFieldChange(e, form) {
    if (e.length === 1) {
      const fieldName = e[0].name[0];
      const value = e[0].value;

      switch (fieldName) {
        default:
          break;
        case 'tipe_cuti':
          processLeaveType(value);
          break;
        case 'tanggal_cuti':
          processLeaveDate(value);
          break;
      }
    }
  }

  async function processLeaveType(value) {
    if (leaveType.value !== value) {
      isChange = true;

      const tempLeaveType = JSON.parse(JSON.stringify(leaveType));
      tempLeaveType.value = value;

      setLeaveType(tempLeaveType);
      const { 
        leave_rem_plafon, 
        leave_queue,
        leave_rem 
      } = await setPlafon(value)

      const leavePlafon = Number(leave_rem_plafon) >= 0 ?
      `${leave_rem_plafon} Hari` : leave_rem_plafon

      const leaveRem = Number(leave_rem) >= 0 ?
      `${leave_rem} Hari` : leave_rem

      const selectedOption = leaveType.options.find((option) => option.value === value);

      remainsDayOff.value = leavePlafon;
      setRemainsDayOff(JSON.parse(JSON.stringify(remainsDayOff)));

      setUnfinishLeave(() => {
        return { ...unfinishLeave, value: `${leave_queue} Hari` }
      })

      availPlafon.value = leaveRem
      setAvailPlafon(JSON.parse(JSON.stringify(availPlafon)))

      leave_config_emp_id = selectedOption.configLineId;
      params = { leave_config_id: value, employee_id, leave_config_emp_id }
    }
  }

  async function setPlafon(leave_config_id) {
    setIsLoading(true)
    let result
    const params: GetLeavePlafonDTO = {
      leave_config_id,
      leave_config_emp_id,
      employee_id
    }

    await useCaseImpl.getLeavePlafon(params).then(
      (res) => {
        result = res.data.content
        setIsLoading(false)
      }, (err) => {
        setIsLoading(false);
      }
    )

    return result
  }

  function processLeaveDate(value) {
    if (value) {
      const startDate = new Date(leaveDate.startDate).toLocaleDateString();
      const endDate = new Date(leaveDate.endDate).toLocaleDateString();
      const value0 = new Date(value[0]).toLocaleDateString();
      const value1 = new Date(value[1]).toLocaleDateString();

      if (startDate !== value0 || endDate !== value1) {
        isChange = true;

        leaveDate.startDate = new Date(value[0]);
        leaveDate.endDate = new Date(value[1]);
        setLeaveDate(JSON.parse(JSON.stringify(leaveDate)));

        getDurationLeave(new Date(value[0]), new Date(value[1]));
      }
    }
  }

  function getDurationLeave(startDate, endDate) {
    setIsLoading(true);
    const params: GetLeaveDurationDTO = {
      date_start_leave: startDate,
      date_end_leave: endDate,
      employee_id,
    };
    useCaseImpl.getLeaveDuration(params).then(
      (res: any) => {
        setIsLoading(false);

        const data = res.data;
        const tempLeaveDuration = JSON.parse(JSON.stringify(leaveDuration));
        tempLeaveDuration.value = data.content.leave_duration;

        setLeaveDuration(tempLeaveDuration);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }

  function onFinish(e) {
    if (availPlafon.value !== "Tak Terbatas") {
      const remainsDayOffValue = Number(availPlafon.value.split(' ')[0]);
      const leaveDurationValue = Number(leaveDuration.value.split(' ')[0]);

      if (leaveDurationValue > remainsDayOffValue) {
        toastService.error('Cuti yang di ajukan melebihi sisa plafon yang tersedia');
      } else {
        createLeave(e);
      }
    } else {
      createLeave(e);
    }
  }

  async function createLeave(e) {
    setIsLoading(true);

    if (uploadImagesResult === null) {
      uploadImagesResult = await useCaseImpl.uploadAllImage(archiveImage);
    }

    const params: CreateLeaveRequestDTO = {
      employee_id,
      date_start_leave: leaveDate.startDate,
      date_end_leave: leaveDate.endDate,
      job_id,
      note: e.keterangan ? e.keterangan : '',
      state: 'draft',
      department_id,
      leave_config_id: e.tipe_cuti,
      leave_config_emp_id,
      leave_duration_int: Number(leaveDuration.value.split(' ')[0]),
      images: uploadImagesResult,
      reduce_leave_rem: true
    };

    useCaseImpl.submitLeave(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          history.push('/app/hr-leave/my-submission');
          localNotificationService.openSuccess('Pengajuan Cuti Berhasil', '', 3);
        }

        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
        const data = err.data;

        if (data.status === Constant.LOCAL_VALIDATION_ERROR) {
          toastService.error(data.error.message);
        }
      },
    );
  }

  function actionButtonModal(procedure) {
    setVisibleWarningModal(false);

    if (procedure === 'on-ok') {
      history.push('/app/hr-leave/my-submission');
    }
  }

  function actionHideModal() {
    setVisibleListModal(false);
  }

  return (
    <div
      className="add-my-submission-leave-page"
      style={{ position: 'relative', height: 'calc(100vh - 100px)' }}
    >
      <Modal
        title="Peringatan"
        cancelText="BATALKAN"
        visible={visibleWarningModal}
        cancelButtonProps={{ type: 'ghost' }}
        onOk={() => actionButtonModal('on-ok')}
        onCancel={() => actionButtonModal('on-cancel')}
      >
        <p>
          Data telah dimodifikasi, perubahan yang Anda buat akan diabaikan. Anda yakin ingin
          meninggalkan halaman ini?
        </p>
      </Modal>
      {params && (
        <ModalSubmissionLeave
          actionHideModal={actionHideModal}
          visibleModal={visibleListModal}
          dataSource={{
            nik: employee_nik,
            name: employee_name,
            params,
          }}
        />
      )}
      <EmployeeLeaveDetailTemplate
        submitButton={submitButton}
        firstActionButton={firstActionButton}
        secondActionButton={{ active: false }}
        thirdActionButton={{ active: false }}
        fourthActionButton={{ active: false }}
        stepState="Pengajuan"
        submissionDate={submissionDate}
        employee={employee}
        position={position}
        department={department}
        description={description}
        leaveType={leaveType}
        remainsDayOff={remainsDayOff}
        leaveDate={leaveDate}
        leaveDuration={leaveDuration}
        unfinishLeave={unfinishLeave}
        availPlafon={availPlafon}
        firstApprovalData={approvalData.firstApprovalData}
        secondApprovalData={approvalData.secondApprovalData}
        firstArchiveImg={{}}
        secondArchiveImg={{}}
        thirdArchiveImg={{}}
        fourthArchiveImg={{}}
        onChangeArchiveImg={onChangeArchiveImg}
        onFieldChange={onFieldChange}
        onFinish={onFinish}
        isLoading={isLoading}
      />
    </div>
  );
}
