import moment from 'moment';
import { getCheckinStatus, getCheckoutStatus, getAbsentStatus, getRadiusStatus } from './AttendanceStatus.mapper';

export function listRecapMapper(response) {
  const content = response.content ? response.content : null;
  let contentMap = null;

  if (content) {
    contentMap = content.map((data) => {
      const item = {
        id: data.id,
        key: data.id,
        employee_id: data.employee_id,
        employee: data.nik || data.nama ? `${data.nik} ${data.nama}` : '-',
        jabatan: data.jabatan || '-',
        checkin: data.check_in || '-',
        checkin_status: getCheckinStatus(data.category_check_in),
        checkout: data.check_out || '-',
        checkout_status: getCheckoutStatus(data.category_check_out),
        jadwal_kerja: data.jadwal_kerja || '-',
        work_hour: data.worked_hours || '-',
        total_late: data.total_late || '-',
        media_absensi: data.type_attendance || '-',
        desc: data.reason || '-',
        status_absent_info: getAbsentStatus(data.status_absent_info),
        radius_status: `Check in: ${getRadiusStatus(data.check_in_radius)} Check out: ${getRadiusStatus(data.check_out_radius)}`,
        location: 'Tampilkan',
        gmap_loc: data.gmap_loc,
        status_worked: data.status_worked,
        create_date_string: data.view_create_date,
        create_date: data.create_date,
        create_uid: data.create_uid,
        create_name: data.create_name,
        write_date_string: data.view_write_date,
        write_date: data.write_date,
        write_uid: data.write_uid,
        write_name: data.write_name,
        action: data.status_worked ? 'Ubah ke Tidak Masuk Kerja' : 'Batalkan Perubahan',
        buttonStyle: data.status_worked ? {backgroundColor: '#1bc604', borderColor: '#1bc604'} : {}
      };
      return item;
    });
  }

  return {
    content: contentMap,
    metaData: response.metadata,
  };
}
