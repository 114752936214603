const moment = require('moment')

export const HistoryTableHeader = [
  {
    title: "Tanggal Perubahan",
    dataIndex: "create_date",
    key: "create_date",
    render: (text) => {
      return moment(text).format('DD/MM/YYYY HH:mm:ss')
    }
  },
  {
    title: "Diubah Oleh",
    dataIndex: "write_name",
    key: "write_name",
    render: null
  },
  {
    title: "Action",
    dataIndex: "note",
    key: "note",
  },
]