import { EmployeeApprovalLeaveDetailUseCase } from '../../../../../../@core/use-case/leave/EmployeeApprovalLeaveDetail.usecase';
import getJobList from '../../../../../../@core/interaction/job/GetJobList.interaction';
import { JobListObjById } from '../../../../../../@core/entity/Job.entity';
import { GetJobListDTO } from '../../../../../../@core/interface/job/GetJobList.interface';
import { jobListToObjById } from '../../../../../../@core/mapper/job/GetJobList.mapper';
import {
  GetLeaveDetailDTO,
  GetLeaveDetailResp,
} from '../../../../../../@core/interface/leave/GetLeaveDetail.interface';
import getLeaveRequestDetail from '../../../../../../@core/interaction/leave/GetLeaveRequestDetail.interaction';
import { GetEmployeeLeaveConfigListResp } from '../../../../../../@core/interface/leave/GetEmployeeLeaveConfigList.interface';
import getEmployeeLeaveConfigList from '../../../../../../@core/interaction/leave/GetEmployeeLeaveConfigList.interaction';
import {
  leaveDetailFormMapper,
  leaveApprovalDetailMapper,
} from '../../../../../../@core/mapper/leave/LeaveDetail.mapper';
import getLeaveApprovalDetail from '../../../../../../@core/interaction/leave/GetLeaveApprovalDetail.interaction';
import {
  UpdateLeaveStateResp,
  UpdateLeaveStateDTO,
} from '../../../../../../@core/interface/leave/UpdateLeaveState.interface';
import { LeaveDetailApproval } from '../../../../../../@core/entity/SubmissionLeaveDetail.entity';
import updateLeaveState from '../../../../../../@core/interaction/leave/UpdateLeaveState.interaction';
import getLeavePlafon from '../../../../../../@core/interaction/leave/GetLeavePlafon.interaction';
import { getPendingSubmissionList } from '../../../../../../@core/interaction/leave/GetPendingSubmissionList.interaction';
import { LeaveId } from '../../../../../../@core/entity/Leave.entity';
import Constant from '../../../../../../config/Constants';
import API from '../../../../../../services/API.service';

export default class HrEmployeeApprovalLeaveDetailUseCaseImpl extends EmployeeApprovalLeaveDetailUseCase {
  getJobList(params: GetJobListDTO): Promise<JobListObjById> {
    return getJobList(params).then((res: any) => jobListToObjById(res.data.content));
  }

  getLeaveDetail(params: GetLeaveDetailDTO): Promise<GetLeaveDetailResp> {
    return new Promise<GetLeaveDetailResp>(async (resolve, reject) => {
      const leaveId = params.leaveId;
      const jobList = params.jobList;

      const leaveDetail = await getLeaveRequestDetail(leaveId);

      if (leaveDetail.status === 200 && leaveDetail.data?.content) {
        const employee_id = leaveDetail.data.content.employee_id;
        const getEmployeeDetailConfig: GetEmployeeLeaveConfigListResp =
          await getEmployeeLeaveConfigList({ employee_id });

        const mapEmployeeDetailConfig = {};
        getEmployeeDetailConfig.data.content.forEach((detail: any) => {
          mapEmployeeDetailConfig[detail.leave_type_id] = detail;
        });
        const resultMapper = leaveDetailFormMapper(
          leaveDetail.data.content,
          jobList,
        );
        resolve(resultMapper);
      } else {
        reject(leaveDetail);
      }
    });
  }

  getApprovalLeave(leaveId: number, jobList: JobListObjById): Promise<LeaveDetailApproval> {
    return getLeaveApprovalDetail(leaveId).then((res: any) =>
      leaveApprovalDetailMapper(res.data.content, jobList),
    );
  }

  approveLeave(params: UpdateLeaveStateDTO): Promise<UpdateLeaveStateResp> {
    return updateLeaveState(params);
  }

  rejectLeave(leaveId: LeaveId[]): Promise<UpdateLeaveStateResp> {
    const params: UpdateLeaveStateDTO = {
      leaveId,
      leaveState: 'reject',
    };
    return updateLeaveState(params);
  }

  getLeavePlafon(params) {
    const URL = Constant.URL_BASE_ATTENDANCE + '/v3/leave/approval/check'
    return API.get(URL, { params })
  }

  getPendingSubmissionList(params) {
    return getPendingSubmissionList(params)
  }
}
