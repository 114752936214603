import Constant from '../../../../../config/Constants';
import API from '../../../../../services/API.service';

export default class DetailApprovalLoanUsecase {
  getDetail(id) {
    const URL = Constant.URL_BASE_LOAN + '/v3/request/loan/approval/' + id;
    return API.get(URL);
  }

  update(body) {
    const URL = Constant.URL_BASE_LOAN + '/v3/request/loan/update';
    return API.put(URL, body);
  }

  getConfigLoan() {
    const URL = Constant.URL_BASE_LOAN + '/v3/lov/config';
    return API.get(URL).then((res) => {
      const content = res.data.content;
      const result = content?.map((config) => {
        return {
          key: config.config_loan_id,
          value: config.config_loan_id,
          label: config.config_name,
          loan_plafon: config.loan_plafon,
          loan_interest: config.loan_interest,
          loan_rule: config.loan_rule,
          max_installment: config.max_installment,
          department_id: config.department_id,
          employee_id: config.employee_id,
          job_id: config.job_id,
        };
      });
      return result
    });
  }
}
