import { Form, Button, Input, Space, Card, Result } from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserDispatch, loginUser } from '../../context/UserContext';
import useQuery from '../../services/UseQuery.service';
import AuthService from '../../context/AuthService';
import ToastService from '../../services/Toast.service';
import hiraLogo from '../../components/asset/hira_logo.png';

const authService = new AuthService();
const toastService = new ToastService();

export default function ForgotPassword(props) {
  // global
  var userDispatch = useUserDispatch();
  var query = useQuery();
  const history = useHistory();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [companyCode, setCompanyCode] = useState('');
  var [email, setEmail] = useState('');
  var [isSent, setisSent] = useState(false);

  if(isSent) {
    return (
      <Result
        status="success"
        title="Link untuk pergantian password telah dikirim ke alamat email yang dimasukkan"
        
        extra={[
          <Button type="primary" key="console" onClick={() => history.push('/login') }>
            Go To Login
          </Button>,
        ]}
      />

    )
  }

  return (
    <div className="login">
      <Space align="center" direction="vertical">
        <div style={{ margin: '40px 0' }}>
          <img src={hiraLogo} />
        </div>
        <Card style={{ width: '480px' }}>
          <Form
            layout="vertical"
            onFinish={(e) => {
              setIsLoading(true);
              authService
                .resetPassword(companyCode, email)
                .then((response: any) => {
                  const data = response.data;
                  if (response.status === 200 && !!data.content) {
                    toastService.success(data?.message);
                    setisSent(true);
                  }
                  setIsLoading(false);
                })
                .catch((resp) => {
                  setIsLoading(false);
                  toastService.error(resp?.message);
                });
            }}
          >
            <Form.Item name="company_code" label="Kode Perusahaan">
              <Input size="large" onChange={(e) => setCompanyCode(e.target.value)} />
            </Form.Item>
            <Form.Item name="email" label="Alamat Email" rules={[{ required: true }]}>
              <Input
                required
                placeholder='Masukkan alamat email Anda'
                size="large"
                autoCapitalize="off"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>

            <Form.Item name="submit">
              <Button type="primary" htmlType="submit" size="large" loading={isLoading} block>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Space>
    </div>
  );
}
