import { JobListObjById } from '../../entity/Job.entity';
import { LeaveSubmissionEmployeeInfo } from '../../entity/LeaveSubmissionEmployeeInfo.entity';
import { Employee } from '../../entity/Employee.entity';

export function employeeDetailToLeaveSubmissionDetail(
  employeeDetail: Employee,
  jobList: JobListObjById,
): LeaveSubmissionEmployeeInfo {
  const employeeJobInfo = jobList[employeeDetail.job_id];

  return {
    employee_name: employeeDetail.name_related,
    position: employeeJobInfo && employeeJobInfo.job_name ? employeeJobInfo.job_name : '-',
    department:
      employeeJobInfo && employeeJobInfo.dept_name ? employeeJobInfo.dept_name : '-',
    job_id: employeeDetail.job_id,
    department_id: employeeDetail.department_id,
    company_id: employeeDetail.company_id,
    employee_id: employeeDetail.id,
    nik: employeeDetail.nik,
  };
}
