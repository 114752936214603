import moment from "moment"
import { DetailEmployeeApprovalOvertime, EmployeeApprovalOvertimeResp, EmployeeSubmissionOvertimeResp, ListApprovalOvertimeType } from "./OvertimeMapperInterface"

export function ListSubmissionOvetimeMapper(
  overtimeList: EmployeeSubmissionOvertimeResp[]
) {
  const result = []

  overtimeList.forEach((ovt) => {
    const res = {
      key: ovt.id,
      number_submission_overtime: ovt.number_request,
      employee_nik: ovt.employee_nik,
      employee_name: ovt.employee_name,
      submission_date: ovt.date_request_string,
      start: ovt.lembur_start_string,
      end: ovt.lembur_end_string,
      status: ovt.lbl_state,
      create_name: ovt.create_name,
      create_date: ovt.view_create_date,
      write_name: ovt.write_name,
      write_date: ovt.view_write_date,
    }

    result.push(res)
  })

  return result
}

export function ListApprovalOvetimeMapper(
  overtimeList: EmployeeApprovalOvertimeResp[]
) {
  const result = []

  overtimeList.forEach((ovt) => {
    const res = {
      key: ovt.id,
      number_submission_overtime: ovt.number_request,
      employee_nik: ovt.nik,
      employee_name: ovt.emp_name,
      submission_date: FormatDateSubmission(ovt.date_request),
      start: FormatDateOvertime(ovt.date_start),
      end: FormatDateOvertime(ovt.date_end),
      overtime_reason: ovt.lembur_reason,
      overtime_duration: ovt.time_total,
      status: MappingActiveState(ovt.state),
      create_date: FormatDateOvertime(ovt.create_date),
      href: '/v2/app/hr-overtime/employee-approval-overtime/' + ovt.id
    }

    result.push(res)
  })

  return result
}

export function DetailApprovalOvertimeMapper(
  overtimeDetail: DetailEmployeeApprovalOvertime
) {
  const result = {
    employee: overtimeDetail.employee_name,
    date_request: FormatDateSubmission(overtimeDetail.date_request),
    start_time: FormatDateOvertime(overtimeDetail.lembur_start),
    end_time: FormatDateOvertime(overtimeDetail.lembur_end),
    duration: overtimeDetail.time_total,
    overtime_reason: overtimeDetail.lembur_reason
  }
  
  return {
    dataSource: result,
    status: overtimeDetail.create_date,
    approval1: MappingListApproval(overtimeDetail.list_app1),
    approval2: MappingListApproval(overtimeDetail.list_app2),
    historyId: overtimeDetail.history_id,
    submissionNumber: overtimeDetail.number_request,
    activeSteps: MappingActiveState(overtimeDetail.state),
  }
}

export function DetailSubmissionOvertimeMapper(
  overtimeDetail: EmployeeSubmissionOvertimeResp
) {
  const result = {
    employee: overtimeDetail.employee_name,
    date_request: FormatDateSubmission(overtimeDetail.date_request),
    start_time: FormatDateOvertime(overtimeDetail.lembur_start),
    end_time: FormatDateOvertime(overtimeDetail.lembur_end),
    duration: overtimeDetail.time_total,
    overtime_reason: overtimeDetail.lembur_reason,
    historyId: overtimeDetail.history_id
  }
  
  return {
    dataSource: result,
    status: overtimeDetail.is_active,
    approval1: AddKeyOnList(overtimeDetail.list_apporval_1),
    approval2: AddKeyOnList(overtimeDetail.list_apporval_2),
    historyId: overtimeDetail.history_id,
    submissionNumber: overtimeDetail.number_request,
    activeSteps: MappingActiveState(overtimeDetail.state),
  }
}

function AddKeyOnList(list: any[]) {
  if (!list?.length) {
    return null
  }

  return list.map((l, i) => {
    return { key: i, ...l }
  })
}

function MappingListApproval(list: ListApprovalOvertimeType[]) {
  const result = []
  list?.forEach((detail) => {
    result.push({
      employee_name: detail.employee_name,
      jabatan: detail.job
    })
  })
  return result
}

function MappingActiveState(state) {
  switch(state) {
    case 'submit':
      return 'Pengajuan'
    case 'wait-approve2':
      return "Persetujuan Ke-2"
    case 'reject':
      return 'Ditolak'
    case 'rejected':
      return 'Ditolak'
    case 'cancel':
      return "Batal"
    case 'done':
      return 'Selesai'
    default:
      return ''
  }
}

function FormatDateOvertime(dateString: string) {
  if (!dateString) {
    return ''
  }
  const date = moment(dateString)
  return date.format("DD/MM/YYYY HH:mm")
}

function FormatDateSubmission(dateString: string) {
  if (!dateString) {
    return ''
  }
  const date = moment(dateString)
  return date.format("DD/MM/YYYY")
}