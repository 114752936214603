import { Pagination, Table, Tabs } from "antd";

export default function LocationList({ content }) {
  function paginationOnChange(page) {
    if (content.paginationOnchange) {
      content.paginationOnchange(page)
    }
  }

  return (
    <Tabs defaultActiveKey="1" type="card">
      <Tabs.TabPane tab="Lokasi" key="1">
        {
          content?.total ? (
            <Pagination
              style={{ marginBottom: '20px' }}
              current={content.current}
              pageSize={content.pageSize}
              total={content.total}
              size={content.size}
              showSizeChanger={false}
              onChange={paginationOnChange}
            />
          ) : null
        }
        <Table
          columns={[
            {
              title: 'Lokasi',
              key: 'name',
              dataIndex: 'name',
              align: "center" as const,
              render: v => <div style={{ width: '100%', textAlign: 'start' }}>{v}</div>
            }
          ]}
          dataSource={content.location_list}
          pagination={false}
        />
      </Tabs.TabPane>
    </Tabs>
  )
}