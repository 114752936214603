import { useEffect, useState } from "react";
import AddMasterWorkingScheduleTemplate from "../template/AddMasterWorkingScheduleTemplate";
import AddMasterWorkingScheduleUseCase from "./AddMasterWorkingSchedule.usecase";
import { ToolbarDetailActionButton } from "../../../../component/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface";
import ToastService from "../../../../../../services/Toast.service";
import LocalNotificationService from "../../../../../../services/LocalNotification.service";
import { MapperSaveMasterWorkingSchedule } from "../../../../../../@core/mapper/attendance/SaveMasterWorkingSchedule.mapper";
import { DefaultDataSourceMasterWorking } from "./AddMasterWorkingSchedule.constant";

const usecase = new AddMasterWorkingScheduleUseCase()
const toastService = new ToastService()
const localNotificationService = new LocalNotificationService()

export default function AddMasterWorkingSchedule() {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    return () => {
      window.location.reload()
    }
  }, [])

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Master Jadwal Kerja',
        href: "/v2/app/attendance/master-working-schedule",
      },
      {
        clickable: false,
        displayPathName: 'Baru',
        href: "/v2/app/attendance/master-working-schedule/new/",
      }
    ],
  };

  const submitButton = { active: true, disabled: isLoading };
  const firstActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: isLoading,
    onClick: () => {
      const back = window.confirm("Anda Ingin Kembali")
      if (back) {
        window.location.href = "/v2/app/attendance/master-working-schedule"
      }
    },
  };

  const onFieldChange = (e) => {
    if (e.length === 1) {
      const fieldName = e[0].name[0];
      const value = e[0].value;

      switch (fieldName) {
        default:
          break;
      }
    }
  }

  const onFinish = (value) => {
    setIsLoading(true)
    const body = MapperSaveMasterWorkingSchedule(value)
    
    usecase.save(body).then((res) => {
      const data = res.data
      if (data.code === 200) {
        const message = data.message
        localNotificationService.openSuccess(message, '', 1)
      }
      setTimeout(() => {
        window.location.href = "/v2/app/attendance/master-working-schedule"
      }, 1500)
    }).catch(err => {
      setIsLoading(false)
      toastService.error(err)
    })
}

return (
  <div className="add-master-schedule">
    <AddMasterWorkingScheduleTemplate
      key={'add-master-schedule'}
      breadcrumbConf={breadcrumbConf}
      viewMode={false}
      isDetail={false}
      historyId={null}
      dataSource={DefaultDataSourceMasterWorking}
      submitButton={submitButton}
      firstActionButton={firstActionButton}
      secondActionButton={{ active: false }}
      thirdActionButton={{ active: false }}
      fourthActionButton={{ active: false }}
      dropdownActionButton={{ visible: false }}
      onFieldChange={onFieldChange}
      onFinish={onFinish}
      isLoading={isLoading}
    />
  </div>
)
}