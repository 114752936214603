import Constant from '../../../config/Constants';
import API from '../../../services/API.service';

export class EmployeeNewsUsecase {
  getEmployeeNews(params) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/dashboard/karyawan/news-section"
    return API.get(URL, {params})
  }
  getNewsDetail(Id) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/dashboard/karyawan/news-section/" + Id
    return API.get(URL, {})
  }
}