import { Button, Form } from "antd"
import Breadcrumb from "../../../../../../components/breadcrumb/Breadcrumb"
import BottomLoading from "../../../../../../components/bottom-loading/BottomLoading"
import { AddEmployeeWorkingScheduleTemplatePropsType } from "./AddEmployeeWorkingSchedule.interface";
import ToolbarButtonDetailTemplate from "../../../../component/toolbar-button-detail-template/ToolbarButtonDetailTemplate";
import FormBuilder from "antd-form-builder";
import { SearchByJobOrEmployee } from "./components/SearchByJobOrEmployee";
import { useEffect, useState } from "react";
import AddEmployeeWorkingScheduleUseCase from "../add-employee-working-schedule/AddEmployeeWorkingSchedule.usecase";
import { EditableTableEmployeeWorking } from "./components/EditableTableEmployeeWorking";
import { ScheduleWorkMapper } from "../../../../../../@core/mapper/attendance/GetEmployeeWorkingSchedule.mapper";
import { constant } from "./AddEmployeeWorkingScheduleTemplate.constant";

const usecase = new AddEmployeeWorkingScheduleUseCase()
let disableSave = true
let page = 1
let limit = 50
let total = 0
let job
let q

export default function AddEmployeeWorkingScheduleTemplate(props: AddEmployeeWorkingScheduleTemplatePropsType) {
  const [form] = Form.useForm()
  const [dataSource, setDataSource] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [disabledSaveButton, setDisabledSaveButton] = useState(false)
  
  let searchColumnKey = constant.SEARCH_COLUMN_KEY
  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: true };
  const tableHeader = constant.TABLE_HEADER

  useEffect(() => {
    const value = form.getFieldsValue()
    
    form.setFieldsValue({ 
      ...value,
      table_change: { 
        ...value.table_change,
        tableData,
        listSchedule: props.listSchedule,
        pagination: {
          ...value.table_change.pagination,
          total: total,
        }
      } 
    })
  }, [dataSource])

  useEffect(() => {
    if (disableSave !== disabledSaveButton) {
      setDisabledSaveButton(disableSave)
    }
  }, [disableSave])

  const onFinish = (values) => {
    if (props.onFinish) {
      props.onFinish(form.getFieldsValue().table_change);
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (props.onFinishFailed) {
      props.onFinishFailed(form.getFieldsValue());
    }
  };

  const onFieldsChange = (changedFields) => {
    if (props.onFieldChange) {
      props.onFieldChange(changedFields, form);
    }
  };

  function loadList() {
    setIsLoading(true)
    const params = {
      page,
      limit,
      job,
      q,
      with_meta: true,
    }

    usecase.search(params).then((res: any) => {
      const content = res.data.content;
      const metaData = res.data.metadata;

      const resultDataSource = content?.length ? ScheduleWorkMapper(content, page)
        : constant.DEFAULT_EMPTY_DATA_SOURCE

      form.resetFields()
      form.setFieldsValue({
        employee: q,
        job,
        name: {
          is_select_job: !!job?.length,
          selected_job: job,
          employee: q
        }
      })
      
      total = metaData.total_records
      setDataSource([...resultDataSource])
      setIsLoading(false)
    }, (err) => {
      setIsLoading(false)
    })
  }

  function searchListSchedule(key) {
    return props.listSchedule.find(e => e.key === key).label
  }

  const onApplyData = () => {
    const value = form.getFieldsValue()
    let applyData = {}

    dataSource.forEach(data => {
      const key = data.key
      const date_start_schedule_work = value.effective_date ?
        value.effective_date.format('YYYY-MM-DD') : 
        value.table_change.rowTableChange[key] ? 
        value.table_change.rowTableChange[key].date_start_schedule_work
        : null

      const date_end_schedule_work = value.end_date ?
        value.end_date.format('YYYY-MM-DD') : 
        value.table_change.rowTableChange[key] ? 
        value.table_change.rowTableChange[key].date_end_schedule_work
        : null
      
      const nama_jadwal_kerja = value.schedule_work ?
        searchListSchedule(value.schedule_work) : searchListSchedule(value.table_change.rowTableChange[key]) ? 
        searchListSchedule(value.table_change.rowTableChange[key].nama_jadwal_kerja) : null

      applyData = {
        ...applyData,
        [key]: {
          ...data,
          date_start_schedule_work,
          date_end_schedule_work,
          nama_jadwal_kerja,
        }
      }
    })

    if (value.effective_date && value.end_date && value.schedule_work) {
      disableSave = false
      const copyDataSource = JSON.parse(JSON.stringify(dataSource))
      setDataSource(copyDataSource)
    }
    
    form.setFieldsValue({
      ...value,
      table_change: {
        ...value.table_change,
        rowTableChange: applyData,
        editableRowKey: null,
        selectedRowKeys: {
          1: [],
        },
      }
    })

    setTimeout(() => {
      form.setFieldsValue({
        ...value,
        table_change: {
          ...value.table_change,
          rowTableChange: applyData,
        },
      });
    }, 100);
  }
  
  const meta = {
    columns: 4,
    colon: false,
    fields: [
      {
        key: 'name',
        label: '',
        required: true,
        colSpan: 3,
        widget: SearchByJobOrEmployee,
        message: 'Nama Jadwal Kerja Harus Diisi',
        widgetProps: {
          disabled: isLoading,
          listJob: props.listJob,
          onClick: (value) => {
            if (value.is_select_job) {
              job = value.selected_job
              q = null
            } else {
              q = value.employee
              job = null
            }
            loadList()
          }
        },
        initialValue: {
          is_select_job: true,
          selected_job: [],
          employee: "",
        },
        formItemLayout: {
          labelCol: { span: 3 },
          wrapperCol: { span: 14 }
        },
      },
      {
        key: 'separator',
        colSpan: 4,
        render() {
          return (
            <hr style={{ marginTop: '-15px' }} />
          );
        },
      },
      {
        key: 'effective_date',
        label: 'Tanggal Efektif',
        viewMode: false,
        colSpan: 1,
        widget: 'date-picker',
        formItemLayout: {
          labelCol: { span: 9.5 },
          wrapperCol: { span: 24 }
        },
      },
      {
        key: 'end_date',
        label: 'Tanggal Berakhir',
        viewMode: false,
        colSpan: 1,
        widget: 'date-picker',
        formItemLayout: {
          labelCol: { span: 12 },
          wrapperCol: { span: 24 }
        },
      },
      {
        key: 'schedule_work',
        label: 'Jadwal Kerja',
        viewMode: false,
        colSpan: 2,
        widget: 'select',
        align: 'left',
        options: props.listSchedule,
        formItemLayout: {
          labelCol: { span: 6 },
          wrapperCol: { span: 10 }
        },
      },
      {
        key: 'apply_data',
        viewMode: false,
        colSpan: 3,
        widget: () => (
          <Button 
            type="primary"
            disabled={isLoading} 
            onClick={() => onApplyData()} 
          >
            Terapkan
          </Button>
          ),
        formItemLayout: {
          labelCol: { span: 12 },
          wrapperCol: { span: 4 }
        },
      },
      {
        key: 'separator',
        colSpan: 4,
        render() {
          return (
            <hr style={{ marginTop: '-15px' }} />
          );
        },
      },
      {
        key: 'table_change',
        colSpan: 4,
        widget: EditableTableEmployeeWorking,
        widgetProps: {
          disabled: isLoading,
          columns: tableHeader,
        },
        initialValue: {
          selectedRowKeys: {
            1: []
          },
          pagination: {
            current: page,
            pageSize: limit,
            total: total,
            size: 'small',
          },
          rowTableChange: {},
          listSchedule: [],
          tableData,
        },
        onChange: (value) => {
          if (page !== value.pagination.current) {
            page = value.pagination.current
            loadList()
          }
          
          let selectedId = []
          for (const key in value.selectedRowKeys) {
            selectedId = [...selectedId, ...value.selectedRowKeys[key]]
          }

          if (!selectedId.length) {
            const isNotValid = []
            for (const key in value.rowTableChange) {
              if (!value.rowTableChange[key].date_start_schedule_work ||
                !value.rowTableChange[key].date_end_schedule_work ||
                !value.rowTableChange[key].nama_jadwal_kerja) {
                isNotValid.push(true)
              }
            }
            disableSave = isNotValid.includes(true)
          } else {
            const isNotValid = selectedId.map((id) => {
              if (value.rowTableChange[`${id}`]) {
                if (!value.rowTableChange[`${id}`].date_start_schedule_work ||
                  !value.rowTableChange[`${id}`].date_end_schedule_work ||
                  !value.rowTableChange[`${id}`].nama_jadwal_kerja) {
                  return true
                }
              } else {
                return true
              }
            })
            disableSave = isNotValid.includes(true)
          }

          const copyDataSource = JSON.parse(JSON.stringify(dataSource))
          setDataSource(copyDataSource)
        }
      }
    ]
  }
  return (
    <div id="add-employee-working-schedule" style={{ height: 'calc(100vh - 100px)' }}>
      <Breadcrumb breadcrumbConf={props.breadcrumbConf} />

      <Form
        name="basic"
        style={{ width: '100%' }}
        layout="horizontal"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={onFieldsChange}
      >
        <ToolbarButtonDetailTemplate
          submitButton={{ 
            ...props.submitButton, 
            disabled: props.isLoading || isLoading || disabledSaveButton
          }}
          firstActionButton={{ ...props.firstActionButton, disabled: props.isLoading || isLoading }}
          secondActionButton={props.secondActionButton}
          thirdActionButton={props.thirdActionButton}
          fourthActionButton={props.fourthActionButton}
          dropdownActionButton={props.dropdownActionButton}
        />

        <div className="scrollable-content">
          <div className="form-wrapper-schedule">
            <FormBuilder form={form} meta={meta} />
          </div>
        </div>
      </Form>

      <BottomLoading active={props.isLoading || isLoading} />
    </div>
  )
}