import Constant from "../../../../../config/Constants"
import API from "../../../../../services/API.service"
import { loanPaymentMapper } from '../../../../../@core/mapper/loan/GetLoanPaymentList.mapper'

export default class OvertimeReportFilterUseCaseImpl {

  getEmployeeList() {
    const param: any = {
      page: 1,
      limit: 500
    }
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/emp"
    return API.get(URL, param).then((res: any) => {
      const result = res.data.content.map(element => {
        return { label: element['alternative_name'], value: JSON.stringify(element), key: element['id'] }
      })
      return result;
    })
  }

  getLoanType() {
    const URL = Constant.URL_BASE_LOAN + "/v3/lov/loan"
    return API.get(URL).then((res: any) => {
      const result = res.data.content.map(element => {
        return { label: element['name'], value: JSON.stringify(element), key: element['id'] }
      })
      return result;
    })
  }

  getLoanListPayment(params) {
    const URL = Constant.URL_BASE_LOAN + "/v3/payment/loan"
    return API.get(URL, { params }).then((res: any) => 
    loanPaymentMapper(res.data)
    )
  }

  getLoanDetailPayment(params) {
    const URL = Constant.URL_BASE_LOAN + "/v3/payment/loan/" + params
    return API.get(URL).then((res: any) => 
    loanPaymentMapper(res.data)
    )
  }
  
  updateLoanPayment(body) {
    const URL = Constant.URL_BASE_LOAN + "/v3/payment/loan"
    return API.post(URL, body)
  }
}
