import Constant from "../../../../../config/Constants";
import API from "../../../../../services/API.service";
import { claimConfigMapper } from '../../../../../@core/mapper/claim/GetClaimConfigurationList.mapper'

export default class ClaimConfigurationListUsecase {

  getClaimConfigList(params) {
    const URL = Constant.URL_BASE_CLAIM + "/v3/configuration/claim"
    return API.get(URL, { params }).then((res: any) => 
    claimConfigMapper(res.data)
    )
  }

  generateClaimConfiguration(body) {
    const URL = Constant.URL_BASE_CLAIM + '/v3/configuration/claim/generate';
    return API.post(URL, body);
  }

  export(params) {
    const URL =  Constant.URL_BASE_CLAIM + "/v3/configuration/claim/export"
    return API.get(URL, { params })
  }

  deactiveClaimConfiguration(body) {
    const URL = Constant.URL_BASE_CLAIM + '/v3/configuration/claim/active';
    return API.put(URL, body);
  }
}