import { useEffect, useState } from 'react';
import { Table, Col, Row, Tabs } from 'antd';
import UploadImage from '../../../../../../../components/upload-image/UploadImage';
import { ApprovalAndArchiveTabsPropsType } from './ApprovalAndArchiveTabs.interface';
import './ApprovalAndArchiveTabs.less';

const { TabPane } = Tabs;

export default function ApprovalAndArchiveTabs(props: ApprovalAndArchiveTabsPropsType) {
  const columns = [
    { title: 'Nama', dataIndex: 'name' },
    { title: 'Jabatan', dataIndex: 'position' },
  ];

  let [firstApprovalData, setFirstApprovalData] = useState();
  let [secondApprovalData, setSecondApprovalData] = useState();

  useEffect(() => {
    processFirstApprovalData();
  }, [props.firstApprovalData]);
  useEffect(() => {
    processSecondApprovalData();
  }, [props.secondApprovalData]);

  const processFirstApprovalData = () => {
    let result: any = [{ name: 'Example Name', position: 'Chief Technology Officer' }];

    if (props.firstApprovalData) {
      result = props.firstApprovalData ? props.firstApprovalData : result;
    }

    setFirstApprovalData(result);
  };

  const processSecondApprovalData = () => {
    let result: any = [{ name: 'Example Name', position: 'Chief Technology Officer' }];

    if (props.secondApprovalData) {
      result = props.secondApprovalData ? props.secondApprovalData : result;
    }

    setSecondApprovalData(result);
  };

  const ApprovalTab = ({ dataSource }) => {
    return <Table columns={columns} dataSource={dataSource} pagination={false} />;
  };

  const onImageSelected = (selectedImg, componentId) => {
    if (props.onChangeArchiveImg) {
      props.onChangeArchiveImg(selectedImg, componentId);
    }
  };

  return (
    <div className="tabs-wrapper">
      <Tabs defaultActiveKey="1" type="card">
        <TabPane tab="Persetujuan" key="1">
          <Row>
            <Col span={4}>
              <label className="label-table">Persetujuan Satu</label>
            </Col>
            <Col span={7}>
              <ApprovalTab dataSource={firstApprovalData} />
            </Col>
            <Col span={4}>
              <label className="label-table">Persetujuan Dua</label>
            </Col>
            <Col span={7}>
              <ApprovalTab dataSource={secondApprovalData} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Lampiran" key="2">
          <Row>
            <Col span={3}>
              <label className="label-table">Lampiran 1</label>
            </Col>
            <Col span={3}>
              <UploadImage
                onChange={onImageSelected}
                componentId={'archive_img_1'}
                selectedImg={props.firstArchiveImg.sourceImg}
                canEdit={props.firstArchiveImg.canEdit}
              />
            </Col>

            <Col span={3}>
              <label className="label-table">Lampiran 2</label>
            </Col>
            <Col span={3}>
              <UploadImage
                onChange={onImageSelected}
                componentId={'archive_img_2'}
                selectedImg={props.secondArchiveImg.sourceImg}
                canEdit={props.secondArchiveImg.canEdit}
              />
            </Col>

            <Col span={3}>
              <label className="label-table">Lampiran 3</label>
            </Col>
            <Col span={3}>
              <UploadImage
                onChange={onImageSelected}
                componentId={'archive_img_3'}
                selectedImg={props.thirdArchiveImg.sourceImg}
                canEdit={props.thirdArchiveImg.canEdit}
              />
            </Col>

            <Col span={3}>
              <label className="label-table">Lampiran 4</label>
            </Col>
            <Col span={3}>
              <UploadImage
                onChange={onImageSelected}
                componentId={'archive_img_4'}
                selectedImg={props.fourthArchiveImg.sourceImg}
                canEdit={props.fourthArchiveImg.canEdit}
              />
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </div>
  );
}
