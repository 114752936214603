import Constant from '../../../../../config/Constants';
import API from '../../../../../services/API.service';

export default class HrAddMasterEmployeeUsecase {
  create(body) {
    const URL = Constant.URL_BASE_COMPANY + `/v3/employee/master-employee`;
    return API.post(URL, body);
  }
  getCountry() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/country"
    return API.get(URL).then((res: any) => {
      const result = res.data.content.map(element => {
        return { label: element['name'], value: JSON.stringify(element), key: element['id'] }
      })
      return result;
    })
  }

  getReligion() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/religion"
    return API.get(URL).then((res: any) => {
      const result = res.data.content.map(element => {
        return { label: element['name'], value: JSON.stringify(element), key: element['id'] }
      })
      return result;
    })
  }

  getEdu() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/education"
    return API.get(URL).then((res: any) => {
      const result = res.data.content.map(element => {
        return { label: element['name'], value: JSON.stringify(element), key: element['id'] }
      })
      return result;
    })
  }

  getJob(params) {
    const URL = Constant.URL_BASE_COMPANY + '/v3/lov/job';
    return API.get(URL, {params}).then((res: any) => {
      const result = res?.data?.content.map((element) => {
        return { label: element['job_name'], value: JSON.stringify(element), key: element['id'] };
      });
      return result;
    });
  }

  getEmpl(id) {
    const params:any = {
      job_id: id
    }
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/emp"
    return API.get(URL, { params })
  }

  getDepartment() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/dept"
    return API.get(URL).then((res: any) => {
      const result = res.data.content.map(element => {
        return { label: element['dept_name'], value: JSON.stringify(element), key: element['id'] }
      })
      return result;
    })
  }
  getPtkp() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/ptkp-cat"
    return API.get(URL).then((res: any) => {
      const result = res.data.content.map(element => {
        return { label: element['name'], value: JSON.stringify(element), key: element['id'] }
      })
      return result;
    })
  }

  getRegion() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/region"
    return API.get(URL).then(res => {
      return res.data.content.map((opt) => {
        return { key: opt.id, label: opt.region_name, value: opt.id }
      })
    })
  }
  getLocation(params) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/location"
    return API.get(URL, {params}).then(res => {
      return res.data.content.map((opt) => {
        return { key: opt.id, label: opt.name, value: opt.id }
      })
    })
  }

  getProvince(params) {
    const URL = Constant.URL_BASE_COMPANY + '/v3/lov/country-state'
    return API.get(URL, { params })
  }

  getCities(params) {
    const URL = Constant.URL_BASE_COMPANY + '/v3/lov/kab-kota'
    return API.get(URL, { params })
  }

  getDistrict(params) {
    const URL = Constant.URL_BASE_COMPANY + '/v3/lov/kecamatan'
    return API.get(URL, { params })
  }

  getSubdistrict(params) {
    const URL = Constant.URL_BASE_COMPANY + '/v3/lov/desa'
    return API.get(URL, { params })
  }

}
