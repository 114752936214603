import { Select } from "antd";

export const SelectEmployeeName = (props) => {
  return (
    <Select
      showSearch={true}
      defaultValue={[]}
      value={props.value}
      mode="multiple"
      onChange={props.onChange}
      options={props?.employee_list}
      filterOption={(input:any, option:any) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 
          && !props.selected_employee.find(e => e.id === option.value)
      }
    />
  )
}