import axios from 'axios';
import Constant from '../config/Constants';
class AuthService {
  login(companyCode, login, password) {
    const URL = Constant.URL_BASE_USER + '/auth';
    const params = {
      companyCode: companyCode,
      login: login,
      password: password,
    };
    return new Promise((resolve, reject) => {
      axios
        .post(URL, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }
  resetPassword(companyCode, email) {
    const URL = Constant.URL_BASE_USER + '/auth/reset-password';
    const params = {
      companyCode: companyCode,
      email: email,
    };
    return new Promise((resolve, reject) => {
      axios
        .put(URL, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  }
  forceUpdatePassword(new_password, id) {
    const URL = Constant.URL_BASE_USER + '/auth/force-update-password/'+ id;
    const params = {
      new_password: new_password,
    };
    return new Promise((resolve, reject) => {
      axios
        .put(URL, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
          }
          reject(error.response.data);
        });
    });
  }
  changePassword(password, new_password, id) {
    const URL = Constant.URL_BASE_USER + '/auth/change-password/'+ id;
    const params = {
      password: password,
      new_password: new_password,
    };
    return new Promise((resolve, reject) => {
      axios
        .put(URL, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  }
}

export default AuthService;
