import { Button, Form } from "antd"
import Breadcrumb from "../../../../../components/breadcrumb/Breadcrumb"
import BottomLoading from "../../../../../components/bottom-loading/BottomLoading"
import FormBuilder from "antd-form-builder";
import { AddEmployeeDepartmentTemplatePropsType } from "./AddMasterDepartmentTemplate.interface"
import ChildDepartmentList from "./component/ChildDepartmentList";
import ToolbarButtonDetailTemplate from "../../../component/toolbar-button-detail-template/ToolbarButtonDetailTemplate";

export default function AddEmployeeDepartmentTemplate(props: AddEmployeeDepartmentTemplatePropsType) {
  const [form] = Form.useForm()
  
  const meta = {
    columns: 4,
    colon: false,
    fields: [
      {
        key: 'name',
        label: 'Nama Departemen',
        colSpan: 4,
        viewMode: props.viewMode,
        initialValue: props.detailDataSource?.name || "",
        rules: [
          {
            required: true,
            whitespace: true,
            message: 'Nama Departemen harus diisi',
          },
        ],
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 }
        },
      },
      {
        key: 'parent_id',
        label: 'Departemen Induk',
        viewMode: props.viewMode,
        colSpan: 4,
        align: 'left',
        widget: 'select',
        options: props.listDepartment,
        initialValue: parentDepartmentValue(),
        widgetProps: {
          showSearch: true,
          filterOption: (input:any, option:any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 }
        },
      },
      {
        key: 'separator',
        label: '',
        viewMode: true,
        colSpan: 4,
        initialValue: 'Keterangan: Jika kolom Departemen Induk tidak diisi, maka akan menjadi departemen induk.',
      }
    ]
  }

  function parentDepartmentValue() {
    return props.viewMode ? 
      props.detailDataSource?.parent_name || ""
      : props.detailDataSource?.parent_id
  }

  const onFinish = (values) => {
    if (props.onFinish) {
      props.onFinish(form.getFieldsValue());
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (props.onFinishFailed) {
      props.onFinishFailed(form.getFieldsValue());
    }
  };

  const onFieldsChange = (changedFields) => {
    if (props.onFieldChange) {
      props.onFieldChange(changedFields, form);
    }
  };

  return (
    <div id="add-employee-department-template" style={{ height: 'calc(100vh - 100px)' }}>
      <Breadcrumb breadcrumbConf={props.breadcrumbConf} />

      <Form
        name="basic"
        style={{ width: '100%' }}
        layout="horizontal"
        labelAlign="left"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={onFieldsChange}
      >
        <ToolbarButtonDetailTemplate
          submitButton={{
            ...props.submitButton,
            disabled: props.isLoading
          }}
          firstActionButton={{ ...props.firstActionButton, disabled: props.isLoading }}
          secondActionButton={props.secondActionButton}
          thirdActionButton={props.thirdActionButton}
          fourthActionButton={props.fourthActionButton}
          dropdownActionButton={props.dropdownActionButton}
        />

        <div className="scrollable-content">
          <div className="form-wrapper-schedule">
            <FormBuilder form={form} meta={meta} />
            <ChildDepartmentList content={props.listTableDataDetail} />
          </div>
        </div>
      </Form>

      <BottomLoading active={props.isLoading} />
    </div>
  )
}