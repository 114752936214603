import moment from "moment";

export function claimConfigMapper(response) {
    const content = response.content ? response.content : null;
    let contentMap = null;
  
    if (content) {
      contentMap = content.map((data) => {
        const item = {
          id: data.id,
          key: data.id,
          name: data.name,
          plafon_type: data.plafon_type,
          plafon_value: data.plafon_value ? `Rp${data.plafon_value.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")}` : '-',
          plafon_reset: data.plafon_reset,
          active_when: data.active_when,
          config_type: data.config_type,
          is_active: !!data.is_active ? 'Aktif' : 'Tidak Aktif',
          approval_1: data.approval_1 || '-',
          approval_2: data.approval_2 || '-',
          create_name: data.create_name,
          create_date: moment(data.view_create_date).format('DD-MM-YYYY HH:mm'),
          write_name: data.write_name,
          write_date: moment(data.view_write_date).format('DD-MM-YYYY HH:mm'),
          action: 'Proses Ulang'
        };
        return item;
      });
    }
  
    return {
      content: contentMap,
      metaData: response.metadata,
    };
  }
  