import { Card, Form } from 'antd';
import { SimpleCard } from './DashboardCard.interface';
import moment from 'moment';
import MonthPickerField from './MonthPickerField';
import { useForm } from 'antd/lib/form/Form';

export default function ReadOnlyCard({ title, total, onChange }: SimpleCard) {
  const [form] = useForm();

  const ExtraComponent = () => {
    return onChange ? (
      <Form form={form} onValuesChange={(e) => onChange(title, form.getFieldValue(title))}>
        <MonthPickerField key={title} name={title} />
      </Form>
    ) : (
      <h1 style={{ fontSize: '18px', fontWeight: 700, lineHeight: '48px' }}>{moment().format('MMM YYYY')}</h1>
    );
  };

  return (
    <Card title={title} extra={<ExtraComponent />} style={{ minWidth: '100%', minHeight: '100%' }}>
      <h1 style={{ fontSize: '48px', fontWeight: 700 }}>{total}</h1>
    </Card>
  );
}
