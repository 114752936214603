import { useEffect, useState } from 'react';
import LandingListTemplate from '../../../../../../templates/landing-list-template/LandingListTemplate';
import { useHistory, useLocation } from 'react-router-dom';
import { OvertimeConstant } from '../../../../../../config/Overtime.constant';
import { LandingListTemplatePagination } from '../../../../../../templates/landing-list-template/LandingListTemplate.interface';
import HrEmployeeOvertimeReportUseCaseImpl from './HrEmployeeOvertimeReport.usecase.impl';
import LocalNotificationService from '../../../../../../services/LocalNotification.service';

const useCaseImpl = new HrEmployeeOvertimeReportUseCaseImpl();
const localNotificationService = new LocalNotificationService();

let page = 1;
let loadPagination = true;
let sort = 'write_date|desc';
let start_date_ovt = '';
let end_date_ovt = '';
let state = [];
let employee_id = [];
let job_id = [];
export default function HrEmployeeOvertimeReport() {
  const history = useHistory();
  const location:any = useLocation();
  
  const tableHeader: any = OvertimeConstant.TABLE_HEADER;
  const searchColumnKey = OvertimeConstant.SEARCH_COLUMN_KEY;
  const searchColumnComponent = OvertimeConstant.SEARCH_COLUMN_COMPONENT;
  const limit = 10;

  let [pagination, setPagination] = useState<LandingListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });
  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([]);
  let [tableScroll, setTableScroll] = useState({ y: 200 });
  let [disableDeleteButton, setDisableDeleteButton] = useState(true);
  let [visibleWarningModal, setVisibleWarningModal] = useState(false);
  let [disableExportButton, setDisableExportButton] = useState(false);

  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: false, searchColumnComponent };

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {
    
    loadList();
    const clientHeight = document.getElementById('employee-attendance-page').clientHeight;

    setTableScroll({ y: clientHeight - 220 });
  }

  function loadList() {
    setIsLoading(true);
    const tempFilter:any = location.state?.data;
    
    if(tempFilter) {
      state = tempFilter?.status;
      employee_id = tempFilter?.list_employee;
      job_id = tempFilter?.list_job;
      start_date_ovt = tempFilter?.start;
      end_date_ovt = tempFilter?.end;
    }
    const params = {
      page,
      limit,
      sort,
      start_date_ovt,
      end_date_ovt,
      state,
      employee_id,
      job_id,
      with_meta: true,
    };
    useCaseImpl
      .getOvertimeReport(params)
      .then(
        (res: any) => {
          setIsLoading(false);
          const content = res.content;
          const metaData = res.metaData;
          const resultDataSource = content ? content : OvertimeConstant.DEFAULT_EMPTY_DATA_SOURCE;
          setDataSource([...resultDataSource]);
          updatePaginationState(metaData.total_records);

          loadPagination = true;
          setDisableExportButton(content ? false : true);
        },
        (err) => {
          setIsLoading(false);
          setDataSource([...OvertimeConstant.DEFAULT_EMPTY_DATA_SOURCE]);
          loadPagination = true;
          setDisableExportButton(true);
        }
      )
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    processPagination(outputPagination);
  };

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
  }
  function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };

  const exportButton = {
    onClick: () => {
      setIsLoading(true);
      const params = {
        sort,
        start_date_ovt,
        end_date_ovt,
        state,
        employee_id,
        job_id,
        with_meta: true,
      };
      useCaseImpl.exportEmployeeOvtReport(params).then(
        (res: any) => {
          const data = res.data;
          if (data.status === 200) {
            const file = base64ToArrayBuffer(data.content.data);
            saveByteArray(data.content.filename, file);
          }

          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
        },
      );
    },
    disabled: disableExportButton || isLoading,
  };
  

  return (
    <div id="employee-attendance-page" style={{ height: 'calc(100vh - 100px)' }}>
      <LandingListTemplate
        addButton={{visible: false}}
        deleteButton={{visible: false}}
        exportButton={exportButton}
        tableHeader={tableHeader}
        tableData={tableData}
        tablePagination={pagination}
        tableOnChange={tableOnChange}
        isLoading={isLoading}
        tableScroll={tableScroll}
      />
    </div>
  );
}
