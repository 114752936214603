import Moment from 'moment';

export function leaveConfigurationMapper(response, hrefPath: string) {
  const content = response.content ? response.content : null;
  let contentMap = null;

  if (content) {
    contentMap = content.map((data) => {
      const item = {
        id: data.id,
        key: data.id,
        name: data.name,
        config_type: data.config_type,
        plafon_type: data.plafon_type,
        date_plafon_reset: data.date_plafon_reset,
        active_when: data.active_when,
        approval_2: data.approval_2 || '-',
        approval_1: data.approval_1 || '-',
        is_active: data.is_active,
        company_id: data.company_id,
        create_date: Moment(data.view_create_date).format('DD/MM/YYYY HH:mm'),
        create_uid: data.create_uid,
        create_name: data.create_name,
        write_date: Moment(data.view_write_date).format('DD/MM/YYYY HH:mm'),
        write_uid: data.write_uid,
        write_name: data.write_name,
        href: `${hrefPath}${data.id}`,
        action: 'Proses Ulang'
      };
      return item;
    });
  }

  return {
    content: contentMap,
    metaData: response.metadata,
  };
}
