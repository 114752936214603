export function getCheckinStatus(status) {
    const collection = {
      TA: 'Tidak Absen',
      TT: 'Terlambat',
      TW: 'Tepat Waktu',
      MKDJ: 'Masuk Kerja Diluar Jadwal'
    };
  
    return collection[status] || '-';
  }
  
  export function getCheckoutStatus(status) {
    const collection = {
      MK: 'Masuk Kerja',
      PC: 'Pulang Cepat',
      TA: 'Tidak Absen',
      MKDJ: 'Masuk Kerja Diluar Jadwal'
    };
  
    return collection[status] || '-';
  }
  
  export function getAbsentStatus(status) {
    const collection = {
      TM: 'Tidak Masuk Kerja',
      MK: 'Masuk Kerja',
      MKDJ: 'Masuk Kerja Diluar Jadwal'
    };
  
    return collection[status] || '-';
  }

  export function getRadiusStatus(status) {
    const collection = {
      outside: '<span style="color: red">Di Luar</span><br/>',
      inside: '<span style="color: #1bc604">Di Dalam</span><br/>',
    };
    return collection[status] || '-';
  }
  