import { GetEmployeeListDTO } from "../../../../../@core/interface/employee/GetEmployeeList.interface";
import Constant from "../../../../../config/Constants";
import API from "../../../../../services/API.service";

export default class AddEmployeeSubmissionClaimUsecase {
  create(body) {
    const URL = Constant.URL_BASE_CLAIM + "/v3/request/claim/create";
    return API.post(URL, body)
  }

  getEmployeeList(params: GetEmployeeListDTO) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/emp";

    return API.get(URL, { params }).then((res: any) => {
      const result = res.data.content.map((employee) => {
        return {
          label: employee.alternative_name,
          value: employee.id,
          nik: employee.nik,
          job_id: employee.job_id,
          job_name: employee.job_name,
          department_id: employee.dept_id,
        };
      });

      return result
    });
  }

  
  getConfigClaim(employee_id) {
    const URL = Constant.URL_BASE_CLAIM + '/v3/lov/list-config';
    return API.get(URL, { params: { employee_id } }).then((res) => {
      const content = res.data.content;
      const result = content?.map((config) => {
        return {
          key: config.id,
          value: config.id,
          label: config.name,
          plafon: config.plafon_value,
          limit: config.plafon_value,
        };
      });
      return result
    });
  }
  
  getPlafonClaim(params) {
    const URL = Constant.URL_BASE_CLAIM + '/v3/request/claim/check';
    return API.get(URL, { params });
  }
}