import Constant from '../../../../../../config/Constants';
import API from '../../../../../../services/API.service';
import { TemplateHeader } from './ImportEmployeeWorkingSchedule.constant';
import {
  validateNik,
  validateStartDate,
  validateEndDate,
  validateName,
} from './ImportEmployeeWorkingSchedule.service';

const templateHeader = TemplateHeader;
const moment = require('moment');

export default class ImportEmployeeWorkingScheduleUseCase {
  doValidate(selectedFile, masterSchedule) {
    return new Promise((resolve, reject) => {
      const excelArrays = selectedFile.content.excel_arrays;
      const extension = selectedFile.content.file_name.split('.').pop();
      const requiredExtension = ['xlsx', 'xls'];
      const result = {
        status: 'error',
        content: {
          dataSource: [],
          errorMessage: '',
        },
      };

      if (requiredExtension.indexOf(extension) !== -1) {
        if (excelArrays.length === 0 || excelArrays.length === 1 || excelArrays.length > 150) {
          result.status = 'error';
          result.content.errorMessage =
            excelArrays.length === 0 || excelArrays.length === 1
              ? 'File tidak memiliki data'
              : 'File yang di impor melebihi 140 kolom';

          reject(result);
        } else {
          if (JSON.stringify(excelArrays[0]) !== JSON.stringify(templateHeader)) {
            result.status = 'error';
            result.content.errorMessage =
              'File yang di pilih tidak sesuai dengan template yang telah di sediakan';

            reject(result);
          } else {
            excelArrays.forEach((column, index) => {
              const startDate = new Date(column[1]);
              const endDate = new Date(column[2]);

              if (index > 0 && column.length) {
                const rowTable: any = {
                  status: true,
                  nik: column[0] ? column[0] : '',
                  start_date: column[1] ? moment(column[1]).format('DD/MM/YYYY') : '',
                  end_date: column[2] ? moment(column[2]).format('DD/MM/YYYY') : '',
                  name: column[3] ? column[3] : '',

                  error_list: {
                    nik: validateNik(column[0], 'NIK'),
                    start_date: validateStartDate(column[1], 'Mulai Tanggal'),
                    end_date: validateEndDate(column[1], column[2], 'Sampai Tanggal'),
                    name: validateName(column[3], masterSchedule, 'Nama Jadwal Kerja'),
                  },
                };

                for (const key in rowTable.error_list) {
                  if (rowTable.error_list[key].status === false) {
                    rowTable.status = false;
                    break;
                  }
                }

                result.content.dataSource.push(rowTable);
              }

              if (index === excelArrays.length - 1) {
                result.status = 'success';
                resolve(result);
              }
            });
          }
        }
      } else {
        result.content.errorMessage =
          'Jenis File yang di upload tidak sesuai (jenis file harus xls, atau xlsx)';
        reject(result);
      }
    });
  }

  getMasterSchedule() {
    return new Promise((resolve, reject) => {
      const URL = Constant.URL_BASE_ATTENDANCE + '/v3/lov/master-jadwal';
      const params = { limit: 500 };

      API.get(URL, { params }).then((res: any) => {
        const data = res.data;

        if (data.status === 200) {
          const result = {};
          data.content.forEach((el: any) => {
            result[el.name] = el;
          });

          resolve(result);
        } else {
          reject(data);
        }
      });
    });
  }

  validateToServer(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + '/v3/configuration/jadwal-kerja-karyawan/validate';
    return API.post(URL, body);
  }

  downloadTemplate() {
    const URL = Constant.URL_BASE_ATTENDANCE + '/v3/configuration/jadwal-kerja-karyawan/template';
    return API.get(URL);
  }

  saveSchedule(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + '/v3/configuration/jadwal-kerja-karyawan';
    return API.post(URL, body);
  }
}
