export const ListLeaveUsageReportConstant = {
  TABLE_HEADER: [
    {
      title: 'NIK',
      key: 'nik',
      dataIndex: 'nik',
      width: 200
    },
    {
      title: 'Nama Karyawan',
      key: 'employee_name',
      dataIndex: 'employee_name',
      width: 210
    },
    {
      title: 'Jabatan',
      key: 'job',
      dataIndex: 'job',
      width: 220
    },
    {
      title: 'Konfigurasi Cuti',
      key: 'leave_config',
      dataIndex: 'leave_config',
      width: 280
    },
    {
      title: 'Tanggal Kadaluarsa',
      key: 'expired_date',
      dataIndex: 'expired_date',
      width: 200,
    },
    {
      title: 'Plafon Cuti',
      key: 'leave_plafon',
      dataIndex: 'leave_plafon',
      width: 150
    },
    { title: 'Penggunaan Cuti', key: 'usage_leave', dataIndex: 'usage_leave', width: 180 },
    { title: 'Cuti Belum Final', key: 'leave_not_final', dataIndex: 'leave_not_final', isCTA: true, width: 150 },
    { title: 'Plafon Yang Tersedia', key: 'remaining_day_off', dataIndex: 'remaining_day_off', width: 180 },
  ],
  SEARCH_COLUMN_KEY: [],
  DEFAULT_EMPTY_DATA_SOURCE: [
    {
      holiday_name: '1',
      madeBy: '',
      madeOn: '',
      changeBy: '',
      changeOn: '',
    },
  ],
}