import { Col, Row, Table } from "antd";
import { IDRFormat } from "../../../../../../@core/interaction/currency/IDRFormat";

export default function ApprovalTableTabs({ dataSource, form }) {
  return (
    <Row justify="center">
      <Col span={20}>
        <Table
          bordered={true}
          pagination={false}
          dataSource={dataSource}
          columns={[
            { key: 'no', title: 'No', dataIndex: 'no', width: 80, align: "center" as const },
            { key: 'submission_number', title: 'No Pengajuan', dataIndex: 'submission_number', width: 100, render: t => <a>{t}</a>, align: "center" as const },
            { key: 'loan_type', title: 'Tipe Pinjaman', dataIndex: 'loan_type', width: 180, align: "center" as const },
            {
              key: 'loan_total',
              title: 'Total Pinjaman',
              dataIndex: 'loan_total',
              width: 180,
              render: total => IDRFormat(total),
              align: "center" as const 
            },
            {
              key: 'interest',
              title: 'Bunga',
              dataIndex: 'interest',
              width: 160,
              render: total => IDRFormat(total),
              align: "center" as const 
            },
            {
              key: 'tenor',
              title: 'Tenor (Bulan)',
              dataIndex: 'tenor',
              width: 120,
              align: "center" as const 
            },
            {
              key: 'total_payment',
              title: 'Total Dibayar',
              dataIndex: 'total_payment',
              width: 180,
              render: total => IDRFormat(total),
              align: "center" as const 
            },
          ]}
          summary={(pageData) => {
            let total_pinjaman = 0;
            let total_bunga = 0;
            let total_bayar = 0;

            pageData.forEach(({ loan_total, interest, total_payment }) => {
              total_pinjaman += loan_total || 0;
              total_bunga += interest || 0;
              total_bayar += total_payment || 0;
            });

            return (
              <>
                <Table.Summary.Row style={{ fontWeight: 'bold' }}>
                  <Table.Summary.Cell align="center" index={1}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell align="center" index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell align="center" index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell align="center" index={4}>{IDRFormat(total_pinjaman)}</Table.Summary.Cell>
                  <Table.Summary.Cell align="center" index={5}>{IDRFormat(total_bunga)}</Table.Summary.Cell>
                  <Table.Summary.Cell align="center" index={6}></Table.Summary.Cell>
                  <Table.Summary.Cell align="center" index={7}>{IDRFormat(total_bayar)}</Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </Col>
    </Row>
  );
}
