export const MasterLocationListConstant = {
  TABLE_HEADER: [
    {
      title: 'Lokasi',
      key: 'name',
      dataIndex: 'name',
      width: 240,
      editable: false,
    },
    {
      title: 'Region',
      key: 'region_name',
      dataIndex: 'region_name',
      width: 180,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      editable: true,
      width: 180,
    },
    {
      title: 'Sebagai Lokasi Absensi',
      key: 'attendance_location',
      dataIndex: 'attendance_location',
      width: 180,
    },
    { title: 'Dibuat Oleh', key: 'create_name', dataIndex: 'create_name', editable: false, width: 200 },
    { title: 'Dibuat Pada', key: 'create_date', dataIndex: 'create_date', editable: false, width: 160, isFormatDate: true, sorter: true },
    { title: 'Diubah Oleh', key: 'write_name', dataIndex: 'write_name', editable: false, width: 200 },
    { title: 'Diubah Pada', key: 'write_date', dataIndex: 'write_date', editable: false, width: 160, isFormatDate: true, sorter: true },
  ],
  SEARCH_COLUMN_KEY: [
    { key: 'key', componentType: "search-input" },
    { key: 'name', componentType: "text" },
    { key: 'region_name', componentType: "text" },
  ],
  DEFAULT_EMPTY_DATA_SOURCE: [
    {
      key: 'search-input',
      name: '',
      region_name: '',
      create_name: '',
      create_date: '',
      write_name: '',
      write_date: '',
    },
  ],
}