import { useEffect, useReducer } from "react";
import { AddMassLeaveUseCase } from "./AddMassLeave.usecase";
import { ToolbarDetailActionButton } from "../../../component/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface";
import AddMassLeaveTemplate from "../../templates/mass-leave-detail-template/MassLeaveDetailTemplate";
import ToastService from "../../../../../services/Toast.service";
import LocalNotificationService from "../../../../../services/LocalNotification.service";
import { SaveMassLeaveMapper, listConfigLeaveMapper } from "../../../../../@core/mapper/leave/MassLeave.mapper";

const useCase = new AddMassLeaveUseCase()
const toastService = new ToastService()
const localNotificationService = new LocalNotificationService();

export default function AddMassLeave() {
  const [viewState, viewDispatch] = useReducer(
    (prev, next) => {
      return { ...prev, ...next }
  }, { isLoading: false, employeeList: [], configList: [] })

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Cuti Bersama',
        href: "/v2/app/hr-leave/all-request",
      },
      {
        clickable: false,
        displayPathName: 'Baru',
        href: "/v2/app/hr-leave/all-request/new/",
      }
    ],
  };

  useEffect(() => {
    init()
  }, [])

  const submitButton = { active: true, disabled: viewState.disabledSaveButton || viewState.isLoading };
  const firstActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: viewState.isLoading,
    onClick: () => {
      const back = window.confirm("Anda Ingin Kembali")
      if (back) {
        window.location.href = "/v2/app/hr-leave/all-request"
      }
    },
  };

  async function init() {
    viewDispatch({ isLoading: true })

    useCase.getLeaveConfig().then(
      (res: any) => {
      viewDispatch({
          configList: listConfigLeaveMapper(res.data.content) 
        })
    });

    useCase.getEmp().then(
      (res) => {
        const data = res.data
        if (data.status === 200) {
          const result = data.content.map((emp) => {
            return {
              key: emp.id,
              value: emp.id,
              label: emp.alternative_name,
            }
          })
          viewDispatch({
            isLoading: false,
            employeeList: [...result]
          })
        }
      }
    ).catch(err => {
      viewDispatch({ isLoading: false })
      })
  }

  function getEmployeeByConfig(id) {
    viewDispatch({ isLoading: true })
    useCase.getLeaveConfigDetail(id).then(
      (res) => {
        const content = res.data.content
        const result = content.map((emp) => {
          const name = `${emp.nik} - ${emp.name}`
          return {
            key: emp.employee_id,
            value: emp.employee_id,
            label: emp.alternative_name || name,
          }
        })

        if (!result.length) {
          toastService.error("Tidak ada karyawan yang sesuai dengan config cuti yang dipilih");
        }

        viewDispatch({
          isLoading: false,
          reduce_leave_rem: true,
          disabledSaveButton: !result.length,
          employeeList: [...result]
        })
      }
    ).catch(e => viewDispatch({ isLoading: false }))
  }

  const onFieldChange = (e, form) => {
    const fieldName = Object.keys(e)[0];
    const value = Object.values(e)[0];

    switch (fieldName) {
      default:
        break;
      case 'leave_config_id':
        getEmployeeByConfig(value);
        break;
      case 'reduce_leave_rem':
        form.setFieldsValue({ leave_config_id: [], employee_id: [] });
        useCase
          .getEmp()
          .then((res) => {
            const data = res.data;
            if (data.status === 200) {
              const result = data.content.map((emp) => {
                return {
                  key: emp.id,
                  value: emp.id,
                  label: emp.alternative_name,
                };
              });
              viewDispatch({
                isLoading: false,
                reduce_leave_rem: value,
                disabledSaveButton: !result.length,
                employeeList: [...result],
              });
            }
          })
          .catch((err) => {
            viewDispatch({ isLoading: false });
          });
        break;
    }
  };

  const onFinish = (value) => {
    viewDispatch({ isLoading: true })
    const body = SaveMassLeaveMapper(value, viewState.employeeList)

    useCase.save(body).then(
      (res) => {
        const data = res.data
        if (data.status === 200) {
          const message = data.content.cause
          localNotificationService.openSuccess(message, '', 1)
        }
        setTimeout(() => {
          window.location.href = "/v2/app/hr-leave/all-request"
        }, 2500)
      }
    ).catch((err) => {
      toastService.error(err)
      viewDispatch({ isLoading: false })
    })
  }

  return (
    <div className="add-mass-leave-page">
      <AddMassLeaveTemplate
        breadcrumbConf={breadcrumbConf}
        firstActionButton={firstActionButton}
        secondActionButton={{ active: false }}
        thirdActionButton={{ active: false }}
        fourthActionButton={{ active: false }}
        dropdownActionButton={{ visible: false }}
        submitButton={submitButton}
        employeeList={viewState.employeeList}
        configList={viewState.configList}
        dataSource={viewState}
        isDetail={false}
        isLoading={viewState.isLoading}
        onFieldChange={onFieldChange}
        onFinish={onFinish}
      />
    </div>
  )
}