import { Menu, Tooltip } from 'antd';
import React from 'react';
import SidebarItem from './SidebarItem';
import 'font-awesome/css/font-awesome.min.css';

function AuthMenu({ items, level }) {
  if (!items || !items.length) {
    return null;
  }

  return items.map((m) => {
    return (
      <Menu key={'submenu-' + m.name} mode="inline" inlineIndent={level}>
        {!!m.sub_menus && !!m.sub_menus.length ? (
          <Menu.SubMenu
            title={m.name}
            icon={m.menu_icon ? <i className={m.menu_icon + ' ant-menu-item-icon'} /> : null}
          >
            <AuthMenu items={m.sub_menus} level={level + 1} />
          </Menu.SubMenu>
        ) : (
          <Menu.Item icon={m.menu_icon ? <i className={m.menu_icon} /> : null}>
            <Tooltip mouseEnterDelay={0.5} title={m.name}>
              <SidebarItem menu={m}>{m.name}</SidebarItem>
            </Tooltip>
          </Menu.Item>
        )}
      </Menu>
    );
  });
}

export default AuthMenu;
