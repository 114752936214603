import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import { Button, Space, Pagination, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './CardListTemplate.less';
import {
  CardsListTemplateButton,
  CardsListTemplatePagination,
  CardsListTemplatePropsType,
} from './CardListTemplate.interface';
import Cards from '../card-list-template/components/Cards'
import BottomLoading from '../../components/bottom-loading/BottomLoading';

let output = {
  pagination: null,
  sort: null,
  searchColumn: null,
  selection: null,
};

export default function CardListTemplate(props: CardsListTemplatePropsType) {
  let [addButton, setAddButton] = useState<CardsListTemplateButton>();
  let [exportButton, setExportButton] = useState<CardsListTemplateButton>();
  let [deleteButton, setDeleteButton] = useState<CardsListTemplateButton>();
  let [dropdownButton, setDropdownButton] = useState<CardsListTemplateButton>();
  let [dataSource, setDataSource] = useState([]);

  let [pagination, setPagination] = useState<CardsListTemplatePagination>({
    current: 1,
    pageSize: 5,
    total: 5,
    size: 'small',
  });
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    fillActionButtonState('addButton');
  }, [props.addButton]);
  useEffect(() => {
    fillActionButtonState('exportButton');
  }, [props.exportButton]);
  useEffect(() => {
    fillActionButtonState('deleteButton');
  }, [props.deleteButton]);
  useEffect(() => {
    processTableData();
  }, [props.tableData]);
  useEffect(() => {
    processPagination();
  }, [props.tablePagination]);
  useEffect(() => {
    processLoading();
  }, [props.isLoading]);
  useEffect(() => {
    if (props.dropdownButton) {
      processDropdownButton();
    }
  }, [props.dropdownButton]);

  const fillActionButtonState = (procedure) => {
    const result: any = { visible: true, disabled: false };

    if (props[procedure]) {
      result.visible = props[procedure].visible === undefined ? true : props[procedure].visible;
      result.disabled = props[procedure].disabled === undefined ? false : props[procedure].disabled;
    }

    switch (procedure) {
      case 'addButton':
        setAddButton(result);
        break;
      case 'exportButton':
        setExportButton(result);
        break;
      case 'deleteButton':
        setDeleteButton(result);
        break;
      default:
        break;
    }
  };

  const processTableData = () => {
    if (props.tableData && props.tableData.dataSource && props.tableData.dataSource.length) {
      const searchColumnKey = props.tableData.searchColumnKey
        ? props.tableData.searchColumnKey
        : [];
      const searchColumnResult = JSON.parse(JSON.stringify(props.tableData.dataSource[0]));
      let dataSource = props.tableData.dataSource ? props.tableData.dataSource : [];
      if(dataSource.length === 1 && dataSource[0].key === 'text'){
        dataSource = [];
      }
      let result = [];

      if (searchColumnKey.length) {
        for (let key in searchColumnResult) {
          if (searchColumnKey.indexOf(key) !== -1) {
            searchColumnResult[key] = 'search-input';
          } else {
            searchColumnResult[key] = '';
          }
        }
        result.push(searchColumnResult);
      }
      result = result.concat(dataSource);
      setDataSource([...result]);
    }
  };

  const processPagination = () => {
    const resultPagination: CardsListTemplatePagination = pagination;

    if (props.tablePagination) {
      const tablePagination = props.tablePagination;
      resultPagination.current = tablePagination.current ? tablePagination.current : 1;
      resultPagination.pageSize = tablePagination.pageSize ? tablePagination.pageSize : 5;
      resultPagination.total = tablePagination.total ? tablePagination.total : 5;
      resultPagination.size = tablePagination.size ? tablePagination.size : 'small';
    }

    setPagination(resultPagination);
  };

  const processLoading = () => {
    const result = props.isLoading !== undefined ? props.isLoading : true;
    setLoading(result);
  };

  const processDropdownButton = () => {
    if(props.dropdownButton) {
      const result: any = { visible: false, disabled: false, options: [] };
      result.visible = props.dropdownButton.visible === undefined ? true : props.dropdownButton.visible;
      result.disabled = props.dropdownButton.disabled === undefined ? false : props.dropdownButton.disabled;
      result.options = props.dropdownButton.options
        ? props.dropdownButton.options
        : [{ label: 'Option 1', value: 'option_1' }];
  
      setDropdownButton(result)
    }
  }

  const sendOutputTable = () => {
    if (props.tableOnChange) {
      props.tableOnChange(output);
    }
  };

  const onClickActionButton = (procedure) => {
    if (props[procedure] && props[procedure].onClick) {
      props[procedure].onClick();
    }
  };

  const paginationOnChange = (page, pageSize) => {
    output.pagination = { page, pageSize };
    sendOutputTable();
  };

  const AddButton = () => {
    return addButton && addButton.visible ? (
      <Button
        type="primary"
        disabled={addButton.disabled}
        onClick={() => onClickActionButton('addButton')}
      >
        Tambah
      </Button>
    ) : null;
  };

  const ExportButton = () => {
    return exportButton && exportButton.visible ? (
      <Button
        type="primary"
        disabled={exportButton.disabled}
        onClick={() => onClickActionButton('exportButton')}
      >
        Export
      </Button>
    ) : null;
  };

  const DeleteButton = () => {
    return deleteButton && deleteButton.visible ? (
      <Button
        type="primary"
        disabled={deleteButton.disabled}
        onClick={() => onClickActionButton('deleteButton')}
      >
        Delete
      </Button>
    ) : null;
  };

  const DropdownButton = () => {
    const menu = dropdownButton && dropdownButton.visible ? (
      <Menu>
        {dropdownButton?.options?.map((opt) => (
          <Menu.Item
            disabled={opt.disabled}
            onClick={() => opt.onClick()}
            key={opt.key}
          >
            {opt.label}
          </Menu.Item>
        ))}
      </Menu>
    ) : null

    return dropdownButton && dropdownButton.visible ? (
      <Dropdown
        disabled={dropdownButton.disabled}
        overlay={menu}
      >
        <Button
          type='primary'
        >
          Tindakan <DownOutlined />
        </Button>
      </Dropdown>
    ) : null
  };

  return (
    <div id="landing-list-template">
      <Breadcrumb />

      <div className="toolbar">
        <Space>
          <AddButton />
          <ExportButton />
          <DeleteButton />
        </Space>
        <DropdownButton />

        <Pagination
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={pagination.total}
          size={pagination.size}
          showSizeChanger={false}
          onChange={paginationOnChange}
        />
      </div>
      <Cards source={dataSource} type={props.type} onDeactivate={props.onDeactivate} scroll={props.tableScroll ? props.tableScroll : false} />

      <BottomLoading active={loading} />
    </div>
  );
}
