import { Form } from "antd";
import { FormItemPropsType } from "./FormItem.interface";

export default function
  FormItemValue({ label, name, state }: FormItemPropsType) {
  return state && state.active === true ? (
    <Form.Item
      label={label}
      name={name}
      labelAlign="left"
      colon={false}
      rules={state.rules}
    >
      <span className="form-value">{state.value}</span>
    </Form.Item>
  ) : null;
};