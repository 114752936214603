import Constant from '../../../config/Constants';
import API from '../../../services/API.service';

export default class AdminDashboardUsecase {
  getNews() {
    const params = {
      page: 1,
      limit: 3,
    }
    const URL = Constant.URL_BASE_COMPANY + "/v3/dashboard/karyawan/news-section"
    return API.get(URL, {params})
  }
  getEmployeeSection() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/dashboard/karyawan/emp-section"
    return API.get(URL)
  }
  getBanner() {
    const params = {
      page: 1,
      limit: 5,
    }
    const URL = Constant.URL_BASE_MISC + "/res/banner"
    return API.get(URL, {params})
  }
  getProcessApp() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/dashboard/karyawan/process-app"
    return API.get(URL)
  }
}