import React from 'react';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.less';
import 'react-toastify/dist/ReactToastify.css';

import Login from './components/Login/Login';
import ForgotPassword from './components/Login/ForgotPassword';
import ResetPassword from './components/Login/ResetPassword';
import ChangePassword from './components/Login/ChangePassword';
import VerifyEmail from './components/additional/VerifyEmail';
// context
import { useUserState } from './context/UserContext';

import AuthContentLayout from './components/Layouts/AuthContentLayout';
import Logout from './components/Login/Logout';
import NotFound from './pages/error/NotFound';

function App() {
  const userState: any = useUserState();
  const isAuthenticated = userState.isAuthenticated;

  return (
    <BrowserRouter basename="/v2">
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} />
        <Route exact path="/app" render={() => <Redirect to="/app/dashboard" />} />
        <PrivateRoute path="/app" component={AuthContentLayout} />
        <PrivateRoute path="/logout" component={Logout} />
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/forgot-password" component={ForgotPassword} />
        <PublicRoute path="/new-password/:id" component={ResetPassword} />
        <PublicRoute path="/change-password/:id" component={ChangePassword} />
        <PublicRoute path="/verify-email/:id" component={VerifyEmail} />
        <Route path="*" component={NotFound} />
      </Switch>
      <ToastContainer closeOnClick={false} />
    </BrowserRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    let goto = rest.location.pathname;
    if (goto.includes('logout')) {
      goto = '/';
    }
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                search: '?goto=' + encodeURI(goto),
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}

export default App;
