import './DynamicStepsDetail.less';
import { RightOutlined } from '@ant-design/icons';
import { Space } from 'antd';


export default function DynamicStepsDetail({ activeStep, steps }) {
  const ActiveStep = ({ stepName }) => {
    return (
      <div className="active-step">
        <span className="active-step-container">{stepName}</span>
        <div className="arrow-right"></div>
      </div>
    );
  };

  const Step = ({ stepName, index }) => {
    return (
      <div className="step">
        <span className="step-name">{stepName}</span>

        {index !== 6 && <RightOutlined />}
      </div>
    );
  };

  const RenderStep = ({ stepName, index }) => {
    return stepName === activeStep ? (
      <ActiveStep stepName={stepName} />
    ) : (
      <Step stepName={stepName} index={index} />
    );
  };

  return (
    <div id="hira-step-leave-detail">
      <Space size="middle">
        {steps?.map((element, index) => (
          <RenderStep key={element} stepName={element} index={index} />
        ))}
      </Space>
    </div>
  );
}
