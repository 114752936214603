import { ToolbarDetailActionButton } from "../../components/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface";
import { useEffect, useState } from "react";
import ToastService from "../../../../../services/Toast.service";
import LocalNotificationService from "../../../../../services/LocalNotification.service";
import AddMasterLocationTemplate from "../template/AddMasterLocationTemplate";
import DetailMasterLocationUsecase from "./DetailMasterLocation.usecase";
import { getDetailMasterLocationMapper } from "../../../../../@core/mapper/employee/GetMasterLocation.mapper";
import { DetailMasterLocationType } from "../template/AddMasterLocationTemplate.interface";

const usecase = new DetailMasterLocationUsecase()
const toastService = new ToastService()
const localNotificationService = new LocalNotificationService();

export default function DetailMasterLocation({ match }) {
  const locationId = match.params.locationId

  const [regionList, setRegionList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [viewMode, setViewMode] = useState(true)
  const [isActive, setIsActive] = useState(false)
  const [detailDataSource, setDetailDataSource] = useState<DetailMasterLocationType>({
    name: null,
    region_id: null,
    region_name: null,
    checkbox_attendance_location: null,
    select_attendance_location: null,
    is_active: null
  })

  useEffect(() => {
    init()
  }, [])

  async function init() {
    const listRegion = await usecase.getRegionList()
    setRegionList([...listRegion])

    usecase.getDetail(locationId).then(
      (res) => {
        const content = getDetailMasterLocationMapper(res.data.content)
        setIsActive(content.is_active)
        setDetailDataSource(content)
        setIsLoading(false)
      }
    ).catch(err => {
      setIsLoading(false)
    })
  }

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Master Lokasi',
        href: "/v2/app/hr-employee/master-location",
      },
      {
        clickable: false,
        displayPathName: detailDataSource.name,
        href: "/v2/app/hr-employee/master-location/new/",
      }
    ],
  };

  const submitButton = { active: !viewMode, disabled: false };
  const firstActionButton: ToolbarDetailActionButton = {
    label: 'UBAH',
    type: 'primary',
    active: viewMode,
    disabled: false,
    onClick: () => {
      setViewMode(false)
    },
  };
  const secondActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: false,
    onClick: () => {
      if (!viewMode) {
        return window.location.reload()
      }
      const back = window.confirm("Anda Ingin Kembali")
      if (back) {
        window.location.href = "/v2/app/hr-employee/master-location"
      }
    },
  };
  const dropdownButton = {
    visible: true,
    disabled: isLoading,
    options: [
      { key: 'inactive', label: isActive ? 'Non-Aktifkan' : "Aktifkan", onClick: () => setActive(!isActive) },
    ]
  };

  function setActive(status) {
    setIsLoading(true);
    const params = {
      ids: [locationId],
      status
    };

    usecase.active(params).then(
      (res: any) => {
        const data = res.data
        if (data.code === 200) {
          const message = data.message
          localNotificationService.openSuccess(message, '', 1)
        }
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      },
      (err) => {
        setIsLoading(false);
        toastService.error(err)
      },
    );
  }

  const onFinish = (e) => {
    setIsLoading(true)
    const body = {
      id: locationId,
      name: e.location?.trim(),
      parent_id: e.region,
      as_attendance: !!e.checkbox_attendance_location,
      ...e.select_attendance_location, 
      radius: Number(e.radius),
      active: true,
    }
    
    usecase.update(body).then(
      (res) => {
        const data = res.data
        if (data.code === 200) {
          const message = data.message
          localNotificationService.openSuccess(message, '', 1)
        }
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      }
    ).catch((err) => {
      toastService.error(err)
      setIsLoading(false)
    })
  }
  
  return (
    <div className="add-employee-location">
      <AddMasterLocationTemplate
        breadcrumbConf={breadcrumbConf}
        dropdownActionButton={dropdownButton}
        firstActionButton={firstActionButton}
        secondActionButton={secondActionButton}
        thirdActionButton={{ active: false }}
        fourthActionButton={{ active: false }}
        detailDataSource={detailDataSource}
        listRegion={regionList}
        onFinish={onFinish}
        viewMode={viewMode}
        submitButton={submitButton}
        isLoading={isLoading}
        historyId={null}
      />
    </div>
  )
}