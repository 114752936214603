import Constant from "../../../../../config/Constants"
import API from "../../../../../services/API.service"

export default class DetailMasterLocationUsecase {
  update(body) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/master-location/update"
    return API.put(URL, body)
  }

  getDetail(id) {
    const URL = Constant.URL_BASE_COMPANY + `/v3/master-location/detail/${id}`
    return API.get(URL)
  }

  active(body) {
    const URL = Constant.URL_BASE_COMPANY + "/v3/master-location/update-state"
    return API.put(URL, body)
  }

  getRegionList() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/master-region/list"
    return API.get(URL).then(res => {
      return res.data.content.map((opt) => {
        return { key: opt.id, label: opt.region_name, value: opt.id }
      })
    })
  }
}