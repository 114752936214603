import { LeaveState } from '../../interface/leave/leave.interface';
import { LeaveStatus } from '../../entity/Leave.entity';

export function getLeaveState(leaveStatus: LeaveStatus): LeaveState {
  const collection = {
    Pengajuan: 'draft',
    'Persetujuan Satu': 'approve1',
    'ersetujuan Ke-2': 'approve2',
    Disetujui: 'approved',
    Ditolak: 'reject',
    Batal: 'cancel',
    Selesai: 'done',
  };
  return collection[leaveStatus] || '-';
}

export function getLeaveStatus(leaveState: LeaveState): LeaveStatus {
  const collection = {
    draft: 'Pengajuan',
    approve1: 'Persetujuan Satu',
    approve2: 'Persetujuan Ke-2',
    approved: 'Disetujui',
    reject: 'Ditolak',
    cancel: 'Batal',
    done: 'Selesai',
  };

  return collection[leaveState] || '-';
}
