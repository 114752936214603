import moment from 'moment';

export function getLocationListMapper(locationList) {
  const result = [];

  if (locationList.length) {
    locationList.forEach((location) => {
      result.push({
        key: location.id,
        name: location.name,
        region_name: location.region_name,
        status: location.active ? 'Aktif' : 'Non-aktif',
        attendance_location: location.as_attendance ? 'Ya' : 'Tidak',
        create_name: location.create_name,
        create_date: location.view_create_date,
        write_name: location.write_name,
        write_date: location.view_write_date,
      });
    });
  }

  return result;
}

export function getDetailMasterLocationMapper(detail) {
  return {
    name: detail.name,
    region_id: detail.parent_id,
    region_name: detail.region_name,
    checkbox_attendance_location: detail.as_attendance,
    select_attendance_location: {
      lat: detail.lat,
      lng: detail.lng,
      radius: detail.radius,
    },
    is_active: detail.active,
  };
}

function formatDate(dateString: string) {
  return moment(dateString).format('DD/MM/YYYY HH:mm:ss');
}
