import { Col, Row, Table, Tabs } from 'antd';
import SubmissionInformationsTable from './SubmissionDetailsTable';
import { MemoizeHistoryTabs } from '../../../employee/components/history-payroll-period-tabs/HistoryPayrollPeriodTabs';

export default function TabsWrapperSubmissionClaim({
  historyId,
  onChange,
  disabled,
  viewMode,
  value,
  approval1,
  approval2,
}) {
  const ApprovalTab = ({ dataSource }) => {
    return (
      <Table
        columns={[
          { key: 'alternative_name', dataIndex: 'alternative_name', title: 'Nama' },
          { key: 'job_name', dataIndex: 'job_name', title: 'Jabatan' },
        ]}
        dataSource={dataSource}
        pagination={false}
      />
    );
  };

  return (
    <div className="tabs-wrapper">
      <Tabs defaultActiveKey="1" type="card">
        <Tabs.TabPane tab="Rincian Pengajuan" key="1">
          <SubmissionInformationsTable viewMode={viewMode} disabled={disabled} onChange={onChange} value={value} />
        </Tabs.TabPane>
        {historyId ? (
          <>
            <Tabs.TabPane tab="Persetujuan" key="2">
              <Row>
                <Col span={4}>
                  <label className="label-table">Persetujuan Satu</label>
                </Col>
                <Col span={7}>
                  <ApprovalTab dataSource={approval1 || []} />
                </Col>
                <Col span={4}>
                  <label className="label-table">Persetujuan Dua</label>
                </Col>
                <Col span={7}>
                  <ApprovalTab dataSource={approval2 || []} />
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="History" key="3">
              <MemoizeHistoryTabs key="history-master" history_id={historyId} />
            </Tabs.TabPane>
          </>
        ) : null}
      </Tabs>
    </div>
  );
}
