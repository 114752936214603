export const AttendanceConstant = {
  TABLE_HEADER: [
    {
      title: 'Karyawan',
      key: 'employee_name',
      dataIndex: 'employee_name',
      sorter: true,
      isCTA: true,
      width: 200,
    },
    { title: 'Jabatan', key: 'jabatan', dataIndex: 'jabatan', sorter: true, width: 150 },
    { title: 'Waktu Check In', key: 'check_in', dataIndex: 'check_in', sorter: true, width: 150 },
    { title: 'Status Check In', key: 'category_check_in', dataIndex: 'category_check_in', sorter: true, width: 150 },
    { title: 'Waktu Check Out', key: 'check_out', dataIndex: 'check_out', sorter: true, width: 150 },
    { title: 'Status Check Out', key: 'category_check_out', dataIndex: 'category_check_out', sorter: true, width: 150 },
    { title: 'Jadwal Kerja', key: 'jadwal_kerja', dataIndex: 'jadwal_kerja', width: 150 },
    { title: 'Total Jam Kerja', key: 'worked_hours', dataIndex: 'worked_hours', width: 100 },
    { title: 'Total terlambat', key: 'total_late', dataIndex: 'total_late', width: 100},
    { title: 'Media Absensi', key: 'type_attendance', dataIndex: 'type_attendance', width: 150},
    { title: 'Status Absensi', key: 'status_absent_info', dataIndex: 'status_absent_info', sorter: true, width: 150 },
    { title: 'Keterangan', key: 'reason', dataIndex: 'reason', width: 150},
  ],

    SEARCH_COLUMN_KEY: [],

    SEARCH_COLUMN_COMPONENT: [
      { key: 'key', componentType: "text" },
      { key: 'employee_name', componentType: "text" },
      { key: 'check_in', componentType: "date" },
      { key: 'check_out', componentType: "date" },
      { key: 'status_absent_info', componentType: 'select', options: [
        { label: 'Semua', value: '' },
        { label: 'Tidak Masuk Kerja', value: 'TM' },
        { label: 'Masuk Kerja', value: 'MK' },
      ]},
      { key: 'category_check_in', componentType: 'select', options: [
        { label: 'Semua', value: '' },
        { label: 'Tidak Absen', value: 'TA' },
        { label: 'Terlambat', value: 'TT' },
        { label: 'Tepat Waktu', value: 'TW' },
      ]},
      { key: 'category_check_out', componentType: 'select', options: [
        { label: 'Semua', value: '' },
        { label: 'Pulang Cepat', value: 'PC' },
        { label: 'Masuk Kerja', value: 'MK' },
        { label: 'Tidak Absen', value: 'TA' },
      ]}
    ],
    
    DEFAULT_EMPTY_DATA_SOURCE: [
      {
        key: 'empty-data',
        employee_name: '',
        check_in: '',
        check_out: '',
        status_absent_info: '',
        category_check_in: '',
        category_check_out: '',
      }
    ],
    DETAIL_ABSENT_TABLE_HEADER: [
      {
        title: 'Tanggal',
        dataIndex: 'tanggal',
      },
      {
        title: 'Check In',
        dataIndex: 'check_in',
      },
      {
        title: 'Check Out',
        dataIndex: 'check_out',
      },
      {
        title: 'Total Jam Kerja',
        dataIndex: 'total',
      },
      {
        title: 'Keterangan',
        dataIndex: 'description',
      },
      {
        title: 'Lokasi',
        dataIndex: 'location',
      },
    ],
  };
  
  export const RecapAttendanceConstant = {
    TABLE_HEADER: [
      {
        title: 'Karyawan',
        key: 'employee_name',
        dataIndex: 'employee_name',
        sorter: true,
        width: 200,
      },
      { title: 'Jabatan', key: 'job_name', dataIndex: 'job_name', sorter: true, width: 100 },
      { title: 'Waktu Check In', key: 'check_in', dataIndex: 'check_in', sorter: true, width: 150 },
      { title: 'Status Check In', key: 'category_check_in', dataIndex: 'category_check_in', sorter: true, width: 150 },
      { title: 'Waktu Check Out', key: 'check_out', dataIndex: 'check_out', sorter: true, width: 150 },
      { title: 'Status Check Out', key: 'category_check_out', dataIndex: 'category_check_out', sorter: true, width: 150 },
      { title: 'Jadwal Kerja', key: 'jadwal_kerja', dataIndex: 'jadwal_kerja', width: 150 },
      { title: 'Total Jam Kerja', key: 'worked_hours', dataIndex: 'worked_hours', width: 100 },
      { title: 'Total terlambat', key: 'total_late', dataIndex: 'total_late', width: 100},
      { title: 'Media Absensi', key: 'type_attendance', dataIndex: 'type_attendance', width: 150},
      { title: 'Status Absensi', key: 'status_absent_info', dataIndex: 'status_absent_info', sorter: true, width: 130 },
      { title: 'Keterangan', key: 'reason', dataIndex: 'reason', width: 150},
    ],

    SEARCH_COLUMN_KEY: [],
    SEARCH_COLUMN_COMPONENT: [
      { key: 'key', componentType: "text" },
      { key: 'employee_name', componentType: "text" },
      { key: 'check_in', componentType: "date" },
      { key: 'check_out', componentType: "date" },
      { key: 'status_absent_info', componentType: 'select', options: [
        { label: 'Semua', value: null },
        { label: 'Tidak Masuk Kerja', value: 'TM' },
        { label: 'Masuk Kerja', value: 'MK' },
      ]},
      { key: 'category_check_in', componentType: 'select', options: [
        { label: 'Semua', value: '' },
        { label: 'Tidak Absen', value: 'TA' },
        { label: 'Terlambat', value: 'TT' },
        { label: 'Tepat Waktu', value: 'TW' },
      ]},
      { key: 'category_check_out', componentType: 'select', options: [
        { label: 'Semua', value: '' },
        { label: 'Pulang Cepat', value: 'PC' },
        { label: 'Masuk Kerja', value: 'MK' },
        { label: 'Tidak Absen', value: 'TA' },
      ]}
    ],
    DEFAULT_EMPTY_DATA_SOURCE: [
      {
        key: 'empty-data',
        employee_name: '',
        check_in: '',
        check_out: '',
        status_absent_info: '',
        category_check_in: '',
        category_check_out: '',
      }
    ],
  };
  
export const MissingAttendanceConstant = {
    TABLE_HEADER: [
      { title: 'NIK', key: 'nik', dataIndex: 'nik', width: 80 },
      {
        title: 'Karyawan',
        key: 'name_related',
        dataIndex: 'name_related',
        width: 200,
      },
      { title: 'Jabatan', key: 'job_name', dataIndex: 'job_name', width: 100 },
      { title: 'Jadwal Kerja', key: 'sch_work', dataIndex: 'sch_work', width: 150 },
      { title: 'Tanggal Kehadiran', key: 'status_absent_date', dataIndex: 'status_absent_date', width: 150 },
      { title: 'Keterangan', key: 'desc', dataIndex: 'desc', width: 150},
    ],
  
      SEARCH_COLUMN_KEY: [],
      DEFAULT_EMPTY_DATA_SOURCE: [
        {
          key: 'empty-data',
          employee_id: '',
          nik: '',
          name_related: '',
          job_name: '',
          sch_work: '',
          status_absent_date: '',
          desc: '',
        },
      ],
    };
