import { Col, Modal, Row, Table } from "antd"
import { useMemo, useState } from "react"

const TableHeader = [
  {
    key: 'state',
    dataIndex: 'state',
    align: 'center' as const,
    render: null
  },
  {
    key: 'count',
    dataIndex: 'count',
    align: 'center' as const
  },
  {
    key: 'leave_log',
    dataIndex: 'leave_log',
    align: 'center' as const
  },
]


const enumState = {
  Success: "Diproses",
  Failed: "Gagal",
  Waiting: "Belum Diproses",
}

export const TableStatusTabs = ({ leave_log, list_employee }) => {
  const [visibleModal, setVisibleModal] = useState(false)
  const [isFailedModal, setIsFailedModal] = useState(false)
  const [dataSource, setDataSource] = useState([])

  const headerDetail = [
    {
      key: 'nik',
      title: 'NIK',
      dataIndex: 'nik',
      align: 'center' as const,
      width: 140,
      render: null
    },
    {
      key: 'employee_id',
      title: 'Nama',
      dataIndex: 'employee_id',
      width: 180,
      align: 'center' as const,
      render: (employee_id) => {
        return list_employee?.find(e => e.value === employee_id)?.label?.split("- ")[1]
      }
    },
    {
      key: 'job_name',
      title: 'Jabatan',
      dataIndex: 'job_name',
      align: 'center' as const
    },
  ]
  
  const headerFailed = [
    ...headerDetail,
    {
      key: 'description',
      title: "Keterangan",
      dataIndex: 'description',
      align: 'center' as const,
      render: (message) => {
        return message?.split(" (")[0]
      }
    }
  ]

  useMemo(() => {
    TableHeader[0].render = (e) => {
      return enumState[e]
    }

    TableHeader[2].render = (e, record) => {
      return <a onClick={() => onShowModal(e, record)}>Lihat detail</a>
    }
  }, [])

  const onShowModal = (e, record) => {
    const isFailed = enumState[record.state] === enumState.Failed
    setIsFailedModal(isFailed)
    setDataSource(e)
    setVisibleModal(true)
  }
  
  return (
    <Row justify="center">
      <Modal
        visible={visibleModal}
        footer={false}
        width={800}
        onCancel={() => setVisibleModal(false)}
        children={
          <Table
            pagination={false}
            dataSource={dataSource}
            columns={
              isFailedModal ? 
              headerFailed : 
              headerDetail
            }
          />
        }
      />
      <Col span={14}>
        <Table
          pagination={false}
          showHeader={false}
          dataSource={[
            leave_log.find(e => e.state === Object.keys(enumState)[0]),
            leave_log.find(e => e.state === Object.keys(enumState)[1]),
            leave_log.find(e => e.state === Object.keys(enumState)[2])
          ]}
          columns={TableHeader}
        />
      </Col>
    </Row>
  )
}