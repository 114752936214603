import Moment from 'moment';
import { getCheckinStatus, getCheckoutStatus, getAbsentStatus } from './AttendanceStatus.mapper';
import { GetAttendanceResp } from '../../interface/attendance/GetAttendance.interface';
import { AttendanceItem, AttendanceList } from '../../entity/AttendanceList.entity';

export function attendanceListMapper(getAttendanceResp, hrefPath: string): AttendanceList {
  const content = getAttendanceResp.content ? getAttendanceResp.content : null;
  let contentMap: AttendanceItem[] = null;

  if (content) {
    contentMap = content.map((data) => {
      const attendanceItem = {
        id: data.id,
        key: data.id,
        create_date: data.create_date ? Moment(data.create_date).format('DD/MM/YYYY HH:mm') : '-',
        write_uid: data.write_uid,
        create_uid: data.create_uid,
        check_in: data.check_in ? Moment(data.check_in).format('DD/MM/YYYY HH:mm') : '-',
        employee_id: data.employee_id,
        employee_name: data.nama,
        jabatan: data.jabatan,
        total_works_per_hour: data.total_works_per_hour,
        company_id: data.company_id,
        department_id: data.department_id,
        hr_attendance_id: data.hr_attendance_id,
        information: data.information,
        hira_absent_id: data.hira_absent_id,
        category_check_in: getCheckinStatus(data.category_check_in),
        status_absent_date: data.status_absent_date ? Moment(data.status_absent_date).format('DD/MM/YYYY HH:mm') : '-',
        write_date: Moment(data.write_date).format('DD/MM/YYYY HH:mm'),
        type_attendance: data.type_attendance,
        check_out: data.check_out ? Moment(data.check_out).format('DD/MM/YYYY HH:mm') : '-',
        name: data.name,
        status_absent: data.status_absent,
        category_check_out: getCheckoutStatus(data.category_check_out),
        reason: data.reason,
        status_worked: data.status_worked,
        status_absent_info: getAbsentStatus(data.status_absent_info),
        total_late: data.total_late,
        lat: data.lat,
        lng: data.lng,        
        gmap_loc: data.gmap_loc,
        worked_hours: data.worked_hours,
        place_of_attendance: data.place_of_attendance,
        show_for_edit: data.show_for_edit,
        attendance_detail_ids: data.attendance_detail_ids,
        create_name: data.create_name,
        write_name: data.write_name,
        href: `${hrefPath}${data.id}`,
        jadwal_kerja: data.jadwal_kerja,
      };

      return attendanceItem;
    });
  }

  return {
    content: contentMap,
    metaData: getAttendanceResp.metadata,
  };
}
