import { useEffect, useState } from 'react';
import LandingListTemplate from '../../../../../../templates/landing-list-template/LandingListTemplate';
import { useHistory } from 'react-router-dom';
import { RecapAttendanceConstant } from '../../../../../../config/Attendance.constant';
import { LandingListTemplatePagination } from '../../../../../../templates/landing-list-template/LandingListTemplate.interface';
import RecapAttendanceUseCaseImpl from './HrMyRecapAttendance.usecase.impl';
import HrAttendanceService from '../../../HrAttendanceService';
import CalendarTempltePage from "../calendar-recap-attendance/HrCalendarRecapAttendance";

const hrAttendanceService = new HrAttendanceService();
const useCaseImpl = new RecapAttendanceUseCaseImpl();

let page = 1;
let loadPagination = true;
let sort = 'create_date|desc';
let employee_name = '';
let check_in_date = '';
let check_out_date = '';
let status_absent_info = null;
let status_check_in = '';
let status_check_out = '';
let selectionAttendanceId = [];

export default function HrMyRecapAttendanceList() {
  const history = useHistory();
  const tableHeader: any = RecapAttendanceConstant.TABLE_HEADER;
  const searchColumnKey = RecapAttendanceConstant.SEARCH_COLUMN_KEY;
  const searchColumnComponent = RecapAttendanceConstant.SEARCH_COLUMN_COMPONENT;
  const limit = 10;

  let [pagination, setPagination] = useState<LandingListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });
  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([]);
  let [tableScroll, setTableScroll] = useState({ y: 200, x: 'max-content' });
  let [disableExportButton, setDisableExportButton] = useState(false);
  const [calendarMode, setCalendarMode] = useState(false);
  const [calendarData, setCalendarData] = useState<any>([])

  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: true, searchColumnComponent };

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {

    loadList();
    loadCalendarData();
    const clientHeight = document.getElementById('recap-attendance-page').clientHeight;
    
    setTableScroll({ y: clientHeight - 220, x: 'max-content' });
  }

  function loadList() {
    setIsLoading(true);

    const params = {
      page,
      limit,
      employee_name,
      check_in_date,
      check_out_date,
      status_absent_info,
      status_check_in,
      status_check_out,
      sort,
      with_meta: true,
    };
    useCaseImpl
      .getMyRecapAttendance(params, '/v2/app/hr-attendance/my-recap-attendance/')
      .then(
        (res:any) => {
          setIsLoading(false);
          const content = res.content;
          const metaData = res.metaData;

          const resultDataSource = content ? content : RecapAttendanceConstant.DEFAULT_EMPTY_DATA_SOURCE;
          setDataSource([...resultDataSource]);
          updatePaginationState(metaData?.total_records);

          loadPagination = true;
          setDisableExportButton(content ? false : true);
        },
        (err) => {
          setIsLoading(false);
          setDataSource([...RecapAttendanceConstant.DEFAULT_EMPTY_DATA_SOURCE]);
          loadPagination = true;
          setDisableExportButton(true);
        }
      )
  }
  function loadCalendarData() {
    setIsLoading(true);

    const params = {
      page,
      limit:100,
      sort,
      with_meta: true,
    };
    useCaseImpl
      .getCalendarData(params)
      .then(
        (res:any) => {
          setIsLoading(false);
          const content = res.content;
          const resultDataSource = content ? content : [];
          setCalendarData(resultDataSource);
        },
        (err) => {
          setIsLoading(false);
          setCalendarData([]);
        }
      )
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    const outputSort = output.sort;
    const outputSearchColumn = output.searchColumn;

    processSearchColumn(outputSearchColumn);
    processSelection(output.selection);
    processPagination(outputPagination);
  };

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  function processSearchColumn(outputSearchColumn) {
    let isLoadList = false;
    if (outputSearchColumn) {
      if (
        outputSearchColumn.employee_name !== undefined &&
        outputSearchColumn.employee_name !== employee_name
      ) {
        employee_name = outputSearchColumn.employee_name;
        isLoadList = true;
      }

      if (
        outputSearchColumn.check_in !== undefined &&
        outputSearchColumn.check_in !== check_in_date
      ) {
        check_in_date = outputSearchColumn.check_in;
        isLoadList = true;
      }

      if (
        outputSearchColumn.check_out !== undefined &&
        outputSearchColumn.check_out !== check_out_date
      ) {
        check_out_date = outputSearchColumn.check_out;
        isLoadList = true;
      }
      if (
        outputSearchColumn.status_absent_info !== undefined &&
        outputSearchColumn.status_absent_info !== status_absent_info
      ) {
        status_absent_info = outputSearchColumn.status_absent_info;
        isLoadList = true;
      }
      if (
        outputSearchColumn.category_check_in !== undefined &&
        outputSearchColumn.category_check_in !== status_check_in
      ) {
        status_check_in = outputSearchColumn.category_check_in;
        isLoadList = true;
      }
      if (
        outputSearchColumn.category_check_out !== undefined &&
        outputSearchColumn.category_check_out !== status_check_out
      ) {
        status_check_out = outputSearchColumn.category_check_out;
        isLoadList = true;
      }

      if (isLoadList) {
        page = 1;
        loadPagination = false;
        loadList();
      }
    }
  }
  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionAttendanceId) {
      selectionAttendanceId = outputSelection;
    }
  }

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
  }
  function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };

  const changeModeButton = {
    visible: true,
    onClick: () => {
      setCalendarMode(!calendarMode)
    },
    disabled: isLoading,
  };

  const exportButton = {
    onClick: () => {
      setIsLoading(true);
      const params = {
        // page,
        // limit,
        employee_name,
        check_in_date,
        check_out_date,
        status_absent_info,
        status_check_in,
        status_check_out,
        sort,
        with_meta: true,
      };
      useCaseImpl.exportMyRecapAttendance(params).then(
        (res: any) => {
          const data = res.data;
          if (data.status === 200) {
            const file = base64ToArrayBuffer(data.content.data);
            saveByteArray(data.content.filename, file);
          }
          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
        },
      );
    },
    disabled: disableExportButton || isLoading,
  };

  return calendarMode ? (
    <div id='recap-attendance-page'>
      <CalendarTempltePage 
        eventDates={calendarData}
        isLoading={isLoading}
        changeModeButton={changeModeButton}
      />
    </div>
  ) : (
      <div id="recap-attendance-page" style={{ height: 'calc(100vh - 100px)' }}>
      <LandingListTemplate
        addButton={{visible: false}}
        deleteButton={{visible: false}}
        exportButton={exportButton}
        tableHeader={tableHeader}
        tableData={tableData}
        tablePagination={pagination}
        tableOnChange={tableOnChange}
        isLoading={isLoading}
        tableScroll={tableScroll}
        changeModeButton={changeModeButton}
      />
    </div>
  )
}
