import { GetLeaveRequestDetailResp } from '../../interface/leave/GetLeaveRequestDetail.interface';
import Constant from '../../../config/Constants';
import API from '../../../services/API.service';
import { LeaveId } from '../../entity/Leave.entity';

export default function getLeaveRequestDetail(
  leaveId: LeaveId,
): Promise<GetLeaveRequestDetailResp> {
  const URL = Constant.URL_BASE_ATTENDANCE + '/v3/leave/request/' + leaveId;
  return API.get(URL);
}
