import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "antd";
import ExpandableListTemplate from "../../../../../../templates/expandable-list-template/ExpandableListTemplate";
import { LandingListTemplatePagination } from "../../../../../../templates/landing-list-template/LandingListTemplate.interface";
import RecurringHolidaysListUseCase from "./ListRecurringHolidays.usecase";
import { RecurringHolidaysListConstant } from "./ListRecurringHolidays.constant";
import { MapperMasterHolidayList } from "../../../../../../@core/mapper/attendance/MasterHolidayList.mapper";
import LocalNotificationService from "../../../../../../services/LocalNotification.service";
import ToastService from "../../../../../../services/Toast.service";


const useCase = new RecurringHolidaysListUseCase()
const constant = RecurringHolidaysListConstant
const localNotificationService = new LocalNotificationService()
const toastService = new ToastService()

let page = 1;
let loadPagination = true;
let sort = 'write_date|desc'
const limit = 10;
let selectionRecurringId = []
let nama_hari_libur
let date_start
let date_stop
let is_active

export default function ListRecurringHolidays() {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false)
  const [visibleWarningModal, setVisibleWarningModal] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [tableScroll, setTableScroll] = useState({ y: 600 });
  const [pagination, setPagination] = useState<LandingListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });

  useEffect(() => {
    loadList()
  }, [])

  let tableHeader: any = constant.TABLE_HEADER
  let searchColumnKey = constant.SEARCH_COLUMN_KEY
  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: true };

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: false,
        displayPathName: 'Hari Libur Berulang',
        href: "/v2/app/attendance/recurring-holidays",
      },
    ],
  };

  const addButton = {
    onClick: () => {
      history.push('/app/attendance/recurring-holidays/new/');
    },
    disabled: isLoading,
  };

  const dropdownButton = {
    visible: true,
    disabled: isLoading,
    options: [
      { key: 'export', label: 'Ekspor', onClick: () => exportData() },
      { key: 'non-active', label: 'Non-Aktifkan', onClick: () => setVisibleWarningModal(true) }
    ]
  };

  const onRow = (e) => {
    if (e.key !== 'search-input') {
      history.push("/app/attendance/recurring-holidays/" + e.key)
    }
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    const outputSort = output.sort;
    const outputSearchColumn = output.searchColumn;
    
    processSort(outputSort);
    processSearchColumn(outputSearchColumn);
    processSelection(output.selection);
    processPagination(outputPagination);
  };

  function loadList() {
    setIsLoading(true)
    const params = {
      page,
      limit,
      sort,
      nama_hari_libur,
      date_start,
      date_stop,
      is_active,
      with_meta: true,
    }

    useCase.getAll(params).then((res: any) => {
      const content = res.data.content;
      const metaData = res.data.metadata;

      const resultDataSource = content && content.length ? MapperMasterHolidayList(content)
        : constant.DEFAULT_EMPTY_DATA_SOURCE

      setDataSource([...resultDataSource]);
      loadPagination = true
      updatePaginationState(metaData.total_records);
      setIsLoading(false)
    }, (err) => {
      toastService.error(err)
      setIsLoading(false)
    })
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData ? totalData : 1;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  function processSort(outputSort) {
    if (outputSort && useCase.convertOutputSortToEndPointFormat(outputSort) !== sort) {
      page = 1;
      loadPagination = false;

      sort = useCase.convertOutputSortToEndPointFormat(outputSort);
      loadList();
    }
  }

  function processSearchColumn(outputSearchColumn) {
    let isLoadList = false;

    if (outputSearchColumn) {
      if (
        outputSearchColumn.holiday_name !== undefined &&
        outputSearchColumn.holiday_name !== nama_hari_libur
      ) {
        nama_hari_libur = outputSearchColumn.holiday_name;
        isLoadList = true;
      }
    }

    if (outputSearchColumn) {
      if (
        outputSearchColumn.effective_date !== undefined &&
        outputSearchColumn.effective_date !== date_start
      ) {
        date_start = outputSearchColumn.effective_date;
        isLoadList = true;
      }
    }

    if (outputSearchColumn) {
      if (
        outputSearchColumn.end_date !== undefined &&
        outputSearchColumn.end_date !== date_stop
      ) {
        date_stop = outputSearchColumn.end_date;
        isLoadList = true;
      }
    }

    if (outputSearchColumn) {
      if (
        outputSearchColumn.status !== undefined &&
        outputSearchColumn.status !== is_active
      ) {
        is_active = outputSearchColumn.status;
        isLoadList = true;
      }
    }

    if (isLoadList) {
      page = 1;
      loadPagination = false;
      loadList();
    }
  }

  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionRecurringId) {
      selectionRecurringId = outputSelection;
    }
  }

  function deleteToEndPoint() {
    setIsLoading(true);
    setVisibleWarningModal(false);

    const params = {
      ids: selectionRecurringId,
    };

    useCase.active(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          localNotificationService.openSuccess(data.message, '', 3)
        }
        setVisibleWarningModal(false);
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      },
      (err) => {
        toastService.error(err)
        setVisibleWarningModal(false);
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      },
    );
  }

  function exportData() {
    setIsLoading(true)
    const params = {
      sort,
      nama_hari_libur,
      date_start,
      date_stop,
      is_active,
      id: selectionRecurringId,
      with_meta: true,
    }

    useCase.export(params).then((resp: any) => {
      setIsLoading(false);
      const content = resp.data.content;
      if (resp.status === 200) {
        const data = content.data
        let sampleArr = base64ToArrayBuffer(data)
        saveByteArray(content.filename, sampleArr);
      }
    })
  }

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
  }

  function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };

  return (
    <div id="recurring-holiday-list" style={{ height: 'calc(100vh - 100px)' }}>
      <Modal
        title="Hapus Data"
        cancelText="BATALKAN"
        visible={visibleWarningModal}
        cancelButtonProps={{ type: 'ghost' }}
        onOk={deleteToEndPoint}
        onCancel={() => setVisibleWarningModal(false)}
      >
        <p>Apakah yakin ingin menon-aktifkan data-data ini?</p>
      </Modal>
      <ExpandableListTemplate
        breadcrumbConf={breadcrumbConf}
        addButton={addButton}
        deleteButton={{ visible: false }}
        exportButton={{ visible: false }}
        dropdownButton={dropdownButton}
        tableHeader={tableHeader}
        tableData={tableData}
        tablePagination={pagination}
        tableOnChange={tableOnChange}
        isLoading={isLoading}
        tableScroll={tableScroll}
        onRow={onRow}
      />
    </div>
  )
}