import { TableHeaderColumnsType } from "./ImportLeaveCorrection.interface"

export const LeaveCorrectionTableColumn = [
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: null,
    width: 100,
  },
  {
    title: 'NIK',
    dataIndex: 'nik',
    key: 'nik',
    render: null,
    width: 150,
  },
  {
    title: 'Nama Karyawan',
    dataIndex: 'nama_karyawan',
    key: 'nama_karyawan',
    render: null,
    width: 200,
  },
  {
    title: 'Jabatan',
    dataIndex: 'nama_jabatan',
    key: 'nama_jabatan',
    render: null,
    width: 200,
  },
  {
    title: 'Tipe Cuti',
    dataIndex: 'tipe_cuti',
    key: 'tipe_cuti',
    render: null,
    width: 150,
  },
  {
    title: 'Ubah Plafon',
    dataIndex: 'ubah_plafon',
    key: 'ubah_plafon',
    render: null,
    width: 150,
  },{
    title: 'Koreksi Plafon',
    dataIndex: 'koreksi_plafon',
    key: 'koreksi_plafon',
    render: null,
    width: 150,
  },
  {
    title: 'Ubah Sisa Cuti',
    dataIndex: 'ubah_sisa_cuti',
    key: 'ubah_sisa_cuti',
    render: null,
    width: 160,
  },
  {
    title: 'Koreksi Sisa Cuti',
    dataIndex: 'koreksi_sisa_cuti',
    key: 'koreksi_sisa_cuti',
    render: null,
    width: 180,
  },
  {
    title: 'Pesan Error',
    dataIndex: 'error_message',
    key: 'error_message',
    render: null,
    width: 180,
  },
]