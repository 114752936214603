import React, { useEffect, useState } from 'react';
import { Form, Button, } from 'antd';
import FormBuilder from 'antd-form-builder';
import { useHistory } from 'react-router-dom';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import HrAddMasterJobUsecase from './AddMasterJob.usecase';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import PageLoader from '../../../../../components/loader/PageLoader';

const useCaseImpl = new HrAddMasterJobUsecase()
const localNotificationService = new LocalNotificationService();

export default function HrAddMasterJob() {
  const history = useHistory();
  const [form] = Form.useForm();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [, updateState] = React.useState<any>();
  const [jobList, setJobList] = useState([]);
  const [deptList, setDeptList] = useState([]);
  const [AccessList, setAccessList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Master Jabatan',
        href: "/v2/app/hr-employee/list-master-job",
      },
      {
        clickable: false,
        displayPathName: 'Baru',
      }
    ],
  };

  const meta: any = {
    columns: 2,
    fields: [
      {
        key: 'name',
        label: 'Nama Jabatan',
        rules: [{ required: true, whitespace: true, message: "Nama Jabatan harus diisi", }],
      },
      {
        key: 'department',
        label: 'Departemen',
        widget: 'select',
        required: true,
        message: 'Departemen harus dipilih',
        options: deptList,
        widgetProps: {
          showSearch: true
        },
      },
      {
        key: 'job_atasan',
        label: 'Jabatan Atasan',
        widget: 'select',
        options: jobList,
        widgetProps: {
          showSearch: true
        },
        onChange: async(value) => {
          const temp = JSON.parse(value).id;
          const getEmp = await useCaseImpl.getEmpl(temp);
          if(getEmp && getEmp.length > 0){
            form.setFieldsValue({
              atasan_langsung: getEmp[0].label,
            })
          }
          forceUpdate();
        }
      },
      {
        key: 'atasan_langsung',
        label: 'Nama Atasan Langsung',
        readOnly: true,
      },

      {
        key: 'access',
        label: 'Hak Akses',
        widget: 'select',
        options: AccessList
      },
      {
        key: 'description',
        label: 'Deskripsi Pekerjaan',
      },
    ],
  };

  useEffect(() => {
    setIsLoading(true);
    initialPage();
  }, []);

  async function initialPage() {
    const getJob = await useCaseImpl.getJob({is_parent: true});
    const getDept = await useCaseImpl.getDepartment();
    const getAccessList = await useCaseImpl.getAccessList();
    
    setJobList(getJob);
    setDeptList(getDept);
    setAccessList(getAccessList);
    setIsLoading(false);
  }

  async function onFinish(e) {
    setIsLoading(true);
    if (e) {
      let tempParam: any = {}
      e.department && (tempParam['department_id'] = JSON.parse(e.department).id)
      e.department && (tempParam['department_name'] = JSON.parse(e.department).dept_name)
      e.job_atasan && (tempParam['parent_id'] = JSON.parse(e.job_atasan).id)
      e.access && (tempParam['group_id'] = JSON.parse(e.access).id)
      const params = {
        description : e.description, 
        job_name : e.name.trim(),
        ...tempParam
     }
     useCaseImpl.create(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          history.push('/app/hr-employee/list-master-job');
          localNotificationService.openSuccess('Jabatan Berhasil Ditambah', '', 3);
        }
        setIsLoading(false);
      },
      (err) => {
        localNotificationService.openError(err, '', 3)
        setIsLoading(false);
      },
    );
    }
  }

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div
      className="add-leave-configuration-page"
      style={{ position: 'relative', height: 'calc(100vh - 100px)' }}
    >
      <Form form={form} layout="horizontal" onFinish={onFinish}>
        <div>
          <div className="form-wrapper">
            <div>
              <Breadcrumb breadcrumbConf={breadcrumbConf} />
              <Form.Item
                className="form-footer"
                style={{ width: '100%', marginTop: 20 }}
              >
                <Button
                  type="primary"
                  onClick={() => form.submit()}
                  disabled={isLoading}
                >
                  Simpan
                </Button>
                &nbsp; &nbsp;
                <Button
                  onClick={() => history.push('/app/hr-employee/list-master-job')}
                >
                  Batal
                </Button>
              </Form.Item>
              <div style={{ border: '1px solid #000', padding: 10, overflowY: 'scroll', height: '70vh' }}>
                <FormBuilder form={form} meta={meta} />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
