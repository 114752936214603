import moment from "moment";

export function claimPaymentMapper(response) {
    const content = response.content ? response.content : null;
    let contentMap = null;
  
    if (content) {
      contentMap = content.map((data) => {
        const item = {
          id: data.id,
          key: data.id,
          nik: data.nik,
          no_claim: data.nomor_claim,
          employee_name: data.employee_name,
          date_claim: moment(data.date_claim).format('DD-MM-YYYY'),
          claim_type: data.type_claim,
          description: data.description,
          amount: `Rp${data.total_request}`,
          approved_by: data.approved_by,
          state_payment: data.state_payment,
          action: 'Lihat Detail'
        };
        return item;
      });
    }
  
    return {
      content: contentMap,
      metaData: response.metadata,
    };
  }

  export function claimDetailPaymentMapper(response) {
    const content = response.content ? response.content : null;
    let contentMap = null;
  
    if (content) {
      contentMap = content.map((data) => {
        const item = {
          id: data.id,
          date_end: moment(data.date_end).format('DD-MM-YYYY'),
          date_start: moment(data.date_start).format('DD-MM-YYYY'),
          description: data.description,
          total: `Rp${data.total}`,
        };
        return item;
      });
    }
  
    return {
      content: contentMap,
      metaData: response.metadata,
    };
  }
  