import { Col, Pagination, Row, Table } from "antd";
import { HistoryTableHeader } from "./HistoryPayrollPeriodTabs.constant";
import React, { useEffect, useMemo, useReducer } from "react";
import HistoryPayrollTabsUseCase from "./HistoryPayrollPeriodTabs.usecase";
import BottomLoading from "../../../../components/bottom-loading/BottomLoading";

const useCase = new HistoryPayrollTabsUseCase()

function HistoryPayrollPeriodTabs({ history_id }) {
  const [state, dispatch] = useReducer((prev, next) => {
    return { ...prev, ...next }
  }, { pageSize: 0, tableData: [], page: 1, isLoading: false })

  function GetHistoryTableData(page) {
    dispatch({ isLoading: true })
    const params = {
      page,
      limit: 3,
      history_id,
      with_meta: true,
    }

    useCase.getHistory(params).then(
      (res) => {
        const content = res.data.content
        const dataSource = content.map((ct) => {
          return { ...ct, key: ct.id }
        })
        const { total_pages } = res.data.metadata
        dispatch({ tableData: dataSource })
        dispatch({ pageSize: total_pages })
        dispatch({ isLoading: false })
      }
    ).catch((err) => {
      dispatch({ isLoading: false })
    })
  }


  useMemo(() => {
    if (history_id) {
      GetHistoryTableData(state.page)
    }
  }, [history_id, state.page])

  const paginationOnChange = (value) => {
    dispatch({ page: value })
    GetHistoryTableData(value)
  }

  return (
    <div className="tabs-wrapper">
      <Row justify="center">
        <Col span={14}>
          {state.tableData.length > 0 && (
            <Pagination
              style={{ float: 'right', marginBottom: '15px' }}
              current={state.page}
              pageSize={1}
              total={state.pageSize}
              size='default'
              showSizeChanger={false}
              onChange={paginationOnChange}
            />
          )}
          
          <Table
            columns={HistoryTableHeader}
            dataSource={state.tableData}
            pagination={false}
          />
        </Col>
      </Row>

      <BottomLoading active={state.isLoading} />
    </div>
  )
}

export const MemoizeHistoryTabs = React.memo(HistoryPayrollPeriodTabs)