import SubmissionLeaveUseCase from '../../../../../../@core/use-case/leave/SubmissionLeave.usecase';
import getLeaveTypes from '../../../../../../@core/interaction/leave/GetLeaveTypes.interaction';
import getMySubmissionLeaves from '../../../../../../@core/interaction/leave/GetMySubmissionLeaves.interaction';
import { leaveTypeToObjById } from '../../../../../../@core/mapper/leave/GetLeaveTypes.mapper';
import { leaveListMapper } from '../../../../../../@core/mapper/leave/LeaveRequestList.mapper';
import deleteLeaves from '../../../../../../@core/interaction/leave/DeleteLeaves.interaction';
import exportMySubmissionLeaves from '../../../../../../@core/interaction/leave/ExportMySubmissionLeave.interaction';
import { LeaveId } from '../../../../../../@core/entity/Leave.entity';
import { UpdateLeaveStateDTO } from '../../../../../../@core/interface/leave/UpdateLeaveState.interface';
import updateLeaveState from '../../../../../../@core/interaction/leave/UpdateLeaveState.interaction';

export default class MySubmissionLeaveUseCaseImpl extends SubmissionLeaveUseCase {
  getLeaveTypes() {
    return getLeaveTypes().then((res: any) => leaveTypeToObjById(res.content));
  }

  getSubmissionLeaves(params, leaveTypeObj, hrefPath) {
    return getMySubmissionLeaves(params).then((res: any) =>
      leaveListMapper(res.data, leaveTypeObj, hrefPath),
    );
  }

  cancelLeave(leaveId: LeaveId[]) {
    const params: UpdateLeaveStateDTO = {
      leaveId,
      leaveState: 'cancel',
    };
    return updateLeaveState(params);
  }

  exportSubmissionLeaves(params) {
    return exportMySubmissionLeaves(params);
  }
}
