import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row } from 'antd'
import LandingListTemplate from '../../../../../templates/landing-list-template/LandingListTemplate';
import { AccessListConstant } from "./ListAccess.constant";
import { getListAccessMapper } from '../../../../../@core/mapper/employee/GetListAccess.mapper';
import AccessListUsecase from "./ListAccess.usecase";
import Breadcrumb from "../../../../../components/breadcrumb/Breadcrumb";

const useCaseImpl = new AccessListUsecase()
const constant = AccessListConstant

let page = 1;
let limit = 10;
let name = '';
let loadPagination = true;
let selectionUserId = [];

export default function ListEmployee() {
  const history = useHistory();
  const tableHeader: any = constant.TABLE_HEADER;
  const searchColumnKey = constant.SEARCH_COLUMN_KEY;
  const searchColumnComponent = constant.SEARCH_COLUMN_COMPONENT;

  let [pagination, setPagination] = useState<any>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });
  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([]);
  let [tableScroll, setTableScroll] = useState({ y: 200 });

  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: false, searchColumnComponent };

  useEffect(() => {
    initialPage();
  }, []);

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: false,
        displayPathName: 'Referensi Hak Akses',
      },
    ],
  };

  async function initialPage() {

    loadList();
    const clientHeight = document.getElementById('list-access-page').clientHeight;

    setTableScroll({ y: clientHeight - 220 });
  }

  function loadList() {
    setIsLoading(true);
    const params = {
      page,
      limit,
      name,
      with_meta: true,
    };

    useCaseImpl.getAccessList(params).then(
      (res: any) => {
        const content = res.data.content;
        const metaData = res.data?.metadata;
        const resultDataSource = content && content?.length ? getListAccessMapper(content)
          : constant.DEFAULT_EMPTY_DATA_SOURCE
        loadPagination = true
        setDataSource([...resultDataSource]);
        updatePaginationState(metaData?.total_records);
        setIsLoading(false);
      }
    )
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    const outputSort = output.sort;
    const outputSearchColumn = output.searchColumn;

    // processSort(outputSort);
    processSearchColumn(outputSearchColumn);
    processSelection(output.selection);
    processPagination(outputPagination);
  };

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  function processSearchColumn(outputSearchColumn) {
    let isLoadList = false;
    if (outputSearchColumn) {
      if (
        outputSearchColumn.name !== undefined &&
        outputSearchColumn.name !== name
      ) {
        name = outputSearchColumn.name;
        isLoadList = true;
      }
      if (isLoadList) {
        page = 1;
        loadPagination = false;
        loadList();
      }
    }
  }



  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionUserId) {
      selectionUserId = outputSelection;
    }
  }

  const addButton = {
    onClick: () => {
      history.push('/app/hr-employee/access/new/');
    },
    disabled: isLoading,
  };

  const onRow = (e) => {
    if (e.key && e.key !== 'text' && e.key !== 'empty-data') {
      history.push("/app/hr-employee/update-access/" + e.key)
    }
  }

  return (
    <div id="list-access-page" style={{ height: 'calc(100vh - 100px)' }}>
      <Row justify='space-between'>
        <Breadcrumb breadcrumbConf={breadcrumbConf} />
      </Row>
      <LandingListTemplate
        addButton={addButton}
        deleteButton={{ visible: false }}
        exportButton={{ visible: false }}
        tableHeader={tableHeader}
        tableData={tableData}
        tablePagination={pagination}
        tableOnChange={tableOnChange}
        isLoading={isLoading}
        tableScroll={tableScroll}
        onRow={onRow}
      />
    </div>
  );
}
