import Constant from "../../../../../../config/Constants"
import API from "../../../../../../services/API.service"

export default class EmployeeSubmissionOvertimeUseCase {
  convertOutputSortToEndPointFormat(tableOutputSort) {
    let result = '';
    const columnKey = tableOutputSort.columnKey;
    const order = tableOutputSort.order;

    if (order) {
      switch (columnKey) {
        default:
          break;
        case 'number_submission_overtime':
          result += 'number_request|';
          break;
        case 'submission_date':
          result += 'date_request|';
          break;
      }

      switch (order) {
        default:
          break;
        case 'ascend':
          result += 'asc';
          break;
        case 'descend':
          result += 'desc';
          break;
      }
    }

    return result;
  }

  getAll(params) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/overtime/approval"
    return API.get(URL, { params })
  }

  export(params) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/overtime/approval/export"
    return API.get(URL, { params })
  }

  approve(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/overtime/approval"
    return API.post(URL, body)
  }
}