import { TableDaysValueType } from "../../../pages/hr/attendance/configuration/master-working-schedule/template/AddMasterWorkingScheduleTemplate.interface";

export function MapperSaveMasterWorkingSchedule(value) {
  const tolerance_absent = Number(value.attendance_tolerance)
  const result = {
    hira_absent: [
      {
        name: value.name?.trim(),
        absent_option: "toleransi",
        tolerance_absent,
        sanctions_absent: null,
        absent_rule: null,
        tolerance_absent_rule: true,
        sanctions_absent_rule: null,
        is_radius: value.is_radius,
        radius: mapperValueRadius(value.radius),
        days_of_work: mapperDaysOfWork(value.table_days),
      }
    ]
  }

  return result
}

export function mapperDaysOfWork(daysOfWork: TableDaysValueType) {
  const result = []
  for (const key in daysOfWork) {
    result.push({
      day: key.toLocaleLowerCase(),
      start_time: formatTimeToFloat(daysOfWork[key].start),
      end_time: formatTimeToFloat(daysOfWork[key].end),
      break_time: formatTimeToFloat(daysOfWork[key].break),
    })
  }

  return result
}

export function formatTimeToFloat(time) {
  if (!time) {
    return null
  }
  const result = time.minutes() / 60 + time.hour()
  return result
}

function mapperValueRadius(radius) {
  switch(radius) {
    case 'Check In & Out':
      return 'check_in_out'
    case 'Check In':
      return 'check_in'
    case 'Check Out':
      return 'check_out'
    default:
      return null
  }
}