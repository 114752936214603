import Constant from "../../../../../config/Constants";
import API from "../../../../../services/API.service";
import { loanConfigMapper } from '../../../../../@core/mapper/loan/GetLoanConfigurationList'

export default class LoanConfigurationListUsecase {

  getLoanConfigList(params) {
    const URL = Constant.URL_BASE_LOAN + "/v3/configuration/loan"
    return API.get(URL, { params }).then((res: any) => 
      loanConfigMapper(res.data)
    )
  }

  export(params) {
    const URL =  Constant.URL_BASE_LOAN + "/v3/configuration/loan/export"
    return API.get(URL, { params })
  }

  deactiveLoanConfiguration(body) {
    const URL = Constant.URL_BASE_LOAN + '/v3/configuration/loan/active';
  return API.put(URL, body);
  }
}