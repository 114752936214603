import moment from "moment";
import { GetListClaimInterface } from "./ListClaimMapper.interface";

export function getEmployeeClaimList(
  listClaim: GetListClaimInterface[],
  href: string
) {
  const result = listClaim.map((claim) => {
    return {
      key: claim.id,
      claim_number: claim.name,
      nik: claim.nik,
      employee: claim.employee_name,
      submission_date: formatSubmissionDate(claim.date_claim),
      claim_type: claim.claim_type || claim.claim_config,
      submission_total: claim.total_value?.toLocaleString(),
      status: MappingActiveState(claim.state),
      create_name: claim.create_name,
      create_date: formatCreateDate(claim.create_date),
      write_name: claim.write_name,
      write_date: formatCreateDate(claim.write_date),
      href: href + claim.id
    };
  });

  return result
}

function MappingActiveState(state) {
  switch(state) {
    case 'submit':
      return 'Pengajuan'
    case 'wait-approve2':
      return "Persetujuan Ke-2"
    case 'payment_rejected':
      return 'Ditolak'
    case 'rejected':
      return 'Ditolak'
    case 'cancel':
      return 'Batal'
    case 'done':
      return 'Selesai'
    case 'paid':
      return 'Dibayar'
    default:
      return ''
  }
}


function formatSubmissionDate(dateString: string) {
  if (dateString) {
    return moment(dateString).format("DD/MM/YYYY")
  }

  return ''
}

function formatCreateDate(dateString: string) {
  if (dateString) {
    return moment(dateString).format("DD/MM/YYYY HH:mm:ss")
  } 

  return ''
}