import Constant from "../../../../../config/Constants";
import API from "../../../../../services/API.service";

export default class ListLeaveUsageReportUseCase {
  getAll(params) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/leave/request/report"
    return API.get(URL, { params })
  }

  export(params) {
    const URL =  Constant.URL_BASE_ATTENDANCE + "/v3/leave/request/report/export"
    return API.get(URL,{ params })
  }
}