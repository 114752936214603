import moment from "moment";

export function getDepartmentListMapper(departmentList) {
  const result = [];

  if (departmentList.length) {
    departmentList.forEach((dept) => {
      result.push({
        key: dept.id,
        title: dept.name,
        description: dept.company_name,
        department_parent: dept.department_parent,
        first_row: dept.department_parent || "N/A",
        second_row: `(${dept.amount_employee})`,
        status: dept.status ? "Aktif" : "Non-aktif",
        third_row: formatDateDepartment(dept.write_date),
        create_name: dept.create_name,
        create_date: formatDateDepartment(dept.create_date),
        write_name: dept.write_name,
        write_date: formatDateDepartment(dept.write_date),
      });
    });
  }

  return result;
}

export function getChildDepartmentListMapper(deptList) {
  const result = [];

  if (deptList.length) {
    deptList.forEach((dept) => {
      result.push({
        key: dept.id,
        name: dept.name,
      });
    });
  }

  return result;
}

export function getDetailDepartmetMapper(departmentDetail) {
  return {
    id: departmentDetail.id,
    name: departmentDetail.name,
    parent_id: departmentDetail.parent_id,
    is_active: departmentDetail.active,
  }
}

function formatDateDepartment(dateString: string) {
  return moment(dateString).format("DD/MM/YYYY HH:mm:ss")
}