export const ClaimConfigConstant = {
    TABLE_HEADER: [
      {
        title: 'Nama Klaim',
        key: 'name',
        dataIndex: 'name',
        width: 150,
      },
      {
        title: 'Tipe Plafon',
        key: 'plafon_type',
        dataIndex: 'plafon_type',
        width: 150,
      },
      {
        title: 'Nilai Plafon',
        key: 'plafon_value',
        dataIndex: 'plafon_value',
        width: 120,
      },
      { title: 'Periode Reset',
        key: 'plafon_reset',
        dataIndex: 'plafon_reset',
        width: 150 
      },
      { title: 'Aktif Ketika', key: 'active_when', dataIndex: 'active_when', width: 130 },
      {
        title: 'Berlaku Untuk',
        key: 'config_type',
        dataIndex: 'config_type',
        width: 120
      },
      { title: 'Status', key: 'is_active', dataIndex: 'is_active', width: 120 },
      { title: 'Persetujuan 1', key: 'approval_1', dataIndex: 'approval_1',  width: 130 },
      { title: 'Persetujuan 2', key: 'approval_2', dataIndex: 'approval_2',  width: 130 },
      { title: 'Dibuat Oleh', key: 'create_name', dataIndex: 'create_name', width: 150 },
      { title: 'Dibuat Pada', key: 'create_date', dataIndex: 'create_date', width: 150, sorter: true, },
      { title: 'Diubah Oleh', key: 'write_name', dataIndex: 'write_name', width: 150 },
      { title: 'Diubah Pada', key: 'write_date', dataIndex: 'write_date', width: 150, sorter: true, },
      { title: 'Proses Ulang',isAction: true, key: 'action', dataIndex: 'action', width: 120 },
    ],
    SEARCH_COLUMN_KEY: [],
    SEARCH_COLUMN_COMPONENT: [
      { key: 'key', componentType: "text" },
      { key: 'name', componentType: "text" },
      { key: 'plafon_type', componentType: "select", options: [
        { label: 'Semua', value: '' },
        { label: 'Terbatas', value: 'terbatas' },
        { label: 'Tidak Terbatas', value: 'tidak_terbatas' },
      ]},
      { key: 'plafon_reset', componentType: "select", options: [
        { label: 'Semua', value: '' },
        { label: '1 Bulanan', value: '1-bulanan' },
        { label: '3 Bulanan', value: '3-bulanan' },
        { label: '6 Bulanan', value: '6-bulanan' },
        { label: 'Tahunan', value: 'tahunan' },
      ]},
      { key: 'active_when', componentType: 'select', options: [
        { label: 'Semua', value: '' },
        { label: 'Mulai Bergabung ', value: 'mulai_bergabung' },
        { label: 'Bergabung di ke - n', value: 'bergabung_dibulan' },
      ]},
      { key: 'config_type', componentType: 'select', options: [
        { label: 'Semua', value: '' },
        { label: 'Jabatan', value: 'job' },
        { label: 'Karyawan', value: 'employee' },
      ]},
      { key: 'is_active', componentType: 'select', options: [
        { label: 'Semua', value: '' },
        { label: 'Aktif', value: 'aktif' },
        { label: 'Non Aktif', value: 'tidak aktif' },
      ]},
      { key: 'approval_1', componentType: 'select', options: [
        { label: 'Semua', value: '' },
        { label: 'Tanpa Persetujuan ', value: 'tanpa_persetujuan' },
        { label: 'Atasan Langsung ', value: 'atasan_langsung' },
        { label: 'Jabatan', value: 'jabatan' },
      ]},
      { key: 'approval_2', componentType: 'select', options: [
        { label: 'Semua', value: '' },
        { label: 'Tanpa Persetujuan ', value: 'tanpa_persetujuan' },
        { label: 'Atasan Langsung ', value: 'atasan_langsung' },
        { label: 'Jabatan', value: 'jabatan' },
      ]}
    ],
    DEFAULT_EMPTY_DATA_SOURCE: [
      {
        key: 'empty-data',
        name: '',
        plafon_type: '',
        plafon_reset: '',
        active_when: '',
        config_type: '',
        is_active: '',
        approval_1: '',
        approval_2: ''
      },
    ],
  }