import Constant from '../../../../../config/Constants';
import API from '../../../../../services/API.service';

export default class HrAddMasterEmployeeUsecase {
  update(body) {
    const URL = Constant.URL_BASE_USER + `/v3/hak-akses`;
    return API.put(URL, body);
  }

  createUser(body) {
    const URL = Constant.URL_BASE_USER + `/v3/hak-akses/${body.id}/user`;
    return API.post(URL, body);
  }

  getAllMenu() {
    const URL = Constant.URL_BASE_USER + "/v3/menu/all"
    return API.get(URL)
  }

  getAccessDetail(id) {
    const URL = Constant.URL_BASE_USER + "/v3/hak-akses/" + id;
    return API.get(URL)
  }

  getExisitingUser(id, params) {
    const URL = Constant.URL_BASE_USER + "/v3/hak-akses/" + id + '/user';
    return API.get(URL, { params })
  }
  getUserList() {
    const params = {
      is_hak_akses: true
    }
    const URL = Constant.URL_BASE_USER + "/v3/lov/users" 
    return API.get(URL, {params})
  }

  getAccessList() {
    const URL = Constant.URL_BASE_USER + "/v3/lov/hak-akses" 
    return API.get(URL)
  }

  getHierarchyList(params) {
    const URL = Constant.URL_BASE_USER + "/v3/hak-akses/hierarchy" 
    return API.get(URL, { params })
  }

}
