import { useEffect, useState } from 'react';
import LandingListTemplate from '../../../../../../templates/landing-list-template/LandingListTemplate';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import HrLeaveService from '../../../HrLeaveService';
import { LeaveConstant } from '../../../../../../config/Leave.constant';
import { LandingListTemplatePagination } from '../../../../../../templates/landing-list-template/LandingListTemplate.interface';
import MySubmissionLeaveUseCaseImpl from './HrMySubmissionLeave.usecase.impl';
import { GetLeavesDTO } from '../../../../../../@core/interface/leave/GetLeaves.interface';
import { LeaveTypeObjById } from '../../../../../../@core/entity/LeaveType.entity';

const leaveService = new HrLeaveService();
const useCaseImpl = new MySubmissionLeaveUseCaseImpl();

let page = 1;
let loadPagination = true;
let sort = 'name|desc';
let name = '';
let nik = '';
let employee = '';
let leave_all = null;
let leave_type_id = [];
let leaveTypes: LeaveTypeObjById = {};
let selectionLeaveId = [];
let leave_config

export default function HrMySubmissionLeave() {
  const history = useHistory();
  let tableHeader: any = LeaveConstant.TABLE_HEADER;
  let searchColumnKey = LeaveConstant.SEARCH_COLUMN_KEY;
  const limit = 10;

  let [pagination, setPagination] = useState<LandingListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });
  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([]);
  let [tableScroll, setTableScroll] = useState({ y: 200 });
  let [disableDeleteButton, setDisableDeleteButton] = useState(true);
  let [visibleWarningModal, setVisibleWarningModal] = useState(false);
  let [disableExportButton, setDisableExportButton] = useState(false);

  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: true };

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {
    tableHeader[1].sorter = false;
    tableHeader[2].sorter = false;
    searchColumnKey.splice(2, 2);

    loadList();

    const clientHeight = document.getElementById('my-submission-leave-page').clientHeight;
    setTableScroll({ y: clientHeight - 220 });
  }

  function loadList() {
    setIsLoading(true);

    const params: GetLeavesDTO = {
      page,
      limit,
      sort,
      name,
      leave_type_id,
      leave_all,
      leave_config,
      with_meta: true,
      // state: ['draft', 'approve1', 'approve2', 'approved', 'done', 'reject', 'cancel'],
    };

    useCaseImpl.getSubmissionLeaves(params, leaveTypes, '/v2/app/hr-leave/my-submission/').then(
      (res) => {
        setIsLoading(false);

        const content = res.content;
        const metaData = res.metaData;

        const resultDataSource = content ? content : LeaveConstant.DEFAULT_EMPTY_DATA_SOURCE;
        setDataSource([...resultDataSource]);
        updatePaginationState(metaData.total_records);

        loadPagination = true;
        setDisableExportButton(content ? false : true);
      },
      (err) => {
        setIsLoading(false);
        setDataSource([...LeaveConstant.DEFAULT_EMPTY_DATA_SOURCE]);
        loadPagination = true;
        setDisableExportButton(true);
      },
    );
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    const outputSort = output.sort;
    const outputSearchColumn = output.searchColumn;

    processSort(outputSort);
    processSearchColumn(outputSearchColumn);
    processSelection(output.selection);
    processPagination(outputPagination);
  };

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  function processSort(outputSort) {
    if (outputSort && leaveService.convertOutputSortToEndPointFormat(outputSort) !== sort) {
      page = 1;
      loadPagination = false;

      sort = leaveService.convertOutputSortToEndPointFormat(outputSort);
      loadList();
    }
  }

  function processSearchColumn(outputSearchColumn) {
    let isLoadList = false;
    
    if (outputSearchColumn) {
      if (
        outputSearchColumn.numberSubmissionLeave !== undefined &&
        outputSearchColumn.numberSubmissionLeave !== name
      ) {
        name = outputSearchColumn.numberSubmissionLeave;
        isLoadList = true;
      }

      if (
        outputSearchColumn.employeeNumber !== undefined &&
        outputSearchColumn.employeeNumber !== nik
      ) {
        nik = outputSearchColumn.employeeNumber;
        isLoadList = true;
      }

      if (
        outputSearchColumn.employeeName !== undefined &&
        outputSearchColumn.employeeName !== employee
      ) {
        employee = outputSearchColumn.employeeName;
        isLoadList = true;
      }

      if (
        outputSearchColumn.isLeaveAll !== undefined &&
        outputSearchColumn.isLeaveAll !== leave_all
      ) {
        leave_all = outputSearchColumn.isLeaveAll
        isLoadList = true
      }

      if (
        outputSearchColumn.leaveConfig !== undefined &&
        outputSearchColumn.leaveConfig !== leave_config
      ) {
        leave_config = outputSearchColumn.leaveConfig
        isLoadList = true
      }

      if (
        outputSearchColumn.leaveType !== undefined &&
        leaveService.convertLeaveTypeIdParam(outputSearchColumn.leaveType, leaveTypes).length !==
          leave_type_id.length
      ) {
        leave_type_id = leaveService.convertLeaveTypeIdParam(
          outputSearchColumn.leaveType,
          leaveTypes,
        );
        isLoadList = true;
      }

      if (isLoadList) {
        page = 1;
        loadPagination = false;
        loadList();
      }
    }
  }

  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionLeaveId) {
      selectionLeaveId = outputSelection;
      setDisableDeleteButton(selectionLeaveId.length ? false : true);
    }
  }

  const addButton = {
    onClick: () => {
      history.push('/app/hr-leave/my-submission/new/');
    },
    disabled: isLoading,
  };

  const deleteButton = {
    name: "Batalkan",
    onClick: () => {
      setVisibleWarningModal(true);
    },
    disabled: disableDeleteButton || isLoading,
  };

  function deleteToEndPoint() {
    setIsLoading(true);
    setVisibleWarningModal(false);

    useCaseImpl.cancelLeave(selectionLeaveId).then(
      (res: any) => {
        setIsLoading(false);

        const data = res.data;
        if (data.status === 200) {
          page = 1;
          loadList();
          setDisableDeleteButton(true);
        }
        setVisibleWarningModal(false);
      },
      (err) => {
        setIsLoading(false);
        setVisibleWarningModal(false);
      },
    );
  }

  const exportButton = {
    onClick: () => {
      setIsLoading(true);

      const params: GetLeavesDTO = {
        id: selectionLeaveId,
        sort,
        name,
        leave_type_id,
        leave_config,
        leave_all,
        with_meta: true,
      };
      useCaseImpl.exportSubmissionLeaves(params).then(
        (res: any) => {
          const data = res.data;
          if (data.status === 200) {
            leaveService.createAndExecuteHref(data.content.url);
          }
          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
        },
      );
    },
    disabled: disableExportButton || isLoading,
  };

  return (
    <div id="my-submission-leave-page" style={{ height: 'calc(100vh - 100px)' }}>
      <Modal
        title="Hapus Data"
        cancelText="BATALKAN"
        visible={visibleWarningModal}
        cancelButtonProps={{ type: 'ghost' }}
        onOk={deleteToEndPoint}
        onCancel={() => setVisibleWarningModal(false)}
      >
        <p>Apakah yakin ingin menghapus data-data ini?</p>
      </Modal>
      <LandingListTemplate
        addButton={addButton}
        deleteButton={deleteButton}
        exportButton={exportButton}
        tableHeader={tableHeader}
        tableData={tableData}
        tablePagination={pagination}
        tableOnChange={tableOnChange}
        isLoading={isLoading}
        tableScroll={tableScroll}
      />
    </div>
  );
}
