import { FormatDateWriteDate } from "../leave/MassLeave.mapper";
import { ColumnScheduleWorkEmployee, ScheduleWorkEmployeeListResp } from "./EmployeeWorkingScheduleMapper.interface";

const moment = require('moment')

export function ScheduleWorkListMapper(
  scheduleWorkList: ScheduleWorkEmployeeListResp[]
): ColumnScheduleWorkEmployee[] {
  const content = scheduleWorkList ? scheduleWorkList : null
  let contentMap: ColumnScheduleWorkEmployee[]

  if (content) {
    contentMap = content.map((data) => {
      const startDate = data.date_start_schedule_work ?
        moment(data.date_start_schedule_work).format('YYYY-MM-DD') : null
      const endDate = data.date_end_schedule_work ?
        moment(data.date_end_schedule_work).format('YYYY-MM-DD') : null

      const result = {
        key: data.id,
        id: data.id,
        nama_jadwal_kerja: data.nama_jadwal_kerja,
        employee_id: data.employee_id,
        employee_name: data.employee_name,
        employee_nik: data.employee_name.split(" - ")[0],
        date_start_schedule_work: startDate,
        date_end_schedule_work: endDate,
        hira_absent_id: data.hira_absent_id,
        name: data.name,
        history_id: data.history_id,
        company_id: data.company_id,
        create_date_string: data.view_create_date,
        create_date: data.create_date,
        create_uid: data.create_uid,
        create_name: data.create_name,
        write_date_string: data.view_write_date,
        write_date: data.write_date,
        write_uid: data.write_uid,
        write_name: data.write_name,
      }

      return result
    })
  }

  return contentMap
}

export function ScheduleWorkMapper(
  scheduleWorkList: ScheduleWorkEmployeeListResp[],
  pages: number
): ColumnScheduleWorkEmployee[] {
  const content = scheduleWorkList ? scheduleWorkList : null
  let contentMap: ColumnScheduleWorkEmployee[]

  if (content) {
    contentMap = content.map((data, index) => {
      const startDate = data.date_start_schedule_work ?
        moment(data.date_start_schedule_work).format('YYYY-MM-DD') : null
      const endDate = data.date_end_schedule_work ?
        moment(data.date_end_schedule_work).format('YYYY-MM-DD') : null

      const result = {
        key: pages * 50 + index,
        id: data.id,
        nama_jadwal_kerja: data.hira_absent_name,
        employee_id: data.employee_id,
        employee_name: data.employee_name,
        date_start_schedule_work: startDate,
        date_end_schedule_work: endDate,
        hira_absent_id: data.hira_absent_id,
        employee_nik: data.nik,
        name: data.name,
        history_id: data.history_id,
        company_id: data.company_id,
        create_date: data.create_date,
        create_uid: data.create_uid,
        create_name: data.create_name,
        write_date: data.write_date,
        write_uid: data.write_uid,
        write_name: data.write_name,
      }

      return result
    })
  }

  return contentMap
}