import { LeaveTypeObjById } from '../../entity/LeaveType.entity';
import { JobListObjById } from '../../entity/Job.entity';
import { GetJobListDTO } from '../../interface/job/GetJobList.interface';
import { GetEmployeeLeaveConfigListDTO } from '../../interface/leave/GetEmployeeLeaveConfigList.interface';
import { EmployeeLeaveConfigOption } from '../../entity/EmployeeLeaveConfig.entity';
import {
  GetLeaveDurationDTO,
  GetLeaveDurationResp,
} from '../../interface/leave/GetLeaveDuration.interface';
import {
  CreateLeaveRequestDTO,
  CreateLeaveRequestResp,
} from '../../interface/leave/CreateLeaveRequest.interface';
import {
  UploadAllImageDTO,
  UploadAllImageResp,
} from '../../interface/asset/UploadAllImage.interface';

export default abstract class AddSubmissionLeaveUseCase {
  abstract getJobList(params: GetJobListDTO): Promise<JobListObjById>;
  abstract getLeaveTypes(): Promise<LeaveTypeObjById>;
  abstract getEmployeeLeaveConfigList(
    params: GetEmployeeLeaveConfigListDTO,
  ): Promise<EmployeeLeaveConfigOption[]>;
  abstract getLeaveDuration(params: GetLeaveDurationDTO): Promise<GetLeaveDurationResp>;
  abstract uploadAllImage(params: UploadAllImageDTO): Promise<UploadAllImageResp>;
  abstract submitLeave(params: CreateLeaveRequestDTO): Promise<CreateLeaveRequestResp>;
}
