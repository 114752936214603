export const FormTemplateConstant = {
  ITEMS: [
    {
      title: "Langkah 1",
      description: "Informasi Perusahaan",
    },
    {
      title: "Langkah 2",
      description: "Alamat & Kontak Perusahaan",
    },
    {
      title: "Langkah 3",
      description: "Penanggung Jawab",
    }
  ],
  MOCK_PROVINCE: [{ key: "Jawa Tengah", label: "Jawa Tengah", value: "Jawa Tengah" }, { key: "Jawa Barat", label: "Jawa Barat", value: "Jawa Barat" }],
  MOCK_DISTRICT: [{ key: "Semarang", label: "Semarang", value: "Semarang" }],
  MOCK_SUBDISTRICT: [{ key: "Tangerang", label: "Tangerang", value: "Tangerang" }],
}