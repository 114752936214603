import { useEffect, useState } from "react";
import AddEmployeeWorkingScheduleTemplate from "../template/AddEmployeeWorkingScheduleTemplate";
import AddEmployeeWorkingScheduleUseCase from "./AddEmployeeWorkingSchedule.usecase";
import { ToolbarDetailActionButton, ToolbarDetailDropdownButton } from "../../../../component/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface";
import ToastService from "../../../../../../services/Toast.service";
import { MapperSaveScheduleWork } from "../../../../../../@core/mapper/attendance/SaveEmployeeWorkingSchedule.mapper";
import LocalNotificationService from "../../../../../../services/LocalNotification.service";
import { constant } from "../template/AddEmployeeWorkingScheduleTemplate.constant";

const usecase = new AddEmployeeWorkingScheduleUseCase()
const toastService = new ToastService()
const localNotificationService = new LocalNotificationService();

export default function AddEmployeeWorkingSchedule() {
  // state view
  const [isLoading, setIsLoading] = useState(false)
  const [jobList, setJobList] = useState([])
  const [scheduleList, setScheduleList] = useState([])

  // constant view
  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Jadwal Kerja Karyawan',
        href: "/v2/app/attendance/employee-working-schedule",
      },
      {
        clickable: false,
        displayPathName: 'Baru',
        href: "/v2/app/attendance/employee-working-schedule/new/",
      }
    ],
  };

  const submitButton = { active: true, disabled: isLoading };
  const firstActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: false,
    onClick: () => {
      const back = window.confirm("Anda Ingin Kembali")
      if (back) {
        window.location.href = "/v2/app/attendance/employee-working-schedule"
      }
    },
  };

  useEffect(() => {
    GetJobList()
    getScheduleWork()
  }, [])
  
  async function GetJobList() {
    const result = await usecase.getJob()
    setJobList([...result])
    return result
  }

  function getScheduleWork() {
    setIsLoading(true)
    const params = {
      page: 1,
      limit: 30,
      with_meta: false,
    }

    usecase.getMasterSchedule(params).then((res: any) => {
      setIsLoading(false)
      const content = res.data.content;

      const resultDataSource = content && content.length ? mapperOptions(content) 
        : constant.DEFAULT_EMPTY_DATA_SOURCE
      
        setScheduleList([...resultDataSource])
    }, (err) => {
      setIsLoading(false)
      toastService.error(err)
    })
  }

  function mapperOptions(content) {
    return content.map((c) => {
      return { key: c.id, label: c.name, value: c.id }
    })
  }

  const onFieldChange = (e) => {
    if (e.length === 1) {
      const fieldName = e[0].name[0];
      const value = e[0].value;

      switch (fieldName) {
        default:
          break;
      }
    }
  }

  const onFinish = (value) => {
    setIsLoading(true)
    const body = MapperSaveScheduleWork(value, scheduleList)
    
    usecase.save(body).then(
      (res) => {
        const data = res.data
        if (data.code === 200) {
          const message = data.message
          localNotificationService.openSuccess(message, '', 1)
        }
        setTimeout(() => {
          window.location.href = "/v2/app/attendance/employee-working-schedule"
        }, 1500)
      }
    ).catch((err) => {
      toastService.error(err)
      setIsLoading(false)
    })
  }

  return (
    <div className="add-employee-working-schedule">
      <AddEmployeeWorkingScheduleTemplate 
        breadcrumbConf={breadcrumbConf}
        submitButton={submitButton}
        firstActionButton={firstActionButton}
        secondActionButton={{ active: false }}
        thirdActionButton={{ active: false }}
        fourthActionButton={{ active: false }}
        dropdownActionButton={{ visible: false }}
        onFinish={onFinish}
        onFieldChange={onFieldChange}
        listSchedule={scheduleList}
        listJob={jobList}
        historyId={null}
        isLoading={isLoading}
      />
    </div>
  )
}