import { useEffect, useState } from "react";
import ToastService from "../../../../../services/Toast.service";
import { useHistory } from "react-router-dom";
import FilterLoanInstallmentReportUsecase from "./FilterLoanInstallmentReport.usecase";
import FilterLoanInstallmentReportTemplate from "../template/LoanInstallmentReportTemplate";

const usecase = new FilterLoanInstallmentReportUsecase()
const toastService = new ToastService()

let getEmployee

export default function FilterLoanInstallmentReport() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false)
  const [employeeList, setEmployeeList] = useState([])

  useEffect(() => {
    initialPage();
  }, []);

  const breadcrumbConf = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Laporan Angsuran Pinjaman',
        href: null,
      },
      {
        clickable: false,
        displayPathName: 'Filter',
        href: null,
      }
    ],

  }

  async function initialPage() {
    setIsLoading(true);
    getEmployee = await usecase.getEmployeeList();
    
    setEmployeeList(getEmployee);
    setIsLoading(false);
  }

  const onFinish = (value) => {
    const selectedEmployee = employeeList.find(e => e.value === value.employee)

    const employee = selectedEmployee.label
    const loan_number = value?.loan_number
    
    
    history.push({
      pathname: "/app/hr-loan/loan-installment-report/",
      state: {
        data: { employee, loan_number }
      }
    });
  }

  return (
    <div className="filter-leave-usage-report">
      <FilterLoanInstallmentReportTemplate
        breadcrumbConf={breadcrumbConf}
        employeeList={employeeList}
        onFinish={onFinish}
        onFieldChange={null}
        isLoading={isLoading}
      />
    </div>
  )
}