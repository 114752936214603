export const CompanyConstant = {
    TABLE_HEADER: [
      {
        title: 'Kode Perusahaan',
        key: 'company_code',
        dataIndex: 'company_code',
        width: 150,
      },
      {
        title: 'Nama Perusahaan',
        key: 'name',
        dataIndex: 'name',
        width: 150,
      },
      {
        title: 'Jenis Badan Usaha',
        key: 'company_type',
        dataIndex: 'company_type',
        width: 120,
      },
      { 
        title: 'Nomor Telepon Perusahaan',
        key: 'phone_number',
        dataIndex: 'phone_number',
        width: 150 
      },
      { 
        title: 'Alamat Perusahaan',
        key: 'company_address',
        dataIndex: 'company_address',
        width: 150 
      },
      { 
        title: 'Provinsi',
        key: 'province',
        dataIndex: 'province',
        width: 150 
      },
      { 
        title: 'Kota',
        key: 'city',
        dataIndex: 'city',
        width: 150 
      },
      { 
        title: 'Kategory Usaha',
        key: 'company_category',
        dataIndex: 'company_category',
        width: 150 
      },
      { 
        title: 'Jumlah Karyawn',
        key: 'cfg_sum_employee',
        dataIndex: 'cfg_sum_employee',
        width: 150 
      },
      { 
        title: 'Nomor Rekening',
        key: 'bank_account_number',
        dataIndex: 'bank_account_number',
        width: 150 
      },
      { 
        title: 'Nama Penanggung Jawab',
        key: 'pic',
        dataIndex: 'pic',
        width: 150 
      },
      { 
        title: 'Nomor Telepon Penanggung Jawab',
        key: 'pic_phone_number',
        dataIndex: 'pic_phone_number',
        width: 150 
      },
      { 
        title: 'Email Penanggung Jawab',
        key: 'email',
        dataIndex: 'email',
        width: 150 
      },
      { 
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        width: 150 
      },
      { title: 'Tanggal Daftar', key: 'create_date', dataIndex: 'create_date', width: 150, sorter: true, },
      { title: 'Tanggal Ditolak', key: 'rejected_date', dataIndex: 'rejected_date', width: 150, sorter: true, }, 
      { title: 'Tindakan',isApproval: true, key: 'action', dataIndex: 'action', width: 200 },
    ],
    SEARCH_COLUMN_KEY: [],
    SEARCH_COLUMN_COMPONENT: [
      { key: 'key', componentType: "text" },
      { key: 'company_code', componentType: "text" },
      { key: 'company_type', componentType: "text" },
      { key: 'name', componentType: "text" },
      { key: 'company_category', componentType: "text" },
      { key: 'pic', componentType: "text" },
      { key: 'email', componentType: "text" },
      { key: 'status', componentType: "select", options: [
        { label: 'Semua', value: '' },
        { label: 'Menunggu Persetujuan', value: 'menunggu_persetujuan' },
        { label: 'Disetujui', value: 'disetujui' },
        { label: 'Ditolak', value: 'ditolak' },
      ]},
    ],
    DEFAULT_EMPTY_DATA_SOURCE: [
      {
        key: 'empty-data',
        name: '',
        company_code: '',
        company_type: '',
        company_category: '',
        pic: '',
        email: '',
        status: '',
      },
    ],
  }