import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Space, Col, Image } from 'antd';
import moment from 'moment';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import defaultAvatar from '../../../components/asset/avatar.jpeg';
import { aesGcmDecrypt } from '../../../services/Encrypter';
export default function Cards(props) {
  const history = useHistory();
  let [dataSource, setDataSource] = useState<any>(null);
  const Card = (data) => {
    switch (data.type) {
      case 'master-job':
        return (
          <Col key={data.item.key} xs={24} md={12} lg={8} xl={6}>
            <div style={{ borderRadius: 4, border: 'solid 1px #000', background: '#fff' }}>
              <Row justify="space-between" style={{ background: '#e5e5e5', padding: 10 }}>
                <Col>
                  <span style={{ fontWeight: 'bold', fontSize: 14, color: 'grey' }}>
                    {data.item.job_name}
                  </span>
                  <br />
                  <span style={{ fontWeight: 'bold', fontSize: 14, color: 'red' }}>
                    {data.item.department_name}
                  </span>
                </Col>
                <Col>
                  <Space>
                    <EditOutlined
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`/app/hr-employee/update-master-job/${data.item.key}`);
                      }}
                    />
                    <DeleteOutlined
                      onClick={(e) => {
                        e.preventDefault();
                        props.onDeactivate(data.item.key);
                      }}
                    />
                  </Space>
                </Col>
              </Row>
              <div style={{ padding: '0px 10px' }}>
                <div style={{ fontSize: 12, fontWeight: 'bold', margin: '15px 0px' }}>
                  Jumlah karyawan({data.item.sum_emp || '-'})
                </div>
                <div
                  style={{
                    background: 'red',
                    color: '#fff',
                    fontWeight: 'bold',
                    width: 'fit-content',
                    padding: '0px 5px',
                  }}
                >
                  {data.item.is_active}
                </div>
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 'bold',
                    fontStyle: 'italic',
                    margin: '15px 0px',
                  }}
                >
                  Terakhir Diubah: {moment(data.item.write_date).format('DD/MM/YYYY HH:mm:ss')}
                </div>
              </div>
            </div>
          </Col>
        );
      case 'master-employee':
        return (
          <Col key={data.item.key} xs={24} md={12} lg={8} xl={6}>
            <div style={{ borderRadius: 4, border: 'solid 1px #000', background: '#fff' }}>
              <Row justify="space-between" style={{ background: '#e5e5e5', padding: 10 }}>
                <Col>
                  <span style={{ fontWeight: 'bold', fontSize: 14, color: 'grey' }}>
                    {data.item.job_name}
                  </span>
                  <br />
                </Col>
                <Col>
                  <Space>
                    <EditOutlined
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`/app/hr-employee/update-master-employee/${data.item.key}`);
                      }}
                    />
                  </Space>
                </Col>
              </Row>

              <Row style={{ marginTop: 15 }}>
                <Col style={{ width: '30%' }}>
                  <div>
                    <Image
                      width={80}
                      height={80}
                      style={{
                        objectFit: 'cover',
                        objectPosition: 'top center',
                        borderRadius: '50%',
                        marginLeft: 10,
                      }}
                      src={data.item.decryptedFoto || defaultAvatar}
                    />
                  </div>
                </Col>
                <Col style={{ width: '70%' }}>
                  <div style={{ fontSize: 12, fontWeight: 'bold', margin: '5px 5px' }}>
                    {data.item.name}
                  </div>
                  <div style={{ fontSize: 12, fontWeight: 'bold', margin: '0px 5px 15px 5px' }}>
                    {data.item.category}
                  </div>
                  <div
                    style={{
                      fontSize: 12,
                      fontWeight: 'bold',
                      fontStyle: 'italic',
                      margin: '5px 5px',
                    }}
                  >
                    Terakhir Diubah:
                    <br /> {data.item.write_date}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        );
    }
  };

  useEffect(() => {
    decriptImage(props.source);
  }, [props.source]);

  async function decriptImage(source) {
    const temp = await Promise.all(
      !!source &&
        source.map(async (item) => {
          let tempFoto = null;
          if (item.file_foto) {
            tempFoto = await aesGcmDecrypt(item.file_foto);
          }
          return {
            ...item,
            decryptedFoto: tempFoto,
          };
        }),
    );
    setDataSource(temp);
  }

  return (
    <div
      style={{
        position: 'relative',
        flexWrap: 'wrap',
        overflowY: 'scroll',
        height: props?.scroll?.y + 100 || 'auto',
      }}
    >
      <Row gutter={[10, 10]}>
        {dataSource &&
          dataSource.map((item) => {
            return <Card item={item} type={props.type} />;
          })}
      </Row>
    </div>
  );
}
