import React, { useEffect, useState } from 'react';
import { Form, Button, Menu, Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import FormBuilder from 'antd-form-builder';
import { useHistory } from 'react-router-dom';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import HrUpdateMasterJobUsecase from './MasterJobUpdate.usecase';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import PageLoader from '../../../../../components/loader/PageLoader';

const useCaseImpl = new HrUpdateMasterJobUsecase();
const localNotificationService = new LocalNotificationService();
let jobId = '';

export default function HrAddMasterJob({ match }) {
  jobId = match.params.jobId;
  const history = useHistory();
  const [form] = Form.useForm();
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [initialData, setInitialData] = useState<any>({});
  const [jobList, setJobList] = useState([]);
  const [deptList, setDeptList] = useState([]);
  const [AccessList, setAccessList] = useState([]);
  const [isActive, setIsActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false);

  const [breadcrumbConf, setBreadcrumbConf] = useState<any>({
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Master Jabatan',
        href: '/v2/app/hr-employee/list-master-job',
      },
      { clickable: false, displayPathName: '' },
    ],
  });

  const meta: any = {
    columns: 2,
    fields: [
      {
        key: 'job_name',
        label: 'Nama Jabatan',
        rules: [{ required: true, whitespace: true, message: 'Nama Jabatan harus diisi' }],
      },
      {
        key: 'department',
        label: 'Departemen',
        widget: 'select',
        required: true,
        message: 'Departemen harus dipilih',
        options: deptList,
        widgetProps: {
          showSearch: true,
        },
      },
      {
        key: 'job_atasan',
        label: 'Jabatan Atasan',
        widget: 'select',
        options: jobList,
        widgetProps: {
          showSearch: true,
        },
        onChange: async (value) => {
          const temp = JSON.parse(value).id;
          const getEmp = await useCaseImpl.getEmpl(temp);
          if (getEmp && getEmp.length > 0) {
            form.setFieldsValue({
              atasan_langsung: getEmp[0].label,
            });
          }
          forceUpdate();
        },
      },
      {
        key: 'atasan_langsung',
        label: 'Nama Atasan Langsung',
        readOnly: true,
      },

      {
        key: 'access',
        label: 'Hak Akses',
        widget: 'select',
        forwardRef: true,
        options: AccessList,
      },
      {
        key: 'description',
        label: 'Deskripsi Pekerjaan',
      },
    ],
  };

  useEffect(() => {
    setIsLoading(true);
    initialPage();
  }, []);

  async function initialPage() {
    setIsLoading(true);
    const getJob = await useCaseImpl.getJob(jobId);
    const getDept = await useCaseImpl.getDepartment();
    const getAccessList = await useCaseImpl.getAccessList();
    setJobList(getJob);
    setDeptList(getDept);
    setAccessList(getAccessList);

    const jobDetail: any = await useCaseImpl.getJobDetail(jobId);
    let tempInitial = {
      job_name: jobDetail.job_name,
      department: JSON.stringify({
        id: jobDetail.department_id,
        dept_name: jobDetail.department_name,
      }),
      job_atasan: getJob.find((x) => JSON.parse(x.value).id === jobDetail.parent_id)?.value,
      atasan_langsung: jobDetail.parent_emp_name,
      description: jobDetail.description,
      access: getAccessList.find((x) => JSON.parse(x.value).id === jobDetail.group_id)?.value,
      ...jobDetail,
    };

    const tempBreadcrumbConf = JSON.parse(JSON.stringify(breadcrumbConf));
    tempBreadcrumbConf.manualData[1].displayPathName = jobDetail?.job_name;
    setIsActive(jobDetail.is_active)
    setBreadcrumbConf(tempBreadcrumbConf);
    setInitialData(tempInitial);
    setIsLoading(false);
  }

  async function onFinish(e) {
    setIsLoading(true);
    if (e) {
      let tempParam: any = {};
      e.department && (tempParam['department_id'] = JSON.parse(e.department).id);
      e.department && (tempParam['department_name'] = JSON.parse(e.department).dept_name);
      e.job_atasan && (tempParam['parent_id'] = JSON.parse(e.job_atasan).id);
      e.access && (tempParam['group_id'] = JSON.parse(e.access).id);

      const params = {
        id: jobId,
        description: e.description,
        job_name: e.job_name.trim(),
        history_id: initialData.history_id,
        ...tempParam,
      };
      useCaseImpl.updateMasterJob(params).then(
        (res: any) => {
          const data = res.data;
          if (data.status === 200) {
            history.push('/app/hr-employee/list-master-job');
            localNotificationService.openSuccess('Master Jabatan Berhasil Diubah', '', 3);
          }
          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
        },
      );
    }
  }
  async function handleChangeStatus(status) {
    setIsLoading(true);
    const params = {
      id: [jobId],
      status: status ? 'aktif' : 'non-aktif'
    }
    useCaseImpl.changeStatus(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          const message = data.message
          localNotificationService.openSuccess(message, '', 1)
          initialPage()
        } else {
          localNotificationService.openToast('topRight', data?.error?.message, '', { color: '#fff', background: 'red' }, 3)
        }
        setIsLoading(false);
      },
      (err) => {
        localNotificationService.openToast('topRight', err, '', { color: '#fff', background: 'red' }, 3)
        setIsLoading(false);
      },
    );
  }

  const dropdownButton = {
    visible: true,
    disabled: isLoading,
    options: [
      { key: 'active', label: isActive ? 'Non-Aktifkan' : 'Aktifkan', onClick: () => handleChangeStatus(!isActive) },
    ]
  };

  const DropdownButton = () => {
    const menu =
      dropdownButton && dropdownButton.visible ? (
        <Menu>
          {dropdownButton.options.map((opt: any) => (
            <Menu.Item disabled={opt.disabled} onClick={() => opt.onClick()} key={opt.key}>
              {opt.label}
            </Menu.Item>
          ))}
        </Menu>
      ) : null;

    return dropdownButton && dropdownButton.visible ? (
      <Dropdown disabled={dropdownButton.disabled} overlay={menu}>
        <Button type="primary">
          Tindakan <DownOutlined />
        </Button>
      </Dropdown>
    ) : null;
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div
      className="update-master-job"
      style={{ position: 'relative', height: 'calc(100vh - 100px)' }}
    >
      <Form
        form={form}
        initialValues={initialData}
        layout="horizontal"
        onFinish={onFinish}
        onValuesChange={forceUpdate}
      >
        <div>
          <div className="form-wrapper">
            <div>
              <Breadcrumb breadcrumbConf={breadcrumbConf} />
              <Form.Item className="form-footer" style={{ width: '100%', marginTop: 20 }}>
                <div
                  style={{
                    margin: '20px 0px 20px 0px',
                    display: 'flex',
                    width: '50%',
                    justifyContent: 'space-between',
                  }}
                >
                  <Space>
                    <Button type="primary" onClick={() => form.submit()} disabled={isLoading}>
                      Simpan
                    </Button>

                    <Button onClick={() => history.push('/app/hr-employee/list-master-job')}>
                      Batal
                    </Button>
                  </Space>
                  <DropdownButton />
                </div>
              </Form.Item>
              <div
                style={{
                  border: '1px solid #000',
                  padding: 10,
                  overflowY: 'scroll',
                  height: '70vh',
                }}
              >
                <FormBuilder form={form} meta={meta} />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
