import getEmployeeMe from "../../../../../@core/interaction/employee/GetEmployeeMe.interaction";
import Constant from "../../../../../config/Constants"
import API from "../../../../../services/API.service"

export default class MySubmissionOvertimeUseCase {
  convertOutputSortToEndPointFormat(tableOutputSort) {
    let result = '';
    const columnKey = tableOutputSort.columnKey;
    const order = tableOutputSort.order;

    if (order) {
      switch (columnKey) {
        default:
          break;
        case 'number_submission_overtime':
          result += 'number_submission_overtime|';
          break;
        case 'submission_date':
          result += 'submission_date|';
          break;
      }

      switch (order) {
        default:
          break;
        case 'ascend':
          result += 'asc';
          break;
        case 'descend':
          result += 'desc';
          break;
      }
    }

    return result;
  }

  getEmployeeMe() {
    return getEmployeeMe().then((res: any) => {
      return res.data.content
    })
  }

  getAll(params) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/overtime/request/me/list"
    return API.get(URL, { params })
  }

  export(params) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/overtime/request/export"
    return API.get(URL, { params })
  }

  active(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/overtime/request/set-state"
    return API.put(URL, body)
  }
}