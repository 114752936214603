import Constant from '../../../../../config/Constants';
import API from '../../../../../services/API.service';

export default class HrAddMasterEmployeeUsecase {
  create(body) {
    const URL = Constant.URL_BASE_USER + `/v3/hak-akses`;
    return API.post(URL, body);
  }

  getAllMenu() {
    const URL = Constant.URL_BASE_USER + "/v3/menu/all"
    return API.get(URL)
  }

  getAccessList() {
    const URL = Constant.URL_BASE_USER + "/v3/lov/hak-akses" 
    return API.get(URL)
  }

  getUserList() {
    const params = {
      is_hak_akses: true
    }
    const URL = Constant.URL_BASE_USER + "/v3/lov/users" 
    return API.get(URL, {params})
  }

  getHierarchyList(params) {
    const URL = Constant.URL_BASE_USER + "/v3/hak-akses/hierarchy" 
    return API.get(URL, { params })
  }

}
