import './StepsLeaveDetail.less';
import { RightOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { LeaveStatus } from '../../../../../../../@core/entity/Leave.entity';

const steps = [
  'Pengajuan',
  'Persetujuan Satu',
  'Persetujuan Ke-2',
  'Disetujui',
  'Ditolak',
  'Batal',
  'Selesai',
];

interface PropsType {
  activeStep: LeaveStatus;
}

export default function StepsLeaveDetail({ activeStep }: PropsType) {
  const ActiveStep = ({ stepName }) => {
    return (
      <div className="active-step">
        <span className="active-step-container">{stepName}</span>
        <div className="arrow-right"></div>
      </div>
    );
  };

  const Step = ({ stepName, index }) => {
    return (
      <div className="step">
        <span className="step-name">{stepName}</span>

        {index !== 6 && <RightOutlined />}
      </div>
    );
  };

  const RenderStep = ({ stepName, index }) => {
    return stepName === activeStep ? (
      <ActiveStep stepName={stepName} />
    ) : (
      <Step stepName={stepName} index={index} />
    );
  };

  return (
    <div id="hira-step-leave-detail">
      <Space size="middle">
        {steps.map((element, index) => (
          <RenderStep key={element} stepName={element} index={index} />
        ))}
      </Space>
    </div>
  );
}
