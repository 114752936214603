import { useEffect, useState } from 'react';
import { Row, Modal, Table } from 'antd';
import moment from 'moment';
import LandingListTemplate from '../../../../../templates/landing-list-template/LandingListTemplate';
import { useHistory, useLocation } from 'react-router-dom';
import { ClaimReportConstant } from './ClaimReport.constant';
import { LandingListTemplatePagination } from '../../../../../templates/landing-list-template/LandingListTemplate.interface';
import HrEmployeeClaimReportUseCaseImpl from './ClaimReport.usecase';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import { IDRFormat } from "../../../../../@core/interaction/currency/IDRFormat";

const useCaseImpl = new HrEmployeeClaimReportUseCaseImpl();
const localNotificationService = new LocalNotificationService();

let page = 1;
let loadPagination = true;
let sort = 'write_date|desc';
let limit_type = '';
let employee_id = [];
let job_id = [];
let claim_config_id = [];
let is_expired = '';
export default function HrEmployeeClaimReport() {
  const history = useHistory();
  const location: any = useLocation();

  const tableHeader: any = ClaimReportConstant.TABLE_HEADER;
  const searchColumnKey = ClaimReportConstant.SEARCH_COLUMN_KEY;
  const searchColumnComponent = ClaimReportConstant.SEARCH_COLUMN_COMPONENT;
  const limit = 10;

  let [pagination, setPagination] = useState<LandingListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });
  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([]);
  let [dataDetail, setDataDetail] = useState([]);
  let [tableScroll, setTableScroll] = useState({ y: 200 });
  let [visibleModalClaim, setVisibleModalClaim] = useState(false);
  let [disableExportButton, setDisableExportButton] = useState(false);

  const tableData = {
    searchColumnKey,
    dataSource,
    tableRowSelectionActive: false,
    searchColumnComponent,
  };

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: false,
        displayPathName: 'Laporan Penggunaan Klaim',
      },
    ],
  };

  const CLAIM_TABLE_HEADER = [
    {
      title: 'Nomor Pengajuan Klaim',
      dataIndex: 'no_claim_req',
      width: 150
    },
    {
      title: 'Tanggal Pengajuan',
      dataIndex: 'date_claim',
      width: 150,
      render: (value) => {
        return moment(value).format('DD-MM-YYYY')
      }
    },
    {
      title: 'Tipe Klaim',
      dataIndex: 'claim_type',
      width: 150
    },
    {
      title: 'Keterangan',
      dataIndex: 'desc',
      width: 150
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width: 150,
      render: (value) => {
        return IDRFormat(value)
      }
    },
  ]

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {
    loadList();
    const clientHeight = document.getElementById('claim-report-page').clientHeight;

    setTableScroll({ y: clientHeight - 220 });
  }

  function loadList() {
    setIsLoading(true);
    const tempFilter: any = location.state?.data;
    if (tempFilter) {
      employee_id = tempFilter?.list_employee;
      job_id = tempFilter?.list_job;
      claim_config_id = tempFilter?.list_claim_config;
      limit_type = tempFilter?.limit_type;
      is_expired = tempFilter?.is_expired;
    }
    const params = {
      page,
      limit,
      limit_type,
      is_expired,
      employee_id,
      job_id,
      claim_config_id,
      with_meta: true,
    };
    useCaseImpl.getClaimReportList(params).then(
      (res: any) => {
        setIsLoading(false);
        const content = res.content;
        const metaData = res.metaData;
        const resultDataSource = content ? content : ClaimReportConstant.DEFAULT_EMPTY_DATA_SOURCE;
        setDataSource([...resultDataSource]);
        updatePaginationState(metaData.total_records);

        loadPagination = true;
        setDisableExportButton(content ? false : true);
      },
      (err) => {
        setIsLoading(false);
        setDataSource([...ClaimReportConstant.DEFAULT_EMPTY_DATA_SOURCE]);
        loadPagination = true;
        setDisableExportButton(true);
      },
    );
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    const outputAction = output.action;
    processPagination(outputPagination);
    processAction(outputAction);
  };

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  async function processAction(outputAction) {
    if (outputAction && outputAction.id) {
      setVisibleModalClaim(true);
      const params = {
        claim_config_id: [outputAction.claim_config_id],
        employee_id: [outputAction.employee_id],
        id: outputAction.id,
        with_meta: true
      }
      const detail = await useCaseImpl.getClaimReportDetail(params);
      if (!!detail && detail?.data?.content) {
        setDataDetail(detail.data.content);
      }
    }
  }

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }
  function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  }

  const exportButton = {
    onClick: () => {
      setIsLoading(true);
      const params = {
        limit_type,
        is_expired,
        employee_id,
        job_id,
        claim_config_id,
        with_meta: true,
      };
      useCaseImpl.export(params).then(
        (res: any) => {
          const data = res.data;
          if (data.status === 200) {
            const file = base64ToArrayBuffer(data.content.data);
            saveByteArray(data.content.filename, file);
          }

          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
        },
      );
    },
    disabled: disableExportButton || isLoading,
  };

  return (
    <div id="claim-report-page" style={{ height: 'calc(100vh - 100px)' }}>
      <Row justify="space-between">
        <Breadcrumb breadcrumbConf={breadcrumbConf} />
      </Row>
      <Modal
        title=""
        open={visibleModalClaim}
        footer={null}
        onCancel={()=> {
          setVisibleModalClaim(false)
          setDataDetail([]);
        }}
        width={'fit-content'}
      >
        <div>
          <Table
            columns={CLAIM_TABLE_HEADER}
            dataSource={dataDetail}
            pagination={null}
          />
        </div>
      </Modal>
      <LandingListTemplate
        addButton={{ visible: false }}
        deleteButton={{ visible: false }}
        exportButton={exportButton}
        tableHeader={tableHeader}
        tableData={tableData}
        tablePagination={pagination}
        tableOnChange={tableOnChange}
        isLoading={isLoading}
        tableScroll={tableScroll}
      />
    </div>
  );
}
