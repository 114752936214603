import Constant from "../../../../../../config/Constants"
import API from "../../../../../../services/API.service"
import createOvtConfiguration from '../../../../../../@core/interaction/overtime/CreateOvtEmployeeConfiguration.interaction';
import updateOvtConfiguration from '../../../../../../@core/interaction/overtime/UpdateEmployeeOvtConfiguration.interaction'
import getOvtConfiguration from '../../../../../../@core/interaction/overtime/GetOvertimetConfiguration.interaction';
import getJobList from '../../../../../../@core/interaction/job/GetJobList.interaction';
import { jobListToOption } from '../../../../../../@core/mapper/job/GetJobList.mapper';

export default class HrAddCompanyOvtConfiguration {

  getJobList(params) {
    return getJobList(params).then((res: any) => jobListToOption(res.data.content));
  }

  getJob() {
    const URL = Constant.URL_BASE_COMPANY + "/company/v3/lov/job"
    return API.get(URL).then((res:any) => 
      {
        const result = res.data.content.map(element => {
          return { label: element['job_name'], value: JSON.stringify(element), key: element['id'] }
        })
        return result;
      })
  }

  getEmpl() {
    const param:any = {
      page:1,
      limit: 500
    }
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/emp"
    return API.get(URL, param).then((res:any) => 
      {
        const result = res.data.content.map(element => {
          return { label: element['alternative_name'], value: JSON.stringify(element), key: element['id'] }
        })
        return result;
      })
  }

  getOvtConfiguration(): Promise<any> {
    return getOvtConfiguration();
  }
  
  submitOvtConfiguration(params): Promise<any> {
    if(!!params.id){
      return updateOvtConfiguration(params);
    } else {
      return createOvtConfiguration(params);
    }
  }

}
