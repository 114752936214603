import './BottomLoading.less';

interface Props {
  active: boolean;
}

export default function BottomLoading({ active }: Props) {
  const Loading = () => {
    return active ? <div id="hira-bottom-loading-component">Loading</div> : null;
  };

  return <Loading />;
}
