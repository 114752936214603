export function employeeClaimReportMapper(response) {
    const content = response.content ? response.content : null;
    let contentMap = null;
    if (content) {
      contentMap = content.map((data) => {
        const item = {
          id: data.id,
          key: data.id,
          nik: data.nik,
          emp_name: data.employee_name,
          job_name: data.job_name,
          data_claim: data.data_claim,
          claim_plafon: data.claim_plafon,
          claim_total: `Rp.${data.claim_total}`,
          claim_rem: data.claim_rem,
          claim_name: data.claim_name,
          employee_id: data.employee_id,
          claim_config_id: data.claim_config_id,
        };
        return item;
      });
    }
    return {
      content: contentMap,
      metaData: response.metadata,
    };
  }
  