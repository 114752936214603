import { useHistory } from 'react-router-dom';
import CardViewListTemplate from '../../../../../templates/card-view-list-template/CardViewListTemplate';
import { useEffect, useState } from 'react';
import ToastService from '../../../../../services/Toast.service';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import { CardViewListTemplatePagination } from '../../../../../templates/card-view-list-template/CardViewListTemplate.interface';
import { getDepartmentListMapper } from '../../../../../@core/mapper/employee/GetDepartmentList.mapper';
import { ToolbarButtonCardType } from '../../../../../templates/card-view-list-template/components/ComponentCardView.interface';
import { MasterDepartmentConstant } from './MasterDepartmentList.constant';
import MasterDepartmentUseCase from './MasterDepartmentList.usecase';
import { Modal } from 'antd';

let page = 1;
let loadPagination = true;
let sort = 'write_date|desc';
const limit = 12;
let selectionId = [];
let name;
let parent_name;
let delete_id;

const constant = MasterDepartmentConstant;
const usecase = new MasterDepartmentUseCase();
const toastService = new ToastService();
const localNotificationService = new LocalNotificationService();

export default function MasterDepartmentList() {
  const history = useHistory();

  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([]);
  let [tableScroll, setTableScroll] = useState({ y: 700, x: 'max-content' });
  let [visibleDeleteButton, setVisibleDeleteButton] = useState(false);
  let [disableExportButton, setDisableExportButton] = useState(false);
  let [visibleWarningModal, setVisibleWarningModal] = useState<'active' | 'non-active' | ''>('');
  let [isCardMode, setIsCardMode] = useState(true);
  let [pagination, setPagination] = useState<CardViewListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });

  let tableHeader: any = constant.TABLE_HEADER;
  let searchColumnKey = constant.SEARCH_COLUMN_KEY;
  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: true };

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: false,
        displayPathName: 'Master Departemen',
        href: '/v2/app/hr-employee/master-department',
      },
    ],
  };

  useEffect(() => {
    loadList();
  }, []);

  function loadList() {
    setIsLoading(true);
    const params = {
      page,
      limit,
      sort,
      name,
      parent_name,
      with_meta: true,
    };

    usecase.getAll(params).then(
      (res: any) => {
        setIsLoading(false);
        const content = res.data.content;
        const metaData = res.data.metadata;

        const resultDataSource = content?.length
          ? getDepartmentListMapper(content)
          : constant.DEFAULT_EMPTY_DATA_SOURCE;

        setDataSource([...resultDataSource]);
        updatePaginationState(metaData.total_records);

        loadPagination = true;
        setDisableExportButton(content ? false : true);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  function processSort(outputSort) {
    if (outputSort && usecase.convertOutputSortToEndPointFormat(outputSort) !== sort) {
      page = 1;
      loadPagination = false;

      sort = usecase.convertOutputSortToEndPointFormat(outputSort);
      loadList();
    }
  }

  function processSearchColumn(outputSearchColumn) {
    let isLoadList = false;

    if (outputSearchColumn) {
      if (outputSearchColumn.title !== undefined && outputSearchColumn.title !== name) {
        name = outputSearchColumn.title;
        isLoadList = true;
      }

      if (
        outputSearchColumn.department_parent !== undefined &&
        outputSearchColumn.department_parent !== parent_name
      ) {
        parent_name = outputSearchColumn.department_parent;
        isLoadList = true;
      }
    }

    if (isLoadList) {
      page = 1;
      loadPagination = false;
      loadList();
    }
  }

  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionId) {
      selectionId = outputSelection;
      setVisibleDeleteButton(!!selectionId.length)
    }
  }

  const deleteButton = {
    visible: visibleDeleteButton,
    disabled: isLoading,
    onClick: () => {
      setVisibleWarningModal('non-active');
    },
  };

  const addButton = {
    onClick: () => {
      history.push('/app/hr-employee/master-department/new/');
    },
    disabled: isLoading,
  };

  const dropdownButton = {
    visible: true,
    disabled: isLoading,
    options: [
      {
        key: 'ekspor',
        disabled: disableExportButton,
        label: 'Ekspor',
        onClick: () => exportData(),
      },
      !isCardMode ? {
        key: 'active',
        label: 'Aktifkan',
        disabled: !visibleDeleteButton,
        onClick: () => {
          delete_id = selectionId
          setVisibleWarningModal('active')
        },
      } : null,
      !isCardMode ? {
        key: 'non-active',
        label: 'Non-Aktifkan',
        disabled: !visibleDeleteButton,
        onClick: () => {
          delete_id = selectionId
          setVisibleWarningModal('non-active')
        },
      } : null,
    ],
  };

  function setActive(status, ids) {
    setIsLoading(true);
    setVisibleWarningModal('');

    const params = {
      ids,
      status,
    };

    usecase.active(params).then(
      (res: any) => {
        setIsLoading(false);
        const data = res.data;
        if (data.code === 200) {
          const message = data.message;
          localNotificationService.openSuccess(message, '', 1);
        }
        setVisibleWarningModal('');
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      },
      (err) => {
        setIsLoading(false);
        toastService.error(err);
      },
    );
  }

  function exportData() {
    setIsLoading(true);
    const params = {
      ids: selectionId,
      sort,
      name,
      parent_name,
      with_meta: true,
    };

    usecase.export(params).then((resp: any) => {
      setIsLoading(false);
      const content = resp.data.content;
      if (resp.status === 200) {
        const data = content.data;
        let sampleArr = base64ToArrayBuffer(data);
        saveByteArray(content.filename, sampleArr);
      }
    }).catch(e => setIsLoading(false));
  }

  function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  function saveByteArray(reportName, byte) {
    let blob = new Blob([byte], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    let fileName = reportName;
    link.download = fileName;
    link.click();
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    const outputSort = output.sort;
    const outputSearchColumn = output.searchColumn;

    processSort(outputSort);
    processSearchColumn(outputSearchColumn);
    processSelection(output.selection);
    processPagination(outputPagination);
  };

  const onRow = (e) => {
    if (e.key !== 'search-input') {
      history.push('/app/hr-employee/master-department/' + e.key);
    }
  };

  const EditButtonCard: ToolbarButtonCardType = {
    visible: true,
    disabled: isLoading,
    onClick: (e) => {
      if (e !== 'search-input') {
        history.push('/app/hr-employee/master-department/' + e);
      }
    },
  };

  const DeleteButtonCard: ToolbarButtonCardType = {
    visible: true,
    disabled: isLoading,
    onClick: (e) => {
      delete_id = [e];
      setVisibleWarningModal('non-active');
    },
  };

  const changeMode = (output: boolean) => {
    setIsCardMode(output)
  }

  return (
    <>
      <Modal
        title="Non-aktifkan Data"
        cancelText="BATALKAN"
        visible={visibleWarningModal === 'non-active'}
        cancelButtonProps={{ type: 'ghost' }}
        onOk={() => setActive(false, delete_id)}
        onCancel={() => setVisibleWarningModal('')}
      >
        <p>Apakah yakin ingin menon-aktifkan data-data ini?</p>
      </Modal>
      <Modal
        title="Aktifkan Data"
        cancelText="BATALKAN"
        visible={visibleWarningModal === 'active'}
        cancelButtonProps={{ type: 'ghost' }}
        onOk={() => setActive(true, delete_id)}
        onCancel={() => setVisibleWarningModal('')}
      >
        <p>Apakah yakin ingin mengaktifkan data-data ini?</p>
      </Modal>
      <CardViewListTemplate
        breadcrumbConf={breadcrumbConf}
        addButton={addButton}
        deleteButton={deleteButton}
        dropdownButton={dropdownButton}
        exportButton={{ visible: false }}
        tableData={tableData}
        tableHeader={tableHeader}
        selectedRowKeys={selectionId}
        tableOnChange={tableOnChange}
        onRow={onRow}
        tablePagination={pagination}
        tableScroll={tableScroll}
        changeMode={changeMode}
        DeleteButtonCard={DeleteButtonCard}
        EditButtonCard={EditButtonCard}
        isLoading={isLoading}
      />
    </>
  );
}
