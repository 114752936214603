import { Card, Row } from 'antd';

export default function Shortcut(props) {
  const ShortcutProps = props.ShortcutProps || [
    {
      key: 'database',
      icon: 'fa-users',
      title: 'Database Karyawan',
      href: '/v2/app/hr-employee/list-employee',
    },
    {
      key: 'attendance',
      icon: 'fa-street-view',
      title: 'Kehadiran Karyawan',
      href: '/v2/app/hr-attendance/employee-attendance',
    },
    {
      key: 'leave',
      icon: 'fa-cubes',
      title: 'Cuti Karyawan',
      href: '/v2/app/hr-leave/employee-submission',
    },
    {
      key: 'news',
      icon: 'fa-newspaper-o',
      title: 'Tambah Berita',
      href: '/v2/app/hr-news/news-list',
    },
  ];

  return (
    <Card title="Akses Cepat" extra={<p style={{ lineHeight: 1.5 }}>&nbsp;</p>} style={{ minWidth: '100%', minHeight: '100%' }}>
      <Row justify="space-around" gutter={[12, 12]}>
        {ShortcutProps.map((elemen) => {
          return (
            <a
              key={elemen.key}
              href={elemen.href}
              style={{
                height: '96px',
                color: '#202020',
                width: '100px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <i className={`icon-shortcut fa ${elemen.icon}`} />
              <p className="text-shortcut">{elemen.title}</p>
            </a>
          );
        })}
      </Row>
    </Card>
  );
}
