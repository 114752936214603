import Constant from "../../config/Constants";

export function processHrLeavePath(location) {
  const result = [];
  let displayPathName = '';
  let href = '/v2/app/hr-leave';
  let previousPath = '';

  location.forEach((path, index) => {
    switch (path) {
      case 'my-submission':
        displayPathName = 'Pengajuan Cuti Saya';
        break;
      case 'employee-submission':
        displayPathName = 'Pengajuan Cuti Karyawan';
        break;
      case 'employee-approval':
        displayPathName = 'Persetujuan Cuti Karyawan';
        break;
      case 'leave-configuration':
        displayPathName = 'Konfigurasi Cuti';
        break;
      case 'new':
        displayPathName = 'Baru';
        break;
      default:
        displayPathName = processEndOfPath(previousPath, path);
        break;
    }

    previousPath = path;
    href += `/${path}`;

    result.push({
      displayPathName,
      href,
      clickable: index === location.length - 1 ? false : true,
    });
  });

  function processEndOfPath(previousPath, currentPath) {
    switch (previousPath) {
      default:
        return currentPath;
      case 'employee-submission':
        return currentPath.split('-').join('/');
      case 'my-submission':
        return currentPath.split('-').join('/');
      case 'employee-approval':
        return currentPath.split('-').join('/');
    }
  }

  return result;
}

export function processHrAttendancePath(location) {
  const result = [];
  let displayPathName = '';
  let href = '/v2/app/hr-attendance';
  let previousPath = '';

  location.forEach((path, index) => {
    switch (path) {
      case 'employee-attendance':
        displayPathName = 'Absensi Seluruh Karyawan';
        break;
      case 'recap-attendance':
        displayPathName = 'Rekap Absensi';
        break;
      case 'my-recap-attendance':
        displayPathName = 'Rekap Absensi Saya';
        break;
      case 'calendar-recap-attendance':
        displayPathName = 'Rekap Absensi Saya';
        break;
      case 'check-missing-attendance':
        displayPathName = 'Data Kehadiran Belum Masuk';
        break;
        
      case 'new':
        displayPathName = 'Baru';
        break;
      default:
        break;
    }

    previousPath = path;
    href += `/${path}`;

    result.push({
      displayPathName,
      href,
      clickable: index === location.length - 1 ? false : true,
    });
  });

  return result;
}

export function processHrOvtPath (location) {
  const result = [];
  let displayPathName = '';
  let href = '/v2/app/hr-overtime';
  let previousPath = '';
  console.log(location);
  

  location.forEach((path, index) => {
    switch (path) {
      case 'company-overtime-configuration':
        displayPathName = 'Lembur Perusahaan';
        break;
      case 'employee-overtime-configuration':
        displayPathName = 'Lembur Karyawan';
        break;
      case 'employee-overtime-report':
        displayPathName = 'Laporan Lembur';
        break;
      default:
        break;
    }

    previousPath = path;
    href += `/${path}`;

    result.push({
      displayPathName,
      href,
      clickable: index === location.length - 1 ? false : true,
    });
  });

  return result;
}
