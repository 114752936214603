import React, { useEffect, useRef, useState } from "react";
import Chartjs from 'chart.js/auto';

const LineChart = (props) => {
  const lineChartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState<any>(null);

  const chartConfig:any = {
    type: "line",
    data: {
      labels: props.data.labels,
      datasets: [
        {
          label: "Karyawan Bergabung",
          data: props.data.data,
          backgroundColor: "green",
          borderColor: 'green',
          borderWidth: 2,
          lineTension: 0,
          pointRadius: 2,
          fill: 'none',
        },
      ]
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
            display: false,
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart'
      },
      tooltips: {
        mode: 'index',
        intersect: false,
        titleMarginBottom: 15,
        bodySpacing: 10,
        callbacks: {
          label: function(tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || '';
  
              if (label) {
                  label += ': ';
              }
              label += tooltipItem.yLabel
              return label;
          }
        }
      },
      hover: {
        mode: 'nearest',
        intersect: true
      },
      scales: {
        x: {
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Month'
          }
        },
        y: {
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Value',
            
          }
        },
        yAxes: [{
          ticks: {
            beginAtZero: true,
            callback: function(value, index, values) {
              return value
            }
          }
        }]
      }
    }
  };
  useEffect(() => {
    if (lineChartContainer && lineChartContainer.current) {
      const newChartInstance = new Chartjs(lineChartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
    }
  }, [lineChartContainer]);

  useEffect(() => {
    if(props && !!props?.data && chartInstance !== null){
      chartInstance.data.labels = props?.data?.labels ? props?.data?.labels : [];
      chartInstance.data.datasets[0].data = props.data.data;
      chartInstance.update();
    }
  }, [props]);

  return (
    <div style={{position: 'relative', height: '40vh'}}>
      <canvas ref={lineChartContainer} />
    </div>
  );
};

export default LineChart;