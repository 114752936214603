import moment from "moment";

export function getListEmployeeMapper(listEmployee: any[]) {
  let result = [];
  listEmployee.map((item) => {
    const temp = {
      key: item.id,
      nik: item.nik,
      name: item.name_related,
      job_name: item.job_name,
      department_name: item.department_name,
      atasan: item.atasan_1_name,
      sum_emp: item.sum_emp,
      category: item.category,
      join_date: item.date_join ? moment(item.date_join).format('DD-MM-YYYY') : '-',
      location: item.location,
      file_foto: item.file_foto,
      create_name: item.create_name,
      create_date: moment(item.view_create_date).format('DD-MM-YYYY HH:mm'),
      write_name: item.write_name,
      write_date: moment(item.view_write_date).format('DD-MM-YYYY HH:mm'),
    }

    result.push(temp)
  })

  return result
}
