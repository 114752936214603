import { FileOutlined, DeleteFilled } from '@ant-design/icons';
import { useState } from 'react';
import './ComponentStyle.less';
import ModalShowImageLeave from '../../../../../components/upload-image/ModalShowImageLeave';

const componentId = 'upload-file';
const imageExt = ['jpg', 'jpeg', 'png'];
let extCurrent;

export default function UploadFile(props) {
  let [isShowModal, setIsShowModal] = useState(false);

  const onDelete = () => {
    if (props.onChange) {
      props.onChange('', componentId);
    }
  };

  async function onChange(e) {
    const file = e.target.files[0];
    if (!!file) {
      const url = URL.createObjectURL(file);
      extCurrent = file.name.split(".").at(-1);

      if (props.onChange) {
        const base64Result = await getBase64(file);

        const result = {
          base64Result,
          file,
          url,
        };
        props.onChange(result, componentId);
      }
    }
  }

  function getBase64(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        resolve(error);
      };
    });
  }

  return (
    <div className="edit-image-wrapper">
      {!props.value?.url ? (
        <label className="full-box-select-img" htmlFor={componentId}>
          <FileOutlined
            style={{ fontSize: '24px', margin: '12px auto auto auto', display: 'block' }}
          />
          <span className="select-img-text">Pilih File...</span>
        </label>
      ) : (
        <div className="selected-img-wrapper">
          {imageExt.indexOf(extCurrent) >= 0 ? (
            <img
              alt="selected-img"
              src={props.value?.url}
              style={{ cursor: 'pointer' }}
              onClick={() => setIsShowModal(!isShowModal)}
            />
          ) : (
            props.value?.file?.name
          )}

          <button className="delete-image-btn" onClick={onDelete}>
            <DeleteFilled />
          </button>
          <label className="change-img-btn" htmlFor={componentId}>
            Ubah File
          </label>
        </div>
      )}

      <input
        className="toogle-file"
        type="file"
        id={componentId}
        name={componentId}
        accept="image/jpeg, image/jpg, image/png, application/pdf"
        onChange={(e) => onChange(e)}
      />

      <ModalShowImageLeave
        imageSrc={props.value?.url}
        isShowModal={isShowModal}
        showModal={() => setIsShowModal(!isShowModal)}
      />
    </div>
  );
}
