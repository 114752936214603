import Constant from "../../../../../../config/Constants";
import API from "../../../../../../services/API.service";

export default class MasterWorkingScheduleUseCase {
  convertOutputSortToEndPointFormat(tableOutputSort) {
    let result = '';
    const columnKey = tableOutputSort.columnKey;
    const order = tableOutputSort.order;

    if (order) {
      switch (columnKey) {
        default:
          break;
        case 'working_schedule_name':
          result += 'name|';
          break;
        case 'total_work_hour':
          result += 'view_total_works_per_hour|';
          break;
        case 'create_date':
          result += 'create_date|';
          break;
        case 'write_date':
          result += 'write_date|';
          break;
      }

      switch (order) {
        default:
          break;
        case 'ascend':
          result += 'asc';
          break;
        case 'descend':
          result += 'desc';
          break;
      }
    }
    
    return result;
  }

  getAll(params) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/configuration/master-jadwal-kerja"
    return API.get(URL, { params })
  }

  export(params) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/configuration/master-jadwal-kerja/export"
    return API.get(URL, { params })
  }

  active(body) {
    const URL = Constant.URL_BASE_ATTENDANCE + "/v3/configuration/master-jadwal-kerja/active"
    return API.put(URL, body)
  }
}