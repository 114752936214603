import { useEffect, useState } from "react";
import { ToolbarDetailActionButton } from "../../../employee/components/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface";
import LocalNotificationService from "../../../../../services/LocalNotification.service";
import ToastService from "../../../../../services/Toast.service";
import DetailMySubmissionClaimUsecase from "./DetailMySubmissionClaim.usecase";
import { DetailMySubmissionClaimType } from "../../template/AddMySubmissionClaimTemplate.interface";
import AddMySubmissionClaimTemplate from "../../template/AddMySubmissionClaimTemplate";
import { getDetailClaimMapper } from "../../../../../@core/mapper/claim/DetailClaim.mapper";
import { ToolbarLeaveDetailActionButton } from "../../../leaves/templates/employee-leave-detail-template/components/toolbar-leave-detail-template/ToolbarLeaveDetailTemplate.interface";

const usecase = new DetailMySubmissionClaimUsecase();
const localNotificationService = new LocalNotificationService();
const toastService = new ToastService()
const steps = [
  "Pengajuan", "Disetujui",
  "Persetujuan Ke-2", "Ditolak",
  "Batal", "Selesai", "Dibayar"
];

export default function DetailMySubmissionClaim({ match }) {
  const claimId = match.params.claimId

  const [isLoading, setIsLoading] = useState(false);
  const [detailDataSource, setDetailDataSource] = useState<DetailMySubmissionClaimType>({
    name: '',
    employee: '',
    submission_date: '',
    claim_type: '',
    sisa_plafon: null,
    claim_limit: null,
    submission_total: null,
    detail: '',
    approval1: [],
    approval2: [],
    history_id: null,
    is_active: false,
    state: null
  });

  useEffect(() => {
    init()
  }, [])

  async function init() {
    setIsLoading(true)
    
    usecase.getDetail(claimId).then(
      (res) => {
        const content = res.data.content
        const claim_config_id = content.claim_config_id
        const employee_id = content.employee_id
        const params = {
          claim_config_id,
          employee_id,
        };
        usecase.getPlafonClaim(params).then(
          (res) => {
            const detailConfig = res.data.content
            const detail = getDetailClaimMapper(content, detailConfig)
            setDetailDataSource(detail)
            setIsLoading(false)
          }
        ).catch(e => toastService.error(e))
      }
    ).catch(err => {
      setIsLoading(false)
    })
  }

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Pengajuan Klaim Saya',
        href: "/v2/app/hr-claim/my-submission-claim",
      },
      {
        clickable: false,
        displayPathName: detailDataSource.name,
        href: "/v2/app/hr-claim/my-submission-claim/new/",
      }
    ],
  };

  const submitButton = { active: false, disabled: false };
  const firstActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: false,
    onClick: () => {
      const back = window.confirm("Anda Ingin Kembali")
      if (back) {
        window.location.href = "/v2/app/hr-claim/my-submission-claim"
      }
    },
  };
  const cancelButton: ToolbarLeaveDetailActionButton = {
    label: 'BATALKAN',
    type: 'primary',
    active: detailDataSource.is_active,
    onClick: () => setActive(false),
    disabled: isLoading,
  };

  function setActive(status) {
    setIsLoading(true);

    const params = {
      id: [claimId],
      status
    };

    usecase.active(params).then(  
      (res: any) => {
        setIsLoading(false);
        const content = res.data.content
        const message = content
        localNotificationService.openSuccess(message, '', 1)
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      },
      (err) => {
        setIsLoading(false);
        toastService.error(err)
      },
    );
  }

  const onFieldChange = () => {

  }

  const onFinish = (e) => {

  }

  return (
    <div className="detail-my-submission-claim">
      <AddMySubmissionClaimTemplate
        breadcrumbConf={breadcrumbConf}
        dropdownActionButton={{ visible: false }}
        firstActionButton={firstActionButton}
        secondActionButton={{ active: false }}
        thirdActionButton={cancelButton}
        fourthActionButton={{ active: false }}
        submitButton={submitButton}
        detailDataSource={detailDataSource}
        activeSteps={detailDataSource.state}
        steps={steps}
        historyId={detailDataSource.history_id}
        onFieldChange={onFieldChange}
        onFinish={onFinish}
        onFinishFailed={null}
        isLoading={isLoading}
        viewMode={true}
      />
    </div>
  )
}