import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import { Table, Button, Input, Space, Pagination, Menu, Dropdown, DatePicker, Select, Row, Col } from 'antd';
import './ExpandableListTemplate.less';
import {
  ExpandableListTemplateButton,
  ExpandableListTemplateDropdownButton,
  ExpandableListTemplatePagination,
  ExpandableListTemplatePropsType,
  SearchColumnKeyType,
} from './ExpandableListTemplate.interface';
import BottomLoading from '../../components/bottom-loading/BottomLoading';
import { DownOutlined, CalendarOutlined } from '@ant-design/icons';

let output = {
  pagination: null,
  sort: null,
  searchColumn: null,
  selection: null,
};

export default function ExpandableListTemplate(props: ExpandableListTemplatePropsType) {
  const { expandedRowRender } = props
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  let [addButton, setAddButton] = useState<ExpandableListTemplateButton>();
  let [exportButton, setExportButton] = useState<ExpandableListTemplateButton>();
  let [deleteButton, setDeleteButton] = useState<ExpandableListTemplateButton>();
  let [importButton, setImportButton] = useState<ExpandableListTemplateButton>();
  let [changeModeButton, setChangeModeButton] = useState<ExpandableListTemplateButton>();
  let [dropdownButton, setDropdownButton] = useState<ExpandableListTemplateDropdownButton>();
  let [dataSource, setDataSource] = useState([]);
  let [columns, setColumns] = useState<any>();
  let [tableRowSelectionActive, setTableRowSelectionActive] = useState();
  let [selectedRowKeys, setSelectedRowKeys] = useState<any>();
  let [pagination, setPagination] = useState<ExpandableListTemplatePagination>({
    current: 1,
    pageSize: 5,
    total: 5,
    size: 'small',
  });
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    fillActionButtonState('addButton');
  }, [props.addButton]);
  useEffect(() => {
    fillActionButtonState('exportButton');
  }, [props.exportButton]);
  useEffect(() => {
    fillActionButtonState('deleteButton');
  }, [props.deleteButton]);
  useEffect(() => {
    if (props.importButton) {
      fillActionButtonState('importButton'); 
    }
  }, [props.deleteButton]);
  useEffect(() => {
    if (props.dropdownButton) {
      processDropdownButton();
    }
  }, [props.dropdownButton]);
  useEffect(() => {
    if (props.changeModeButton) {
      fillActionButtonState('changeModeButton')
    }
  }, [props.changeModeButton]);
  useEffect(() => {
    processTableData();
  }, [props.tableData]);
  useEffect(() => {
    processTableHeader();
  }, [props.tableHeader]);
  useEffect(() => {
    processPagination();
  }, [props.tablePagination]);
  useEffect(() => {
    processLoading();
  }, [props.isLoading]);

  const fillActionButtonState = (procedure) => {
    const result: any = { visible: true, disabled: false };

    if (props[procedure]) {
      result.visible = props[procedure].visible === undefined ? true : props[procedure].visible;
      result.disabled = props[procedure].disabled === undefined ? false : props[procedure].disabled;
    }

    switch (procedure) {
      case 'addButton':
        setAddButton(result);
        break;
      case 'exportButton':
        setExportButton(result);
        break;
      case 'deleteButton':
        setDeleteButton(result);
        break;
      case 'changeModeButton':
        setChangeModeButton(result);
        break;
      case 'importButton':
        setImportButton(result)
        break;
      default:
        break;
    }
  };

  const processDropdownButton = () => {
    const result: any = { visible: true, disabled: false, options: [] };

    result.visible = props.dropdownButton.visible === undefined ? true : props.dropdownButton.visible;
    result.disabled = props.dropdownButton.disabled === undefined ? false : props.dropdownButton.disabled;
    result.options = props.dropdownButton.options
      ? props.dropdownButton.options
      : [{ label: 'Option 1', value: 'option_1' }];

    setDropdownButton(result)
  }

  const processTableData = () => {
    if (props.tableData && props.tableData.dataSource && props.tableData.dataSource.length) {
      const searchColumnKey: SearchColumnKeyType[] = props.tableData.searchColumnKey
        ? props.tableData.searchColumnKey
        : [];
      const searchColumnResult = JSON.parse(JSON.stringify(props.tableData.dataSource[0]));
      const dataSource = props.tableData.dataSource ? props.tableData.dataSource : [];
      let result = [];

      if (searchColumnKey.length) {
        for (let key in searchColumnResult) {
          searchColumnResult[key] = '';
          searchColumnKey.map(sck => {
            if (sck.key === key) {
              searchColumnResult[key] = sck.componentType
            }
          })
        }

        result.push(searchColumnResult);
      }
      result = result.concat(dataSource);

      setDataSource([...result]);
      processTableRowSelection();
    }
  };

  const processTableHeader = () => {
    const column: any = props.tableHeader ? props.tableHeader : [];
    const result: any = [];

    if (column.length) {
      column.forEach((header) => {
        const temp = header;
        temp.render = (text, record) => {
          switch (text) {
            case 'text':
              return <Input.Search
                allowClear
                defaultValue={null}
                onPressEnter={(e: any) => onEnterSearchColumn(e.target.value, header.key)}
                onSearch={(val) => onEnterSearchColumn(val, header.key)}
              />
            case 'date':
              return <DatePicker
                format="YYYY-MM-DD"
                defaultValue={null}
                onChange={(_, date) => onEnterSearchColumn(date, header.key)}
              />
            case 'select':
              const options = props.tableData.searchColumnKey.find(sck => {
                return sck.key === header.key
              }).options
              return <Select
                style={{ width: '100%' }}
                options={options}
                defaultValue={null}
                onChange={(value) => onEnterSearchColumn(value, header.key)}
              />
            default:
              break;
          }
          if (header.isCTA !== undefined && header.isCTA) {
            return <a href={record.href}>{text}</a>;
          } else {
            return <span>{text}</span>;
          }
        };

        result.push(header);
      });
      setColumns([...result]);
    } else {
      // empty conf for header table
    }
  };

  const processPagination = () => {
    const resultPagination: ExpandableListTemplatePagination = pagination;

    if (props.tablePagination) {
      const tablePagination = props.tablePagination;
      resultPagination.current = tablePagination.current ? tablePagination.current : 1;
      resultPagination.pageSize = tablePagination.pageSize ? tablePagination.pageSize : 5;
      resultPagination.total = tablePagination.total ? tablePagination.total : 5;
      resultPagination.size = tablePagination.size ? tablePagination.size : 'small';
    }

    setPagination(resultPagination);
  };

  const processLoading = () => {
    const result = props.isLoading !== undefined ? props.isLoading : true;
    setLoading(result);
  };

  const processTableRowSelection = () => {
    const result: any = props.tableData.tableRowSelectionActive
      ? props.tableData.tableRowSelectionActive
      : false;
    setTableRowSelectionActive(result);
  };

  const sendOutputTable = () => {
    if (props.tableOnChange) {
      props.tableOnChange(output);
    }
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys([...selectedRowKeys]);

    output.selection = selectedRowKeys;
    sendOutputTable();
  };

  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.key === 'search-input' || record.key === 'empty-data',
    }),
  };

  const onClickActionButton = (procedure) => {
    if (props[procedure] && props[procedure].onClick) {
      props[procedure].onClick();
    }
  };

  const onEnterSearchColumn = (value, columnName) => {
    if (!output.searchColumn) {
      output.searchColumn = {};
    }
    output.searchColumn[columnName] = value;

    sendOutputTable();
  };

  const tableOnChange = (pagination, filters, sort, extra) => {
    output.sort = {
      columnKey: sort.columnKey,
      field: sort.field,
      order: sort.order,
    };
    sendOutputTable();
  };

  const onRow = (record) => {
    if (props.onRow) {
      props.onRow(record)
    } else {
      handleRowExpand(!isRowExpanded(record), record)
    }
  }
  const paginationOnChange = (page, pageSize) => {
    output.pagination = { page, pageSize };
    sendOutputTable();
  };

  const AddButton = () => {
    return addButton && addButton.visible ? (
      <Button
        type="primary"
        disabled={addButton.disabled}
        onClick={() => onClickActionButton('addButton')}
      >
        Tambah
      </Button>
    ) : null;
  };

  const ExportButton = () => {
    return exportButton && exportButton.visible ? (
      <Button
        type="primary"
        disabled={exportButton.disabled}
        onClick={() => onClickActionButton('exportButton')}
      >
        Export
      </Button>
    ) : null;
  };

  const DeleteButton = () => {
    return deleteButton && deleteButton.visible ? (
      <Button
        type="primary"
        disabled={deleteButton.disabled}
        onClick={() => onClickActionButton('deleteButton')}
      >
        Delete
      </Button>
    ) : null;
  };

  const ImportButton = () => {
    return importButton && importButton.visible ? (
      <Button
        type="primary"
        disabled={importButton.disabled}
        onClick={() => onClickActionButton('importButton')}
      >
        Import
      </Button>
    ) : null;
  };

  const DropdownButton = () => {
    const menu = dropdownButton && dropdownButton.visible ? (
      <Menu>
        {dropdownButton.options.map((opt) => (
          <Menu.Item
            disabled={opt.disabled}
            onClick={() => opt.onClick()}
            key={opt.key}
          >
            {opt.label}
          </Menu.Item>
        ))}
      </Menu>
    ) : null

    return dropdownButton && dropdownButton.visible ? (
      <Dropdown
        disabled={dropdownButton.disabled}
        overlay={menu}
      >
        <Button
          type='primary'
        >
          Tindakan <DownOutlined />
        </Button>
      </Dropdown>
    ) : null
  };

  const ChangeModeButton = () => {
    return changeModeButton && changeModeButton.visible ? (
      <Button
        type="primary"
        disabled={changeModeButton.disabled}
        onClick={() => onClickActionButton('changeModeButton')}
      >
        <CalendarOutlined />
      </Button>
    ) : null
  }

  const handleRowExpand = (expanded, record) => {
    if (record.key !== 'search-input') {
      const keys = [...expandedRowKeys];
      if (expanded) {
        keys.push(record.key);
      } else {
        const index = keys.indexOf(record.key);
        if (index >= 0) {
          keys.splice(index, 1);
        }
      }
      setExpandedRowKeys(keys);
    }
  };

  const isRowExpanded = (record) => {
    return expandedRowKeys.includes(record.key);
  };

  return (
    <div id="extended-list-template">
      <Breadcrumb breadcrumbConf={props.breadcrumbConf} />

      <div className="toolbar">
        <Space>
          <AddButton />
          <ExportButton />
          <DeleteButton />
          <ImportButton />
        </Space>

        <DropdownButton />

        <Col
          className={changeModeButton &&
            changeModeButton.visible ? 'left-section'
            : ''}
        >
          <ChangeModeButton />
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            size={pagination.size}
            showSizeChanger={false}
            onChange={paginationOnChange}
          />
        </Col>

      </div>

      <Table
        className="no-expand-icon-table"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        rowSelection={tableRowSelectionActive ? rowSelection : null}
        onChange={tableOnChange}
        scroll={props.tableScroll ? props.tableScroll : false}
        expandable={{
          expandedRowKeys: expandedRowKeys,
          onExpand: handleRowExpand,
          rowExpandable: (record) => true,
          expandedRowRender,
          expandIcon: () => null,
          expandIconColumnIndex: -1
        }}
        onRow={(record) => ({
          onClick: () => onRow(record),
        })}
      />

      <BottomLoading active={loading} />
    </div>
  );
}
