import { InputNumber, Row, Space } from "antd"

export const AttendanceToleranceInputNumber = ({ value, onChange, isLoading, viewMode }) => {
  return !viewMode ? (
      <Space direction="horizontal">
        <InputNumber   
          onChange={onChange}
          disabled={isLoading} 
          value={value}
          min={0} 
          style={{ width: '96%' }} 
          formatter={value => `${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ',')}
        />
        <span style={{ fontWeight: 600 }}>Menit</span>
      </Space>
  ) : <span>{value} Menit</span>
}