import Constant from "../../../../../config/Constants";
import API from "../../../../../services/API.service";

export default class DetailEmployeeApprovalClaimUsecase {
  getDetail(id) {
    const URL = Constant.URL_BASE_CLAIM + '/v3/request/claim/' + id;
    return API.get(URL);
  }
  
  update(body, id) {
    const URL = Constant.URL_BASE_CLAIM + '/v3/request/approval/claim/' + id;
    return API.put(URL, body);
  }

  getPlafonClaim(params) {
    const URL = Constant.URL_BASE_CLAIM + '/v3/request/claim/check';
    return API.get(URL, { params });
  }
}