import { DatePicker } from "antd";
import moment from "moment";

export default function DatePickerFutureDate({ onChange, disabledForm }) {
  return (
    <DatePicker 
      key='date-picker-future'
      disabled={disabledForm}
      disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))}
      onChange={onChange}
    />
  )
}