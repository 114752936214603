import { DefaultDataSourceMasterWorking } from "../../../pages/hr/attendance/configuration/master-working-schedule/add-master-working-schedule/AddMasterWorkingSchedule.constant";
import { DaysOfWorkType, MasterWorkingScheduleDetailResp } from "./MasterWorkingScheduleMapper.interface";

const moment = require("moment")
export function ScheduleMasterDetailMapper(
  scheduleMasterDetail: MasterWorkingScheduleDetailResp
) {
  const historyId = scheduleMasterDetail.history_id
  let daysOfWork = DefaultDataSourceMasterWorking.table_days
  scheduleMasterDetail.days_of_work.forEach(
    (dayOfWork) => {
      const dayData = daysMapper(dayOfWork)
      daysOfWork[capitalizeFirstLetter(dayOfWork.day)] = dayData
    })
  
  const result = {
    name: scheduleMasterDetail.name,
    is_radius: scheduleMasterDetail.is_radius,
    radius: mapperValueRadius(scheduleMasterDetail.radius),
    attendance_tolerance: scheduleMasterDetail.tolerance_absent,
    break_total: floatToTimeString(scheduleMasterDetail.hour_of_break),
    work_time_total: scheduleMasterDetail.view_total_works_per_hour,
    table_days: daysOfWork
  }

  return {
    scheduleMasterDetail: result,
    status: scheduleMasterDetail.is_active,
    historyId,
  }
}

export function convertFloatToTime(time) {
  if (!time) {
    return null
  }
  const hours = Math.floor(time);
  const minutes = Math.round((time - hours) * 60);
  return moment({ hour: hours, minute: minutes });
}

function daysMapper(dayData: DaysOfWorkType) {
  const result = {
    start: convertFloatToTime(dayData.start_time),
    end: convertFloatToTime(dayData.end_time),
    break: convertFloatToTime(dayData.break_time),
  }

  return result
}

function capitalizeFirstLetter(day) {
  return day.charAt(0).toUpperCase() + day.slice(1);
}

function mapperValueRadius(radius) {
  switch(radius) {
    case 'check_in_out':
      return 'Check In & Out'
    case 'check_in':
      return 'Check In'
    case 'check_out':
      return 'Check Out'
    default:
      return radius
  }
}

export function floatToTimeString(floatTime) {
  const hours = Math.floor(floatTime);
  const minutes = Math.round((floatTime % 1) * 60);

  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  return formattedTime;
}