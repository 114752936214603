import { ExportLeaveResp } from '../../interface/leave/ExportLeave.interface';
import { DeleteLeavesDTO, DeleteLeavesResp } from '../../interface/leave/DeleteLeaves.interface';
import { GetLeavesDTO } from '../../interface/leave/GetLeaves.interface';
import { SubmissionLeaveList } from '../../entity/SubmissionLeaveList.entity';
import { LeaveTypeObjById } from '../../entity/LeaveType.entity';
import { LeaveId } from '../../entity/Leave.entity';
import { UpdateLeaveStateResp } from '../../interface/leave/UpdateLeaveState.interface';

export default abstract class SubmissionLeaveUseCase {
  constructor() {}

  abstract getLeaveTypes(): Promise<LeaveTypeObjById>;
  abstract getSubmissionLeaves(
    params: GetLeavesDTO,
    leaveTypeObj: LeaveTypeObjById,
    hrefPath: string,
  ): Promise<SubmissionLeaveList>;
  abstract cancelLeave(leaveId: LeaveId[]): Promise<UpdateLeaveStateResp>;
  abstract exportSubmissionLeaves(params: GetLeavesDTO): Promise<ExportLeaveResp>;
}
