import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { processHrLeavePath, processHrAttendancePath, processHrOvtPath} from './Breadcrumb.service';
import { BreadcrumbPropsType } from './Breadcrumb.interface';
import './Breadcrumb.less';

export default function Breadcrumb({ breadcrumbConf }: BreadcrumbPropsType) {
  let pathname = useLocation().pathname.split('/');
  let [breadcrumb, setBreadcrumb] = useState([]);

  useEffect(() => {
    if (breadcrumbConf && breadcrumbConf.createFromRoute === false) {
      setBreadcrumb(breadcrumbConf.manualData ? breadcrumbConf.manualData : []);
    } else {
      processPathnameToBreadcrumb();
    }
  }, [breadcrumbConf]);

  function processPathnameToBreadcrumb() {
    pathname.splice(0, 2);
    const location = pathname;

    if (location.indexOf('') !== -1) {
      location.splice(location.indexOf(''), 1);
    }

    const parentRoute = location[0];
    location.splice(0, 1);

    switch (parentRoute) {
      case 'hr-leave':
        setBreadcrumb([...processHrLeavePath(location)]);
        break;
      case 'hr-attendance':
        setBreadcrumb([...processHrAttendancePath(location)]);
        break;
      case 'hr-overtime':
        setBreadcrumb([...processHrOvtPath(location)]);
        break;
      default:
        console.error('cant find parent route ', parentRoute);
        break;
    }
  }

  const Pathname = ({ element }) => {
    if (element.clickable) {
      return <a href={element.href}>{element.displayPathName}</a>;
    } else {
      return <span className="path-name disabled">{element.displayPathName}</span>;
    }
  };

  return (
    <div id="hira-breadcrumb">
      {breadcrumb &&
        breadcrumb.length > 0 &&
        breadcrumb.map((element: any, index) => (
          <div key={element.displayPathName+index}>
            <Pathname element={element} />

            {index !== breadcrumb.length - 1 && (
              <span className="breadcrumb-path-separator">/</span>
            )}
          </div>
        ))}
    </div>
  );
}
