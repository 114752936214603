import React, { useEffect, useState } from 'react';
import { Button, Pagination, Row, Col } from 'antd';
import Breadcrumb from '../../../../components/breadcrumb/Breadcrumb';
import BottomLoading from '../../../../components/bottom-loading/BottomLoading';
import NotificationCard from './components/NotificationCard';
import {
  NotificationCardPropsType,
  NotificationListTemplateButton,
  NotificationListTemplatePagination,
  NotificationListTemplatePropsType,
} from './NotificationListTemplate.interface';
import Search from 'antd/lib/input/Search';
import { aesGcmDecrypt } from '../../../../services/Encrypter';

let output = {
  pagination: null,
  sort: null,
  searchColumn: null,
  selection: null,
};

export default function NotificationListTemplate(props: NotificationListTemplatePropsType) {
  let [importButton, setImportButton] = useState<NotificationListTemplateButton>();
  let [dataSource, setDataSource] = useState<NotificationCardPropsType[]>([]);
  let [images, setImages] = useState(['', '', '', '', '', '', '', '', '', '', '', '',])
  let [pagination, setPagination] = useState<NotificationListTemplatePagination>({
    current: 1,
    pageSize: 5,
    total: 5,
    size: 'small',
  });
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    fillActionButtonState('importButton');
  }, [props.importButton]);
  useEffect(() => {
    processTableData();
  }, [props.dataSource]);
  useEffect(() => {
    processPagination();
  }, [props.tablePagination]);
  useEffect(() => {
    processLoading();
  }, [props.isLoading]);
  useEffect(() => {
    const copyImages = [...images];
    props.dataSource?.forEach(async (ds, i) => {
      copyImages[i] = !!ds.src ? await aesGcmDecrypt(ds.src) : null
      setImages([...copyImages]);
    });
  }, [props.dataSource]);

  const fillActionButtonState = (procedure) => {
    const result: any = { visible: true, disabled: false };

    if (props[procedure]) {
      result.visible = props[procedure].visible === undefined ? true : props[procedure].visible;
      result.disabled = props[procedure].disabled === undefined ? false : props[procedure].disabled;
    }

    switch (procedure) {
      case 'importButton':
        setImportButton(result);
        break;
      default:
        break;
    }
  };

  const processTableData = () => {
    if (props.dataSource && props.dataSource && props.dataSource.length) {
      const dataSource: NotificationCardPropsType[] = props.dataSource ? props.dataSource : [];

      setDataSource([...dataSource]);
    }
  };

  const processPagination = () => {
    const resultPagination: NotificationListTemplatePagination = pagination;

    if (props.tablePagination) {
      const tablePagination = props.tablePagination;
      resultPagination.current = tablePagination.current ? tablePagination.current : 1;
      resultPagination.pageSize = tablePagination.pageSize ? tablePagination.pageSize : 5;
      resultPagination.total = tablePagination.total ? tablePagination.total : 5;
      resultPagination.size = tablePagination.size ? tablePagination.size : 'small';
    }

    setPagination(resultPagination);
  };

  const processLoading = () => {
    const result = props.isLoading !== undefined ? props.isLoading : true;
    setLoading(result);
  };

  const sendOutputTable = () => {
    if (props.tableOnChange) {
      props.tableOnChange(output);
    }
  };

  const onClickActionButton = (procedure) => {
    if (props[procedure] && props[procedure].onClick) {
      props[procedure].onClick();
    }
  };

  const paginationOnChange = (page, pageSize) => {
    output.pagination = { page, pageSize };
    sendOutputTable();
  };

  const ImportButton = () => {
    return importButton && importButton.visible ? (
      <Button
        type="primary"
        disabled={importButton.disabled}
        onClick={() => onClickActionButton('importButton')}
      >
        Import
      </Button>
    ) : null;
  };

  const onSearch = (e) => {
    if (props.onSearch) {
      props.onSearch(e)
    }
  }

  return (
    <div id="notification-list-template">
      <Row justify="space-between" align='middle' style={{ marginBottom: '30px' }}>
        <Breadcrumb breadcrumbConf={props.breadcrumbConf} />

        <Search
          allowClear
          placeholder="Cari"
          onSearch={onSearch}
          style={{ width: 250 }}
        />
      </Row>

      <ImportButton />

      <Row style={{ minHeight: 'calc(100vh - 500px)', margin: '30px 0' }} gutter={[16, 16]}>
        {dataSource.map((data, index) => {
          return (
            <Col sm={24} lg={12} xl={8}>
              <NotificationCard
                key={index}
                src={images[index]}
                job={data.job}
                href={data.href}
                name={data.name}
                title={data.title}
                extra={data.extra}
                button={data.button}
              />
            </Col>
          );
        })}
      </Row>

      <Row style={{ marginTop: '30px' }} justify="center">
        {pagination.total > 1 && (
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            size={pagination.size}
            showSizeChanger={false}
            onChange={paginationOnChange}
          />
        )}
      </Row>

      <BottomLoading active={loading} />
    </div>
  );
}
