import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { message, Popover } from 'antd';

export default function ErrorButtonHover({status, record}) {
  const arrayRecord = Array.from(record.error_message)

  const content = (
    <div>
      {arrayRecord.length &&
        arrayRecord.map((element, i) => {
          if (element){
            return (<p key={i}>{element}</p>)
          }
        })}
    </div>
  )
  return status ? (
    <CheckCircleOutlined style={{ fontSize: '18px', color: '#60b23c' }} />
  ) : (
    <Popover content={content} placement="topLeft" title="Error Message">
      <WarningOutlined style={{ fontSize: '18px', color: '#d64219', cursor: 'pointer' }} />
    </Popover>
  );
}