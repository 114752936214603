import './ImportExcelFile.less';
import { ImportExcelFilePropType } from './ImportExcelFile.interface';
import Constant from '../../../../config/Constants';
import { useState } from 'react';
import { excelFileToFormDataFormat } from '../../ImportExcelFileTemplate.service';

export default function ImportExcelFile({ chooseFileButton }: ImportExcelFilePropType) {
  let [fileName, setFileName] = useState('');

  const onSelectedFile = async (e) => {
    const file = e.target.files[0];
    setFileName(file.name);

    if (chooseFileButton && chooseFileButton.onSelected) {
      const excelFile = await excelFileToFormDataFormat(file)
      chooseFileButton.onSelected(excelFile);
    }
  };

  return (
    <div className="import-csv-section">
      <h3>Pilih file CSV atau excel untuk diimpor</h3>

      <div className="flex-container">
        <label id={chooseFileButton?.disabled ? 'disabled-label' : ''} className="select-file" htmlFor="csv-section-selected-file">
          Pilih Dokumen
        </label>
        <span className="selected-filename">{fileName}</span>

        <input
          disabled={chooseFileButton?.disabled}
          id="csv-section-selected-file"
          className="toggle-file"
          type="file"
          accept={Constant.FILE_EXCEL}
          onChange={(e: any) => onSelectedFile(e)}
          onClick={(e: any) => {
            e.target.value = null;
          }}
        />
      </div>
    </div>
  );
}
