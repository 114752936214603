import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { BreadcrumbConf } from "../../../../../components/breadcrumb/Breadcrumb.interface";
import { createExcelFromByte } from "../../../payroll/configuration/master-payroll/list-master-payroll/ListMasterPayroll.service";
import { ListLoanReportConstant } from "../../loan-report/list-loan-report/ListLoanReport.constant";
import ListLoanInstallmentReportUseCase from "./ListLoanInstallmentReport.usecase";
import ListLoanInstallmentReportTemplate from "../template/ListLoanInstallmentReportTemplate";
import { GetDetailInstallment } from "../../../../../@core/mapper/loan/AddSubmissionLoan.mapper";

const constant = ListLoanReportConstant
const usecase = new ListLoanInstallmentReportUseCase()

let name
let employee

export default function ListLoanInstallmentReport() {
  const location:any = useLocation();

  const [isLoading, setIsLoading] = useState(false)
  const [dataSource, setDataSource] = useState([]);
  const [disableExportButton, setDisableExportButton] = useState(true)
  const [breadcrumbConf, setBreadcrumbConf] = useState<BreadcrumbConf>({
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Laporan Angsuran Pinjaman',
        href: "/v2/app/hr-loan/loan-installment-report/filter/",
      },
    ],
  })

  useEffect(() => {
    initialPage()
  }, [])

  function initialPage() {
    setIsLoading(true);
    const tempFilter:any = location.state?.data;
    
    if(tempFilter) {
      name = tempFilter?.loan_number;
      employee = tempFilter?.employee
    }
    
    proccessFilterData({ name, employee })

    usecase.getAll({ name }).then((res: any) => {
      const content = res.data.content;

      const resultDataSource = content ? GetDetailInstallment(content) 
        : constant.DEFAULT_EMPTY_DATA_SOURCE

      setDisableExportButton(!content.length)
      setDataSource([...resultDataSource]);
      setIsLoading(false)
    })
  }

  const dropdownButton = {
    visible: true,
    disabled: isLoading || disableExportButton,
    options: [
      { key: 'export', label: 'Ekspor', onClick: () => exportData() },
    ]
  };

  function exportData() {
    setIsLoading(true)

    usecase.export({ name }).then((resp: any) => {
      setIsLoading(false);
      const content = resp.data.content;
      if (resp.status === 200) {
        const bytes = content.data
        const fileName = content.filename
        createExcelFromByte(bytes, fileName);
      }
    })
  }

  function proccessFilterData(filterData) {
    const manual_data = []
    if (filterData.employee) {
      manual_data.push(manualData(filterData.employee))
    }

    if (filterData.name) {
      manual_data.push(manualData(filterData.name))
    }
    
    if (manual_data.length && breadcrumbConf.manualData.length <= 1) {
      const copyBreadcrumb = JSON.parse(JSON.stringify(breadcrumbConf))
      copyBreadcrumb.manualData.push(...manual_data)
      setBreadcrumbConf(copyBreadcrumb)
    }
  }

  function manualData(displayPathName: string) {
    return { clickable: false, displayPathName, href: null }
  }

  return (
    <div className="list-loan-installment-report">
      <ListLoanInstallmentReportTemplate 
        breadCrumbConf={breadcrumbConf}
        dropdownButton={dropdownButton}
        dataSource={dataSource}
      />
    </div>
  )
}