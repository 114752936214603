import { Button, Col, Form, Radio, Row, Select } from "antd"

export const RadioGroupMasterSchedule = ({ value, onChange, onClick, viewMode }) => {
  return (
    <Col>
      <Row>
        <Radio.Group
          disabled={viewMode}
          value={value.is_all_day}
          onChange={(v) => onChange({ is_all_day: v.target.value, selected_day: [] })}
        >
          <Radio value={true}>
            <div
              style={{ fontWeight: '600' }}
            >Terapkan ke semua hari</div>
          </Radio>
          <Form.Item
            style={{ marginTop: '10px' }}
          >
            <Radio value={false}>
              <div
                style={{ fontWeight: '600' }}
              >Pilih Hari</div>
            </Radio>
            <Select
              value={value.selected_day}
              onChange={(v) => onChange({ is_all_day: value.is_all_day, selected_day: v })}
              disabled={value.is_all_day || viewMode}
              mode="multiple"
              placeholder="Pilih hari"
              style={{ width: '200px', marginTop: '10px' }}
            >
              <Select.Option value="senin">Senin</Select.Option>
              <Select.Option value="selasa">Selasa</Select.Option>
              <Select.Option value="rabu">Rabu</Select.Option>
              <Select.Option value="kamis">Kamis</Select.Option>
              <Select.Option value="jumat">Jumat</Select.Option>
              <Select.Option value="sabtu">Sabtu</Select.Option>
              <Select.Option value="minggu">Minggu</Select.Option>
            </Select>
          </Form.Item>
        </Radio.Group>
      </Row>

      <Button 
        disabled={viewMode}
        type="primary"
        onClick={() => onClick(value)}
      >
        Terapkan
      </Button>
    </Col>
  )
}