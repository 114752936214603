import { useEffect, useState } from 'react';
import { Image, Row, Space } from 'antd';
import PageLoader from '../../../components/loader/PageLoader';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';
import { EmployeeNewsUsecase } from './EmployeeNews.usecase';
import { aesGcmDecrypt } from '../../../services/Encrypter';
import './EmployeeNews.less';

const usecase = new EmployeeNewsUsecase();

let newsId = '';

export default function EmployeeNewsDetail({ match }) {
  newsId = match.params.newsId;
  let [isLoading, setIsLoading] = useState(false);
  let [dataNews, setDataNews] = useState<any>();
  let [dataSource, setDataSource] = useState<any>(null);

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Dashboard',
        href: '/v2/app/employee-dashboard',
      },
      {
        clickable: true,
        displayPathName: 'Berita',
        href: '/v2/app/employee/list-news',
      },
      {
        clickable: false,
        displayPathName: `${dataNews?.name}`,
      },
    ],
  };

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {
    setIsLoading(true);
    const newsDetail: any = await usecase.getNewsDetail(newsId);
    if (newsDetail?.data?.content) {
      setDataNews(newsDetail?.data?.content);
      if (newsDetail?.data?.content?.view_list_lampiran) {
        decriptImage(newsDetail?.data?.content?.view_list_lampiran);
      }
    }

    setIsLoading(false);
  }

  async function decriptImage(source) {
    const temp = await Promise.all(
      !!source &&
        source.map(async (item) => {
          let tempFoto = null;
          if (item.file) {
            tempFoto = await aesGcmDecrypt(item.file);
          }
          return {
            ...item,
            decryptedFoto: tempFoto,
          };
        }),
    );
    setDataSource(temp);
  }

  const RawHTML = ({ children, className = '' }) => (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '<br />') }}
    />
  );

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div style={{ width: '100%' }}>
      <Breadcrumb breadcrumbConf={breadcrumbConf} />
      {dataNews && (
        <div
          style={{
            border: 'solid 0px grey',
            padding: 25,
            borderRadius: 8,
            background: '#fff',
            marginTop: 20,
            maxHeight: '80vh',
            overflow: 'auto',
          }}
        >
          <h2>{dataNews?.name}</h2>
          <div style={{ padding: '30px 0px' }} className='news-detail'>
            <RawHTML>{dataNews?.description}</RawHTML>
          </div>
          <div>
            {dataSource && dataSource.length > 0 && <h3>Lampiran</h3>}

            <Row>
              <Space>
                {dataSource &&
                  dataSource.map((item) => {
                    return (
                      <div>
                        <Image
                          width={'30vh'}
                          height={'fit-content'}
                          preview={true}
                          src={item?.decryptedFoto}
                        />
                      </div>
                    );
                  })}
              </Space>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
}
