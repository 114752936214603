import { useEffect, useState } from "react";
import LocalNotificationService from "../../../../../services/LocalNotification.service";
import ToastService from "../../../../../services/Toast.service";
import AddMasterRegionUsecase from "./AddMasterRegion.usecase";
import { ToolbarDetailActionButton } from "../../components/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface";
import AddMasterRegionTemplate from "../template/AddMasterRegionTemplate";

const usecase = new AddMasterRegionUsecase()
const toastService = new ToastService()
const localNotificationService = new LocalNotificationService();

export default function AddMasterRegion() {
  const [isLoading, setIsLoading] = useState(false)

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Master Region',
        href: "/v2/app/hr-employee/master-region",
      },
      {
        clickable: false,
        displayPathName: 'Baru',
        href: "/v2/app/hr-employee/master-region/new/",
      }
    ],
  };

  const submitButton = { active: true, disabled: false };
  const firstActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: true,
    disabled: false,
    onClick: () => {
      const back = window.confirm("Anda Ingin Kembali")
      if (back) {
        window.location.href = "/v2/app/hr-employee/master-region"
      }
    },
  };

  const onFinish = (e) => {
    setIsLoading(true)
    const body = {
      region_name: e.name?.trim(),
      active: true,
    }
    usecase.create(body).then(
      (res) => {
        const data = res.data
        if (data.code === 200) {
          const message = data.message
          localNotificationService.openSuccess(message, '', 1)
        }
        setTimeout(() => {
          window.location.href = "/v2/app/hr-employee/master-region"
        }, 1000)
      }
    ).catch((res) => {
      toastService.error(res?.data?.error?.message)
      setIsLoading(false)
    })
  }
  
  return (
    <div className="add-employee-region">
      <AddMasterRegionTemplate
        breadcrumbConf={breadcrumbConf}
        dropdownActionButton={{ visible: false }}
        firstActionButton={firstActionButton}
        secondActionButton={{ active: false }}
        thirdActionButton={{ active: false }}
        fourthActionButton={{ active: false }}
        onFinish={onFinish}
        submitButton={submitButton}
        isLoading={isLoading}
        historyId={null}
      />
    </div>
  )
}