import { Form, Button, Input, Space, Card } from 'antd';
import { useState } from 'react';
import { useUserDispatch, loginUser } from '../../context/UserContext';
import useQuery from '../../services/UseQuery.service';
import hiraLogo from '../../components/asset/hira_logo.png';

export default function Login(props) {
  // global
  var userDispatch = useUserDispatch();
  var query = useQuery();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [companyCode, setCompanyCode] = useState('');
  var [login, setLogin] = useState('');
  var [pass, setPass] = useState('');
  var [goto, setGoto] = useState(query.get('goto'));

  return (
    <div className="login">
      <Space align="center" direction="vertical">
        <div style={{ margin: '40px 0' }}>
          <img src={hiraLogo} />
        </div>
        <Card style={{ width: '480px' }}>
          <Form
            layout="vertical"
            onFinish={(e) => {
              loginUser(
                userDispatch,
                companyCode,
                login,
                pass,
                props.history,
                setIsLoading,
                setError,
                goto,
              );
            }}
          >
            <Form.Item name="company_code" label="Kode Perusahaan">
              <Input size="large" onChange={(e) => setCompanyCode(e.target.value)} />
            </Form.Item>
            <Form.Item name="login" label="NIK atau Email" rules={[{ required: true }]}>
              <Input
                required
                size="large"
                autoCapitalize="off"
                onChange={(e) => setLogin(e.target.value)}
              />
            </Form.Item>

            <Form.Item name="password" label="Password" rules={[{ required: true }]}>
              <Input
                required
                size="large"
                type="password"
                onChange={(e) => setPass(e.target.value)}
              />
            </Form.Item>
            <Form.Item name="password">
              <Button type="primary" htmlType="submit" size="large" loading={isLoading} block>
                Masuk
              </Button>
            </Form.Item>
            <div className="row text-forgot-pass">
              <div className="col-sm-6 text-left"></div>
              <div className="col-sm-6 text-right">
                <a href={window.location.origin + '/v2/forgot-password'}>
                  Lupa Password?
                </a>
              </div>
            </div>
            <br />
            <div style={{fontSize: '12px', textAlign: 'center', width: '100%'}}>PT Bank OCBC NISP Tbk berizin dan diawasi oleh Otoritas Jasa Keuangan & Bank Indonesia, serta merupakan peserta penjaminan LPS</div>
          </Form>
        </Card>
      </Space>
    </div>
  );
}
