import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col, Radio, Space, InputNumber, Select, Spin } from 'antd';
import FormBuilder from 'antd-form-builder';
import GoogleMap from 'google-map-react';
import Breadcrumb from '../../../../../../components/breadcrumb/Breadcrumb';
import { useHistory } from 'react-router-dom';
import LocalNotificationService from '../../../../../../services/LocalNotification.service';
import AddEmployeeAttendance from './HrAddEmployeeAttendance.usecase.impl';
import moment from 'moment';

const useCaseImpl = new AddEmployeeAttendance();
const localNotificationService = new LocalNotificationService();

export default function HrAddEmployeeAttendance() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [employeeList, setEmployeeList] = useState([]);
  const [checkinLocation, setCheckinLocation] = useState<any>({ lat: 0, lng: 0 });
  const [checkoutLocation, setCheckoutLocation] = useState<any>({ lat: 0, lng: 0 });
  const [checkinStatus, setCheckinStatus] = useState<boolean>(false);
  const [checkoutStatus, setCheckoutStatus] = useState<boolean>(false);
  const [geoFencing, setGeoFencing] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  function arePointsNear(checkPoint, centerPoint, km) {
    var ky = 40000 / 360;
    var kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky;
    var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
    var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
    return Math.sqrt(dx * dx + dy * dy) <= km;
  }

  const CheckinWrapper: any = ({ value = {}, onChange }) => {
    function _onClickCheckin(obj) {
      setCheckinLocation({ lat: obj.lat, lng: obj.lng });
      onChange?.({ lat: obj.lat, lng: obj.lng });
    }
    const renderMarkers = (map, maps) => {
      const locationStatus = arePointsNear(
        { lat: checkinLocation?.lat, lng: checkinLocation?.lng },
        { lat: geoFencing?.lat, lng: geoFencing?.lng },
        geoFencing?.radius / 1000,
      );
      setCheckinStatus(locationStatus);
      let marker = new maps.Marker({
        position: { lat: checkinLocation?.lat, lng: checkinLocation?.lng },
        map,
        title: 'Checkin',
      });
      return marker;
    };

    const renderCircle = (map, maps, geo) => {
      let marker = new maps.Circle({
        center: { lat: geo.lat, lng: geo.lng },
        map,
        radius: geo.radius,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.3,
      });
      return marker;
    };

    return (
      <div style={{ position: 'relative', width: '100%', height: '30vh', borderRadius: 10 }}>
        <GoogleMap
          defaultZoom={12}
          defaultCenter={[
            geoFencing?.lat || checkinLocation?.lat || -6.3004,
            geoFencing?.lng || checkinLocation?.lng || 106.635039,
          ]}
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API }}
          yesIWantToUseGoogleMapApiInternals
          onClick={_onClickCheckin}
          onGoogleApiLoaded={({ map, maps }) => {
            if (!!checkinLocation) {
              renderMarkers(map, maps);
            }
            if (geoFencing && geoFencing.as_attendance && geoFencing.is_radius) {
              if (geoFencing.used_when == 'check_in_out' || geoFencing.used_when == 'check_in') {
                renderCircle(map, maps, geoFencing);
              }
            }
          }}
        ></GoogleMap>
      </div>
    );
  };

  const CheckoutWrapper: any = ({ value = {}, onChange }) => {
    function _onClickCheckout(obj) {
      setCheckoutLocation({ lat: obj.lat, lng: obj.lng });
      onChange?.({ lat: obj.lat, lng: obj.lng });
    }
    const renderMarkers = (map, maps) => {
      const locationStatus = arePointsNear(
        { lat: checkoutLocation?.lat, lng: checkoutLocation?.lng },
        { lat: geoFencing?.lat, lng: geoFencing?.lng },
        geoFencing?.radius / 1000,
      );
      setCheckoutStatus(locationStatus);
      let marker = new maps.Marker({
        position: { lat: checkoutLocation?.lat, lng: checkoutLocation?.lng },
        map,
        title: 'Checkout',
      });
      return marker;
    };

    const renderCircle = (map, maps, geo) => {
      let marker = new maps.Circle({
        center: { lat: geo.lat, lng: geo.lng },
        map,
        radius: geo.radius,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.3,
      });
      return marker;
    };

    return (
      <div style={{ position: 'relative', width: '100%', height: '30vh', borderRadius: 10 }}>
        <GoogleMap
          defaultZoom={12}
          defaultCenter={[
            geoFencing?.lat || checkoutLocation?.lat || -6.3004,
            geoFencing?.lng || checkoutLocation?.lng || 106.635039,
          ]}
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API }}
          onClick={_onClickCheckout}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            if (!!checkinLocation) {
              renderMarkers(map, maps);
            }
            if (geoFencing && geoFencing.as_attendance && geoFencing.is_radius) {
              if (geoFencing.used_when == 'check_in_out' || geoFencing.used_when == 'check_out') {
                renderCircle(map, maps, geoFencing);
              }
            }
          }}
        ></GoogleMap>
      </div>
    );
  };

  const CheckinComp = ({ value, onChange }) => {
    return (
      <div style={{ border: 'solid 1px grey', padding: 20 }}>
        <CheckinWrapper />
        <div style={{ padding: 20 }}>
          {geoFencing?.is_radius ? (
            checkinStatus || geoFencing?.used_when === 'check_out' ? (
              <div>
                <p style={{ fontWeight: 'bold', color: 'green' }}>Anda berada di area kantor</p>
              </div>
            ) : (
              <div>
                <p style={{ fontWeight: 'bold', color: 'red' }}>Anda tidak berada di area kantor</p>
              </div>
            )
          ) : null}
        </div>
      </div>
    );
  };

  const CheckoutComp = ({ value, onChange }) => {
    return (
      <div style={{ border: 'solid 1px grey', padding: 20 }}>
        <CheckoutWrapper />
        <div style={{ padding: 20 }}>
          {geoFencing?.is_radius ? (
            checkoutStatus || geoFencing?.used_when === 'check_in' ? (
              <div>
                <p style={{ fontWeight: 'bold', color: 'green' }}>Anda berada di area kantor</p>
              </div>
            ) : (
              <div>
                <p style={{ fontWeight: 'bold', color: 'red' }}>Anda tidak berada di area kantor</p>
              </div>
            )
          ) : null}
        </div>
      </div>
    );
  };

  const meta: any = {
    columns: 1,
    fields: [
      {
        key: 'employee',
        label: 'Nama Karyawan',
        widget: 'select',
        options: employeeList,
        required: true,
        message: 'Nama Karyawan harus diisi',
        widgetProps: {
          showSearch: true,
        },
        onChange: async (value) => {
          const temp_id = JSON.parse(value)?.id;
          const params = {
            emp_id: temp_id,
          };
          const getGeofencing = await useCaseImpl.getGeofencing(params);
          if (!!getGeofencing) {
            setGeoFencing(getGeofencing);
          }
        },
      },
      {
        key: 'is_absent',
        label: 'Tidak Masuk Kerja',
        widget: 'checkbox',
      },
      {
        key: 'checkin_date',
        label: 'Waktu Check In',
        widget: 'date-picker',
        required: true,
        widgetProps: {
          showTime: { defaultValue: moment('00:00', 'HH:mm') },
          format: 'DD-MM-YYYY HH:mm',
        },
      },
      {
        key: 'checkin_loc',
        label: 'Lokasi Check In',
        forwardRef: true,
        widget: CheckinComp,
      },
      {
        key: 'is_checkout',
        label: 'Checkout',
        widget: 'checkbox',
      },
      {
        key: 'checkout_date',
        label: 'Waktu Check Out',
        widget: 'date-picker',
        disabled: true,
        widgetProps: {
          showTime: { defaultValue: moment('00:00', 'HH:mm') },
          format: 'DD-MM-YYYY HH:mm',
        },
      },
      {
        key: 'checkout_loc',
        label: 'Lokasi Check Out',
        forwardRef: true,
        widget: CheckoutComp,
      },
      {
        key: 'duration',
        label: 'Durasi Jam Kerja',
        readOnly: true,
        defaultValue: '00:00',
      },
      {
        key: 'reason',
        label: 'Alasan',
        required: false,
        widget: 'textarea',
      },
    ],
  };

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {
    setIsLoading(true);
    const employeeList = await useCaseImpl.getEmpl();
    setEmployeeList(employeeList);
    setIsLoading(false);
  }

  function getDateDiff(startDate, endDate) {
    const diffMs = Math.abs(new Date(endDate).getTime() - new Date(startDate).getTime());
    const totalMinutes = Math.floor(diffMs / (1000 * 60));
    let hours: any = Math.floor(totalMinutes / 60);
    let minutes: any = totalMinutes - hours * 60;
    hours < 10 && (hours = `0${hours}`);
    minutes < 10 && (minutes = `0${minutes}`);
    return `${hours}:${minutes}`;
  }

  async function onFinish(e) {
    if (e) {
      submitToEndPoint(e);
    }
  }
  async function submitToEndPoint(e) {
    setIsLoading(true);
    let tempParam: any = {};
    e.employee && (tempParam['employee_id'] = JSON.parse(e.employee)?.id);
    const params = {
      status_absent_date: e?.checkin_date
        ? moment(e.checkin_date).format('YYYY-MM-DD')
        : moment(new Date()).format('YYYY-MM-DD'),
      reason: e.reason,
      status_worked: !e.is_absent,
      status_absent: !!e.is_absent,
      check_in: e?.checkin_date ? moment(e.checkin_date).format('YYYY-MM-DD HH:mm:ss') : null,
      check_out: e?.checkout_date ? moment(e.checkout_date).format('YYYY-MM-DD HH:mm:ss') : null,
      gmap_loc: !e.is_absent
        ? `{\"position\":{\"lat\":${checkinLocation?.lat},\"lng\":${checkinLocation?.lng}},\"position_out\":{\"lat\":${checkoutLocation?.lat},\"lng\":${checkoutLocation?.lng}},\"zoom\":2}`
        : null,
      show_for_edit: true,
      check_in_radius: checkinStatus ? 'inside' : 'outside',
      check_out_radius: checkoutStatus ? 'inside' : 'outside',
      ...tempParam,
    };

    useCaseImpl.submitAttendance(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          history.push('/app/hr-attendance/employee-attendance');
          localNotificationService.openSuccess('Absen Berhasil dibuat', '', 3);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }

  async function onChangeForm(value) {
    if (!!value) {
      switch (Object.keys(value)[0]) {
        default:
          break;
        case 'is_absent':
          forceUpdate();
          break;
        case 'is_checkout':
          forceUpdate();
          break;
        case 'checkout_date':
          forceUpdate();
          break;
      }
    }
  }

  let newMeta = meta;
  if (form.getFieldValue('is_absent')) {
    if (!!form.getFieldValue('is_absent')) {
      newMeta.fields = [
        {
          key: 'employee',
          label: 'Nama Karyawan',
          widget: 'select',
          options: employeeList,
          required: true,
          message: 'Nama Karyawan harus diisi',
          widgetProps: {
            showSearch: true,
          },
          onChange: async (value) => {
            const temp_id = JSON.parse(value)?.id;
            const params = {
              emp_id: temp_id,
            };
            const getGeofencing = await useCaseImpl.getGeofencing(params);
            if (!!getGeofencing) {
              if (!!getGeofencing) {
                setGeoFencing(getGeofencing);
              }
            }
          },
        },
        {
          key: 'is_absent',
          label: 'Tidak Masuk Kerja',
          widget: 'checkbox',
        },
        {
          key: 'reason',
          label: 'Alasan',
          required: true,
          widget: 'textarea',
          message: 'Alasan harus diisi',
        },
      ];
    }
  }
  if (form.getFieldValue('is_checkout')) {
    newMeta.fields[5].disabled = false;
    newMeta.fields[5].required = true;
  }

  if (form.getFieldValue('checkin_date') && form.getFieldValue('checkout_date')) {
    form.setFieldsValue({
      duration: getDateDiff(
        form.getFieldValue('checkout_date'),
        form.getFieldValue('checkin_date'),
      ),
    });
  }

  return (
    <div
      className="add-attendance-page"
      style={{ position: 'relative', height: 'calc(100vh - 100px)' }}
    >
      <Form form={form} layout="horizontal" onFinish={onFinish} onValuesChange={onChangeForm}>
        <div>
          <div className="form-wrapper">
            <div>
              <Breadcrumb />
              <Form.Item className="form-footer" style={{ width: '100%', marginTop: 20 }}>
                <Button type="primary" onClick={() => form.submit()} disabled={isLoading}>
                  Simpan
                </Button>
                &nbsp; &nbsp;
                <Button onClick={() => history.push('/app/hr-attendance/employee-attendance')}>
                  Batal
                </Button>
              </Form.Item>
              <div
                style={{
                  border: '1px solid #000',
                  padding: 10,
                  overflowY: 'scroll',
                  height: '70vh',
                }}
              >
                <FormBuilder form={form} meta={meta} />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
