export function GetFilterLoanMapper(filter_list) {
  return filter_list?.map((filter) => {
    return {
      key: filter?.name,
      submission_number: filter?.name,
      loan_config: filter?.config_name,
      tenor: filter?.term_of_installment,
      total_pokok: filter?.value_installment,
      total_payment: filter?.value_installment_paid,
      remnant: filter?.value_installment_remaining,
      nik: filter?.nik,
      employee_name: filter?.employee_name,
      job: filter?.job_name,
    };
  })
}
