import { Button, Col, Dropdown, Menu, Row, Table } from 'antd';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import { ListLoanInstallmentReportInterface } from './LoanInstallmentReportTemplate.interface';
import { DownOutlined } from '@ant-design/icons';
import TableDetailLoan from './components/TableDetailLoan';

export default function ListLoanInstallmentReportTemplate(
  props: ListLoanInstallmentReportInterface,
) {
  const DropdownButton = () => {
    const menu =
      props.dropdownButton && props.dropdownButton.visible ? (
        <Menu>
          {props.dropdownButton.options.map((opt) => (
            <Menu.Item disabled={opt.disabled} onClick={() => opt.onClick()} key={opt.key}>
              {opt.label}
            </Menu.Item>
          ))}
        </Menu>
      ) : null;

    return props.dropdownButton && props.dropdownButton.visible ? (
      <Dropdown disabled={props.dropdownButton.disabled} overlay={menu}>
        <Button type="primary">
          Tindakan <DownOutlined />
        </Button>
      </Dropdown>
    ) : null;
  };

  return (
    <div style={{ height: 'calc(100vh - 105px)', overflowY: 'auto', overflowX: 'hidden' }}>
      <Breadcrumb breadcrumbConf={props.breadCrumbConf} />

      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '30px' }}>
        <DropdownButton />
      </div>

      <TableDetailLoan dataSource={props.dataSource} />
    </div>
  );
}
