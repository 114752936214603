import Cookies from 'js-cookie';

type CookiesKeyword = 'hira';

export default class CookiesService {
  set(keyword: CookiesKeyword = 'hira', content, expired) {
    Cookies.set(keyword, content, expired);
  }

  remove(keyword: CookiesKeyword = 'hira') {
    Cookies.remove(keyword);
  }

  getJSON(keyword: CookiesKeyword = 'hira') {
    return Cookies.getJSON(keyword);
  }
}
