import { Form, Tabs } from "antd"
import Breadcrumb from "../../../../../../components/breadcrumb/Breadcrumb"
import BottomLoading from "../../../../../../components/bottom-loading/BottomLoading"
import { AddMasterWorkingScheduleTemplatePropsType } from "./AddMasterWorkingScheduleTemplate.interface";
import './AddMasterWorkingScheduleTemplate.less'
import { MemoizeHistoryTabs } from "../../../../component/history-payroll-period-tabs/HistoryPayrollPeriodTabs";
import FormBuilder from "antd-form-builder";
import { RadioGroupMasterSchedule } from "./components/RadioGroupMasterSchedule";
import TableDaysMasterSchedule from "./components/TableDaysMasterSchedule";
import { TimePickerMasterSchedule } from "./components/TimePickerMasterSchedule";
import ToolbarButtonDetailTemplate from "../../../../component/toolbar-button-detail-template/ToolbarButtonDetailTemplate";
import React, { useEffect } from "react";
import { AttendanceToleranceInputNumber } from "./components/AttendanceToleranceInputNumber";
import { DisplayTotalMasterSchedule } from "./components/DisplayTotalMasterSchedule";
import { getTotalTime, onClickRadioDays } from "./AddMasterWorkingScheduleTemplate.service";
import { DefaultDataSourceMasterWorking } from "../add-master-working-schedule/AddMasterWorkingSchedule.constant";

export default function AddMasterWorkingScheduleTemplate(props: AddMasterWorkingScheduleTemplatePropsType) {
  const [form] = Form.useForm()
  const [, updateState] = React.useState<any>(); const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    props.isDetail && form.setFieldsValue({...props.dataSource })
  }, [props.dataSource])

  useEffect(() => {
    !props.isDetail && form.setFieldsValue({ ...DefaultDataSourceMasterWorking })
  }, [])

  const onFinish = (values) => {
    if (props.onFinish) {
      props.onFinish(form.getFieldsValue());
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (props.onFinishFailed) {
      props.onFinishFailed(form.getFieldsValue());
    }
  };

  const onFieldsChange = (changedFields) => {
    if (props.onFieldChange) {
      props.onFieldChange(changedFields, form);
    }
  };

  const meta = {
    columns: 3,
    colon: false,
    fields: [
      {
        key: 'name',
        label: 'Nama Jadwal Kerja',
        viewMode: props.viewMode,
        initialValue: "",
        colSpan: 3,
        rules: [
          {
            required: true,
            whitespace: true,
            message: "Nama Jadwal Kerja Harus Diisi",
          }
        ],
        formItemLayout: { 
          labelCol: { span: 3 }, 
          wrapperCol: { span: 6 } 
        },
      },
      {
        key: 'attendance_tolerance',
        label: 'Toleransi Kehadiran',
        colSpan: 3,
        initialValue: "",
        widget: AttendanceToleranceInputNumber,
        widgetProps: {
          isLoading: props.isLoading,
          viewMode: props.viewMode
        },
        formItemLayout: { 
          labelCol: { span: 3 }, 
          wrapperCol: { span: 3 } 
        },
      },
      {
        key: 'is_radius',
        label: 'Gunakan radius absensi',
        colSpan: 1,
        initialValue: false,
        disabled: props.viewMode,
        widget: 'checkbox',
        formItemLayout: { 
          labelCol: { span: 23 }, 
          wrapperCol: { span: 24 } 
        },
      },
      {
        key: 'radius',
        label: 'Pada saat',
        colSpan: 2,
        viewMode: props.viewMode,
        required: true,
        message: 'Pada saat Kerja Harus Diisi',
        widget: 'select',
        options: [
          { key: 'Check In & Out', label: 'Check In & Out', value: 'Check In & Out' },
          { key: 'Check In', label: 'Check In', value: 'Check In' },
          { key: 'Check Out', label: 'Check Out', value: 'Check Out' },
        ],
        formItemLayout: { 
          labelCol: { span: 3 }, 
          wrapperCol: { span: 4 } 
        },
      },
      {
        key: 'separator',
        colSpan: 4,
        render() {
          return (
            <hr />
          );
        },
      },
      {
        key: 'start_time',
        label: 'Jam Mulai',
        viewMode: false,
        colSpan: 1,
        widget: TimePickerMasterSchedule,
        widgetProps: {
          viewMode: props.viewMode
        },
        formItemLayout: { 
          labelCol: { span: 12 }, 
          wrapperCol: { span: 24 } 
        },
      },
      {
        key: 'end_time',
        label: 'Jam Selesai',
        viewMode: false,
        colSpan: 1,
        widget: TimePickerMasterSchedule,
        widgetProps: {
          viewMode: props.viewMode
        },
        formItemLayout: { 
          labelCol: { span: 12 }, 
          wrapperCol: { span: 24 } 
        },
      },
      {
        key: 'break_duration',
        label: 'Durasi Istirahat',
        viewMode: false,
        colSpan: 1,
        widget: TimePickerMasterSchedule,
        widgetProps: {
          viewMode: props.viewMode
        },
        formItemLayout: { 
          labelCol: { span: 12 }, 
          wrapperCol: { span: 24 } 
        },
      },
      {
        key: 'radio_days',
        label: '',
        viewMode: false,
        colSpan: 3,
        initialValue: {
          is_all_day: true,
          selected_day: [],
        },
        widget: RadioGroupMasterSchedule,
        widgetProps: {
          viewMode: props.viewMode,
          onClick: (value) => onClickRadioDays(value, form)
        },
        formItemLayout: { 
          labelCol: { span: 12 }, 
          wrapperCol: { span: 24 } 
        },
      },
      {
        key: 'separator',
        colSpan: 4,
        render() {
          return (
            <hr />
          );
        },
      },
      {
        key: 'table_days',
        label: '',
        viewMode: false,
        colSpan: 2,
        widget: TableDaysMasterSchedule,
        widgetProps: { 
          form,
          isDetail: props.isDetail,
          viewMode: props.viewMode,
          onChange: (evt) => {
            getTotalTime(evt, form)
          }
        },
      },
      {
        key: 'break_total',
        label: 'Total Istirahat',
        widget: DisplayTotalMasterSchedule,
        colSpan: 3,
        initialValue: "",
        formItemLayout: { 
          labelCol: { span: 3 }, 
          wrapperCol: { span: 6 } 
        },
      },
      {
        key: 'work_time_total',
        label: 'Total Jam Kerja',
        widget: DisplayTotalMasterSchedule,
        initialValue: "",
        viewMode: false,
        colSpan: 3,
        formItemLayout: { 
          labelCol: { span: 3 }, 
          wrapperCol: { span: 6 } 
        },
      },
    ]
  }

  if (!form.getFieldsValue().is_radius) {
    meta.fields.splice(3, 1)
  }

  return (
    <div id="add-salary-component" style={{ height: 'calc(100vh - 100px)' }}>
      <Breadcrumb key={'breadcrumb'} breadcrumbConf={props.breadcrumbConf} />

      <Form
        name="basic"
        layout="horizontal"
        form={form}
        labelAlign="left"
        onFinish={onFinish}
        onValuesChange={forceUpdate}
        onFinishFailed={onFinishFailed}
        onFieldsChange={onFieldsChange}
      >
        <ToolbarButtonDetailTemplate
          submitButton={props.submitButton}
          firstActionButton={props.firstActionButton}
          secondActionButton={props.secondActionButton}
          thirdActionButton={props.thirdActionButton}
          fourthActionButton={props.fourthActionButton}
          dropdownActionButton={props.dropdownActionButton}
        />

        <div className="scrollable-content">
          <div className="form-wrapper-schedule">
            <FormBuilder meta={meta} form={form} />
            {props.historyId ? (
              <div className="tabs-wrapper">
                <Tabs defaultActiveKey="1" type="card">
                  <Tabs.TabPane tab="History" key="1">
                    <MemoizeHistoryTabs key="history-master" history_id={props.historyId} />
                  </Tabs.TabPane>
                </Tabs>
              </div>
            ) : null}
          </div>
        </div>
      </Form>

      <BottomLoading active={props.isLoading} />
    </div>
  )
}