export const MasterJobListConstant = {
    TABLE_HEADER: [
      {
        title: 'Jabatan',
        key: 'job_name',
        dataIndex: 'job_name',
        width: 200,
      },
      {
        title: 'Departemen',
        key: 'department_name',
        dataIndex: 'department_name',
        width: 180,
      },
      {
        title: 'Jabatan Atasan',
        key: 'job_atasan',
        dataIndex: 'job_atasan',
        width: 180,
      },
      {
        title: 'Atasan Langsung',
        key: 'atasan_langsung',
        dataIndex: 'atasan_langsung',
        width: 180,
      },
      {
        title: 'Jumlah Karyawan',
        key: 'sum_emp',
        dataIndex: 'sum_emp',
        width: 100,
      },
      {
        title: 'Status',
        key: 'is_active',
        dataIndex: 'is_active',
        width: 150,
      },
      { title: 'Dibuat Oleh', key: 'create_name', dataIndex: 'create_name', width: 150 },
      { title: 'Dibuat Pada', key: 'create_date', dataIndex: 'create_date', width: 150, sorter: true, },
      { title: 'Diubah Oleh', key: 'write_name', dataIndex: 'write_name', width: 150 },
      { title: 'Diubah Pada', key: 'write_date', dataIndex: 'write_date', width: 150, sorter: true, },
    ],
    
    SEARCH_COLUMN_KEY: [],
    SEARCH_COLUMN_COMPONENT: [
      { key: 'key', componentType: "text" },
      { key: 'job_name', componentType: "text" },
      { key: 'department_name', componentType: "text" },
    ],
    DEFAULT_EMPTY_DATA_SOURCE: [
      {
        key: 'text',
        job_name: '',
        department_name: '',
      },
    ],
  }

  export const SubordinatConstant = {
    TABLE_HEADER: [
      {
        title: 'Jabatan',
        key: 'job_name',
        dataIndex: 'job_name',
        width: 200,
      },
      {
        title: 'Departemen',
        key: 'department_name',
        dataIndex: 'department_name',
        width: 180,
      },
      {
        title: 'Jumlah Karyawan',
        key: 'sum_emp',
        dataIndex: 'sum_emp',
        width: 100,
      },
    ],
  }