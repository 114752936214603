import { Button, Col, Form, InputNumber, Row } from 'antd';
import GoogleMapReact from 'google-map-react';
import './SelectAttendanceLocation.less'
import { useRef } from 'react';

export default function SelectAttendanceLocation({ value, onChange, disabled }) {
  const inputRef = useRef(null)

  const CheckinWrapper: any = () => {
    function _onClickCheckin(obj) {
      
      !disabled && onChange({ lat: obj.lat, lng: obj.lng, radius: value?.radius });
    }

    async function init(maps, map) {
      const center = { lat: -6.3004, lng: 106.635039 };
      
      const defaultBounds = {
        north: center.lat + 0.1,
        south: center.lat - 0.1,
        east: center.lng + 0.1,
        west: center.lng - 0.1,
      };
      const input = document.getElementById('pac-input') as HTMLInputElement;
      const options = {
        bounds: defaultBounds,
        componentRestrictions: { country: 'id' },
        fields: ['address_components', 'geometry', 'icon', 'name'],
        strictBounds: false,
      };

      const autocomplete = new maps.places.Autocomplete(input, options);
      autocomplete.addListener('place_changed', () => onPlaceChange(autocomplete))
    }

    function onPlaceChange(autocomplete) {
      let place = autocomplete.getPlace();

      if (place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        onChange({ ...value, lat, lng})
      }
    }

    const renderMarkers = (map, maps) => {
      let circle = new maps.Circle({
        map,
        center: { lat: value?.lat, lng: value?.lng },
        radius: value?.radius,
        fillColor: 'blue',
        fillOpacity: 0.2,
        strokeWeight: 0.3,
        strokeColor: 'blue',
      });

      let marker = new maps.Marker({
        position: { lat: value?.lat, lng: value?.lng },
        map,
        title: 'Checkin',
        circle,
      });

      init(maps, map);
      return marker;
    };

    return (
      <div style={{ position: 'relative', width: '100%', height: '30vh', borderRadius: 10 }}>
        <GoogleMapReact
          defaultZoom={12}
          defaultCenter={[value?.lat || -6.3004, value?.lng || 106.635039]}
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API, libraries: 'places' }}
          yesIWantToUseGoogleMapApiInternals
          onClick={_onClickCheckin}
          onGoogleApiLoaded={({ map, maps }) => (!!value ? renderMarkers(map, maps) : {})}
        ></GoogleMapReact>
      </div>
    );
  };

  return (
    <Col id='select-attendance-location' style={{ position: 'relative', width: '100%', height: '30vh', borderRadius: 10 }}>
      <Row>
        <Col span={14}>
          <Form.Item label="Nama Tempat" labelAlign="left" colon={false}>
            <input
              id="pac-input"
              ref={inputRef}
              onFocus={() => inputRef.current.value = ''}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            style={{ marginLeft: '25px' }}
            label="Radius"
            name="radius"
            labelAlign="left"
            colon={false}
            initialValue={value?.radius}
            rules={[{ required: true, message: "Radius lokasi harus diisi."}]}
          >
            <Row>
              <Col span={20}>
                <InputNumber
                  disabled={disabled}
                  defaultValue={value?.radius}
                  style={{ width: '100%' }}
                  min={1}
                  parser={(value) => value?.replace('.', '') as unknown as number}
                  onChange={(v) => onChange({ ...value, radius: v })}
                />
              </Col>
              <Col span={4}>
                <Button disabled={true}>M</Button>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
      <div style={{ border: 'solid 1px grey', padding: 20 }}>
        <CheckinWrapper />
      </div>
    </Col>
  );
}
