import moment from "moment";

export function companyMapper(response) {
    const content = response.content ? response.content : null;
    let contentMap = null;
  
    if (content) {
      contentMap = content.map((data) => {
        const item = {
          id: data.id,
          key: data.id,
          company_code: data.company_code,
          name: data.name,
          company_type: data.company_type,
          phone_number: data.phone_number,
          company_address: data.company_address,
          province: data.province,
          city: data.city,
          company_category: data.company_category || '-',
          cfg_sum_employee: data.cfg_sum_employee || '-',
          bank_account_number: data.bank_account_number,
          pic: data.pic,
          pic_phone_number: data.pic_phone_number,
          email: data.email,
          status: data.state || '-',
          create_date: moment(data.create_date).format('DD-MM-YYYY HH:mm'),
          rejected_date: data.rejected_date ? moment(data.rejected_date).format('DD-MM-YYYY HH:mm') : '-',
          action: 'approval'
        };
        return item;
      });
    }
  
    return {
      content: contentMap,
      metaData: response.metadata,
    };
  }
  