import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Carousel, Image } from 'antd';
import { aesGcmDecrypt } from '../../../services/Encrypter';

const Carousels = (props: any) => {
  const history = useHistory();
  let [dataSource, setDataSource] = useState<any>(null);

  useEffect(() => {
    decriptImage(props?.banner);
  }, [props.banner]);

  async function decriptImage(source) {
    const temp = await Promise.all(
      !!source &&
        source.map(async (item) => {
          let tempFoto = null;
          if (item.image_url) {
            tempFoto = await aesGcmDecrypt(item.image_url);
          }
          return {
            ...item,
            decryptedFoto: tempFoto,
          };
        }),
    );
    setDataSource(temp);
  }
  const onChange = (currentSlide: number) => {
    console.log(currentSlide);
  };

  return (
    <Carousel autoplay afterChange={onChange}>
      {dataSource &&
        dataSource.map((item) => {
          return (
            <div>
              <Image
                width={'100%'}
                height={'fit-content'}
                preview={false}
                src={item?.decryptedFoto}
                onClick={() => window.open(item.redirection, '_blank').focus()}
              />
            </div>
          );
        })}
    </Carousel>
  );
};

export default Carousels;
