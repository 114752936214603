export const MasterHolidayListConstant = {
  TABLE_HEADER: [
    {
      title: 'Nama Hari Libur',
      key: 'holiday_name',
      dataIndex: 'holiday_name',
      sorter: true,
      width: 250,
    },
    {
      title: 'Dari',
      key: 'effective_date',
      dataIndex: 'effective_date',
      sorter: true,
      width: 200,
    },
    {
      title: 'Sampai',
      key: 'end_date',
      dataIndex: 'end_date',
      sorter: true,
      width: 200,
    },
    {
      title: 'Lama Libur',
      key: 'holiday_duration',
      dataIndex: 'holiday_duration',
      width: 120,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 150,
    },
    { title: 'Dibuat Oleh', key: 'create_name', dataIndex: 'create_name', width: 220 },
    { title: 'Dibuat Pada', key: 'create_date', dataIndex: 'create_date', width: 220, sorter: true, },
    { title: 'Diubah Oleh', key: 'write_name', dataIndex: 'write_name', width: 220 },
    { title: 'Diubah Pada', key: 'write_date', dataIndex: 'write_date', width: 220, sorter: true, },
  ],
  SEARCH_COLUMN_KEY: [
    { key: 'key', componentType: "search-input" },
    { key: 'holiday_name', componentType: "text" },
    { key: 'effective_date', componentType: "date" },
    { key: 'end_date', componentType: "date" },
    { key: 'status', componentType: 'select', options: [
      { label: 'Semua', value: null },
      { label: 'Aktif', value: 'aktif' },
      { label: 'Non-Aktif', value: 'tidak aktif' },
    ]},
  ],
  DEFAULT_EMPTY_DATA_SOURCE: [
    {
      key: '',
      holiday_name: '',
      effective_date: '',
      end_date: '',
      holiday_duration: '',
      status: '',
      recurring: '',
      create_name: '',
      create_date: '',
      write_name: '',
      write_date: '',
    },
  ],
}