import Constant from "../../../../../config/Constants"
import API from "../../../../../services/API.service"

export default class HrAddCompanyOvtConfiguration {

  getCompanyConfiguration() {
    const URL = Constant.URL_BASE_COMPANY + '/v3/config-company'
    return API.get(URL)
  }

  submitCompanyConfig(body): Promise<any> {
    const URL = Constant.URL_BASE_COMPANY + '/v3/config-company';
    return API.put(URL, body);
  }

  getProvince(params) {
    const URL = Constant.URL_BASE_COMPANY + '/v3/lov/country-state'
    // return API.get(URL)
    return API.get(URL, { params })
  }

  getCities(params) {
    const URL = Constant.URL_BASE_COMPANY + '/v3/lov/kab-kota'
    return API.get(URL, { params })
  }
  
  getDistrict(params) {
    const URL = Constant.URL_BASE_COMPANY + '/v3/lov/kecamatan'
    return API.get(URL, { params })
  }

  getSubdistrict(params) {
    const URL = Constant.URL_BASE_COMPANY + '/v3/lov/desa'
    return API.get(URL, { params })
  }

  getCompType() {
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/comp-type"
    return API.get(URL)
  }


}
