export const ListMassLeaveConstant = {
  TABLE_HEADER: [
    {
      title: 'Nama Cuti',
      key: 'leave_name',
      dataIndex: 'leave_name',
      width: 200,
    },
    {
      title: 'Tanggal Mulai Cuti',
      key: 'date_start',
      dataIndex: 'date_start',
      width: 200,
    },
    {
      title: 'Tanggal Berakhir Cuti',
      key: 'date_end',
      dataIndex: 'date_end',
      width: 200,
    },
    {
      title: 'Memotong Cuti',
      key: 'reduce_leave_rem',
      dataIndex: 'reduce_leave_rem',
      width: 200,
    },
    {
      title: 'Konfigurasi Cuti',
      key: 'config_name',
      dataIndex: 'config_name',
      width: 200,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 150,
    },
    { title: 'Dibuat Oleh', key: 'create_name', dataIndex: 'create_name', width: 120 },
    { title: 'Dibuat Pada', key: 'create_date', dataIndex: 'create_date', width: 200 },
    { title: 'Diubah Oleh', key: 'write_name', dataIndex: 'write_name', width: 120 },
    { title: 'Diubah Pada', key: 'write_date', dataIndex: 'write_date', width: 200 },
  ],
  SEARCH_COLUMN_KEY: [
    { key: 'key', componentType: "search-input" },
    { key: 'leave_name', componentType: "text" },
    {
      key: 'reduce_leave_rem', componentType: 'select', options: [
        { label: 'Semua', value: null },
        { label: 'Ya', value: true },
        { label: 'Tidak', value: false },
      ]
    },
    {
      key: 'status', componentType: 'select', options: [
        { label: 'Semua', value: null },
        { label: 'Aktif', value: true },
        { label: 'Non-Aktif', value: false },
      ]
    },
  ],
  DEFAULT_EMPTY_DATA_SOURCE: [
    {
      key: 'empty-data',
      leave_name: null,
      reduce_leave_rem: null,
      status: null,
      madeBy: '',
      madeOn: '',
      changeBy: '',
      changeOn: '',
    },
  ],
}