import moment from "moment";
import { DetailInstallmentType, DetailSubmisiionLoanType } from "../../../pages/hr/loan/submission-loan/template/AddSubmissionLoanTemplate.interface";
import { AddSubmissionLoanInterface, DetailLoanHistoryInterface } from "./SubmissionLoan.interface";
import { IDRFormat } from "../../interaction/currency/IDRFormat";

export function AddSubmissionLoanMapper(body, configList, empDetail): AddSubmissionLoanInterface {
  const configDetail = configList.find(e => e.value === body.loan_type)

  return {
    config_id: body.loan_type,
    employee_id: empDetail.employee_id,
    job_id: empDetail.job_id,
    loan_value_requested: body.proposed_loan,
    loan_interest : configDetail.loan_interest,
    single_installment: body.estimation,
    department_id : empDetail.department_id,
    description : body.detail,
    term_of_installment : body.proposed_tenor,
  }
}

export function GetDetailSubmissionLoan(loan_request, cfg, appr1, appr2): DetailSubmisiionLoanType {
  const employee_name = loan_request.nik + " - " +loan_request.employee_name
  const config_detail = cfg?.find(e => e.value === loan_request.config_id)
  
  return {
    employee: employee_name,
    submission_date: moment(loan_request.date_request).format("YYYY-MM-DD"),
    loan_type: config_detail.label,
    max_plafon: config_detail.loan_plafon,
    percent_interest: config_detail.loan_rule === 'tanpa_bunga' ? "Tanpa Bunga" : loan_request.loan_interest + " % (" + config_detail.loan_rule + ')',
    max_tenor: config_detail.max_installment + " Bulan",
    proposed_loan: IDRFormat(loan_request.loan_value_requested),
    proposed_tenor: loan_request.term_of_installment + " Bulan",
    estimation: null,
    name: loan_request.name,
    state: MappingActiveState(loan_request.state),
    detail: loan_request.description,
    approval1: appr1,
    approval2: appr2,
  }
}

export function GetDetailInstallment(loan_installment): DetailInstallmentType[] {
  const result = loan_installment.map(
    (installment, index) => {
      return {
        key: installment.ID,
        month_to: index + 1,
        pokok: installment.value_installment,
        bunga: installment.value_loan_interest,
        total_cicilan: installment.value_installment_total,
        status_pembayaran: MappingLoanState(installment.state),
        no_pembayaran: installment.installment_number,
      }
    }
  )

  return result
}

export function GetDetailApprovalLoan(loan_request): DetailSubmisiionLoanType {
  const employee_name = loan_request.nik + " - " +loan_request.employee_name
  
  return {
    employee: employee_name,
    submission_date: moment(loan_request.date_request).format("YYYY-MM-DD"),
    loan_type: loan_request.loan_type,
    max_plafon: loan_request.loan_plafon,
    percent_interest: loan_request.loan_rule === 'tanpa_bunga' ? "Tanpa Bunga" : `${loan_request.loan_interest} % (${loan_request.loan_rule})`,
    max_tenor: loan_request.max_installment + " Bulan",
    proposed_loan: IDRFormat(loan_request.loan_value_requested),
    proposed_tenor: loan_request.term_of_installment + " Bulan",
    estimation: loan_request.single_installment || 0,
    name: loan_request.name,
    state: MappingActiveState(loan_request.state),
    approval1: loan_request.approval1,
    approval2: loan_request.approval2,
    detail: loan_request.description,
  }
}

export function GetDetailHistory(loan_history): DetailLoanHistoryInterface[] {
  return loan_history?.map(
    (history, index) => {
      return {
        key: history.ID,
        no: index + 1,
        submission_number: history.name,
        loan_type: history.config_name,
        loan_total: history.value_installment,
        interest: history.value_loan_interest,
        tenor: history.term_of_installment,
        total_payment: history.total_payment,
      }
    }
  )
}

export function MappingLoanState(state) {
  switch(state) {
    case 'belum_lunas':
      return 'Belum Dibayar'
    case 'lunas':
      return "Dibayar"
    default:
      return ''
  }
}


function MappingActiveState(state) {
  switch(state) {
    case 'submit':
      return 'Pengajuan'
    case 'wait-approve2':
      return "Persetujuan Ke-2"
    case 'payment_rejected':
      return 'Ditolak'
    case 'rejected':
      return 'Ditolak'
    case 'cancel':
      return "Batal"
    case 'done':
      return 'Selesai'
    default:
      return ''
  }
}