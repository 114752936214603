import Constant from "../../../../../../config/Constants"
import API from "../../../../../../services/API.service"
import createOvtConfiguration from '../../../../../../@core/interaction/overtime/CreateOvtEmployeeConfiguration.interaction';
import updateOvtConfiguration from '../../../../../../@core/interaction/overtime/UpdateEmployeeOvtConfiguration.interaction'
import deactiveOvtConfiguration from '../../../../../../@core/interaction/overtime/DeactiveEmployeeOvtConfiguration.interaction'
import getOvtConfiguration from '../../../../../../@core/interaction/overtime/GetEmployeeOvertimeConfigurationDetail.interaction';
import getJobList from '../../../../../../@core/interaction/job/GetJobList.interaction';
import { jobListToOption } from '../../../../../../@core/mapper/job/GetJobList.mapper';

export default class HrUpdateCompanyOvtConfiguration {

  getJobList(params) {
    return getJobList(params).then((res: any) => jobListToOption(res.data.content));
  }

  getJob(temp={}) {
    const params:any = {
      ...temp
    }
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/job"
    return API.get(URL, {params}).then((res:any) => 
      {
        const result = res.data.content.map(element => {
          return { label: element['job_name'], value: JSON.stringify(element), key: element['id'] }
        })
        return result;
      })
  }

  getEmpl(temp={}) {
    const params:any = {
      ...temp
    }
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/emp"
    
    return API.get(URL, {params}).then((res:any) => 
      {
        const result = res.data.content.map(element => {
          return { label: element['alternative_name'], value: JSON.stringify(element), key: element['id'] }
        })
        return result;
      })
  }

  getOvtConfiguration(param): Promise<any> {
    return getOvtConfiguration(param);
  }
  
  updateOvtConfiguration(params): Promise<any> {
    if(!!params.id){
      return updateOvtConfiguration(params);
    } else {
      return createOvtConfiguration(params);
    }
  }
  deactiveOvtConfiguration(params): Promise<any> {
    return deactiveOvtConfiguration(params);
  }

}
