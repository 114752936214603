import moment from 'moment';
import getEmployeeList from '../../../../../@core/interaction/employee/GetEmployeeList.interaction';
import getJobList from '../../../../../@core/interaction/job/GetJobList.interaction';
import { GetEmployeeListDTO } from '../../../../../@core/interface/employee/GetEmployeeList.interface';
import { GetJobListDTO } from '../../../../../@core/interface/job/GetJobList.interface';
import { jobListToObjById } from '../../../../../@core/mapper/job/GetJobList.mapper';
import Constant from '../../../../../config/Constants';
import API from '../../../../../services/API.service';

const FormData = require('form-data');

export default class AddEmployeeTerminateUsecase {
  create(formData) {
    const URL = Constant.URL_BASE_COMPANY + '/v3/termination/create';
    return API.post(URL, formData);
  }

  getCategory() {
    const URL = Constant.URL_BASE_COMPANY + '/v3/termination/category';
    return API.get(URL).then((res) => {
      const result = res.data.content.map((e) => {
        return {
          label: e.category.name,
          value: e.category.id,
          key: e.category.id,
          options: this.mapperReasonResign(e.reason_resign || e.reason_phk),
        };
      });

      return result;
    });
  }

  getEmployeeList(params: GetEmployeeListDTO) {
    return getEmployeeList(params).then((res: any) => {
      const result = res.data.content.map((employee) => {
        return {
          label: employee.alternative_name,
          value: employee.id,
          job_id: employee.job_id,
          company_id: employee.company_id,
          department_id: employee.department_id,
          join_date: moment(employee.join_date).format("YYYY-MM-DD"),
          id: employee.id,
        };
      });

      return result
    });
  }

  getJobList(params: GetJobListDTO) {
    return getJobList(params).then((res: any) => jobListToObjById(res.data.content));
  }

  mapperReasonResign(element) {
    return element
      ? element.map((e) => {
          return {
            label: e.phk_name,
            value: e.phk_id,
            key: e.phk_id,
          };
        })
      : [];
  }
}
