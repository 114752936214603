import { Form, Modal, Row } from 'antd';
import FormBuilder from 'antd-form-builder';
import { useForm } from 'antd/lib/form/Form';
import { AddMySubmissionClaimTemplatePropsType } from './AddMySubmissionClaimTemplate.interface';
import Breadcrumb from '../../../../components/breadcrumb/Breadcrumb';
import ToolbarButtonDetailTemplate from '../../employee/components/toolbar-button-detail-template/ToolbarButtonDetailTemplate';
import BottomLoading from '../../../../components/bottom-loading/BottomLoading';
import React, { useEffect, useState } from 'react';
import TabsWrapperSubmissionClaim from './component/TabsWrapperSubmissionClaim';
import { ReadOnlyFields } from './component/ReadOnlyFields';
import DynamicStepsDetail from '../../overtime/templates/submission-overtime-template/components/dynamic-steps/DynamicStepsDetail';
import moment from 'moment-timezone';

let message = ""

export default function AddMySubmissionClaimTemplate(props: AddMySubmissionClaimTemplatePropsType) {
  const [form] = useForm();
  const [visibleModalError, setVisibleModalError] = useState(false)
  
  useEffect(() => {
    const tabs_wrapper = props.detailDataSource?.tabs_wrapper
    if (!!tabs_wrapper?.dataSource) {
      form.setFieldsValue({ tabs_wrapper })
    }
  }, [props.detailDataSource?.tabs_wrapper])

  const disabledDate = (current) => {
    let date = new Date();
    return current && current.valueOf() > date;
  };

  const meta = {
    columns: 4,
    colon: false,
    fields: [
      {
        key: 'employee',
        label: 'Karyawan',
        colSpan: 4,
        disabled: props.isLoading,
        viewMode: !props.empoyeeList?.length || props.viewMode,
        required: true,
        message: 'Karyawan harus diisi.',
        widget: 'select',
        options: props.empoyeeList,
        initialValue: props.detailDataSource?.employee,
        widgetProps: {
          showSearch: true,
          filterOption: (input:any, option:any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'submission_date',
        label: 'Tanggal Pengajuan',
        colSpan: 4,
        disabled: props.isLoading,
        viewMode: true,
        required: true,
        initialValue: props.detailDataSource?.submission_date || (!props.viewMode ? moment().format("YYYY-MM-DD") : ""),
        widgetProps: { disabledDate },
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'claim_type',
        label: 'Tipe Klaim',
        colSpan: 4,
        disabled: props.isLoading,
        viewMode: props.viewMode,
        widget: 'select',
        options: props.listConfigClaim,
        required: true,
        initialValue: props.detailDataSource?.claim_type,
        message: 'Tipe Klaim harus diisi.',
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'sisa_plafon',
        label: 'Sisa Plafon',
        colSpan: 4,
        viewMode: false,
        widget: ReadOnlyFields,
        widgetProps: {
          initialValue: props.detailDataSource?.sisa_plafon,
        },
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'claim_limit',
        label: 'Limit Per Klaim',
        colSpan: 4,
        viewMode: false,
        widget: ReadOnlyFields,
        widgetProps: {
          initialValue: props.detailDataSource?.claim_limit,
        },
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'submission_total',
        label: 'Total Pengajuan',
        colSpan: 4,
        viewMode: false,
        widget: ReadOnlyFields,
        widgetProps: {
          initialValue: props.detailDataSource?.submission_total,
        },
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'detail',
        label: 'Keterangan',
        colSpan: 4,
        disabled: props.isLoading,
        viewMode: props.viewMode,
        widget: 'textarea',
        required: true,
        initialValue: props.detailDataSource?.detail,
        message: 'Keterangan harus diisi.',
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'tabs_wrapper',
        label: '',
        colSpan: 4,
        widget: TabsWrapperSubmissionClaim,
        required: true,
        initialValue: props.detailDataSource?.tabs_wrapper || { dataSource: [], total: 0 },
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 24 },
        },
        widgetProps: {
          disabled: props.viewMode || props.isLoading || !form.getFieldValue('sisa_plafon'),
          viewMode: props.viewMode,
          historyId: props.historyId,
          approval1: props.detailDataSource?.approval1,
          approval2: props.detailDataSource?.approval2,
        },
        // rules: [
        //   {
        //     validator: (_, value) => {
        //       return new Promise((resolve, reject) => {
        //         if (value.total) {
        //           const sisa_plafon = parseInt(form.getFieldValue('sisa_plafon'))
        //           const limit_klaim = parseInt(form.getFieldValue('claim_limit'))
        //           if (value.total > sisa_plafon) {
        //             return reject("Total klaim yang diajukan melebihi sisa plafon yang tersedia")
        //           }
  
        //           if (value.total > limit_klaim) {
        //             return reject("Total klaim yang diajukan melebihi limit per klaim yang diperbolehkan")
        //           } 

        //           resolve("")
        //         }
        //       })
        //     },
        //   },
        // ],
      },
    ],
  };

  const onFinish = (values) => {
    if (props.onFinish) {
      props.onFinish(form.getFieldsValue());
    }
  };

  const onFinishFailed = (errorInfo) => {
    const errorDetail = errorInfo.errorFields[0]
    if (errorDetail.name[0] === 'tabs_wrapper') {
      message = errorDetail.errors[0]
      setVisibleModalError(true)
    }
    
    if (props.onFinishFailed) {
      props.onFinishFailed(form.getFieldsValue());
    }
  };

  const onFieldsChange = (e) => {
    if (e.length === 1) {
      const fieldName = e[0].name[0];
      const value = e[0].value;

      switch (fieldName) {
        default:
          break;
        case 'tabs_wrapper':
          form.setFieldsValue({ submission_total: value.total || null })
          break;
      }
    }
    if (props.onFieldChange) {
      props.onFieldChange(e, form)
    }
  };

  return (
    <div className="add-my-submission-claim-template">
      <Breadcrumb breadcrumbConf={props.breadcrumbConf} />

      <Modal
        visible={visibleModalError}
        footer={false}
        closable={true}
        onCancel={() => setVisibleModalError(false)}
        centered
        bodyStyle={{
          background: '#d42534',
          color: 'white',
          textAlign: 'center',
          fontSize: '1.8rem',
          fontWeight: '500',
        }}
      >
        {message}
      </Modal>
      <Form
        form={form}
        name="basic"
        style={{
          width: '100%',
          height: 'calc(100vh - 130px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
        layout="horizontal"
        labelAlign="left"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={onFieldsChange}
      >
        <Row justify="space-between" align="middle">
          <ToolbarButtonDetailTemplate
            submitButton={{
              ...props.submitButton,
              disabled: props.isLoading,
            }}
            firstActionButton={{ ...props.firstActionButton, disabled: props.isLoading }}
            secondActionButton={props.secondActionButton}
            thirdActionButton={props.thirdActionButton}
            fourthActionButton={props.fourthActionButton}
            dropdownActionButton={props.dropdownActionButton}
          />

          <DynamicStepsDetail activeStep={props.activeSteps} steps={props.steps} />
        </Row>
        <FormBuilder form={form} meta={meta} />
      </Form>

      <BottomLoading active={props.isLoading} />
    </div>
  );
}
