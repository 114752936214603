import React, { useEffect, useRef, useState } from 'react';
import Chartjs from 'chart.js/auto';

const PieChart = (props) => {
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState<any>(null);

  const chartConfig: any = {
    type: 'doughnut',
    data: {
      labels: props.data.labels,
      datasets: [
        {
          label: 'My First Dataset',
          data: props.data.data,
          backgroundColor: props.color,
          hoverOffset: 4,
        },
      ],
    },
    options: {
      tooltips: {
        enabled: false,
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  };
  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chartjs(chartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
    }
  }, [chartContainer]);

  useEffect(() => {
    if (props.data && chartInstance !== null) {
      chartInstance.data.labels = props.data.labels ? props.data.labels : [];
      chartInstance.data.datasets[0].data = props.data.data;
      chartInstance.update();
    }
  }, [props]);

  return (
    <div style={{ position: 'relative', height: 'auto', width: '100%' }}>
      <canvas ref={chartContainer} />
    </div>
  );
};

export default PieChart;
