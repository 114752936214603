import { useEffect, useReducer } from "react";
import AddRecurringHolidaysTemplate from "../template/AddRecurringHolidaysTemplate";
import { RecurringHolidaysFormReducer } from "./DetailRecurringHolidays.reducer";
import { ACTION_FORM_TYPE, INITIAL_FORM_STATE } from "./DetailRecurringHolidays.constant";
import { ToolbarDetailActionButton, ToolbarDetailDropdownButton } from "../../../../component/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface";
import RecurringHolidaysListUseCase from "../list-recurring-holidays/ListRecurringHolidays.usecase";
import ToastService from "../../../../../../services/Toast.service";
import LocalNotificationService from "../../../../../../services/LocalNotification.service";
import moment from "moment";

const usecase = new RecurringHolidaysListUseCase()
const toastService = new ToastService()
const localNotificationService = new LocalNotificationService()

export default function DetailRecurringHolidays({ match }) {
  const recurringId = match.params.rcrHolidayId

  // useReducer
  const [stateView, dispatchView] = useReducer(
    (prev, next) => {
      return { ...prev, ...next }
  }, { isLoading: false, isEdit: false, isActive: false, detailResp: null })
  const [stateForm, dispatchForm] = useReducer(RecurringHolidaysFormReducer, INITIAL_FORM_STATE)
  
  useEffect(() => {
    dispatchView({ isEdit: false })
  }, [recurringId])

  useEffect(() => {
    if (stateView.isEdit) {
      dispatchForm({ type: ACTION_FORM_TYPE.CHANGE_TO_EDIT_MODE })
    }
  }, [stateView.isEdit])

  useEffect(() => {
    usecase.getDetail(recurringId).then(
      (res) => {
        const content = res.data.content
        dispatchForm({ 
          type: ACTION_FORM_TYPE.SET_VALUE_FORM_ITEM,
          payload: content
        })
        dispatchView({ 
          isActive: content.is_active,
          detailResp: content
        })
      }
    ).catch(err => {
      toastService.error(err)
    })
  }, [])
  // constant view
  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Hari Libur Berulang',
        href: "/v2/app/attendance/recurring-holidays",
      },
      {
        clickable: false,
        displayPathName: 'Baru',
        href: "/v2/app/attendance/recurring-holidays/new/",
      }
    ],
  };

  const submitButton = { active: stateView.isEdit, disabled: stateView.isLoading };

  const firstActionButton: ToolbarDetailActionButton = {
    label: 'UBAH',
    type: 'primary',
    active: !stateView.isEdit,
    disabled: stateView.isLoading,
    onClick: () => {
      dispatchView({ isEdit: true })
    },
  };

  const secondActionButton: ToolbarDetailActionButton = {
    label: 'TAMBAH',
    type: 'primary',
    active: !stateView.isEdit,
    disabled: stateView.isLoading,
    onClick: () => {
      const back = window.confirm("Anda Ingin Kembali")
      if (back) {
        window.location.href = "/v2/app/attendance/recurring-holidays/new/"
      }
    },
  };

  const thirdActionButton: ToolbarDetailActionButton = {
    label: 'BATAL',
    type: 'ghost',
    active: stateView.isEdit,
    disabled: stateView.isLoading,
    onClick: () => {
      const back = window.confirm("Anda Ingin Kembali")
      if (back) {
        window.location.href = "/v2/app/attendance/recurring-holidays"
      }
    },
  };

  const dropdownActionButton: ToolbarDetailDropdownButton = {
    disabled: false,
    visible: !!recurringId,
    options: [
      { 
        key: 'non-aktif', 
        disabled: !stateView.isActive, 
        label: 'Non-Aktifkan', 
        onClick() { setActive(false) },
      }
    ]
  }

  function setActive(status) {
    dispatchView({ isLoading: true })

    const params = {
      ids: [recurringId],
      status
    };

    usecase.active(params).then(
      (res: any) => {
        dispatchView({ isLoading: false })
        const content = res.data.content
        if (content.code === 200) {
          const message = content.message.split("- ")[1]
          localNotificationService.openSuccess(message, '', 1)
        }
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      },
      (err) => {
        dispatchView({ isLoading: false })
        toastService.error(err)
      },
    );
  }

  function processDate(startDate, endDate) {
    if (startDate && endDate) {
      const result = endDate.diff(startDate, 'days')
      
      dispatchForm({ 
        type: ACTION_FORM_TYPE.SET_HOLIDAYS_LENGTH, 
        payload: `${endDate >= startDate ? result + 1 : result - 1} Hari`
      })
    }
  }

  const onFieldChange = (e, form) => {
    if (e.length === 1) {
      const fieldName = e[0].name[0];
      const value = e[0].value;
      const startDate = form.getFieldsValue().start_date
      const endDate = form.getFieldsValue().end_date

      switch (fieldName) {
        default:
          break;
        case 'start_date': 
          processDate(startDate, endDate)
          break;
        case 'end_date': 
          processDate(startDate, endDate)
          break;
      }
    }
  }

  const onFinish = (value) => {
    dispatchView({ isLoading: true })
    const holidayTotal = stateForm.holidayLength.value.split(" ")[0]
    const body = {
      id: stateView.detailResp.id,
      company_id: stateView.detailResp.company_id,
      national_holiday: stateView.detailResp.national_holiday,
      recurring: true,
      name: value.holiday_name?.trim(),
      date_start: moment(value.start_date).format('YYYY-MM-DD'),
      date_stop: moment(value.end_date).format('YYYY-MM-DD'),
      total_holidays: Number(holidayTotal),
      is_active: stateView.detailResp.is_active,
      history_id: stateView.detailResp.history_id,
    }

    usecase.update(body).then(
      (res) => {
        const data = res.data
        if (data.code === 200) {
          const message = data.message
          localNotificationService.openSuccess(message, '', 1)
        }
        setTimeout(() => {
          window.location.reload()
        }, 1500)
    }).catch(err => {
      toastService.error(err)
      dispatchView({ isLoading: false })
    })
  }
  
  return (
    <AddRecurringHolidaysTemplate
      breadcrumbConf={breadcrumbConf}
      holidayName={stateForm.holidayName}
      startDate={stateForm.startDate}
      endDate={stateForm.endDate}
      holidayLength={stateForm.holidayLength}
      isRecurring={stateForm.isRecurring}
      dropdownActionButton={dropdownActionButton}
      firstActionButton={firstActionButton}
      secondActionButton={secondActionButton}
      thirdActionButton={thirdActionButton}
      fourthActionButton={{ active: false }}
      submitButton={submitButton}
      isEdit={stateView.isEdit}
      isDetail={true}
      isLoading={stateView.isLoading}
      onFieldChange={onFieldChange}
      onFinish={onFinish}
      historyId={stateView.detailResp?.history_id}
    />
  )
}