import { Col, Row, Table } from "antd";

export default function TableDetailLoan({ dataSource }) {
  return (
    <Row justify="center">
        <Col span={20}>
          <Table
            bordered={true}
            pagination={false}
            dataSource={dataSource}
            columns={[
              { key: 'month_to', title: 'Bulan ke-', dataIndex: 'month_to', width: 80, align: 'center' },
              {
                key: 'pokok',
                title: 'Pokok',
                dataIndex: 'pokok',
                width: 150,
                render: (t) => t?.toLocaleString(),
                align: 'center'
              },
              {
                key: 'bunga',
                title: 'Bunga',
                dataIndex: 'bunga',
                width: 150,
                render: (t) => t?.toLocaleString(),
                align: 'center'
              },
              {
                key: 'total_cicilan',
                title: 'Total Angsuran',
                dataIndex: 'total_cicilan',
                width: 150,
                render: (t) => t?.toLocaleString(),
                align: 'center'
              },
              {
                key: 'status_pembayaran',
                title: 'Status Pembayaran',
                dataIndex: 'status_pembayaran',
                width: 180,
                align: 'center'
              },
              {
                key: 'no_pembayaran',
                title: 'No Pembayaran',
                dataIndex: 'no_pembayaran',
                width: 180,
                render: (t) => t ? <a>{t}</a> : "-",
                align: 'center'
              },
            ]}
            summary={(pageData) => {
              let total_pokok = 0;
              let total_bunga = 0;
              let total_cicil = 0;

              pageData.forEach(({ pokok, bunga, total_cicilan }) => {
                total_pokok += pokok
                total_bunga += bunga;
                total_cicil += total_cicilan;
              });

              return (
                <>
                  <Table.Summary.Row style={{ fontWeight: 'bold' }}>
                    <Table.Summary.Cell align='center' index={1}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell align='center' index={2}>
                      {total_pokok?.toLocaleString()}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align='center' index={3}>
                      {total_bunga?.toLocaleString()}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align='center' index={4}>
                      {total_cicil?.toLocaleString()}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Col>
      </Row>
  )
}