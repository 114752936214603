export function validateNik(nik, field_name: string) {
  const nikMaxLength = 99;

  const result = {
    status: false,
    message: [],
    field_name,
  };

  if (!nik) {
    result.message.push('Harus diisi');
  } else {
    if (`${nik}`.length > nikMaxLength) {
      result.message.push('Jumlah karakter melebihi batas maksimum');
    } else {
      result.status = true;
    }
  }

  return result;
}

export function validateStartDate(start_date: string, field_name: string) {
  const result = {
    status: false,
    message: [],
    field_name,
  };

  if (!start_date) {
    result.message.push('Harus diisi');
  } else {
    if (`${new Date(start_date)}` === 'Invalid Date') {
      result.message.push('Format tanggal salah');
    } else {
      result.status = true;
    }
  }

  return result;
}

export function validateEndDate(start_date: string, end_date: string, field_name: string) {
  const result = {
    status: false,
    message: [],
    field_name,
  };

  if (!end_date) {
    result.message.push('Harus diisi');
  } else {
    if (`${new Date(end_date)}` === 'Invalid Date') {
      result.message.push('Format tanggal salah');
    } else {
      if (new Date(end_date).getTime() >= new Date(start_date).getTime()) {
        result.status = true;
      } else {
        result.message.push('Sampai Tanggal harus lebih besar atau sama dengan Mulai Tanggal');
      }
    }
  }

  return result;
}

export function validateName(name: string, masterSchedule, field_name: string) {
  const result = {
    status: false,
    message: [],
    field_name,
  };

  if (name) {
    if (masterSchedule[name]) {
      result.status = true;
    } else {
      result.message.push(
        'Nama Jadwal Kerja tidak sesuai, silahkan cek Master Jadwal Kerja Karyawan',
      );
    }
  } else {
    result.message.push('Harus diisi');
  }

  return result;
}

export function mapDataSourceToParam(dataSource, masterSchedule) {
  return new Promise((resolve) => {
    const result = { schedule_work: [] };

    if (dataSource.length && masterSchedule) {
      dataSource.forEach((element: any, index) => {
        const rawStartDate = element.start_date.split('/');
        const date_start_schedule_work = `${rawStartDate[2]}-${rawStartDate[1]}-${rawStartDate[0]}`;

        const rawEndDate = element.end_date.split('/');
        const date_end_schedule_work = `${rawEndDate[2]}-${rawEndDate[1]}-${rawEndDate[0]}`;

        const schedule = {
          employee_nik: element.nik,
          date_start_schedule_work,
          date_end_schedule_work,
          name: 'List Jadwal Kerja',
          hira_absent_id: masterSchedule[element.name] ? masterSchedule[element.name].id : 0,
        };

        result.schedule_work.push(schedule);

        if (index === dataSource.length - 1) {
          resolve(result);
        }
      });
    }
  });
}
