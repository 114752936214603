export function employeeListToOptionsMapper(employeeList) {
  const result = [];

  if (employeeList.length) {
    employeeList.forEach((employee) => {
      result.push({
        label: employee.alternative_name,
        value: employee.id,
        job_id: employee.job_id,
        company_id: employee.company_id,
        department_id: employee.dept_id,
        id: employee.id,
      });
    });
  }

  return result;
}
