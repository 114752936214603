import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col, Radio, Space, InputNumber } from 'antd';
import FormBuilder from 'antd-form-builder';
import moment from 'moment';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import { useHistory } from 'react-router-dom';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import AddConfigurationClaim from './AddClaimConfiguration.usecase';

const useCaseImpl = new AddConfigurationClaim();
const localNotificationService = new LocalNotificationService();

export default function HrAddConfigurationClaim() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [employeeList, setEmployeeList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [monthJoin, setMonthJoin] = useState<any>();
  const [claimIntensity, setClaimIntensity] = useState<any>();
  const [monthIntensity, setMonthIntensity] = useState<any>();
  let [isLoading, setIsLoading] = useState(true);

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Konfigurasi Klaim',
        href: '/v2/app/hr-claim/configuration/list-configuration',
      },
      {
        clickable: false,
        displayPathName: 'Baru',
      },
    ],
  };

  const disabledDate = (current) => {
    const date = moment(current).toDate().getDate();
    return current && (date === 29 || date === 30 || date === 31);
  };

  const ActiveWhen = ({ value, onChange }) => {
    return (
      <Radio.Group onChange={onChange} value={value}>
        <Space direction="vertical">
          <Radio value={'mulai_bergabung'}>Mulai Bergabung</Radio>
          <Radio value={'bergabung_dibulan'}>
            Bergabung di Bulan ke
            {value === 'bergabung_dibulan' ? (
              <InputNumber
                min={1}
                defaultValue={monthJoin}
                onChange={(e) => setMonthJoin(e)}
                style={{ width: 50, marginLeft: 10 }}
              />
            ) : null}
          </Radio>
        </Space>
      </Radio.Group>
    );
  };

  const IntensityComp = ({ value, onChange }) => {
    return (
      <Space direction="horizontal" size={3}>
        <Form.Item
          style={{ marginBottom: 0 }}
          label={null}
          name="claim_intensity"
          rules={[{ required: true, message: 'Please input!' }]}
        >
          <InputNumber
            min={1}
            defaultValue={claimIntensity}
            onChange={(e) => setClaimIntensity(e)}
            style={{ width: 60 }}
          />
        </Form.Item>
        Klaim Dalam
        <Form.Item
          style={{ marginBottom: 0 }}
          label={null}
          name="month_intensity"
          rules={[
            { required: true, message: 'Please input!' },
            {
              validator: (rule, value, callback) => {
                return new Promise((resolve, reject) => {
                  if (value && value > 12) {
                    reject(new Error('Tidak boleh lebih dari 12'));
                  } else {
                    resolve(value);
                  }
                });
              },
            },
          ]}
        >
          <InputNumber
            min={1}
            defaultValue={monthIntensity}
            onChange={(e) => setMonthIntensity(e)}
            onBlur={() => {
              onChange = forceUpdate;
            }}
            onStep={() => {
              onChange = forceUpdate();
            }}
            style={{ width: 60 }}
          />
        </Form.Item>
        Bulan Terakhir
      </Space>
    );
  };

  const meta: any = {
    columns: 1,
    fields: [
      {
        key: 'name',
        label: 'Nama Klaim',
        rules: [{ required: true, whitespace: true, message: 'Nama Klaim harus diisi' }],
      },
      {
        key: 'plafon_type',
        label: 'Tipe Plafon',
        required: true,
        message: 'Tipe Plafon harus diisi',
        widget: 'select',
        options: [
          { value: 'terbatas', label: 'Terbatas' },
          { value: 'tidak_terbatas', label: 'Tidak Terbatas' },
        ],
      },
      {
        key: 'plafon_value',
        label: 'Nilai Plafon',
        widget: 'number',
        required: true,
        message: 'Nilai Plafon harus diisi',
        widgetProps: {
          style: { width: '50%' },
          formatter: (value) => `${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ','),
          min: 1,
        },
      },
      {
        key: 'limit_type',
        label: 'Limit per Klaim',
        required: true,
        message: 'Limit per Klaim harus diisi',
        widget: 'select',
        options: [
          { value: 'terbatas', label: 'Terbatas' },
          { value: 'tidak_terbatas', label: 'Tidak Terbatas' },
        ],
      },
      {
        key: 'effective_date',
        label: 'Tanggal Efektif',
        required: true,
        message: 'Tanggal Efektif harus diisi',
        widget: 'date-picker',
        widgetProps: { disabledDate },
      },
      {
        key: 'reset_period',
        label: 'Periode Reset',
        required: true,
        message: 'Periode Reset harus diisi',
        widget: 'select',
        options: [
          { value: 'tahunan', label: 'Tahunan' },
          { value: '6bulanan', label: '6 Bulanan' },
          { value: '3bulanan', label: '3 Bulanan' },
          { value: 'bulanan', label: '1 Bulanan' },
        ],
      },
      {
        key: 'is_proof_required',
        label: 'Membutuhkan Bukti',
        widget: 'checkbox',
      },
      {
        key: 'is_intensity_limit',
        label: 'Batasan Insentitas',
        widget: 'checkbox',
      },
      {
        key: 'active_when',
        label: 'Aktif Ketika',
        forwardRef: true,
        widget: ActiveWhen,
        options: [
          { value: 'mulai_bergabung', label: 'Mulai Bergabung' },
          { value: 'bergabung_dibulan', label: 'Bergabung di Bulan ke' },
        ],
        initialValue: 'mulai_bergabung',
      },
      {
        key: 'config_type',
        label: 'Berlaku Untuk',
        widget: 'radio-group',
        forwardRef: true,
        options: [
          { value: 'job', label: 'Jabatan' },
          { value: 'employee', label: 'Karyawan' },
        ],
        initialValue: 'job',
      },
      {
        key: 'job',
        label: 'Jabatan',
        required: true,
        message: 'Jabatan harus diisi',
        widget: 'select',
        options: jobList,
        widgetProps: {
          mode: 'multiple',
        },
      },
      {
        key: 'approval_1',
        label: 'Persetujuan 1',
        required: true,
        message: 'Persetujuan 1 harus diisi',
        widget: 'select',
        options: [
          { value: 'tanpa_persetujuan', label: 'Tanpa Persetujuan' },
          { value: 'atasan_langsung', label: 'Atasan Langsung' },
          { value: 'jabatan', label: 'Jabatan' },
        ],
      },
    ],
  };

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {
    setIsLoading(true);
    const getJob = await useCaseImpl.getJob();
    const employeeList = await useCaseImpl.getEmpl();
    setJobList(getJob);
    setEmployeeList(employeeList);
    setIsLoading(false);
  }

  async function onFinish(e) {
    setIsLoading(true);
    const list_job =
      e.job &&
      e.job
        .map((item) => JSON.parse(item))
        .map(function (row) {
          return {
            job_id: row.id,
            name: row.job_name,
            dept_name: row.dept_name,
            dept_id: row.dept_id,
          };
        });
    const list_employee =
      e.employee &&
      e.employee
        .map((item) => JSON.parse(item))
        .map(function (row) {
          return {
            employee_id: row.id,
            nik: row.nik,
            name: row.name,
            alternative_name: row.alternative_name,
            job_id: row.job_id,
            job_name: row.job_name,
            dept_id: row.dept_id,
            dept_name: row.dept_name,
          };
        });
    let tempParam: any = {};
    e.job_approval1 && (tempParam['job_approval_1'] = JSON.parse(e.job_approval1)?.id);
    e.job_approval2 && (tempParam['job_approval_2'] = JSON.parse(e.job_approval2)?.id);
    e.employee && (tempParam['list_emp'] = list_employee);
    e.job && (tempParam['list_job'] = list_job);
    let plafon_reset;
    let plafon_reset_count;
    switch (e.reset_period) {
      case 'bulanan':
        plafon_reset = 'bulanan';
        plafon_reset_count = 1;
        break;
      case '3bulanan':
        plafon_reset = 'bulanan';
        plafon_reset_count = 3;
        break;
      case '6bulanan':
        plafon_reset = 'bulanan';
        plafon_reset_count = 6;
        break;
      case 'tahunan':
        plafon_reset = 'tahunan';
        plafon_reset_count = 1;
        break;
      default:
        break;
    }

    const params = {
      name: e.name.trim(),
      plafon_type: e.plafon_type,
      plafon_value: e.plafon_value && e.plafon_value.toString(),
      plafon_reset: plafon_reset,
      plafon_reset_count: plafon_reset_count && plafon_reset_count.toString(),
      limit_type: e.limit_type && e.limit_type.toString(),
      limit_claim: e.limit_value && e.limit_value.toString(),
      effective_date: moment(e.effective_date).format('YYYY-MM-DD'),
      is_proof_required: e.is_proof_required,
      is_intensity_limit: e.is_intensity_limit,
      every_claim: claimIntensity ? claimIntensity.toString() : '',
      every_month: monthIntensity ? monthIntensity.toString() : '',
      config_type: e.config_type,
      active_when: e.active_when,
      month_join: monthJoin ? monthJoin.toString() : '0',
      approval_1: e.approval_1,
      approval_2: e.approval_2,
      is_2nd_appr: e.approval_2 && e.approval_2 !== 'tanpa_persetujuan' ? true : false,
      ...tempParam,
    };

    useCaseImpl.submitClaimConfiguration(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          history.push('/app/hr-claim/configuration/list-configuration');
          localNotificationService.openSuccess('Konfigurasi Klaim Berhasil Dibuat', '', 3);
        }

        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }

  let newMeta = meta;
  if (form.getFieldValue('plafon_type')) {
    if (form.getFieldValue('plafon_type') === 'tidak_terbatas') {
      const objIndex = newMeta.fields.findIndex((obj) => obj.key === 'plafon_value');
      newMeta.fields[objIndex] = {
        key: 'hide_plafon_value',
        render: () => {
          return null;
        },
      };
    }
  }
  if (form.getFieldValue('limit_type')) {
    if (form.getFieldValue('limit_type') === 'terbatas') {
      const objIndex = newMeta.fields.findIndex((obj) => obj.key === 'limit_type');
      newMeta.fields.splice(objIndex + 1, 0, {
        key: 'limit_value',
        label: 'Jumlah Limit per Klaim',
        widget: 'number',
        required: true,
        message: 'Nilai Plafon harus diisi',
        widgetProps: {
          style: { width: '50%' },
          formatter: (value) => `${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ','),
          min: 1,
        },
      });
    }
  }
  if (form.getFieldValue('is_intensity_limit')) {
    if (!!form.getFieldValue('is_intensity_limit')) {
      const objIndex = newMeta.fields.findIndex((obj) => obj.key === 'is_intensity_limit');
      newMeta.fields.splice(objIndex + 1, 0, {
        key: 'intensity',
        label: 'Hanya Diperbolehkan',
        forwardRef: true,
        widget: IntensityComp,
      });
    }
  }

  if (form.getFieldValue('config_type')) {
    if (form.getFieldValue('config_type') === 'employee') {
      const objIndex = newMeta.fields.findIndex((obj) => obj.key === 'job');
      newMeta.fields[objIndex] = {
        key: 'employee',
        label: 'Karyawan',
        required: true,
        message: 'Karyawan harus diisi',
        widget: 'select',
        options: employeeList,
        widgetProps: {
          mode: 'multiple',
        },
      };
    } else if (form.getFieldValue('config_type') === 'job') {
      const objIndex = newMeta.fields.findIndex((obj) => obj.key === 'employee');
      newMeta.fields[objIndex] = {
        key: 'job',
        label: 'Jabatan',
        required: true,
        message: 'Jabatan harus diisi',
        widget: 'select',
        options: jobList,
        widgetProps: {
          mode: 'multiple',
        },
      };
    }
  }
  if (form.getFieldValue('approval_1')) {
    if (form.getFieldValue('approval_1') === 'jabatan') {
      newMeta.fields[newMeta.fields.length + 1] = {
        key: 'job_approval1',
        label: 'Nama Jabatan',
        widget: 'select',
        required: true,
        message: 'Nama Jabatan harus diisi',
        options: jobList,
        widgetProps: {
          showSearch: true,
        },
      };
      newMeta.fields[newMeta.fields.length + 2] = {
        key: 'approval_2',
        label: 'Persetujuan 2',
        widget: 'select',
        required: true,
        message: 'Persetujuan 2 harus diisi',
        options: [
          { value: 'tanpa_persetujuan', label: 'Tanpa Persetujuan' },
          { value: 'atasan_langsung', label: 'Atasan Langsung' },
          { value: 'jabatan', label: 'Jabatan' },
        ],
      };
    }
    if (form.getFieldValue('approval_1') === 'atasan_langsung') {
      newMeta.fields[newMeta.fields.length + 1] = {
        key: 'approval_2',
        label: 'Persetujuan 2',
        required: true,
        message: 'Persetujuan 2 harus diisi',
        widget: 'select',
        options: [
          { value: 'tanpa_persetujuan', label: 'Tanpa Persetujuan' },
          { value: 'atasan_langsung', label: 'Atasan Langsung' },
          { value: 'jabatan', label: 'Jabatan' },
        ],
      };
    }
  }
  if (form.getFieldValue('approval_2')) {
    if (
      form.getFieldValue('approval_2') === 'jabatan' &&
      form.getFieldValue('approval_1') !== 'tanpa_persetujuan'
    ) {
      newMeta.fields[newMeta.fields.length + 1] = {
        key: 'job_approval2',
        label: 'Nama Jabatan',
        widget: 'select',
        required: true,
        options: jobList,
        widgetProps: {
          showSearch: true,
        },
      };
    }
  }

  return (
    <div
      className="add-claim-configuration-page"
      style={{ position: 'relative', height: 'calc(100vh - 100px)' }}
    >
      <Form form={form} layout="horizontal" onFinish={onFinish} onValuesChange={forceUpdate}>
        <div>
          <div className="form-wrapper">
            <div>
              <Breadcrumb breadcrumbConf={breadcrumbConf} />
              <Form.Item className="form-footer" style={{ width: '100%', marginTop: 20 }}>
                <Button type="primary" onClick={() => form.submit()} disabled={isLoading}>
                  Simpan
                </Button>
                &nbsp; &nbsp;
                <Button
                  onClick={() => history.replace('/app/hr-claim/configuration/list-configuration')}
                >
                  Batal
                </Button>
              </Form.Item>
              <div
                style={{
                  border: '1px solid #000',
                  padding: 10,
                  overflowY: 'scroll',
                  height: '70vh',
                }}
              >
                <FormBuilder form={form} meta={meta} />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
