import { useEffect, useState } from 'react';
import LandingListTemplate from '../../../templates/landing-list-template/LandingListTemplate';
import { useHistory } from 'react-router-dom';
import { Row } from 'antd';
import { CompanyConstant } from './CompanyApproval.constant';
import CompanyApprovalUseCaseImpl from './CompanyApproval.usecase';
import LocalNotificationService from '../../../services/LocalNotification.service';
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";

const useCaseImpl = new CompanyApprovalUseCaseImpl();
const localNotificationService = new LocalNotificationService();

let page = 1;
let loadPagination = true;
let sort = 'write_date|desc';
let name = '';
let plafon_type = '';
let active_when = '';
let is_active = '';
let config_type = '';
let approval_1 = '';
let approval_2 = '';
let plafon_reset = '';

export default function CompanyApproval() {
  const history = useHistory();
  const tableHeader: any = CompanyConstant.TABLE_HEADER;
  const searchColumnKey = CompanyConstant.SEARCH_COLUMN_KEY;
  const searchColumnComponent = CompanyConstant.SEARCH_COLUMN_COMPONENT;
  const limit = 10;

  let [pagination, setPagination] = useState<any>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });
  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([]);
  let [tableScroll, setTableScroll] = useState({ y: 200 });

  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: false, searchColumnComponent };
  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: false,
        displayPathName: 'Perushaaan Baru',
      },
    ],
  };

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {

    loadList();
    const clientHeight = document.getElementById('employee-attendance-page').clientHeight;

    setTableScroll({ y: clientHeight - 220 });
  }

  function loadList() {
    setIsLoading(true);

    const params = {
      page,
      limit,
      sort,
      // name,
      // plafon_type,
      // plafon_reset,
      // active_when,
      // is_active,
      // config_type,
      // approval_1,
      // approval_2,
      with_meta: true,
    };
    useCaseImpl
      .getlistCompany(params)
      .then(
        (res: any) => {
          setIsLoading(false);
          const content = res?.content;
          const metaData = res?.metaData;
          const resultDataSource = (content && content.length) ? content : CompanyConstant.DEFAULT_EMPTY_DATA_SOURCE;
          setDataSource([...resultDataSource]);
          updatePaginationState(metaData?.total_records);
          loadPagination = true;
        },
        (err) => {
          setIsLoading(false);
          setDataSource([...CompanyConstant.DEFAULT_EMPTY_DATA_SOURCE]);
          loadPagination = true;
        }
      )
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    const outputSort = output.sort;
    const outputSearchColumn = output.searchColumn;
    const outputApproval = output.tableApproval;
    
    processApproval(outputApproval);
    processSearchColumn(outputSearchColumn);
    processPagination(outputPagination);
  };

  function processApproval(outputApproval) {
    if (outputApproval && outputApproval?.record.id) {
      setIsLoading(true);
      const param = {
        id: outputApproval?.record.id,
        state: outputApproval.status
      };
      useCaseImpl.approvalAction(param).then(
        (res: any) => {
          setIsLoading(false);
          const data = res.data;
          if (data.status === 200) {
            localNotificationService.openSuccess(
              '',
              data.content?.cause || data.content?.message,
              3,
            );
            loadList();
          }
        },
        (err) => {
          setIsLoading(false);
        },
      );
    }
  }

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  function processSearchColumn(outputSearchColumn) {
    let isLoadList = false;
    if (outputSearchColumn) {
      if (
        outputSearchColumn.name !== undefined &&
        outputSearchColumn.name !== name
      ) {
        name = outputSearchColumn.name;
        isLoadList = true;
      }

      if (
        outputSearchColumn.plafon_type !== undefined &&
        outputSearchColumn.plafon_type !== plafon_type
      ) {
        plafon_type = outputSearchColumn.plafon_type;
        isLoadList = true;
      }
      if (
        outputSearchColumn.plafon_reset !== undefined &&
        outputSearchColumn.plafon_reset !== plafon_reset
      ) {
        plafon_reset = outputSearchColumn.plafon_reset;
        isLoadList = true;
      }
      if (
        outputSearchColumn.active_when !== undefined &&
        outputSearchColumn.active_when !== active_when
      ) {
        active_when = outputSearchColumn.active_when;
        isLoadList = true;
      }
      if (
        outputSearchColumn.is_active !== undefined &&
        outputSearchColumn.is_active !== is_active
      ) {
        is_active = outputSearchColumn.is_active;
        isLoadList = true;
      }
      if (
        outputSearchColumn.config_type !== undefined &&
        outputSearchColumn.config_type !== config_type
      ) {
        config_type = outputSearchColumn.config_type;
        isLoadList = true;
      }
      if (
        outputSearchColumn.approval_1 !== undefined &&
        outputSearchColumn.approval_1 !== approval_1
      ) {
        approval_1 = outputSearchColumn.approval_1;
        isLoadList = true;
      }
      if (
        outputSearchColumn.approval_2 !== undefined &&
        outputSearchColumn.approval_2 !== approval_2
      ) {
        approval_2 = outputSearchColumn.approval_2;
        isLoadList = true;
      }

      if (isLoadList) {
        page = 1;
        loadPagination = false;
        loadList();
      }
    }
  }

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }
  function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };


  async function exportData() {
    setIsLoading(true);
    const params = {
      page,
      limit,
      sort,
      name,
      plafon_type,
      plafon_reset,
      active_when,
      is_active,
      config_type,
      approval_1,
      approval_2,
      with_meta: true,
    };
    useCaseImpl.export(params,).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          const file = base64ToArrayBuffer(data.content.data);
          saveByteArray(data.content.filename, file);
        }

        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }

  const dropdownButton = {
    visible: true,
    options: [
      { key: 'export', label: 'Export', onClick: () => exportData() },
    ]
  };

  return (
    <div id="employee-attendance-page" style={{ height: 'calc(100vh - 100px)' }}>
      <Row justify='space-between'>
        <Breadcrumb breadcrumbConf={breadcrumbConf} />
      </Row>
      <LandingListTemplate
        addButton={{ visible: false }}
        deleteButton={{ visible: false }}
        exportButton={{ visible: false }}
        dropdownButton={dropdownButton}
        tableHeader={tableHeader}
        tableData={tableData}
        tablePagination={pagination}
        tableOnChange={tableOnChange}
        isLoading={isLoading}
        tableScroll={tableScroll}
      />
    </div>
  );
}
