import { Select, Tabs } from 'antd';
import { RefSelectProps } from 'antd/lib/select';
import React, { useRef, useState } from 'react';
import { FormTemplateConstant } from '../RegisterFormTemplate.constant';
import JoinWithUsUsecase from '../../JoinWithUs.usecase'
const usecase = new JoinWithUsUsecase()

const tabsKey = ['province', 'city', 'sub-district', 'postal-code']
const constant = FormTemplateConstant

export default function SelectLocationComponent({ value, onChange }) {
  const selectRef: React.Ref<RefSelectProps> = useRef();
  const [locationOptions, setLocationOptions] = useState(constant.MOCK_PROVINCE)
  let [listProvince, setListProvince] = useState<any>([]);

  const onChangeLocation = (e) => {
    switch(e?.length){
      case 0:
        setLocationOptions(constant.MOCK_PROVINCE)
        onChange(e)
        break;
      case 1:
        setLocationOptions(constant.MOCK_DISTRICT)
        onChange(e)
        break;
      case 2:
        setLocationOptions(constant.MOCK_SUBDISTRICT)
        onChange(e)
        break;
      case 3:
        selectRef.current.blur()
        onChange([...e, "54343"])
        setLocationOptions(constant.MOCK_PROVINCE)
        break;
      case 5:
        onChange([e.at(-1)])
        setLocationOptions(constant.MOCK_DISTRICT)
        break;
    }
  }

  return (
    <Select
      ref={selectRef}
      mode="multiple"
      value={value}
      options={locationOptions}
      onChange={onChangeLocation}
      placeholder='Provinsi, Kota, Kecamatan, Kode Pos'
      tagRender={(props: any) => parseInt(props.label) ? (<span>{props.label}&nbsp;</span>) : (<span>{props.label},&nbsp;</span>)}
      dropdownRender={(menu) =>(
        <Tabs
          size="large"
          id="dropdown-tabs"
          activeKey={tabsKey[value?.length % 4 || 0]}
        >
          <Tabs.TabPane tab="Provinsi" key="province">
            {menu}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Kota" key="city">
            {menu}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Kecamatan" key="sub-district">
            {menu}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Kode Pos" key="postal-code"></Tabs.TabPane>
        </Tabs>
      )}
    />
  );
}
