import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Form, Button, Row, Space, DatePicker, Modal, Table, Input, Col } from 'antd';
import FormBuilder from 'antd-form-builder';
import Breadcrumb from '../../../../../components/breadcrumb/Breadcrumb';
import { LandingListTemplatePagination } from '../../../../../templates/landing-list-template/LandingListTemplate.interface';
import LocalNotificationService from '../../../../../services/LocalNotification.service';
import ListLoanPayment from './ListLoanPayment.usecase';
import { LoanPaymentConstant } from './ListLoanPayment.constant';
import { EditableTableEmployeeWorking } from '../../../attendance/configuration/employee-working-schedule/template/components/EditableTableEmployeeWorking';
import BottomLoading from '../../../../../components/bottom-loading/BottomLoading';
import './ListLoanPayment.less';
import { MappingLoanState } from '../../../../../@core/mapper/loan/AddSubmissionLoan.mapper';

const { RangePicker } = DatePicker;

const useCaseImpl = new ListLoanPayment();
const localNotificationService = new LocalNotificationService();

let page = 1;
let limit = 5;
let params: any = {};

const listSchedule = [
  { key: 'belum_dibayar', label: 'Belum Dibayar', value: 'Belum Dibayar' },
  { key: 'dibayar', label: 'Dibayar', value: 'Dibayar' },
];

export default function HrListLoanPayment() {
  const history = useHistory();
  const [form] = Form.useForm();

  const tableHeader: any = LoanPaymentConstant.TABLE_HEADER;
  const searchColumnKey = LoanPaymentConstant.SEARCH_COLUMN_KEY;
  const searchColumnComponent = LoanPaymentConstant.SEARCH_COLUMN_COMPONENT;
  let [pagination, setPagination] = useState<LandingListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 5,
    size: 'small',
  });
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [employeeList, setEmployeeList] = useState([]);
  const [typeOption, setTypeOption] = useState([]);
  const [dataSource, setDataSource] = useState(LoanPaymentConstant.DEFAULT_EMPTY_DATA_SOURCE);
  const [showList, setShowList] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true)
  const [showModalPassword, setShowModalPassword] = useState(false);
  const [modalDetailProps, setModalDetailProps] = useState({
    title: '',
    visible: false,
    dataSource: [],
  });

  let [isLoading, setIsLoading] = useState(true);

  const tableData = {
    searchColumnKey,
    dataSource,
    tableRowSelectionActive: true,
    searchColumnComponent,
  };

  useEffect(() => {
    const value = form.getFieldsValue();

    form.setFieldsValue({
      ...value,
      table_change: {
        ...value.table_change,
        tableData,
        listSchedule,
        pagination: {
          ...value.table_change.pagination,
          total: pagination.total,
        },
      },
    });

    tableHeader[0].render = (text) => {
      return <a>{text}</a>;
    };

    tableHeader[11].render = (text, record) => {
      return <a onClick={() => processAction(record)}>{text}</a>;
    };
  }, [dataSource]);

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: false,
        displayPathName: 'Pembayaran Pinjaman',
      },
    ],
  };

  const PeriodComp = ({ value, onChange }) => {
    return (
      <div>
        <RangePicker
          defaultValue={value}
          onChange={(v) => onChange(v)}
          style={{ width: '96%' }}
          picker="month"
          format="MMMM-YYYY"
        />
      </div>
    );
  };

  const meta: any = {
    columns: 4,
    fields: [
      {
        key: 'period',
        label: 'Periode',
        widget: PeriodComp,
        colSpan: 3,
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 12 },
        },
        required: true,
        message: 'Periode harus diisi.',
      },
      {
        key: 'loan_type',
        label: 'Tipe Pinjaman',
        widget: 'select',
        forwardRef: true,
        colSpan: 3,
        options: typeOption,
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 12 },
        },
        required: true,
        message: 'Tipe Pinjaman harus diisi',
      },
      {
        key: 'loan_number',
        colSpan: 3,
        label: 'No Pinjaman',
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 12 },
        },
      },
      {
        key: 'employee',
        label: 'Karyawan',
        widget: 'select',
        options: employeeList,
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 12 },
        },
        colSpan: 3,
        widgetProps: {
          mode: 'multiple',
        },
      },
      {
        key: 'state_payment',
        label: 'Status Pembayaran',
        widget: 'select',
        forwardRef: true,
        colSpan: 3,
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 12 },
        },
        options: [
          { value: 'belum_lunas', label: 'Belum Dibayar' },
          { value: 'lunas', label: 'Dibayar' },
          { value: '', label: 'Semua' },
        ],
        initialValue: 'belum_lunas',
      },
      {
        key: 'search_data',
        viewMode: false,
        colSpan: 3,
        widget: () => (
          <Form.Item className="form-footer" style={{ width: '100%', marginTop: 20 }}>
            <Space>
              <Button type="primary" onClick={() => form.submit()} disabled={isLoading}>
                Tampilkan
              </Button>
              <Button type="default" onClick={() => window.location.reload()}>
                Reset
              </Button>
            </Space>
          </Form.Item>
        ),
        formItemLayout: {
          labelCol: { span: 12 },
          wrapperCol: { span: 4 },
        },
      },
      {
        key: 'separator',
        colSpan: 4,
        render() {
          return <hr style={{ marginTop: '-15px' }} />;
        },
      },
      {
        key: 'payment_status',
        label: 'Ubah Status Pembayaran',
        viewMode: false,
        colSpan: 2,
        widget: 'select',
        options: ['Dibayar', 'Belum Dibayar'],
        initialValue: 'Dibayar',
        formItemLayout: {
          labelCol: { span: 5 },
          wrapperCol: { span: 8 },
        },
      },
      {
        key: 'payment_date',
        label: 'Tanggal Pembayaran',
        viewMode: false,
        colSpan: 1,
        widget: 'date-picker',
        formItemLayout: {
          labelCol: { span: 12 },
          wrapperCol: { span: 24 },
        },
      },
      {
        key: 'apply_data',
        viewMode: false,
        colSpan: 3,
        widget: () => (
          <Button
            type="primary"
            disabled={isLoading || !dataSource.length}
            onClick={() => onApplyData()}
          >
            Terapkan
          </Button>
        ),
        formItemLayout: {
          labelCol: { span: 12 },
          wrapperCol: { span: 4 },
        },
      },
      {
        key: 'table_change',
        colSpan: 4,
        widget: EditableTableEmployeeWorking,
        widgetProps: {
          disabled: isLoading,
          columns: tableHeader,
        },
        initialValue: {
          selectedRowKeys: {
            1: [],
          },
          pagination: {
            current: page,
            pageSize: limit,
            total: pagination.total,
            size: 'small',
          },
          rowTableChange: {},
          listSchedule,
          tableData,
          dataSource,
        },
        onChange: (value) => {
          if (params.page !== value.pagination.current) {
            params.page = value.pagination.current;
            loadList(params);
          }

          for (const key in value.rowTableChange) {
            if (value.rowTableChange[key]?.state_payment === 'Belum Dibayar') {
              if (value.rowTableChange[key].payment_date) {
                form.setFieldsValue({
                  ...value,
                  table_change: {
                    ...value.table_change,
                    rowTableChange: {
                      ...value.rowTableChange,
                      [key]: {
                        ...value.rowTableChange[key],
                        payment_date: '',
                      },
                    },
                  },
                });
              }
            }
          }

          disabledSaveButton(value)

          const copyDataSource = JSON.parse(JSON.stringify(dataSource));
          setDataSource(copyDataSource);
        },
      },
    ],
  };

  function disabledSaveButton(value) {
    let selectedId = [];
    let disableSave = true
    for (const key in value.selectedRowKeys) {
      selectedId = [...selectedId, ...value.selectedRowKeys[key]];
    }

    if (selectedId.length) {
      const isNotValid = selectedId.map((id) => {
        if (value.rowTableChange[`${id}`]) {
          if (
            value.rowTableChange[`${id}`].state_payment === "Dibayar" &&
            !value.rowTableChange[`${id}`].payment_date
          ) {
            return true;
          }
        } else {
          return true;
        }
      });
      disableSave = isNotValid.includes(true);
    } else {
      disableSave = true
    }

    setDisabledSave(disableSave)
  }

  if (form.getFieldValue('payment_status') === 'Belum Dibayar') {
    meta.fields.splice(8, 1);
  }

  const onApplyData = () => {
    const value = form.getFieldsValue();
    let applyData = {};

    let selectedId = [];
    for (const key in value.table_change?.selectedRowKeys) {
      selectedId = [...selectedId, ...value.table_change?.selectedRowKeys[key]];
    }

    if (!selectedId.length) {
      dataSource.forEach((data) => {
        const key = data.key;
        const state_payment = value.payment_status
          ? value.payment_status
          : value.table_change.rowTableChange[key]
          ? value.table_change.rowTableChange[key].state_payment
          : null;

        let payment_date = value.payment_date
          ? value.payment_date.format('YYYY-MM-DD')
          : value.table_change.rowTableChange[key]
          ? value.table_change.rowTableChange[key].payment_date
          : null;

        if (state_payment === 'Belum Dibayar') {
          payment_date = null;
        }

        applyData = {
          ...applyData,
          [key]: {
            ...data,
            state_payment,
            payment_date,
          },
        };
      });
    } else {
      selectedId.forEach((id) => {
        const state_payment = value.payment_status
          ? value.payment_status
          : value.table_change.rowTableChange[id]
          ? value.table_change.rowTableChange[id].state_payment
          : null;

        let payment_date = value.payment_date
          ? value.payment_date.format('YYYY-MM-DD')
          : value.table_change.rowTableChange[id]
          ? value.table_change.rowTableChange[id].payment_date
          : null;

        if (state_payment === 'Belum Dibayar') {
          payment_date = null;
        }

        applyData = {
          ...applyData,
          [id]: {
            ...applyData[id],
            state_payment,
            payment_date,
          },
        };
      });
    }

    if (value.payment_status || value.payment_date) {
      const copyDataSource = JSON.parse(JSON.stringify(dataSource));
      setDataSource(copyDataSource);
    }

    form.setFieldsValue({
      ...value,
      table_change: {
        ...value.table_change,
        rowTableChange: applyData,
        editableRowKey: null,
        selectedRowKeys: {
          1: [],
        },
      },
    });

    setTimeout(() => {
      form.setFieldsValue({
        ...value,
        table_change: {
          ...value.table_change,
          rowTableChange: applyData,
        },
      });
      disabledSaveButton(form.getFieldsValue().table_change)
    }, 100);
  };

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {
    setIsLoading(true);
    const getType = await useCaseImpl.getLoanType();
    const employeeList = await useCaseImpl.getEmployeeList();

    setEmployeeList(employeeList);
    setTypeOption(getType);
    setIsLoading(false);
  }

  async function onFinish(e) {
    const list_employee =
      e.employee && e.employee.map((item) => JSON.parse(item)).map((row) => row.id);
    const loan_type = e.loan_type && JSON.parse(e.loan_type).id;
    
    params = {
      with_meta: true,
      page: page,
      limit: limit,
      start_date: e.period[0] && moment(e.period[0]).format('MM-YYYY'),
      end_date: e.period[1] && moment(e.period[1]).format('MM-YYYY'),
      loan_type,
      loan_number: e.loan_number,
      employee: list_employee,
      state: e.state_payment || null,
    };
    loadList(params);
    setShowList(true);
  }

  function loadList(e) {
    setIsLoading(true);
    useCaseImpl.getLoanListPayment(e).then(
      (res: any) => {
        const content = res?.content;
        const metaData = res?.metaData;
        const resultDataSource = content?.length ? content : 
          LoanPaymentConstant.DEFAULT_EMPTY_DATA_SOURCE;

        updatePaginationState(metaData?.total_records);
        setDataSource([...resultDataSource]);
        setIsLoading(false);
      },
      (err) => {
        setDataSource([...LoanPaymentConstant.DEFAULT_EMPTY_DATA_SOURCE]);
        setIsLoading(false);
      },
    );
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  async function processAction(record) {
    if (!!record.loan_request_id) {
      const tempDetail = await useCaseImpl.getLoanDetailPayment(record.loan_request_id);

      setModalDetailProps({
        title: record.loan_number,
        visible: true,
        dataSource: tempDetail.content,
      });
    }
  }

  const save = () => {
    setShowModalPassword(true);
  };

  async function onSubmit(value) {
    const formData = form.getFieldValue('table_change');

    let selectedId = [];
    for (const key in formData.selectedRowKeys) {
      selectedId = [...selectedId, ...formData.selectedRowKeys[key]];
    }

    const payment = selectedId.map((id) => {
      return { id, payment_date: formData.rowTableChange[id]?.payment_date || null };
    });

    const body = {
      payment,
      password: value.password,
    };

    useCaseImpl.updateLoanPayment(body).then(
      (res) => {
        if (res?.data?.status) {
          localNotificationService.openSuccess(res?.data?.message, '', 3);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      },
      (err) => {
        localNotificationService.openError(err, '', 3);
      },
    );
  }

  return (
    <div style={{ position: 'relative', height: 'calc(100vh - 100px)' }}>
      <Modal
        title={modalDetailProps.title}
        style={{ fill: 'black' }}
        width="60%"
        visible={modalDetailProps.visible}
        footer={[]}
        onCancel={() =>
          setModalDetailProps((temp) => {
            return { ...temp, visible: false };
          })
        }
      >
        <Row justify="center">
          <Col span={20}>
            <Table
              bordered={true}
              pagination={false}
              dataSource={modalDetailProps.dataSource}
              columns={[
                {
                  key: 'installment_num',
                  title: 'Bulan ke-',
                  dataIndex: 'installment_num',
                  width: 80,
                  align: 'center',
                },
                {
                  key: 'amount',
                  title: 'Pokok',
                  dataIndex: 'amount',
                  width: 150,
                  render: (v, r) => !r.installment_num ? <b>{v}</b> : v,
                  align: 'center',
                },
                {
                  key: 'interest',
                  title: 'Bunga',
                  dataIndex: 'interest',
                  width: 150,
                  render: (v, r) => !r.installment_num ? <b>{v}</b> : v,
                  align: 'center',
                },
                {
                  key: 'installment_total',
                  title: 'Total Angsuran',
                  dataIndex: 'installment_total',
                  width: 150,
                  render: (v, r) => !r.installment_num ? <b>{v}</b> : v,
                  align: 'center',
                },
                {
                  key: 'state_payment',
                  title: 'Status Pembayaran',
                  dataIndex: 'state_payment',
                  width: 180,
                  render: (v) => MappingLoanState(v),
                  align: 'center',
                },
                {
                  key: 'installment_number',
                  title: 'No Pembayaran',
                  dataIndex: 'installment_number',
                  width: 180,
                  render: (t) => (t  ? <a>{t}</a> : '-'),
                  align: 'center',
                },
              ]}
            />
          </Col>
        </Row>
      </Modal>

      {showModalPassword && (
        <Modal
          open={showModalPassword}
          title="Masukkan Password"
          okText="Submit"
          cancelText={null}
          okButtonProps={{ autoFocus: true, htmlType: 'submit' }}
          onCancel={() => setShowModalPassword(false)}
          destroyOnClose
          modalRender={(dom) => (
            <Form layout="vertical" form={form} onFinish={(values) => onSubmit(values)}>
              {dom}
            </Form>
          )}
        >
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Password harus diisi' }]}
          >
            <Input.Password />
          </Form.Item>
        </Modal>
      )}

      <div style={{ border: '1px solid #000', padding: 10, overflowY: 'scroll', height: '90vh' }}>
        <Form
          colon={false}
          labelAlign="left"
          form={form}
          layout="horizontal"
          onFinish={onFinish}
          onValuesChange={forceUpdate}
        >
          <div>
            <div className="form-wrapper">
              <div>
                <Breadcrumb breadcrumbConf={breadcrumbConf} />
                <div style={{ padding: 10, overflowY: 'scroll', height: 'max-content' }}>
                  <FormBuilder form={form} meta={meta} />
                  <Row justify={'end'}>
                    <Button type="primary" onClick={save} disabled={isLoading || disabledSave}>
                      Simpan
                    </Button>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>

      <BottomLoading active={isLoading} />
    </div>
  );
}
