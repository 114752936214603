import { useState, useEffect } from 'react';
import { Input, Form, Col, Row, DatePicker, Select, Tooltip } from 'antd';
import {
  EmployeeLeaveDetailFromPropsType,
  EmployeeLeaveDetailGeneralForm,
  EmployeeLeaveDetailLeaveType,
  EmployeeLeaveDetailLeaveDate,
  EmployeeLeaveDetailUnfinishLeave,
} from './EmployeeLeaveDetailForm.interface';
import './EmployeeLeaveDetailForm.less';
import { InfoCircleOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

export default function EmployeeLeaveDetailForm(props: EmployeeLeaveDetailFromPropsType) {
  let [submissionDate, setSubmissionDate] = useState<EmployeeLeaveDetailGeneralForm>();
  let [employee, setEmployee] = useState<EmployeeLeaveDetailGeneralForm>();
  let [position, setPosition] = useState<EmployeeLeaveDetailGeneralForm>();
  let [department, setDepartment] = useState<EmployeeLeaveDetailGeneralForm>();
  let [description, setDescription] = useState<EmployeeLeaveDetailGeneralForm>();

  let [leaveType, setLeaveType] = useState<EmployeeLeaveDetailLeaveType>();
  let [leaveAll, setLeaveAll] = useState<EmployeeLeaveDetailGeneralForm>()
  let [remainsDayOff, setRemainsDayOff] = useState<EmployeeLeaveDetailGeneralForm>();
  let [leaveDate, setLeaveDate] = useState<EmployeeLeaveDetailLeaveDate>();
  let [leaveDuration, setLeaveDuration] = useState<EmployeeLeaveDetailGeneralForm>();
  let [unfinishLeave, setUnfinishLeave] = useState<EmployeeLeaveDetailUnfinishLeave>()
  let [availPlafon, setAvailPlafon] = useState<EmployeeLeaveDetailGeneralForm>()

  const leftSectionBluePrint = [
    {
      label: 'Tanggal Pengajuan',
      name: 'tanggal_pengajuan',
      componentType: '',
      state: submissionDate,
    },
    { label: 'Karyawan', name: 'karyawan', componentType: 'select', state: employee },
    { label: 'Jabatan', name: 'jabatan', componentType: '', state: position },
    { label: 'Departemen', name: 'departemen', componentType: '', state: department },
    { label: 'Keterangan', name: 'keterangan', componentType: 'text_area', state: description },
  ];

  const rightSectionBluePrint = [
    { label: 'Tipe Cuti', name: 'tipe_cuti', componentType: 'select', state: leaveType },
    { label: 'Cuti Bersama', name: 'cuti_bersama', componentType: '', state: leaveAll },
    {
      label: 'Sisa Plafon Cuti',
      name: 'sisa_plafon_cuti',
      componentType: '',
      state: remainsDayOff,
    },
    {
      label: <Tooltip title="Akumulasi jumlah
      hari cuti yang sudah diajukan namun
      masih menunggu persetujuan" placement="top">
        <InfoCircleOutlined style={{ marginRight: '4px' }} />
        <span>Cuti belum final</span>
      </Tooltip>,
      name: 'cuti_belum_final',
      componentType: '',
      state: unfinishLeave,
    },
    {
      label: <Tooltip title="Jumlah
      maksimum plafon cuti yang dapat
      diajukan setelah dikurangi jumlah Cuti
      belum final" placement="top">
        <InfoCircleOutlined style={{ marginRight: '4px' }} />
        <span>Plafon yang tersedia</span>
      </Tooltip>,
      name: 'plafon_yang_tersedia',
      componentType: '',
      state: availPlafon,
    },
    {
      label: 'Tanggal Cuti',
      name: 'tanggal_cuti',
      componentType: 'range-datepicker',
      state: leaveDate,
    },
    { label: 'Durasi', name: 'durasi', componentType: '', state: leaveDuration },
  ];

  useEffect(() => {
    processGeneralPropsType('submissionDate');
  }, [props.submissionDate]);
  useEffect(() => {
    processSelectType('employee');
  }, [props.employee]);
  useEffect(() => {
    processGeneralPropsType('position');
  }, [props.position]);
  useEffect(() => {
    processGeneralPropsType('department');
  }, [props.department]);
  useEffect(() => {
    processGeneralPropsType('description');
  }, [props.description]);

  useEffect(() => {
    processSelectType('leaveType');
  }, [props.leaveType]);
  useEffect(() => {
    processGeneralPropsType('leaveAll');
  }, [props.leaveAll]);
  useEffect(() => {
    processGeneralPropsType('remainsDayOff');
  }, [props.remainsDayOff]);
  useEffect(() => {
    processLeaveDate();
  }, [props.leaveDate]);
  useEffect(() => {
    processGeneralPropsType('leaveDuration');
  }, [props.leaveDuration]);
  useEffect(() => {
    processUnfinishLeave();
  }, [props.unfinishLeave]);
  useEffect(() => {
    processGeneralPropsType('availPlafon');
  }, [props.availPlafon]);

  const processGeneralPropsType = (procedure) => {
    const result: any = {
      active: false,
      value: '',
      editMode: false,
      rules: [{ required: false, message: '' }],
    };

    if (props[procedure]) {
      result.active = props[procedure].active !== undefined ? props[procedure].active : true;
      result.value = props[procedure].value ? props[procedure].value : '';
      result.editMode = props[procedure].editMode !== undefined ? props[procedure].editMode : false;
      result.rules = props[procedure].rules
        ? props[procedure].rules
        : [{ required: false, message: '' }];
    }

    switch (procedure) {
      default:
        break;
      case 'submissionDate':
        setSubmissionDate(result);
        break;
      case 'employee':
        setEmployee(result);
        break;
      case 'position':
        setPosition(result);
        break;
      case 'department':
        setDepartment(result);
        break;
      case 'description':
        setDescription(result);
        break;
      case 'remainsDayOff':
        setRemainsDayOff(result);
        break;
      case 'leaveDuration':
        setLeaveDuration(result);
        break;
      case 'availPlafon':
        setAvailPlafon(result);
        break;
      case 'leaveAll':
        setLeaveAll(result);
        break;
    }
  };

  const processSelectType = (procedure) => {
    const result: any = {
      active: true,
      value: '',
      editMode: false,
      options: [{ label: 'Option 1', value: 'option_1' }],
      rules: [{ required: false, message: '' }],
    };

    if (props[procedure]) {
      result.active = props[procedure].active !== undefined ? props[procedure].active : true;
      result.value = props[procedure].value;
      result.editMode = props[procedure].editMode !== undefined ? props[procedure].editMode : false;
      result.options = props[procedure].options
        ? props[procedure].options
        : [{ label: 'Option 1', value: 'option_1' }];
      result.rules = props[procedure].rules
        ? props[procedure].rules
        : [{ required: false, message: '' }];
    }

    switch (procedure) {
      default:
        break;
      case 'leaveType':
        setLeaveType(result);
        break;
      case 'employee':
        result.showSearch = true;
        result.placeholder = 'Cari karyawan...';
        setEmployee(result);
        break;
    }
  };

  const processUnfinishLeave = () => {
    const result: any = {
      active: false,
      value: '',
      editMode: false,
      showList: <></>,
      rules: [{required: false, message: ''}]
    }

    if (props.unfinishLeave) {
      result.active = props.unfinishLeave.active !== undefined ? props.unfinishLeave.active : true;
      result.value = props.unfinishLeave.value;
      result.editMode = props.unfinishLeave.editMode !== undefined ? props.unfinishLeave.editMode : false;
      result.showList = props.unfinishLeave.showList !== undefined && result.value && result.value !== "N/A"
       ? props.unfinishLeave.showList : null;
      result.rules = props.unfinishLeave.rules
        ? props.unfinishLeave.rules
        : [{ required: false, message: '' }];
    }    

    setUnfinishLeave(result)
  }

  const processLeaveDate = () => {
    const result: any = {
      active: true,
      startDate: '-',
      endDate: '-',
      value: '',
      editMode: false,
      rules: [{ required: false, message: '' }],
    };

    if (props.leaveDate) {
      result.active = props.leaveDate.active !== undefined ? props.leaveDate.active : true;
      result.startDate = props.leaveDate.startDate ? props.leaveDate.startDate : '-';
      result.endDate = props.leaveDate.endDate ? props.leaveDate.endDate : '-';
      result.value = props.leaveDate.value ? props.leaveDate.value : '';
      result.editMode = props.leaveDate.editMode !== undefined ? props.leaveDate.editMode : false;
      result.rules = props.leaveDate.rules
        ? props.leaveDate.rules
        : [{ required: false, message: '' }];
    }

    setLeaveDate(result);
  };

  const FormItem = ({ sectionBluePrint }) => {
    const label = sectionBluePrint.label;
    const name = sectionBluePrint.name;
    const componentType = sectionBluePrint.componentType;
    const state = sectionBluePrint.state;

    if (state && !state.editMode) {
      return <FormItemValue label={label} name={name} state={state} />;
    } else {
      switch (componentType) {
        default:
          return <FormItemValue label={label} name={name} state={state} />;
        case 'text_area':
          return <FormItemTextArea label={label} name={name} state={state} />;
        case 'range-datepicker':
          return <FormItemRangeDatepicker label={label} name={name} state={state} />;
        case 'select':
          return <FormItemSelect label={label} name={name} state={state} />;
      }
    }
  };

  const FormItemValue = ({ label, name, state }) => {
    return state && state.active === true ? (
      <Form.Item label={label} name={name} labelAlign="left" colon={false} rules={state.rules}>
        <span className="form-value">{state.value}</span>
        {state.showList && <span> {state.showList}</span>}
      </Form.Item>
    ) : null;
  };

  const FormItemTextArea = ({ label, name, state }) => {
    return state && state.active === true ? (
      <Form.Item label={label} name={name} labelAlign="left" colon={false} rules={state.rules}>
        <TextArea rows={4} style={{ width: '90%' }} />
      </Form.Item>
    ) : null;
  };

  const FormItemRangeDatepicker = ({ label, name, state }) => {
    return state && state.active === true ? (
      <Form.Item label={label} name={name} labelAlign="left" colon={false} rules={state.rules}>
        <RangePicker style={{ width: '96%' }} format="DD/MM/YYYY" />
      </Form.Item>
    ) : null;
  };

  const FormItemSelect = ({ label, name, state }) => {
    return state && state.active === true ? (
      <Form.Item label={label} name={name} labelAlign="left" colon={false} rules={state.rules}>
        <Select
          style={{ width: '96%' }}
          showSearch={state.showSearch}
          placeholder={state.placeholder}
          filterOption={(input:any, option:any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {state.options.map((option) => (
            <Option value={option.value} key={option.id}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    ) : null;
  };

  const LeftSection = () => {
    return (
      <Col span={12}>
        {leftSectionBluePrint.map((sectionBluePrint) => (
          <FormItem key={sectionBluePrint.name} sectionBluePrint={sectionBluePrint} />
        ))}
      </Col>
    );
  };

  const RightSection = () => {
    return (
      <Col span={12}>
        {rightSectionBluePrint.map((sectionBluePrint) => (
          <FormItem key={sectionBluePrint.name} sectionBluePrint={sectionBluePrint} />
        ))}
      </Col>
    );
  };

  return (
    <Row>
      <LeftSection />
      <RightSection />
    </Row>
  );
}
