import Constant from '../../../config/Constants';
import API from '../../../services/API.service';
import {
  GetEmployeeLeaveConfigListDTO,
  GetEmployeeLeaveConfigListResp,
} from '../../interface/leave/GetEmployeeLeaveConfigList.interface';

export default function getEmployeeLeaveConfigList(
  params: GetEmployeeLeaveConfigListDTO,
): Promise<GetEmployeeLeaveConfigListResp> {
  const URL = `${Constant.URL_BASE_ATTENDANCE}/v3/lov/leave-config`;
  return API.get(URL, { params });
}
