import { useEffect, useState } from "react";
import ExpandableListTemplate from "../../../../../../templates/expandable-list-template/ExpandableListTemplate";
import { ExpandableListTemplateDropdownButton, ExpandableListTemplatePagination } from "../../../../../../templates/expandable-list-template/ExpandableListTemplate.interface";
import { ApprovalOvertimeListConstant } from "./EmployeeApprovalOvertimeList.constant";
import EmployeeapprovalOvertimeUseCase from "./EmployeeApprovalOvertimeList.usecase";
import { Button, Modal, Space } from "antd";
import ToastService from "../../../../../../services/Toast.service";
import LocalNotificationService from "../../../../../../services/LocalNotification.service";
import { createExcelFromByte } from "../../../../payroll/configuration/master-payroll/list-master-payroll/ListMasterPayroll.service";
import { ListApprovalOvetimeMapper } from "../../../../../../@core/mapper/overtime/ListSubmissionOvertimeMapper";

const useCase = new EmployeeapprovalOvertimeUseCase()
const toastService = new ToastService()
const localNotificationService = new LocalNotificationService()
let page = 1;
let loadPagination = true;
let sort = 'write_date|desc';
let number_request
let nik
let emp_name
let date_request
let selectionApprovalId = [];
let id
let submissionNumber
let state: "approve" | "reject"
const limit = 10

export default function ListEmployeeApprovalOvertime() {
  const tableHeader: any = ApprovalOvertimeListConstant.TABLE_HEADER
  const searchColumnKey = ApprovalOvertimeListConstant.SEARCH_COLUMN_KEY
  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: false,
        displayPathName: 'Persetujuan Lembur',
        href: "/v2/app/payroll/configuration/Master-gaji",
      },
    ],
  };
  let [pagination, setPagination] = useState<ExpandableListTemplatePagination>({
    current: page,
    pageSize: limit,
    total: 0,
    size: 'small',
  });
  let [isLoading, setIsLoading] = useState(false);
  let [dataSource, setDataSource] = useState([
    ...ApprovalOvertimeListConstant.DEFAULT_EMPTY_DATA_SOURCE
  ]);
  let [tableScroll, setTableScroll] = useState({ y: 700, x: 'max-content' });
  let [visibleWarningModal, setVisibleWarningModal] = useState(false)
  const tableData = { searchColumnKey, dataSource, tableRowSelectionActive: true };

  useEffect(() => {
    loadList()
    tableHeader[9].render = (_, record) => {
      return record.key !== 'search-input' && record.key !== 'empty-data' ? (
        <Space>
          <ApproveButton rowId={record.key} 
            number={record.number_submission_overtime} />
          <RejectButton rowId={record.key} 
            number={record.number_submission_overtime} />
        </Space>
      ) : null
    }
  }, [])

  function loadList() {
    setIsLoading(true);

    const params = {
      page,
      limit,
      sort,
      number_request,
      nik,
      date_request,
      emp_name,
      with_meta: true,
    };

    useCase.getAll(params).then(
      (res: any) => {
        setIsLoading(false)

        const content = res.data.content;
        const metaData = res.data.metadata;

        const resultDataSource = content?.length ?  ListApprovalOvetimeMapper(content) : 
        ApprovalOvertimeListConstant.DEFAULT_EMPTY_DATA_SOURCE;
        
        setDataSource([...resultDataSource]);
        updatePaginationState(metaData?.total_records || 0);

        loadPagination = true;
      },
      (err) => {
        setIsLoading(false);
        loadPagination = true;
      })
  }

  function updatePaginationState(totalData) {
    const tempPagination = pagination;

    tempPagination.current = page;
    tempPagination.total = totalData;
    setPagination(JSON.parse(JSON.stringify(tempPagination)));
  }

  const tableOnChange = (output) => {
    const outputPagination = output.pagination;
    const outputSort = output.sort;
    const outputSearchColumn = output.searchColumn;

    processSort(outputSort);
    processSearchColumn(outputSearchColumn);
    processSelection(output.selection);
    processPagination(outputPagination);
  };

  function processPagination(outputPagination) {
    if (outputPagination && outputPagination.page !== page && loadPagination) {
      page = outputPagination.page;
      loadList();
    }
  }

  function processSort(outputSort) {
    if (outputSort && useCase.convertOutputSortToEndPointFormat(outputSort) !== sort) {
      page = 1;
      loadPagination = false;

      sort = useCase.convertOutputSortToEndPointFormat(outputSort);
      loadList();
    }
  }

  function processSearchColumn(outputSearchColumn) {
    let isLoadList = false;

    if (outputSearchColumn) {
      if (
        outputSearchColumn.number_submission_overtime !== undefined &&
        outputSearchColumn.number_submission_overtime !== number_request
      ) {
        number_request = outputSearchColumn.number_submission_overtime;
        isLoadList = true;
      }

      if (
        outputSearchColumn.employee_nik !== undefined &&
        outputSearchColumn.employee_nik !== nik
      ) {
        nik = outputSearchColumn.employee_nik;
        isLoadList = true;
      }

      if (
        outputSearchColumn.employee_name !== undefined &&
        outputSearchColumn.employee_name !== emp_name
      ) {
        emp_name = outputSearchColumn.employee_name;
        isLoadList = true;
      }

      if (
        outputSearchColumn.submission_date !== undefined &&
        outputSearchColumn.submission_date !== date_request
      ) {
        date_request = outputSearchColumn.submission_date;
        isLoadList = true;
      }

      if (isLoadList) {
        page = 1;
        loadPagination = false;
        loadList();
      }
    }
  }

  function processSelection(outputSelection) {
    if (outputSelection && outputSelection !== selectionApprovalId) {
      selectionApprovalId = outputSelection;
    }
  }

  const approveData = () => {
    setIsLoading(true)
    const body = { id: id, state }

    useCase.approve(body).then(
      (res) => {
        const content = res.data.content
        if (content.code === 200) {
          localNotificationService.openSuccess('Pengajuan lembur telah ' + state ? 'disetujui' : 'ditolak', '', 1)
        }
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      }).catch(err => {
        setIsLoading(false)
        toastService.error(err)
      })
  }

  function exportData() {
    setIsLoading(true);

    const params = {
      sort,
      number_request,
      date_request,
      nik,
      emp_name,
      id: selectionApprovalId,
      with_meta: true,
    };

    useCase.export(params).then(
      (res: any) => {
        const content = res.data.content;
        if (res.data.status === 200) {
          const bytes = content.data
          const fileName = content.filename
          createExcelFromByte(bytes, fileName);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    )
  }

  const ApproveButton = ({ rowId, number }) => {
    return (
      <Button
        size="large"
        disabled={isLoading}
        onClick={() => {
          id = rowId
          state = "approve"
          submissionNumber = number
          setVisibleWarningModal(true)
        }}
        style={{
          color: 'white',
          fontWeight: 500,
          borderRadius: '5px',
          background: '#00cc00',
        }}
      >
        Setuju
      </Button>
    )
  }

  const RejectButton = ({ rowId, number }) => {
    return (
      <Button
        size="large"
        type="primary"
        disabled={isLoading}
        onClick={() => {
          id = rowId
          state = "reject"
          submissionNumber = number
          setVisibleWarningModal(true)
        }}
        style={{
          color: 'white',
          fontWeight: 500,
          borderRadius: '5px',
        }}
      >
        Tolak
      </Button>
    )
  }

  const dropdownButton: ExpandableListTemplateDropdownButton = {
    visible: true,
    disabled: isLoading,
    options: [
      { key: 'ekspor', label: 'Ekspor', onClick: () => exportData() },
    ]
  };

  return (
    <div className="list-salary-approval">
      <Modal
        cancelText="BATALKAN"
        centered={true}
        visible={visibleWarningModal}
        cancelButtonProps={{ type: 'ghost' }}
        onOk={approveData}
        onCancel={() => setVisibleWarningModal(false)}
      >
        <div style={{
          display: "flex",
          width: '100%',
          flexDirection: "column",
          alignItems: 'center',
        }}>
          <p>
            Apakah anda yakin ingin 
            {state === "approve" ? ' menyetujui ' : ' menolak '} 
            Pengajuan Lembur
          </p>
          <a href="">{submissionNumber}</a>
        </div>
      </Modal>
      <ExpandableListTemplate
        breadcrumbConf={breadcrumbConf}
        tableData={tableData}
        tableHeader={tableHeader}
        deleteButton={{ visible: false }}
        addButton={{ visible: false }}
        exportButton={{ visible: false }}
        dropdownButton={dropdownButton}
        tableOnChange={tableOnChange}
        tablePagination={pagination}
        tableScroll={tableScroll}
        isLoading={isLoading}
        expandedRowRender={null}
      />
    </div>
  )
}