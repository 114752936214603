import Constant from "../config/Constants";
var keyBase64 = btoa(process.env.REACT_APP_PUBLIC_ENCRYPTION_KEY);
export async function aesGcmDecrypt( ciphertextBase64) {
  try {
    const keyBuffer = Uint8Array.from(atob(keyBase64), c => c.charCodeAt(0));
    const enc = Uint8Array.from(atob(ciphertextBase64), c => c.charCodeAt(0));

    const nonce = enc.slice(0, 12);
    const ciphertextBytes = enc.slice(12);

    const key = await crypto.subtle.importKey(
      'raw',
      keyBuffer,
      { name: 'AES-GCM', length: 256 },
      false,
      ['decrypt']
    );

    const decryptedArrayBuffer = await crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv: nonce
      },
      key,
      ciphertextBytes
    );

    const decoder = new TextDecoder();
    const plaintext = decoder.decode(decryptedArrayBuffer);
    return plaintext;
  } catch (err) {
    console.error('Error during decryption:', err);
    return '';
  }
}