import { Col, Row, Select } from "antd"

export const EmployeeNameSubmissionOvertime = ({ options, isLoading, value, onChange }) => {
  return (
    <Row>
      <Col span={16}>
        <Select
          style={{ width: '96%' }}
          showSearch={true}
          value={value}
          onChange={onChange}
          placeholder={isLoading ? 'Loading...' : 'Pilih Karyawan...'}
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {options.map((option) => (
            <Select.Option value={option.value} key={option.id}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Col>
    </Row>
  )
}