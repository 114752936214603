import { useEffect, useState } from 'react';
import ImportCsvTemplate from '../../../../../../templates/import-csv-template/ImportCsvTemplate';
import { MasterWorkingScheduleTableColumns } from './ImportMasterWorkingSchedule.constant';
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import ImportMasterWorkingScheduleUseCase from './ImportMasterWorkingSchedule.usecase';
import HrLeaveService from '../../../../leaves/HrLeaveService';
import { useUserState } from '../../../../../../context/UserContext';
import { groupingScheduleByName } from './ImportMasterWorkingSchedule.service';
import SharedService from '../../../../../../services/Shared.service';
import ErrorDetailButton from '../../../../../../templates/import-csv-template/component/error-detail-button/ErrorDetailButton';
import LocalNotificationService from '../../../../../../services/LocalNotification.service';
import ToastService from '../../../../../../services/Toast.service';

const localNotificationService = new LocalNotificationService();
const hrLeaveService = new HrLeaveService();
const sharedService = new SharedService();
const toastService = new ToastService();
let selectedFile;
let body;

export default function ImportMasterWorkingSchedule() {
  const useCase = new ImportMasterWorkingScheduleUseCase();
  const userState: any = useUserState();

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Master Jadwal Kerja',
        href: `/v2/app/attendance/master-working-schedule`,
      },
      {
        clickable: false,
        displayPathName: 'Import',
        href: '/v2/app/hr-leave/employee-approval/new',
      },
    ],
  };
  let [isLoading, setIsLoading] = useState(false);
  let [disabledValidationButton, setDisabledValidationButton] = useState(true);
  let [disabledSaveButton, setDisabledSaveButton] = useState(true);
  let [disabledDownloadTemplateButton, setDisabledDownloadTemplateButton] = useState(false);
  let tableHeader = MasterWorkingScheduleTableColumns;
  let [tableData, setTableData] = useState([]);
  let [tableScroll, setTableScroll] = useState({ y: 200 });

  useEffect(() => {
    tableHeader[0].render = (status) => {
      return status ? (
        <CheckCircleOutlined style={{ fontSize: '18px', color: '#60b23c' }} />
      ) : (
        <WarningOutlined style={{ fontSize: '18px', color: '#d64219' }} />
      );
    };

    tableHeader[7].render = (text, record) => {
      return !record.status ? <ErrorDetailButton data={record} /> : null;
    };

    const clientHeight = document.getElementById('import-master-working-schedule').clientHeight;
    setTableScroll({ y: clientHeight - 310 });

    selectedFile = undefined;
  }, []);

  const onFileSelected = (e: any) => {
    setTableData([...[]]);

    if (e.status === 'success') {
      setDisabledValidationButton(false);
      setDisabledSaveButton(true);
      selectedFile = e;
    } else {
      setDisabledValidationButton(true);
      setDisabledSaveButton(true);

      const error_message = e.content.error_message;
      toastService.error(error_message);
    }
  };

  const onClickValidate = () => {
    setIsLoading(true);

    useCase.doValidate(selectedFile).then(
      async (res: any) => {
        const dataSource = res.content.dataSource;

        for (let columnIndex = 0; columnIndex < dataSource.length; columnIndex++) {
          if (dataSource[columnIndex].status === false) {
            setTableData([...dataSource]);
            setDisabledValidationButton(true);
            setIsLoading(false);
            toastService.error(
              'Terjadi kesalahan data, silahkan melihat table di bawah untuk lebih detail nya',
            );
            break;
          }

          if (columnIndex === dataSource.length - 1) {
            body = await groupingScheduleByName(dataSource, userState.auth.company_id);

            useCase.validateToServer(body).then(
              (res) => {
                const data = res.data;
                if (data.content.valid === true) {
                  setTableData([...dataSource]);
                  setDisabledSaveButton(false);
                  setDisabledValidationButton(true);
                  setIsLoading(false);
                } else {
                  const errors = data.content.errors;

                  processValidateFromServer(dataSource, errors);
                  setDisabledSaveButton(true);
                  setDisabledValidationButton(true);
                  setIsLoading(false);
                }
              },
              (err: any) => {
                setTableData([...dataSource]);
                setDisabledSaveButton(false);
                setDisabledValidationButton(true);
                setIsLoading(false);
              },
            );
          }
        }
      },
      (err: any) => {
        const error_message = err.content.errorMessage;
        toastService.error(error_message);
        setDisabledValidationButton(true);
        setIsLoading(false);
      },
    );
  };

  const processValidateFromServer = (dataSource, errors) => {
    let isErrorOption = false
    if (errors.length) {
      let temp = JSON.parse(JSON.stringify(dataSource));

      errors.forEach((element: any, index) => {
        let indexError = 0;

        if (element.errors) {
          element.errors.forEach((error) => {
            if (element.index === 0) {
              indexError = element.index + error.index;
            } else {
              indexError = element.index * 7 + error.index;
            }

            if (error.message.includes(';')) {
              const rowErrors = error.message.split('; ');
              rowErrors.forEach((data) => {
                const rawError = data.split(': ');
                const errorField = rawError[0];
                const errorMessage = rawError[1];

                temp[indexError].status = false;
                temp[indexError].error_list[errorField].status = false;
                temp[indexError].error_list[errorField].message.push(errorMessage);
              });
            } else {
              const rawError = error.message.split(': ');
              const errorField = rawError[0];
              const errorMessage = rawError[1];

              temp[indexError].status = false;
              temp[indexError].error_list[errorField].status = false;
              temp[indexError].error_list[errorField].message.push(errorMessage);
            }
          });
        } else {
          indexError = element.index * 7;

          const rawError = element.message.split(': ');
          const errorField = rawError[0] === 'days_of_work' ? 'day' : rawError[0];
          const errorMessage = rawError[1];

          if (errorField === "absent_option") {
            temp[indexError].status = false;
            if (!isErrorOption) {
              toastService.error(errorMessage)
              isErrorOption = true 
            }
          } else {
            temp[indexError].status = false;
            temp[indexError].error_list[errorField].status = false;
            temp[indexError].error_list[errorField].message.push(errorMessage);
          }
        }

        if (index === errors.length - 1) {
          setTableData([...temp]);
        }
      });
    }
  };

  const onClickSave = () => {
    setIsLoading(true);
    
    useCase.saveSchedule(body).then(
      (res: any) => {
        const data = res.data;

        if (data.status === 200) {
          localNotificationService.openSuccess('Impor data berhasil.', '', 3);

          setTimeout(() => {
            const backLink = `/v2/app/attendance/master-working-schedule`;
            sharedService.backToOdooLink(backLink);
          }, 1500);
        } else {
          setIsLoading(false);
        }
      },
      (err: any) => {
        setIsLoading(false);
      },
    );
  };

  const onClickDownloadTemplate = () => {
    setIsLoading(true);

    useCase.downloadTemplate().then((res: any) => {
      setIsLoading(false);
      const data = res.data;

      if (data.status === 200) {
        hrLeaveService.createAndExecuteHref(data.content.url);
      }
    });
  };

  return (
    <div id="import-master-working-schedule">
      <ImportCsvTemplate
        breadcrumbConf={breadcrumbConf}
        chooseFileButton={{
          onSelected: onFileSelected,
        }}
        validateButton={{
          disabled: disabledValidationButton || isLoading,
          onClick: onClickValidate,
        }}
        saveButton={{
          disabled: disabledSaveButton || isLoading,
          onClick: onClickSave,
        }}
        downloadTemplateButton={{
          disabled: disabledDownloadTemplateButton || isLoading,
          onClick: onClickDownloadTemplate,
        }}
        tableHeader={tableHeader}
        tableData={tableData}
        tableScroll={tableScroll}
        isLoading={isLoading}
      />
    </div>
  );
}
