import moment from 'moment';
import { DetailEmployeeTerminateType } from '../../../pages/hr/employee/employee-terminate/termplate/AddEmployeeTerminateTemplate.interface';
import { InsertFileTableDataSource } from '../../../pages/hr/employee/employee-terminate/termplate/components/InsertFileTable.interface';

const FormData = require('form-data');

export function SaveEmployeeTerminateMapper(
  value: DetailEmployeeTerminateType,
  jobList: any[],
  employeeList: any[],
  leave_queue: number
) {
  const selectedEmployee = employeeList.filter((data) => data.value === value.employee)[0];
  const employeeJobDetail = jobList[selectedEmployee.job_id];

  const [nik, name] = selectedEmployee.label.split(' - ');
  const dataSource = value.insert_file_table.dataSource

  return objectToFormData({
    nik: nik,
    employee_name: name,
    last_job: employeeJobDetail?.job_name,
    last_department: employeeJobDetail?.dept_name,
    description: value.information,
    effective_date: moment(value.effective_date).format('YYYY-MM-DD'),
    reason_termination_id: value.terminate_reason,
    termination_category_id: value.terminate_category,
    employee_id: value.employee,
    leave_queue,
    job_id: employeeJobDetail?.id,
    file: dataSource?.filter(e => e.name !== 'button')
      ?.map(e => objectToFormData(e)),
  })
}

export function getEmployeeTerminateList(terminateData) {
  const result = terminateData.map((terminate) => {
    return {
      key: terminate.id,
      terminate_number: terminate.termination_code,
      nomor_induk_karyawan: terminate.nik,
      name: terminate.employee_name,
      job: terminate.last_job,
      department: terminate.last_department,
      terminate_category: terminate.termination_category,
      keterangan: terminate.description,
      terminate_date: formatEffectiveDate(terminate.effective_date),
      status: terminate.active ? "Aktif" : "Non-aktif",
      create_name: terminate.create_name,
      create_date: formatDate(terminate.create_date),
      write_name: terminate.write_name,
      write_date: formatDate(terminate.write_date),
    }
  });
  
  return result
}

export function getEmployeeTerminateDetail(content) {
  return {
    termination_code: content.termination_code,
    department: content.last_department,
    effective_date: formatEffectiveDate(content.effective_date),
    employee: content.nik + " - " + content.employee_name,
    information: content.description,
    job: content.last_job,
    join_date: content.join_date,
    leave_remaining: content.leave_queue,
    must_be_returned: content.returned,
    terminate_category: content.category,
    terminate_reason: content.reason,
    insert_file_table: content.file,
    history_id: content.history_id,
  }
}

function formatDate(dateString: string) {
  return moment(dateString).format("YYYY-MM-DD HH:mm:ss")
}

function formatEffectiveDate(dateString: string) {
  return moment(dateString).format("YYYY-MM-DD")
}

function objectToFormData(obj) {
  const formData = new FormData();

  Object.entries(obj).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return formData;
}