import { useEffect, useState } from 'react';
import { Button, Col, Row, Space, Skeleton } from 'antd';
import PieChart from './components/PieChart';
import LineChart from './components/LineChart';
import BarChart from './components/BarChart';
import './AdminDashboard.less';
import Notification from './templates/admin-dashboard-template/components/Notification';
import Shortcut from './templates/admin-dashboard-template/components/Shortcut';
import ReadOnlyCard from './templates/admin-dashboard-template/components/ReadOnlyCard';
import EmployeeOvertime from './templates/admin-dashboard-template/components/EmployeeOvertime';
import EmployeeBirthDate from './templates/admin-dashboard-template/components/EmployeeBirthDate';
import moment from 'moment';
import {
  DataSourceBirthDateType,
  NotificationType,
} from './templates/admin-dashboard-template/components/DashboardCard.interface';
import AdminDashboardUsecase from './AdminDashboard.usecase';
import { ToolbarDetailActionButton } from '../hr/component/toolbar-button-detail-template/ToolbarButtonDetailTemplate.interface';
import ToolbarButtonDetailTemplate from '../hr/employee/components/toolbar-button-detail-template/ToolbarButtonDetailTemplate';

const useCaseImpl = new AdminDashboardUsecase();
let is_admin = false;
let is_manager = false;
let is_employee = false;
export default function Dashboard() {
  const currentYear = new Date().getFullYear();
  const [isLoading, setIsLoading] = useState(false);
  const [eduData, setEduData] = useState<any>([]);
  const [statusData, setStatusData] = useState<any>([]);
  const [ageData, setAgeData] = useState<any>([]);
  const [PtkpData, setPtkpData] = useState<any>([]);
  const [turnOverData, setTurnOverData] = useState<any>([]);
  const [deptData, setDeptData] = useState<any>([]);
  const [selectedYear, setSelectedYear] = useState<any>(currentYear);
  const [employeeJoin, setEmployeeJoin] = useState<number>(0);
  const [employeeTotal, setEmployeeTotal] = useState<number>(0);
  const [notificationData, setNotificationData] = useState<NotificationType>();
  const [employeeOvertime, setEmployeeOvertime] = useState<any>({ hour: 0, employee: 0 });
  const [dataSourceBirthDate, setDataSourceBirthDate] = useState<DataSourceBirthDateType[]>([]);

  const currentMenu = localStorage.getItem('menu');
    if (currentMenu) {
      const menu = JSON.parse(currentMenu);
      is_admin = menu.find(item => item.id === 2)?.isAdmin ? true : false
      is_manager = menu.find(item => item.id === 2)?.isManager ? true : false
      is_employee = menu.find(item => item.id === 2)?.isEmployee ? true : false
    }

  useEffect(() => {
    initialPage();
  }, []);

  useEffect(() => {
    initTurnOver();
  }, [selectedYear]);

  async function initialPage() {
    setIsLoading(true);

    let reportEdu: any = {
      labels: [],
      data: [],
      item: [],
    };
    let reportEmployeeStatus: any = {
      labels: [],
      data: [],
      item: [],
    };
    let reportAge: any = {
      labels: [],
      data: [],
      item: [],
    };
    let reportPtkp: any = {
      labels: [],
      data: [],
      item: [],
    };
    let reportDept: any = {
      labels: [],
      data: [],
      item: [],
    };

    const [
      eduChart,
      statusChart,
      ageChart,
      ptkpChart,
      deptChart,
      birthDate,
      notification,
      employeeTotal,
    ] = await Promise.all([
      useCaseImpl.getChartEducation(),
      useCaseImpl.getChartEmployeeStatus(),
      useCaseImpl.getChartAge(),
      useCaseImpl.getChartPtkp(),
      useCaseImpl.getChartDepartment(),
      useCaseImpl.getEmployeeBirthDate(),
      useCaseImpl.getNotification(),
      useCaseImpl.getEmployeeTotal(),
    ]);

    if (eduChart?.data?.content) {
      let labels = [];
      let data = [];
      let items = [];
      eduChart?.data?.content.map((item, i) => {
        labels.push(item.edu_name);
        data.push(item.total);
        items.push(item);
      });
      reportEdu.labels = labels;
      reportEdu.data = data;
      reportEdu.item = items;

      setEduData(reportEdu);
    }

    if (statusChart?.data?.content) {
      let labels = [];
      let data = [];
      let items = [];
      statusChart?.data?.content.map((item, i) => {
        labels.push(item.category);
        data.push(item.total);
        items.push(item);
      });
      reportEmployeeStatus.labels = labels;
      reportEmployeeStatus.data = data;
      reportEmployeeStatus.item = items;
      setStatusData(reportEmployeeStatus);
    }

    if (ageChart?.data?.content) {
      let labels = [];
      let data = [];
      let items = [];
      ageChart?.data?.content.map((item, i) => {
        labels.push(item.umur);
        data.push(item.total);
        items.push(item);
      });
      reportAge.labels = labels;
      reportAge.data = data;
      reportAge.item = items;
      setAgeData(reportAge);
    }

    if (ptkpChart?.data?.content) {
      let labels = [];
      let data = [];
      let items = [];
      ptkpChart?.data?.content.map((item, i) => {
        labels.push(item.ptkp_name);
        data.push(item.total);
        items.push(item);
      });
      reportPtkp.labels = labels;
      reportPtkp.data = data;
      reportPtkp.item = items;
      setPtkpData(reportPtkp);
    }

    if (deptChart?.data?.content) {
      let labels = [];
      let data = [];
      let items = [];
      deptChart?.data?.content.map((item, i) => {
        labels.push(item.department_name);
        data.push(item.total);
        items.push(item);
      });
      reportDept.labels = labels;
      reportDept.data = data;
      reportDept.item = items;
      setDeptData(reportDept);
    }

    const date = moment().format('YYYY-MM');
    dateRecruitment(date);
    dateOvertime(date);

    if (birthDate?.data?.content) {
      const result: DataSourceBirthDateType[] = birthDate?.data?.content.map((item, i) => {
        return {
          key: item.id,
          url: item.file_foto,
          title: item.employee_name,
          description: item.dept_job_name,
          date: moment(item.birth_date).format('DD MMMM'),
        };
      });
      setDataSourceBirthDate(result);
    }

    if (notification?.data?.content) {
      const { count_emp_salary, count_emp_sch, count_emp_ter } = notification.data.content;
      setNotificationData({
        endOfWork: count_emp_ter,
        salaryProcces: count_emp_salary,
        workSchedule: count_emp_sch,
      });
    }

    if (employeeTotal?.data?.content) {
      const { total } = employeeTotal.data.content;
      setEmployeeTotal(total);
    }

    setIsLoading(false);
  }
  async function initTurnOver() {
    const params = {
      date_year: selectedYear,
    };

    let reportTurnOver: any = {
      labels: [],
      dataJoin: [],
      dataResign: [],
      item: [],
    };

    const turnOverChart = await useCaseImpl.getChartTurnOver(params);
    if (turnOverChart?.data?.content) {
      let labels = [];
      let data = [];
      turnOverChart?.data?.content.map((item, i) => {
        labels.push(item.bulan);
        data.push(item.total_all);
      });
      reportTurnOver.labels = labels;
      reportTurnOver.data = data;
      setTurnOverData(reportTurnOver);
    }
  }

  async function dateRecruitment(value: string) {
    const date_join = moment(value).format('YYYY-MM');
    const totalEmployee = await useCaseImpl.getEmployeeJoin({ date_join });
    if (totalEmployee?.data?.content) {
      const total = totalEmployee.data.content.total;
      setEmployeeJoin(total);
    }
  }

  async function dateOvertime(value: string) {
    const date_now = moment(value).format('YYYY-MM');
    const overtime = await useCaseImpl.getEmployeeOvertime({ date_now });
    if (overtime?.data?.content) {
      const { total_employee, total_hour } = overtime.data.content;

      setEmployeeOvertime({ hour: total_hour, employee: total_employee });
    }
  }

  const onChangeDate = (fields, value) => {
    switch (fields) {
      case 'Rekrutmen':
        dateRecruitment(value);
        break;
      case 'Lembur Karyawan':
        dateOvertime(value);
        break;
    }
  };

  const adminButton: ToolbarDetailActionButton = {
    active: is_admin,
    disabled: true,
    label: 'Admin',
  };

  const managerButton: ToolbarDetailActionButton = {
    active: is_manager,
    disabled: false,
    label: 'Daftar Pengajuan',
    onClick: () => {
      window.location.href = '/v2/app/manager-dashboard';
    },
  };

  const employeeButton: ToolbarDetailActionButton = {
    active: is_employee,
    disabled: false,
    label: 'Personal Detail',
    onClick: () => {
      window.location.href = '/v2/app/employee-dashboard';
    },
  };

  return (
    <div id="dashboard-page">
      <ToolbarButtonDetailTemplate
        submitButton={{ active: false }}
        firstActionButton={adminButton}
        secondActionButton={employeeButton}
        thirdActionButton={managerButton}
        fourthActionButton={{ active: false }}
        dropdownActionButton={{ visible: false }}
      />

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Shortcut />
        </Col>
        <Col span={12}>
          {isLoading ? (
            <Skeleton loading={isLoading} active />
          ) : (
            <Notification
              key="notification"
              endOfWork={notificationData?.endOfWork || 0}
              salaryProcces={notificationData?.salaryProcces || 0}
              workSchedule={notificationData?.workSchedule || 0}
            />
          )}
        </Col>
        <Col span={8}>
          {isLoading ? (
            <Skeleton loading={isLoading} active />
          ) : (
            <ReadOnlyCard key="employee_total" title="Total Karyawan" total={employeeTotal} />
          )}
        </Col>
        <Col span={8}>
          {isLoading ? (
            <Skeleton loading={isLoading} active />
          ) : (
            <ReadOnlyCard
              key="recruitment"
              title="Rekrutmen"
              total={employeeJoin}
              onChange={onChangeDate}
            />
          )}
        </Col>
        <Col span={8}>
          {isLoading ? (
            <Skeleton loading={isLoading} active />
          ) : (
            <EmployeeOvertime
              key="employee_overtime"
              title="Lembur Karyawan"
              employee={employeeOvertime.employee}
              hour={employeeOvertime.hour}
              onChange={onChangeDate}
            />
          )}
        </Col>

        <Col span={8}>
          {isLoading ? (
            <Skeleton loading={isLoading} active />
          ) : (
            <div className="chart-wrapper">
              <p className="header-label">Pendidikan</p>
              <p style={{ marginBottom: 20 }}>Jumlah karyawan</p>
              <PieChart
                data={eduData}
                color={[
                  '#fc6524',
                  '#e31620',
                  '#25ba25',
                  '#a80813',
                  '#1d58b6',
                  '#093a95',
                  '#228e52',
                  '#fda615',
                  '#5fc6c9',
                ]}
              />
              <div className="legend-wrapper">
                {!!eduData.item &&
                  eduData.item.map((item) => {
                    return (
                      <div>
                        <Row justify={'space-between'}>
                          <span className="label">{item.edu_name}</span>
                          <Space size={'middle'}>
                            <span className="label">{item.total}</span>
                            <span className="label">{item.persent}</span>
                          </Space>
                        </Row>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </Col>
        <Col span={8}>
          {isLoading ? (
            <Skeleton loading={isLoading} active />
          ) : (
            <div className="chart-wrapper">
              <p className="header-label">Status Karyawan</p>
              <p style={{ marginBottom: 20 }}>Jumlah karyawan</p>
              <PieChart data={statusData} color={['red', 'green', 'orange', 'blue', 'yellow']} />
              <div className="legend-wrapper">
                {!!statusData.item &&
                  statusData.item.map((item) => {
                    return (
                      <div>
                        <Row justify={'space-between'}>
                          <span className="label">{item.category}</span>
                          <Space size={'middle'}>
                            <span className="label">{item.total}</span>
                            <span className="label">{item.persent}</span>
                          </Space>
                        </Row>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </Col>
        <Col span={8}>
          {isLoading ? (
            <Skeleton loading={isLoading} active />
          ) : (
            <EmployeeBirthDate dataSource={dataSourceBirthDate} />
          )}
        </Col>

        <Col span={8}>
          {isLoading ? (
            <Skeleton loading={isLoading} active />
          ) : (
            <div className="chart-wrapper">
              <p className="header-label">Umur</p>
              <p style={{ marginBottom: 20 }}>Jumlah karyawan</p>
              <PieChart
                data={ageData}
                color={[
                  '#fc6524',
                  '#e31620',
                  '#25ba25',
                  '#a80813',
                  '#1d58b6',
                  '#093a95',
                  '#228e52',
                  '#fda615',
                  '#5fc6c9',
                ]}
              />
              <div className="legend-wrapper">
                {!!ageData.item &&
                  ageData.item.map((item) => {
                    return (
                      <div>
                        <Row justify={'space-between'}>
                          <span className="label">{item.umur}</span>
                          <Space size={'middle'}>
                            <span className="label">{item.total}</span>
                            <span className="label">{item.persent}</span>
                          </Space>
                        </Row>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </Col>
        <Col span={8}>
          {isLoading ? (
            <Skeleton loading={isLoading} active />
          ) : (
            <div className="chart-wrapper">
              <p className="header-label">Status Perkawinan</p>
              <p style={{ marginBottom: 20 }}>Jumlah karyawan</p>
              <PieChart
                data={PtkpData}
                color={[
                  '#fc6524',
                  '#e31620',
                  '#25ba25',
                  '#a80813',
                  '#1d58b6',
                  '#093a95',
                  '#228e52',
                  '#fda615',
                  '#5fc6c9',
                ]}
              />
              <div className="legend-wrapper">
                {!!PtkpData.item &&
                  PtkpData.item.map((item) => {
                    return (
                      <div>
                        <Row justify={'space-between'}>
                          <span className="label">{item.ptkp_name}</span>
                          <Space size={'middle'}>
                            <span className="label">{item.total}</span>
                            <span className="label">{item.persent}</span>
                          </Space>
                        </Row>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </Col>
        <Col span={8}>
          {isLoading ? (
            <Skeleton loading={isLoading} active />
          ) : (
            <div className="chart-wrapper">
              <p className="header-label">Turnover Rate</p>
              <p style={{ marginBottom: 20 }}>Jumlah karyawan</p>
              <Row>
                <Space>
                  <Button
                    onClick={() => setSelectedYear(currentYear - 2)}
                    type={selectedYear == currentYear - 2 ? 'primary' : 'default'}
                  >
                    {currentYear - 2}
                  </Button>
                  <Button
                    onClick={() => setSelectedYear(currentYear - 1)}
                    type={selectedYear == currentYear - 1 ? 'primary' : 'default'}
                  >
                    {currentYear - 1}
                  </Button>
                  <Button
                    onClick={() => setSelectedYear(currentYear)}
                    type={selectedYear == currentYear ? 'primary' : 'default'}
                  >
                    {currentYear}
                  </Button>
                </Space>
              </Row>
              <LineChart data={turnOverData} />
            </div>
          )}
        </Col>

        <Col span={24} style={{ width: 'max-content' }}>
          {isLoading ? (
            <Skeleton loading={isLoading} active />
          ) : (
            <div style={{ position: 'relative', width: '100%', overflow: 'scroll' }}>
              <div
                className="chart-wrapper"
                style={{ width: deptData?.data?.length > 50 ? '300%' : '100%' }}
              >
                <p className="header-label">Departemen</p>
                <p style={{ marginBottom: 20 }}>Jumlah karyawan</p>
                <BarChart data={deptData} />
              </div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}
