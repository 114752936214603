export const LeaveConstant = {
  TABLE_HEADER: [
    {
      title: 'Nomor Pengajuan Cuti',
      key: 'numberSubmissionLeave',
      dataIndex: 'numberSubmissionLeave',
      sorter: true,
      isCTA: true,
    },
    { title: 'NIK', key: 'employeeNumber', dataIndex: 'employeeNumber', sorter: true },
    { title: 'Karyawan', key: 'employeeName', dataIndex: 'employeeName', sorter: true },
    {
      title: 'Tanggal Pengajuan',
      key: 'submissionDate',
      dataIndex: 'submissionDate',
      sorter: true,
    },
    { title: 'Durasi', key: 'leaveDuration', dataIndex: 'leaveDuration', sorter: false },
    { title: 'Tipe Cuti', key: 'leaveConfig', dataIndex: 'leaveConfig', sorter: true },
    { title: 'Cuti Bersama', key: 'isLeaveAll', dataIndex: 'isLeaveAll', sorter: true, searchSelectOptions: [
      { label: 'Semua', value: null },
      { label: 'Ya', value: true },
      { label: 'Tidak', value: false },
    ] },
    { title: 'Status', key: 'leaveStatus', dataIndex: 'leaveStatus', sorter: true },
  ],
  SEARCH_COLUMN_KEY: [
    'key',
    'numberSubmissionLeave',
    'employeeNumber',
    'employeeName',
    'leaveConfig',
    'isLeaveAll',
  ],
  DEFAULT_EMPTY_DATA_SOURCE: [
    {
      key: 'empty-data',
      numberSubmissionLeave: '',
      employeeNumber: '',
      employeeName: '',
      submissionDate: '',
      leaveDuration: '',
      leaveConfig: '',
      leaveStatus: '',
      isLeaveAll: '',
    },
  ],
};

export const LeaveConfigConstant = {
  TABLE_HEADER: [
    {
      title: 'Nama Cuti',
      key: 'name',
      dataIndex: 'name',
      sorter: true,
      width: 150,
      isCTA: true,
    },
    { title: 'Tipe Plafon', key: 'plafon_type', dataIndex: 'plafon_type', width: 150 },
    { title: 'Periode Reset', key: 'date_plafon_reset', dataIndex: 'date_plafon_reset', width: 150 },
    { title: 'Aktif Ketika', key: 'active_when', dataIndex: 'active_when', width: 130 },
    {
      title: 'Berlaku Untuk',
      key: 'config_type',
      dataIndex: 'config_type',
      width: 120
    },
    { title: 'Status', key: 'is_active', dataIndex: 'is_active', width: 150 },
    { title: 'Persetujuan 1', key: 'approval_1', dataIndex: 'approval_1',  width: 130 },
    { title: 'Persetujuan 2', key: 'approval_2', dataIndex: 'approval_2',  width: 130 },
    { title: 'Dibuat Oleh', key: 'create_name', dataIndex: 'create_name', width: 120 },
    { title: 'Dibuat Pada', key: 'create_date', dataIndex: 'create_date', width: 120 },
    { title: 'Diubah Oleh', key: 'write_name', dataIndex: 'write_name', width: 120 },
    { title: 'Diubah Pada', key: 'write_date', dataIndex: 'write_date', width: 120 },
    { title: 'Proses Ulang',isAction: true, key: 'action', dataIndex: 'action', width: 120 },
  ],
  SEARCH_COLUMN_KEY: [],
  SEARCH_COLUMN_COMPONENT: [
    { key: 'key', componentType: "text" },
    { key: 'name', componentType: "text" },
    { key: 'plafon_type', componentType: "select", options: [
      { label: 'Semua', value: '' },
      { label: 'Terbatas', value: 'terbatas' },
      { label: 'Tidak Terbatas', value: 'tidak_terbatas' },
    ]},
    { key: 'date_plafon_reset', componentType: "text" },
    { key: 'active_when', componentType: 'select', options: [
      { label: 'Semua', value: '' },
      { label: 'Mulai Bergabung ', value: 'mulai_bergabung' },
      { label: 'Bergabung di ke - n', value: 'bergabung_dibulan' },
    ]},
    { key: 'config_type', componentType: 'select', options: [
      { label: 'Semua', value: '' },
      { label: 'Jabatan', value: 'job' },
      { label: 'Karyawan', value: 'employee' },
    ]},
    { key: 'is_active', componentType: 'select', options: [
      { label: 'Semua', value: '' },
      { label: 'Aktif', value: 'aktif' },
      { label: 'Tidak Aktif', value: 'tidak aktif' },
    ]},
    { key: 'approval_1', componentType: 'select', options: [
      { label: 'Semua', value: '' },
      { label: 'Tanpa Persetujuan ', value: 'tanpa_persetujuan' },
      { label: 'Atasan Langsung ', value: 'atasan_langsung' },
      { label: 'Jabatan', value: 'jabatan' },
    ]},
    { key: 'approval_2', componentType: 'select', options: [
      { label: 'Semua', value: '' },
      { label: 'Tanpa Persetujuan ', value: 'tanpa_persetujuan' },
      { label: 'Atasan Langsung ', value: 'atasan_langsung' },
      { label: 'Jabatan', value: 'jabatan' },
    ]}
  ],
  DEFAULT_EMPTY_DATA_SOURCE: [
    {
      key: 'text',
      name: '',
      plafon_type: '',
      date_plafon_reset: '',
      active_when: '',
      config_type: '',
      is_active: '',
      approval_1: '',
      approval_2: '',
    },
  ],
  EMPLOYEE_TABLE_HEADER: [
    {
      title: 'NIK',
      dataIndex: 'nik',
    },
    {
      title: 'Nama',
      dataIndex: 'name',
    },
    {
      title: 'Jabatan',
      dataIndex: 'job_name',
    },
  ],
  JOB_TABLE_HEADER: [
    {
      title: 'Jabatan',
      dataIndex: 'name',
    },
    {
      title: 'Departement',
      dataIndex: 'dept_name',
    },
  ],
  HISTORY_TABLE_HEADER: [
    {
      title: 'Tanggal Perubahan',
      dataIndex: 'write_date',
    },
    {
      title: 'Diubah Oleh',
      dataIndex: 'write_name',
    },
    {
      title: 'Action',
      dataIndex: 'note',
    },
    
  ]
};
