import { Form, Tabs } from "antd"
import { MassLeaveDetailTemplatePropsType } from "./MassLeaveDetailTemplate.interface";
import Breadcrumb from "../../../../../components/breadcrumb/Breadcrumb";
import ToolbarButtonDetailTemplate from "../../../component/toolbar-button-detail-template/ToolbarButtonDetailTemplate";
import BottomLoading from "../../../../../components/bottom-loading/BottomLoading";
import FormBuilder from "antd-form-builder";
import { useCallback, useEffect, useState } from "react";
import { SelectEmployeeName } from "./components/SelectEmployeeName";
import { AppliesToRadioGroup } from "./components/AppliesToRadioGroup";
import { MemoizeHistoryTabs } from "../../../component/history-payroll-period-tabs/HistoryPayrollPeriodTabs";
import { SelectedEmployees } from "./components/SelectedEmployees";
import "./MassLeaveDetailTemplate.less"
import { TableStatusTabs } from "./components/TableStatusTabs";
import moment from "moment";

export default function AddMassLeaveTemplate(props: MassLeaveDetailTemplatePropsType) {
  const [form] = Form.useForm()
  const [, updateState] = useState<any>(); const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    const result = []
    if (!!props.dataSource?.list_employee_id?.length) {
      props.dataSource?.list_employee_id?.forEach((e) => {
        const data = processValue(e)?.split('-');
        if (data?.length) {
          result.push({ id: e, nik: data[0], employee: data[1] })
        }
      });
    }

    form.setFieldsValue({ 
      reduce_leave_rem: props.dataSource?.reduce_leave_rem,
      selected_employees: {
        checkbox: false,
        dataSource: result,
        selected_employee: props.dataSource?.list_employee_id
      } 
    })
    forceUpdate()
  }, [props.dataSource?.reduce_leave_rem, props.dataSource?.list_employee_id, props.employeeList])

  const processValue = (value: number): string => {
    return props.employeeList?.find((e) => e.key === value)?.label;
  }

  const onFinish = (values) => {
    if (props.onFinish) {
      props.onFinish(form.getFieldsValue());
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (props.onFinishFailed) {
      props.onFinishFailed(form.getFieldsValue());
    }
  };

  const onFieldsChange = (changedFields) => {
    switch(changedFields[0]?.name[0]) {
      case 'applies_to':
        form.setFieldsValue({ employee_id: [] });
        forceUpdate()
        break;
      case 'selected_employees':
        forceUpdate()
        break;
      case 'reduce_leave_rem':
        forceUpdate()
        break;
      case 'leave_config_id': 
        form.setFieldsValue({ employee_id: [] });
        forceUpdate()
        break;
      default:
        break;
    }
  };

  const onValuesChange = (e) => {
    if (props.onFieldChange) {
      props.onFieldChange(e, form);
    }
  }

  const meta: any = {
    columns: 1,
    formItemLayout: { labelCol: { span: 4 }, wrapperCol: { span: 10 } },
    initialValues: { obj: { input: 100 } },
    colon: false,
    fields: [
      {
        key: 'leave_name',
        label: 'Nama Cuti',
        rules: [{ required: true, whitespace: true, message: 'Nama Cuti Harus Diisi' }],
        viewMode: props.viewMode || props.isDetail,
        disabled: props.isLoading,
        initialValue: props.dataSource?.name || "",
      },
      {
        key: 'date_start_leave',
        label: 'Tanggal Mulai Cuti',
        required: true,
        message: 'Tanggal Mulai Cuti Harus Diisi',
        viewMode: props.viewMode || props.isDetail,
        disabled: props.isLoading,
        initialValue: props.dataSource?.date_start_leave ? formatDateString(props.dataSource.date_start_leave) : "",
        widget: "date-picker",
      },
      {
        key: 'date_end_leave',
        label: 'Tanggal Berakhir Cuti',
        required: true,
        message: 'Tanggal Berakhir Cuti Harus Diisi',
        viewMode: props.viewMode || props.isDetail,
        disabled: props.isLoading,
        initialValue: props.dataSource?.date_end_leave ? formatDateString(props.dataSource.date_end_leave) : "",
        widget: "date-picker",
        rules: [
          { validator: () => { 
            const dateStart = form.getFieldValue('date_start_leave')
            const dateEnd = form.getFieldValue('date_end_leave')

            if (moment(dateStart).isAfter(moment(dateEnd))) {
              return Promise.reject(new Error("Tanggal Berakhir harus lebih dari Tanggal Mulai"))  
            }
      
            return Promise.resolve()
          } }
        ]
      },
      {
        key: 'reduce_leave_rem',
        label: 'Mengurangi Cuti Karyawan',
        initialValue: props.dataSource?.reduce_leave_rem,
        disabled: props.isDetail || props.isLoading,
        widget: "checkbox",
        viewMode: false,
        options: [],
        required: false,
        message: '',
      },
      {
        key: 'applies_to',
        label: 'Berlaku Untuk',
        initialValue: manageValueApplies(),
        viewMode: props.isDetail,
        required: false,
        message: '',
        widget: AppliesToRadioGroup,
        widgetProps: {
          selected_employee: props.dataSource?.list_employee_id,
          disabled: props.viewMode || props.isLoading,
          isDetail: props.isDetail,
        },
      }
    ]
  }

  if (form.getFieldValue('selected_employees')?.checkbox || manageCondition()) {
    meta.fields.splice(6, 0, {
      key: 'employee_id',
      label: 'Karyawan',
      viewMode: false,
      required: true,
      message: 'Karyawan Harus Diisi',
      disabled: props.isLoading,
      widget: SelectEmployeeName,
      widgetProps: {
        employee_list: props.employeeList,
        selected_employee: form.getFieldValue("selected_employees")?.dataSource,
        disabled: props.viewMode,
        isDetail: props.isDetail,
      },
    }) 
  }

  if (props.isDetail && !!props.dataSource?.certain_employee) {
    meta.fields.splice(5, 0, {
      key: 'selected_employees',
      label: 'Karyawan yang sudah dipilih',
      required: false,
      message: '',
      viewMode: false,
      widget: SelectedEmployees,
      widgetProps: {
        employee_list: props.employeeList,
        disabled: props.viewMode,
        isDetail: props.isDetail,
      },
    }) 
  }
  
  if (form.getFieldValue('reduce_leave_rem')) {
    meta.fields.splice(4 ,0,{
      key: 'leave_config_id',
      label: 'Konfigurasi Cuti',
      viewMode: props.isDetail,
      widget: 'select',
      disabled: props.isLoading,
      required: true,
      message: 'Konfigurasi Cuti Harus Diisi',
      options: props.configList,
      widgetProps: { 
        showSearch: true,
        filterOption: (input:any, option:any) => 
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 
      },
      initialValue: props.dataSource?.config_name,
    })
  }

  function manageCondition() {
    if (typeof form.getFieldValue('applies_to') === "string") {
      return false
    }

    return form.getFieldValue('applies_to')
  }

  function manageValueApplies() {
    return props.dataSource?.certain_employee ?
        "Karyawan Tertentu" : "Semua Karyawan"
  }

  function formatDateString(dateString: string): string {
    return moment(dateString).format("YYYY-MM-DD")
  }

  return (
    <div id="add-salary-component" style={{ height: 'calc(100vh - 100px)' }}>
      <Breadcrumb breadcrumbConf={props.breadcrumbConf} />

      <Form
        name="basic"
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 8 }}
        layout="horizontal"
        labelAlign="left"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={onFieldsChange}
        onValuesChange={onValuesChange}
      >
        <ToolbarButtonDetailTemplate
          submitButton={props.submitButton}
          firstActionButton={props.firstActionButton}
          secondActionButton={props.secondActionButton}
          thirdActionButton={props.thirdActionButton}
          fourthActionButton={props.fourthActionButton}
          dropdownActionButton={props.dropdownActionButton}
        />

        <div className="scrollable-content" style={{ height: '680px' }}>
          <div className="form-wrapper">
            <FormBuilder form={form} meta={meta} />
            {props.isDetail ? (
              <div className="tabs-wrapper">
                <Tabs defaultActiveKey="1" type="card">
                  <Tabs.TabPane tab="History" key="1">
                    <MemoizeHistoryTabs key="history-master" history_id={props.dataSource?.history_id} />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Status" key="2">
                    <TableStatusTabs leave_log={props.dataSource?.detail_leave_log} list_employee={props.employeeList} />
                  </Tabs.TabPane>
                </Tabs>
              </div>
            ) : null}
          </div>
        </div>
      </Form>

      <BottomLoading active={props.isLoading} />
    </div>
  )
}