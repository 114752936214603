import Constant from "../../../../../../config/Constants"
import API from "../../../../../../services/API.service"
import getJobList from '../../../../../../@core/interaction/job/GetJobList.interaction';
import getEmployeeList from '../../../../../../@core/interaction/employee/GetEmployeeList.interaction';
import createAttendance from '../../../../../../@core/interaction/attendance/CreateAttendance.interaction'
import { employeeListToOptionsMapper } from '../../../../../../@core/mapper/employee/GetEmployeeList.mapper';
import { jobListToOption } from '../../../../../../@core/mapper/job/GetJobList.mapper';

export default class HrAddEmployeeAttendanceImpl  {
  getJobList(params) {
    return getJobList(params).then((res: any) => jobListToOption(res.data.content.job));
  }
  getJob() {
    const URL = Constant.URL_BASE_PATH + "/hr/lov/job"
    return API.get(URL).then((res:any) => 
      {
        const result = res.data.content.map(element => {
          return { label: element['job_name'], value: JSON.stringify(element), key: element['id'] }
        })
        return result;
      })
  }
  getEmpl() {
    const param: any = {
      page: 1,
      limit: 500
    }
    const URL = Constant.URL_BASE_COMPANY + "/v3/lov/emp"
    return API.get(URL, param).then((res: any) => {
      const result = res.data.content.map(element => {
        return { label: element['alternative_name'], value: JSON.stringify(element), key: element['id'] }
      })
      return result;
    })
  }

  getEmployeeList(params): Promise<any> {
    return getEmployeeList(params).then((res: any) =>
      employeeListToOptionsMapper(res.data.content),
    );
  }

  getGeofencing(params): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const URL = Constant.URL_BASE_ATTENDANCE + "/v3/absensi/detail/location"
      return API.get(URL, {params}).then(async (res: any) => {
        if (res.status === 200) {
          const content = res.data.content;
          resolve(content);
        } else {
          reject(res);
        }
      })
    });
  }
  submitAttendance(params) {
    return createAttendance(params);
  }
}
