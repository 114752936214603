import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Checkbox, InputNumber, Radio, Space, Input, Col, Menu, Dropdown } from 'antd';
import FormBuilder from 'antd-form-builder';
import { useHistory } from 'react-router-dom';
import { PlusOutlined, CloseSquareFilled, DownOutlined } from '@ant-design/icons'
import Breadcrumb from '../../../../../../components/breadcrumb/Breadcrumb';
import PageLoader from '../../../../../../components/loader/PageLoader';
import LocalNotificationService from '../../../../../../services/LocalNotification.service';
import UpdateOvtConfiguration from './HrUpdateEmployeeOvtConfiguration.usecase.impl';
import './HrUpdateEmployeeOvtConfiguration.less';

const useCaseImpl = new UpdateOvtConfiguration();
const localNotificationService = new LocalNotificationService();

let overtimeId = '';
export default function HrAddConfigurationOvertime({ match }) {
  const history = useHistory();
  const [form] = Form.useForm();
  overtimeId = match.params.overtimeId;
  
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [, updateState] = React.useState<any>();
  const [jobList, setJobList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [listWorkday, setListWorkday] = useState<any>([0]);
  const [listHoliday, setListHoliday] = useState<any>([0]);
  const [checkedWorkdays, setCheckedWorkdays] = useState<any>(false);
  const [checkedHolidays, setCheckedHolidays] = useState<any>(false);
  const [initialData, setInitialData] = useState<any>({});

  const breadcrumbConf: any = {
    createFromRoute: false,
    manualData: [
      {
        clickable: true,
        displayPathName: 'Lembur Karyawan',
        href: "/v2/app/hr-overtime/employee-overtime-configuration/",
      },
      {
        clickable: false,
        displayPathName: initialData.name,
      }
    ],
  };

  function onAddMoreWorkday(props) {
    let next = listWorkday.length;
    setListWorkday([...listWorkday, next])
  }
  function onRemoveWorkday(item) {
    setListWorkday(listWorkday.filter((d) => d !== item));
  }
  function onAddMoreHoliday(props) {
    let next = listHoliday.length;
    setListHoliday([...listHoliday, next])
  }
  function onRemoveHoliday(item) {
    setListHoliday(listHoliday.filter((d) => d !== item));
  }
  const onChangeCheckWorkdays = (e) => {
    setCheckedWorkdays(e.target.checked)
  };
  const onChangeCheckHolidays = (e) => {
    setCheckedHolidays(e.target.checked)
  };

  const onChangeOvtDays = (config, e, i, value, onChange) => {
    let temp:any = {};
    if(value === undefined) {
      temp["ovt_day"] = []
    } else {
      temp = value;
    }
    switch (config) {
      default:
        break;
      case 'hour_start':
        temp.ovt_day[i] = {
          hour_start: e,
          hour_end: value && value.ovt_day[i]?.hour_end,
          num_multipler: value && value.ovt_day[i]?.num_multipler,
        }
        break;
      case 'hour_end':
        temp.ovt_day[i] = {
          hour_start: value && value.ovt_day[i]?.hour_start,
          hour_end: e,
          num_multipler: value && value.ovt_day[i]?.num_multipler,
        }
        break;
      case 'num_multipler':
        temp.ovt_day[i] = {
          hour_start: value && value.ovt_day[i]?.hour_start,
          hour_end: value && value.ovt_day[i]?.hour_end,
          num_multipler: e,
        }
        break;
      case 'is_max_hour_day':
        temp.is_max_hour_day = e
        onChange = forceUpdate
        break;
      case 'max_hour_day':
        temp.max_hour_day = e
        break;
    }
    onChange(temp);
  }

  const onChangeOvtHolidays = (config, e, i, value, onChange) => {
    let temp:any = {};
    if(value === undefined) {
      temp["ovt_holiday"] = []
    } else {
      temp = value;
    }
    switch (config) {
      default:
        break;
      case 'hour_start':
        temp.ovt_holiday[i] = {
          hour_start: e,
          hour_end: value && value.ovt_holiday[i]?.hour_end,
          num_multipler: value && value.ovt_holiday[i]?.num_multipler,
        }
        break;
      case 'hour_end':
        temp.ovt_holiday[i] = {
          hour_start: value && value.ovt_holiday[i]?.hour_start,
          hour_end: e,
          num_multipler: value && value.ovt_holiday[i]?.num_multipler,
        }
        break;
      case 'num_multipler':
        temp.ovt_holiday[i] = {
          hour_start: value && value.ovt_holiday[i]?.hour_start,
          hour_end: value && value.ovt_holiday[i]?.hour_end,
          num_multipler: e,
        }
        break;
      case 'is_max_hour_holiday':
        temp.is_max_hour_holiday = e
        onChange = forceUpdate
        break;
      case 'max_hour_holiday':
        temp.max_hour_holiday = e
        break;
    }
    onChange(temp);
  }

  const Compensation = ({ value, onChange }) => {
    return (
      <Radio.Group
        onChange={v => onChange({ ovt_type: v.target.value, amount: '' })}
        defaultValue={value?.ovt_type}
      >
        <Space direction="vertical">
          <Radio value={'default'}>Default (Gaji Bulanan/ 173)</Radio>
          <Radio
            value={'normal'}>Nominal Tetap
            {value?.ovt_type === 'normal' ? <InputNumber min={1} onChange={v => onChange({ ovt_type: value.ovt_type, amount: v })} style={{ width: 100, marginLeft: 10 }} value={value?.amount} />
              : null} / Jam
          </Radio>
        </Space>
      </Radio.Group>
    )
  }

  const Rounding = ({ value, onChange }) => {
    return (
      <>
        <InputNumber min={1} onChange={onChange} value={value} style={{ width: 70 }} /> Menit akan dibulatkan menjadi 60 Menit
      </>
    )
  }
  const OvertimeWorkdays = ({ value, onChange }) => {
    const ovt_day_value = value?.ovt_day;
    
    return (
      <div style={{ marginLeft: 30, width: '35%' }}>
        <Row justify='space-around'>
          <Col>Jam</Col>
          <Col>Pengali</Col>
        </Row>
        <hr />
        {
          (!listWorkday || listWorkday.length < 1) && (
            <Row justify='space-between'>
              <Col style={{ marginBottom: 10}}>
                <Row align="stretch">
                  <Input style={{ width: 70 }} />
                  <Input style={{ width: 70 }} />
                </Row>
              </Col>
              <Col>
                <Input style={{ width: 140 }} />
              </Col>
            </Row>
          )
        }
        {
          !!listWorkday && listWorkday.map((item, i) => {
            return (
              <span key={i}>
                <Row justify='space-between'>
                  <Col style={{ marginBottom: 10 }}>
                    <Row align="stretch">
                      <InputNumber
                        min={1}
                        onChange={v => onChangeOvtDays('hour_start', v, i, value, onChange)}
                        value={ovt_day_value && ovt_day_value[i]?.hour_start}
                        style={{ width: 70 }}
                        onBlur={() => { onChange = forceUpdate() }}
                        onStep={() => { onChange = forceUpdate() }}
                      />
                      &nbsp; s/d &nbsp;
                      <InputNumber
                        min={1}
                        onChange={v => onChangeOvtDays('hour_end', v, i, value, onChange,)}
                        value={ovt_day_value && ovt_day_value[i]?.hour_end}
                        style={{ width: 70 }}
                        onBlur={() => { onChange = forceUpdate() }}
                        onStep={() => { onChange = forceUpdate() }}
                      />
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <InputNumber
                        min={1}
                        onChange={v => onChangeOvtDays('num_multipler', v, i, value, onChange,)}
                        value={ovt_day_value && ovt_day_value[i]?.num_multipler}
                        style={{ width: listWorkday.length - 1 === i ? 100 : 140 }}
                        onBlur={() => { onChange = forceUpdate() }}
                        onStep={() => { onChange = forceUpdate() }}
                      />
                      {
                        listWorkday.length - 1 === i && (
                          <Button onClick={() => onRemoveWorkday(i)}>
                            <CloseSquareFilled />
                          </Button>
                        )
                      }
                    </Row>
                  </Col>
                </Row>
              </span>
            );
          })
        }
        <Button
          disabled={listWorkday.length >= 9}
          type="primary"
          style={{ borderRadius: 5, marginTop: 20 }}
          icon={<PlusOutlined />}
          size={'small'}
          onClick={onAddMoreWorkday}
        >
          Tambah
        </Button>
        <div style={{ marginTop: 25 }}>
          <Checkbox
            checked={value?.is_max_hour_day}
            onChange={v => onChangeOvtDays('is_max_hour_day', v.target.checked, 0, value, onChange)}
            style={{ marginBottom: 20 }}
          >Berlaku batas maksimum</Checkbox>
          {
            value?.is_max_hour_day && (
              <div>
                <InputNumber
                  min={1}
                  value={value?.max_hour_day}
                  onChange={v => onChangeOvtDays('max_hour_day', v, 0, value, onChange)}
                  onBlur={() => { onChange = forceUpdate }}
                  onStep={() => { onChange = forceUpdate() }}
                /> Jam
              </div>
            )
          }
        </div>
      </div>
    )
  }
  const OvertimeHolidays = ({ value, onChange }) => {
    const ovt_holiday_value = value?.ovt_holiday;
    return (
      <div style={{ marginLeft: 30, width: '35%' }}>
        <Row justify='space-around'>
          <Col>Jam</Col>
          <Col>Pengali</Col>
        </Row>
        <hr />
        {
          !!listHoliday && listHoliday.map((item, i) => {
            return (
              <span key={i}>
                <Row justify='space-between'>
                  <Col style={{ marginBottom: 10 }}>
                    <Row align="stretch">
                      <InputNumber
                        min={1}
                        onChange={v => onChangeOvtHolidays('hour_start', v, i, value, onChange)}
                        value={ovt_holiday_value && ovt_holiday_value[i]?.hour_start}
                        style={{ width: 70 }}
                        onBlur={() => { onChange = forceUpdate }}
                        onStep={() => { onChange = forceUpdate() }}
                      />
                      &nbsp; s/d &nbsp;
                      <InputNumber
                        min={1}
                        onChange={v => onChangeOvtHolidays('hour_end', v, i, value, onChange,)}
                        value={ovt_holiday_value && ovt_holiday_value[i]?.hour_end}
                        style={{ width: 70 }}
                        onBlur={() => { onChange = forceUpdate }}
                        onStep={() => { onChange = forceUpdate() }}
                      />
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <InputNumber
                        min={1}
                        onChange={v => onChangeOvtHolidays('num_multipler', v, i, value, onChange,)}
                        value={ovt_holiday_value && ovt_holiday_value[i]?.num_multipler}
                        style={{ width: listHoliday.length - 1 === i ? 100 : 140 }}
                        onBlur={() => { onChange = forceUpdate }}
                        onStep={() => { onChange = forceUpdate() }}
                      />
                      {
                        listHoliday.length - 1 === i && (
                          <Button onClick={() => onRemoveHoliday(i)}>
                            <CloseSquareFilled />
                          </Button>
                        )
                      }
                    </Row>
                  </Col>
                </Row>
              </span>
            );
          })
        }
        <Button
          disabled={listHoliday.length >= 9}
          type="primary"
          style={{ borderRadius: 5, marginTop: 20 }}
          icon={<PlusOutlined />}
          size={'small'}
          onClick={onAddMoreHoliday}
        >
          Tambah
        </Button>
        <div style={{ marginTop: 25 }}>
          <Checkbox
            checked={value?.is_max_hour_holiday}
            onChange={v => onChangeOvtHolidays('is_max_hour_holiday', v.target.checked, 0, value, onChange)}
            style={{ marginBottom: 20 }}
          >Berlaku batas maksimum</Checkbox>
          {
            value?.is_max_hour_holiday && (
              <p>
                <InputNumber
                  min={1}
                  value={value?.max_hour_holiday}
                  onChange={v => onChangeOvtHolidays('max_hour_holiday', v, 0, value, onChange)}
                  onBlur={() => { onChange = forceUpdate }}
                  onStep={() => { onChange = forceUpdate() }}
                /> Jam
              </p>
            )
          }
        </div>
      </div>
    )
  }

  const meta: any = {
    columns: 1,
    fields: [
      {
        key: 'name',
        label: 'Nama Lembur',
        rules: [{ required: true, whitespace: true, message: "Nama Lembur harus diisi", }],
      },
      {
        key: 'is_rounding',
        label: 'Gunakan Pembulatan',
        widget: 'radio-group',
        forwardRef: true,
        options: [
          { value: true, label: 'Ya' },
          { value: false, label: 'Tidak' },
        ],
        onChange: forceUpdate
      },
      {
        key: 'rounding_time',
        label: 'Jika sama / lebih besar dari',
        widget: Rounding,
      },
      {
        key: 'ovt_compentation',
        label: 'Kompensasi Lembur',
        forwardRef: true,
        widget: Compensation,
        options: [
          { value: 'default', label: 'Default (Gaji Bulanan/ 173)' },
          { value: 'normal', label: 'Nominal Tetap' },
        ],
      },
      {
        key: 'config_type',
        label: 'Berlaku Untuk',
        widget: 'radio-group',
        forwardRef: true,
        options: [
          { value: 'job', label: 'Jabatan' },
          { value: 'employee', label: 'Karyawan' },
        ],
        onChange: forceUpdate
      },
      {
        key: 'job',
        label: 'Jabatan',
        required: true,
        message: 'Jabatan harus diisi',
        widget: 'select',
        options: jobList,
        widgetProps: {
          mode: 'multiple'
        },
      },
      {
        key: 'separator',
        colSpan: 4,
        render() {
          return (
            <hr />
          );
        },
      },

      {
        key: 'label1',
        colSpan: 4,
        render() {
          return (
            <Row>
              <label className="section-form-label" style={{ fontWeight: 'bold' }}>Pengali Lembur Hari Biasa</label>
            </Row>
          );
        },
      },
      {
        key: 'ovt_workdays',
        widget: OvertimeWorkdays,
      },

      {
        key: 'separator2',
        colSpan: 4,
        render() {
          return (
            <hr />
          );
        },
      },

      {
        key: 'label2',
        colSpan: 4,
        render() {
          return (
            <Row>
              <label className="section-form-label" style={{ fontWeight: 'bold' }}>Pengali Lembur Hari Libur</label>
            </Row>
          );
        },
      },
      {
        key: 'ovt_holidays',
        widget: OvertimeHolidays,
      },
      {
        key: 'separator3',
        colSpan: 4,
        render() {
          return (
            <hr />
          );
        },
      },
      {
        key: 'approval_1',
        label: 'Persetujuan 1',
        widget: 'select',
        required: true,
        options: [
          { value: 'tanpa_persetujuan', label: 'Tanpa Persetujuan' },
          { value: 'atasan_langsung', label: 'Atasan Langsung' },
          { value: 'jabatan', label: 'Jabatan' },
        ],
        onChange: forceUpdate
      },

    ],
  };

  useEffect(() => {
    initialPage();
  }, []);

  async function initialPage() {
    setIsLoading(true);
    const getJob = await useCaseImpl.getJob();
    const employeeList = await useCaseImpl.getEmpl();
    
    setJobList(getJob);
    setEmployeeList(employeeList);

    const ovtDetail: any = await useCaseImpl.getOvtConfiguration(overtimeId);
    const temp = ovtDetail.data.content;
    let tempEmp = temp.list_emp && temp.list_emp.map(e => e.employee_id);
    let tempJob = temp.list_job && temp.list_job.map(e => e.job_id);
    let initialEmployee;
    let initialJob;
    let initialJobApproval_1;
    let initialJobApproval_2;
    if(tempEmp) {
      initialEmployee = await useCaseImpl.getEmpl({id: tempEmp.map(id => id)});
    }
    if(tempJob) {
      initialJob = await useCaseImpl.getJob({job_id: tempJob.map(id => id)});
    }
    if(temp.approval_1) {
      initialJobApproval_1 = await useCaseImpl.getJob({job_id: temp.job_approval_1});
    }
    if(temp.approval_2) {
      initialJobApproval_2 = await useCaseImpl.getJob({job_id: [temp.job_approval_2]});
    }
    
    temp.ovt_day.length > 0 && setListWorkday(Array.from(Array(temp.ovt_day.length).keys()));
    temp.ovt_holiday.length > 0 && setListHoliday(Array.from(Array(temp.ovt_holiday.length).keys()));

    let tempInitial = {
      id: temp.id,
      name: temp.name,
      is_rounding: temp.is_rounding,
      rounding_time: temp.rounding_time,
      config_type: temp.ovt_type_rel,
      approval_1: temp.approval_1,
      approval_2: temp.approval_2,
      job_approval1: initialJobApproval_1 && initialJobApproval_1[0]?.value,
      job_approval2: initialJobApproval_2 && initialJobApproval_2[0]?.value,
      ovt_compentation: { ovt_type: temp.ovt_type, amount: temp.amount },
      ovt_workdays: {
        ovt_day: temp.ovt_day,
        is_max_hour_day: temp.is_max_hour_day,
        max_hour_day: temp.max_hour_day
      },
      ovt_holidays: {
        ovt_holiday: temp.ovt_holiday,
        is_max_hour_holiday: temp.is_max_hour_holiday,
        max_hour_holiday: temp.max_hour_holiday,
      },
      employee: initialEmployee && initialEmployee.map(item => item.value),
      job: initialJob && initialJob.map(item => item.value),
      history_id: temp.history_id,
      company_id: temp.company_id
    }

    setInitialData(tempInitial)
    setIsLoading(false);

  }

  async function onFinish(e) {
    if (e) {
      submitToEndPoint(e);
    }
  }

  async function changeStatus(e) {
    const params = {
      ids: [initialData?.id],
      status: e
    }
    let message = 'Konfigurasi Lembur Berhasil di non-aktifkan';
    if(e === 'aktif') {
      message = 'Konfigurasi Lembur Berhasil di aktifkan';
    }
    useCaseImpl.deactiveOvtConfiguration(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          history.push('/app/hr-overtime/employee-overtime-configuration/');
          localNotificationService.openSuccess(message, '', 3);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }
  

  async function submitToEndPoint(e) {
    setIsLoading(true);

    const list_job = e.job && e.job.map(item => JSON.parse(item)).map(function (row) {
      return {
        job_id: row.id,
        name: row.job_name,
        dept_name: row.dept_name,
        dept_id: row.dept_id

      }
    })
    const list_employee = e.employee && e.employee.map(item => JSON.parse(item)).map(function (row) {
      return {
        employee_id: row.id,
        nik: row.nik,
        name: row.name,
        alternative_name: row.alternative_name,
        job_id: row.job_id,
        job_name: row.job_name,
        dept_id: row.dept_id,
        dept_name: row.dept_name
      }
    })
    const ovt_days = e.ovt_workdays?.ovt_day && e.ovt_workdays.ovt_day.map(item => {
      return{
        ...item,
        num_multipler: item.num_multipler.toString(),
      }
    })
    const ovt_holidays = e.ovt_holidays?.ovt_holiday && e.ovt_holidays.ovt_holiday.map(item => {
      return{
        ...item,
        num_multipler: item.num_multipler.toString(),
      }
    })
    let tempParam: any = {}
    e.job_approval1 && (tempParam['job_approval_1'] = JSON.parse(e.job_approval1).id)
    e.job_approval2 && (tempParam['job_approval_2'] = JSON.parse(e.job_approval2).id)
    e.employee && (tempParam['list_emp'] = list_employee)
    e.job && (tempParam['list_job'] = list_job)

    const params = {
      id: initialData?.id,
      name: e.name.trim(),
      ovt_type_rel: e.config_type,
      is_rounding: e.is_rounding,
      rounding_time: e.rounding_time,
      ovt_type: e.ovt_compentation?.ovt_type,
      amount: e.ovt_compentation?.amount.toString(),
      is_max_hour_day: e.ovt_workdays?.is_max_hour_day,
      max_hour_day: e.ovt_workdays?.max_hour_day,
      is_max_hour_holiday: e.ovt_holidays.is_max_hour_holiday,
      max_hour_holiday: e.ovt_holidays.max_hour_holiday,
      is_2nd_appr: (e.approval_2 && e.approval_2 !== 'tanpa_persetujuan') ? true : false,
      approval_1: e.approval_1,
      approval_2: e.approval_2 || 'tanpa_persetujuan',
      history_id: initialData?.history_id,
      ovt_day: ovt_days,
      ovt_holiday: ovt_holidays,
      company_id: initialData.company_id,
      ...tempParam
    }

    useCaseImpl.updateOvtConfiguration(params).then(
      (res: any) => {
        const data = res.data;
        if (data.status === 200) {
          history.push('/app/hr-overtime/employee-overtime-configuration/');
          localNotificationService.openSuccess('Konfigurasi Lembur Berhasil Diubah', '', 3);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }

  let newMeta = meta;

  if (form.getFieldValue('is_rounding') !== undefined && !form.getFieldValue('is_rounding')) {
    newMeta.fields = newMeta.fields.filter(function (item) {
      return item.key !== 'rounding_time';
    });
  } else {
    if (!form.getFieldValue('is_rounding') && !initialData?.is_rounding) { 
      newMeta.fields = newMeta.fields.filter(function (item) {
        return item.key !== 'rounding_time';
      });
    }
  }

  if (form.getFieldValue('config_type')) {
    if (form.getFieldValue('config_type') === 'employee') {
      const objIndex = newMeta.fields.findIndex((obj => obj.key === 'job'));
      newMeta.fields[objIndex] = {
        key: 'employee',
        label: 'Karyawan',
        required: true,
        message: 'Karyawan harus diisi',
        widget: 'select',
        options: employeeList,
        widgetProps: {
          mode: 'multiple'
        },
      }
    } else if (form.getFieldValue('config_type') === 'job') {
      const objIndex = newMeta.fields.findIndex((obj => obj.key === 'employee'));
      newMeta.fields[objIndex] = {
        key: 'job',
        label: 'Jabatan',
        required: true,
        message: 'Jabatan harus diisi',
        widget: 'select',
        options: jobList,
        widgetProps: {
          mode: 'multiple'
        },
      }
    }
  } else {
    if(initialData.config_type && initialData.config_type === 'employee' ) {
      const objIndex = newMeta.fields.findIndex((obj => obj.key === 'job'));
      newMeta.fields[objIndex] = {
        key: 'employee',
        label: 'Karyawan',
        required: true,
        message: 'Karyawan harus diisi',
        widget: 'select',
        options: employeeList,
        widgetProps: {
          mode: 'multiple'
        },
      }
    }
  }

  if (form.getFieldValue('approval_1')) {
    if (form.getFieldValue('approval_1') === 'jabatan') {
      newMeta.fields[newMeta.fields.length + 1] = ({
        key: 'job_approval1',
        label: 'Nama Jabatan',
        widget: 'select',
        required: true,
        message: 'Jabatan harus diisi',
        options: jobList,
        widgetProps: {
          showSearch: true
        },
      })
      newMeta.fields[newMeta.fields.length + 2] = (
        {
          key: 'approval_2',
          label: 'Persetujuan 2',
          widget: 'select',
          required: true,
          options: [
            { value: 'tanpa_persetujuan', label: 'Tanpa Persetujuan' },
            { value: 'atasan_langsung', label: 'Atasan Langsung' },
            { value: 'jabatan', label: 'Jabatan' },
          ],
          onChange: forceUpdate
        }
      )
    }
    if (form.getFieldValue('approval_1') === 'atasan_langsung') {
      newMeta.fields[newMeta.fields.length + 1] = (
        {
          key: 'approval_2',
          label: 'Persetujuan 2',
          widget: 'select',
          required: true,
          options: [
            { value: 'tanpa_persetujuan', label: 'Tanpa Persetujuan' },
            { value: 'atasan_langsung', label: 'Atasan Langsung' },
            { value: 'jabatan', label: 'Jabatan' },
          ],
          onChange: forceUpdate
        }
      )
    }
  } else {
    if (initialData?.approval_1 === 'jabatan') {
      newMeta.fields[newMeta.fields.length + 1] = ({
        key: 'job_approval1',
        label: 'Nama Jabatan',
        widget: 'select',
        required: true,
        options: jobList,
        widgetProps: {
          showSearch: true
        },
      })
      newMeta.fields[newMeta.fields.length + 2] = (
        {
          key: 'approval_2',
          label: 'Persetujuan 2',
          widget: 'select',
          required: true,
          options: [
            { value: 'tanpa_persetujuan', label: 'Tanpa Persetujuan' },
            { value: 'atasan_langsung', label: 'Atasan Langsung' },
            { value: 'jabatan', label: 'Jabatan' },
          ],
          onChange: forceUpdate
        }
      )
    }
    if (initialData?.approval_1 === 'atasan_langsung') {
      newMeta.fields[newMeta.fields.length + 1] = (
        {
          key: 'approval_2',
          label: 'Persetujuan 2',
          widget: 'select',
          required: true,
          options: [
            { value: 'tanpa_persetujuan', label: 'Tanpa Persetujuan' },
            { value: 'atasan_langsung', label: 'Atasan Langsung' },
            { value: 'jabatan', label: 'Jabatan' },
          ],
          onChange: forceUpdate
        }
      )
    }
  }
  if (form.getFieldValue('approval_2')) {
    if (
      form.getFieldValue('approval_2') === 'jabatan' &&
      form.getFieldValue('approval_1') !== 'tanpa_persetujuan') {
        newMeta.fields[newMeta.fields.length + 1] = ({
          key: 'job_approval2',
          label: 'Nama Jabatan',
          widget: 'select',
          required: true,
          options: jobList,
          widgetProps: {
            showSearch: true
          },
        })
    }
  } else {
    if (initialData?.approval_2 === 'jabatan') {
      newMeta.fields[newMeta.fields.length + 1] = ({
        key: 'job_approval2',
        label: 'Nama Jabatan',
        widget: 'select',
        required: true,
        options: jobList,
        widgetProps: {
          showSearch: true
        },
      })
    }
  }
  const dropdownButton = {
    visible: true,
    disabled: isLoading,
    options: [
      { key: 'non-active', label: 'Non-Aktifkan', onClick: () => changeStatus('tidak aktif') },
      // { key: 'active', label: 'Aktifkan', onClick: () => changeStatus('aktif') }
    ]
  };

  const DropdownButton = () => {
    const menu = dropdownButton && dropdownButton.visible ? (
      <Menu>
        {dropdownButton.options.map((opt:any) => (
          <Menu.Item
            disabled={opt.disabled}
            onClick={() => opt.onClick()}
            key={opt.key}
          >
            {opt.label}
          </Menu.Item>
        ))}
      </Menu>
    ) : null

    return dropdownButton && dropdownButton.visible ? (
      <Dropdown
        disabled={dropdownButton.disabled}
        overlay={menu}
      >
        <Button
          type='primary'
        >
          Tindakan <DownOutlined />
        </Button>
      </Dropdown>
    ) : null
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div
      className="add-leave-configuration-page"
      style={{ position: 'relative', height: 'calc(100vh - 100px)' }}
    >
      <Form form={form} layout="horizontal" initialValues={initialData} onFinish={onFinish}>
        <div>
          <div className="form-wrapper">
            <div>
              <Breadcrumb breadcrumbConf={breadcrumbConf} />
              <Form.Item
                className="form-footer"
                style={{ width: '100%', marginTop: 20 }}
                
              >
                <div className='employee-config-toolbar'>
                  <Space>
                    <Button
                      type="primary"
                      onClick={() => form.submit()}
                      disabled={isLoading}
                    >
                      Ubah
                    </Button>
                    <Button
                      type="primary"
                      onClick={ ()=> history.push('/app/hr-overtime/employee-overtime-configuration/new/')}
                      disabled={isLoading}
                    >
                      Tambah
                    </Button>
                    <Button
                      onClick={() => history.push('/app/hr-overtime/employee-overtime-configuration/')}
                    >
                      Batal
                    </Button>
                  </Space>
                  <DropdownButton />
                </div>
              </Form.Item>
              <div style={{ border: '1px solid #000', padding: 10, overflowY: 'scroll', height: '70vh' }}>
                <FormBuilder form={form} meta={newMeta} />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
